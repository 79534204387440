import Cookies from 'js-cookie';

const setBrowserStorage = (show) => {
	if (show === 'always') {
		return;
	}
	if (show === 'next session') {
		window.sessionStorage.setItem('promoPopupShown', true);
	}
	if (show === '7 days') {
		Cookies.set('promoPopupShown', 'true', { expires: 7 });
	}
};

export default setBrowserStorage;
