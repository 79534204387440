import React from 'react';
import Logo from '../Logo';

const MinimalHeader = () => (
	<header className="text-white bg-brand-400">
		<div className="container px-6 py-4 mx-auto">
			<a href="/" title="Home">
				<Logo />
			</a>
		</div>
	</header>
);

export default MinimalHeader;
