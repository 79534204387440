import React from 'react';
import FooterLogo from '../FooterLogo';

const FooterBadgesHardcoded = ({ extended = false }) => (
	<div className="flex flex-wrap items-center justify-center gap-4 mx-auto mb-6 md:mb-16">
		{extended && (
			<>
				<FooterLogo
					imgSrc="https://www.datocms-assets.com/23428/1645700055-feefoplatinumservice2022rectanglelsmldark.png"
					alt="Platinum trusted service award 2022"
					className="col-span-2 w-52"
				/>
				<FooterLogo
					imgSrc="https://www.datocms-assets.com/23428/1638461090-frontrunners4x.png"
					alt="Front runners award"
				/>
				<FooterLogo
					imgSrc="https://www.datocms-assets.com/23428/1638461074-crozdesk-happiest4x.png"
					alt="High user satisfaction"
				/>
			</>
		)}
		<FooterLogo
			imgSrc="https://www.datocms-assets.com/23428/1619436653-google-rating.png"
			alt="Google Rating 4.9/5"
		/>
		<FooterLogo
			imgSrc="https://www.datocms-assets.com/23428/1654781686-getapp-4-7.png"
			alt="Get App rating 4.7/5"
		/>
		<FooterLogo
			imgSrc="https://www.datocms-assets.com/23428/1654781587-capterra-4-7.png"
			alt="Google Rating 4.9/5"
		/>
		<FooterLogo
			imgSrc="https://www.datocms-assets.com/23428/1659103833-2022-top25_businessserviceslogo-1.jpg"
			alt="Business Services top 25 Companies"
		/>
		<FooterLogo
			imgSrc="https://www.datocms-assets.com/23428/1654682666-best-companies-very-good-2022.png"
			alt="Best companies to work for badge"
		/>
		<FooterLogo
			imgSrc="https://www.datocms-assets.com/23428/1660816242-microsoftteams-image-57.png"
			alt="Crozdesk software awards leader"
		/>
		<FooterLogo
			imgSrc="https://www.datocms-assets.com/23428/1638461087-cyberessentials4x.png"
			alt="Cyber Essentials Plus Award"
		/>
	</div>
);

export default FooterBadgesHardcoded;
