import React, { useState } from 'react';
import Button from '@brighthr/component-button';

const Dropdown = ({ selectedOption, children }) => {
	const [dropdownOpen, setDropdownOpen] = useState(false);
	return (
		<>
			<Button
				color="primary"
				iconName="chevron-thick-down"
				iconPosition="right"
				className="flex flex-row items-center justify-between w-full px-3 py-2 text-left border-2 rounded-md dropdown-btn border-brand-300"
				size="base"
				textButton
				text={selectedOption}
				onClick={() => setDropdownOpen(!dropdownOpen)}
			/>

			{dropdownOpen && (
				<Button
					color="primary"
					className="flex flex-col items-center w-full text-left bg-white shadow-lg justify- between dropdown-content lg:shadow-none"
					size="base"
					textButton
					text={children}
					onClick={() => setDropdownOpen(false)}
				/>
			)}
		</>
	);
};

export default Dropdown;
