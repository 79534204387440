import React, { useState, useEffect } from 'react';
import LinkButton from '@brighthr/component-linkbutton';

import WellCTA from '../../WellCTA';
import CopyBlock from '../../CopyBlock';
import { useLocale } from '../../../context/Locale';
import { buildLink } from '../../../utils/locale';
import LocaleContent from '../../LocaleContent';

const CMSWellCTA = ({ title, text }) => {
	const locale = useLocale();
	const [utmString, setUtmString] = useState('');

	useEffect(() => {
		setUtmString(window.sessionStorage.getItem('utmString') || '');
	}, []);

	return (
		<WellCTA>
			<div className="mb-6">
				<h2 className="mb-6 text-xl font-bold leading-snug md:text-3xl">{title}</h2>
				{text && <CopyBlock>{text}</CopyBlock>}
				<div className="flex flex-col justify-center md:flex-row">
					<div className="w-full mx-2 mb-2 md:w-fit md:mb-0">
						<LinkButton
							color="accent"
							size="xl"
							text="Request a demo"
							href={buildLink(locale, 'book-a-demo')}
							target="_blank"
						/>
					</div>

					<LocaleContent showInLocales={['en', 'ca']}>
						<div className="w-full mx-2 md:w-fit ">
							<LinkButton
								className="mt-24"
								color="accent"
								outlineButton
								size="xl"
								text={locale === 'en' ? 'Get a price online' : 'Request pricing'}
								href={buildLink(locale, `request-quote${utmString}`)}
								target="_blank"
							/>
						</div>
					</LocaleContent>
				</div>
			</div>
		</WellCTA>
	);
};

export default CMSWellCTA;
