import React, { useEffect, useState } from 'react';
import loadScript from 'load-script2';
import Button from '@brighthr/component-button';
import { useFormik } from 'formik';
import submitMarketoForm from '../submitMarketoForm';
import FormFooterLinks from '../FormFooterLinks';
import { useLocale } from '../../../context/Locale';

const datefns = require('date-fns');

const RequestAppointmentForm = () => {
	const pagelocale = useLocale();
	const [todaysDate, setTodaysDate] = useState();

	useEffect(() => {
		const today = datefns.format(new Date(), 'yyyy-MM-dd');
		setTodaysDate(today);
	}, []);

	useEffect(() => {
		// Preloads the Marketo form for background submission
		const loadForm = () =>
			loadScript('//app-lon04.marketo.com/js/forms2/js/forms2.min.js')
				.then(() => {
					window.MktoForms2.loadForm('//app-lon04.marketo.com', '217-MIC-854', 2796);
				})
				.catch(() => {});

		if (window.requestIdleCallback) {
			window.requestIdleCallback(loadForm);
		} else {
			setTimeout(loadForm);
		}
	}, []);

	const urlParams = (name) => {
		const results =
			typeof window !== 'undefined'
				? new RegExp(`[?&]${name}=([^&#]*)`).exec(window.location.search)
				: '';

		if (results == null) {
			return null;
		}

		return decodeURI(results[1]) || 0;
	};

	const requestappointmentBrightSafe = urlParams('p') ? urlParams('p') : '';
	const requestappointmentTerritory = urlParams('t') ? urlParams('t') : '';

	const modularTitle =
		requestappointmentBrightSafe === 'hr' && requestappointmentTerritory === 'uk'
			? 'Request an HR documentation appointment'
			: 'Request appointment';

	const { handleChange, handleSubmit, setStatus, values, isSubmitting, status } = useFormik({
		initialValues: {
			FirstName: '',
			LastName: '',
			Email: '',
			Company: '',
			requestappointmentAccountnumber: '',
			Phone: '',
			AppointmentType: '',
			preferredDate: '',
			preferredEndDate: '',
			Contact_Us_Comments__c: '',
			LeadSource: 'Request an appointment',
			requestappointmentBrightSafe,
			requestappointmentTerritory,
		},
		onSubmit: async (data) => {
			submitMarketoForm(data)
				.then(() => {
					if (window.dataLayer) {
						window.dataLayer.push({
							event: 'Request appointment submit',
						});
					}
					setStatus('submitted');
				})
				.catch(() => {
					setStatus('error');
				});
		},
	});

	const handleClick = () => {
		if (window.dataLayer) {
			window.dataLayer.push({
				event: 'Request appointment',
			});
		}
	};

	const closeTab = () => {
		if (typeof window === 'undefined') return;
		window.close();
	};

	return (
		<>
			{status === 'error' && (
				<p className="text-red-400">
					Sorry we have been unable handle your request, please try again later.
				</p>
			)}

			{status === 'submitted' && (
				<div>
					<h2 className="mb-2 text-3xl font-bold">Thank you</h2>
					<p>
						Thanks for your request, you will be contacted shortly to arrange your
						appointment.
					</p>

					<Button className="w-full my-3 lg:mb-0" onClick={closeTab}>
						Back to dashboard
					</Button>
				</div>
			)}

			{!status && (
				<form className="flex flex-col max-w-full pt-4" onSubmit={handleSubmit}>
					<h4 className="mb-4 text-2xl font-bold md:text-3xl">{modularTitle}</h4>

					<div className="mb-8 space-y-3">
						<div className="flex flex-wrap w-full md:flex-nowrap">
							<label
								htmlFor="FirstName"
								className="block w-full text-sm font-bold text-left"
							>
								First name
								<input
									id="FirstName"
									name="FirstName"
									placeholder="Jane"
									required
									value={values.FirstName}
									onChange={handleChange}
									onClick={handleClick}
									className="block w-full px-4 py-2 mt-2 border-2 border-gray-500 rounded focus-visible:drop-shadow-md focus-visible:border-brand-400"
								/>
							</label>
							<span className="block mx-4 "> &nbsp;</span>
							<label
								htmlFor="LastName"
								className="block w-full text-sm font-bold text-left"
							>
								Last name
								<input
									id="LastName"
									name="LastName"
									placeholder="Smith"
									required
									value={values.LastName}
									onChange={handleChange}
									onClick={handleClick}
									className="block w-full px-4 py-2 mt-2 border-2 border-gray-500 rounded focus-visible:drop-shadow-md focus-visible:border-brand-400"
								/>
							</label>
						</div>
						<div className="flex flex-wrap w-full md:flex-nowrap">
							<label
								htmlFor="Email"
								className="block w-full mb-1 text-sm font-bold text-left"
							>
								Email address
								<input
									id="Email"
									name="Email"
									placeholder="jane.smith@acmeinc.com"
									type="email"
									pattern="^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$"
									required
									value={values.Email}
									onChange={handleChange}
									onClick={handleClick}
									className="block w-full px-4 py-2 mt-2 border-2 border-gray-500 rounded focus-visible:drop-shadow-md focus-visible:border-brand-400"
								/>
							</label>
							<span className="block mx-4 "> &nbsp;</span>
							<label
								htmlFor="Company"
								className="block w-full mb-1 text-sm font-bold text-left"
							>
								Company
								<input
									id="Company"
									name="Company"
									placeholder="ACME inc."
									required
									value={values.Company}
									onChange={handleChange}
									onClick={handleClick}
									className="block w-full px-4 py-2 mt-2 border-2 border-gray-500 rounded focus-visible:drop-shadow-md focus-visible:border-brand-400"
								/>
							</label>
						</div>
						<label
							htmlFor="requestappointmentAccountnumber"
							className="block w-full mb-1 text-sm font-bold text-left md:mb-0"
						>
							Account number
							<input
								id="requestappointmentAccountnumber"
								name="requestappointmentAccountnumber"
								placeholder="Account number"
								required
								value={values.requestappointmentAccountnumber}
								onChange={handleChange}
								onClick={handleClick}
								className="block w-full px-4 py-2 mt-2 border-2 border-gray-500 rounded focus-visible:drop-shadow-md focus-visible:border-brand-400"
							/>
						</label>
						<label
							htmlFor="Phone"
							className="block w-full mb-1 text-sm font-bold text-left"
						>
							Telephone number
							<input
								id="Phone"
								name="Phone"
								type="tel"
								placeholder="12345678"
								pattern="[0-9\s]{7,16}"
								title="Enter a valid telephone number between 7 and 16 digits."
								required
								value={values.Phone}
								onChange={handleChange}
								onClick={handleClick}
								className="block w-full px-4 py-2 mt-2 border-2 border-gray-500 rounded focus-visible:drop-shadow-md focus-visible:border-brand-400"
							/>
						</label>

						<label
							htmlFor="AppointmentType"
							className="block w-full text-sm font-bold text-left"
						>
							Appointment type
							<select
								id="AppointmentType"
								name="AppointmentType"
								required
								className="w-full p-2 pr-10 mt-2 border-2 border-gray-500 rounded-md select-arrow"
								value={values.AppointmentType}
								onChange={handleChange}
							>
								<option disabled hidden selected value="">
									Please select
								</option>
								<option value="Specific document request / query">
									Specific document request / query
								</option>
								<option value="Review of existing document(s)">
									Review of existing document(s)
								</option>
								<option value="HR advice">HR advice</option>
								<option value="H&S advice">H&S advice</option>
								<option value="Face-to-face support">Face-to-face support</option>
								<option value="Bright software support">
									Bright software support
								</option>
								{requestappointmentTerritory === 'ca' && (
									<option value="Annual review">Annual review</option>
								)}
							</select>
						</label>
						<label
							htmlFor="preferredDate"
							className="block w-full text-sm font-bold text-left"
						>
							Preferred date(s)
							<div className="flex flex-col items-center md:flex-row">
								<input
									type="text"
									onFocus={(e) => {
										e.currentTarget.type = 'date';
										e.currentTarget.focus();
									}}
									placeholder="Select date"
									id="preferredDate"
									name="preferredDate"
									min={todaysDate}
									required
									value={values.preferredDate}
									onChange={handleChange}
									onClick={handleClick}
									className="block w-full px-4 py-2 mt-2 border-2 border-gray-500 rounded focus-visible:drop-shadow-md focus-visible:border-brand-400"
								/>

								<span className="hidden mx-4 md:block"> &ndash; </span>

								<input
									type="text"
									onFocus={(e) => {
										e.currentTarget.type = 'date';
										e.currentTarget.focus();
									}}
									placeholder="Select end date"
									id="preferredEndDate"
									name="preferredEndDate"
									value={values.preferredEndDate}
									onChange={handleChange}
									onClick={handleClick}
									min={values.preferredDate || todaysDate}
									required
									className="block w-full px-4 py-2 mt-2 border-2 border-gray-500 rounded focus-visible:drop-shadow-md focus-visible:border-brand-400"
								/>
							</div>
						</label>
						<label
							htmlFor="Contact_Us_Comments__c"
							className="block w-full mb-1 text-sm font-bold text-left"
						>
							Other details
							<textarea
								placeholder="Please enter any other details here"
								id="OtherDetails"
								name="Contact_Us_Comments__c"
								value={values.Contact_Us_Comments__c}
								onChange={handleChange}
								onClick={handleClick}
								className="block w-full h-32 px-4 py-2 mt-2 border-2 border-gray-500 rounded focus-visible:drop-shadow-md focus-visible:border-brand-400"
							/>
						</label>
						<input type="hidden" id="product" name="product" />
						<input type="hidden" id="tenant" name="tenant" />
					</div>

					<div className="flex flex-col">
						<Button
							id="Submit"
							type="submit"
							size="lg"
							disabled={isSubmitting}
							text="Submit"
						/>
					</div>
					<FormFooterLinks
						className="mt-2 text-sm text-left text-gray-900"
						textLinkColor="text-gray-900"
						locale={pagelocale}
					/>
				</form>
			)}
		</>
	);
};

export default RequestAppointmentForm;
