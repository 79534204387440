import React from 'react';
import cn from 'classnames';
import Container from '../../Container';
import CopyBlock from '../../CopyBlock';
import TestimonialQuote from '../../TestimonialQuote';

const TestimonialCards = ({ title, descriptionNode, testimonials }) => {
	const cols =
		(testimonials.length % 3 === 0 && 'md:grid-cols-3') ||
		(testimonials.length % 2 === 0 && 'md:grid-cols-2') ||
		'grid-cols-1';

	return (
		<Container className="">
			{title && <h3 className="mb-2 text-2xl font-bold md:text-3xl">{title}</h3>}
			{descriptionNode && (
				<div className="mb-6">
					<CopyBlock>{descriptionNode.childMdx.body}</CopyBlock>
				</div>
			)}

			<ul className={cn('grid grid-flow-row gap-8', cols)}>
				{testimonials.map((t) => (
					<li>
						<TestimonialQuote
							colour="Dark"
							quoteSource={t.quoteSource}
							quoteTextNode={t.quoteTextNode}
						/>
					</li>
				))}
			</ul>
		</Container>
	);
};

export default TestimonialCards;
