import React from 'react';
import { useFormikContext } from 'formik';
import cn from 'classnames';
import { useSessionState } from '../../context/session';
import Tag from '../../../../icons/Tag.svg';
import CrossSmall from '../../../../icons/CrossSmall.svg';

const SummaryDetails = ({
	subtotal,
	quoteReady,
	taxLabel,
	price,
	tax,
	unitPrice,
	promoCodeObj,
	promoInput,
	promoCodeError,
	handlePromoCodeRemoval,
	setPromoCodeError,
	discountAmount,
	handleFetchPromotionCode,
	showPromoInput,
	setShowPromoInput,
	taxApplied,
	term,
}) => {
	const formik = useFormikContext();
	const { session } = useSessionState();
	const amountOfEmployees = formik.values.amountOfEmployees || session.amountOfEmployees || 0;

	return (
		<div>
			<div className="pb-3 mb-3 border-b border-gray-400">
				<h3 className="mb-0 text-lg font-bold leading-7">Employees:</h3>
				{quoteReady ? (
					<p>
						{amountOfEmployees} employees
						<span className="ml-3 text-gray-500">
							({amountOfEmployees} x £{unitPrice})
						</span>
					</p>
				) : (
					<p className="w-full h-5 mt-1 leading-5 bg-gray-200 rounded animate-pulse" />
				)}
			</div>
			<div className="relative pb-3 mb-3 border-b border-gray-400">
				<h3 className="mb-0 text-lg font-bold leading-7">Term:</h3>
				<p>{term} Months</p>
			</div>
			<div className="pb-3 mb-3 border-b border-gray-400">
				<div className="flex flex-row justify-between flex-nowrap">
					<h3 className="mb-0 text-lg font-bold leading-7">Subtotal:</h3>
					{quoteReady ? (
						<div className="mb-0 text-lg font-bold leading-7">
							£<span>{subtotal}</span>
						</div>
					) : (
						<div className="w-20 h-5 mt-2 bg-gray-200 rounded animate-pulse" />
					)}
				</div>
			</div>
			<div className="pb-3 mb-1">
				<button
					className={cn('font-bold text-brand-500', {
						hidden: showPromoInput,
						block: !showPromoInput,
					})}
					type="button"
					onClick={() => setShowPromoInput(true)}
					// eslint-disable-next-line react/no-unknown-property
					cy-data="showPromocodeInput"
				>
					Add promotion code
				</button>

				<div
					className={cn({
						hidden: !showPromoInput,
						block: showPromoInput,
					})}
				>
					{promoCodeObj?.active === true ? (
						<>
							<div>
								<div className="flex flex-row justify-between flex-nowrap">
									<div className="flex flex-row items-center px-3 py-1 text-lg bg-gray-300 rounded flex-nowrap">
										<Tag />
										<p className="mx-3">{promoCodeObj.code}</p>
										<button
											type="button"
											onClick={() => handlePromoCodeRemoval()}
										>
											<CrossSmall />
											<span className="hidden">Remove</span>
										</button>
									</div>
									{quoteReady ? (
										<div
											className="mb-0 text-gray-600"
											data-testid="discountAmount"
										>
											-£
											<span>{discountAmount}</span>
										</div>
									) : (
										<div className="w-20 h-5 mt-2 bg-gray-200 rounded animate-pulse" />
									)}
								</div>
							</div>
							<p className="mt-2 mb-0 text-gray-600" data-cy="promocodeName">
								{promoCodeObj.coupon.name}
							</p>
						</>
					) : (
						<>
							<div className="relative block">
								<label htmlFor="promotionCode">
									<input
										id="promotionCode"
										name="promitionCode"
										data-testid="promoCodeInput"
										className={cn(
											'w-full h-10 py-0 pl-4 pr-10 border-2 rounded pac-target-input outline-none',
											{
												'border-gray-400 text-gray-600': !promoCodeError,
												'border-red text-red': promoCodeError,
											},
										)}
										ref={promoInput}
										onChange={() => {
											if (promoCodeError) {
												setPromoCodeError(false);
											}
										}}
										onKeyDown={(e) => {
											if (e.key === 'Enter') {
												handleFetchPromotionCode();
											}
										}}
										maxLength="25"
									/>
								</label>
								<button
									type="button"
									onClick={() => handleFetchPromotionCode()}
									className={cn(
										'absolute font-bold right-4 top-2 text-brand-500 bg-white pl-3',
										{
											'text-brand-500': !promoCodeError,
											'text-gray-500 cursor-default': promoCodeError,
										},
									)}
								>
									Apply
								</button>
							</div>
							{promoCodeError ? (
								<p className="mt-1 text-sm text-red">{promoCodeError}</p>
							) : (
								''
							)}
						</>
					)}
				</div>
			</div>
			<div className="pb-3 mb-3 border-b border-gray-400">
				<div className="flex flex-row justify-between text-gray-500 flex-nowrap">
					{quoteReady ? (
						<>
							<p>{taxLabel}</p>
							<span data-testid="taxAmount">
								{taxApplied ? `£${tax}` : 'Billing address required'}
							</span>
						</>
					) : (
						<div className="w-20 h-5 mt-2 bg-gray-200 rounded animate-pulse" />
					)}
				</div>
			</div>
			<div className="pb-3">
				<div className="flex flex-row justify-between flex-nowrap">
					<p className="mb-0 text-lg font-bold leading-7">Total due today</p>
					{quoteReady ? (
						<span className="mb-0 text-lg font-bold leading-7">£{price}</span>
					) : (
						<div className="w-20 h-5 mt-2 bg-gray-200 rounded animate-pulse" />
					)}
				</div>
			</div>
		</div>
	);
};

export default SummaryDetails;
