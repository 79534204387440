import React from 'react';
import ZapierLogo from '../../../../images/partners/zapier-logo.svg';

const IntegrationToast = ({ apiData }) => (
	<>
		<a
			role="button"
			href={apiData.create_url}
			target="_blank"
			rel="noopener noreferrer"
			className="hover:-translate-y-0.5 relative z-10 flex flex-col md:flex-row md:items-center gap-3 md:gap-8 px-4 py-3 mx-4 bg-white border border-gray-300 rounded-md shadow-md hover:cursor-pointer hover:shadow-lg hover:bg-zapier-light lg:mx-auto lg:w-3/4"
		>
			<div className="flex flex-row justify-between w-20 gap-4 shrink-0">
				<img
					src={apiData.steps[0].image}
					alt={`${apiData.steps[0].title} logo`}
					className="w-8"
				/>
				<img
					src={apiData.steps[1].image}
					alt={`${apiData.steps[1].title} logo`}
					className="w-8"
				/>
			</div>
			<div className="flex flex-col gap-2 mr-auto">
				<p className="font-bold">{apiData.title}</p>
				<div className="flex flex-row items-center gap-2">
					<img
						className="p-0.5 w-6 bg-white border border-gray-300 rounded-sm shadow-md"
						src={ZapierLogo}
						alt="Zapier logo"
					/>
					<p className="text-xs font-bold text-gray-700">
						{apiData.steps[0].title} + {apiData.steps[1].title}
					</p>
				</div>
			</div>
			<p className="px-3 py-2 mr-auto text-white break-normal rounded-md md:mr-0 bg-zapier-orange shrink-0 hover:shadow-lg hover:bg-zapier-dark">
				Try it
			</p>
		</a>
	</>
);

export default IntegrationToast;
