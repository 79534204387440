import React from 'react';
import ReactMarkdown from 'react-markdown';
import Stars from '../../../images/PPC/stars.svg';

const FeefoTestimonialsCms = ({ review, author, source }) => (
	<>
		<div className="max-w-md p-6 mx-4 mb-12 text-left bg-white rounded shadow-md testimonial">
			<div className="mb-3">
				<img src={Stars} alt="stars" />
			</div>
			<ReactMarkdown>{review}</ReactMarkdown>
		</div>
		<p className="max-w-md mx-auto text-sm font-bold text-left md:pl-4">{author}</p>
		<p className="max-w-md mx-auto text-sm font-bold text-left text-gray-500 md:pl-4">
			{source}
		</p>
	</>
);

export default FeefoTestimonialsCms;
