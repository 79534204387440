import React from 'react';

import questionMark from '../../../../images/buy-online/confirmation/question.png';
import webinar from '../../../../images/buy-online/confirmation/webinar.png';
import SixtyForty, { Left, Right } from '../../../../layouts/SixtyForty';
import Var from '../../../../components/Var';

const Card = ({ children, href }) => (
	<a href={href} className="p-6 space-y-3 text-center bg-white rounded shadow">
		{children}
	</a>
);

const Confirmation = () => (
	<SixtyForty headerStyle="none">
		<Left>
			<div className="mx-auto prose text-center">
				<h1>Thanks for purchasing BrightHR!</h1>
				<p>
					You’ve now joined the{' '}
					<strong>
						<Var name="numCompanies" />
					</strong>{' '}
					Bright businesses who trust us to make their HR admin quick, easy and
					stress-free.
				</p>
				<p>
					Your welcome email is on its way to your inbox right now. It’s full of essential
					information, like your <strong>order summary and setup instructions</strong>, so
					you can create your account at a time that suits you.
				</p>

				{window.sessionStorage.getItem('regUrl') !== null && (
					<p>
						Or you can{' '}
						<a
							href={window.sessionStorage.getItem('regUrl')}
							className="font-bold underline text-brand-500"
						>
							create your account now
						</a>
					</p>
				)}

				<p>
					<a href="/" data-cy="confirmationBackLink">
						Back to BrightHR
					</a>
				</p>
			</div>
		</Left>
		<Right>
			<div className="mr-auto ">
				<div className="prose">
					<h2>Get the most out of your BrightHR</h2>
					<p>
						Here at BrightHR, we’ve created lots of resources to help you get to grips
						with your new software.
					</p>
					<p>
						There’s our <a href="/help/">support hub</a>, where you can find quick
						answers to common BrightHR questions and download user guides for both you
						and your team.
					</p>
					<p>
						And then there’s our{' '}
						<a href="https://pages.brighthr.com/getting-started-webinars.html">
							introductory webinar series
						</a>
						. Each webinar lasts around 30 minutes and gives you an in-depth overview of
						your BrightHR account—helping you to get up and running in no time.
					</p>
				</div>

				<div className="grid grid-cols-2 gap-6 mt-10">
					<Card href="/help/">
						<img src={questionMark} className="h-16 mx-auto" alt="Question Mark" />

						<p className="font-bold leading-snug">Check out our support & FAQs page</p>
					</Card>
					<Card href="https://pages.brighthr.com/getting-started-webinars.html">
						<img src={webinar} className="h-16 mx-auto" alt="Book a webinar" />

						<p className="font-bold leading-snug">
							Sign up to our introductory webinars
						</p>
					</Card>
				</div>
			</div>
		</Right>
	</SixtyForty>
);

export default Confirmation;
