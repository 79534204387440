import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import Logo from '../../Logo';
import DemoCTA from '../DemoCTA';
import DemoCTASecondary from '../DemoCTA/secondary';

const Navbar = ({
	hasNavScrolled,
	primaryCtaText,
	dynamicPhoneNumbers,
	telephoneNumber,
	showModal,
	primaryCtaHref,
	secondaryCtaHref,
	secondaryCtaText,
	secondaryClassName,
	secondaryShow,
	theme = 'blue',
}) => {
	const [hasBurgerNavOpened, sethasBurgerNavOpened] = useState(false);
	const [rulerLoaded, setRulerLoaded] = useState(true);

	const hrefTelNumber = telephoneNumber ? telephoneNumber.replace(/\s/g, '') : '08007830319';

	const togglehasBurgerNavOpened = () => {
		sethasBurgerNavOpened(!hasBurgerNavOpened);
	};

	// eslint-disable-next-line no-nested-ternary
	const phoneNumber = dynamicPhoneNumbers ? (
		<div>
			{rulerLoaded ? (
				// eslint-disable-next-line jsx-a11y/anchor-has-content
				<a className="rulerdynamic" href="tel:08007830319" aria-label="contact number" />
			) : (
				<a href="tel:08007830319">{telephoneNumber}</a>
			)}
		</div>
	) : telephoneNumber ? (
		<a href={`tel:${hrefTelNumber}`}>{telephoneNumber}</a>
	) : (
		<a href="tel:08007830319">0800 783 0319</a>
	);

	useEffect(() => {
		// eslint-disable-next-line no-underscore-dangle
		if (typeof window.__raconfig === 'undefined') {
			setRulerLoaded(false);
		}
	}, [rulerLoaded]);

	const hasWhiteBg =
		(theme === 'white' && !hasNavScrolled) || (theme === 'blue' && hasNavScrolled);
	const hasBlueBg =
		(theme === 'blue' && !hasNavScrolled) || (theme === 'white' && hasNavScrolled);
	const hasDarkBlueBg = !hasNavScrolled && hasBurgerNavOpened && theme === 'blue';

	return (
		<header
			className={cn(
				'mx-auto select-none header sticky top-0 z-10 transition duration-300 ease-in-out',
				{
					'bg-brand-600': hasDarkBlueBg,
					'bg-brand-500': hasBlueBg,
					'bg-white shadow-md': hasWhiteBg,
				},
			)}
		>
			<div className="px-4 py-2 mx-auto max-w-7xl md:px-6">
				<div className="flex items-center justify-between w-full md:justify-between md:w-auto">
					<a data-cy="slug-navbar-logo" href="https://www.brighthr.com/">
						<Logo
							className={cn('w-32 md:w-auto', {
								'text-white': hasBlueBg,
								'text-brand-500': hasWhiteBg,
							})}
						/>
					</a>
					<div className="flex items-center">
						<div
							data-cy="slug-navbar-phone"
							className={cn(
								'mb-0 ml-2 font-bold leading-tight md:ml-0 md:flex items-center',
								{
									'text-white': hasBlueBg,
									'text-brand-500': hasWhiteBg,
								},
							)}
						>
							<svg
								width="16"
								height="15"
								viewBox="0 0 16 15"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
								className="hidden mr-2 align-text-top md:inline-block"
							>
								<path
									d="M9.096 3.358A2.917 2.917 0 0111.4 5.662M9.096 1.025a5.25 5.25 0 014.637 4.631m-.583 4.654v1.75a1.167 1.167 0 01-1.272 1.166 11.542 11.542 0 01-5.033-1.79 11.372 11.372 0 01-3.5-3.5 11.542 11.542 0 01-1.79-5.056 1.166 1.166 0 011.16-1.272h1.75a1.166 1.166 0 011.167 1.003c.073.56.21 1.11.408 1.64a1.166 1.166 0 01-.263 1.23l-.74.74a9.332 9.332 0 003.5 3.5l.74-.74a1.166 1.166 0 011.23-.263c.53.197 1.08.334 1.64.408a1.166 1.166 0 011.003 1.184z"
									stroke="currentColor"
									strokeWidth="1.5"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</svg>

							{phoneNumber}
						</div>
						<div
							className={cn(
								'flex items-center cursor-pointer minline-block md:hidden ml-4',
								{
									'text-white': hasBlueBg,
									'text-brand-500': hasWhiteBg,
								},
							)}
						>
							<svg
								width="32"
								height="33"
								viewBox="0 0 32 33"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
								onClick={togglehasBurgerNavOpened}
								className={hasBurgerNavOpened ? 'hidden' : ''}
							>
								<path
									d="M22 26.5642H6C3.794 26.5642 2 24.7702 2 22.5642C2 20.3582 3.794 18.5642 6 18.5642H22C24.206 18.5642 26 20.3582 26 22.5642C26 24.7702 24.206 26.5642 22 26.5642ZM6 20.5642C4.897 20.5642 4 21.4612 4 22.5642C4 23.6672 4.897 24.5642 6 24.5642H22C23.103 24.5642 24 23.6672 24 22.5642C24 21.4612 23.103 20.5642 22 20.5642H6Z"
									fill="currentColor"
								/>
								<path
									d="M26 14.5642H6C3.794 14.5642 2 12.7702 2 10.5642C2 8.35821 3.794 6.56421 6 6.56421H26C28.206 6.56421 30 8.35821 30 10.5642C30 12.7702 28.206 14.5642 26 14.5642ZM6 8.56421C4.897 8.56421 4 9.46121 4 10.5642C4 11.6672 4.897 12.5642 6 12.5642H26C27.103 12.5642 28 11.6672 28 10.5642C28 9.46121 27.103 8.56421 26 8.56421H6Z"
									fill="currentColor"
								/>
							</svg>
							<svg
								width="32"
								height="33"
								viewBox="0 0 32 33"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
								onClick={togglehasBurgerNavOpened}
								className={hasBurgerNavOpened ? '' : 'hidden'}
							>
								<path
									d="M24 28.5643C22.937 28.5643 21.925 28.1513 21.175 27.3893L16 22.2273L10.825 27.4023C10.075 28.1523 9.063 28.5773 8 28.5773C5.8 28.5773 4 26.7773 4 24.5773C4 23.5143 4.412 22.5023 5.175 21.7523L10.337 16.5643L5.175 11.3893C4.425 10.6393 4 9.62727 4 8.56427C4 6.36427 5.8 4.56427 8 4.56427C9.063 4.56427 10.075 4.97627 10.825 5.73927L16 10.9013L21.175 5.72627C21.925 4.97627 22.937 4.55127 24 4.55127C26.2 4.55127 28 6.35127 28 8.55127C28 9.61427 27.587 10.6263 26.825 11.3763L21.663 16.5643L26.838 21.7393C27.588 22.4893 28.013 23.5013 28.013 24.5643C28.001 26.7643 26.2 28.5643 24.001 28.5643H24ZM16 19.8023C16.25 19.8023 16.512 19.9023 16.712 20.0903L22.587 25.9653C22.962 26.3403 23.462 26.5523 24 26.5523C25.1 26.5523 26 25.6523 26 24.5523C26 24.0143 25.788 23.5143 25.413 23.1393L19.538 17.2643C19.151 16.8773 19.151 16.2393 19.538 15.8523L25.413 9.97727C25.788 9.60227 26 9.10227 26 8.56527C26 7.46527 25.1 6.56527 24 6.56527C23.462 6.56527 22.962 6.77827 22.587 7.15327L16.712 13.0283C16.325 13.4153 15.687 13.4153 15.3 13.0283L9.425 7.15327C9.05 6.77827 8.55 6.56527 8.013 6.56527C6.913 6.56527 6.013 7.46527 6.013 8.56527C6.013 9.10227 6.226 9.60227 6.601 9.97727L12.476 15.8523C12.863 16.2393 12.863 16.8773 12.476 17.2643L6.601 23.1393C6.226 23.5143 6.013 24.0143 6.013 24.5523C6.013 25.6523 6.913 26.5523 8.013 26.5523C8.55 26.5523 9.05 26.3403 9.425 25.9653L15.3 20.0903C15.488 19.9023 15.75 19.8023 16 19.8023Z"
									fill="currentColor"
								/>
							</svg>
						</div>
						<div
							data-cy="slug-navbar-cta"
							className="hidden w-full mt-3 text-center md:inline-block md:ml-4 md:mt-0 md:w-auto"
						>
							<DemoCTA
								primaryCtaHref={primaryCtaHref}
								primaryCtaText={primaryCtaText}
								showModal={showModal}
							/>

							{secondaryShow && (
								<DemoCTASecondary
									secondaryCtaHref={secondaryCtaHref}
									secondaryCtaText={secondaryCtaText}
									secondaryClassName={secondaryClassName}
								/>
							)}
						</div>
					</div>
				</div>
				<div
					className={`text-center mt-3 w-full md:ml-4 md:mt-0 md:w-auto ${
						hasBurgerNavOpened ? 'inline-block md:hidden' : 'hidden'
					}`}
				>
					<DemoCTA
						primaryCtaHref={primaryCtaHref}
						primaryCtaText={primaryCtaText}
						showModal={showModal}
					/>
					{secondaryShow && (
						<DemoCTASecondary
							secondaryCtaHref={secondaryCtaHref}
							secondaryCtaText={secondaryCtaText}
						/>
					)}
				</div>
			</div>
		</header>
	);
};

export default Navbar;
