/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import MasterLayout from '../Master';
import Footer from '../../components/Footer';

const PPCLayout = ({ children, fullWidth }) => (
	<MasterLayout>
		<div
			className={cn({
				'container p-6 mx-auto overflow-visible': !fullWidth,
			})}
		>
			<main className="mb-24 lg:mb-0">{children}</main>
			<footer className="">
				<Footer isPpcFooter />
			</footer>
		</div>
	</MasterLayout>
);

PPCLayout.propTypes = {
	children: PropTypes.node.isRequired,
};

export default PPCLayout;
