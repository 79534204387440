import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import CopyBlock from '../../CopyBlock';
import ZapierOverlayThree from '../../../images/partners/zapier_stage_3_overlay.svg';
import ZapierForm from '../../../images/partners/zapier_form.svg';
import CreateZap from '../../../images/partners/create_zap.svg';
import OnTick from '../../../images/partners/on_tick.svg';

const ThreeStep = ({ threeStep }) => (
	<>
		<div className="mx-auto max-w-screen-xxl">
			<div className="relative z-10 px-12 space-y-6 ">
				<h1 className="px-0 mb-8 text-3xl font-bold text-center lg:mb-16 xl:mb-24 lg:px-8 lg:text-left">
					How it works
				</h1>
				<div>
					<ol className="flex flex-col items-center gap-2 space-y-12 lg:space-y-0 lg:flex-row lg:items-start lg:justify-between">
						<li className="flex flex-col items-center w-full space-y-3 lg:px-8 md:w-3/4 lg:w-1/3 lg:px-12">
							<div className="mb-4 lg:order-1 lg:mb-0">
								<CopyBlock>{threeStep[0].textNode.childMdx.body}</CopyBlock>
							</div>
							<div className="relative max-w-md">
								<img
									src={ZapierForm}
									className="absolute bottom-0 left-0 z-10 w-24 -translate-x-6 -translate-y-3 border rounded-lg shadow-lg xl:top-0 sm:w-40 lg:w-24 xl:w-auto sm:-translate-y-8 xl:-translate-y-12 border-zapier-orange"
									alt="Zapier signup"
								/>
								<GatsbyImage
									image={threeStep[0].image.gatsbyImageData}
									className="object-cover w-full h-full mb-4 rounded-lg shadow-lg"
									alt={threeStep[0].image.alt}
								/>
							</div>
						</li>
						<li className="flex flex-col items-center w-full space-y-3 md:w-3/4 lg:w-1/3 lg:px-8 lg:translate-y-8">
							<div className="mb-4 lg:order-1 lg:mb-0">
								<CopyBlock>{threeStep[1].textNode.childMdx.body}</CopyBlock>
							</div>
							<div className="relative max-w-md">
								<img
									src={OnTick}
									className="absolute bottom-0 left-0 z-10 w-40 -translate-x-16 translate-y-8 rounded-lg"
									alt="Green tick"
								/>
								<img
									src={CreateZap}
									className="absolute right-0 z-10 translate-x-16 -translate-y-24 rounded-full top-24"
									alt="Create zap"
								/>
								<GatsbyImage
									image={threeStep[1].image.gatsbyImageData}
									className="object-cover w-full h-full mb-4 rounded-lg shadow-lg"
									alt={threeStep[1].image.alt}
								/>
							</div>
						</li>
						<li className="flex flex-col items-center w-full space-y-3 lg:px-8 md:w-3/4 lg:w-1/3 lg:px-12 lg:translate-y-16">
							<div className="mb-4 lg:order-1 lg:mb-0">
								<CopyBlock>{threeStep[2].textNode.childMdx.body}</CopyBlock>
							</div>
							<div className="relative max-w-md">
								<img
									src={ZapierOverlayThree}
									className="absolute top-0 right-0 z-10 translate-x-16 -translate-y-6 md:w-auto md:translate-x-24 lg:translate-x-12 lg:-translate-y-6"
									alt="Zapier overlay"
								/>
								<GatsbyImage
									image={threeStep[2].image.gatsbyImageData}
									className="object-cover w-full h-full mb-4 rounded-lg shadow-lg"
									alt={threeStep[2].image.alt}
								/>
							</div>
						</li>
					</ol>
				</div>
			</div>
			<svg
				width="1440"
				height="126"
				viewBox="0 0 1440 126"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
				className="absolute z-0 hidden w-screen -translate-y-72 lg:flex xxl:hidden"
			>
				<path
					d="M-79.6003 1.97107L287.524 1.97107C302.146 1.97107 314 13.8248 314 28.4471V28.4471C314 43.0694 325.854 54.9231 340.476 54.9231H695C714.054 54.9231 729.5 70.3693 729.5 89.4231V89.4231C729.5 108.477 744.946 123.923 764 123.923H1473.5"
					stroke="#FF4A00"
					strokeWidth="3"
					strokeLinecap="round"
					strokeDasharray="0.55 7.31"
				/>
			</svg>
		</div>
	</>
);

export default ThreeStep;
