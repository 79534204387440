import React from 'react';
import cn from 'classnames';

const Breadcrumbs = ({ children, className }) => (
	<ul
		itemScope
		itemType="http://schema.org/BreadcrumbList"
		className={cn('flex flex-row text-sm items-center', className)}
	>
		{children}
	</ul>
);

/* Use GatsbyLink when plaform move complete */
const Breadcrumb = ({ href, position, children, color }) =>
	href ? (
		<>
			<li
				itemProp="itemListElement"
				itemScope
				itemType="http://schema.org/ListItem"
				className={cn('hidden lg:block first:block', color || 'text-brand-500')}
			>
				<a
					href={href}
					itemID={href}
					itemScope
					itemProp="item"
					itemType="https://schema.org/WebPage"
				>
					<span itemProp="name">{children}</span>
				</a>
				<meta itemProp="position" content={position + 1} />
			</li>
			<li className="hidden mx-2 lg:block">&gt;</li>
		</>
	) : (
		<>
			{position > 1 && (
				<>
					<li className="mx-2 lg:hidden">&gt;</li>
					<li className="mx-2 lg:hidden">...</li>
				</>
			)}
			<li className="mx-2 lg:hidden">&gt;</li>

			<li
				itemProp="itemListElement"
				itemScope
				itemType="http://schema.org/ListItem"
				className="overflow-hidden font-bold align-middle whitespace-nowrap ellipsis"
			>
				<span itemProp="name">{children}</span>
				<meta itemProp="position" content={position + 1} />
			</li>
		</>
	);

export default Breadcrumbs;
export { Breadcrumb };
