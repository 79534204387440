import React, { useState } from 'react';
import MenuColumn from '../MenuColumn';
import MenuColumnSimple from '../SimpleMenuColumn';
import WhatsNew from '../WhatsNew';

function DesktopMenuComponent({ menuTitle, navLinks, featured, largerColumn }) {
	const [activeItem, setActiveItem] = useState(navLinks[0]);
	return (
		<div className="flex flex-row gap-2 p-2 bg-gray-200">
			{activeItem?.sublinks && (
				<div className="w-full min-w-[320px]">
					<MenuColumn
						activeItem={activeItem}
						setActiveItem={setActiveItem}
						navLinks={navLinks}
					/>
				</div>
			)}
			<div
				className={`w-full ${
					largerColumn ? 'min-w-[340px]' : 'min-w-[320px]'
				} rounded-md bg-white p-5`}
			>
				<MenuColumnSimple links={activeItem?.sublinks ? activeItem?.sublinks : navLinks} />
			</div>
			{featured.length > 0 &&
				(menuTitle.toLowerCase() === featured[0].placement ||
					featured[0].placement === 'everywhere') && (
					<div className="flex w-full min-w-[280px] flex-col gap-2 lg:mt-6 xl:mt-0">
						<WhatsNew data={featured} />
					</div>
				)}
		</div>
	);
}

export default DesktopMenuComponent;
