import React from 'react';
import { nanoid } from 'nanoid';
import Step from './Step';

const StepByStep = ({ steps, stepHeaderOne, stepHeaderTwo, stepHeaderThree }) => (
	<div className="flex flex-col content-center w-full px-6 md:mx-auto max-w-screen-xxl">
		<div className="flex flex-col gap-4 my-8 text-center lg:mt-12 lg:mb-20">
			{stepHeaderOne && <h2 className="text-3xl">{stepHeaderOne}</h2>}
			{stepHeaderTwo && <h3 className="text-2xl">{stepHeaderTwo}</h3>}
			{stepHeaderThree && <h4 className="text-xl">{stepHeaderThree}</h4>}
		</div>

		{steps.map((el) => (
			<Step key={nanoid()} step={el} index={steps.indexOf(el)} />
		))}
	</div>
);
export default StepByStep;
