import React, { useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import Cookies from 'js-cookie';
import { useLocation } from '@reach/router';

import { Modal, ModalBody } from '@brighthr/component-modal';
import { useFormik } from 'formik';
import loadScript from 'load-script2';
import Button from '@brighthr/component-button';
import submitMarketoForm from '../components/Marketo/submitMarketoForm';
import FormFooterLinks from '../components/Marketo/FormFooterLinks';
import Layout from '../layouts/Default';
import DatoSEO from '../components/DatoSEO';
import HrefLang from '../components/HrefLang';
import VideoBlock from '../components/CMSBlocks/VideoBlock';
import Cross from '../icons/Cross.svg';
import { LOCALE_PATHS } from '../utils/locale';

const ProductDemo = ({ data }) => {
	const { seoMetaTags, _allSeoLocales, productDemoElements, slug, locale } =
		data.datoCmsProductDemo;
	const location = useLocation();
	const [openForm, setOpenForm] = useState(false);
	const [allowPlay, setAllowPlay] = useState(false);
	const [submitPlay, setSubmitPlay] = useState(false);
	const standardisedLocale = LOCALE_PATHS[locale] || locale;

	useEffect(() => {
		// Preloads the Marketo form for background submission
		const loadForm = () =>
			loadScript('//app-lon04.marketo.com/js/forms2/js/forms2.min.js')
				.then(() => {
					window.MktoForms2.loadForm(
						'//app-lon04.marketo.com',
						'217-MIC-854',
						2796,
						(form) => {
							form.onSuccess(() => false);
						},
					);
				})
				.catch(() => {});

		if (window.requestIdleCallback) {
			window.requestIdleCallback(loadForm);
		} else {
			setTimeout(loadForm);
		}
	}, []);

	const initialValues = {
		FirstName: '',
		LastName: '',
		Email: '',
		LeadSource: '',
	};

	const triggerGate = () => {
		initialValues.LeadSource =
			standardisedLocale === 'ie' ? `IE - Web - See it in action` : `Web - See it in action`;
		setOpenForm(true);
	};

	const { handleChange, handleSubmit, setStatus, values, isSubmitting, status } = useFormik({
		initialValues,
		onSubmit: async (formData) => {
			submitMarketoForm(formData)
				.then(() => {
					Cookies.set('bhr_sf', '1', { expires: 3650 });
					setStatus('submitted');
					setOpenForm(false);
					setAllowPlay(true);
					setSubmitPlay(true);
				})
				.catch(() => {
					setStatus('error');
				});
		},
	});

	return (
		<Layout fullWidth>
			<HrefLang locales={_allSeoLocales} slug={slug} />
			<DatoSEO path={location.pathname} meta={seoMetaTags} />

			<div className="my-16">
				<VideoBlock
					imgSrc={productDemoElements[0].thumbnail.gatsbyImageData}
					alt={productDemoElements[0].thumbnail.alt}
					header={productDemoElements[0].header}
					title={productDemoElements[0].title}
					youtubeId={productDemoElements[0].youtubeVideoId}
					vimeoId={productDemoElements[0].vimeoId}
					subText={productDemoElements[0].videoSubTextNode.childMdx.body}
					isGated
					triggerGate={triggerGate}
					setAllowPlay={setAllowPlay}
					allowPlay={allowPlay}
					submitPlay={submitPlay}
				/>
			</div>

			{openForm && (
				<Modal width="sm" close={() => setOpenForm(false)} version="2">
					<ModalBody>
						<div className="relative px-4 py-5">
							<h2 className="mt-8 text-3xl font-bold leading-9 text-center text-brand-500">
								See BrightHR in action
							</h2>
							<p className="mb-10 text-lg leading-7 text-center">
								Watch a quick tour of BrightHR to see how much simpler work can be.
							</p>
							<button
								type="button"
								className="absolute -mt-4 -mr-4 top-5 right-5"
								onClick={() => setOpenForm(false)}
							>
								<Cross className="w-8 h-8" />
							</button>
							<form onSubmit={handleSubmit} className="max-w-xl mx-auto bg-white">
								{status === 'error' && (
									<p className="mb-5 font-bold text-legacy-error">
										Something went wrong. Please try submitting your details
										again.
									</p>
								)}
								<div className="md:flex md:flex-row md:justify-between">
									<label htmlFor="first" className="block w-full mb-4 md:mr-2">
										<strong>First name</strong>
										<input
											type="text"
											id="FirstName"
											name="FirstName"
											placeholder="First name"
											value={values.FirstName}
											onChange={handleChange}
											required
											className="block w-full px-4 py-3 mt-2 placeholder-gray-400 border border-gray-400 rounded"
										/>
									</label>
									<label htmlFor="last" className="block w-full mb-4 md:ml-2">
										<strong>Last name</strong>
										<input
											type="text"
											id="LastName"
											name="LastName"
											placeholder="Last name"
											value={values.LastName}
											onChange={handleChange}
											required
											className="block w-full px-4 py-3 mt-2 placeholder-gray-400 border border-gray-400 rounded"
										/>
									</label>
								</div>
								<label htmlFor="email" className="block mb-8">
									<strong>Email address</strong>
									<input
										type="email"
										id="Email"
										name="Email"
										placeholder="Email address"
										value={values.Email}
										onChange={handleChange}
										required
										className="block w-full px-4 py-3 mt-2 placeholder-gray-400 border border-gray-400 rounded"
									/>
								</label>

								<div className="flex justify-center mb-6 text-center">
									<Button
										id="Submit"
										type="submit"
										size="lg"
										disabled={isSubmitting}
										text="Watch demo"
									/>
								</div>

								<FormFooterLinks className="mt-2 text-sm text-left text-gray-900" />
							</form>
						</div>
					</ModalBody>
				</Modal>
			)}
		</Layout>
	);
};

export default ProductDemo;

export const query = graphql`
	query ProductDemo($slug: String, $locale: String!) {
		datoCmsProductDemo(locale: { eq: $locale }, slug: { eq: $slug }) {
			_allSeoLocales {
				locale
			}
			id
			locale
			seoMetaTags {
				...GatsbyDatoCmsSeoMetaTags
			}
			slug
			productDemoElements {
				... on DatoCmsVideoBlock {
					blocktype
					id
					header
					title
					videoSubTextNode {
						childMdx {
							body
						}
					}
					thumbnail {
						alt
						gatsbyImageData(imgixParams: { auto: "" }, layout: CONSTRAINED)
					}
					vimeoId
					youtubeVideoId
				}
			}
		}
	}
`;
