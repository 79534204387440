import React from 'react';
import Newsletter from './newsletter';

const NewsletterFooter = () => (
	<>
		<div className="w-full bg-brand-100">
			<div className="container relative p-6 mx-auto text-center lg:flex lg:items-center lg:text-left lg:py-12">
				<h3
					data-cy="newsletter-cta"
					className="mb-8 text-xl font-bold lg:w-1/3 lg:mb-0 lg:mr-auto"
				>
					Stay in the know{' '}
					<span className="block text-primary-700">
						With HR updates, exclusive offers, and new product launches you don&apos;t
						want to miss.
					</span>
				</h3>
				<div className="w-full lg:w-1/2">
					<Newsletter styling="footer" />
				</div>
			</div>
		</div>
	</>
);

export default NewsletterFooter;
