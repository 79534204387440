/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import Cross from '../../icons/Cross.svg';

const getSource = (vimeoId, youtubeId) => {
	if (youtubeId) {
		return `https://www.youtube.com/embed/${youtubeId}?autoplay=1&modestbranding=true&rel=0`;
	}

	if (vimeoId) {
		return vimeoId.includes('?')
			? `https://player.vimeo.com/video/${vimeoId}`
			: `https://player.vimeo.com/video/${vimeoId}?h=21e18e0129&autoplay=1`;
	}

	return '';
};

const VideoModal = ({ youtubeId, vimeoId, videoTitle, onClose }) => (
	<div
		className="fixed top-0 bottom-0 left-0 right-0 flex flex-col items-center justify-center p-6 z-100 bg-gray-overlay"
		onClick={onClose}
	>
		<div
			className="relative items-stretch w-full max-w-4xl shadow aspect-video"
			onClick={(e) => e.stopPropagation()}
		>
			<iframe
				id="ytplayer"
				title={videoTitle}
				type="text/html"
				className="absolute top-0 bottom-0 left-0 right-0 w-full h-full"
				src={getSource(vimeoId, youtubeId)}
				frameBorder="0"
				allow="autoplay"
			/>
			<button type="button" className="absolute top-0 right-0 -mt-4 -mr-4" onClick={onClose}>
				<Cross className="w-8 h-8 text-white" />
			</button>
		</div>
	</div>
);

export default VideoModal;
/* eslint-enable jsx-a11y/click-events-have-key-events */
/* eslint-enable jsx-a11y/no-static-element-interactions */
