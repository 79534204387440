import React from 'react';
import { graphql } from 'gatsby';
import { useLocation } from '@reach/router';
import DefaultLayout from '../layouts/Default';
import MeetOurCustomers from '../components/MeetOurCustomers';
import BlobTestimonialTwo from '../components/CMSBlocks/BlobTestimonialTwo';
import FooterCTA from '../components/CMSBlocks/FooterCTA';
import VideoHero from '../components/CMSBlocks/VideoHero';
import TabbedCarousel from '../components/CMSBlocks/TabbedCarousel';
import BackgroundBrightLogo from '../images/vectors/BackgroundBrightLogo.svg';
import DatoSEO from '../components/DatoSEO';
import VerticalImageScrollWithCopy from '../components/CMSBlocks/VerticalImageScrollWithCopy';
import { localisedRead } from '../utils/locale';
import HrefLang from '../components/HrefLang';
import VideoBlock from '../components/CMSBlocks/VideoBlock';
import ImageWithCopy from '../components/CMSBlocks/ImageWithCopy';
import HighlightedCopy from '../components/CMSBlocks/HighlightedCopy';

const BusinessSizePage = ({ data, pageContext }) => {
	const location = useLocation();
	const {
		businessSizeElements,
		videoHero,
		meetOurCustomer,
		carouselHeader,
		tabbedCarousel,
		footerCta,
		seoMetaTags,
		verticalCarouselParent,
		verticalCarouselSubtitle,
		verticalCarouselTitle,
		_allSlugLocales,
		_allSeoLocales,
	} = data.datoCmsBusinessSize;

	return (
		<>
			<HrefLang
				locales={_allSeoLocales}
				slug={(hreflang) => `who-we-serve/${localisedRead(_allSlugLocales, hreflang)}`}
				lang={pageContext.locale}
			/>
			<DatoSEO path={location.pathname} meta={seoMetaTags} lang={pageContext.locale} />

			<div className="max-w-full">
				<div className="relative overflow-hidden bg-gradient-to-b from-brand-200">
					<img
						className="absolute left-0 right-0 z-0 mx-auto translate-x-64 opacity-50"
						src={BackgroundBrightLogo}
						alt="Background bright logo"
					/>
					<div className="relative">
						{videoHero.length > 0 && (
							<VideoHero
								title={videoHero[0].title}
								text={videoHero[0].textNode.childMdx.body}
								youtubeId={videoHero[0].youtubeId}
								vimeoId={videoHero[0].vimeoId}
								thumbnail={videoHero[0].thumbnail.gatsbyImageData}
								underneathText={videoHero[0].underneathText}
							/>
						)}
					</div>

					{meetOurCustomer.length > 0 && (
						<MeetOurCustomers
							logos={meetOurCustomer[0].logos}
							cta={meetOurCustomer[0].ctaNode.childMdx.body}
						/>
					)}
				</div>

				<TabbedCarousel slides={tabbedCarousel} carouselHeader={carouselHeader} />

				<VerticalImageScrollWithCopy
					verticalCarouselParent={verticalCarouselParent}
					title={verticalCarouselTitle}
					subtitle={verticalCarouselSubtitle}
				/>

				{businessSizeElements.map((el) => (
					<div className="mb-6 md:mb-12 last:mb-0" key={el.id}>
						{el.blocktype === 'BlobTestimonialTwo' && (
							<div>
								<BlobTestimonialTwo
									imgSrc={el.blobTestimonialImage.gatsbyImageData}
									alt={el.blobTestimonialImage.alt}
									text={el.textNode.childMdx.body}
									quoteSource={el.quoteSourceNode.childMdx.body}
								/>
							</div>
						)}
						{el.blocktype === 'VideoBlock' && (
							<VideoBlock
								imgSrc={el.thumbnail.gatsbyImageData}
								alt={el.thumbnail.alt}
								header={el.header}
								title={el.title}
								youtubeId={el.youtubeVideoId}
								vimeoId={el.vimeoId}
								subText={el.videoSubTextNode.childMdx.body}
							/>
						)}
						{el.blocktype === 'ImageWithCopy' && (
							<ImageWithCopy
								imgSrc={el.imageWithCopyImage.gatsbyImageData}
								alt={el.imageWithCopyImage.alt}
								text={el.textNode.childMdx.body}
								imagePosition={el.imagePosition}
							/>
						)}
						{el.blocktype === 'HighlightedCopy' && (
							<HighlightedCopy version={el.version || 'v1'}>
								{el.textNode.childMdx.body}
							</HighlightedCopy>
						)}
					</div>
				))}

				{footerCta.length > 0 && (
					<FooterCTA
						cta={footerCta[0].ctaNode.childMdx.body}
						header={footerCta[0].header}
					/>
				)}
			</div>
		</>
	);
};

const WrappedBusinessSizePage = (props) => (
	<DefaultLayout fullWidth>
		<BusinessSizePage {...props} />
	</DefaultLayout>
);

export default WrappedBusinessSizePage;

export const query = graphql`
	query businessSizeQuery($slug: String, $locale: String!) {
		datoCmsBusinessSize(locale: { eq: $locale }, slug: { eq: $slug }) {
			_allSeoLocales {
				locale
			}
			_allSlugLocales {
				locale
				value
			}
			seoMetaTags {
				...GatsbyDatoCmsSeoMetaTags
			}
			businessSizeElements {
				... on DatoCmsBlobTestimonialTwo {
					textNode {
						childMdx {
							body
						}
					}
					blobTestimonialImage {
						gatsbyImageData
					}
					id
					blocktype
					quoteSourceNode {
						childMdx {
							body
						}
					}
				}
				... on DatoCmsHighlightedCopy {
					id
					blocktype
					version
					textNode {
						childMdx {
							body
						}
					}
				}
				... on DatoCmsImageWithCopy {
					id
					blocktype
					imagePosition
					textNode {
						childMdx {
							body
						}
					}
					imageWithCopyImage {
						alt
						gatsbyImageData(layout: CONSTRAINED, width: 590)
					}
				}
				... on DatoCmsVideoBlock {
					blocktype
					id
					header
					title
					videoSubTextNode {
						childMdx {
							body
						}
					}
					thumbnail {
						alt
						gatsbyImageData(imgixParams: { auto: "" }, layout: CONSTRAINED)
					}
					vimeoId
					youtubeVideoId
				}
			}
			meetOurCustomer {
				id
				blocktype
				logos {
					alt
					gatsbyImageData
					url
				}
				ctaNode {
					childMdx {
						body
					}
				}
			}
			videoHero {
				title
				id
				underneathText
				youtubeId
				vimeoId
				blocktype
				textNode {
					childMdx {
						body
					}
				}
				thumbnail {
					gatsbyImageData
				}
			}
			carouselHeader
			tabbedCarousel {
				title
				link
				descriptionNode {
					childMdx {
						body
					}
				}
				image {
					url
				}
			}
			footerCta {
				ctaNode {
					childMdx {
						body
					}
				}
				header
			}
			verticalCarouselTitle
			verticalCarouselParent {
				... on DatoCmsImageWithCopy {
					id
					blocktype
					imagePosition
					textNode {
						childMdx {
							body
						}
					}
					backgroundColour
					imageWithCopyImage {
						gatsbyImageData(
							layout: CONSTRAINED
							width: 590
							imgixParams: { fm: "webp" }
						)
						url
						alt
					}
				}
			}
		}
	}
`;
