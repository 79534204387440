import React from 'react';
import { buildLink } from '../../utils/locale';

import Twitter from '../../images/book-a-demo/twitter.svg';
import Linkedin from '../../images/book-a-demo/linkedin.svg';
import Facebook from '../../images/book-a-demo/facebook.svg';
import YouTube from '../../images/book-a-demo/youtube.svg';

const SocialIcons = () => (
	<div>
		<a href="https://twitter.com/BrightHR_">
			<img src={Twitter} alt="Twitter" width={32} height={32} className="inline-block mr-4" />
		</a>
		<a href="https://www.linkedin.com/company/brighthr">
			<img
				src={Linkedin}
				alt="Linkedin"
				width={32}
				height={32}
				className="inline-block mr-4"
			/>
		</a>
		<a href="https://www.facebook.com/BrightHRcom?fref=ts">
			<img
				src={Facebook}
				alt="Facebook"
				width={32}
				height={32}
				className="inline-block mr-4"
			/>
		</a>
		<a href="https://www.youtube.com/c/BrighthrTV">
			<img src={YouTube} alt="Youtube" width={32} height={32} className="inline-block" />
		</a>
	</div>
);

const MinimalFooter = ({ locale, additionalClasses }) => (
	<footer
		className={`mb-6 mt-16 text-center mx-auto max-w-[375px] md:max-w-full ${additionalClasses}`}
	>
		<ul>
			<li className="mb-2 font-bold lg:inline-block">
				<a href={buildLink(locale, 'contact')}>Contact Us</a>
				<span className="hidden lg:inline-block lg:mx-3">&bull;</span>
			</li>
			<li className="mb-2 font-bold lg:inline-block">
				<a href={buildLink(locale, 'help')}>Help Center</a>
				<span className="hidden lg:inline-block lg:mx-3">&bull;</span>
			</li>
			<li className="mb-2 font-bold lg:inline-block">
				<a href={buildLink(locale, 'about-us')}>About Us</a>
			</li>
		</ul>
		<p className="md:inline-block">
			<a
				href={buildLink(locale, 'terms')}
				className="block mb-2 font-bold md:inline-block md:font-normal"
			>
				Terms & privacy
			</a>
		</p>
		<span className="hidden mx-2 md:inline-block">|</span>
		<p className="mb-4 md:inline-block">Copyright © {new Date().getFullYear()} BrightHR</p>
		<SocialIcons />
	</footer>
);

export default MinimalFooter;
