import React, { useRef, useMemo } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import cn from 'classnames';
import { graphql } from 'gatsby';
import { useLocation } from '@reach/router';
import DefaultLayout from '../layouts/Default';
import useFuse from '../components/useFuse';
import SearchBar from '../components/SearchBar';
import Breadcrumbs, { Breadcrumb } from '../components/Breadcrumbs';
import Question, { format, isValidForLocale } from '../components/Support/Question';

import DatoSEO from '../components/DatoSEO';
import HrefLang from '../components/HrefLang';
import { buildLink, SUPPORTED_LOCALES } from '../utils/locale';
import LogSupport from '../components/Support/LogSupportCase';
import PhoneContact from '../components/Support/PhoneContact';
import Pagination from '../components/Pagination';
import scrollDirection from '../utils/scrollDirection';
import usePagination from '../hooks/usePagination';
import usePaginatedSearch from '../hooks/usePaginatedSearch';

const fuseOptions = {
	keys: [
		{
			name: 'question',
			weight: 0.7,
		},
		{
			name: 'answer',
			weight: 0.3,
		},
	],
};

const SupportTopics = ({ pageContext, data }) => {
	const listRef = useRef();
	const searchInputRef = useRef();
	const location = useLocation();
	const scroll = scrollDirection();
	const questions = data.allDatoCmsSupportQuestion.nodes
		.filter((q) => isValidForLocale(q, pageContext.locale))
		.map((q) => format(q, pageContext.locale));

	const { result, search } = useFuse(questions, fuseOptions);
	const categories = useMemo(
		() =>
			data.allDatoCmsSupportQuestion.nodes
				.filter(
					(q) =>
						isValidForLocale(q, pageContext.locale) && q.locale === pageContext.locale,
				)
				.reduce((accum, node) => {
					const { name } = node.subTopic;

					if (accum[name]) {
						// eslint-disable-next-line no-param-reassign
						accum[name].count++;
					} else {
						// eslint-disable-next-line no-param-reassign
						accum[name] = {
							...node.subTopic,
							count: 1,
						};
					}

					return accum;
				}, {}),
		[data.allDatoCmsSupportQuestion.nodes, pageContext.locale],
	);

	const PER_PAGE = 9;
	const { page, pageNumbers, currentPageResults } = usePagination(result.length, PER_PAGE);
	const { searchInput, clearSearch, showSearchResults, handleKeyDown, delayedURLUpdate } =
		usePaginatedSearch({
			searchInputRef,
			search,
		});

	return (
		<DefaultLayout fullWidth>
			<HrefLang locales={SUPPORTED_LOCALES} slug={`help/faqs/${pageContext.topic.slug}`} />
			<DatoSEO path={location.pathname} meta={data.datoCmsSupportTopic.seoMetaTags} />
			<div className="px-6">
				{' '}
				<section className="flex flex-row max-w-screen-xl mx-auto bg-right-top bg-no-repeat bg-contain">
					<div className="w-full px-6 py-8 grow md:pr-12">
						<Breadcrumbs className="hidden mb-4 md:flex">
							<Breadcrumb href={buildLink(pageContext.locale, 'help')} position={0}>
								Support
							</Breadcrumb>
							<Breadcrumb
								href={buildLink(pageContext.locale, 'help/faqs')}
								position={1}
							>
								FAQs
							</Breadcrumb>
							<Breadcrumb position={2}>{pageContext.topic.name}</Breadcrumb>
						</Breadcrumbs>
						<h1 className="mb-3 text-2xl font-bold lg:text-4xl md:mt-12">
							{pageContext.topic.name}
						</h1>
						<p className=" lg:text-xl">{data.datoCmsSupportTopic.headerDescription}</p>
					</div>
					<StaticImage
						data-cy="support-faqs-hero-image"
						src="../images/support/faqs-hero.png"
						alt="faqs banner"
						className="hidden w-4/12 ml-auto shrink-0 md:block"
						placeholder="eager"
						aria-hidden
					/>
				</section>
				<div className="max-w-screen-xl mx-auto mt-8 md:mt-14">
					<SearchBar
						listRef={listRef}
						className="mb-8"
						value={searchInput}
						onChange={(e) => {
							showSearchResults(e, false);
							delayedURLUpdate(e);
						}}
						placeholder="What do you need help with?"
						ref={searchInputRef}
						clearSearch={clearSearch}
						scroll={scroll}
						onKeyDown={handleKeyDown}
					/>
					<div
						className={cn(
							'rounded bg-brand-100',
							searchInput.length >= 3 ? 'block p-8 pt-6 mb-8' : 'hidden',
						)}
						ref={listRef}
					>
						{searchInput.length >= 3 && (
							<>
								<h3 className="mb-6">
									Search results{' '}
									<span className="font-bold">({result.length})</span>
								</h3>
								<ul className="space-y-4">
									{currentPageResults(result).map((question) => (
										<li key={question.id}>
											<Question {...question} locale={pageContext.locale} />
										</li>
									))}
								</ul>
								{result.length > PER_PAGE && (
									<Pagination
										page={page}
										pageNumbers={pageNumbers}
										searchInput={searchInput}
										className="mt-6 mb-0"
									/>
								)}
							</>
						)}
					</div>
					{searchInput.length < 3 && (
						<section>
							<h2 className="mt-6 mb-8 text-2xl text-center">FAQs by category</h2>
							<ul className="flex flex-wrap mt-0 md:-mx-4">
								{Object.keys(categories).map((k) => {
									if (k === 'null') {
										return;
									}

									const cat = categories[k];

									return (
										<li className="w-full mr-4 sm:w-auto md:mb-0 md:mr-0 md:w-1/2 lg:w-1/3 md:p-4">
											<a
												href={buildLink(
													pageContext.locale,
													`help/faqs/${pageContext.topic.slug}/${cat.slug}`,
												)}
												className="relative flex md:h-40"
											>
												<img
													src={cat.image.fixed?.src}
													alt={cat.name}
													className="absolute hidden object-cover w-full h-full md:block"
												/>
												<div className="relative z-10 py-2 mt-auto bg-white md:px-6 text-brand-500 md:text-gray-700">
													<strong>{cat.name}</strong> ({cat.count}){' '}
													<svg
														width="12"
														height="7"
														viewBox="0 0 24 14"
														fill="none"
														xmlns="http://www.w3.org/2000/svg"
														className="inline-block ml-2"
													>
														<path
															d="M17.2473 0L16.112 1.13527L20.927 5.95025H0V7.55524H20.927L16.112 12.3702L17.2473 13.5055L24 6.75381L17.2473 0.00213984V0Z"
															fill="#3DB0F7"
														/>
													</svg>
												</div>
											</a>
										</li>
									);
								})}
							</ul>
						</section>
					)}

					<section className="flex flex-col pt-12 mt-12 mb-8 border-t md:items-stretch md:flex-row">
						<div className="w-full md:mr-3 md:6/12">
							<LogSupport />
						</div>
						<div className="w-full md:ml-3 md:6/12">
							<PhoneContact topicName={pageContext.topic.name} />
						</div>
					</section>
				</div>
			</div>
		</DefaultLayout>
	);
};

export default SupportTopics;

export const query = graphql`
	query supportTopicPage($locale: String!, $topicId: String!) {
		datoCmsSupportTopic(originalId: { eq: $topicId }) {
			seoMetaTags {
				...GatsbyDatoCmsSeoMetaTags
			}
			headerDescription
			webinar {
				monday
				tuesday
				wednesday
				thursday
				friday
				time
				name
				marketoFormId
				descriptionNode {
					childMdx {
						body
					}
				}
			}
		}
		allDatoCmsSupportQuestion(
			filter: { locale: { eq: $locale }, topic: { originalId: { eq: $topicId } } }
		) {
			nodes {
				locale
				showInAu
				showInCa
				showInIe
				showInNz
				showInUk
				topic {
					name
				}
				subTopic {
					slug
					name
					image {
						fixed(width: 650, imgixParams: { auto: "compress", q: 60 }) {
							srcSet
							src
						}
					}
				}
				...QuestionCard
			}
		}
	}
`;
