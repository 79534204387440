import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import cn from 'classnames';

import Header from '../../components/Header';
import MinimalHeader from '../../components/MinimalHeader';
import MasterLayout from '../Master';
import LangNotify from '../../components/LangNotify';

export const Left = ({ children }) => (
	<div className="px-6 py-10 mx-auto lg:ml-auto lg:mr-6 lg:w-7/12 lg:p-12 lg:pl-6 xl:pl-6 xl:p-20 lg:max-w-xl xl:max-w-3xl">
		{children}
	</div>
);

export const Right = ({ children }) => (
	<div className="px-6 py-10 lg:w-5/12 bg-brand-100 lg:p-12 xl:p-20">
		<div className="mx-auto lg:mr-auto lg:ml-6 xl:max-w-xl">{children}</div>
	</div>
);

const SixtyForty = ({ children, headerStyle = 'full' }) => {
	const data = useStaticQuery(graphql`
		query {
			site {
				siteMetadata {
					title
				}
			}
		}
	`);

	return (
		<MasterLayout>
			{headerStyle === 'full' && <Header siteTitle={data.site.siteMetadata.title} />}
			{headerStyle === 'minimal' && <MinimalHeader />}
			<LangNotify />
			<main className={cn('flex flex-col lg:flex-row')}>{children}</main>
		</MasterLayout>
	);
};

SixtyForty.propTypes = {
	children: PropTypes.node.isRequired,
};

export default SixtyForty;
