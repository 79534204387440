import React from 'react';
import { MDXProvider } from '@mdx-js/react';

import Heading from './components/Heading';
import {
	ButtonLink,
	ButtonGrid,
	ButtonGridLink,
	VideoButton,
	PlayStoreButton,
	AppStoreButton,
	ButtonRow,
} from './components/Buttons';
import {
	NumCompanies,
	SalesNumber,
	SupportEmail,
	SupportNumber,
} from './components/globalVariables';
import Blockquote from './components/Blockquote';
import Anchor from './components/Anchor';
import UL from './components/UL';
import OL from './components/OL';
import Text from './components/Text';
import Image from './components/Image';
import CallOutBox from './components/CallOutBox';
import Ribbon from './components/Ribbon';

const CopyBlockProvider = ({ children }) => (
	<MDXProvider
		components={{
			a: Anchor,
			h1: (props) => <Heading {...props} level="h2" />,
			h2: (props) => <Heading {...props} level="h2" />,
			h3: (props) => <Heading {...props} level="h3" />,
			h4: (props) => <Heading {...props} level="h4" />,
			h5: (props) => <Heading {...props} level="h5" />,
			img: Image,
			Image,
			ul: UL,
			ol: OL,
			p: Text,
			ButtonLink,
			ButtonGrid,
			ButtonRow,
			VideoButton,
			AppStoreButton,
			PlayStoreButton,
			NumCompanies,
			SalesNumber,
			SupportEmail,
			SupportNumber,
			ButtonGridLink,
			blockquote: Blockquote,
			CallOutBox,
			Ribbon,
		}}
	>
		{children}
	</MDXProvider>
);

export default CopyBlockProvider;
