import { graphql, Link } from 'gatsby';
import React, { useState, useEffect } from 'react';
import { remark } from 'remark';
import remarkRehype from 'remark-rehype/lib';
import remarkParse from 'remark-parse/lib';
import rehypeStringify from 'rehype-stringify';

import LinkButton from '@brighthr/component-linkbutton';
import { useLocation } from '@reach/router';
import DefaultLayout from '../layouts/Default';
import CopyBlock from '../components/CopyBlock';
import DatoSEO from '../components/DatoSEO';
import ArticleProvider from '../components/CopyBlock/ArticleProvider';
import { buildLink, LOCALE_PATHS } from '../utils/locale';

const JobPostPage = ({ data, pageContext }) => {
	const location = useLocation();
	const locale = LOCALE_PATHS[pageContext.locale];
	let mailTo = 'mailto:careers@brighthr.com';

	const prefix = 'mailto:';
	switch (locale) {
		case 'au':
			mailTo = `${prefix}careers@brighthr.com.au`;
			break;
		case 'nz':
			mailTo = `${prefix}careers@brighthr.com.au`;
			break;
		case 'ca':
			mailTo = `${prefix}careers@peninsula-ca.com`;
			break;
		case 'ie':
			mailTo = `${prefix}careers@brighthr.com`;
			break;
		default:
			mailTo = `${prefix}careers@brighthr.com`;
	}

	const addressSchema = {
		en: {
			'@type': 'PostalAddress',
			streetAddress: 'The Peninsula, Victoria Place',
			addressLocality: 'Manchester',
			addressRegion: 'England',
			postalCode: 'M4 4FB',
			addressCountry: 'GB',
		},
		ca: {
			'@type': 'PostalAddress',
			streetAddress: '123 Front Street West, Suite 200',
			addressLocality: 'Toronto',
			addressRegion: 'Ontario',
			postalCode: 'M5J 2M2',
			addressCountry: 'CA',
		},
		au: {
			'@type': 'PostalAddress',
			streetAddress: 'Level 12, 700 Harris Street',
			addressLocality: 'Ultimo',
			addressRegion: 'New South Wales',
			postalCode: '2007',
			addressCountry: 'AU',
		},
		ie: {
			'@type': 'PostalAddress',
			streetAddress: 'Block W, East Point Business Park, Alfie Bryne Rd',
			addressLocality: 'Dublin',
			postalCode: 'Dublin 3',
			addressCountry: 'IE',
		},
		nz: {
			'@type': 'PostalAddress',
			streetAddress: '1 Nelson St',
			addressLocality: 'Auckland',
			postalCode: '1010',
			addressCountry: 'NZ',
		},
	};

	const currencySchema = {
		en: 'GBP',
		ca: 'CAD',
		au: 'AUD',
		ie: 'EUR',
		nz: 'NZD',
	};

	const [autoSchema, setAutoSchema] = useState([]);

	useEffect(() => {
		async function createAutoSchema() {
			const jobDescription = async (descriptionMarkdown) => {
				const thing = await remark()
					.use(remarkParse)
					.use(remarkRehype)
					.use(rehypeStringify)
					.process(descriptionMarkdown);
				return String(thing);
			};

			const autoSchemaObj = {
				title: data.datoCmsJobPost.jobTitle,
				description: `${await jobDescription(data.datoCmsJobPost.description)}`,
				datePosted: data.datoCmsJobPost.meta.firstPublishedAt,
				employmentType: 'FULL_TIME',
				hiringOrganization: {
					'@type': 'Organization',
					name: data.site.siteMetadata.title,
					logo: data.site.siteMetadata.logo,
				},
				jobLocation: {
					'@type': 'Place',
					address: addressSchema[locale],
				},
				baseSalary: {
					'@type': 'MonetaryAmount',
					currency: currencySchema[locale],
					value: {
						'@type': 'QuantitativeValue',
						value: data.datoCmsJobPost.salary,
						unitText: 'YEAR',
					},
				},
			};

			setAutoSchema(await autoSchemaObj);
		}
		createAutoSchema();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<DefaultLayout fullWidth>
			<DatoSEO
				meta={data.datoCmsJobPost.seoMetaTags}
				path={location.pathname}
				schemaType="JobPosting"
				autoSchema={autoSchema}
			/>
			<div className="relative py-16 overflow-hidden bg-white">
				<div className="container px-6 mx-auto ">
					<Link to={buildLink(locale, `careers`)} className="flex items-center">
						<svg
							xmlns="http://www.w3.org/2000/svg"
							className="w-6 h-6 mr-2"
							fill="none"
							viewBox="0 0 24 24"
							stroke="currentColor"
						>
							<path
								strokeLinecap="round"
								strokeLinejoin="round"
								strokeWidth={2}
								d="M10 19l-7-7m0 0l7-7m-7 7h18"
							/>
						</svg>
						Back to jobs
					</Link>
				</div>

				<div className="hidden lg:block lg:relative lg:inset-y-0 lg:h-full lg:w-full">
					<div className="relative h-full mx-auto text-lg max-w-prose" aria-hidden="true">
						<svg
							className="absolute translate-x-32 top-12 left-full"
							width="404"
							height="384"
							fill="none"
							viewBox="0 0 404 384"
						>
							<defs>
								<pattern
									id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
									x="0"
									y="0"
									width="20"
									height="20"
									patternUnits="userSpaceOnUse"
								>
									<rect
										x="0"
										y="0"
										width="4"
										height="4"
										className="text-gray-200"
										fill="currentColor"
									/>
								</pattern>
							</defs>
							<rect
								width="404"
								height="384"
								fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)"
							/>
						</svg>
					</div>
				</div>
				<div className="relative px-4 sm:px-6 lg:px-8">
					<div className="mx-auto text-lg max-w-prose">
						<div>
							<p className="block text-base font-bold tracking-wide text-center uppercase text-brand-500">
								{data.datoCmsJobPost.department}
							</p>
							<h1 className="block mt-2 text-3xl font-extrabold leading-8 tracking-tight text-center text-gray-900 sm:text-4xl">
								{data.datoCmsJobPost.jobTitle}
							</h1>
							<p className="block mt-3 text-base font-bold text-center text-gray-600 ">
								Salary: {data.datoCmsJobPost.salary} • Location:{' '}
								{data.datoCmsJobPost.location}
							</p>

							<div className="m-auto mt-6 text-center w-fit">
								<LinkButton
									outlineButton
									text="Apply now"
									size="lg"
									href={`${mailTo}?subject=${data.datoCmsJobPost.jobTitle} - ${data.datoCmsJobPost.originalId} - ${data.datoCmsJobPost.location}`}
								/>
							</div>
						</div>
					</div>
					<div className="max-w-3xl mx-auto mt-12">
						<ArticleProvider>
							<CopyBlock>
								{data.datoCmsJobPost.descriptionNode.childMdx.body}
							</CopyBlock>
						</ArticleProvider>
					</div>
				</div>
			</div>
		</DefaultLayout>
	);
};

export default JobPostPage;

export const query = graphql`
	query seoAndJobaPost($locale: String!, $originalId: String!) {
		site {
			siteMetadata {
				title
				siteUrl
				logo
			}
		}

		datoCmsJobPost(locale: { eq: $locale }, originalId: { eq: $originalId }) {
			originalId
			seoMetaTags {
				tags
			}
			meta {
				firstPublishedAt
			}
			jobTitle
			salary
			description
			descriptionNode {
				childMdx {
					body
				}
			}
			department
			location
		}
	}
`;
