import React from 'react';
import LinkButton from '@brighthr/component-linkbutton';
import { GatsbyImage } from 'gatsby-plugin-image';
import WebinarPopupContent from './WebinarPopupContent';

const LiveWebinarPopup = ({ upcomingWebinar, href }) => (
	<>
		<div className="relative block px-8 pt-8 lg:hidden">
			<WebinarPopupContent
				subHeading="Join our Webinar on"
				heading={upcomingWebinar.title}
				description={
					upcomingWebinar.speakerOne.externalSpeaker ? (
						<p className="mb-4 leading-tight">{upcomingWebinar.description}</p>
					) : (
						<p className="mb-4 leading-tight">
							{upcomingWebinar.description} Hosted by{' '}
							<span className="font-bold">{upcomingWebinar.speakerOne.fullName}</span>
							{upcomingWebinar.speakerTwo?.fullName && (
								<>
									{' '}
									and
									<span className="font-bold">
										{' '}
										{upcomingWebinar.speakerTwo.fullName}
									</span>
								</>
							)}
							.
						</p>
					)
				}
				date={`${upcomingWebinar.liveWebinarDate}T${upcomingWebinar.liveWebinarTime}:00`}
				CTA={
					<div className="absolute left-0 right-0 z-10 m-auto text-center w-fit">
						<LinkButton
							href={`${href}?utm_source=website&utm_medium=popup&utm_content=image`}
							target="_blank"
							id="webinar-popup-image"
							text="Save my seat"
							size="lg"
						/>
					</div>
				}
			/>
		</div>

		<div
			className={`flex justify-center grid-cols-2 lg:grid ${
				upcomingWebinar.speakerOne?.externalSpeaker
					? 'external-speaker-bg text-white'
					: 'webinar-popover-bg'
			}`}
		>
			<div className="hidden py-8 pl-8 lg:block">
				{upcomingWebinar.speakerOneShowLogo && upcomingWebinar.speakerOne?.logo?.url && (
					<div>
						<img
							src={upcomingWebinar.speakerOne.logo.url}
							alt=""
							className="max-w-[200px] mb-10"
						/>
					</div>
				)}
				<WebinarPopupContent
					subHeading="Join our Webinar on"
					heading={upcomingWebinar.title}
					description={
						upcomingWebinar.speakerOne.externalSpeaker ? (
							<p className="mb-4 leading-tight">{upcomingWebinar.description}</p>
						) : (
							<p className="mb-4 leading-tight">
								{upcomingWebinar.description} Hosted by{' '}
								<span className="font-bold">
									{upcomingWebinar.speakerOne.fullName}
								</span>
								{upcomingWebinar.speakerTwo?.fullName && (
									<>
										{' '}
										and
										<span className="font-bold">
											{' '}
											{upcomingWebinar.speakerTwo.fullName}
										</span>
									</>
								)}
								.
							</p>
						)
					}
					date={`${upcomingWebinar.liveWebinarDate}T${upcomingWebinar.liveWebinarTime}:00`}
					CTA={
						<div className="w-fit">
							<LinkButton
								href={`${href}?utm_source=website&utm_medium=popup&utm_content=image`}
								target="_blank"
								id="webinar-popup-image"
								text="Save my seat"
								size="lg"
							/>
						</div>
					}
				/>
			</div>
			<div className="relative flex items-end pt-12 lg:pt-0 ">
				{upcomingWebinar.jointHeadshot?.headshot ? (
					<GatsbyImage
						image={upcomingWebinar.jointHeadshot.headshot.gatsbyImageData}
						className="w-auto h-auto lg:w-[350px] lg:h-[350px]"
						alt={upcomingWebinar.speakerOne.headshot.alt || ''}
					/>
				) : (
					<GatsbyImage
						image={upcomingWebinar.speakerOne.headshot.gatsbyImageData}
						alt={upcomingWebinar.speakerOne.headshot.alt || ''}
						className="w-auto h-auto lg:w-[350px] lg:h-[350px]"
					/>
				)}

				{upcomingWebinar.speakerOne.externalSpeaker && upcomingWebinar.speakerTwo.logo && (
					<div className="absolute right-0 pb-4 pr-5">
						<img src={upcomingWebinar.speakerTwo.logo.url} alt="" className="" />
					</div>
				)}
			</div>
		</div>
	</>
);

export default LiveWebinarPopup;
