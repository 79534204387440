import React from 'react';
import { format } from 'date-fns';
import { useLocale } from '../../context/Locale';
import { buildLink } from '../../utils/locale';

const TermsCopyrightBlock = ({ className = '' }) => {
	const locale = useLocale();
	return (
		<div>
			<p className={`${className} relative text-xs`}>
				<a
					className="underline"
					href={buildLink(locale, 'terms')}
					target="_blank"
					rel="noreferrer"
				>
					Terms &amp; privacy
				</a>
				&nbsp;| Copyright © {format(new Date(), 'yyyy')} BrightHR
			</p>
		</div>
	);
};

export default TermsCopyrightBlock;
