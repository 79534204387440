import React from 'react';
import LinkButton from '@brighthr/component-linkbutton';
import { StaticImage } from 'gatsby-plugin-image';
import WebinarPopupContent from './WebinarPopupContent';

const DefaultWebinarPopup = ({ href }) => (
	<>
		<div className="block p-8 lg:hidden">
			<WebinarPopupContent
				subHeading="Before you go..."
				heading="View our webinars"
				description={
					<p className="my-4 leading-tight">
						Join our free webinar series with to get essential guidance on your BrightHR
						people management tools.
					</p>
				}
				CTA={
					<div className="flex justify-center pt-0 lg:justify-start lg:pt-5">
						<LinkButton
							href={`${href}?utm_source=website&utm_medium=popup&utm_content=ondemand`}
							id="webinar-popup-default"
							text="Visit our Webinar Page"
						/>
					</div>
				}
			/>
		</div>
		<div className="flex justify-center grid-cols-2 lg:grid webinar-popover-bg">
			<div className="hidden p-8 lg:block">
				<WebinarPopupContent
					subHeading="Before you go..."
					heading="View our webinars"
					description={
						<p className="my-4 leading-tight">
							Join our free webinar series with to get essential guidance on your
							BrightHR people management tools.
						</p>
					}
					CTA={
						<div className="flex justify-center pt-0 lg:justify-start lg:pt-5">
							<LinkButton
								href={`${href}?utm_source=website&utm_medium=popup&utm_content=ondemand`}
								id="webinar-popup-default"
								text="Visit our Webinar Page"
							/>
						</div>
					}
				/>
			</div>
			<div className="relative flex items-end">
				<StaticImage
					src="https://www.datocms-assets.com/23428/1667305423-lucy-gilbert-co-1.png"
					alt="Lucy Gilbert"
					width={400}
					height={400}
				/>
			</div>
		</div>
	</>
);

export default DefaultWebinarPopup;
