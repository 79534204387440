import React, { useEffect, useState } from 'react';
import { Router, useLocation } from '@reach/router';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import Spinner from '@brighthr/component-spinner';
import MinimalLayout from '../../layouts/Minimal';
import SEO from '../../components/SEO';
import { SessionProvider, useSessionState } from './context/session';
import RegistrationFailed from './pages/Checkout/RegistrationFailed';
import config from '../../config';
import Payment from './pages/Payment';
import Confirmation from './pages/Confirmation';
import Optimize from '../../components/Optimize';
import AuthenticationCompleted from './pages/Payment/AuthenticationCompleted';

const { STRIPE_PUBLIC_KEY } = config();

const stripePromise = loadStripe(STRIPE_PUBLIC_KEY);

const BuyOnline = () => {
	const session = useSessionState();
	const { pathname } = useLocation();
	const [errorWarning, setErrorWarning] = useState(false);

	useEffect(() => {
		const timer = setTimeout(() => setErrorWarning(true), 5000);

		return () => clearTimeout(timer);
	}, []);

	if (!session.initialised) {
		return (
			<div className="mx-auto text-center">
				<p className="py-12 text-2xl text-brand-500">Loading checkout...</p>
				<div className="flex justify-center">
					<Spinner ariaLabel="processing request" size="xl" />
				</div>

				{errorWarning && (
					<p className="text-sm text-gray-700">
						Something wrong? Please try refreshing or call us on{' '}
						<a className="font-bold" href="tel:0800 470 2432">
							0800 470 2432
						</a>
					</p>
				)}
			</div>
		);
	}

	return (
		<>
			{pathname !== '/buy-online/payment' ? (
				<MinimalLayout fullWidth={pathname === '/buy-online/confirmation'}>
					<div className="flex flex-col min-h-screen">
						<SEO
							title="Buy Online"
							meta={[
								{
									name: 'robots',
									value: 'noindex',
								},
							]}
							path=""
						/>

						<Elements stripe={stripePromise}>
							<Router>
								<RegistrationFailed path="/buy-online/registration-contact-customer-support" />
								<Confirmation path="/buy-online/confirmation" />
								<AuthenticationCompleted path="/buy-online/payment/authentication-completed" />
							</Router>
						</Elements>

						<p className="py-12 mt-auto text-sm text-center text-gray-700">
							Registered Office (UK): Bright HR Limited, The Peninsula, Victoria
							Place, Manchester, M4 4FB. Registered in England and Wales No: 9283467.
							Tel: 0800 783 2806.
						</p>
					</div>
				</MinimalLayout>
			) : (
				<div>
					<Optimize />
					<main className="mb-24 lg:mb-0">
						<div className="flex flex-col min-h-screen">
							<SEO
								title="Buy Online"
								meta={[
									{
										name: 'robots',
										value: 'noindex',
									},
								]}
								path=""
							/>
							<Elements stripe={stripePromise}>
								<Router>
									<Payment path="/buy-online/payment" />
								</Router>
							</Elements>

							<p className="py-12 mt-auto text-sm text-center text-gray-700">
								Registered Office (UK): Bright HR Limited, The Peninsula, Victoria
								Place, Manchester, M4 4FB. Registered in England and Wales No:
								9283467. Tel: 0800 783 2806.
							</p>
						</div>
					</main>
				</div>
			)}
		</>
	);
};

export default () => (
	<SessionProvider>
		<BuyOnline />
	</SessionProvider>
);
