import React from 'react';
import { buildLink } from '../../../utils/locale';
import CrossThin from '../../../icons/CrossThin.svg';
import LeadershipSocials from '../Socials';

const ProfileInfoBox = ({ infoBox, locale, setInfoBox }) => {
	const findFirstParagraph = (text) => text.split('\n')[0];

	return (
		<>
			<div className="z-10 hidden gap-10 p-6 md:flex bg-brand-100">
				<div className="flex flex-col shrink-0">
					<p className="text-2xl font-bold text-brand-400">{infoBox.name}</p>
					<p className="mb-4 font-bold">{infoBox.companyRole}</p>

					<LeadershipSocials
						tiktok={infoBox.tiktok}
						twitter={infoBox.twitter}
						linkedin={infoBox.linkedin}
					/>
				</div>
				<div className="flex flex-col">
					<p className="mb-4">
						{findFirstParagraph(infoBox.descriptionNode.internal.content)}
					</p>
					<a
						className="mr-auto font-bold text-brand-500 hover:text-brand-400"
						href={buildLink(locale, `our-leadership/${infoBox.slug}`)}
					>
						View full profile
					</a>
				</div>
				<button
					onClick={() => setInfoBox({ slug: null })}
					type="button"
					className="w-8 h-8 transition duration-150 ease-in-out shrink-0 hover:rotate-90"
				>
					<CrossThin />
				</button>
			</div>
		</>
	);
};

export default ProfileInfoBox;
