import React, { useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import { useDebouncedCallback } from 'use-debounce';
import { useLocation } from '@reach/router';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import qs from 'qs';
import Hero from '../components/PPC/Hero';
import ModalForm from '../components/PPC/Forms/ModalForm';
import InlineForm from '../components/PPC/Forms/InlineForm';
import Navbar from '../components/PPC/Navbar';
import BlockVideo from '../components/PPC/BlockVideo';
import Businesses from '../components/PPC/Businesses';
import ImageWithCopyBlocks from '../components/PPC/ImageWithCopyBlocks';
import Features from '../components/PPC/Features';
import FeefoTestimonialsCms from '../components/PPC/FeefoTestimonials/cms';
import FooterBadgesHardcoded from '../components/PPC/FooterBadges/hardcoded';
import FooterCTA from '../components/PPC/FooterCTA';
import ProductInfo from '../components/PPC/ProductInfo';
import DemoCTA from '../components/PPC/DemoCTA';
import MultiParagraphBlock from '../components/MultiParagraphBlock';
import ArticleProvider from '../components/CopyBlock/ArticleProvider';
import FeatureImageWithCopy from '../components/PPC/FeatureImageWithCopy';
import DatoSEO from '../components/DatoSEO';

const PPC = ({ data }) => {
	const {
		brightBusinesses,
		businessLogos,
		dynamicPhoneNumbers,
		featuresHeader,
		formTitle,
		formSubTitle,
		footerTitle,
		freedemoElements,
		heroImage,
		primaryCtaHref,
		primaryCtaText,
		productInfo,
		productInfoCopyHeader,
		productInfoCopyNode,
		productInfoImagePosition,
		productInfoLowerCopyHeader,
		productInfoLowerCopy,
		productInfoSubtitle,
		productInfoTitle,
		productInfoImage,
		extraProductInfo,
		extraProductTitle,
		extraProductCopyNode,
		extraProductImagePosition,
		extraProductImage,
		secondaryCtaHref,
		secondaryCtaText,
		section1Header,
		section2Header,
		showSecondaryCtaButtons,
		showbusinesses,
		slug,
		seoMetaTags,
		telephoneNumber,
		title,
		videoBlockPpc,
		heroTextNode,
	} = data.datoCmsFreeDemoPpc;

	const [showModal, setShowModal] = useState(false);
	const [hasNavScrolled, sethasNavScrolled] = useState(false);
	const { search } = useLocation();

	const { h1, h2 } = qs.parse(search, { ignoreQueryPrefix: true });

	const controlNavbar = () => {
		if (window.scrollY > 0) {
			sethasNavScrolled(true);
		} else {
			sethasNavScrolled(false);
		}
	};

	const debouncedChangeHandler = useDebouncedCallback(controlNavbar, 100);

	useEffect(() => {
		controlNavbar();
		window.addEventListener('scroll', debouncedChangeHandler);
		return () => {
			window.removeEventListener('scroll', debouncedChangeHandler);
		};
	}, [debouncedChangeHandler]);

	const sliderSettings = {
		dots: true,
		arrows: false,
		slidesToShow: 3,
		slidesToScroll: 3,
		infinite: false,
		responsive: [
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					centerMode: true,
					centerPadding: '20px',
					infinite: true,
					dots: true,
					autoplay: true,
					speed: 500,
					autoplaySpeed: 10000,
					pauseOnHover: true,
				},
			},
			{
				breakpoint: 992,
				settings: {
					slidesToShow: 2,
					centerPadding: '20px',
					infinite: true,
					dots: true,
					autoplay: true,
					speed: 500,
					autoplaySpeed: 10000,
					pauseOnHover: true,
				},
			},
		],
	};

	return (
		<>
			<ArticleProvider>
				<DatoSEO path={`${slug}-ppc`} meta={seoMetaTags} noSchema />
				<Navbar
					showModal={() => setShowModal(true)}
					hasNavScrolled={hasNavScrolled}
					dynamicPhoneNumbers={dynamicPhoneNumbers}
					telephoneNumber={telephoneNumber}
					primaryCtaHref={primaryCtaHref}
					secondaryCtaHref={secondaryCtaHref}
					secondaryCtaText={secondaryCtaText}
					secondaryShow={showSecondaryCtaButtons}
				/>

				<Hero
					h1={slug === 'timesheet-app-a' ? 'The easy-to-use timesheet app' : h1 || title}
					h2={h2 || heroTextNode}
					primaryCtaHref={primaryCtaHref}
					primaryCtaText={primaryCtaText}
					showModal={() => setShowModal(true)}
					secondaryCtaHref={secondaryCtaHref}
					secondaryCtaText={secondaryCtaText}
					showSecondaryCtaButtons={showSecondaryCtaButtons}
					heroImage={heroImage.url}
				/>

				<section className="container p-6 mx-auto mb-12 xl:pt-0">
					{showbusinesses && (
						<Businesses header={brightBusinesses} badges={businessLogos} />
					)}
				</section>

				{videoBlockPpc[0] && (
					<section>
						{videoBlockPpc.map((el) => (
							<BlockVideo
								key={el.id}
								imgSrc={el.thumbnail.url}
								alt={el.thumbnail.alt}
								header={el.header}
								title={el.title}
								youtubeId={el.youtubeVideoId}
								vimeoId={el.vimeoId}
								subText={el.videoSubText}
								primaryCtaText={primaryCtaText}
								primaryCtaHref={primaryCtaHref}
								showModal={() => setShowModal(true)}
							/>
						))}
					</section>
				)}

				{!productInfo && (
					<section
						className={`mb-12 lg:mb-0 ${
							productInfo ? '' : 'bg-gradient-to-b from-brand-100 to-white'
						}`}
					>
						<ImageWithCopyBlocks block={freedemoElements} header={section2Header} />
					</section>
				)}

				{productInfo && (
					<section
						className={`mb-12 md:mb-18 ${
							productInfo ? 'bg-gradient-to-b from-brand-100 to-white' : ''
						}`}
					>
						<ProductInfo
							productInfoTitle={productInfoTitle}
							productInfoSubtitle={productInfoSubtitle}
							productInfoCopyHeader={productInfoCopyHeader}
							productInfoCopyNode={productInfoCopyNode}
							productInfoImagePosition={productInfoImagePosition}
							productInfoImage={productInfoImage}
							productInfoLowerCopyHeader={productInfoLowerCopyHeader}
							productInfoLowerCopy={productInfoLowerCopy}
						/>
						{!extraProductInfo && (
							<div className="flex flex-col gap-4 m-4 text-center md:mb-8">
								<DemoCTA
									primaryCtaText={primaryCtaText}
									showModal={() => setShowModal(true)}
									primaryCtaHref={primaryCtaHref}
								/>
							</div>
						)}
					</section>
				)}

				{extraProductInfo && (
					<section className="p-6 mx-auto max-w-7xl">
						<h2 className="max-w-2xl mx-auto mb-12 text-3xl font-semibold text-center md:text-4xl md:font-bold md:mb-20 ">
							<MultiParagraphBlock content={extraProductTitle} />
						</h2>
						{extraProductImage && (
							<FeatureImageWithCopy
								imgSrc={extraProductImage?.gatsbyImageData}
								alt={extraProductImage?.alt}
								imagePosition={extraProductImagePosition}
								copy={extraProductCopyNode}
								tickedBulletPoints
							/>
						)}

						<div className="flex flex-col gap-4 m-4 text-center md:mb-8">
							<p>Ready to try BrightHR for yourself?</p>
							<DemoCTA
								primaryCtaText={primaryCtaText}
								showModal={() => setShowModal(true)}
								primaryCtaHref={primaryCtaHref}
							/>
						</div>
					</section>
				)}

				<Features featureElements={freedemoElements} featuresHeader={featuresHeader} />

				<section>
					<h2 className="max-w-2xl px-6 mx-auto mb-12 text-3xl font-semibold text-center md:text-4xl md:font-bold md:mb-20">
						<MultiParagraphBlock content={section1Header} />
					</h2>

					<div className="reviews-background">
						<div className="px-6 py-6 mx-auto max-w-7xl ">
							<Slider {...sliderSettings}>
								{freedemoElements.map(
									(el) =>
										el.blocktype === 'ReviewRecord' && (
											<FeefoTestimonialsCms
												review={el.reviewContent}
												author={el.reviewAuthor}
												source={el.reviewType}
												key={el.id}
											/>
										),
								)}
							</Slider>
						</div>
					</div>
				</section>

				<section className="hidden px-3 mb-12 md:mb-18 form-section-bg md:block">
					<InlineForm
						telephoneNumber={telephoneNumber}
						formTitle={formTitle || 'See BrightHR in action'}
						formSubTitle={
							formSubTitle ||
							'Fill out a few short details below to arrange your free demo.'
						}
					/>
				</section>

				<section className="px-8 py-12 text-center md:py-24 form-section-bg md:hidden">
					<FooterCTA
						footerTitle={footerTitle}
						primaryCtaText={primaryCtaText}
						primaryCtaHref={primaryCtaHref}
						showModal={() => setShowModal(true)}
						secondaryCtaHref={secondaryCtaHref}
						secondaryCtaText={secondaryCtaText}
						secondaryShow={showSecondaryCtaButtons}
					/>
				</section>

				<FooterBadgesHardcoded />

				<div className="w-full">
					{showModal && (
						<ModalForm
							onClose={() => setShowModal(false)}
							formTitle="See BrightHR in action"
							formSubTitle="Fill out a few short details below to arrange your free demo."
						/>
					)}
				</div>
			</ArticleProvider>
		</>
	);
};

export default PPC;

export const query = graphql`
	query ppcPageBySlug($slug: String) {
		datoCmsFreeDemoPpc(slug: { eq: $slug }) {
			seo {
				title
				description
			}
			seoMetaTags {
				...GatsbyDatoCmsSeoMetaTags
			}
			telephoneNumber
			dynamicPhoneNumbers
			title
			primaryCtaHref
			primaryCtaText
			formSubTitle
			formTitle
			footerTitle
			secondaryCtaHref
			secondaryCtaText
			section2Header
			section1Header
			featuresHeader
			brightBusinesses
			showForm
			showSecondaryCtaButtons
			showbusinesses
			heroText
			slug
			productInfo
			productInfoCopyHeader
			productInfoCopyNode {
				childMdx {
					body
				}
			}
			productInfoImagePosition
			productInfoLowerCopyHeader
			productInfoLowerCopy
			productInfoSubtitle
			productInfoTitle
			productInfoImage {
				gatsbyImageData
				alt
			}
			extraProductInfo
			extraProductTitle
			extraProductCopyNode {
				childMdx {
					body
				}
			}
			extraProductImagePosition
			extraProductImage {
				gatsbyImageData
				alt
			}
			heroTextNode {
				childMdx {
					body
				}
			}
			videoBlockPpc {
				id
				header
				thumbnail {
					url
					alt
				}
				title
				vimeoId
				youtubeVideoId
				videoSubText
			}
			businessLogos {
				alt
				url
			}
			footerBadges {
				alt
				url
			}
			heroImage {
				url
			}
			freedemoElements {
				... on DatoCmsReview {
					blocktype
					id
					reviewAuthor
					reviewContent
					reviewType
				}
				... on DatoCmsImageWithCopyTwo {
					id
					title
					copyNode {
						childMdx {
							body
						}
					}
					blocktype
					image {
						url
						alt
					}
				}
				... on DatoCmsImageWithCopyV2 {
					id
					blocktype
					imagePosition
					header
					copyNode {
						childMdx {
							body
						}
					}
					imageWithCopyImageV2 {
						gatsbyImageData
						alt
					}
				}
			}
		}
	}
`;
