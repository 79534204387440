import React, { useEffect, useState } from 'react';
import IntegrationToast from './IntegrationToast';

const IntegrationStack = ({ integrationHeaderOne, integrationHeaderTwo }) => {
	const [apiData, setApiData] = useState(false);

	useEffect(() => {
		fetch(
			'https://api.zapier.com/v1/zap-templates?client_id=9AvQP03zMwhepTFNySc0tgbVu23ITUqNH2dq9JGj',
		)
			.then((response) => response.json())
			.then((data) => setApiData(data))
			.catch((e) => {
				// eslint-disable-next-line no-console
				console.log(e);
			});
	}, []);

	return (
		<>
			{Array.isArray(apiData) && (
				<div className="mx-auto max-w-7xl">
					<div className="flex flex-col max-w-3xl gap-8 px-2 mx-6 mb-10 text-center md:mx-auto">
						{integrationHeaderOne && (
							<h2 className="text-3xl">{integrationHeaderOne}</h2>
						)}
						{integrationHeaderTwo && (
							<h4 className="text-lg md:text-xl">{integrationHeaderTwo}</h4>
						)}
					</div>
					<div className="relative flex flex-col gap-3">
						{apiData.slice(0, 4).map((data) => (
							<IntegrationToast key={data.id} apiData={data} />
						))}
						<svg
							width="1440"
							height="386"
							viewBox="0 0 1440 386"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
							className="absolute z-0 hidden w-screen -translate-x-1/2 lg:block xl:w-screen -translate-y-[62%] top-1/2 left-1/2 xxl:hidden"
						>
							<path
								d="M-123.1 89.3751V347.859C-123.1 368.053 -106.73 384.423 -86.5358 384.423H58.9355C79.1295 384.423 95.5 368.053 95.5 347.859V229.488C95.5 209.294 111.871 192.923 132.065 192.923H1305.94C1326.13 192.923 1342.5 176.553 1342.5 156.359V38.9876C1342.5 18.7936 1358.87 2.4231 1379.06 2.4231H1533"
								stroke="#697D8C"
								strokeWidth="3"
								strokeLinecap="round"
								strokeDasharray="0.55 7.31"
							/>
						</svg>
					</div>
				</div>
			)}
		</>
	);
};

export default IntegrationStack;
