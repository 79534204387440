import React from 'react';
import LinkButton from '@brighthr/component-linkbutton';
import Spinner from '@brighthr/component-spinner';
import { buildLink } from '../../utils/locale';
import LocaleContent from '../LocaleContent';
import useExistingUserTimout from '../../hooks/useExistingUserTimout';

const StaticCTA = ({ locale }) => {
	const { isCookieReady, existingUser } = useExistingUserTimout({ locale });

	return (
		<div className="flex flex-col items-center justify-center w-full gap-4 p-6 mx-auto text-center border border-gray-400 rounded shadow-xl standard-cta md:shadow-none md:border-none md:h-64">
			<strong className="text-xl font-bold text-brand-500">
				Ready to find out how BrightHR can help you?
			</strong>

			<div className="flex items-center h-[90px] lg:h-[73px]">
				{isCookieReady ? (
					<div className="flex flex-col w-full gap-4 md:w-auto md:flex-row">
						{existingUser ? (
							<LinkButton
								text="Contact support"
								href={buildLink(locale, '/contact?Reason=Support')}
							/>
						) : (
							<>
								<LinkButton
									href={buildLink(locale, 'book-a-demo')}
									text="Request a demo"
								/>

								<LocaleContent showInLocales={['en', 'ca', 'ie', 'au']}>
									<LinkButton
										outlineButton
										href={buildLink(locale, 'request-quote')}
										text={
											locale === 'en'
												? 'Get a price online'
												: 'Request pricing'
										}
									/>
								</LocaleContent>
							</>
						)}
					</div>
				) : (
					<div className="flex items-center m-auto">
						<Spinner
							ariaLabel="processing request"
							className="stroke-primary-700"
							size="xs"
						/>
					</div>
				)}
			</div>
		</div>
	);
};

export default StaticCTA;
