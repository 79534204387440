import { graphql } from 'gatsby';
import React, { useState, useRef, useEffect } from 'react';
import { useLocation } from '@reach/router';

import Layout from '../layouts/Default';
import SEO from '../components/SEO';
import useFuse from '../components/useFuse';
import CopyBlock from '../components/CopyBlock';
import Pagination from '../components/Pagination';
import CrossIcon from '../icons/Cross.svg';
import WebinarCards from '../components/ResourceCards/Webinar';
import { buildLink, LOCALE_PATHS } from '../utils/locale';
import HrefLang from '../components/HrefLang';
import Container from '../components/Container';
import Breadcrumbs, { Breadcrumb } from '../components/Breadcrumbs';
import ServiceWebinarBanner from '../components/ServiceWebinarBanner';
import usePaginatedSearch from '../hooks/usePaginatedSearch';
import usePagination from '../hooks/usePagination';

const fuseOptions = {
	keys: ['title', 'breadcrumbText', 'bodyNode.childMdx.excerpt'],
};

const WebinarCategory = ({ data, pageContext }) => {
	const { title, bodyNode, seo, slug, _allSeoLocales } = data.datoCmsWebinarCategory;
	const { nodes: webinars } = data.webinars;
	const { webinarsPerPage, slug: librarySlug, title: libraryTitle } = data.library;
	const [isMobile, setIsMobile] = useState();
	const { result, search } = useFuse(webinars, fuseOptions);
	const listRef = useRef();
	const searchInputRef = useRef();
	const location = useLocation();
	const locale = LOCALE_PATHS[pageContext.locale];

	useEffect(() => {
		const browserWidth = window.innerWidth;

		if (browserWidth < 768) {
			setIsMobile(true);
		}
	}, []);

	const defaultPerPage = isMobile ? 3 : 6;

	const PER_PAGE = webinarsPerPage || defaultPerPage;

	const { page, pageNumbers, currentPageResults } = usePagination(result.length, PER_PAGE);
	const { showSearch, searchInput, showSearchResults, clearSearch, handleKeyDown } =
		usePaginatedSearch({ search, searchInputRef });

	const dataSet = showSearch ? result : result.slice(1);

	return (
		<Layout fullWidth>
			<HrefLang locales={_allSeoLocales} slug={`webinars/${slug}`} />
			<SEO
				path={location.pathname}
				title={seo.title}
				description={seo.description || bodyNode.childMdx.excerpt}
			/>

			<div className="py-4 bg-gray-200 md:mb-2">
				<Container className="px-5">
					<Breadcrumbs>
						<Breadcrumb href={buildLink(locale, '/')} position={0}>
							Home
						</Breadcrumb>
						<Breadcrumb
							href={buildLink(
								locale,
								`${librarySlug}?page=1${
									searchInput ? `&search=${searchInput}` : ''
								}`,
							)}
							position={1}
						>
							{libraryTitle}
						</Breadcrumb>
						<Breadcrumb position={2}>{title}</Breadcrumb>
					</Breadcrumbs>

					<h1 className="my-4 text-2xl font-bold leading-8 tracking-tight text-gray-900">
						{title}
					</h1>

					<div className="mb-12 text-lg leading-7 text-gray-700">
						{bodyNode.childMdx.body && <CopyBlock>{bodyNode.childMdx.body}</CopyBlock>}
					</div>

					<label htmlFor="search" className="block mb-16">
						<div className="relative flex bg-white rounded-md shadow-lg">
							<svg
								className="absolute -translate-y-1/2 top-1/2 left-4 hover:cursor-pointer"
								width="18"
								height="18"
								viewBox="0 0 18 18"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
								onClick={showSearchResults}
								title="Search"
								tabIndex="0"
							>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M1.79993 8.09969C1.79993 4.62043 4.62043 1.79993 8.09969 1.79993C11.579 1.79993 14.3995 4.62043 14.3995 8.09969C14.3995 9.79758 13.7278 11.3386 12.6356 12.4715C12.6055 12.4948 12.5765 12.5202 12.5488 12.5479C12.5211 12.5756 12.4956 12.6046 12.4723 12.6348C11.3393 13.7274 9.79798 14.3995 8.09969 14.3995C4.62043 14.3995 1.79993 11.579 1.79993 8.09969ZM13.1559 14.4278C11.7702 15.5364 10.0124 16.1994 8.09969 16.1994C3.62635 16.1994 0 12.573 0 8.09969C0 3.62635 3.62635 0 8.09969 0C12.573 0 16.1994 3.62635 16.1994 8.09969C16.1994 10.0119 15.5367 11.7693 14.4286 13.1549L17.7364 16.4628C18.0879 16.8142 18.0879 17.384 17.7364 17.7355C17.3849 18.087 16.8151 18.087 16.4637 17.7355L13.1559 14.4278Z"
									fill="#004991"
								/>
							</svg>

							<input
								id="search"
								className="flex-1 py-5 pl-12 pr-4 rounded-md"
								placeholder="Search webinars..."
								onChange={showSearchResults}
								onKeyDown={handleKeyDown}
								value={searchInput}
								ref={searchInputRef}
							/>

							{showSearch && (
								<CrossIcon
									className="absolute w-4 -translate-y-1/2 top-1/2 right-4 hover:cursor-pointer"
									onClick={clearSearch}
									tabindex="0"
								/>
							)}
						</div>
					</label>
					{!showSearch && (
						<WebinarCards
							className="flex"
							cardClassName="w-full"
							resources={[result[0]]}
							locale={locale}
							slugBase="webinars"
							showcase
						/>
					)}
				</Container>
			</div>

			<Container className="px-5">
				<div ref={listRef}>
					<h2 className="mb-6 text-2xl font-bold leading-8 text-gray-900">
						{!showSearch && result.length > 1 && `All ${title} Webinars`}
						{showSearch &&
							(result.length > 0
								? `All results for '${searchInput}'`
								: `We couldn't find any webinars that matched your search.`)}
					</h2>

					<WebinarCards
						className="flex flex-col mb-8 md:flex-row md:flex-wrap md:-translate-x-4 md:mb-0"
						cardClassName="w-full pb-8 md:w-1/2 md:px-4 lg:w-1/3 last:pb-0 md:last:pb-8"
						resources={currentPageResults(dataSet)}
						locale={locale}
						slugBase="webinars"
						searchInput={searchInput}
					/>

					{dataSet.length > PER_PAGE && (
						<Pagination
							page={page}
							pageNumbers={pageNumbers}
							searchInput={searchInput}
						/>
					)}
				</div>
			</Container>
			<ServiceWebinarBanner />
		</Layout>
	);
};

export default WebinarCategory;

export const query = graphql`
	query WebinarCategory($locale: String!, $id: String!) {
		datoCmsWebinarCategory(locale: { eq: $locale }, id: { eq: $id }) {
			id
			slug
			title
			bodyNode {
				childMdx {
					body
					excerpt
				}
			}
			seo {
				title
				description
			}
			_allSeoLocales {
				locale
			}
		}
		webinars: allDatoCmsWebinar(
			filter: { category: { id: { eq: $id } }, locale: { eq: $locale }, slug: { ne: null } }
			sort: { order: DESC, fields: meta___createdAt }
		) {
			nodes {
				id
				title
				slug
				date
				meta {
					createdAt
				}
				bodyNode {
					childMdx {
						excerpt(truncate: true)
					}
				}
				thumbnail {
					gatsbyImageData(
						width: 870
						imgixParams: { auto: "compress", ar: "16:9", fit: "crop" }
					)
					alt
				}
				youtubeId
				category {
					id
					slug
					title
				}
			}
		}
		library: datoCmsWebinarLibrary {
			slug
			title
			webinarsPerPage
		}
	}
`;
