import React from 'react';

const PriceBadge = ({ price, description }) => (
	<p className="text-xs font-bold">
		From just
		<strong className="block text-2xl">{price}</strong>
		{description}
	</p>
);
const OfferBadge = ({ offer, description }) => (
	<p className="text-xs">
		<strong className="text-2xl">{offer}</strong>
		<span className="block leading-none">for</span>
		<span className="font-bold leading-tight">{description}</span>
	</p>
);

const Badge = ({ description, offer, price }) => (
	<div className="w-40 text-center text-white border-4 border-white rounded-full embed-responsive embed-responsive-1by1 bg-brand-500 rotate-12">
		<div className="flex flex-col items-center justify-center p-1 embed-responsive-item">
			{price ? (
				<PriceBadge price={price} description={description} />
			) : (
				<OfferBadge offer={offer} description={description} />
			)}
		</div>
	</div>
);

export default Badge;
