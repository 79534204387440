import React from 'react';
import Notebook from '../../../icons/Notebook.svg';

const GuideCard = ({ title, topic, link }) => (
	<li className="bg-white border border-gray-300 rounded shadow-md">
		<a href={link}>
			<div className="flex flex-col justify-between h-full p-5 flex-nowrap">
				<div className="mb-4">
					<h2 className="text-lg font-bold leading-7 text-left">{title}</h2>
				</div>
				<div className="mt-auto">
					<span className="inline-block w-auto px-3 py-1 mt-auto text-xs leading-4 bg-gray-200 rounded-full">
						<Notebook className="inline mr-2" />
						<strong className="inline-block leading-6">{topic.name}</strong>
					</span>
				</div>
			</div>
		</a>
	</li>
);

export default GuideCard;
