import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import cn from 'classnames';
import loadScript from 'load-script2';
// import submitMarketoForm from '../../../Marketo/submitMarketoForm';
import submitMarketoForm from '../../../../utils/PPC/submitMarketo';
import FormFooterLinks from '../../FormFooterLinks';
import Input from '../../Input';

const ModalForm = ({ onClose, formTitle, formSubTitle }) => {
	const [shortForm, setShortForm] = useState(false);

	useEffect(() => {
		if (window.innerHeight < 600) {
			setShortForm(true);
		}
	}, []);

	// We do this to tackle the soft keyboard on android overlapping content
	const isAndroidOperatingSystem = () => {
		const userAgent = navigator.userAgent || navigator.vendor || window.opera;

		if (/android/i.test(userAgent)) {
			return true;
		}
		return false;
	};

	useEffect(() => {
		// Preloads the Marketo form for background submission
		const loadForm = () =>
			loadScript('//app-lon04.marketo.com/js/forms2/js/forms2.min.js')
				.then(() => {
					window.MktoForms2.loadForm(
						'//app-lon04.marketo.com',
						'217-MIC-854',
						2796,
						(form) => {
							form.onSuccess(() => false);
						},
					);
				})
				.catch(() => {});

		if (window.requestIdleCallback) {
			window.requestIdleCallback(loadForm);
		} else {
			setTimeout(loadForm);
		}
	}, []);

	const { handleChange, handleSubmit, setStatus, values, isSubmitting, submitted, error } =
		useFormik({
			initialValues: {
				FirstName: '',
				LastName: '',
				Email: '',
				Company: '',
				Phone: '',
			},
			onSubmit: async (data) => {
				submitMarketoForm(data)
					.then(() => {
						setStatus('submitted');
					})
					.catch(() => {
						setStatus('error');
					});
			},
		});

	return (
		<div>
			<form
				data-cy="modal-form"
				className={cn(
					'fixed top-0 left-0 z-50 w-full p-5 bg-white border-gray-400 md:rounded-lg sm:-translate-x-1/2 sm:-translate-y-1/2 sm:w-4xl sm:left-1/2 sm:top-1/2 drop-shadow-2xl',
					{
						'overflow-y-scroll pb-64 h-screen': isAndroidOperatingSystem(),
					},
				)}
				onSubmit={handleSubmit}
			>
				<div
					onClick={onClose}
					onKeyDown={onClose}
					role="button"
					tabIndex="0"
					className="absolute cursor-pointer top-4 right-4"
				>
					<svg
						width="16"
						height="16"
						viewBox="0 0 16 16"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M9.0625 8L15.7812 1.28125C15.9271 1.13542 16 0.958333 16 0.75C16 0.541667 15.9271 0.364583 15.7812 0.21875C15.6354 0.0729167 15.4583 0 15.25 0C15.0417 0 14.8646 0.0729167 14.7188 0.21875L8 6.9375L1.28125 0.21875C1.13542 0.0729167 0.958333 0 0.75 0C0.541667 0 0.364583 0.0729167 0.21875 0.21875C0.0729167 0.364583 0 0.541667 0 0.75C0 0.958333 0.0729167 1.13542 0.21875 1.28125L6.9375 8L0.21875 14.7188C0.0729167 14.8646 0 15.0417 0 15.25C0 15.4583 0.0729167 15.6354 0.21875 15.7812C0.291667 15.8542 0.375 15.9062 0.46875 15.9375C0.5625 15.9792 0.65625 16 0.75 16C0.84375 16 0.9375 15.9792 1.03125 15.9375C1.125 15.9062 1.20833 15.8542 1.28125 15.7812L8 9.0625L14.7188 15.7812C14.7917 15.8542 14.875 15.9062 14.9688 15.9375C15.0625 15.9792 15.1562 16 15.25 16C15.3438 16 15.4375 15.9792 15.5312 15.9375C15.625 15.9062 15.7083 15.8542 15.7812 15.7812C15.9271 15.6354 16 15.4583 16 15.25C16 15.0417 15.9271 14.8646 15.7812 14.7188L9.0625 8Z"
							fill="black"
						/>
					</svg>
				</div>
				{error && (
					<p className="text-lg font-bold">
						Sorry we have been unable to book your demo, please call us on 1800 279 841.
					</p>
				)}
				{!error && !submitted && (
					<>
						<div className={shortForm ? 'hidden' : 'block'}>
							<h3 className="mt-2 mb-4 text-2xl font-bold text-center md:text-3xl">
								{formTitle}
							</h3>
							<p className="mb-6 text-center">{formSubTitle}</p>
						</div>

						<div>
							<div className="flex justify-between space-x-4">
								<Input
									onChange={handleChange}
									value={values.FirstName}
									type="text"
									label="First name"
									name="FirstName"
									placeholder="John"
									data-cy="modal-form-firstname"
									required
								/>
								<Input
									onChange={handleChange}
									value={values.LastName}
									type="text"
									label="Last name"
									name="LastName"
									placeholder="Smith"
									data-cy="modal-form-lastname"
									required
								/>
							</div>

							<div>
								<Input
									onChange={handleChange}
									value={values.Email}
									type="email"
									name="Email"
									label="Email address"
									placeholder="john.smith@acmeinc.com"
									data-cy="modal-form-email"
									required
								/>
							</div>

							<div>
								<Input
									onChange={handleChange}
									value={values.Company}
									type="text"
									label="Company name"
									name="Company"
									placeholder="Acme Inc."
									data-cy="modal-form-company"
									required
								/>
							</div>

							<div>
								<Input
									onChange={handleChange}
									value={values.Phone}
									type="tel"
									label="Phone number"
									name="Phone"
									placeholder="01632 960410"
									data-cy="modal-form-phone"
									pattern="[0-9\s]{7,16}"
									title="Enter a valid telephone number between 7 and 16 digits."
									required
								/>
							</div>

							<div className="text-center">
								<button
									id="submit"
									data-cy="modal-form-submit"
									type="submit"
									disabled={isSubmitting}
									className="block w-full px-6 py-3 mt-2 font-bold text-center text-white rounded-full md:w-auto md:inline-block bg-secondary-300 hover:bg-secondary-400"
								>
									Book my free demo
								</button>
							</div>
						</div>
						<FormFooterLinks className="text-sm text-left" />
					</>
				)}
			</form>
			<div
				className="fixed top-0 bottom-0 left-0 right-0 z-20 flex flex-col items-center justify-center p-6 bg-lightbox"
				onClick={onClose}
				onKeyPress={onClose}
				role="button"
				tabIndex={0}
				aria-label="Close form modal"
			/>
		</div>
	);
};

export default ModalForm;
