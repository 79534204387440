import React, { useEffect } from 'react';
import Button from '@brighthr/component-button';
import { useFormik } from 'formik';
import cn from 'classnames';
import loadScript from 'load-script2';
import submitMarketoForm from '../../Marketo/submitMarketoForm';
import { useLocale } from '../../../context/Locale';
import { buildLink } from '../../../utils/locale';

const Newsletter = ({ styling = 'default' }) => {
	const locale = useLocale();

	useEffect(() => {
		const loadForm = () =>
			loadScript('//app-lon04.marketo.com/js/forms2/js/forms2.min.js')
				.then(() => {
					window.MktoForms2.loadForm('//app-lon04.marketo.com', '217-MIC-854', 1781);
				})
				.catch(() => {});

		if (window.requestIdleCallback) {
			window.requestIdleCallback(loadForm);
		} else {
			setTimeout(loadForm);
		}
	}, []);

	const { handleChange, handleSubmit, setStatus, values, isSubmitting, status } = useFormik({
		initialValues: {
			Email: '',
			LeadSource: 'None',
		},
		onSubmit: async (data) => {
			submitMarketoForm(data, 1781)
				.then(() => {
					setStatus('submitted');
				})
				.catch(() => {
					setStatus('error');
				});
		},
	});

	const confirmation =
		styling === 'footer'
			? "Your subscription has been confirmed. You've been added to our list and will hear from us soon."
			: 'Thanks for subscribing! We’ll be in touch soon.';

	return (
		<>
			<div data-cy="newsletter-confirmation">
				{status === 'error' && (
					<p>
						There was an error in the sign up process, please refresh the page and try
						again.
					</p>
				)}

				{status === 'submitted' && <p>{confirmation}</p>}
			</div>

			{status === undefined && (
				<form onSubmit={handleSubmit}>
					<div
						className={cn(
							'flex max-w-full gap-4 mb-4 md:justify-center flex-col',
							styling === 'footer' ? 'lg:flex-row' : 'gap-3 justify-start',
						)}
					>
						<div className="md:flex-auto md:mb-0">
							<input
								data-cy="newsletter-email"
								className="w-full px-4 py-2 text-gray-800 border border-gray-400 rounded"
								type="email"
								name="Email"
								value={values.Email}
								placeholder="Email address"
								required
								onChange={handleChange}
							/>
						</div>
						<Button
							data-cy="newsletter-sign-up"
							type="submit"
							disabled={isSubmitting}
							size="lg"
							data-footer-link="newsletter-sign-up"
							text="Sign up"
						/>
					</div>
					<p className="text-xs">
						View our privacy notice{' '}
						<a
							href={buildLink(locale, 'terms')}
							target="_blank"
							rel="noopener noreferrer"
							className="underline"
						>
							here
						</a>
					</p>
				</form>
			)}
		</>
	);
};

export default Newsletter;
