const getEnv = require('./getEnv');

module.exports = () => ({
	BRIGHT_API_URL: getEnv({
		production: 'https://api.brighthr.com',
		default: 'https://sandbox-api.brighthr.com',
	}),
	HOST: getEnv({
		production: 'https://www.brighthr.com',
		default: 'http://develop.brightdotcom.pages.dev',
	}),
	OAUTH_AUTHORITY: getEnv({
		production: 'https://login.brighthr.com',
		default: 'https://sandbox-login.brighthr.com',
	}),
	STRIPE_PUBLIC_KEY: getEnv({
		production:
			'pk_live_51H2yrSIhA3Pmpn4mgTXsJAdpITSCMF2lLVJ5P4aGYVjPWWRXJ1pA9wBaMuuzTLViyg5iB6mwxYbSxfWSNV7mUdwY00QOiQNPzR',
		default:
			'pk_test_51H2yrSIhA3Pmpn4m7Q1RaxTlsdPkNY1tbb4H2BFsPjzBrufrrsfo3Pue6dVnI17fCDnasWOEmNqjLGFyr4rbJ3nX00kgCi8QJG',
	}),
});
