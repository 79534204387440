/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import LazyLoad from 'react-lazyload';
import { GatsbyImage } from 'gatsby-plugin-image';
import { nanoid } from 'nanoid';
import Flag from '../CountryFlag';
import FooterImageWithCopy from '../CMSBlocks/FooterImageWithCopy';
import { LOCALES, LOCALE_CONFIG, localisedRead } from '../../utils/locale';
import { useLocale, useLocaleUpdate } from '../../context/Locale';
import Section from './Components/section';
import Social from './Components/social';
import AppDownload from './Components/appDownload';
import NewsletterFooter from './Components/newsletterFooter';
import XingBlue from '../../icons/XingBlue.svg';

const Footer = ({ isPpcFooter = false }) => {
	const locale = useLocale();
	const updateLocale = useLocaleUpdate();
	const [showNewsletter] = useState(true);

	const data = useStaticQuery(graphql`
		query FooterDataQuery {
			datoCmsFooter {
				id
				_allFooterTextLocales {
					locale
					value
				}
				_allGeneralLinksTitleLocales {
					locale
					value
				}
				_allProductsLinkTitleLocales {
					locale
					value
				}
				_allCompanyLinksTitleLocales {
					locale
					value
				}
				_allGeneralLocales {
					locale
					value {
						id
						href
						text
					}
				}
				_allProductsLocales {
					locale
					value {
						id
						href
						text
					}
				}
				_allCompanyLinksLocales {
					locale
					value {
						id
						href
						text
					}
				}
				trustBadges {
					url
					alt
					gatsbyImageData(
						layout: CONSTRAINED
						width: 108
						imgixParams: { fm: "webp", auto: "compress" }
					)
				}
				_allSubFooterTextLocales {
					value
					locale
				}
				_allFooterImageWithCopyLocales {
					locale
					value {
						textNode {
							childMdx {
								body
							}
						}
						footerImageWithCopyImage {
							alt
							gatsbyImageData(
								width: 138
								layout: FIXED
								imgixParams: { auto: "compress" }
							)
						}
					}
				}
			}
		}
	`);

	const { trustBadges } = data.datoCmsFooter;
	const [row1Append, setRow1Append] = useState();
	const [row2Append, setRow2Append] = useState();

	const half = Math.ceil(trustBadges.length / 2);
	const row1 = trustBadges.slice(0, half);
	const row2 = trustBadges.slice(half, trustBadges.length);

	useEffect(() => {
		setRow1Append(row1.concat(...row1));
		setRow2Append(row2.concat(...row2));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [trustBadges]);

	const footerImageWithCopy = localisedRead(
		data.datoCmsFooter._allFooterImageWithCopyLocales,
		locale,
	)[0];

	return (
		<footer id="footer">
			{showNewsletter && !isPpcFooter && <NewsletterFooter />}
			{!isPpcFooter && (
				<div className="container p-6 py-10 mx-auto">
					<div className="md:grid md:grid-cols-3 lg:grid-cols-4 lg:gap-12">
						<div>
							<XingBlue width="48" height="48" className="mb-4" />
							<p className="mb-4">
								{localisedRead(data.datoCmsFooter._allFooterTextLocales, locale)}
							</p>
							<Social />
						</div>
						<Section
							key={localisedRead(
								data.datoCmsFooter._allGeneralLinksTitleLocales,
								locale,
							)}
							name={localisedRead(
								data.datoCmsFooter._allGeneralLinksTitleLocales,
								locale,
							)}
							links={localisedRead(data.datoCmsFooter._allGeneralLocales, locale)}
						/>
						<Section
							key={localisedRead(
								data.datoCmsFooter._allProductsLinkTitleLocales,
								locale,
							)}
							name={localisedRead(
								data.datoCmsFooter._allProductsLinkTitleLocales,
								locale,
							)}
							links={localisedRead(data.datoCmsFooter._allProductsLocales, locale)}
							className="md:row-span-2"
						/>
						<Section
							key={localisedRead(
								data.datoCmsFooter._allCompanyLinksTitleLocales,
								locale,
							)}
							name={localisedRead(
								data.datoCmsFooter._allCompanyLinksTitleLocales,
								locale,
							)}
							links={localisedRead(
								data.datoCmsFooter._allCompanyLinksLocales,
								locale,
							)}
							className="md:row-span-2"
						/>

						<div className="mb-10 lg:mb-0" data-testid="country-flags">
							<h3 className="mb-2 font-bold lg:text-xl">BrightHR in your region</h3>
							<ul>
								{LOCALES.map((l) => (
									<li key={l} className="flex items-center mb-3">
										<Flag
											countryCode={LOCALE_CONFIG[l].countryCode}
											className="w-4 h-4 mr-2"
										/>
										<button
											type="button"
											className="block cursor-pointer text-brand-500 hover:text-brand-200"
											onClick={() => updateLocale(l)}
										>
											{LOCALE_CONFIG[l].name}
										</button>
									</li>
								))}
							</ul>
						</div>
						<AppDownload />
					</div>
				</div>
			)}

			<div className="relative p-6 py-0 text-center border-gray-400 border-y">
				<div className="container flex flex-row flex-wrap justify-between gap-10 py-10 mx-auto md:hidden">
					<LazyLoad once>
						{data.datoCmsFooter.trustBadges.map((badge) => (
							<GatsbyImage
								key={badge.url}
								image={badge.gatsbyImageData}
								className="mx-auto my-8"
								alt={badge.alt || ''}
							/>
						))}
					</LazyLoad>
				</div>

				<div className="container justify-center hidden py-4 mx-auto overflow-hidden md:flex">
					<div
						className="z-50 flex flex-col justify-center w-full py-6 pt-0 pr-4 text-left md:w-5/12"
						style={{
							boxShadow: '-2px 0 15px 30px rgb(255 255 255 / 100%)',
						}}
					>
						<div className="max-w-xl">
							<h2 className="mb-6 text-xl font-bold ">
								BrightHR&apos;s industry awards &amp; recognitions
							</h2>
							<h3 className="text-lg">
								We’ve received many prestigious awards for our innovative software
								and exceptional service.
							</h3>
						</div>
					</div>

					<div className="relative flex h-72 overflow-hidden md:w-[750px]">
						<div
							className="absolute overflow-visible whitespace-nowrap md:animate-horizontalScrollAnimationFast"
							style={{
								// number of badges * badge width + margins
								width: `${row1.length * 136}px`,
							}}
						>
							{row1Append && (
								<LazyLoad once>
									<ul className="flex flex-row">
										{row1Append.map((badge) => (
											<li
												key={nanoid()}
												className="m-3 bg-center bg-no-repeat bg-contain w-28 h-28 shrink-0"
												style={{
													backgroundImage: `url(${badge.url})`,
												}}
											/>
										))}
									</ul>
								</LazyLoad>
							)}
						</div>

						<div
							className="absolute w-full overflow-visible top-36 whitespace-nowrap md:animate-horizontalScrollAnimationSlow"
							style={{
								// number of badges * badge width + margin
								width: `${row2.length * 136}px`,
							}}
						>
							{row2Append && (
								<LazyLoad once>
									<ul className="flex flex-row">
										{row2Append.map((badge) => (
											<li
												key={nanoid()}
												className="m-3 bg-center bg-no-repeat bg-contain w-28 h-28 shrink-0"
												style={{
													backgroundImage: `url(${badge.url})`,
												}}
											/>
										))}
									</ul>
								</LazyLoad>
							)}
						</div>
					</div>
					<div
						className="relative"
						style={{
							boxShadow: '-2px 0 15px 30px rgb(255 255 255 / 100%)',
						}}
					/>
				</div>
			</div>

			<div className="bg-gray-100 border border-top-gray-400">
				<div className="container flex flex-col p-6 py-10 mx-auto lg:items-center lg:flex-row lg:w-4/5">
					{footerImageWithCopy?.footerImageWithCopyImage && !isPpcFooter && (
						<div className="lg:w-1/5">
							<FooterImageWithCopy
								isPpcFooter={!isPpcFooter}
								imgSrc={
									footerImageWithCopy?.footerImageWithCopyImage.gatsbyImageData
								}
								alt={footerImageWithCopy?.footerImageWithCopyImage.alt}
								text={footerImageWithCopy?.textNode.childMdx.body}
							/>
						</div>
					)}
					<div>
						<p className="mb-4 whitespace-pre-line">
							{localisedRead(data.datoCmsFooter._allSubFooterTextLocales, locale)}
						</p>
						<p className="text-xs">Copyright © {new Date().getFullYear()} BrightHR</p>
					</div>
				</div>
			</div>
		</footer>
	);
};

export default Footer;

/* eslint-enable no-underscore-dangle */
