import React, { useState, useCallback } from 'react';
import { useSwipeable } from 'react-swipeable';
import cn from 'classnames';
import { GatsbyImage } from 'gatsby-plugin-image';

const Carousel = ({ slides }) => {
	const [currentSlide, setSlide] = useState(0);
	const slideCount = slides.length - 1;

	const navigateToIndex = (index) => setSlide(index);

	const next = useCallback(
		() =>
			setSlide((_currentSlide) =>
				_currentSlide < slideCount ? _currentSlide + 1 : _currentSlide,
			),
		[slideCount],
	);
	const prev = useCallback(
		() => setSlide((_currentSlide) => (_currentSlide > 0 ? _currentSlide - 1 : _currentSlide)),
		[],
	);
	const handlers = useSwipeable({
		onSwipedLeft: next,
		onSwipedRight: prev,
		preventDefaultTouchmoveEvent: true,
	});

	return (
		<div className="relative max-w-lg aspect-[1.75] m-auto">
			<div className="aspect-[1.75]" {...handlers}>
				{slides.map(({ gatsbyImageData, alt }, i) => (
					<GatsbyImage
						image={gatsbyImageData}
						className={cn('!absolute transition-opacity duration-500 top-0 left-0', {
							'opacity-100 ': currentSlide === i,
							'opacity-0': currentSlide !== i,
						})}
						alt={alt}
					/>
				))}
			</div>
			<div className="text-center">
				{slides.map((_, i) => (
					<button
						type="button"
						className={cn('inline-block w-4 h-4 rounded-full mr-2 last:mr-0', {
							'bg-brand-200': currentSlide !== i,
							'bg-brand-400': currentSlide === i,
						})}
						onClick={() => navigateToIndex(i)}
					>
						<span className="sr-only">Go to slide {i + 1}</span>
					</button>
				))}
			</div>
		</div>
	);
};

export default Carousel;
