import React, { useRef, useEffect } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import cn from 'classnames';
import groupBy from 'group-by';
import { graphql } from 'gatsby';
import { useLocation } from '@reach/router';
import { LOCALE_CONFIG, LOCALE_PATHS, buildLink, SUPPORTED_LOCALES } from '../utils/locale';
import DefaultLayout from '../layouts/Default';

import { useVar } from '../components/Var';
import HrefLang from '../components/HrefLang';
import Breadcrumbs, { Breadcrumb } from '../components/Breadcrumbs';

import useFuse from '../components/useFuse';
import SearchBar from '../components/SearchBar';
import SEO from '../components/SEO';
import Pagination from '../components/Pagination';
import scrollDirection from '../utils/scrollDirection';

import Question, { format, isValidForLocale } from '../components/Support/Question';
import usePagination from '../hooks/usePagination';
import usePaginatedSearch from '../hooks/usePaginatedSearch';

const fuseOptions = {
	keys: [
		{
			name: 'question',
			weight: 0.7,
		},
		{
			name: 'answer',
			weight: 0.3,
		},
	],
};

const ProductCard = ({ productName, children, image, isApp, href }) => (
	<li>
		<a
			href={href}
			className={cn(
				'block h-full px-6 py-8 text-center border rounded shadow-none hover:shadow-lg transition-shadow duration-150',
				{
					'border-brand-400': !isApp,
					'border-secondary-300': isApp,
				},
			)}
		>
			<div
				className={cn(
					'p-5 rounded-full w-16 h-16 mx-auto mb-4',
					isApp ? 'bg-secondary-100' : 'bg-brand-200',
				)}
			>
				<img alt={`${productName} Logo`} src={image} />
			</div>
			<h4 className="mb-1 text-lg font-bold">{productName}</h4>
			<p>{children}</p>
		</a>
	</li>
);

const ContactUs = () => {
	const salesNumber = useVar('salesNumber');
	return (
		<div className="bg-gray-200 flex flex-col space-y-2 rounded md:items-center md:justify-between p-6 mb-8 ">
			<p className="font-bold">
				Still got a question about using BrightHR? Our friendly service team is here to
				help.{' '}
			</p>
			<p className="text-xl font-bold text-brand-500">Call {salesNumber}</p>
		</div>
	);
};

const SupportFAQs = ({ data, pageContext }) => {
	const PER_PAGE = 9;
	const groupedTopics = groupBy(data.allDatoCmsSupportTopic.nodes, 'colour');

	const pinkProducts = Object.values(groupedTopics)[0];
	const blueProducts = Object.values(groupedTopics)[1];

	const topics = blueProducts.concat(pinkProducts);

	const listRef = useRef();
	const searchInputRef = useRef();

	const scroll = scrollDirection();
	const location = useLocation();
	const locale = LOCALE_PATHS[pageContext.locale];
	const fullLocale = LOCALE_CONFIG[locale].name;

	const questions = data.allDatoCmsSupportQuestion.nodes
		.filter((q) => isValidForLocale(q, pageContext.locale))
		.map((q) => format(q, pageContext.locale));

	const { result, search } = useFuse(questions, fuseOptions);

	const { page, pageNumbers, currentPageResults } = usePagination(result.length, PER_PAGE);
	const { searchInput, showSearchResults, clearSearch, handleKeyDown, delayedURLUpdate } =
		usePaginatedSearch({
			search,
			searchInputRef,
		});

	useEffect(() => {
		if (localStorage.getItem('storedSearchTerm')) {
			search(localStorage.getItem('storedSearchTerm'));
			localStorage.removeItem('storedSearchTerm');
		}
	}, [search]);

	return (
		<DefaultLayout fullWidth>
			<HrefLang locales={SUPPORTED_LOCALES} slug="help/faqs" />
			<SEO
				path={location.pathname}
				title="Frequently Asked Questions | FAQ's"
				description={`These frequently asked questions (FAQ's) may help you with your Bright HR ${fullLocale} query. See our most commonly asked questions here. `}
			/>
			<section className="flex flex-row max-w-screen-xl mx-auto bg-right-top bg-no-repeat bg-contain">
				<div className="w-full px-6 py-8 bg-white grow md:pr-12">
					<Breadcrumbs className="hidden mb-4 md:flex">
						<Breadcrumb
							href={buildLink(
								pageContext.locale,
								`help?page=1${searchInput ? `&search=${searchInput}` : ''}`,
							)}
							position={0}
						>
							Support
						</Breadcrumb>
						<Breadcrumb position={1}>FAQs</Breadcrumb>
					</Breadcrumbs>
					<h1 className="mb-3 text-2xl font-bold lg:text-4xl md:mt-12">FAQs</h1>
					<p className=" lg:text-xl">{data.datoCmsSupport.faqsDescription}</p>
				</div>
				<StaticImage
					data-cy="support-faqs-hero-image"
					src="../images/support/faqs-hero.png"
					alt="faqs banner"
					className="hidden w-4/12 ml-auto shrink-0 md:block"
					placeholder="eager"
				/>
			</section>
			<div className="px-6 mx-auto mt-8 md:mt-14 max-w-7xl">
				<SearchBar
					listRef={listRef}
					className="mb-8"
					value={searchInput}
					onChange={(e) => {
						showSearchResults(e, false);
						delayedURLUpdate(e);
					}}
					placeholder="What do you need help with?"
					clearSearch={clearSearch}
					scroll={scroll}
					ref={searchInputRef}
					onKeyDown={handleKeyDown}
				/>

				<div
					className={cn(
						'rounded bg-brand-100',
						searchInput.length >= 3 ? 'block p-8 pt-6 mb-8' : 'hidden',
					)}
					ref={listRef}
				>
					{searchInput.length >= 3 && (
						<>
							<h3 className="mb-6">
								Search results <span className="font-bold">({result.length})</span>
							</h3>
							<ul className="space-y-4">
								{currentPageResults(result).map((question) => (
									<li key={question.id}>
										<Question {...question} locale={pageContext.locale} />
									</li>
								))}
							</ul>
							{result.length > PER_PAGE && (
								<Pagination
									page={page}
									pageNumbers={pageNumbers}
									searchInput={searchInput}
									className="mt-6 mb-0"
								/>
							)}
						</>
					)}
				</div>
				{searchInput.length < 3 && (
					<>
						<h2 className="mt-6 mb-8 text-2xl text-center">Browse FAQs by product</h2>

						<div className="mb-8">
							<ul className="grid gap-6 lg:grid-cols-3 md:grid-cols-2">
								{topics
									.filter((t) => t.name !== null)
									.map(({ name, slug, icon, colour, description }) => (
										<ProductCard
											key={name}
											href={buildLink(
												pageContext.locale,
												`help/faqs/${slug}`,
											)}
											productName={name}
											image={icon.fixed.src}
											isApp={colour === 'pink'}
										>
											{description}
										</ProductCard>
									))}
							</ul>
						</div>
					</>
				)}

				<ContactUs />
			</div>
		</DefaultLayout>
	);
};

export default SupportFAQs;

export const query = graphql`
	query supportPage($locale: String!) {
		allDatoCmsSupportTopic(
			filter: { locale: { eq: $locale } }
			sort: { fields: name, order: ASC }
		) {
			nodes {
				name
				slug
				description
				icon {
					fixed(width: 60, height: 60) {
						src
					}
				}
				colour
			}
		}
		datoCmsSupport(locale: { eq: $locale }) {
			faqsDescription
			seoMetaTags {
				...GatsbyDatoCmsSeoMetaTags
			}
		}
		allDatoCmsSupportQuestion(filter: { locale: { eq: $locale } }) {
			nodes {
				locale
				showInAu
				showInCa
				showInIe
				showInNz
				showInUk
				topic {
					name
				}
				subTopic {
					slug
					name
					image {
						fixed(width: 650, imgixParams: { auto: "compress", q: 60 }) {
							srcSet
							src
						}
					}
				}
				...QuestionCard
			}
		}
	}
`;
