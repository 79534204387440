/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes, { shape, array } from 'prop-types';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import { useStaticQuery, graphql } from 'gatsby';
import formatPathForCanonical from '../../utils/formatPathForCanonical';
import { DEFAULT_LOCALE } from '../../utils/locale';
import SeoSchema from '../SeoSchema';

function DatoSEO({
	meta,
	lang = DEFAULT_LOCALE,
	path,
	children,
	datoSchema,
	schemaType = 'WebSite',
	autoSchema,
	noSchema,
}) {
	const { site } = useStaticQuery(
		graphql`
			query {
				site {
					siteMetadata {
						siteUrl
					}
				}
			}
		`,
	);

	let backupSchema;

	if (autoSchema === undefined) {
		const title = meta.tags.filter((tag) => tag.tagName === 'title')[0].content;
		const appendedPageTitle = title.endsWith(` | ${site.siteMetadata.title}`)
			? title
			: `${title} | ${site.siteMetadata.title}`;
		backupSchema = {
			name: appendedPageTitle,
			url: `${site.siteMetadata.siteUrl}${path}`,
			sameAs: [
				'https://www.facebook.com/BrightHRcom?fref=ts',
				'https://twitter.com/brighthr_UK',
				'https://www.youtube.com/channel/UCKvE4P25zlQ5TbpaZweyIcg',
				'https://www.linkedin.com/company/brighthr',
			],
		};
	}

	return (
		<>
			<HelmetDatoCms
				htmlAttributes={{
					lang,
				}}
				seo={meta}
			>
				{children}
				<link
					rel="canonical"
					href={`${site.siteMetadata.siteUrl}/${formatPathForCanonical(path)}`}
				/>
			</HelmetDatoCms>
			{!noSchema && (
				<SeoSchema
					datoSchema={datoSchema}
					schemaType={schemaType}
					autoSchema={autoSchema || backupSchema}
				/>
			)}
		</>
	);
}

DatoSEO.defaultProps = {
	lang: `en`,
	meta: {
		tags: [],
	},
};

DatoSEO.propTypes = {
	lang: PropTypes.string,
	path: PropTypes.string.isRequired,
	meta: shape({
		// eslint-disable-next-line react/forbid-prop-types
		tags: array,
	}),
};

export default DatoSEO;
