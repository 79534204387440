import React from 'react';
import { graphql } from 'gatsby';
import { useLocation } from '@reach/router';
import DefaultLayout from '../layouts/Default';
import DatoSEO from '../components/DatoSEO';
import StepByStep from '../components/CMSBlocks/StepByStep';
import IntegrationStack from '../components/CMSBlocks/IntegrationStack';
import ThreeStep from '../components/CMSBlocks/ThreeStep';
import ZapierHero from '../images/partners/Zapier_Hero.svg';
import BackgroundBrightLogo from '../images/vectors/BackgroundBrightLogoBlue.svg';
import ZapierLogo from '../images/partners/zapier-logo.svg';
import { localisedRead } from '../utils/locale';
import HrefLang from '../components/HrefLang';

const IntegrationPage = ({ data, pageContext }) => {
	const location = useLocation();
	const {
		stepByStep,
		seoMetaTags,
		stepHeaderOne,
		stepHeaderTwo,
		stepHeaderThree,
		integrationHeaderOne,
		integrationHeaderTwo,
		slug,
		title,
		description,
		threeStep,
		_allSlugLocales,
		_allSeoLocales,
	} = data.datoCmsIntegration;

	const integrationBackground = () => {
		if (slug === 'zapier') {
			return "lg:bg-[url('../images/partners/Zapier_Background.svg')]";
		}
	};

	return (
		<>
			<HrefLang
				locales={_allSeoLocales}
				slug={(hreflang) => localisedRead(_allSlugLocales, hreflang)}
				lang={pageContext.locale}
			/>
			<DatoSEO path={location.pathname} meta={seoMetaTags} lang={pageContext.locale} />

			<div className="max-w-full overflow-hidden">
				<div className="mx-auto bg-gradient-to-b from-brand-200/20">
					<div className="flex flex-row mx-auto max-w-screen-xxl flex-nowrap">
						<div className="flex flex-col max-w-3xl gap-4 mx-4 mb-12 lg:shrink-0 lg:w-1/2 md:mx-12 md:gap-10">
							<div className="flex flex-row mt-6 mr-auto bg-white border rounded-lg shadow-md md:mt-12 border-neutral-300">
								<img
									src={BackgroundBrightLogo}
									alt="BrightHR logo"
									className="w-8 p-2"
								/>
								<div className="border-r border-neutral-300" />
								<img src={ZapierLogo} alt="Zapier logo" className="w-8 p-2" />
							</div>
							<h1 className="text-3xl font-bold lg:text-5xl">{title}</h1>
							<p className="text-lg">{description}</p>
						</div>

						<img
							src={ZapierHero}
							alt="Zapier Hero"
							className="relative hidden max-w-3xl -translate-y-36 xl:translate-y-0 xl:absolute xl:top-0 xl:max-w-4xl xl:-right-36 lg:block xxl:-translate-y-24 xxl:relative xxl:right-0 xxl:-mb-36 lg:-mb-44"
						/>
					</div>
					<div className="pb-16 lg:pb-32 xl:mt-12 xxl:mt-0">
						<ThreeStep threeStep={threeStep} />
					</div>
				</div>

				<div
					className={`relative mb-12 md:mb-20 bg-no-repeat bg-none lg:bg-center ${integrationBackground()}`}
				>
					<div>
						<IntegrationStack
							integrationHeaderOne={integrationHeaderOne}
							integrationHeaderTwo={integrationHeaderTwo}
						/>
					</div>
				</div>

				<div className="mb-6 md:mb-36 bg-gradient-to-b from-brand-200/20">
					<StepByStep
						steps={stepByStep}
						stepHeaderOne={stepHeaderOne}
						stepHeaderTwo={stepHeaderTwo}
						stepHeaderThree={stepHeaderThree}
					/>
				</div>
				<div className="flex items-center h-20 md:h-32 bg-neutral-700 ">
					<p className="mx-auto text-center text-white">
						Need some help? Check out our{' '}
						<a
							href="https://zapier.com/apps/brighthr/help"
							target="_blank"
							rel="noopener noreferrer"
							className="text-zapier-orange"
						>
							Zapier Help and Support Docs.
						</a>
					</p>
				</div>
			</div>
		</>
	);
};

const WrappedIntegrationPage = (props) => (
	<DefaultLayout fullWidth>
		<IntegrationPage {...props} />
	</DefaultLayout>
);

export default WrappedIntegrationPage;

export const query = graphql`
	query integrationQuery($slug: String, $locale: String!) {
		datoCmsIntegration(locale: { eq: $locale }, slug: { eq: $slug }) {
			stepHeaderOne
			stepHeaderThree
			stepHeaderTwo
			integrationHeaderOne
			integrationHeaderTwo
			slug
			title
			description
			_allSeoLocales {
				locale
			}
			_allSlugLocales {
				locale
				value
			}
			threeStep {
				textNode {
					childMdx {
						body
					}
				}
				image {
					alt
					gatsbyImageData
				}
			}
			seoMetaTags {
				...GatsbyDatoCmsSeoMetaTags
			}
			stepByStep {
				image {
					alt
					gatsbyImageData(width: 560, height: 315)
				}
				textNode {
					childMdx {
						body
					}
				}
			}
		}
	}
`;
