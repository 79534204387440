/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import MasterLayout from '../Master';
import MinimalHeader from '../../components/MinimalHeader';

const MinimalLayout = ({ children, fullWidth, showTAndCs = false }) => {
	const forms = ['/buy-online/your-details', '/buy-online/your-company'];

	const isFormPage = forms.includes(window.location.pathname);

	return (
		<MasterLayout>
			<MinimalHeader />
			<div
				className={cn({
					'container p-6 mx-auto overflow-visible': !fullWidth,
				})}
			>
				<main className={`${!isFormPage && 'mb-24 lg:mb-0'}`}>{children}</main>
				{!isFormPage && showTAndCs && (
					<footer className="mb-24">
						<p className="pb-24">
							<a
								className="text-brand-500 hover:text-brand-400"
								href="/terms/"
								target="_blank"
							>
								Terms & privacy
							</a>{' '}
							| Copyright © 2020 BrightHR
						</p>
					</footer>
				)}
			</div>
		</MasterLayout>
	);
};

MinimalLayout.propTypes = {
	children: PropTypes.node.isRequired,
};

export default MinimalLayout;
