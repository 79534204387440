import React from 'react';
import cn from 'classnames';

export default ({ id, children, className, ...props }) => (
	<label
		{...props}
		htmlFor={id}
		className={cn('block text-gray-900 text-base font-bold leading-6', className)}
	>
		{children}
	</label>
);
