import React from 'react';
import NavArrow from '../../../icons/nav-arrow.svg';

function MenuColumn({ activeItem, navLinks, setActiveItem }) {
	return (
		<ul className="flex flex-col w-full gap-2">
			{navLinks.map((link) => {
				const { description, displayText, linkUrl } = link;
				const isActive = link.id === activeItem.id;
				return (
					<li
						key={link.displayText}
						className={`rounded px-3 py-2 ${isActive ? 'bg-white' : 'bg-none'}`}
						onMouseEnter={(e) => {
							e.preventDefault();
							setActiveItem(link);
						}}
					>
						<div className="relative flex h-full min-h-[68px] flex-col justify-center">
							{isActive && (
								<div className="visible absolute -right-[39.5px] top-[50%] -translate-x-1/2 -translate-y-1/2">
									<NavArrow />
								</div>
							)}
							{linkUrl ? (
								<a
									href={linkUrl}
									className={`mb-1 w-fit border-b-[1px] text-left font-semibold leading-[14px] delay-150 duration-150 ease-in-out ${
										isActive ? 'border-white' : 'border-inherit'
									} cursor-pointer text-16 text-primary-700 hover:border-brand-400 hover:text-brand-400`}
								>
									{displayText}
								</a>
							) : (
								<p className="font-semibold text-left cursor-default text-16 text-primary-700">
									{displayText}
								</p>
							)}
							<p className="hidden text-sm cursor-default text-neutral-500 lg:block">
								{description}
							</p>
						</div>
					</li>
				);
			})}
		</ul>
	);
}

export default MenuColumn;
