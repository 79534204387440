const VideoSource = (videoId) => {
	const isNum = /^\d+$/.test(videoId);

	if (!isNum) {
		return `https://www.youtube.com/embed/${videoId}?&modestbranding=true&rel=0&autoplay=1`;
	}

	if (isNum) {
		return videoId.includes('?')
			? `https://player.vimeo.com/video/${videoId}`
			: `https://player.vimeo.com/video/${videoId}?autoplay=1`;
	}

	return '';
};

export default VideoSource;
