import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import Buttons from '../Buttons';
import Laptop from '../../../../images/blog/Laptop-blue-bg.png';
import Phone from '../../../../images/blog/Phone-blue-bg.png';

const Device = ({ deviceType, deviceImage, buttonData }) => {
	if (deviceType === 'laptop') {
		return (
			<div className="lg:flex lg:justify-center lg:-mb-4">
				<div className="z-10 flex items-center justify-center w-full mx-auto lg:w-auto lg:max-w-md">
					<div className="relative mt-4 overflow-hidden lg:m-0">
						<GatsbyImage
							alt={deviceImage.alt || ''}
							className="!absolute top-[4%] left-0 right-0 w-3/4 h-[70%] mx-auto"
							image={deviceImage.gatsbyImageData}
						/>

						<img alt="" src={Laptop} className="relative mx-auto" />
					</div>
				</div>
				<div className="relative z-30 block w-full px-4 lg:hidden">
					<Buttons buttonData={buttonData} />
				</div>
			</div>
		);
	}

	if (deviceType === 'phone') {
		return (
			<div className="lg:flex lg:justify-center">
				<div className="relative block px-4 mt-3 lg:hidden">
					<Buttons buttonData={buttonData} />
				</div>
				<div className="flex items-center justify-center w-full max-w-sm mx-auto lg:w-auto -mb-36">
					<div className="relative mt-4 overflow-hidden lg:m-0">
						<GatsbyImage
							alt={deviceImage.alt || ''}
							className="!absolute top-[4%] left-0 right-0 w-3/4 mx-auto"
							image={deviceImage.gatsbyImageData}
						/>

						<img alt="" src={Phone} className="relative mx-auto" />
					</div>
				</div>
			</div>
		);
	}

	return (
		<div className="relative z-30 block w-full px-4 mt-3 lg:hidden">
			<Buttons buttonData={buttonData} />
		</div>
	);
};

export default Device;
