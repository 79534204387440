import React, { useState, useEffect } from 'react';
import { useLocation } from '@reach/router';
import Cookies from 'js-cookie';
import { getLocale, LOCALE_PATHS, LOCALE_CONFIG, DEFAULT_LOCALE } from '../../utils/locale';
import Cross from '../../icons/Cross.svg';
import CountryModal from '../CountryModal';

const LangNotify = () => {
	const [alts, setAlts] = useState([]);
	const [show, setShow] = useState(false);
	const [countryCode, setCountryCode] = useState('');

	const location = useLocation();
	const cookieLocale = LOCALE_CONFIG[Cookies.get('nf_country')];
	const pathLocale = LOCALE_CONFIG[getLocale(location.pathname)];
	const prefferedLocal = alts.find((alt) => alt.locale === countryCode);

	useEffect(() => {
		if (cookieLocale) {
			setCountryCode(cookieLocale.countryCode);
		}

		if (countryCode !== pathLocale.countryCode && prefferedLocal) {
			setShow(true);
		} else if (countryCode === pathLocale.countryCode && prefferedLocal) {
			setShow(false);
		}
	}, [cookieLocale, countryCode, pathLocale, prefferedLocal]);

	useEffect(() => {
		// Hacky (use event emitter?)
		setTimeout(() => {
			const links = document.querySelectorAll('[hreflang]');

			setAlts(
				Array.from(links)
					.map((link) => {
						const locale =
							link.hreflang === 'x-default'
								? DEFAULT_LOCALE
								: LOCALE_PATHS[link.hreflang] || 'en';

						return locale
							? {
									locale,
									lang: link.hreflang,
									link: link.href,
							  }
							: null;
					})
					.filter(Boolean),
			);
		}, 300);
	}, []);

	useEffect(() => {
		if (countryCode !== pathLocale.countryCode && prefferedLocal) {
			setShow(true);
		}
	}, [countryCode, pathLocale.countryCode, prefferedLocal]);

	if (!alts.length) return null;

	if (show) {
		return <CountryModal setShow={setShow} prefferedUrl={prefferedLocal.link} />;
	}

	// Leave below as is for now

	if (countryCode !== pathLocale.countryCode && !prefferedLocal) {
		return (
			<div className="relative flex items-center justify-center p-8 text-lg border-white rounded text-brand-500 bg-brand-200">
				You are viewing a {pathLocale.name} page. Some content might not be applicable.
				<button
					type="button"
					onClick={() => setAlts([])}
					className="absolute top-0 right-0 w-4 mt-4 mr-4"
				>
					<Cross />
				</button>
			</div>
		);
	}

	return null;
};

export default LangNotify;
