// eslint-disable-next-line max-classes-per-file
export class AccountAvailabilityError extends Error {
	constructor(message) {
		super(message);

		if (Error.captureStackTrace) {
			Error.captureStackTrace(this, AccountAvailabilityError);
		}

		this.name = 'AccountAvailabilityError';
	}
}

export class CustomerSetupError extends Error {
	constructor(message) {
		super(message);

		if (Error.captureStackTrace) {
			Error.captureStackTrace(this, CustomerSetupError);
		}

		this.name = 'CustomerSetupError';
	}
}

export class PurchaseError extends Error {
	constructor(message) {
		super(message);

		if (Error.captureStackTrace) {
			Error.captureStackTrace(this, PurchaseError);
		}

		this.name = 'PurchaseError';
	}
}

export class PaymentMethodCreationError extends Error {
	constructor(message) {
		super(message);

		if (Error.captureStackTrace) {
			Error.captureStackTrace(this, PaymentMethodCreationError);
		}

		this.name = 'PaymentMethodCreationError';
	}
}

export class OrderError extends Error {
	constructor(message, status) {
		super(message);

		if (Error.captureStackTrace) {
			Error.captureStackTrace(this, OrderError);
		}

		this.name = 'OrderError';
		this.statusCode = status;
	}
}
