import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import CopyBlock from '../../CopyBlock';
import CopyBlockProvider from '../../CopyBlock/provider';

const FeatureImageWithCopy = ({
	imgSrc,
	alt,
	header,
	copy,
	imagePosition,
	tickedBulletPoints = false,
}) => (
	<div className="flex flex-wrap items-center justify-center gap-4 mx-auto md:flex-nowrap max-w-7xl">
		<div
			className={`order-0 w-full pt-1 mb-6 lg:mb-0 md:w-2/5 lg:w-1/2 ${
				imagePosition === 'Left' ? 'md:order-0' : 'md:order-1'
			}`}
		>
			<GatsbyImage
				image={imgSrc}
				alt={alt || ''}
				loading="lazy"
				className="w-full max-w-md mx-auto lg:max-w-lg"
			/>
		</div>
		<div
			className={`md:px-6 md:w-3/5 lg:w-1/2 xl:max-w-lg ${
				imagePosition === 'Right' ? 'lg:pr-28' : 'lg:pl-28'
			}`}
		>
			<h2 className="mb-4 text-2xl font-bold text-center md:text-left">{header}</h2>

			<div className="text-center text-gray-700 md:text-left">
				{tickedBulletPoints ? (
					<CopyBlockProvider>
						<CopyBlock>{copy.childMdx.body}</CopyBlock>
					</CopyBlockProvider>
				) : (
					<CopyBlock>{copy.childMdx.body}</CopyBlock>
				)}
			</div>
		</div>
	</div>
);

export default FeatureImageWithCopy;
