import React from 'react';
import cn from 'classnames';

export default ({ children, highlight }) => (
	<fieldset
		className={cn('m-0', {
			'bg-gray-200 p-4 rounded': highlight,
		})}
	>
		<div className="space-y-3">{children}</div>
	</fieldset>
);
