import { graphql } from 'gatsby';
import React, { useRef } from 'react';

import { useLocation } from '@reach/router';
import DefaultLayout from '../layouts/Default';
import Breadcrumbs, { Breadcrumb } from '../components/Breadcrumbs';
import DownloadCards from '../components/DownloadCards';
import DatoSEO from '../components/DatoSEO';
import useFuse from '../components/useFuse';
import HrefLang from '../components/HrefLang';
import { buildLink, LOCALE_PATHS } from '../utils/locale';
import Container from '../components/Container';
import Pagination from '../components/Pagination';
import CrossIcon from '../icons/Cross.svg';
import usePagination from '../hooks/usePagination';
import DownloadCategoryList from '../components/DownloadCategoryList';
import usePaginatedSearch from '../hooks/usePaginatedSearch';

const fuseOptions = {
	keys: ['title', 'breadcrumbText', 'bodyNode.childMdx.excerpt'],
};

const PER_PAGE = 6;

const DownloadCategory = ({ data, pageContext }) => {
	const { title, _allSeoLocales, seoMetaTags, slug } = data.datoCmsDownloadCategory;
	const searchInputRef = useRef();
	const { nodes: downloads } = data.downloads;
	const listRef = useRef();
	const { result, search } = useFuse(downloads, fuseOptions);
	const location = useLocation();
	const locale = LOCALE_PATHS[pageContext.locale];
	const { nodes: downloadCategory } = data.downloadCategory;
	const { page, pageNumbers, currentPageResults } = usePagination(result.length, PER_PAGE);
	const { showSearch, searchInput, showSearchResults, clearSearch, handleKeyDown } =
		usePaginatedSearch({ search, searchInputRef });

	return (
		<DefaultLayout fullWidth>
			<HrefLang locales={_allSeoLocales} slug={`download-centre/${slug}`} />
			<DatoSEO path={location.pathname} meta={seoMetaTags} lang={pageContext.locale} />

			<div className="py-4 bg-gray-200 md:mb-8">
				<Container>
					<Breadcrumbs>
						<Breadcrumb href={buildLink(locale, '/')} position={0}>
							Home
						</Breadcrumb>
						<Breadcrumb href={buildLink(locale, 'download-centre')} position={1}>
							Download Centre
						</Breadcrumb>
						<Breadcrumb position={2}>{title}</Breadcrumb>
					</Breadcrumbs>
					<h1 className="mt-4 mb-8 text-2xl font-bold tracking-tight">{title}</h1>

					<DownloadCategoryList
						downloadCategory={downloadCategory}
						search={searchInput}
						downloadFilter={title}
						locale={locale}
					/>

					<label htmlFor="search">
						<div className="relative flex bg-white rounded-md shadow-lg">
							<svg
								className="absolute -translate-y-1/2 top-1/2 left-4 hover:cursor-pointer"
								width="18"
								height="18"
								viewBox="0 0 18 18"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
								onClick={showSearchResults}
								title="Search"
								tabIndex="0"
							>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M1.79993 8.09969C1.79993 4.62043 4.62043 1.79993 8.09969 1.79993C11.579 1.79993 14.3995 4.62043 14.3995 8.09969C14.3995 9.79758 13.7278 11.3386 12.6356 12.4715C12.6055 12.4948 12.5765 12.5202 12.5488 12.5479C12.5211 12.5756 12.4956 12.6046 12.4723 12.6348C11.3393 13.7274 9.79798 14.3995 8.09969 14.3995C4.62043 14.3995 1.79993 11.579 1.79993 8.09969ZM13.1559 14.4278C11.7702 15.5364 10.0124 16.1994 8.09969 16.1994C3.62635 16.1994 0 12.573 0 8.09969C0 3.62635 3.62635 0 8.09969 0C12.573 0 16.1994 3.62635 16.1994 8.09969C16.1994 10.0119 15.5367 11.7693 14.4286 13.1549L17.7364 16.4628C18.0879 16.8142 18.0879 17.384 17.7364 17.7355C17.3849 18.087 16.8151 18.087 16.4637 17.7355L13.1559 14.4278Z"
									fill="#004991"
								/>
							</svg>

							<input
								id="search"
								className="flex-1 p-4 pl-12"
								placeholder={`Search ${title}...`}
								onChange={showSearchResults}
								onKeyDown={handleKeyDown}
								value={searchInput}
								ref={searchInputRef}
							/>

							{showSearch && (
								<CrossIcon
									className="absolute w-4 -translate-y-1/2 top-1/2 right-4 hover:cursor-pointer"
									onClick={clearSearch}
									tabindex="0"
								/>
							)}
						</div>
					</label>
				</Container>
			</div>

			<Container>
				<div ref={listRef}>
					<h2 className="mb-4 text-2xl font-bold">
						{!showSearch && `All ${title}`}
						{showSearch && result.length > 0 && `All results for '${searchInput}'`}
						{showSearch &&
							result.length === 0 &&
							`We couldn't find any documents that matched your search.`}
					</h2>
					<DownloadCards
						className="flex flex-row flex-wrap justify-center md:justify-start md:ml-auto"
						cardClassName="max-w-[300px] md:max-w-full md:w-1/2 md:odd:pl-0 md:even:pr-0"
						downloads={currentPageResults(result)}
						locale={locale}
					/>
					{result.length > PER_PAGE && (
						<Pagination
							page={page}
							searchInput={searchInput}
							pageNumbers={pageNumbers}
						/>
					)}
				</div>
			</Container>
		</DefaultLayout>
	);
};

export default DownloadCategory;

export const query = graphql`
	query DownloadCategory($id: String, $locale: String!) {
		datoCmsDownloadCategory(id: { eq: $id }) {
			_allSeoLocales {
				locale
			}
			seoMetaTags {
				...GatsbyDatoCmsSeoMetaTags
			}
			id
			slug
			title
		}
		downloads: allDatoCmsDownload(
			filter: { category: { id: { eq: $id } }, locale: { eq: $locale }, slug: { ne: null } }
			sort: { order: DESC, fields: meta___firstPublishedAt }
		) {
			nodes {
				bodyNode {
					childMdx {
						excerpt(pruneLength: 140)
					}
				}
				title
				breadcrumbText
				id
				slug
				hideImageOverlay
				imageOverlayText
				meta {
					updatedAt
				}
				heroImage {
					gatsbyImageData(width: 180, imgixParams: { auto: "compress" })
				}
				document {
					size
				}
				category {
					id
					slug
					title
					locale
				}
			}
		}
		downloadCategory: allDatoCmsDownloadCategory(
			filter: { locale: { eq: $locale }, slug: { ne: null } }
		) {
			nodes {
				id
				slug
				locale
				title
			}
		}
	}
`;
