import React, { useState } from 'react';
import VimeoThumbnail from '../../VimeoThumbnail';
import VideoModal from '../../VideoModal';
import PlayArrow from '../../../icons/PlayArrow.svg';

const VideoCard = ({ title, vimeoId, topic }) => {
	const [open, setOpen] = useState(false);

	return (
		<li className="bg-white rounded shadow-md">
			<div
				role="button"
				tabIndex="0"
				onClick={(e) => {
					e.preventDefault();
					setOpen(true);
				}}
				onKeyDown={(e) => {
					if (e.key === 'Enter') {
						setOpen(true);
					}
				}}
				className="flex flex-col h-full flex-nowrap"
			>
				<div className="relative bg-cover aspect-video shrink-0 bg-brand-400">
					<VimeoThumbnail id={vimeoId} alt={`${title} thumbnail`} />

					<svg
						className="absolute bottom-0 right-0 m-4"
						width="24"
						height="24"
						viewBox="0 0 24 24"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<circle cx="12" cy="12" r="12" fill="white" />
						<path d="M16.8 11.975L9.60002 8V15.95L16.8 11.975Z" fill="#0057AD" />
					</svg>
				</div>

				<div className="flex flex-col justify-between h-full p-5 flex-nowrap">
					<div className="mb-4">
						<button
							type="button"
							className="text-lg font-bold leading-7 text-left"
							onClick={() => setOpen((o) => !o)}
						>
							{title}
						</button>
					</div>
					<div className="mt-auto">
						<span className="inline-block w-auto px-3 py-1 mt-auto text-xs leading-4 bg-gray-200 rounded-full">
							<PlayArrow className="inline mr-2" />
							<strong className="inline-block leading-6">{topic.name}</strong>
						</span>
					</div>
				</div>
			</div>

			{open && (
				<VideoModal vimeoId={vimeoId} videoTitle={title} onClose={() => setOpen(false)} />
			)}
		</li>
	);
};

export default VideoCard;
