import { Helmet } from 'react-helmet';
import React from 'react';

// schemaType should be one of the types specificed in the Google Docs:
// https://developers.google.com/search/docs/appearance/structured-data/search-gallery

const SeoSchema = ({ datoSchema, schemaType = 'WebSite', autoSchema }) => {
	const schemaObj = {
		'@context': 'https://schema.org',
		'@type': schemaType,
		...autoSchema,
	};

	return (
		<Helmet>
			<script key="ld+json" type="application/ld+json">
				{datoSchema || JSON.stringify(schemaObj)}
			</script>
		</Helmet>
	);
};

export default SeoSchema;
