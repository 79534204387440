import React from 'react';
import ValidationAlert from '@brighthr/component-alert';

const BigTotal = ({ quoteReady, price, errorMessage }) => (
	<>
		{!!errorMessage && (
			<ValidationAlert border icon="error" size="xs" type="warning">
				{errorMessage || ''}
			</ValidationAlert>
		)}
		{quoteReady ? (
			<div className="flex flex-col mb-5 lg:flex-row" data-testid="bigTotal">
				<div className="flex flex-col font-bold">
					<div className="flex flex-row items-baseline flex-nowrap">
						<span className="text-3xl leading-10">£</span>
						<span className="text-6xl leading-none">{price}</span>
					</div>
				</div>
				<div className="lg:ml-3 lg:text-gray-600">/ month</div>
			</div>
		) : (
			<div className="w-full h-20 mt-2 mb-5 bg-gray-300 border-2 border-gray-300 rounded-md lg:bg-gray-200 lg:h-14 lg:border-0 lg:mb-6 lg:mt-4 animate-pulse" />
		)}
	</>
);

export default BigTotal;
