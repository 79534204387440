import React from 'react';
import useCountdown from '../../hooks/useCountdown';

const DateTimeDisplay = ({ value, type, className }) => (
	<div
		className={`${className} uppercase w-full m-2 text-center border-2 rounded-lg md:m-5 border-primary-400`}
	>
		<p className="text-2xl font-bold md:text-6xl text-primary-700">{value}</p>
		<p className="uppercase">{type}</p>
	</div>
);

const ClockDisplay = ({ days, hours, minutes, seconds }) => (
	<div className="flex flex-row items-center w-full justify-evenly">
		<DateTimeDisplay value={days} type="Days" />
		<span className="-mt-3 text-2xl font-bold md:text-6xl text-primary-700">:</span>
		<DateTimeDisplay value={hours} type="Hours" />
		<span className="-mt-3 text-2xl font-bold md:text-6xl text-primary-700">:</span>
		<DateTimeDisplay value={minutes} type="Minutes" />
		<span className="hidden -mt-3 text-2xl font-bold md:text-6xl text-primary-700 xs:block">
			:
		</span>
		<DateTimeDisplay value={seconds} type="Seconds" className="hidden xs:block" />
	</div>
);

const CountdownTimer = ({ targetDate }) => {
	const [days, hours, minutes, seconds] = useCountdown(targetDate);

	if (days + hours + minutes + seconds <= 0) {
		return <p>Webinar has started.</p>;
	}
	return <ClockDisplay days={days} hours={hours} minutes={minutes} seconds={seconds} />;
};

export default CountdownTimer;
