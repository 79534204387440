import { graphql } from 'gatsby';
import React, { useState, useRef, useEffect, useMemo } from 'react';
import loadScript from 'load-script2';
import { useLocation } from '@reach/router';

import Layout from '../layouts/Default';
import SEO from '../components/SEO';
import useFuse from '../components/useFuse';
import CopyBlock from '../components/CopyBlock';
import Pagination from '../components/Pagination';
import CrossIcon from '../icons/Cross.svg';
import WebinarCards from '../components/ResourceCards/Webinar';
import { LOCALE_PATHS } from '../utils/locale';
import HrefLang from '../components/HrefLang';
import Container from '../components/Container';
import LiveWebinarCard from '../components/CMSBlocks/LiveWebinarCard';
import ClientOnly from '../utils/client-only';
import usePagination from '../hooks/usePagination';
import usePaginatedSearch from '../hooks/usePaginatedSearch';

const fuseOptions = {
	keys: ['title', 'breadcrumbText', 'bodyNode.childMdx.excerpt'],
};

const WebinarLibrary = ({ data, pageContext }) => {
	const { bodyNode, webinarsPerPage, seo, _allSeoLocales } = data.datoCmsWebinarLibrary;
	const { nodes: webinars } = data.webinars;
	const { nodes: allLiveWebinars } = data.liveWebinarData;
	const [isMobile, setIsMobile] = useState();
	const { result, search } = useFuse(webinars, fuseOptions);
	const listRef = useRef();
	const searchInputRef = useRef();
	const location = useLocation();
	const locale = LOCALE_PATHS[pageContext.locale];

	useEffect(() => {
		const browserWidth = window.innerWidth;

		if (browserWidth < 768) {
			setIsMobile(true);
		}
	}, []);

	useEffect(() => {
		// Preloads the Marketo form for background submission
		const loadForm = () =>
			loadScript('//app-lon04.marketo.com/js/forms2/js/forms2.min.js')
				.then(() => {
					window.MktoForms2.loadForm(
						'//app-lon04.marketo.com',
						'217-MIC-854',
						2796,
						(form) => {
							form.onSuccess(() => false);
						},
					);
				})
				.catch(() => {});

		if (window.requestIdleCallback) {
			window.requestIdleCallback(loadForm);
		} else {
			setTimeout(loadForm);
		}
	}, []);

	const defaultPerPage = isMobile ? 3 : 6;

	const PER_PAGE = webinarsPerPage || defaultPerPage;

	const { page, pageNumbers, currentPageResults } = usePagination(result.length, PER_PAGE);
	const { showSearch, searchInput, showSearchResults, clearSearch, handleKeyDown } =
		usePaginatedSearch({ search, searchInputRef });

	const today = useMemo(() => new Date(), []);

	const nonExpiredWebinars = allLiveWebinars.filter(
		(webinar) => new Date(`${webinar.liveWebinarDate}T${webinar.liveWebinarTime}:00`) > today,
	);

	const sortedLiveWebinars = nonExpiredWebinars
		.sort(
			(a, b) =>
				new Date(`${a.liveWebinarDate}T${a.liveWebinarTime}:00`) -
				new Date(`${b.liveWebinarDate}T${b.liveWebinarTime}:00`),
		)
		.slice(0, 3);

	const showLiveWebinars = nonExpiredWebinars.length > 0;

	const Header = ({ className, children }) =>
		showLiveWebinars ? (
			<h2 className={className}>{children}</h2>
		) : (
			<h1 className={className}>{children}</h1>
		);

	return (
		<Layout>
			<HrefLang locales={_allSeoLocales} slug="webinars" />
			<SEO
				path={location.pathname}
				title={seo.title}
				description={seo.description || bodyNode.childMdx.excerpt}
			/>

			{!showSearch && showLiveWebinars && (
				<ClientOnly>
					<Container className="max-w-screen-xl px-5 lg:px-12">
						<div ref={listRef}>
							<div className="flex flex-col justify-center max-w-2xl mx-auto mb-5 text-center">
								<h1 className="mb-8 text-2xl font-bold leading-8 text-gray-900">
									Upcoming live webinars
								</h1>
								<p className="mb-5">
									Join our free webinar series to get essential guidance on your
									BrightHR people management tools. You can attend as many of the
									live sessions as you like…
								</p>
							</div>
						</div>

						{sortedLiveWebinars.map((webinar, index) => (
							<LiveWebinarCard
								title={webinar.title}
								description={webinar.description}
								webinarDate={webinar.liveWebinarDate}
								webinarTime={webinar.liveWebinarTime}
								webinarTimezone={webinar.liveWebinarTimezone}
								registrationLink={webinar.registrationLink}
								speakerOne={webinar.speakerOne}
								speakerTwo={webinar?.speakerTwo}
								speakerOneShowLogo={webinar.speakerOneShowLogo}
								speakerTwoShowLogo={webinar.speakerTwoShowLogo}
								heroCard={index === 0}
								bottomBorder={
									index !== 0 && index !== sortedLiveWebinars.length - 1
								}
								id={webinar.id}
								jointHeadshot={webinar.jointHeadshot}
							/>
						))}
					</Container>
				</ClientOnly>
			)}

			{webinars.length > 0 && (
				<Container className="max-w-screen-xl px-5 lg:px-12">
					<div ref={listRef}>
						<div className="flex flex-col justify-center max-w-2xl mx-auto mb-5 text-center">
							<Header className="mb-8 text-2xl font-bold leading-8 text-gray-900">
								{!showSearch && result.length > 0 && 'On-Demand Webinars'}
								{showSearch &&
									(result.length > 0
										? `All results for '${searchInput}'`
										: `We couldn't find any webinars that matched your search.`)}
							</Header>
							<p className="mb-5">
								{bodyNode.childMdx.body && (
									<CopyBlock>{bodyNode.childMdx.body}</CopyBlock>
								)}
							</p>
						</div>

						<label htmlFor="search" className="block mb-16">
							<div className="relative flex bg-white rounded-md shadow-lg">
								<svg
									className="absolute -translate-y-1/2 top-1/2 left-4 hover:cursor-pointer"
									width="18"
									height="18"
									viewBox="0 0 18 18"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
									onClick={showSearchResults}
									title="Search"
									tabIndex="0"
								>
									<path
										fillRule="evenodd"
										clipRule="evenodd"
										d="M1.79993 8.09969C1.79993 4.62043 4.62043 1.79993 8.09969 1.79993C11.579 1.79993 14.3995 4.62043 14.3995 8.09969C14.3995 9.79758 13.7278 11.3386 12.6356 12.4715C12.6055 12.4948 12.5765 12.5202 12.5488 12.5479C12.5211 12.5756 12.4956 12.6046 12.4723 12.6348C11.3393 13.7274 9.79798 14.3995 8.09969 14.3995C4.62043 14.3995 1.79993 11.579 1.79993 8.09969ZM13.1559 14.4278C11.7702 15.5364 10.0124 16.1994 8.09969 16.1994C3.62635 16.1994 0 12.573 0 8.09969C0 3.62635 3.62635 0 8.09969 0C12.573 0 16.1994 3.62635 16.1994 8.09969C16.1994 10.0119 15.5367 11.7693 14.4286 13.1549L17.7364 16.4628C18.0879 16.8142 18.0879 17.384 17.7364 17.7355C17.3849 18.087 16.8151 18.087 16.4637 17.7355L13.1559 14.4278Z"
										fill="#004991"
									/>
								</svg>

								<input
									id="search"
									className="flex-1 w-full py-5 pl-12 pr-4 border-2 border-gray-400 rounded-md"
									placeholder="Search on-demand webinars..."
									onChange={showSearchResults}
									onKeyDown={handleKeyDown}
									value={searchInput}
									ref={searchInputRef}
								/>

								{showSearch && (
									<CrossIcon
										className="absolute w-4 -translate-y-1/2 top-1/2 right-4 hover:cursor-pointer"
										onClick={clearSearch}
										tabindex="0"
									/>
								)}
							</div>
						</label>

						<WebinarCards
							className="flex flex-col mb-8 md:flex-row md:flex-wrap md:-translate-x-4 md:mb-0"
							cardClassName="w-full pb-8 md:w-1/2 md:px-4 lg:w-1/3 last:pb-0 md:last:pb-8"
							resources={currentPageResults(result)}
							locale={locale}
							slugBase="webinars"
							searchInput={searchInput}
						/>

						{result.length > PER_PAGE && (
							<Pagination
								page={page}
								pageNumbers={pageNumbers}
								searchInput={searchInput}
							/>
						)}
					</div>
				</Container>
			)}
		</Layout>
	);
};

export default WebinarLibrary;

export const query = graphql`
	query WebinarLibrary($locale: String!, $id: String!) {
		datoCmsWebinarLibrary(locale: { eq: $locale }, id: { eq: $id }) {
			title
			bodyNode {
				childMdx {
					body
					excerpt
				}
			}
			webinarsPerPage
			seo {
				title
				description
			}
			_allSeoLocales {
				locale
			}
		}
		liveWebinarData: allDatoCmsLiveWebinar(
			filter: {
				_allLocalisationEnablerLocales: { elemMatch: { locale: { eq: $locale } } }
				locale: { eq: $locale }
			}
		) {
			nodes {
				id
				title
				registrationLink
				liveWebinarDate
				liveWebinarTime
				liveWebinarTimezone
				description
				locale
				speakerOne {
					fullName
					headshot {
						gatsbyImageData
					}
					jobTitle
					externalSpeaker
					logo {
						url
					}
					logo2 {
						url
					}
				}
				speakerOneShowLogo
				speakerTwo {
					fullName
					headshot {
						gatsbyImageData
						alt
					}
					jobTitle
					externalSpeaker
					logo {
						url
					}
					logo2 {
						url
					}
				}
				jointHeadshot {
					headshot {
						gatsbyImageData(imgixParams: { fm: "webp", auto: "compress" })
						alt
					}
				}
				speakerTwoShowLogo
			}
		}
		webinarCategories: allDatoCmsWebinarCategory(
			filter: { locale: { eq: $locale }, slug: { ne: null } }
		) {
			nodes {
				id
				slug
				locale
				title
			}
		}
		webinars: allDatoCmsWebinar(
			filter: { locale: { eq: $locale }, slug: { ne: null } }
			sort: { order: DESC, fields: meta___createdAt }
		) {
			nodes {
				id
				title
				slug
				date
				meta {
					createdAt
				}
				bodyNode {
					childMdx {
						excerpt(truncate: true)
					}
				}
				thumbnail {
					gatsbyImageData(
						width: 870
						imgixParams: { auto: "compress", ar: "16:9", fit: "crop" }
					)
					alt
				}
				youtubeId
				category {
					id
					slug
					title
				}
			}
		}
	}
`;
