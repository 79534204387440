import { graphql } from 'gatsby';
import React, { useState, useRef, useEffect } from 'react';
import Button from '@brighthr/component-button';
import LinkButton from '@brighthr/component-linkbutton';
// eslint-disable-next-line camelcase
import { atcb_action } from 'add-to-calendar-button';
import 'add-to-calendar-button/assets/css/atcb.css';
import Cookies from 'js-cookie';
import { useLocation } from '@reach/router';
import MiniHeader from '../components/Header/MiniHeader';
import BlobRectangle from '../images/blobs/rectangle-blob.svg';
import { buildLink, LOCALE_PATHS } from '../utils/locale';
import CopyBlock from '../components/CopyBlock';
import Pagination from '../components/Pagination';
import WebinarCards from '../components/ResourceCards/Webinar';
import Container from '../components/Container';
import NewsletterFooter from '../components/Footer/Components/newsletterFooter';
import MasterLayout from '../layouts/Master';
import SEO from '../components/SEO';
import usePagination from '../hooks/usePagination';
import LocaleContent from '../components/LocaleContent';

const WebinarRegistrationConfirmation = ({ data, pageContext }) => {
	const [isCookiesCorrect, setIsCookiesCorrect] = useState(false);
	const [webinarName, setWebinarName] = useState();
	const [webinarDate, setWebinarDate] = useState();
	const [webinarStartTime, setWebinarStartTime] = useState();
	const [webinarEndTime, setWebinarEndTime] = useState();
	const [webinarLocation, setWebinarLocation] = useState();
	const [webinarTimezone, setWebinarTimezone] = useState();
	const [webinarDescription, setWebinarDescription] = useState();

	const { copyNode, title, header } = data.datoCmsWebinarRegistrationConfirmation;
	const { nodes: webinars } = data.webinars;

	const [isMobile, setIsMobile] = useState();
	const listRef = useRef();
	const location = useLocation();
	const locale = LOCALE_PATHS[pageContext.locale];
	const hideCalendar = true;

	useEffect(() => {
		const browserWidth = window.innerWidth;

		if (browserWidth < 768) {
			setIsMobile(true);
		}
	}, []);

	const defaultPerPage = isMobile ? 3 : 6;
	const PER_PAGE = defaultPerPage;

	const { page, pageNumbers, currentPageResults } = usePagination(webinars.length, PER_PAGE);

	useEffect(() => {
		const description = (url) =>
			`<h1><span><strong>1. Click and join at the specified time and date:</strong></span><br /><span>${url}</span><br /><span>Note: This link should not be shared with others; it is unique to you.</span><br /><br /><span><strong>2. Choose one of the following audio options:</strong></span><br /><br /><span>TO USE YOUR COMPUTER'S AUDIO:</span><br /><span>When the Webinar begins, you will be connected to audio using your computer's microphone and speakers (VoIP). A headset is recommended.</span><br /><br /><span>-- OR --</span><br /><br /><span>TO USE YOUR TELEPHONE:</span><br /><span>If you prefer to use your phone, you must select "Use Telephone" after joining the webinar and call in using the numbers below.</span><br /><br /><span>United Kingdom: +44 330 221 9922</span><br /><span>&nbsp;&nbsp;&nbsp;&nbsp;Access Code: 270-689-874</span><br /><span>&nbsp;&nbsp;&nbsp;&nbsp;Audio PIN: Shown after joining the webinar</span><br /><br /><span>Calling from another country?</span><br /><span>View additional phone numbers here:</span><br /><span><a href="https://attendee.gotowebinar.com/audio/8193322484583568471" target="_blank" rel="noopener noreferrer">https://attendee.gotowebinar.com/audio/8193322484583568471</a></span></h1>`;

		const cookieWebinarName = Cookies.get('webinar_calendar_title');
		const cookieWebinarDate = Cookies.get('webinar_calendar_date');
		const cookieWebinarStartTime = Cookies.get('webinar_calendar_start');
		const cookieWebinarEndTime = Cookies.get('webinar_calendar_end');
		const cookieWebinarLocation = Cookies.get('webinar_calendar_location');
		const cookieWebinarTimezone = Cookies.get('webinar_calendar_timezone');
		const cookieWebinarDescription = Cookies.get('webinar_calendar_description');

		setWebinarName(cookieWebinarName || 'BrightHR Webinar');
		setWebinarDate(cookieWebinarDate);
		setWebinarStartTime(cookieWebinarStartTime);
		setWebinarEndTime(cookieWebinarEndTime);
		setWebinarLocation(cookieWebinarLocation || 'Online Webinar');
		setWebinarTimezone(cookieWebinarTimezone || 'Europe/London');
		setWebinarDescription(description(cookieWebinarDescription));

		if (webinarDate && webinarStartTime && webinarEndTime) {
			setIsCookiesCorrect(true);
		}
	}, [webinarDate, webinarEndTime, webinarStartTime]);

	return (
		<MasterLayout>
			<SEO title="Registration complete" path={location.pathname} />
			<MiniHeader locale={pageContext.locale} />
			<div className="flex flex-col items-center gap-4 mx-auto mb-8 overflow-hidden max-w-7xl lg:flex-row lg:gap-8 md:mb-12">
				<div className="max-w-2xl p-4 md:p-6">
					<h1 className="mb-4 text-4xl font-bold">{title}</h1>
					{isCookiesCorrect && webinarName && (
						<h2 className="mb-4 text-2xl">
							{header}
							<strong> &apos;{webinarName}&apos;</strong>
						</h2>
					)}

					<CopyBlock>{copyNode.childMdx.body}</CopyBlock>
				</div>

				<div className="relative flex items-center justify-center w-[384px] h-[467px] md:shrink-0 mx-auto">
					<img
						src={BlobRectangle}
						alt=""
						className="absolute top-0"
						width="431"
						height="524"
					/>
					<div className="relative flex flex-col items-center justify-center w-2/3 gap-6 mx-auto text-center">
						<p className="text-3xl font-bold text-brand-500">
							Ready to find out how BrightHR can help you?
						</p>
						<LocaleContent showInLocales={['en', 'ca']}>
							<LinkButton
								color="accent"
								size="xl"
								text="Get my free demo"
								href={buildLink(locale, 'book-a-demo/webinar-demo')}
							/>
						</LocaleContent>
						<LocaleContent showInLocales={['ie', 'nz']}>
							<LinkButton
								color="accent"
								size="xl"
								text="Get my free demo"
								href={buildLink(locale, 'book-a-demo')}
							/>
						</LocaleContent>
						<LocaleContent showInLocales={['au']}>
							<LinkButton
								color="accent"
								size="xl"
								text="Get my free demo"
								href={buildLink(locale, 'book-a-demo/webinar-demo/')}
							/>
						</LocaleContent>
					</div>
				</div>
			</div>

			{isCookiesCorrect && !hideCalendar && (
				<section className="w-full py-10 text-center bg-brand-100">
					<div className="flex flex-col gap-4">
						<div className="flex flex-col gap-4 p-3">
							<p className="text-2xl font-bold">Add to your calendar</p>
							<p className="text-gray-700">
								You are now registered for our next webinar.
								<br />
								Add it to your calendar so you don&apos;t forget.
							</p>
						</div>

						<div className="flex justify-center">
							<Button
								text="Add to your calendar"
								size="xl"
								onClick={() => {
									atcb_action({
										name: webinarName,
										startDate: webinarDate,
										endDate: webinarDate,
										startTime: webinarStartTime,
										endTime: webinarEndTime,
										location: webinarLocation,
										timeZone: webinarTimezone,
										description: webinarDescription,
										label: 'Add to your calendar',
										iCalFileName: 'BrightHR-Reminder',
										options: [
											'Apple',
											'Google',
											'iCal',
											'Microsoft365',
											'Outlook.com',
											'Yahoo',
										],
									});
								}}
							/>
						</div>
					</div>
				</section>
			)}

			{webinars.length > 0 && (
				<Container className="max-w-screen-xl px-5 lg:px-12">
					<section ref={listRef}>
						<div className="flex flex-col justify-center max-w-2xl mx-auto mb-5 text-center">
							<h2 className="mb-8 text-2xl font-bold leading-8 text-gray-900">
								On-Demand Webinars
							</h2>
							<p className="mb-5 text-gray-700">
								Enjoy our free webinars and get up to speed on the latest employment
								law, health & safety and wellbeing topics.
							</p>
						</div>

						<WebinarCards
							className="flex flex-col mb-8 md:flex-row md:flex-wrap md:-translate-x-4 md:mb-0"
							cardClassName="w-full pb-8 md:w-1/2 md:px-4 lg:w-1/3 last:pb-0 md:last:pb-8"
							resources={currentPageResults(webinars)}
							locale={locale}
							slugBase="webinars"
						/>

						{webinars.length > PER_PAGE && (
							<Pagination page={page} pageNumbers={pageNumbers} />
						)}
					</section>
				</Container>
			)}

			<NewsletterFooter />
		</MasterLayout>
	);
};

export default WebinarRegistrationConfirmation;

export const query = graphql`
	query WebinarRegistrationConfirmationQuery($id: String, $locale: String!) {
		datoCmsWebinarRegistrationConfirmation(
			locale: { eq: $locale }
			slug: { ne: null }
			id: { eq: $id }
		) {
			title
			header
			slug
			_allSlugLocales {
				locale
			}
			copyNode {
				childMdx {
					body
				}
			}
		}
		webinars: allDatoCmsWebinar(
			filter: { locale: { eq: $locale }, slug: { ne: null } }
			sort: { order: DESC, fields: meta___createdAt }
		) {
			nodes {
				id
				title
				slug
				date
				meta {
					createdAt
				}
				bodyNode {
					childMdx {
						excerpt(truncate: true)
					}
				}
				thumbnail {
					gatsbyImageData(
						width: 870
						imgixParams: { auto: "compress", ar: "16:9", fit: "crop" }
					)
					alt
				}
				youtubeId
				category {
					id
					slug
					title
				}
			}
		}
	}
`;
