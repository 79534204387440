import React, { useState } from 'react';
import { QueryClient, QueryClientProvider, useQuery } from 'react-query';
import cn from 'classnames';
import { useCombobox } from 'downshift';
import axios from 'axios';
import { useLocale } from '../../context/Locale';

import LightningLogo from '../../icons/LightningLogo.svg';
import CopyBlock from '../CopyBlock';

const queryClient = new QueryClient();

export default function LightningTileWrapper({ title, content, defaultQuestion }) {
	return (
		<QueryClientProvider client={queryClient}>
			<LightningTile title={title} content={content} defaultQuestion={defaultQuestion} />
		</QueryClientProvider>
	);
}

const LightningTile = ({ title, content, defaultQuestion }) => {
	const LIGHTNING_CLIENT_URL = 'https://www.brighthr.com/lightning';
	const LIGHTNING_API_URL = 'https://api.brighthr.com/v1/advice';
	const locale = useLocale();

	const [question, setQuestion] = useState(defaultQuestion || '');

	const { data: matchedAnswers } = useQuery(
		['lightningSuggestions', question],
		() => {
			if (question.length > 3) {
				return axios
					.post(`${LIGHTNING_API_URL}/suggest`, {
						search: question,
						top: 5,
					})
					.then(({ data: suggestions }) => suggestions)
					.catch(() => []);
			}
			return [];
		},
		{
			enabled: true,
			placeholderData: [],
			keepPreviousData: true,
			retry: 1,
			refetchOnWindowFocus: false,
		},
	);

	const handleQuestion = (e) => {
		e.preventDefault();

		return window.open(
			`${LIGHTNING_CLIENT_URL}/${
				locale === 'en' ? 'uk' : locale
			}/?question=${encodeURIComponent(question.trim())}`,
		);
	};

	// Controls autocomplete -> www.downshift-js.com
	const {
		isOpen,
		getInputProps,
		getMenuProps,
		getItemProps,
		getComboboxProps,
		highlightedIndex,
	} = useCombobox({
		items: matchedAnswers,
		itemToString: (item) => (typeof item === 'string' ? item : item.question),
		initialInputValue: defaultQuestion || '',
		onInputValueChange: ({ inputValue }) => {
			setQuestion(inputValue);
		},
	});

	return (
		<span>
			<div className="flex flex-col items-center p-4 rounded shadow-md md:px-12 md:py-8 justify-evenly lightning-gradient">
				<div className="flex flex-col w-full pb-4 md:pb-12 md:flex-row md:items-center">
					<LightningLogo />
					<div className="mt-4 ml-4 lg:mt-0 lg:ml-8">
						{title && (
							<h3 className="mb-2 text-2xl leading-none lg:text-4xl text-secondary-300">
								{title}
							</h3>
						)}

						{content && <CopyBlock>{content}</CopyBlock>}
					</div>
				</div>

				<div className="relative w-full">
					<form
						onSubmit={(e) => handleQuestion(e)}
						{...getComboboxProps()}
						className={cn(
							'md:flex items-center w-full bg-white p-2 rounded-md shadow-md focus-within:shadow-outline border border-brand-400 relative',
							{
								'rounded-b-none focus-within:shadow-none border-b-0':
									isOpen && matchedAnswers.length,
							},
						)}
					>
						<input
							{...getInputProps()}
							className="w-full h-12 p-2 bg-transparent outline-none md:flex-1 rounded-l-md"
							placeholder="Eg. What is statutory maternity pay?"
							aria-label="Eg. What is statutory maternity pay?"
						/>
						<a
							href={`${LIGHTNING_CLIENT_URL}/${
								locale === 'en' ? 'uk' : locale
							}/?question=${encodeURIComponent(question.trim())}`}
							target="_blank"
							rel="noopener noreferrer"
							className="block w-full px-4 py-2 my-1 text-white rounded md:w-auto md:mx-2 md:flex align-center bg-secondary-300 hover:bg-secondary-400 focus:bg-secondary-400 focus:shadow-outline focus:outline-none"
							aria-label="Submit HR question"
						>
							Ask
						</a>
					</form>
					<ul
						{...getMenuProps()}
						className={cn(
							'rounded-b-md overflow-y-auto max-w-full whitespace-nowrap transition-all border-t-0 absolute w-full',
							{
								'bg-transparent': !isOpen && !matchedAnswers.length,
								'bg-white focus-within:shadow-outline border border-brand-400':
									isOpen && matchedAnswers.length,
							},
						)}
					>
						{isOpen &&
							matchedAnswers.map((suggestion, index) => {
								const questionString =
									typeof suggestion === 'string'
										? suggestion
										: suggestion.question;

								return (
									<li
										key={questionString}
										{...getItemProps({ questionString, index })}
										className={cn('p-4 text-brand-500 hover:cursor-pointer', {
											'bg-brand-100': highlightedIndex === index,
										})}
									>
										{questionString}
									</li>
								);
							})}
					</ul>
				</div>
			</div>
		</span>
	);
};
