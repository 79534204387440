import React from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import { useLocation } from '@reach/router';
import { GatsbyImage } from 'gatsby-plugin-image';
import DatoSEO from '../DatoSEO';
import MasterLayout from '../../layouts/Master';
import Logo from '../Logo';
import { useVar } from '../Var';

import BookADemoForm from '../Marketo/BookADemo';
import Container from '../Container';
import CopyBlock from '../CopyBlock';
import HrefLang from '../HrefLang';
import Badge from '../Badge';
import MinimalFooter from '../MinimalFooter';
import { buildLink } from '../../utils/locale';
import LangNotify from '../LangNotify';
import ReviewBadges from '../ReviewBadges';
import LocaleContent from '../LocaleContent';
import getLeadSource from '../../utils/leadSource';
import { useLocale } from '../../context/Locale';

const CopyStyles = styled.div`
	p:first-child > strong {
		font-size: 1.25rem;
	}
`;

const BookADemo = ({ data, showTitle }) => {
	const locale = useLocale();

	const model = data.datoCmsBookADemo || data.datoCmsDemoPage;
	const location = useLocation();
	const salesNumber = useVar('salesNumber');
	const { isStarlingDemoPage, hideCustomerMessage } = model;
	const hasBadge = model.badge && model.badge.length > 0;
	const demoPageLeadSource = data.datoCmsDemoPage?.leadSource;
	const leadSource =
		demoPageLeadSource ||
		(isStarlingDemoPage ? 'Starling Bank - Book a Demo' : getLeadSource(locale));

	const noLocalePath = () => {
		if (model.locale !== 'en') {
			return location.pathname.slice(3);
		}
		return location.pathname;
	};

	return (
		<>
			<LangNotify />
			<HrefLang
				// eslint-disable-next-line no-underscore-dangle
				locales={model._allSlugLocales || model._allHeadingLocales}
				slug={noLocalePath()}
			/>
			<Helmet>
				<link
					rel="prefetch"
					href="https://app-lon04.marketo.com/js/forms2/js/forms2.min.js"
				/>
			</Helmet>
			<DatoSEO path={location.pathname} meta={model.seoMetaTags} />
			<div className="book-a-demo-form-wrapper">
				<Container className="relative">
					<header className="relative flex flex-row items-center justify-between mb-8 md:mb-20 text-brand-500">
						<a href={buildLink(locale, '/')}>
							<Logo />
						</a>
						{!isStarlingDemoPage && (
							<a
								className="font-bold whitespace-nowrap md:text-xl"
								href={`tel:${salesNumber}`}
							>
								{salesNumber}
							</a>
						)}
					</header>
					<section className="mb-8 ">
						<div className="relative bg-white w-full max-w-[375px] mx-auto mb-8 border border-gray-300 rounded-md shadow-md md:max-w-5xl md:mb-0">
							{hasBadge && (
								<div className="absolute hidden -right-12 -top-12 md:block">
									{model.badge.map((badgeProps) => (
										<Badge {...badgeProps} />
									))}
								</div>
							)}
							<div className="lg:flex">
								{model.formImageMobile && (
									<div className="border-b border-gray-300 lg:hidden">
										{model.formImageMobile.gatsbyImageData ? (
											<GatsbyImage
												image={model.formImageMobile.gatsbyImageData}
												className="rounded-t-md"
												alt={model.formImageMobile.alt || ''}
											/>
										) : (
											<img
												src={model.formImageMobile.url}
												alt={model.formImageMobile.alt || ''}
												className="w-full rounded-t-md"
											/>
										)}
									</div>
								)}
								{model.formImageDesktop && (
									<div className="hidden border-r border-gray-300 lg:block lg:flex-initial">
										{model.formImageDesktop.gatsbyImageData ? (
											<GatsbyImage
												image={model.formImageDesktop.gatsbyImageData}
												className="rounded-l-md"
												alt={model.formImageDesktop.alt || ''}
											/>
										) : (
											<img
												src={model.formImageDesktop.url}
												alt={model.formImageDesktop.alt || ''}
												className="rounded-l-md"
												width="370"
											/>
										)}
									</div>
								)}
								<div className="p-8 md:flex-1 lg:px-16">
									<h1 className="mb-4 text-3xl font-bold leading-none tracking-tight md:text-5xl">
										{model.heading}
									</h1>
									<CopyStyles className="mb-4">
										<CopyBlock>{model.copyNode.childMdx.body}</CopyBlock>
									</CopyStyles>
									{model.termsAndConditions && (
										<small>
											<CopyBlock>
												{model.termsAndConditionsNode.childMdx.body}
											</CopyBlock>
										</small>
									)}
									<BookADemoForm
										ctaText={model.ctaText}
										isStarlingDemoPage={isStarlingDemoPage}
										hideCustomerMessage={hideCustomerMessage}
										leadSource={leadSource}
										locale={locale}
										showTitle={showTitle}
									/>
									<div className="flex items-center justify-center mt-8 lg:my-0 lg:flex-row lg:space-between lg:mt-4 ">
										<LocaleContent showInLocales={['en', 'ie']}>
											<ReviewBadges
												locale={locale}
												pageName="book a demo"
												classes="w-32 h-auto max-h-32"
												g2AwardClasses="ml-4 w-24 lg:w-28"
											/>
										</LocaleContent>
										<LocaleContent showInLocales={['ca', 'au', 'nz']}>
											<ReviewBadges
												locale={locale}
												pageName="book a demo"
												width={locale === 'ca' ? '110' : '170'}
												classes="mx-3"
												g2AwardClasses="ml-2 w-24 lg:w-28"
											/>
										</LocaleContent>
									</div>
								</div>
							</div>
						</div>
					</section>
					<MinimalFooter locale={locale} includePricingPage />
				</Container>
			</div>
		</>
	);
};

const WrappedBookADemo = (props) => (
	<MasterLayout>
		<BookADemo {...props} />
	</MasterLayout>
);

export default WrappedBookADemo;
