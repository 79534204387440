const setUtmSessionData = () => {
	if (typeof window === 'undefined') {
		return;
	}

	const getUtmFromUrl = window.location.search;
	const getUtmFromSession = window.sessionStorage.getItem('utmString');

	if (!getUtmFromSession && getUtmFromUrl) {
		window.sessionStorage.setItem('utmString', getUtmFromUrl);
		return getUtmFromUrl;
	}
};

export default setUtmSessionData;
