import React from 'react';
import BuyOnline from '../../apps/BuyOnline';
import ClientOnly from '../../utils/client-only';

// This is a client side only sub app
const BuyOnlinePage = () => (
	<ClientOnly>
		<BuyOnline />
	</ClientOnly>
);

export default BuyOnlinePage;
