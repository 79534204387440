import React, { useMemo } from 'react';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import mdxSplitter from '../../utils/mdxSplitter';

const CopyBlock = ({ children, injectionId }) => {
	const mdxBody = children[0];
	const splitMdx = useMemo(() => {
		if (children.length > 1 && injectionId) {
			const returnedMdx = mdxSplitter(mdxBody, injectionId);
			if (returnedMdx.length > 0) {
				return returnedMdx;
			}
		}

		return [];
	}, [children, injectionId, mdxBody]);

	const injectedContent = children[1];

	if (typeof children === 'string') {
		return <MDXRenderer>{children}</MDXRenderer>;
	}

	if (injectedContent === null) {
		return <MDXRenderer>{mdxBody}</MDXRenderer>;
	}

	if (splitMdx.length === 1) {
		return <MDXRenderer>{splitMdx[0]}</MDXRenderer>;
	}

	if (splitMdx.length === 2) {
		return (
			<>
				<MDXRenderer>{splitMdx[0]}</MDXRenderer>
				{injectedContent}
				<MDXRenderer>{splitMdx[1]}</MDXRenderer>
			</>
		);
	}

	return <>{children}</>;
};

export default CopyBlock;
