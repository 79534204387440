import React from 'react';

const FooterLogo = ({ imgSrc, alt, className }) => (
	<img
		src={imgSrc}
		alt={alt}
		width="100"
		height="100"
		loading="lazy"
		className={`${className || ''}`}
	/>
);

export default FooterLogo;
