import React from 'react';
import CopyBlock from '../../CopyBlock';

const PartnerReview = ({ logo, partner, review }) => (
	<div className="flex flex-col items-center max-w-screen-md mx-auto space-y-6 text-center lg:flex-row lg:text-left lg:space-x-12">
		<div className="flex items-center flex-none h-40 overflow-hidden border border-gray-300 rounded-full justify-items-center">
			<img src={logo} alt="HQ Club" className="w-40 p-4" />
		</div>
		<div className="space-y-4 shrink">
			<h3 className="text-lg font-bold md:text-xl text-brand-500">{partner}</h3>
			<CopyBlock>{review}</CopyBlock>
		</div>
	</div>
);

export default PartnerReview;
