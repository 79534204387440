import React, { useEffect, useState, useRef } from 'react';
import loadScript from 'load-script2';
import { graphql } from 'gatsby';
import { useLocation } from '@reach/router';
import { useFormik } from 'formik';
import Button from '@brighthr/component-button';
import LinkButton from '@brighthr/component-linkbutton';
import CryptoJS from 'crypto-js';
import AES from 'crypto-js/aes';
import Base64 from 'crypto-js/enc-base64';
import Utf8 from 'crypto-js/enc-utf8';
import qs from 'qs';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Modal, ModalBody, ModalFooter } from '@brighthr/component-modal';
import Icon from '@brighthr/component-icon';
import { useCalendlyEventListener, InlineWidget } from 'react-calendly';
import MasterLayout from '../layouts/Master';
import FormFooterLinks from '../components/Marketo/FormFooterLinks';
import CopyBlock from '../components/CopyBlock';
import CopyBlockProvider from '../components/CopyBlock/provider';
import SEO from '../components/SEO';
import HrefLang from '../components/HrefLang';
import MinimalFooter from '../components/MinimalFooter';
import Logo from '../components/Logo';
import { useVar } from '../components/Var';
import { buildLink, LOCALE_PATHS } from '../utils/locale';
import LocaleContent from '../components/LocaleContent';
import Container from '../components/Container';
import submitMarketoForm from '../components/Marketo/submitMarketoForm';
import VideoBlock from '../components/CMSBlocks/VideoBlock';
import CallUs from '../icons/CallUs.svg';
import CalendarBlue from '../icons/CalendarBlue.svg';
import CalendlyLogo from '../icons/CalendlyLogo.svg';

const DemoSurvey = ({ data, pageContext }) => {
	const { title, subTitle, bodyNode, seo, _allSeoLocales, slug, demoSurveyElements } =
		data.datoCmsDemoSurvey;
	const location = useLocation();
	const locale = LOCALE_PATHS[pageContext.locale];
	const salesNumber = useVar('salesNumber', locale || 'en');
	const demoEmailStr = useRef();
	const demoFirstNameStr = useRef();
	const demoLastNameStr = useRef();
	const demoPhoneStr = useRef();
	const demoSourceStr = useRef();
	const sk = 'bad2dsGtq0ixmcT5qfjFOH';
	const formId = '2796';

	useEffect(() => {
		const qsObj = qs.parse(window.location.search, { ignoreQueryPrefix: true });
		const getDemoEmailSession = window.sessionStorage.getItem('bade');
		const getDemoFirstNameSession = window.sessionStorage.getItem('badfn');
		const getDemoLastNameSession = window.sessionStorage.getItem('badln');
		const getDemoPhoneSession = window.sessionStorage.getItem('badp');
		if (getDemoEmailSession !== null) {
			const decryptDemoEmailSession = AES.decrypt(getDemoEmailSession, sk);
			demoEmailStr.current = decryptDemoEmailSession.toString(CryptoJS.enc.Utf8);
		}
		if (getDemoFirstNameSession !== null) {
			const decryptDemoFirstNameSession = AES.decrypt(getDemoFirstNameSession, sk);
			demoFirstNameStr.current = decryptDemoFirstNameSession.toString(CryptoJS.enc.Utf8);
		}
		if (getDemoLastNameSession !== null) {
			const decryptDemoLastNameSession = AES.decrypt(getDemoLastNameSession, sk);
			demoLastNameStr.current = decryptDemoLastNameSession.toString(CryptoJS.enc.Utf8);
		}
		if (getDemoPhoneSession !== null) {
			const decryptDemoPhoneSession = AES.decrypt(getDemoPhoneSession, sk);
			demoPhoneStr.current = decryptDemoPhoneSession.toString(CryptoJS.enc.Utf8);
		}
		const getDemoSourceSession = window.sessionStorage.getItem('bads');
		if (getDemoSourceSession !== null) {
			demoSourceStr.current = getDemoSourceSession;
		}
		if (Object.keys(qsObj).length !== 0) {
			const deBaseDemoEmailQs = Base64.parse(qsObj.email);
			const stringifydeBaseDemoEmail = Utf8.stringify(deBaseDemoEmailQs);
			const decryptDemoEmailQs = AES.decrypt(stringifydeBaseDemoEmail, sk);
			const deBaseDemoLeadsourceQs = Base64.parse(qsObj.leadsource);
			const stringifydeBaseDemoLeadsource = Utf8.stringify(deBaseDemoLeadsourceQs);
			demoEmailStr.current = decryptDemoEmailQs.toString(CryptoJS.enc.Utf8);
			demoSourceStr.current = stringifydeBaseDemoLeadsource;
		}
	}, []);

	useEffect(() => {
		// Preloads the Marketo form for background submission
		const loadForm = () =>
			loadScript('//app-lon04.marketo.com/js/forms2/js/forms2.min.js')
				.then(() => {
					window.MktoForms2.loadForm('//app-lon04.marketo.com', '217-MIC-854', formId); // ID of original form in Marketo : 1843
				})
				.catch(() => {});

		if (window.requestIdleCallback) {
			window.requestIdleCallback(loadForm);
		} else {
			setTimeout(loadForm);
		}
	}, []);

	const initialValues = {
		Email: demoEmailStr.current || '',
		NumberOfEmployees: '',
		Industry: '',
		demoSurveyMainHRIssues__c: '',
		demoSurveyFeaturesofInterest__c: '',
		LeadSource: demoSourceStr.current || '',
	};

	const { handleChange, handleSubmit, setStatus, values, isSubmitting, status } = useFormik({
		enableReinitialize: true,
		initialValues,
		onSubmit: async (formData) => {
			submitMarketoForm(formData, formId, location)
				.then(() => {
					setStatus('submitted');
				})
				.catch(() => {
					setStatus('error');
				});
		},
	});

	const [openCalendly, setOpenCalendly] = useState(false);
	const [calendlyEventScheduled, setCalendlyEventScheduled] = useState(false);

	useCalendlyEventListener({
		onEventScheduled: () => setCalendlyEventScheduled(true),
	});

	return (
		<>
			{openCalendly === true && (
				<Modal
					width="lg"
					className="justify-top"
					headerSize="lg"
					close={() => setOpenCalendly(false)}
				>
					<ModalBody>
						<button
							type="button"
							className="absolute cursor-pointer top-4 right-7"
							onClick={() => setOpenCalendly(false)}
						>
							<Icon iconName="cross-thin" size="sm" />
						</button>

						<InlineWidget
							hideGdprBanner
							styles={{ minWidth: '240px', height: '66vh' }}
							url="https://calendly.com/brighthr-demo-request/free-brighthr-demo"
							prefill={{
								name: `${demoFirstNameStr.current} ${demoLastNameStr.current}`,
								firstName: demoFirstNameStr.current,
								lastName: demoLastNameStr.current,
								email: values.Email,
								phoneNumber: demoPhoneStr.current,
							}}
							pageSettings={{
								backgroundColor: 'ffffff',
								hideEventTypeDetails: false,
								hideLandingPageDetails: false,
								primaryColor: '0057AD',
								textColor: '0a2233',
								hideGdprBanner: true,
							}}
						/>
					</ModalBody>
					<ModalFooter>
						<div className="flex flex-row items-center justify-between w-full flex-nowrap">
							<CalendlyLogo className="mr-4" />
							{calendlyEventScheduled === true && (
								<Button text="Close" onClick={() => setOpenCalendly(false)} />
							)}
						</div>
					</ModalFooter>
				</Modal>
			)}
			<div className="relative w-full mx-auto overflow-hidden max-w-7xl">
				<Container>
					<HrefLang locales={_allSeoLocales} slug={slug} />
					<SEO path={location.pathname} title={seo.title} />
					<header className="z-10 flex flex-row items-center justify-between w-full mb-8 md:mb-20 text-brand-500">
						<a href={buildLink(locale, '/')}>
							<Logo />
						</a>
						<a
							className="font-bold whitespace-nowrap md:text-xl"
							href={`tel:${salesNumber}`}
						>
							{salesNumber}
						</a>
					</header>
					<div className="flex flex-col lg:flex-row lg:flex-nowrap lg:mb-16 lg:mx-auto lg:justify-center xl:mb-24">
						<div className="max-w-2xl px-5 py-10 mx-auto bg-white border border-gray-300 rounded-lg shadow-sm mb-7 lg:mb-0 md:px-10 lg:mx-0">
							{status !== 'submitted' && (
								<>
									<h2 className="mb-4 text-2xl font-bold leading-8">{title}</h2>
									<p className="mb-4">{subTitle}</p>
									<div className="p-5 bg-gradient-to-b from-gray-200 to-white">
										<div className="flex flex-col pb-5 sm:flex-row sm:flex-nowrap sm:justify-between">
											<div className="mb-3 sm:mb-0">
												<p>
													<strong>Can&rsquo;t wait?</strong>
													<br /> Speak to us right away
												</p>
											</div>
											<a
												href={`tel:${salesNumber}`}
												className="flex bg-white flex-row justify-center px-3 py-3 font-bold border-2 rounded-md flex-nowrap border-brand-500 text-brand-500 sm:h-30%"
											>
												<CallUs className="w-6 h-6 mr-3" />
												<span className="text-sm leading-6 sm:text-base">
													Call us on {salesNumber}
												</span>
											</a>
										</div>
										{!calendlyEventScheduled ? (
											<LocaleContent showInLocales={['en']}>
												<div className="flex flex-col pt-5 border-t border-gray-300 pb-5 sm:flex-row sm:flex-nowrap sm:justify-between">
													<div className="mb-3 sm:mb-0">
														<p>
															<strong>Or...</strong>
															<br /> Book a time that suits you
														</p>
													</div>
													<button
														onClick={() => setOpenCalendly(true)}
														type="button"
														className="flex bg-white flex-row justify-center px-3 py-3 font-bold border-2 rounded-md flex-nowrap border-brand-500 text-brand-500 sm:h-30%"
													>
														<CalendarBlue className="w-6 h-6 mr-3" />
														<span className="text-sm leading-6 sm:text-base">
															Book your slot now
														</span>
													</button>
												</div>
												<div>
													<CalendlyLogo className="mt-3 ml-auto" />
												</div>
											</LocaleContent>
										) : (
											<p>
												Your appointment with one of our HR Experts has been
												booked. Please check your email for confirmation.
											</p>
										)}
									</div>

									<div className="mt-4 mb-6">
										<CopyBlock>{bodyNode.childMdx.body}</CopyBlock>
									</div>
									<form
										onSubmit={handleSubmit}
										className="max-w-xl mx-auto bg-white"
									>
										{status === 'error' && (
											<p className="mb-5 font-bold text-legacy-error">
												Something went wrong. Please try submitting your
												details again.
											</p>
										)}
										<input
											type="hidden"
											id="Email"
											name="Email"
											placeholder="Email address"
											value={values.Email}
											onChange={handleChange}
											required
											className="block w-full px-4 py-3 mt-2 placeholder-gray-400 border border-gray-400 rounded"
										/>
										<label
											htmlFor="NumberOfEmployees"
											className="block w-full mb-4 md:mr-2"
										>
											<strong>How many people do you employ?</strong>
											<input
												type="number"
												id="NumberOfEmployees"
												name="NumberOfEmployees"
												placeholder=""
												value={values.NumberOfEmployees}
												pattern="[1-9][0-9]*"
												min="1"
												onChange={handleChange}
												className="block w-full px-4 py-3 mt-2 placeholder-gray-400 border border-gray-400 rounded"
											/>
										</label>
										<label
											htmlFor="Industry"
											className="block w-full mb-4 md:mr-2"
										>
											<strong>Which industry are you in?</strong>
											<select
												id="Industry"
												name="Industry"
												onChange={handleChange}
												value={values.Industry}
												className="block w-full px-4 py-3 pr-8 leading-tight bg-white border border-gray-400 rounded appearance-none hover:border-gray-500 focus:outline-none focus:shadow-outline"
											>
												<option value="">Select...</option>
												<option value="Accountancy">Accountancy</option>
												<option value="Advertising">Advertising</option>
												<option value="Aerospace">Aerospace</option>
												<option value="Agriculture">Agriculture</option>
												<option value="Apparel">Apparel</option>
												<option value="Automotive">Automotive</option>
												<option value="Banking">Banking</option>
												<option value="Beauty &amp; Cosmetics">
													Beauty &amp; Cosmetics
												</option>
												<option value="Biotechnology">Biotechnology</option>
												<option value="Chemicals">Chemicals</option>
												<option value="Communications">
													Communications
												</option>
												<option value="Construction">Construction</option>
												<option value="Consulting">Consulting</option>
												<option value="Digital Services">
													Digital Services
												</option>
												<option value="Education">Education</option>
												<option value="Electronics">Electronics</option>
												<option value="Employment">Employment</option>
												<option value="Energy">Energy</option>
												<option value="Engineering">Engineering</option>
												<option value="Entertainment">Entertainment</option>
												<option value="Environmental">Environmental</option>
												<option value="Fashion">Fashion</option>
												<option value="Finance">Finance</option>
												<option value="Food &amp; Beverage">
													Food &amp; Beverage
												</option>
												<option value="Government">Government</option>
												<option value="Healthcare">Healthcare</option>
												<option value="Hospitality">Hospitality</option>
												<option value="HR Professional">
													HR Professional
												</option>
												<option value="Information Technology">
													Information Technology
												</option>
												<option value="Insurance">Insurance</option>
												<option value="Journalism &amp; News">
													Journalism &amp; News
												</option>
												<option value="Legal Services">
													Legal Services
												</option>
												<option value="Machinery">Machinery</option>
												<option value="Management Consultancy">
													Management Consultancy
												</option>
												<option value="Manufacturing">Manufacturing</option>
												<option value="Media">Media</option>
												<option value="Not For Profit">
													Not For Profit
												</option>
												<option value="Other">Other</option>
												<option value="Pharmaceutical">
													Pharmaceutical
												</option>
												<option value="Property">Property</option>
												<option value="Publishing">Publishing</option>
												<option value="Recreation">Recreation</option>
												<option value="Recruitment">Recruitment</option>
												<option value="Retail">Retail</option>
												<option value="Service">Service</option>
												<option value="Shipping">Shipping</option>
												<option value="Sports">Sports</option>
												<option value="Technology">Technology</option>
												<option value="Telecommunications">
													Telecommunications
												</option>
												<option value="Transportation">
													Transportation
												</option>
												<option value="Travel">Travel</option>
												<option value="Utilities">Utilities</option>
											</select>
										</label>
										<label
											htmlFor="demoSurveyMainHRIssues__c"
											className="block w-full mb-4 md:mr-2"
										>
											<strong>
												What are the main HR or health &amp; safety issues
												you currently face?
											</strong>
											<input
												type="text"
												id="demoSurveyMainHRIssues__c"
												name="demoSurveyMainHRIssues__c"
												placeholder=""
												value={values.demoSurveyMainHRIssues__c}
												onChange={handleChange}
												className="block w-full px-4 py-3 mt-2 placeholder-gray-400 border border-gray-400 rounded"
											/>
										</label>
										<label
											htmlFor="demoSurveyFeaturesofInterest__c"
											className="block w-full mb-4 md:mr-2"
										>
											<strong>
												Which BrightHR feature interests you the most?
											</strong>
											<select
												id="demoSurveyFeaturesofInterest__c"
												name="demoSurveyFeaturesofInterest__c"
												onChange={handleChange}
												value={values.demoSurveyFeaturesofInterest__c}
												className="block w-full px-4 py-3 pr-8 leading-tight bg-white border border-gray-400 rounded appearance-none hover:border-gray-500 focus:outline-none focus:shadow-outline"
											>
												<option value="">Select...</option>
												<option value="BrightHR">BrightHR</option>
												<option value="BrightSafe">BrightSafe</option>
												<option value="BrightAdvice">BrightAdvice</option>
												<option value="All of these!">All of these!</option>
											</select>
										</label>

										<div className="mt-4 text-center">
											<div className="flex flex-col mx-auto mb-6">
												<Button
													id="Submit"
													type="submit"
													size="xl"
													disabled={isSubmitting}
													text="Share answers"
												/>
											</div>
										</div>

										<FormFooterLinks
											className="text-base text-left text-gray-900"
											textLinkColor="text-brand-500"
										/>
									</form>
								</>
							)}
							{status === 'submitted' && (
								<>
									<h3 className="mb-5 text-2xl font-bold leading-8">
										Your next steps
									</h3>
									<p className="mb-4">
										Here&rsquo;s what&rsquo;s going to happen next:
									</p>
									<ol className="mb-8 list-decimal list-inside">
										<li className="pl-4 mb-2">
											One of our friendly experts will give you a quick call.
										</li>
										<li className="pl-4 mb-2">
											They&rsquo;ll then schedule in your demo with our
											software specialists.
										</li>
										<li className="pl-4 mb-2">
											If you like what you see, our onboarding team will get
											you set up quickly.
										</li>
										<li className="pl-4 mb-2">
											Then, all that&rsquo;s left to do is sit back and enjoy
											easier people management.
										</li>
									</ol>
									<div className="text-center">
										<p className="mb-4">Find out more about BrightHR</p>
										<div className="m-auto w-fit">
											<LinkButton
												size="lg"
												href={buildLink(locale, '/')}
												outlineButton
												text="BrightHR"
											/>
										</div>
									</div>
								</>
							)}
						</div>
						{demoSurveyElements.map((el) => (
							<div key={el.id}>
								{el.blocktype === 'ExpertSidebar' && (
									<div className="hidden max-w-xs ml-10 xl:ml-14 lg:block lg:-mt-12">
										<div className="w-full">
											<div className="mb-8">
												<div className="flex flex-col items-center">
													<GatsbyImage
														image={el.expertImage.gatsbyImageData}
														alt={
															el.expertImage.alt ||
															'Sam Tunney - Bright HR Expert'
														}
														className="w-full h-auto sm:w-2/5 sm:h-auto lg:w-full"
													/>
												</div>
												<div className="p-2 bg-white shadow-2xl sm:text-center lg:text-left">
													<CopyBlock>
														{el.captionNode.childMdx.body}
													</CopyBlock>
												</div>
											</div>
											<div>
												<CopyBlock>{el.copyNode.childMdx.body}</CopyBlock>
											</div>
											<div className="pt-3 pb-8 ticks-pink">
												<CopyBlock>
													{el.featureListNode.childMdx.body}
												</CopyBlock>
											</div>
											<div className="px-8">
												{el.trustBadges.map((trustBadge) => (
													<img
														className="mx-auto mt-5"
														src={trustBadge.url}
														alt={trustBadge.alt || ''}
														key={trustBadge.url}
													/>
												))}
											</div>
										</div>
									</div>
								)}
							</div>
						))}
					</div>
				</Container>
			</div>
			{demoSurveyElements.map((el) => (
				<React.Fragment key={el.id}>
					{el.blocktype === 'VideoBlock' && (
						<VideoBlock
							imgSrc={el.thumbnail.gatsbyImageData}
							alt={el.thumbnail.alt}
							header={el.header}
							vimeoId={el.vimeoId}
						/>
					)}
				</React.Fragment>
			))}
			<Container className="mt-4">
				<MinimalFooter locale={locale} includePricingPage />
			</Container>
		</>
	);
};

const WrappedDemoSurvey = (props) => (
	<MasterLayout>
		<CopyBlockProvider>
			<DemoSurvey {...props} />
		</CopyBlockProvider>
	</MasterLayout>
);

export default WrappedDemoSurvey;

export const query = graphql`
	query demoSurvey($locale: String!) {
		datoCmsDemoSurvey(locale: { eq: $locale }) {
			_allSeoLocales {
				locale
			}
			id
			title
			subTitle
			slug
			bodyNode {
				childMdx {
					body
				}
			}
			seo {
				title
				description
			}
			demoSurveyElements {
				... on DatoCmsVideoBlock {
					blocktype
					id
					vimeoId
					header
					thumbnail {
						alt
						gatsbyImageData
					}
				}
				... on DatoCmsExpertSidebar {
					blocktype
					id
					captionNode {
						childMdx {
							body
						}
					}
					copyNode {
						childMdx {
							body
						}
					}
					featureListNode {
						childMdx {
							body
						}
					}
					expertImage {
						alt
						gatsbyImageData
					}
					trustBadges {
						alt
						url
					}
				}
			}
		}
	}
`;
