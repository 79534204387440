import React from 'react';

const Input = ({ label, name, ...props }) => (
	<label className="block w-full mb-2" htmlFor={name}>
		<span className="block mb-1.5 text-sm font-bold text-left">{label}</span>
		<input
			className="w-full px-4 py-2 text-gray-800 border-2 border-gray-300 rounded-md"
			name={name}
			{...props}
		/>
	</label>
);

export default Input;
