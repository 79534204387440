import React, { useState, useEffect, useRef } from 'react';
import cn from 'classnames';
import { LOCALES, LOCALE_CONFIG } from '../../utils/locale';
import { useLocale, useLocaleUpdate } from '../../context/Locale';
import Flag from '../CountryFlag';

import ChevronDown from '../../icons/ChevronDown.svg';

const Option = ({ countryCode, name }) => (
	<span className="flex items-center w-full pr-2 text-sm whitespace-nowrap">
		<Flag countryCode={countryCode} width="16" height="16" className="w-4 h-4 mr-2" />
		{name}
	</span>
);

const CountryPicker = () => {
	const pickerRef = useRef();
	const locale = useLocale();
	const updateLocale = useLocaleUpdate();
	const [expanded, setExpanded] = useState(false);
	const currentLocale = LOCALE_CONFIG[locale];

	useEffect(() => {
		const handleClick = (e) => {
			if (!pickerRef.current || pickerRef.current.contains(e.target)) return;

			setExpanded(false);
		};

		document.addEventListener('mousedown', handleClick);

		return () => {
			document.removeEventListener('mousedown', handleClick);
		};
	}, []);

	return (
		<div
			data-cy="landingPage-countryPicker"
			className="relative leading-none z-country-picker"
			ref={pickerRef}
		>
			<button
				type="button"
				className="flex items-center py-1 font-normal focus:outline-none focus:underline text-brand-500"
				onClick={() => setExpanded((e) => !e)}
			>
				<Option name={currentLocale.name} countryCode={currentLocale.countryCode} />
				<ChevronDown
					className={`ml-4 fill-primary-700 group-hover:fill-primary-400 duration-200 ${
						expanded && 'rotate-180'
					}`}
					iconName="chevron-thick-down"
					size={14}
				/>
			</button>

			<ul
				className={cn(
					'py-2 rounded-md absolute right-0 fade mt-1 -mr-4 shadow-lg triangle--top bg-white min-w-full text-gray-800',
					{
						fadeIn: expanded,
						fadeOut: !expanded,
					},
				)}
			>
				{LOCALES.filter((l) => l !== locale).map((l) => (
					<li className="last:mb-0" key={l}>
						<button
							type="button"
							className="block w-full px-4 py-2 font-normal transition duration-100 border-2 border-transparent focus:outline-none hover:bg-gray-200 focus:border-gray-300"
							onClick={() => updateLocale(l)}
						>
							<Option
								name={LOCALE_CONFIG[l].name}
								countryCode={LOCALE_CONFIG[l].countryCode}
							/>
						</button>
					</li>
				))}
			</ul>
		</div>
	);
};

export default CountryPicker;
