import React from 'react';
import LinkButton from '@brighthr/component-linkbutton';
import { buildLink } from '../../../../utils/locale';

const Buttons = ({ buttonData }) => {
	const { ctaOneText, ctaOneHref, ctaTwoText, ctaTwoHref } = buttonData;

	return (
		<div className="flex flex-col max-w-sm gap-2 mx-auto">
			<LinkButton
				color="accent"
				href={buildLink('', ctaOneHref)}
				text={ctaOneText}
				size="lg"
			/>

			<LinkButton
				color="accent"
				outlineButton
				href={buildLink('', ctaTwoHref)}
				text={ctaTwoText}
				size="lg"
			/>
		</div>
	);
};

export default Buttons;
