import React, { useState, useEffect } from 'react';
import ButtonBase from 'bright-components/dist/components/Button/Button';
import cn from 'classnames';
import styled from 'styled-components';

const Button = styled(ButtonBase)`
	${(props) =>
		props.block &&
		`
		width: 100%;
		display: block;
	`}
`;

const ButtonLink = (props) => {
	const [utmString, setUtmString] = useState('');

	useEffect(() => {
		setUtmString(window.sessionStorage.getItem('utmString') || '');
	}, []);

	if (props.includeUtmData) {
		const propsUtmData = { ...props, href: props.href + utmString };

		return (
			<Button
				{...propsUtmData}
				className={cn('text-base font-bold', props.className)}
				as="a"
			/>
		);
	}
	return <Button {...props} className={cn('text-base font-bold', props.className)} as="a" />;
};

export default ButtonLink;
