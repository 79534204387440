import React from 'react';
import Button from '@brighthr/component-button';
import { useFormik } from 'formik';
import FormFooterLinks from '../Marketo/FormFooterLinks';
import { useLocale } from '../../context/Locale';

const FeefoForm = () => {
	const pagelocale = useLocale();

	const { handleChange, handleSubmit, values, isSubmitting } = useFormik({
		initialValues: {
			FirstName: '',
			LastName: '',
			Email: '',
			Company: '',
			CustomerReferenceNumber: '',
		},
		onSubmit: () => {
			fetch('/api/feefo/smartlink', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					values,
				}),
			})
				.then((data) => data.json())
				.then((jsonData) => {
					window.location.href = jsonData.smartlink;
				});
		},
	});

	return (
		<>
			<form className="flex flex-col max-w-full pt-4" onSubmit={handleSubmit}>
				<div className="mb-8 space-y-3">
					<div className="flex flex-wrap w-full md:flex-nowrap">
						<label
							htmlFor="FirstName"
							className="block w-full text-sm font-bold text-left"
						>
							First name
							<input
								id="FirstName"
								name="FirstName"
								placeholder="Jane"
								type="text"
								required
								value={values.FirstName}
								onChange={handleChange}
								className="block w-full px-4 py-2 mt-2 border-2 border-gray-500 rounded focus-visible:drop-shadow-md focus-visible:border-brand-400"
							/>
						</label>
						<span className="block mx-4 "> &nbsp;</span>
						<label
							htmlFor="LastName"
							className="block w-full text-sm font-bold text-left"
						>
							Last name
							<input
								id="LastName"
								name="LastName"
								placeholder="Smith"
								type="text"
								required
								value={values.LastName}
								onChange={handleChange}
								className="block w-full px-4 py-2 mt-2 border-2 border-gray-500 rounded focus-visible:drop-shadow-md focus-visible:border-brand-400"
							/>
						</label>
					</div>
					<div className="flex flex-wrap w-full md:flex-nowrap">
						<label
							htmlFor="Email"
							className="block w-full mb-1 text-sm font-bold text-left"
						>
							Email address
							<input
								id="Email"
								name="Email"
								placeholder="jane.smith@acmeinc.com"
								type="email"
								pattern="^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$"
								required
								value={values.Email}
								onChange={handleChange}
								className="block w-full px-4 py-2 mt-2 border-2 border-gray-500 rounded focus-visible:drop-shadow-md focus-visible:border-brand-400"
							/>
						</label>
						<span className="block mx-4 "> &nbsp;</span>
						<label
							htmlFor="Company"
							className="block w-full mb-1 text-sm font-bold text-left"
						>
							Company
							<input
								id="Company"
								name="Company"
								placeholder="ACME inc."
								type="text"
								required
								value={values.Company}
								onChange={handleChange}
								className="block w-full px-4 py-2 mt-2 border-2 border-gray-500 rounded focus-visible:drop-shadow-md focus-visible:border-brand-400"
							/>
						</label>
					</div>
					<label
						htmlFor="CustomerReferenceNumber"
						className="block w-full mb-1 text-sm font-bold text-left md:mb-0"
					>
						Customer reference number (if known)
						<input
							id="CustomerReferenceNumber"
							name="CustomerReferenceNumber"
							placeholder="BRHR12345"
							type="text"
							value={values.CustomerReferenceNumber}
							onChange={handleChange}
							className="block w-full px-4 py-2 mt-2 border-2 border-gray-500 rounded focus-visible:drop-shadow-md focus-visible:border-brand-400"
						/>
					</label>
				</div>

				<Button id="Submit" type="submit" disabled={isSubmitting} text="Submit" size="lg" />

				<FormFooterLinks
					className="mt-2 text-sm text-left text-gray-900"
					textLinkColor="text-gray-900"
					locale={pagelocale}
				/>
			</form>
		</>
	);
};

export default FeefoForm;
