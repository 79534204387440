import React, { useState } from 'react';
import { graphql } from 'gatsby';
import LinkButton from '@brighthr/component-linkbutton';
import DefaultLayout from '../../layouts/Default';
import SEO from '../../components/SEO';
import GoogleReviewsTestimonials from '../../components/GoogleReviewsTestimonials';
import Container from '../../components/Container';
import content from '../../content/pricing_ca';
import PricingCards from '../../components/PricingCards';

import canadiantirelogo from '../../logos/canada/canadiantirelogo.png';
import dominoes from '../../logos/canada/dominoes.png';
import manchuwok from '../../logos/canada/manchuwok.png';
import marbleslabcreamerylogo from '../../logos/canada/marbleslabcreamerylogo.png';
import orangetheoryfitnesslogo from '../../logos/canada/orangetheoryfitnesslogo.png';
import petvalu from '../../logos/canada/petvalu.png';
import philthyphillys from '../../logos/canada/philthyphillys.png';
import timhortonslogo from '../../logos/canada/timhortonslogo.png';

import Modal from '../../components/Modal';
import BookADemoForm from '../../components/Marketo/BookADemo';
import Var from '../../components/Var';

const BusinessLogo = ({ src, alt, className }) => (
	<img
		src={src}
		alt={alt}
		width="90"
		height="90"
		className={`h-24 w-28 object-contain ${className || ''}`}
	/>
);

const IndexPage = ({ data }) => {
	const { googleReviews } = data;
	const [showModal, setShowModal] = useState(false);

	return (
		<DefaultLayout>
			<SEO
				title="HR Management Software Pricing in Canada"
				description="Check out the pricing for our award winning HR Software used by 6000+ Canadian businesses"
				titleSuffix="Our packages"
				path="ca/pricing"
			/>
			<Modal show={showModal} onClose={() => setShowModal(false)}>
				<div className="w-full max-w-lg p-8 mb-8 bg-white md:rounded md:mb-0">
					<BookADemoForm
						ctaText="Book my free demo"
						title="See BrightHR in action"
						subtitle="Fill out a few short details and we'll be in touch as soon as possible"
						leadSource="CA - Pricing Request"
						locale="ca"
					/>
				</div>
			</Modal>
			<div className="relative pt-4 text-gray-800">
				<div className="mb-10 text-center lg:px-40 xl:px-48">
					<h1 className="mb-8 text-4xl font-bold leading-tight md:text-5xl">
						The award-winning HR software used by <Var name="numCompanies" />+ Canadian
						businesses
					</h1>

					<p className="max-w-2xl mx-auto">
						Whether you run a small, medium or large size business, BrightHR offers
						packages to meet your specific needs.
					</p>
				</div>

				<section className="mb-16">
					<PricingCards content={content} buyOnline={false} setShowModal={setShowModal} />
				</section>

				<div className="container relative px-6 mx-auto my-12 text-center md:my-24 xl:my-20 xl:pt-0">
					<div className="max-w-4xl mx-auto my-8 text-center xl:mt-4">
						<h2 className="mb-12 text-xl font-bold text-center md:text-2xl xl:my-0">
							Join <Var name="numCompanies" />+ Canadian businesses using BrightHR
						</h2>
					</div>

					<div className="flex flex-row flex-wrap justify-center max-w-5xl gap-8 mx-auto my-12 space-evenly xl:mt-0">
						<BusinessLogo src={canadiantirelogo} alt="canadian tire logo" />
						<BusinessLogo src={dominoes} alt="dominos pizza logo" />
						<BusinessLogo
							src={marbleslabcreamerylogo}
							alt="marbles slab creamery logo"
						/>
						<BusinessLogo src={manchuwok} alt="manchuwok logo" />
						<BusinessLogo src={timhortonslogo} alt="tim hortons logo" />
						<BusinessLogo src={orangetheoryfitnesslogo} />
						<BusinessLogo src={petvalu} alt="pet valu logo" />
						<BusinessLogo src={philthyphillys} alt="philthy phillys logo" />
					</div>
					<div className="m-auto w-fit">
						<LinkButton
							className="mt-6"
							color="primary"
							href="/ca/customers"
							size="lg"
							text="Meet our customers"
						/>
					</div>
				</div>

				<div className="my-10 text-center">
					<p className="text-xl text-gray-700">
						Looking for a demo and quote right away? Call us now at
						<strong className="text-2xl text-brand-500">
							<a href="tel: 1-888-220-4924"> 1-888-220-4924</a>
						</strong>
					</p>

					<div className="m-auto mt-6 w-fit">
						<LinkButton
							color="accent"
							href="/ca/book-a-demo"
							size="lg"
							text="Start my free demo"
						/>
					</div>
				</div>

				<Container>
					<GoogleReviewsTestimonials
						googleReviews={googleReviews}
						starClass="text-gold"
						textClass="text-black"
					/>
				</Container>
			</div>
		</DefaultLayout>
	);
};

export default IndexPage;

export const query = graphql`
	query pricingCaGoogleReviews {
		googleReviews: allGoogleReview(
			limit: 6
			filter: { rating: { eq: 5 }, body: { ne: null } }
		) {
			nodes {
				id
				body
				rating
				date_utc
			}
		}
	}
`;
