import React from 'react';
import Pause from '../../../icons/Pause.svg';
import Play from '../../../icons/Play.svg';

const ProgressBar = ({ progressBarkey, running, start, pause, currentlyShowing }) => {
	const startPauseToggle = () => {
		if (running) {
			pause();
		} else {
			start();
		}
	};

	return (
		<>
			<div
				key={progressBarkey}
				className={`h-2 rounded-t-md animate-progressBar ${
					!currentlyShowing ? 'bg-white' : 'bg-brand-500'
				}`}
				style={{
					animationPlayState: running ? 'running' : 'paused',
				}}
			/>
			<button
				type="button"
				className="h-8 ml-auto mr-4 text-brand-500 hover:text-brand-400"
				onClick={() => startPauseToggle()}
			>
				{running && <Pause />}
				{!running && <Play />}
			</button>
		</>
	);
};

export default ProgressBar;
