import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

const standardisedLocales = {
	en: 'en',
	ca: 'en-CA',
	au: 'en-AU',
	nz: 'en-NZ',
	ie: 'en-IE',
	'en-CA': 'en-CA',
	'en-AU': 'en-AU',
	'en-NZ': 'en-NZ',
	'en-IE': 'en-IE',
};

const ReviewBadges = ({
	locale,
	pageName,
	width,
	height,
	classes,
	g2AwardWidth,
	g2AwardClasses,
}) => {
	const data = useStaticQuery(graphql`
		query ReviewBadgesQuery {
			allDatoCmsBadgePage {
				nodes {
					locale
					pageName
					preferredReviewerBadge {
						url
						alt
						gatsbyImageData
						format
					}
					g2AwardBadge {
						url
						alt
						gatsbyImageData
						format
					}
				}
			}
		}
	`);

	const { nodes: pages } = data.allDatoCmsBadgePage;
	const selectedPage = pages.filter((page) => page.pageName === pageName);

	const selectedPageByLocale = selectedPage.filter(
		(page) => page.locale === standardisedLocales[locale],
	)[0];

	const selectedReviewerBadgeImage = selectedPageByLocale?.preferredReviewerBadge;
	const selectedG2AwardImage = selectedPageByLocale?.g2AwardBadge;

	return (
		<>
			{selectedReviewerBadgeImage &&
				(selectedReviewerBadgeImage.format !== 'svg' ? (
					<GatsbyImage
						image={selectedReviewerBadgeImage?.gatsbyImageData}
						alt={selectedReviewerBadgeImage?.alt || 'Review badge'}
						width={width}
						height={height}
						className={classes}
					/>
				) : (
					<img
						src={selectedReviewerBadgeImage?.url}
						alt={selectedReviewerBadgeImage?.alt || 'Review badge'}
						width={width}
						height={height}
						className={classes}
					/>
				))}

			{selectedG2AwardImage &&
				(selectedG2AwardImage.format !== 'svg' ? (
					<GatsbyImage
						image={selectedG2AwardImage?.gatsbyImageData}
						alt={selectedG2AwardImage?.alt || 'G2 Award badge'}
						width={g2AwardWidth}
						className={g2AwardClasses}
					/>
				) : (
					<img
						src={selectedG2AwardImage?.url}
						alt={selectedG2AwardImage?.alt || 'G2 Award badge'}
						width={g2AwardWidth}
						className={g2AwardClasses}
					/>
				))}
		</>
	);
};
export default ReviewBadges;
