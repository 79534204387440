import React from 'react';
import { graphql } from 'gatsby';
import BookADemo from '../../components/BookADemo';

const BookADemoIE = (props) => <BookADemo {...props} />;
export default BookADemoIE;

export const query = graphql`
	query bookADemoIE {
		datoCmsBookADemo(locale: { eq: "en-IE" }) {
			id
			locale
			_allHeadingLocales {
				locale
			}
			seoMetaTags {
				...GatsbyDatoCmsSeoMetaTags
			}
			copyNode {
				childMdx {
					body
				}
			}
			heading
			ctaText
			background {
				url
			}
			backgroundBlendHex {
				hex
			}
			formImageDesktop {
				gatsbyImageData(layout: CONSTRAINED, width: 370, imgixParams: { auto: "compress" })
				url
				alt
			}
			formImageMobile {
				gatsbyImageData(layout: CONSTRAINED, width: 716, imgixParams: { auto: "compress" })
				url
				alt
			}
			textColor
			badge {
				... on DatoCmsBadgefrom {
					id
					price
					description
					internal {
						type
					}
				}
				... on DatoCmsBadgeoffer {
					id
					internal {
						type
					}
					offer
					description
				}
			}
		}
	}
`;
