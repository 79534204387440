import React, { useState, useRef, useEffect } from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import cn from 'classnames';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Icon from '@brighthr/component-icon';
import Slider from 'react-slick';
import PlayOverlay from '../../images/feature/play_overlay.png';
import CopyBlock from '../CopyBlock';
import VideoSource from '../../utils/videoSource';

const NextArrow = ({ className, style, onClick }) => (
	<button type="button" onClick={onClick} className={className} style={style}>
		<Icon
			iconName="chevron-thick-right"
			size={32}
			className="w-8 h-8 p-1.5 rounded-full fill-brand-500 bg-white border-gray-400 border"
		/>
	</button>
);

const PrevArrow = ({ className, style, onClick }) => (
	<button type="button" onClick={onClick} className={className} style={style}>
		<Icon
			iconName="chevron-thick-left"
			size={32}
			className="w-8 h-8 p-1.5 rounded-full fill-brand-500 bg-white border-gray-400 border"
		/>
	</button>
);
const VideoCarousel = ({ videoCarousel }) => {
	const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
	const [mobileSlide, setMobileSlide] = useState(0);
	const [showDescription, setShowDescription] = useState(false);
	const [play, setPlay] = useState(false);
	const [playMobile, setPlayMobile] = useState(false);

	const sliderRef = useRef(null);

	useEffect(() => {
		if (sliderRef.current) {
			const { list } = sliderRef.current.innerSlider;
			if (playMobile) {
				list.style.height = '0px';
			} else {
				list.style.height = 'auto';
			}
		}
	}, [playMobile]);

	const changeSlide = (nextVideo) => {
		setMobileSlide(nextVideo);
		setPlayMobile(false);
	};

	const settings = {
		dots: true,
		infinite: false,
		speed: 500,
		slidesToShow: 4,
		slidesToScroll: 1,
		nextArrow: <NextArrow />,
		prevArrow: <PrevArrow />,
		beforeChange: (current, next) => changeSlide(next),
		afterChange: (current) => setMobileSlide(current),
		responsive: [
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				},
			},
		],
	};

	return (
		<>
			<div className="hidden md:block videoBackground">
				<button
					type="button"
					className="relative items-stretch w-full h-full rounded shadow aspect-video"
					onClick={() => {
						setPlay(true);
					}}
				>
					{play && (
						<iframe
							src={VideoSource(videoCarousel[currentVideoIndex].videoId)}
							title={videoCarousel[currentVideoIndex].title}
							frameBorder="0"
							allow="autoplay; fullscreen; picture-in-picture"
							type="text/html"
							className="absolute top-0 bottom-0 left-0 right-0 w-full h-full"
						/>
					)}
					<div className={play ? 'hidden' : ''}>
						<GatsbyImage
							image={videoCarousel[0].thumbnail.gatsbyImageData}
							alt={videoCarousel[0].thumbnail.alt || ''}
						/>
						<img
							src={PlayOverlay}
							alt=""
							className="absolute top-0 left-0 w-full cursor-pointer rounded-2xl "
						/>
					</div>
				</button>
			</div>

			<div className="flex-row justify-between hidden my-4 md:flex">
				<div>
					<p className="mb-2 text-lg font-bold">
						{videoCarousel[currentVideoIndex].title}
					</p>
					<div
						className={`transition-all ${
							showDescription ? 'translate-y-0 block' : '-translate-y-full hidden'
						}`}
					>
						<CopyBlock>
							{videoCarousel[currentVideoIndex].descriptionNode.childMdx.body}
						</CopyBlock>
					</div>
				</div>

				<button
					type="button"
					className="flex flex-row justify-between w-40 shrink-0 fill-brand-500 text-brand-500"
					onClick={() => setShowDescription(!showDescription)}
				>
					<Icon
						iconName="chevron-thick-down"
						size={32}
						className={`transition-all ${showDescription ? 'rotate-180' : ''}`}
					/>
					<p>{showDescription ? 'Minimise details' : 'Expand details'}</p>
				</button>
			</div>

			<div className="hidden md:block">
				<Slider {...settings}>
					{videoCarousel.map((el, index) => (
						<button
							type="button"
							className={cn('w-72 aspect-video', {
								'border-4 border-primary-500': currentVideoIndex === index,
							})}
							onClick={() => {
								setCurrentVideoIndex(index);
								setPlay(true);
							}}
							key={el.id}
						>
							<div className="relative">
								<GatsbyImage
									image={el.thumbnail.gatsbyImageData}
									alt={el.thumbnail.alt || ''}
								/>
								<div
									className={`absolute top-0 left-0 z-10 w-full h-full bg-white/20 ${
										currentVideoIndex === index ? '' : 'hidden'
									}`}
								/>
							</div>
						</button>
					))}
				</Slider>
			</div>

			<div className="block md:hidden">
				{playMobile && !mobileSlide && (
					<div className="relative mx-auto pb-[56.25%] h-0 videoBackground">
						<iframe
							src={VideoSource(videoCarousel[currentVideoIndex].videoId)}
							title={videoCarousel[currentVideoIndex].title}
							frameBorder="0"
							allow="autoplay; fullscreen; picture-in-picture"
							type="text/html"
							className={`absolute top-0 left-0 w-full h-full ${
								playMobile ? '-mb-12' : ''
							}`}
						/>
					</div>
				)}

				<Slider ref={sliderRef} {...settings}>
					{videoCarousel.map((el, index) => (
						<button
							type="button"
							className="relative"
							onClick={() => {
								setCurrentVideoIndex(index);
								setPlayMobile(true);
								setMobileSlide(false);
							}}
							key={el.id}
						>
							<div className={`relative ${playMobile ? 'hidden' : ''}`}>
								<GatsbyImage
									image={el.thumbnail.gatsbyImageData}
									alt={el.thumbnail.alt || ''}
								/>
								<img
									src={PlayOverlay}
									alt=""
									className="absolute top-0 left-0 w-full cursor-pointer rounded-2xl "
								/>
							</div>
						</button>
					))}
				</Slider>

				<div className="mt-12 font-bold">
					<CopyBlock>
						{
							videoCarousel[mobileSlide || currentVideoIndex].descriptionNode.childMdx
								.body
						}
					</CopyBlock>
				</div>
			</div>
			<div />
		</>
	);
};

export default VideoCarousel;
