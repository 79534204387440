import React from 'react';
import cn from 'classnames';
import Phone from '../../icons/phone.svg';

const { default: Logo } = require('../Logo');

const PPCHeader = ({ logoSrc, logoAlt, phoneNumber, phoneText, navBackgroundColour }) => (
	<header
		className={cn(
			'transition-all ease-in translate-y-0 sticky w-full shadow duration-50 z-50 top-0',
			{
				'bg-white': navBackgroundColour === 'white',
				'bg-brand-500': navBackgroundColour === 'blue',
			},
		)}
	>
		<div
			className={cn(
				'flex flex-col flex-wrap items-center w-full max-w-screen-xl px-3 py-2 mx-auto sm:px-8 sm:flex-row sm:justify-between xl:px-12 md:py-3 justify-center md:space-x-4 md:flex-nowrap',
				logoSrc ? '' : 'md:space-between',
			)}
		>
			<div className="flex max-w-xs">
				<Logo
					className={cn('w-5/12', {
						'text-brand-500': navBackgroundColour === 'white',
						'text-white': navBackgroundColour === 'blue',
						'w-5/12': logoSrc,
						'w-full': !logoSrc,
					})}
				/>
				{logoSrc && (
					<>
						<div className="pr-4 mr-4 border-r-2 border-gray-300" />
						<img src={logoSrc} className="object-contain w-5/12" alt={logoAlt} />
					</>
				)}
			</div>

			<div
				className={cn(
					'justify-start mt-1 sm:mt-0 leading-2 md:leading-4 md:items-end md:flex-col',
					phoneNumber ? 'md:flex' : 'hidden',
				)}
			>
				<p
					className={cn('hidden text-sm md:block', {
						'text-black': navBackgroundColour === 'white',
						'text-white': navBackgroundColour === 'blue',
					})}
				>
					{phoneText}
				</p>

				<div className="flex flex-row items-center justify-center mt-2 md:justify-end">
					<Phone
						className={cn('w-4 h-4 md:max-w-lg md:mx-auto ', {
							'text-brand-500': navBackgroundColour === 'white',
							'text-white': navBackgroundColour === 'blue',
						})}
					/>

					<a
						className={cn(
							'block ml-2 text-2xl font-bold leading-3 duration-75 md:leading-4 text-brand-500 hover:text-brand-400',
							{
								'text-brand-500': navBackgroundColour === 'white',
								'text-white': navBackgroundColour === 'blue',
							},
						)}
						href={`tel:${phoneNumber}`}
					>
						{phoneNumber}
					</a>
				</div>
			</div>
		</div>
	</header>
);

export default PPCHeader;
