import React, { useState } from 'react';
import { graphql } from 'gatsby';
import { useLocation } from '@reach/router';
import { GatsbyImage } from 'gatsby-plugin-image';
import { nanoid } from 'nanoid';
import LinkButton from '@brighthr/component-linkbutton';
import Button from '@brighthr/component-button';
import DefaultLayout from '../layouts/Default';
import HrefLang from '../components/HrefLang';
import Modal from '../components/Modal';
import BookADemoForm from '../components/Marketo/BookADemo';
import DatoSEO from '../components/DatoSEO';
import BrightDevice from '../images/partners/brightdevice@2x.svg';
import Blob02 from '../images/partners/Blob_02@2x.svg';
import GoogleReviewsTestimonials from '../components/GoogleReviewsTestimonials';
import MeetOurCustomers from '../components/MeetOurCustomers';
import CopyBlock from '../components/CopyBlock';
import CustomerReview from '../components/CMSBlocks/CustomerReview';
import PartnerReview from '../components/CMSBlocks/PartnerReview';
import VectorWithText from '../components/CMSBlocks/VectorWithText';
import ReviewBadges from '../components/ReviewBadges';
import LocaleContent from '../components/LocaleContent';
import { LOCALE_PATHS } from '../utils/locale';

const Partners = ({ data }) => {
	const [showModal, setShowModal] = useState(false);
	const { googleReviews } = data;
	const location = useLocation();
	const {
		title,
		heroImage,
		locale,
		heroCopyNode,
		meetOurCustomers,
		slug,
		seoMetaTags,
		_allSeoMetaLocales,
		ctaPhoneNumber,
		customerReview,
		partnersReviews,
		benefitsImage,
		benefitsCopyNode,
		benefitsImageCopyNode,
		benefitCards,
	} = data.datoCmsPartnerPage;

	const standardisedLocale = LOCALE_PATHS[locale] || locale;

	return (
		<>
			<HrefLang locales={_allSeoMetaLocales} slug={slug} />
			<DatoSEO path={location.pathname} meta={seoMetaTags} />
			{showModal && (
				<Modal show={showModal} onClose={() => setShowModal(false)}>
					<div className="w-full max-w-lg p-8 mb-8 bg-white md:rounded md:mb-0">
						<BookADemoForm
							ctaText="Get in touch"
							title="Questions? We're here to help."
							pageContext="partnerHome"
							showFeefo={standardisedLocale === 'en'}
							hidePrivacyLinks
							subtitle="Fill out a few short details and we'll be in touch as soon as possible"
							dark
							leadSource="-"
							locale={standardisedLocale}
						/>
					</div>
				</Modal>
			)}

			<div className="text-gray-900">
				<section className="overflow-hidden text-center lg:text-left md:pt-8 bg-gradient-to-t from-white to-brand-200 main-banner-section-bg lg:relative">
					<div className="px-6 py-4 lg:flex lg:container lg:py-16 lg:mx-auto">
						<div className="relative z-10 space-y-4 lg:w-7/12">
							<h1 className="my-8 text-4xl font-bold leading-none md:text-5xl md:mt-0 text-brand-500">
								{title}
							</h1>
							<div className="lg:max-w-md">
								<CopyBlock>{heroCopyNode.childMdx.body}</CopyBlock>
							</div>
							<div className="flex flex-col justify-center w-full gap-4 lg:justify-start md:flex-row">
								<Button
									size="xl"
									onClick={() => setShowModal(true)}
									text="Get in touch"
								/>

								<LinkButton
									size="xl"
									href={`tel:${ctaPhoneNumber}`}
									outlineButton
									color="primary"
									text={`Call ${ctaPhoneNumber}`}
								/>
							</div>
						</div>

						<div className="relative z-0 hidden w-full lg:flex lg:justify-end lg:w-1/2 md:ml-auto">
							<picture className="absolute top-0 z-0 -translate-y-24 md:-translate-y-44 ">
								<img
									loading="eager"
									src={BrightDevice}
									alt="Bright Device"
									width="990"
									height="990"
								/>
							</picture>
							<GatsbyImage image={heroImage.gatsbyImageData} className="w-full" />
						</div>
					</div>
				</section>

				{meetOurCustomers.length > 0 && (
					<section className="container p-6 mx-auto xl:pt-0">
						<div className="container relative px-6 mx-auto my-12 text-center md:my-24 xl:my-20 xl:pt-0">
							<MeetOurCustomers
								logos={meetOurCustomers[0].logos}
								cta={meetOurCustomers[0].ctaNode.childMdx.body}
							/>
						</div>
					</section>
				)}

				{customerReview.length > 0 && (
					<section className="py-12 lg:mb-0">
						{customerReview.map((customer) => (
							<CustomerReview
								heading={customer.heading}
								subHeading={customer.subheadingNode.childMdx.body}
								customer={customer.customer}
								key={nanoid()}
							/>
						))}
					</section>
				)}

				{partnersReviews.length > 0 && (
					<section className="py-12 lg:mb-0">
						<div className="container p-6 mx-auto space-y-10 md:space-y-20">
							<h2 className="pt-12 text-3xl font-bold text-center md:text-4xl">
								What our partners are saying
							</h2>
							{partnersReviews.map((partner) => (
								<PartnerReview
									logo={partner.partnerLogo.url}
									partner={partner.partner}
									review={partner.reviewNode.childMdx.body}
									key={nanoid()}
								/>
							))}
						</div>
					</section>
				)}

				<section className="my-12 overflow-hidden lg:mb-0">
					<div className="container p-6 mx-auto">
						<div className="md:flex md:flex-wrap">
							<div className="my-16 space-y-3 md:px-8 md:w-1/2">
								<h3 className="text-3xl font-bold">
									Benefits of becoming a Bright partner
								</h3>
								<CopyBlock>{benefitsCopyNode.childMdx.body}</CopyBlock>
								<div className="flex flex-row items-center justify-center space-y-6 md:justify-start md:space-y-0 md:space-x-10">
									<LocaleContent showInLocales={['en', 'ie']}>
										<ReviewBadges
											locale={locale}
											pageName="partner"
											classes="w-48 lg:w-56"
											width="224"
											height="60"
											g2AwardClasses="ml-3 w-24 lg:w-28"
										/>
									</LocaleContent>
									<LocaleContent showInLocales={['ca', 'au', 'nz']}>
										<ReviewBadges
											locale={locale}
											pageName="partner"
											width={locale === 'ca' ? '110' : '170'}
											height={locale === 'ca' ? '91' : '80'}
											classes="fill-current"
											g2AwardClasses="ml-4 md:ml-2 w-24 lg:w-28"
										/>
									</LocaleContent>
								</div>
							</div>
							<div className="relative items-center md:w-1/2">
								<picture className="absolute top-0 translate-x-32">
									<img
										loading="eager"
										srcSet={Blob02}
										src={Blob02}
										alt="Bright Blob 02"
										className="w-full"
										width="984"
										height="821"
									/>
								</picture>
								<GatsbyImage image={benefitsImage.gatsbyImageData} />
								<div className="absolute z-30 w-64 p-3 bg-white rounded-lg shadow-lg top-1/2 left-24 xl:left-0 space-x-72">
									<CopyBlock>{benefitsImageCopyNode.childMdx.body}</CopyBlock>
								</div>
							</div>
						</div>
					</div>
				</section>

				{benefitCards.length > 0 && (
					<section className="my-12 lg:mb-0">
						<div className="container p-6 mx-auto space-y-20">
							<h2 className="pt-12 text-3xl font-bold text-center md:text-4xl">
								How working with BrightHR will help you...
							</h2>
							<div className="md:flex md:flex-wrap md:space-y-0">
								{benefitCards.map((benefit) => (
									<div className="p-3 md:w-1/2 lg:w-1/3">
										<div className="flex flex-col items-center h-full p-3 space-y-4 text-center border border-gray-300 rounded-lg md:px-12">
											<VectorWithText
												image={benefit.vector.url}
												alt={benefit.vector.alt || benefit.heading}
												heading={benefit.heading}
												text={benefit.copyNode.childMdx.body}
											/>
										</div>
									</div>
								))}
							</div>
						</div>
					</section>
				)}

				<section>
					<div className="hidden pb-12 md:block">
						<div className="container p-6 mx-auto">
							<LocaleContent showInLocales="ca">
								<GoogleReviewsTestimonials
									googleReviews={googleReviews}
									starClass="text-gold"
									textClass="text-black"
								/>
							</LocaleContent>
						</div>
					</div>
					<div className="form-section-bg-alt">
						<div className="container px-4 pt-24 mx-auto mb-16 text-center md:pt-40 lg:pt-48 xl:pt-24">
							<h3 className="mb-12 text-3xl font-bold md:mb-6 text-brand-500">
								So what are you waiting for?
							</h3>
							<p className="mb-12 md:mb-6">
								Join the team and welcome a new era of people management, the Bright
								way.
							</p>

							<div className="flex flex-col justify-center w-full gap-4 md:flex-row">
								<Button
									size="xl"
									onClick={() => setShowModal(true)}
									text="Get in touch"
								/>

								<LinkButton
									size="xl"
									href={`tel:${ctaPhoneNumber}`}
									outlineButton
									color="primary"
									text={`Call ${ctaPhoneNumber}`}
								/>
							</div>
						</div>
					</div>
				</section>
			</div>
		</>
	);
};
const WrappedPartners = (props) => (
	<DefaultLayout fullWidth>
		<Partners {...props} />
	</DefaultLayout>
);

export default WrappedPartners;

export const query = graphql`
	query partnersPage($id: String, $locale: String!) {
		datoCmsPartnerPage(locale: { eq: $locale }, slug: { ne: null }, id: { eq: $id }) {
			locale
			title
			_allSeoMetaLocales {
				locale
			}
			seoMetaTags {
				...GatsbyDatoCmsSeoMetaTags
			}
			slug
			heroCopyNode {
				childMdx {
					body
				}
			}
			heroImage {
				gatsbyImageData(layout: CONSTRAINED, width: 590)
			}
			meetOurCustomers {
				id
				blocktype
				logos {
					alt
					gatsbyImageData
					url
				}
				ctaNode {
					childMdx {
						body
					}
				}
			}
			ctaPhoneNumber
			customerReview {
				customer
				heading
				subheadingNode {
					childMdx {
						body
					}
				}
			}
			partnersReviews {
				reviewNode {
					childMdx {
						body
					}
				}
				partner
				partnerLogo {
					url
				}
			}
			benefitsImage {
				gatsbyImageData
			}
			benefitsImageCopyNode {
				childMdx {
					body
				}
			}
			benefitsCopyNode {
				childMdx {
					body
				}
			}
			benefitCards {
				copyNode {
					childMdx {
						body
					}
				}
				heading
				vector {
					url
					alt
				}
			}
		}

		googleReviews: allGoogleReview(
			limit: 6
			filter: { rating: { eq: 5 }, body: { ne: null } }
		) {
			nodes {
				id
				body
				rating
				date_utc
			}
		}
	}
`;
