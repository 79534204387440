import React from 'react';
import CopyBlock from '../../CopyBlock';

const ProductBenefit = ({ image, title, content, alt }) => (
	<div className="mt-10 mb-2 text-center md:pr-8 md:flex md:flex-col md:w-1/2 lg:w-1/4 lg:pr-4">
		<img src={image} alt={alt} className="h-48 mx-auto" />
		<h3 className="mt-2 mb-4 text-lg font-bold">{title}</h3>
		<CopyBlock className="mb-10">{content}</CopyBlock>
	</div>
);

export default ProductBenefit;
