/* eslint-disable global-require */
import React from 'react';
import { PACKAGES } from '../apps/BuyOnline/productsContent';

export const BRIGHTHR = {
	id: 'BRIGHTHR',
	name: 'BrightHR Software',
	shortName: 'BrightHR',
	description: 'Smart HR software that simplifies the way you manage your everyday staff admin.',
	img: require('../images/pricing/brighthr.jpg'),
	info: [
		'BrightHR’s smart software makes it easy for you to handle everyday HR tasks—like logging sickness, automatically tracking/calculating vacation for employees, generating complex work shift schedules with ease, plus much more—to taking advantage of contract templates and policy documents.',
		'You also get unlimited document storage space, so you can keep all your important policies and documents in one place.',
	],
};

export const VACCTRAK = {
	id: 'VACCTRAK',
	name: 'VaccTrak: Vaccine tracker',
	shortName: 'VaccTrack',
	description:
		'Keep track of who’s had the COVID-19 vaccine in your business with our exclusive online tool.',
	img: require('../images/pricing/VaccTrak.png'),
	info: [
		'VaccTrak makes it easy for you to record who’s had the COVID-19 vaccine and who’s still on the waiting list—helping you to protect your people and your business through the pandemic.',
	],
};

export const FURLOUGH = {
	id: 'FURLOUGH',
	name: 'Furlough navigator tool',
	shortName: 'Furlough navigator',
	description:
		'The easiest way to plan wage claims, record staff details, and comply with the government’s Job Retention Scheme.',
	img: require('../images/pricing/furlough.jpg'),
	info: [
		"BrightHR's simple and effective furlough navigator helps you quickly monitor, track and report employees who are furloughed.",
	],
};

export const BACKTOWORK = {
	id: 'BACKTOWORK',
	name: 'Back to work navigator tool',
	shortName: 'Back to work navigator',
	description: 'The online hub that helps you reopen your business quickly and safely.',
	img: require('../images/pricing/backtowork.jpg'),
	info: [
		'Our brand-new navigator tool gives you one place to manage all your back to work processes—so you can get your business back on its feet again in no time.',
	],
};

export const PAYROLL = {
	id: 'PAYROLL',
	name: 'Payroll navigator tool',
	shortName: 'Payroll navigator',
	description: 'Save time on your payroll reporting with our easy-to-use online tool.',
	img: require('../images/pricing/payroll.png'),
	info: [
		'Store all of your employees’ payroll details in one secure place, so you have easy access to important information whenever you need it. Plus, download payroll reports to help you give accurate details to HMRC.',
	],
};

export const REDUNDANCY = {
	id: 'REDUNDANCY',
	name: 'Redundancy navigator tool',
	shortName: 'Redundancy navigator',
	description: 'Use our expert online tool to make redundancy as stress-free as possible.',
	img: require('../images/pricing/redunancy.jpg'),
	info: [
		'Our brand-new online tool walks you through the redundancy process and helps to free you from stress and worry.',
	],
};

export const BLIP = {
	id: 'BLIP',
	name: 'Blip: Clock in-and-out app',
	shortName: 'Blip',
	description:
		'Exclusive mobile app that lets you track and record your team’s work hours from at home, on site, on the move or in the office.',
	img: require('../images/pricing/blip.jpg'),
	info: [
		'Blip lets your staff clock in and out of work or on-site by simply using their smartphone and gives you real-time updates of who’s in, who’s off, and who’s on a break.',
		'It also lets you download reports that show exactly when your people were in work, so you can calculate staff wages with confidence.',
	],
};

export const POP = {
	id: 'POP',
	name: 'PoP: Expense tracker app',
	shortName: 'PoP',
	description: 'Time-saving mobile app that lets you approve staff expenses on the go.',
	img: require('../images/pricing/pop.jpg'),
	info: [
		'PoP makes it easy for you to track your employees’ expenses and approve their requests with a swipe.',
		'It even makes calculating mileage costs simple, too.',
		'With just a few simple details, the app’s smart calculator automatically works out the distance and cost of your employee’s journey—so you no longer have to spend hours calculating head-scratching sums.',
	],
};

export const BRIGHTADVICE = {
	id: 'BRIGHTADVICE',
	name: 'BrightAdvice: Dedicated HR Advisors',
	shortName: 'BrightAdvice',
	description:
		'Dedicated Canadian HR Advisors. Professional HR advice from our dedicated Canadian expert advisors.',
	img: require('../images/pricing/brightadvice.jpg'),
	info: [
		'BrightAdvice is a confidential helpline that gives you 9-5pm access to professional advisors for clear, practical and jargon-free advice.',
		'This dedicated helpline saves you valuable time and stress put into Googling answers to important and sensitive HR related questions.',
	],
};

export const BRIGHTBASE = {
	id: 'BRIGHTBASE',
	name: 'BrightBase',
	shortName: 'BrightBase',
	description: 'Online library of downloadable HR policies, templates, guides, and more.',
	img: require('../images/pricing/brightbase.jpg'),
	info: [
		'BrightBase gives you access to download a library of expertly written HR documents, including policies, employment contracts and handbooks, how-to guides, factsheets, and templates, making it the perfect addition to your BrightAdvice helpline.',
	],
};

export const BRIGHTINSURE = {
	id: 'BRIGHTINSURE',
	name: 'BrightInsure: Legal expenses insurance*',
	shortName: 'BrightInsure*',
	description: 'Optional legal expenses insurance for employment disputes.',
	img: require('../images/pricing/brightInsure.jpg'),
	info: [
		'BrightInsure is optional legal expenses insurance that gives you cover in unfair dismissal and indirect discrimination claims for up to £1 million.',
	],
};

export const BRIGHTSAFE = {
	id: 'BRIGHTSAFE',
	name: 'BrightSafe: Health & Safety Advisors',
	shortName: 'BrightSafe',
	description:
		'Simple to use software helping you to manage risk assessments, accident and near miss reporting to staff compliance.',
	img: require('../images/pricing/brightsafe.jpg'),
	info: [
		'BrightSafe’s online software and app helps you keep on top of all your health & safety management, so you can protect your people and your business all in one simple place. ',
		'Make risk assessments in minutes, record workplace hazards, injuries, and incidents on the go, and assign automated safety tasks to ensure you stay compliant.',
		'You’ll also receive access to a full suite of interactive and easy-to-follow e-learning courses for your employees to stay up-to-date with the latest health & safety laws.',
	],
};

export const BRIGHTWELLBEING = {
	id: 'BRIGHTWELLBEING',
	name: 'Employee Assistance Programme',
	shortName: 'BrightWellbeing',
	description: '24/7 confidential employee advice & support line',
	img: require('../images/pricing/brightwellbeing.jpg'),
	info: [
		'BrightWellbeing & Counselling gives your people confidential and compassionate physical and mental health support.',
		'Your employees can call the 24/7 care line for fast wellbeing advice or access face-to-face counselling sessions to talk through their problems with a trained expert.',
		'They can also access health coaching, online wellbeing webinars and mini-health checks whenever they need to, using the free iOS and Android app.',
	],
};

export const BRIGHTEXCHANGE = {
	id: 'BRIGHTEXCHANGE',
	name: 'Bright Exchange',
	shortName: 'BrightExchange',
	description:
		'An online marketplace exclusive to BrightHR clients to advertise their products & services as well as take advantage of exclusive perks and discounts.',
	img: require('../images/pricing/brightexchange.png'),
	info: [
		'Bright Exchange is a FREE marketplace and perk system that connects BrightHR clients and their employees – with a potential of 4500+ business and 50,000 employees across Canada.',
		'Whether it’s using Bright Exchange to advertise your products & services or further engaging your employees by giving them access to exciting savings and offers from other BrightHR businesses, it’s available to support your growth.',
	],
};

export const BRIGHTSAFEADVICE = {
	id: 'BRIGHTSAFEADVICE',
	name: 'BrightSafe Advice: Dedicated Health & Safety Advisors',
	shortName: 'BrightSafeAdvice',
	description:
		'Qualified health & safety advice from our Canadian health & safety advisors available 24/7.',
	img: require('../images/pricing/brightadvice.jpg'),
	info: [
		'No more need to search the internet for unorganized and outdated health & safety answers/advice.',
		'BrightSafe Advice directly connects you to qualified H&S experts over the phone to get you timely answers to your important inquiries.',
		'Our Canadian H&S experts translate complex jargon into easy-to-understand health & safety advice, that’s specific to your industry.',
	],
};

// SUBFEARTURES START

export const SHIFTSANDSCHEDULES = {
	id: 'SHIFTSANDSCHEDULES',
	name: 'Shifts & schedules',
	shortName: 'Schedule and work shift planning system',
};

export const STAFFVACATIONPLANNER = {
	id: 'STAFFVACATIONPLANNER',
	name: 'Staff vacation planner',
	shortName: 'Staff vacation calculator and conflict alerts',
};

export const SICKLEAVE = {
	id: 'SICKLEAVE',
	name: 'Sick leave and lateness',
	shortName: 'Absence, sick, late management software',
};

export const DOCUMENTSTORAGE = {
	id: 'DOCUMENTSTORAGE',
	name: 'HR document storage',
	shortName: 'Unlimited HR document storage',
};

export const OVERTIMETRACKER = {
	id: 'OVERTIMETRACKER',
	name: 'Overtime tracker',
	shortName: 'Overtime tracker',
};

export const BRIGHTSAFEAPP = {
	id: 'BRIGHTSAFEAPP',
	name: 'BrightSafe on the go',
	shortName: 'Health & Safety App – record/manage everything on the go',
};

export const EDUCATIONNAVIGATOR = {
	id: 'EDUCATIONNAVIGATOR',
	name: 'Education navigator',
	shortName: 'E-learning Health & Safety courses',
};

// SUBFEATURES END

export const BENEFITS = [
	BRIGHTHR,
	VACCTRAK,
	PAYROLL,
	FURLOUGH,
	BACKTOWORK,
	REDUNDANCY,
	BLIP,
	POP,
	BRIGHTADVICE,
	BRIGHTBASE,
	BRIGHTINSURE,
	BRIGHTSAFE,
	BRIGHTWELLBEING,
];

export const CONNECT = {
	id: 'connect',
	name: 'Connect',
	icon: require('../icons/Connect.svg'),
	link: `/buy-online/plan?plan=${PACKAGES.CONNECT}`,
	modifiers: ['border-2', 'border-gray-200', 'md:mt-6'],
	subheading:
		'Transform your people management with our easy-to-use award-winning HR and Health & Safety software.',
	benefits: [BRIGHTHR, VACCTRAK, PAYROLL, FURLOUGH, BACKTOWORK, REDUNDANCY, BLIP, POP],
	fullBenefits: [BRIGHTHR, VACCTRAK, PAYROLL, FURLOUGH, BACKTOWORK, REDUNDANCY, BLIP, POP],
};

export const PROTECT = {
	id: 'protect',
	name: 'Protect',
	icon: require('../icons/Protect.svg'),
	link: `/buy-online/plan?plan=${PACKAGES.PROTECT}`,
	mostPopular: true,
	modifiers: ['border-2', 'border-brand-300', 'bg-brand-100'],
	subheading: (
		<>
			The <strong className="text-secondary-300">Connect</strong> package{' '}
			<strong className="uppercase">plus</strong> a team of HR advisors and templates at your
			finger tips
		</>
	),
	benefits: [BRIGHTADVICE, BRIGHTBASE],
	fullBenefits: [
		BRIGHTHR,
		VACCTRAK,
		FURLOUGH,
		PAYROLL,
		BACKTOWORK,
		REDUNDANCY,
		BLIP,
		POP,
		BRIGHTADVICE,
		BRIGHTBASE,
		BRIGHTINSURE,
	],
};

export const PRIME = {
	id: 'prime',
	name: 'Prime',
	icon: require('../icons/Prime.svg'),
	link: `/buy-online/plan?plan=${PACKAGES.PRIME}`,
	modifiers: ['border-2', 'border-gray-200', 'md:mt-6'],
	subheading: (
		<>
			The full service offering for all of your HR, employment law and health &amp; safety
			needs. Get full access to <strong className="text-secondary-300">Connect</strong> and{' '}
			<strong className="text-secondary-300">Protect</strong> full suite of software, tools
			and services at a cost-effective rate.
		</>
	),
	benefits: [BRIGHTSAFE, BRIGHTWELLBEING],
	fullBenefits: [
		BRIGHTHR,
		VACCTRAK,
		PAYROLL,
		FURLOUGH,
		BACKTOWORK,
		REDUNDANCY,
		BLIP,
		POP,
		BRIGHTADVICE,
		BRIGHTBASE,
		BRIGHTINSURE,
		BRIGHTSAFE,
		BRIGHTWELLBEING,
	],
};

export default {
	options: [CONNECT, PROTECT, PRIME],
};
/* eslint-enable global-require */
