import React, { useState } from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import cn from 'classnames';
import CopyBlock from '../../CopyBlock';
import VideoModal from '../../VideoModal';
import PinkBlob from '../../../images/blobs/pink-blob.svg';
import BlueBlob from '../../../images/blobs/blue-blob.svg';
import BlueDots from '../../../images/blobs/blue-dots.svg';
import ShortArrorw from '../../../images/vectors/short-arrow.svg';
import CTAs from '../../CTAs';

//
// Keeping this file for ease of removing Harry
// Will retire the file properly once home, product and feature pages are on Next
//

const HeroHarryRedknapp = ({
	logo,
	title,
	descriptionMdx,
	children,
	page,
	alternativeHero,
	heroImage,
}) => {
	const [isVideoVisible, setVideoVisible] = useState(false);

	return (
		<section
			className={cn(' relative py-8 overflow-hidden bg-no-repeat md:py-16 hero', {
				'blue-slant': !alternativeHero,
				'pink-slant': alternativeHero,
				'xl:pb-48':
					page === '/pop-expense-tracker-app/' ||
					page === '/brightsafe/health-and-safety-advice-line/' ||
					page === '/hr-software/hr-document-storage/' ||
					page === '/hr-software/overtime/' ||
					page === '/hr-software/shifts-and-rotas/' ||
					page === '/brightsafe/brightsafe-on-the-go/' ||
					page === '/hr-software/sick-leave-and-lateness/' ||
					page === '/brightadvice-employment-law-advice/',
				'xl:pb-40':
					page === '/blip-clocking-in-app/' ||
					page === '/hr-software/staff-holiday-planner/' ||
					page === '/hr-software/',
				'mb-8': page === '/' || page === '/ie/',
				'mb-0': page !== '/' && page !== '/ie/',
			})}
		>
			{isVideoVisible && (
				<VideoModal
					vimeoId="749583044"
					videoTitle="BrightHR advert"
					onClose={() => setVideoVisible(false)}
				/>
			)}
			<div className="absolute inset-0 w-full h-full max-w-screen-xl mx-auto">
				{alternativeHero ? (
					<>
						<img
							src={BlueDots}
							alt=""
							className="absolute bottom-0 right-0 w-full xl:-right-56 sm:w-5/6 lg:-bottom-24 lg:max-w-screen-md xl:max-w-screen-lg lg:hidden"
						/>
						<img
							src={PinkBlob}
							alt=""
							className="absolute bottom-0 w-[24rem] -right-20 md:-right-12 lg:right-[-18rem] lg:bottom-0 sm:w-2/3 lg:hidden"
							width="796"
							height="740"
						/>
						<img
							src={BlueBlob}
							alt=""
							className="absolute -left-12 lg:left-[-18rem] top-0 lg:top-0 w-[24rem] hidden lg:block lg:w-full lg:h-full"
							width="1080"
							height="892"
						/>
					</>
				) : (
					<>
						<img
							src={BlueDots}
							alt=""
							className="absolute bottom-0 right-0 w-full xl:-right-56 sm:w-5/6 lg:-bottom-24 lg:max-w-screen-md xl:max-w-screen-lg"
							width="1200"
							height="843"
						/>
						<img
							src={PinkBlob}
							alt=""
							className="absolute bottom-0 w-[24rem] -right-20 md:-right-12 lg:right-[-18rem] lg:bottom-0 sm:w-2/3"
							width="796"
							height="740"
						/>
					</>
				)}
			</div>

			<svg
				className="absolute bottom-[-1px] xxxl:-bottom-6 left-0 z-10 w-full h-auto"
				width="1440"
				height="575"
				viewBox="0 0 1439 575"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M1440-.202V575H0v-40.158c58.457 14.89 121.918 24.658 192.6 24.658 163.075 0 280.954-74.062 395.027-116.702 34.256-12.805 68.169-25.482 102.859-36.798 119.005-38.821 212.825-69.094 301.601-88.277C1043 306.721 1176.5 258 1229.5 218S1370.08 86.28 1440-.202Z"
					fill="#EBF7FF"
				/>
			</svg>

			<svg
				className="absolute bottom-[-1px] xxxl:-bottom-6 left-0 z-30 w-full h-auto"
				width="1440"
				height="575"
				viewBox="0 0 1440 575"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M1440 99.798V575H0v-40.158c58.457 14.89 121.918 24.658 192.6 24.658 163.075 0 280.954-44.062 395.027-86.702 34.256-12.805 68.169-25.482 102.859-36.798 119.005-38.821 212.825-59.094 301.601-78.277C1043 346.721 1092.26 336.078 1143.65 322.5c120.96-31.959 226.43-136.22 296.35-222.702Z"
					fill="#fff"
				/>
			</svg>

			<div className="relative flex flex-col container px-6 mx-auto overflow-visible xl:items-start flex-nowrap lg:flex-row">
				<div className="z-40 w-full mb-12 lg:mb-0 lg:pr-20 sm:mb-0 lg:w-1/2">
					{logo && (
						<img
							src={logo.url}
							className="object-contain object-left w-full h-full"
							style={{
								maxHeight: '6rem',
								maxWidth: '10rem',
							}}
							alt="Logo"
						/>
					)}
					<h1
						className={cn(
							'leading-none text-5xl text-white font-black tracking-tight',
							{
								'max-w-xl mb-0 md:text-[4rem] lg:text-[5.5rem] lg:leading-[4.5rem]':
									page === '/' || page === '/ie/',
								'mb-4 lg:text-[4rem] lg:leading-[4rem]':
									page !== '/' && page !== '/ie/',
							},
						)}
					>
						{title}
					</h1>
					<div className="relative mb-4 text-white hero-harry-redknapp-content">
						<CopyBlock>{descriptionMdx}</CopyBlock>

						<CTAs darkBackground page={page} />

						<img
							className="absolute hidden w-40 xl:block -left-56 bottom-0 scale-y-[-1] rotate-[-20deg]"
							src={ShortArrorw}
							alt=""
						/>
					</div>

					<div className="flex flex-col items-center gap-8 md:flex-row">{children}</div>

					<a
						data-cy="landingPage-alreadyACustomer"
						href={
							page.includes('brightsafe')
								? 'https://app.brightsafe.com'
								: 'https://app.brighthr.com'
						}
						className="block mt-4 mb-5 text-white"
					>
						<strong>Already a customer?</strong>{' '}
						<span className="underline">Start here</span>.
					</a>
				</div>

				<div className="relative right-0 z-40 flex self-center w-full lg:w-1/2">
					<GatsbyImage image={heroImage} alt="" loading="eager" />
				</div>
			</div>
		</section>
	);
};

export default HeroHarryRedknapp;
