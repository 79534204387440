import React, { useEffect, useRef } from 'react';
import Button from '@brighthr/component-button';
import { useFormik } from 'formik';
import { useLocation } from '@reach/router';
import CryptoJS from 'crypto-js';
import AES from 'crypto-js/aes';
import Base64 from 'crypto-js/enc-base64';
import Utf8 from 'crypto-js/enc-utf8';
import qs from 'qs';
import loadScript from 'load-script2';
import submitMarketoForm from '../../../../components/Marketo/submitMarketoForm';
import CopyBlock from '../../../../components/CopyBlock';

const DemoSurveyBox = ({ formTitle, formSubtitle, formBodyNode, setSurveyFormSubmitted }) => {
	const location = useLocation();
	const formId = '2796';
	const demoEmailStr = useRef();
	const demoSourceStr = useRef();
	const sk = 'bad2dsGtq0ixmcT5qfjFOH';

	useEffect(() => {
		// Preloads the Marketo form for background submission
		const loadForm = () =>
			loadScript('//app-lon04.marketo.com/js/forms2/js/forms2.min.js')
				.then(() => {
					window.MktoForms2.loadForm('//app-lon04.marketo.com', '217-MIC-854', formId); // ID of original form in Marketo : 1843
				})
				.catch(() => {});

		if (window.requestIdleCallback) {
			window.requestIdleCallback(loadForm);
		} else {
			setTimeout(loadForm);
		}
	}, []);

	useEffect(() => {
		const qsObj = qs.parse(window.location.search, { ignoreQueryPrefix: true });
		const getDemoEmailSession = window.sessionStorage.getItem('bade');
		if (getDemoEmailSession !== null) {
			const decryptDemoEmailSession = AES.decrypt(getDemoEmailSession, sk);
			demoEmailStr.current = decryptDemoEmailSession.toString(CryptoJS.enc.Utf8);
		}
		const getDemoSourceSession = window.sessionStorage.getItem('bads');
		if (getDemoSourceSession !== null) {
			demoSourceStr.current = getDemoSourceSession;
		}
		if (Object.keys(qsObj).length !== 0) {
			const deBaseDemoEmailQs = Base64.parse(qsObj.email);
			const stringifydeBaseDemoEmail = Utf8.stringify(deBaseDemoEmailQs);
			const decryptDemoEmailQs = AES.decrypt(stringifydeBaseDemoEmail, sk);
			const deBaseDemoLeadsourceQs = Base64.parse(qsObj.leadsource);
			const stringifydeBaseDemoLeadsource = Utf8.stringify(deBaseDemoLeadsourceQs);
			demoEmailStr.current = decryptDemoEmailQs.toString(CryptoJS.enc.Utf8);
			demoSourceStr.current = stringifydeBaseDemoLeadsource;
		}
	}, []);

	const initialValues = {
		Email: demoEmailStr.current || '',
		NumberOfEmployees: '',
		Industry: '',
		demoSurveyMainHRIssues__c: '',
		demoSurveyFeaturesofInterest__c: '',
		LeadSource: demoSourceStr.current || '',
	};

	const { handleChange, handleSubmit, setStatus, values, isSubmitting, status } = useFormik({
		enableReinitialize: true,
		initialValues,
		onSubmit: async (formData) => {
			submitMarketoForm(formData, formId, location)
				.then(() => {
					setStatus('submitted');
					setSurveyFormSubmitted(true);
				})
				.catch(() => {
					setStatus('error');
				});
		},
	});

	return (
		<>
			<h1 className="mb-3 text-3xl font-semibold">{formTitle}</h1>
			<p className="mb-3 text-lg">{formSubtitle}</p>
			<CopyBlock>{formBodyNode.childMdx.body}</CopyBlock>
			{status !== 'submitted' && (
				<form onSubmit={handleSubmit} className="max-w-xl mx-auto mt-5 bg-white">
					{status === 'error' && (
						<p className="mb-5 font-bold text-legacy-error">
							Something went wrong. Please try submitting your details again.
						</p>
					)}
					<input
						type="hidden"
						id="Email"
						name="Email"
						placeholder="Email address"
						value={values.Email}
						onChange={handleChange}
						required
					/>
					<div className="space-y-3">
						<label htmlFor="NumberOfEmployees" className="block">
							<div className="mb-2 text-base font-semibold">
								How many people do you employ?
							</div>
							<input
								type="number"
								id="NumberOfEmployees"
								name="NumberOfEmployees"
								placeholder=""
								value={values.NumberOfEmployees}
								pattern="[1-9][0-9]*"
								min="1"
								onChange={handleChange}
								className="w-full px-4 py-2 text-gray-800 border-2 border-gray-400 rounded"
							/>
						</label>
						<label htmlFor="Industry" className="block">
							<div className="mb-2 text-base font-semibold">
								Which industry are you in?
							</div>
							<select
								id="Industry"
								name="Industry"
								onChange={handleChange}
								value={values.Industry}
								className="w-full px-4 py-2 pr-8 text-gray-800 bg-white border-2 border-gray-400 rounded appearance-none focus:outline-none focus:shadow-outline"
							>
								<option value="">Select...</option>
								<option value="Accountancy">Accountancy</option>
								<option value="Advertising">Advertising</option>
								<option value="Aerospace">Aerospace</option>
								<option value="Agriculture">Agriculture</option>
								<option value="Apparel">Apparel</option>
								<option value="Automotive">Automotive</option>
								<option value="Banking">Banking</option>
								<option value="Beauty &amp; Cosmetics">
									Beauty &amp; Cosmetics
								</option>
								<option value="Biotechnology">Biotechnology</option>
								<option value="Chemicals">Chemicals</option>
								<option value="Communications">Communications</option>
								<option value="Construction">Construction</option>
								<option value="Consulting">Consulting</option>
								<option value="Digital Services">Digital Services</option>
								<option value="Education">Education</option>
								<option value="Electronics">Electronics</option>
								<option value="Employment">Employment</option>
								<option value="Energy">Energy</option>
								<option value="Engineering">Engineering</option>
								<option value="Entertainment">Entertainment</option>
								<option value="Environmental">Environmental</option>
								<option value="Fashion">Fashion</option>
								<option value="Finance">Finance</option>
								<option value="Food &amp; Beverage">Food &amp; Beverage</option>
								<option value="Government">Government</option>
								<option value="Healthcare">Healthcare</option>
								<option value="Hospitality">Hospitality</option>
								<option value="HR Professional">HR Professional</option>
								<option value="Information Technology">
									Information Technology
								</option>
								<option value="Insurance">Insurance</option>
								<option value="Journalism &amp; News">Journalism &amp; News</option>
								<option value="Legal Services">Legal Services</option>
								<option value="Machinery">Machinery</option>
								<option value="Management Consultancy">
									Management Consultancy
								</option>
								<option value="Manufacturing">Manufacturing</option>
								<option value="Media">Media</option>
								<option value="Not For Profit">Not For Profit</option>
								<option value="Other">Other</option>
								<option value="Pharmaceutical">Pharmaceutical</option>
								<option value="Property">Property</option>
								<option value="Publishing">Publishing</option>
								<option value="Recreation">Recreation</option>
								<option value="Recruitment">Recruitment</option>
								<option value="Retail">Retail</option>
								<option value="Service">Service</option>
								<option value="Shipping">Shipping</option>
								<option value="Sports">Sports</option>
								<option value="Technology">Technology</option>
								<option value="Telecommunications">Telecommunications</option>
								<option value="Transportation">Transportation</option>
								<option value="Travel">Travel</option>
								<option value="Utilities">Utilities</option>
							</select>
						</label>
						<label htmlFor="demoSurveyMainHRIssues__c" className="block">
							<div className="mb-2 text-base font-semibold">
								What are the main HR or health &amp; safety issues you currently
								face?
							</div>
							<input
								type="text"
								id="demoSurveyMainHRIssues__c"
								name="demoSurveyMainHRIssues__c"
								placeholder=""
								value={values.demoSurveyMainHRIssues__c}
								onChange={handleChange}
								className="w-full px-4 py-2 text-gray-800 border-2 border-gray-400 rounded"
							/>
						</label>
						<label htmlFor="demoSurveyFeaturesofInterest__c" className="block">
							<div className="mb-2 text-base font-semibold">
								Which BrightHR feature interests you the most?
							</div>
							<select
								id="demoSurveyFeaturesofInterest__c"
								name="demoSurveyFeaturesofInterest__c"
								onChange={handleChange}
								value={values.demoSurveyFeaturesofInterest__c}
								className="w-full px-4 py-2 pr-8 text-gray-800 bg-white border-2 border-gray-400 rounded appearance-none focus:outline-none focus:shadow-outline"
							>
								<option value="">Select...</option>
								<option value="BrightHR">BrightHR</option>
								<option value="BrightSafe">BrightSafe</option>
								<option value="BrightAdvice">BrightAdvice</option>
								<option value="All of these!">All of these!</option>
							</select>
						</label>
					</div>

					<div className="mt-5 text-center">
						<div className="flex flex-col mx-auto mb-6">
							<Button
								id="Submit"
								type="submit"
								size="xl"
								disabled={isSubmitting}
								text="Share answers"
							/>
						</div>
					</div>
				</form>
			)}

			{status === 'submitted' && (
				<p className="mt-5 font-semibold">Thanks for your answers</p>
			)}
		</>
	);
};

export default DemoSurveyBox;
