const registerCustomer = async ({
	customerId,
	paymentMethodId,
	priceId,
	packageId,
	term,
	unitPrice,
	subscription,
	OrderError,
	updateSession,
	session,
	values,
	navigate,
	setErrorMessage,
	taxPercentage,
	customerTaxId,
}) => {
	try {
		await fetch('/api/stripe/order', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				customerId,
				paymentMethodId,
				priceId,
				amountOfEmployees: values.amountOfEmployees,
				packageId,
				term,
				unitPrice,
				email: values.email,
				source: values.source,
				subscription,
				taxPercentage,
				customerTaxId,
			}),
		})
			.then(async (res) => {
				const result = await res.json().catch(() => {
					throw new OrderError('Order request failed', 500);
				});

				if (result.error) {
					throw new OrderError(result.error, res.status);
				}

				return result;
			})
			.then(({ company }) => {
				window.sessionStorage.setItem('regUrl', company?._links?.['create-account']?.href);

				if (window.dataLayer) {
					window.dataLayer.push({
						event: 'Payment Completed',
					});
				}
				updateSession({
					...session.session,
					...values,
					paymentCustomerId: customerId,
					paymentComplete: true,
				});
				navigate('/buy-online/confirmation');
			})
			.catch((subscriptionError) => {
				if (subscriptionError instanceof Error) {
					throw subscriptionError;
				} else {
					throw new OrderError(subscriptionError.message || subscriptionError);
				}
			});
	} catch (e) {
		if (e instanceof OrderError) {
			if (e.statusCode === 500 || e.response?.statusCode === 500) {
				navigate('/buy-online/registration-contact-customer-support');
			} else {
				setErrorMessage(e.message);
			}
		} else {
			setErrorMessage(e.message || e);
		}
	}
};

export default registerCustomer;
