import React from 'react';
import { useVar } from '../../Var';
import Phone from '../../../icons/phone.svg';

const PhoneContact = ({ topicName }) => {
	const salesNumber = useVar('salesNumber');

	return (
		<div className="h-full p-6 border">
			<p className="mb-4 font-bold">
				Still got a question{topicName ? ` about ${topicName}` : ''}?
			</p>

			<p className="mb-4 ">Our friendly service team is here to help.</p>

			<a
				href={`tel:${salesNumber}`}
				className="flex items-center text-lg font-bold text-brand-500 hover:text-brand-400"
			>
				<Phone className="inline-block w-5 h-5 mr-2" />
				<span className="text-xl">{salesNumber}</span>
			</a>
		</div>
	);
};

export default PhoneContact;
