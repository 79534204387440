import fetchPromotionCode from '../../Services/fetchPromotionCode';
import updateQuote from '../../Services/updateQuote';

const triggerFetchPromotionCode = async (code, statesAndValues) => {
	const {
		setPromoCodeObj,
		setPromoCodeError,
		setQuoteReady,
		quoteId,
		customerId,
		setSummaryValues,
		summaryValues,
		setCouponId,
		values,
		debouncedMaybeSyncSession,
	} = statesAndValues;
	const promoCode = await fetchPromotionCode(code).then((obj) => {
		setPromoCodeObj(obj);

		if (obj.error) {
			setPromoCodeError('This discount code is invalid, please try again');
			window.dataLayer.push({
				event: 'Buy Online Promo Code',
				codeValid: 'invalid',
				errorMessage: 'This discount code is invalid, please try again',
			});
			return obj;
		}

		if (obj.active === false) {
			setPromoCodeError('This discount code has expired, please try another code');
			window.dataLayer.push({
				event: 'Buy Online Promo Code',
				codeValid: 'invalid',
				errorMessage: 'This discount code has expired, please try another code',
			});
			return obj;
		}

		if (obj.active === true) {
			setQuoteReady(false);
			const generateQuote = async () => {
				await updateQuote({
					quoteId,
					customerId,
					couponId: obj.coupon.id,
				})
					.then((res) => {
						setSummaryValues({
							...summaryValues,
							totalValue: res.amount_total,
							taxValue: res.total_details?.amount_tax,
							discountAmountValue: res.total_details?.amount_discount,
						});
						setQuoteReady(true);
					})
					.catch(() => {
						setQuoteReady(false);
					});
			};
			generateQuote();

			setCouponId(obj.coupon.id);
			values.promoCode = obj.code;
			values.discountPercent = obj.coupon.percent_off;
			debouncedMaybeSyncSession();
			window.dataLayer.push({ event: 'Buy Online Promo Code', codeValid: 'valid' });
		}

		return obj;
	});

	return promoCode;
};

export default triggerFetchPromotionCode;
