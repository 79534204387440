import React from 'react';
import { graphql } from 'gatsby';
import { useLocation } from '@reach/router';
import { GatsbyImage } from 'gatsby-plugin-image';
import { buildLink, LOCALE_PATHS, localisedRead } from '../utils/locale';
import HrefLang from '../components/HrefLang';
import CopyBlock from '../components/CopyBlock';
import Logo from '../components/Logo';
import MinimalFooter from '../components/MinimalFooter';
import Container from '../components/Container';
import { useVar } from '../components/Var';
import ReferABusinessForm from '../components/Marketo/ReferABusinessForm';
import SEO from '../components/SEO';
import MasterLayout from '../layouts/Master';

const ReferABusiness = ({ data, pageContext }) => {
	const {
		_allSeoLocales,
		_allSlugLocales,
		termsNode,
		copyNode,
		header,
		image,
		stepOne,
		stepTwo,
		stepThree,
	} = data.datoCmsReferABusiness;

	const locale = LOCALE_PATHS[pageContext.locale];
	const salesNumber = useVar('salesNumber', locale);

	const location = useLocation();

	return (
		<MasterLayout>
			<Container className="max-w-7xl">
				<HrefLang
					locales={_allSeoLocales}
					slug={(hreflang) => localisedRead(_allSlugLocales, hreflang)}
				/>
				<SEO
					path={location.pathname}
					locale={locale}
					title="Refer a business"
					description="Refer a business"
				/>
				<header className="flex flex-row items-center justify-between">
					<a href={buildLink(locale, '/')}>
						<Logo className="w-28 md:w-auto text-brand-500" />
					</a>
					<a
						className="text-lg font-bold md:text-xl text-brand-500"
						href={`tel:${salesNumber}`}
					>
						{salesNumber}
					</a>
				</header>
				<main className="container mx-auto overflow-visible bg-right-top bg-no-repeat md:p-6 bg-none">
					<div className="relative flex flex-col content-center w-full pt-12 md:px-6 md:pt-16 md:mx-auto max-w-screen-xxl">
						<h1 className="mb-8 text-3xl font-bold lg:text-5xl">{header}</h1>

						<div className="p-3 mb-8 bg-white border border-gray-300 rounded shadow-lg md:shadow-none md:border-0 md:bg-transparent">
							<div className="flex flex-row mb-8 md:mb-12">
								<div className="w-full gap-10 md:w-3/4">
									<div className="float-right w-1/2 p-3 md:hidden">
										<GatsbyImage
											image={image?.gatsbyImageData}
											loading="eager"
											alt={image?.alt || 'Refer a business'}
										/>
									</div>
									<CopyBlock>{copyNode.childMdx.body}</CopyBlock>
									<div className="flex flex-col w-full gap-4 mt-6 font-bold md:mt-12 md:gap-2 md:h-44 md:flex-row">
										<div className="relative flex items-center w-full h-full gap-3 px-6 py-4 rounded md:items-stretch md:w-52 bg-brand-100 md:bg-transparent md:bg-gradient-to-b md:from-brand-100">
											<p className="w-10 h-10 p-2 text-center text-white border rounded-full shrink-0 md:absolute md:-left-4 md:-top-4 bg-brand-400 border-brand-500">
												1
											</p>
											<p>{stepOne}</p>
										</div>

										<div className="relative flex items-center w-full h-full gap-3 px-6 py-4 rounded md:items-stretch md:w-52 bg-brand-100 md:bg-transparent md:bg-gradient-to-b md:from-brand-100">
											<p className="w-10 h-10 p-2 text-center text-white border rounded-full shrink-0 md:absolute md:-left-4 md:-top-4 bg-brand-400 border-brand-500">
												2
											</p>
											<p>{stepTwo}</p>
										</div>

										<div className="relative flex items-center w-full h-full gap-3 px-6 py-4 rounded md:items-stretch md:w-52 bg-brand-100 md:bg-transparent md:bg-gradient-to-b md:from-brand-100">
											<p className="w-10 h-10 p-2 text-center text-white border rounded-full shrink-0 md:absolute md:-left-4 md:-top-4 bg-brand-400 border-brand-500">
												3
											</p>
											<p>{stepThree}</p>
										</div>
									</div>
								</div>
								<div className="hidden ml-4 md:block">
									<GatsbyImage
										image={image?.gatsbyImageData}
										loading="eager"
										alt={image?.alt || 'Refer a business'}
									/>
								</div>
							</div>
							<ReferABusinessForm locale={locale} />
						</div>

						<div className="text-xs text-gray-700">
							<CopyBlock>{termsNode.childMdx.body}</CopyBlock>
						</div>
					</div>
				</main>
				<MinimalFooter locale={locale} />
			</Container>
		</MasterLayout>
	);
};

export default ReferABusiness;

export const query = graphql`
	query referABusinessQuery($locale: String!) {
		datoCmsReferABusiness(locale: { eq: $locale }) {
			_allSeoLocales {
				locale
			}
			_allSlugLocales {
				locale
				value
			}
			id
			header
			image {
				alt
				gatsbyImageData
			}
			stepOne
			stepThree
			stepTwo
			copyNode {
				childMdx {
					body
				}
			}
			termsNode {
				childMdx {
					body
				}
			}
		}
	}
`;
