import React from 'react';

import PricingCardsComp from '../../PricingCards';
import content from '../../../content/pricing';
import Container from '../../Container';

const PricingCards = ({ buyOnline, connect, protect, prime }) => {
	const filter = [];

	if (connect) {
		filter.push('connect');
	}

	if (protect) {
		filter.push('protect');
	}

	if (prime) {
		filter.push('prime');
	}

	return (
		<Container>
			<div id="pricing" className="anchor" />
			<PricingCardsComp
				demoLink="#demo-form"
				content={content}
				buyOnline={buyOnline}
				filter={filter}
			/>
		</Container>
	);
};

export default PricingCards;
