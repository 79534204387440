import React, { useEffect, useRef, useState } from 'react';
import { graphql } from 'gatsby';
import DefaultLayout from '../layouts/Default';
import ImageWithCopy from '../components/CMSBlocks/ImageWithCopy';
import ProductsHero from '../components/CMSBlocks/ProductsHero';
import Container from '../components/Container';
import CMSWellCTA from '../components/CMSBlocks/WellCTA';
import CarouselWithCopy from '../components/CMSBlocks/CarouselWithCopy';
import VideoWithCopy from '../components/CMSBlocks/VideoWithCopy';
import FeatureBlock from '../components/CMSBlocks/FeatureBlock';
import StatBlob from '../components/CMSBlocks/StatBlob';
import HighlightedCopy from '../components/CMSBlocks/HighlightedCopy';
import CopyBlockProvider from '../components/CopyBlock/provider';
import Copy from '../components/CMSBlocks/Copy';
import DatoSEO from '../components/DatoSEO';
import HrefLang from '../components/HrefLang';
import { localisedRead, LOCALE_PATHS } from '../utils/locale';
import HeroHarryRedknapp from '../components/Heros/HarryRedknapp';
import VideoBlock from '../components/CMSBlocks/VideoBlock';
import TrustBlock from '../components/TrustBlock';
import TrustStatsBlock from '../components/TrustStatsBlock';
import GoogleReviewsTestimonials from '../components/GoogleReviewsTestimonials';
import PromoPopup from '../components/PromoPopup';
import LocaleContent from '../components/LocaleContent';
import ReviewBadges from '../components/ReviewBadges';

const FeaturePage = ({ data, location, pageContext }) => {
	const {
		featureElements,
		featureName,
		featureTitle,
		featureDescriptionNode,
		heroImage,
		product: {
			_allSlugLocales: _allProductSlugLocales,
			slug: productSlug,
			productFeatures,
			productName,
		},
		mainCta,
		seoMetaTags,
		_allSeoLocales,
		_allSlugLocales,
		locale,
	} = data.datoCmsFeature;
	const { googleReviews } = data;
	const standardisedLocale = LOCALE_PATHS[locale];

	const heroRef = useRef(null);

	const pages = [
		'/hr-software/staff-holiday-planner/',
		'/hr-software/shifts-and-rotas/',
		'/hr-software/sick-leave-and-lateness/',
		'/hr-software/hr-document-storage/',
		'/hr-software/overtime/',
		'/brightsafe/health-and-safety-advice-line/',
		'/brightsafe/brightsafe-on-the-go/',
	];

	const alternativeHeroPages = [
		'/hr-software/staff-holiday-planner/',
		'/hr-software/hr-document-storage/',
		'/brightsafe/health-and-safety-advice-line/',
	];

	const [autoSchema, setAutoSchema] = useState({});
	useEffect(() => {
		const aggregateRating = {
			aggregateRating: {
				'@type': 'AggregateRating',
				ratingValue: standardisedLocale === 'ca' ? data.googleReviewTotals.rating : null,
				ratingCount: standardisedLocale === 'ca' ? data.googleReviewTotals.reviews : null,
			},
		};

		setAutoSchema({
			name: productName,
			operatingSystem: 'Android, iOS, Web Browser',
			applicationCategory: 'BusinessApplication',
			...aggregateRating,
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<DefaultLayout fullWidth>
			<HrefLang
				locales={_allSeoLocales}
				slug={(hreflang) =>
					`${localisedRead(_allProductSlugLocales, hreflang)}/${localisedRead(
						_allSlugLocales,
						hreflang,
					)}`
				}
			/>
			<CopyBlockProvider>
				<DatoSEO
					path={location.pathname}
					meta={seoMetaTags}
					lang={pageContext.locale}
					schemaType="SoftwareApplication"
					autoSchema={autoSchema}
				/>

				<div ref={heroRef}>
					{pages.includes(location.pathname) ? (
						<HeroHarryRedknapp
							alternativeHero={alternativeHeroPages.includes(location.pathname)}
							heroImage={heroImage ? heroImage.gatsbyImageData : null}
							title={featureTitle}
							descriptionMdx={featureDescriptionNode.childMdx.body}
							page={location.pathname}
							crumbs={[
								{
									text: 'Home',
									link: '/',
								},
								{
									text: productName,
									link: `/${productSlug}`,
								},
								{
									text: featureName,
								},
							]}
							productSlug={productSlug}
							productFeatures={productFeatures}
						>
							<div className="flex">
								<ReviewBadges
									locale={locale}
									pageName="product blue bg"
									classes="w-48 lg:w-56"
									width="224"
									height="60"
									g2AwardClasses="ml-3 w-24 lg:w-28"
								/>
							</div>
						</HeroHarryRedknapp>
					) : (
						<ProductsHero
							heroImage={heroImage ? heroImage.gatsbyImageData : null}
							title={featureName}
							subTitle={featureTitle}
							descriptionMdx={featureDescriptionNode.childMdx.body}
							page={location.pathname}
							crumbs={[
								{
									text: 'Home',
									link: '/',
								},
								{
									text: productName,
									link: `/${productSlug}`,
								},
								{
									text: featureName,
								},
							]}
							locale={standardisedLocale}
							productSlug={productSlug}
							productFeatures={productFeatures}
						>
							<ReviewBadges
								locale={locale}
								pageName="product"
								classes="w-48 lg:w-56"
								width="224"
								height="60"
								g2AwardClasses="ml-3 w-24 lg:w-28"
							/>
						</ProductsHero>
					)}
				</div>

				{featureElements.map((el) => (
					<div className="mb-6 md:mb-12 last:mb-0">
						{el.blocktype === 'VideoBlock' && (
							<VideoBlock
								imgSrc={el.thumbnail.gatsbyImageData}
								alt={el.thumbnail.alt}
								header={el.header}
								title={el.title}
								youtubeId={el.youtubeVideoId}
								vimeoId={el.vimeoId}
								subText={el.videoSubTextNode.childMdx.body}
							/>
						)}
						{el.blocktype === 'ImageWithCopy' && (
							<ImageWithCopy
								imgSrc={el.imageWithCopyImage.gatsbyImageData}
								alt={el.imageWithCopyImage.alt}
								text={el.textNode.childMdx.body}
								imagePosition={el.imagePosition}
							/>
						)}
						{el.blocktype === 'VideoWithCopy' && (
							<VideoWithCopy
								laptopThumbnail={el.laptopThumbnail.gatsbyImageData}
								noLaptopThumbnail={el.noLaptopThumbnail.gatsbyImageData}
								alt={el.noLaptopThumbnail.alt}
								text={el.textNode.childMdx.body}
								imagePosition={el.imagePosition}
								youtubeId={el.youtubeId}
								vimeoId={el.vimeoId}
								videoClickPrompt={el.videoClickPrompt}
								thumbnailInLaptop={el.thumbnailInLaptop}
								showScrubber={el.showScrubber}
							/>
						)}
						{el.blocktype === 'FeatureBlock' && (
							<FeatureBlock
								title={el.title}
								text={el.textNode.childMdx.body}
								imgSrc1={el.image1?.gatsbyImageData}
								imgSrc2={el.image2?.gatsbyImageData}
								imgAlt1={el.image1?.alt}
								imgAlt2={el.image2?.alt}
							/>
						)}
						{el.blocktype === 'CarouselWithCopy' && (
							<CarouselWithCopy
								slides={el.images}
								text={el.textNode.childMdx.body}
								imagePosition={el.imagePosition}
							/>
						)}
						{el.blocktype === 'HighlightedCopy' && (
							<HighlightedCopy version={el.version}>
								{el.textNode.childMdx.body}
							</HighlightedCopy>
						)}
						{el.blocktype === 'StatBlob' && (
							<StatBlob
								header={el.header}
								figure={el.figure}
								text={el.textNode.childMdx.body}
								imgSrc={el.image.gatsbyImageData}
								alt={el.image.alt}
							/>
						)}
						{el.blocktype === 'Copy' && (
							<Copy
								centered={el.centered}
								background={el.background}
								width={el.width}
								textSize={el.textSize}
							>
								{el.textNode.childMdx.body}
							</Copy>
						)}
						{el.blocktype === 'WellCTA' && (
							<CMSWellCTA title={el.title} text={el.textNode.childMdx.body} />
						)}
						{el.blocktype === 'TrustBlock' && (
							<Container>
								<TrustBlock trustBlockContent={el} fullWidthPage />
							</Container>
						)}
						{el.blocktype === 'TrustStatsBlock' && (
							<Container>
								<TrustStatsBlock trustStatsBlockContent={el} />
							</Container>
						)}
					</div>
				))}

				{mainCta.length > 0 && (
					<CMSWellCTA title={mainCta[0].title} text={mainCta[0].textNode.childMdx.body} />
				)}

				<Container>
					<LocaleContent showInLocales="ca">
						<GoogleReviewsTestimonials
							googleReviews={googleReviews}
							starClass="text-gold"
							textClass="text-black"
						/>
					</LocaleContent>
				</Container>
			</CopyBlockProvider>
			<PromoPopup template="feature" />
		</DefaultLayout>
	);
};

export default FeaturePage;

export const query = graphql`
	query seoAndFeature($slug: String, $locale: String!) {
		site {
			siteMetadata {
				title
			}
		}

		datoCmsFeature(locale: { eq: $locale }, slug: { eq: $slug }) {
			_allSeoLocales {
				locale
			}
			id
			locale
			seoMetaTags {
				...GatsbyDatoCmsSeoMetaTags
			}
			heroImage {
				gatsbyImageData(layout: CONSTRAINED, width: 448)
			}
			featureName
			featureTitle
			featureDescription
			featureDescriptionNode {
				childMdx {
					body
				}
			}
			awards {
				gatsbyImageData(width: 150)
				url
			}
			slug
			_allSlugLocales {
				locale
				value
			}
			product {
				slug
				_allSlugLocales {
					locale
					value
				}
				productName
				productLogo {
					alt
					url
					width
					height
				}
				productFeatures {
					slug
					featureName
					product {
						slug
					}
				}
			}
			featureElements {
				... on DatoCmsVideoBlock {
					blocktype
					id
					header
					title
					videoSubTextNode {
						childMdx {
							body
						}
					}
					thumbnail {
						alt
						gatsbyImageData(imgixParams: { auto: "" }, layout: CONSTRAINED)
					}
					vimeoId
					youtubeVideoId
				}
				... on DatoCmsImageWithCopy {
					blocktype
					imagePosition
					textNode {
						childMdx {
							body
						}
					}
					imageWithCopyImage {
						alt
						gatsbyImageData(layout: CONSTRAINED, width: 590)
					}
				}
				... on DatoCmsVideoWithCopy {
					id
					blocktype
					imagePosition
					textNode {
						childMdx {
							body
						}
					}
					youtubeId
					vimeoId
					laptopThumbnail: thumbnail {
						alt
						gatsbyImageData(
							layout: CONSTRAINED
							width: 540
							imgixParams: { crop: "top,center", ar: "1.5", fit: "crop" }
						)
					}
					noLaptopThumbnail: thumbnail {
						alt
						gatsbyImageData(
							layout: CONSTRAINED
							width: 720
							imgixParams: { crop: "faces,center", ar: "16:9", fit: "crop" }
						)
					}
					videoClickPrompt
					thumbnailInLaptop
					showScrubber
				}

				... on DatoCmsFeatureBlock {
					blocktype
					title
					image1 {
						url
						alt
						gatsbyImageData(layout: CONSTRAINED, width: 590)
					}
					image2 {
						alt
						url
						gatsbyImageData(layout: CONSTRAINED, width: 590)
					}
					textNode {
						childMdx {
							body
						}
					}
				}
				... on DatoCmsHighlightedCopy {
					blocktype
					version
					textNode {
						childMdx {
							body
						}
					}
				}
				... on DatoCmsStatBlob {
					blocktype
					header
					textNode {
						childMdx {
							body
						}
					}
					figure
					image {
						alt
						gatsbyImageData(layout: CONSTRAINED, width: 80)
					}
				}
				... on DatoCmsCopy {
					blocktype
					textNode {
						childMdx {
							body
						}
					}
					centered
					background
					width
					textSize
				}
				... on DatoCmsCarouselWithCopy {
					blocktype
					imagePosition
					textNode {
						childMdx {
							body
						}
					}
					images {
						alt
						gatsbyImageData(
							layout: CONSTRAINED
							width: 750
							imgixParams: { crop: "top,center", ar: "1.75", fit: "crop" }
						)
					}
				}
				... on DatoCmsWellCta {
					blocktype
					title
					textNode {
						childMdx {
							body
						}
					}
				}
				... on DatoCmsTrustBlock {
					id
					blocktype
					trustBlockLayout
					trustReviewTitle
					youtubeId
					vimeoId
					trustBadge {
						url
						width
						alt
					}
					trustReviewBodyNode {
						childMdx {
							body
						}
					}
					trustImage {
						url
						width
						alt
						gatsbyImageData(width: 720)
					}
				}
				... on DatoCmsTrustStatsBlock {
					id
					blocktype
					mainHeading
					subHeading
					firstStatFigure
					firstStatText
					secondStatFigure
					secondStatText
					thirdStatFigure
					thirdStatText
					trustBadge {
						url
						alt
					}
					legalText
				}
			}
			mainCta {
				title
				textNode {
					childMdx {
						body
					}
				}
			}
		}

		googleReviews: allGoogleReview(
			limit: 6
			filter: { rating: { eq: 5 }, body: { ne: null } }
		) {
			nodes {
				id
				body
				rating
				date_utc
			}
		}

		googleReviewTotals {
			rating
			reviews
		}
	}
`;
