import React from 'react';

const knownUrls = [
	'https://www.brighthr.com/',
	'http://sandbox-www.brighthr.com/',
	'http://dev-www.brighthr.com/',
	'brightdotcom.pages.dev',
	'localhost',
];

const Anchor = (props) => {
	const { href } = props;

	const isExternal = href.startsWith('http') && !knownUrls.some((known) => href.includes(known));

	const rel = isExternal ? 'noopener' : undefined;

	const target = isExternal ? '_blank' : undefined;

	return (
		<a {...props} rel={rel} target={target} href={href} className="text-brand-500">
			{props.children}
		</a>
	);
};

export default Anchor;
