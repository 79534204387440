import React, { useEffect, useState, useMemo } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import LinkButton from '@brighthr/component-linkbutton';
import { Modal, ModalBody } from '@brighthr/component-modal';
import cn from 'classnames';
import Icon from '@brighthr/component-icon';
import Cookies from 'js-cookie';
import { useLocale } from '../../context/Locale';
import hasAuthCookie from '../../utils/hasAuthCookies';
import CopyBlock from '../CopyBlock';
import { LOCALE_PATHS } from '../../utils/locale';
import ClientOnly from '../../utils/client-only';
import setBrowserStorage from './util/setBrowserStorage';

const PromoPopup = ({ template }) => {
	const data = useStaticQuery(graphql`
		query PromoPopupQuery {
			allDatoCmsPromoPopUp(sort: { order: DESC, fields: id }) {
				nodes {
					id
					title
					bodyNode {
						childMdx {
							body
						}
					}
					callToActionText
					callToActionUrl
					startDate
					endDate
					trigger
					mobileImage {
						alt
						url
					}
					desktopImage {
						url
						alt
					}
					applyTo
					stopPopUpAfter
					showAgain
					locale
				}
			}
		}
	`);

	const locale = useLocale();
	const [existingUser, setExistingUser] = useState(false);
	const [showPromoPopup, setShowPromoPopup] = useState(false);
	const today = useMemo(() => new Date(), []);
	const { nodes: promos } = data.allDatoCmsPromoPopUp;

	if (typeof window !== 'undefined' && localStorage.getItem('cypressTest')) {
		return null;
	}

	// Check if any Promo Popups are active and applicable to the template
	const applicablePromoPopup = useMemo(() => {
		const findPromo = promos.filter((promo) => {
			if (
				today > new Date(promo.startDate) &&
				today < new Date(promo.endDate) &&
				promo.applyTo.includes(template) &&
				LOCALE_PATHS[promo.locale] === locale
			) {
				return promo;
			}
			return null;
		});
		if (findPromo.length > 1) {
			const activePromoList = findPromo.map((activePromo) => activePromo.title);
			// eslint-disable-next-line no-console
			console.log(
				`Too many active pop-ups. Please delete or deactivate any pop-ups that are no longer required. (${activePromoList})`,
			);
		}
		return findPromo[0] || null;
	}, [locale, promos, template, today]);

	// Use selected Trigger
	useEffect(() => {
		if (applicablePromoPopup?.trigger === 'exit') {
			const onMouseLeave = (event) => {
				if (
					(event.clientY <= 0 ||
						event.clientX <= 0 ||
						event.clientX >= window.innerWidth ||
						event.clientY >= window.innerHeight) &&
					!window.sessionStorage.getItem('promoPopupShown') &&
					Cookies.get('promoPopupShown') !== 'true'
				) {
					setShowPromoPopup(true);

					if (applicablePromoPopup.stopPopUpAfter === 'view') {
						setBrowserStorage(applicablePromoPopup.showAgain);
					}
				}
			};

			if (
				// already filtered expired dates out but need to check sessionStorage or Cookie
				typeof window !== 'undefined' &&
				!window.sessionStorage.getItem('promoPopupShown') &&
				Cookies.get('promoPopupShown') !== 'true'
			) {
				document.documentElement.addEventListener('mouseleave', onMouseLeave);
				return () =>
					document.documentElement.removeEventListener('mouseleave', onMouseLeave);
			}
		}

		if (
			applicablePromoPopup?.trigger === 'timed' &&
			!window.sessionStorage.getItem('promoPopupShown') &&
			Cookies.get('promoPopupShown') !== 'true'
		) {
			setTimeout(() => setShowPromoPopup(true), 1500);

			if (applicablePromoPopup.stopPopUpAfter === 'view') {
				setBrowserStorage(applicablePromoPopup.showAgain);
			}
		}
	}, [applicablePromoPopup]);

	const closePopup = () => {
		setShowPromoPopup(false);
		setBrowserStorage(applicablePromoPopup.showAgain);
	};

	// Check to see if an existing customer is signed in...
	useEffect(() => {
		if (hasAuthCookie()) {
			setExistingUser(true);
		}
	}, [locale]);

	return (
		<>
			{applicablePromoPopup && (
				<div className="hidden">
					<link rel="preload" as="image" href={applicablePromoPopup.desktopImage.url} />
					{applicablePromoPopup.mobileImage && (
						<link
							rel="preload"
							as="image"
							href={applicablePromoPopup.mobileImage.url}
						/>
					)}
				</div>
			)}

			{showPromoPopup && applicablePromoPopup && !existingUser && (
				<ClientOnly>
					<Modal
						close={() => closePopup()}
						width="lg"
						bgDismiss
						allowOverflow
						version="2"
					>
						<button
							type="button"
							className="self-end mt-3 mr-3 cursor-pointer"
							onClick={() => closePopup()}
						>
							<Icon iconName="cross-thin" size="sm" />
						</button>
						<ModalBody className="flex flex-col rounded-b-lg md:flex-row md:pt-5 md:pb-11">
							<div className="mb-3 md:w-1/2 md:mb-0 md:pr-10">
								{applicablePromoPopup.title && (
									<h1 className="mb-6 text-3xl font-extrabold text-center px-7 md:text-5xl md:pr-0">
										{applicablePromoPopup.title}
									</h1>
								)}
								{applicablePromoPopup.bodyNode.childMdx.body && (
									<div className="mb-6 text-lg text-center px-7 md:text-2xl md:pr-0 md:mb-8">
										<CopyBlock>
											{applicablePromoPopup.bodyNode.childMdx.body}
										</CopyBlock>
									</div>
								)}
								<div className="relative px-3 md:w-fit md:mx-auto md:pr-0 prompPopupCta">
									<LinkButton
										color="accent"
										size="xl"
										text={applicablePromoPopup.callToActionText}
										href={applicablePromoPopup.callToActionUrl}
										onClick={() =>
											setBrowserStorage(applicablePromoPopup.showAgain)
										}
									/>
								</div>
							</div>

							{applicablePromoPopup.desktopImage && (
								<div className="md:self-center px-7 md:w-1/2 md:px-0">
									<img
										className={cn('md:block mx-auto', {
											block: applicablePromoPopup.mobileImage === null,
											hidden: applicablePromoPopup.mobileImage !== null,
										})}
										src={applicablePromoPopup.desktopImage.url}
										alt={applicablePromoPopup.desktopImage.alt || ''}
									/>
									{applicablePromoPopup.mobileImage && (
										<img
											className="md:hidden"
											src={applicablePromoPopup.mobileImage.url}
											alt={applicablePromoPopup.mobileImage.alt || ''}
										/>
									)}
								</div>
							)}
						</ModalBody>
					</Modal>
				</ClientOnly>
			)}
		</>
	);
};

export default PromoPopup;
