import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import LazyLoad from 'react-lazyload';
import cn from 'classnames';
import CopyBlock from '../../CopyBlock';
import Container from '../../Container';
import TransparentQuote from '../../../images/home/transparent-quote.svg';
import Blob from '../../../images/blobs/wide-blob/wide-light-blue.svg';

const BlobTestimonial = ({
	imgSrc,
	large,
	alt,
	text,
	testimonial,
	companyLogoSrc,
	companyLogoAlt,
	testimonialPosition,
}) => (
	<section className="overflow-hidden lg:overflow-visible lg:my-36">
		<Container
			className={cn('relative flex flex-wrap items-start', {
				'flex-row': testimonialPosition === 'Left',
				'flex-row-reverse': testimonialPosition === 'Right',
			})}
		>
			<div className="relative w-full lg:w-1/2">
				<img
					className={cn(
						'absolute w-full -translate-y-1 -translate-x-1/3 sm:w-2/3 md:w-1/2 lg:w-full',
						{
							'lg:-translate-x-1/3 lg:-translate-y-20':
								testimonialPosition === 'Left',
							'lg:translate-x-6 lg:-translate-y-20': testimonialPosition === 'Right',
						},
					)}
					src={Blob}
					type="image/svg+xml"
					alt="Blob"
					width="738"
					height="616"
				/>

				<div className="relative">
					<div className="hidden max-w-lg mx-auto xl:inline-block">
						<LazyLoad once>
							<GatsbyImage image={imgSrc} alt={alt} />
						</LazyLoad>
					</div>
					<div
						className={cn('xl:absolute xl:top-20 bg-white rounded-lg shadow-lg box', {
							'xl:w-[35rem]': large,
							'xl:w-80': !large,
							'xl:right-10': testimonialPosition === 'Left',
							'xl:right-0': testimonialPosition === 'Right',
						})}
					>
						<div className="absolute left-2 top-2">
							<img src={TransparentQuote} type="image/svg+xml" alt="Quote " />
						</div>
						<div className="relative p-6">
							<div className="mb-6">
								<CopyBlock>{testimonial}</CopyBlock>
							</div>

							{companyLogoSrc && (
								<LazyLoad once>
									<GatsbyImage
										image={companyLogoSrc}
										className="max-w-lg mx-auto"
										alt={companyLogoAlt}
									/>
								</LazyLoad>
							)}
						</div>
					</div>
				</div>
			</div>

			<div
				className={cn('relative w-full pt-16 lg:w-1/2 lg:py-0', {
					'lg:pl-32': testimonialPosition === 'Left',
					'lg:pl-0 lg:pr-32': testimonialPosition === 'Right',
				})}
			>
				<CopyBlock>{text}</CopyBlock>
			</div>
		</Container>
	</section>
);

export default BlobTestimonial;
