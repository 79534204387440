import React from 'react';

import LazyLoad from 'react-lazyload';
import CopyBlock from '../../CopyBlock';
import Container from '../../Container';
import Carousel from '../../Carousel';

const CarouselWithCopy = ({ slides, imagePosition, text }) => (
	<section className="last:mb-0">
		<Container className="flex flex-wrap items-start">
			{imagePosition === 'Left' && (
				<div className="w-full pt-1 mb-12 lg:mb-0 lg:pr-20 lg:w-1/2">
					<LazyLoad once>
						<Carousel slides={slides} />
					</LazyLoad>
				</div>
			)}
			<div className="w-full lg:w-1/2 lg:pt-8">
				<CopyBlock>{text}</CopyBlock>
			</div>
			{imagePosition === 'Right' && (
				<div className="order-first w-full pt-1 mb-12 lg:mb-0 lg:pl-20 lg:w-1/2 lg:order-none">
					<LazyLoad once>
						<Carousel slides={slides} />
					</LazyLoad>
				</div>
			)}
		</Container>
	</section>
);

export default CarouselWithCopy;
