const DEFAULT_LEAD_SOURCE = 'Bright eCommerce';

// eslint-disable-next-line max-params
const initialFormValues = (session, qsObj, packageId, term) => ({
	tandc: false,
	cardNumber: false,
	cardCvc: false,
	cardExpiry: false,
	companyName: '',
	customerId: session.session.customerId || '',
	firstName: '',
	lastName: '',
	email: '',
	telephone: '',
	address1: '',
	address2: '',
	address3: '',
	postTown: '',
	postCode: '',
	postState: '',
	countryCode: '',
	...(session.session || {}),
	amountOfEmployees: qsObj.employees || session.session.amountOfEmployees || '',
	packageId,
	term,
	source: session.session.source || DEFAULT_LEAD_SOURCE,
	promoCode: '',
	discountPercent: '',
	customerTaxId: session.session.customerTaxId || '',
});

export default initialFormValues;
