import React from 'react';

const TabButton = ({ children, isSelected, href }) => (
	<>
		<a
			className={`inline-block w-full p-3 font-bold text-center cursor-pointer lg:mx-0 whitespace-nowrap lg:max-w-[15rem]  bg-brand-50 border-brand-400 lg:rounded-md duration-75 ${
				isSelected
					? 'bg-brand-100 font-semibold text-black'
					: 'hover:bg-brand-100 text-brand-black font-normal'
			}  focus:text-black hover:text-black focus:bg-brand-100 lg:border-x border-b lg:border-t `}
			href={href}
		>
			{children}
		</a>
	</>
);

export default TabButton;
