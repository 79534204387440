import React from 'react';
import LazyLoad from 'react-lazyload';
import cn from 'classnames';
import { graphql } from 'gatsby';
import { format } from 'date-fns';
import { buildLink } from '../../../utils/locale';

const BlogCard = ({
	stack,
	category,
	slug,
	image,
	title,
	subtitle,
	firstPublishedAt,
	updatedAt,
	date,
	locale,
}) => (
	<a
		href={buildLink(locale, `blog/${category.slug}/${slug}`)}
		className={cn(
			'mb-6 last:mb-0 bg-white border-2 border-gray-300 rounded items-stretch flex  ',
			{
				'flex-col': stack,
				'flex-col sm:flex-row md:flex-col lg:flex-row': !stack,
			},
		)}
	>
		<div
			className={cn('w-full', {
				'h-56': stack,
				'h-56 sm:h-auto md:h-56 lg:h-auto': !stack,
			})}
		>
			<LazyLoad once>
				<img
					className={cn('object-cover w-full min-h-full', {
						'h-56': stack,
						'h-56 sm:h-auto md:h-56 lg:h-auto': !stack,
					})}
					src={image.fixed.src}
					alt={image.alt}
				/>
			</LazyLoad>
		</div>
		<div
			className={cn('flex flex-col px-6 py-4 w-full', {
				'sm:w-6/12 md:w-full lg:w-6/12 h-full sm:h-auto shrink-0': !stack,
				'h-full': stack,
			})}
		>
			<p className="mb-3 text-xs font-bold text-gray-500 uppercase">{category.name}</p>
			<h2 className="mb-2 text-lg font-bold leading-snug">{title}</h2>
			<p className="mb-6 leading-normal ellipsis">{subtitle}</p>

			<div className="flex justify-between mt-auto">
				<p className="text-sm font-bold ">
					{format(new Date(date || updatedAt || firstPublishedAt), 'eeee, MMM dd, yyyy')}
				</p>

				<svg
					className=""
					width="24"
					height="14"
					viewBox="0 0 24 14"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M17.2473 0L16.112 1.13527L20.927 5.95025H0V7.55524H20.927L16.112 12.3702L17.2473 13.5055L24 6.75381L17.2473 0.00213984V0Z"
						fill="#3DB0F7"
					/>
				</svg>
			</div>
		</div>
	</a>
);

export const query = graphql`
	fragment BlogCard on DatoCmsBlogPost {
		id
		title
		subtitle
		slug
		date
		meta {
			firstPublishedAt
			updatedAt
		}
		category {
			name
			slug
			id
		}
		contentNode {
			childMdx {
				excerpt(truncate: true, pruneLength: 110)
			}
		}
		thumbnail {
			fixed(width: 500, imgixParams: { auto: "compress,format", q: 65 }) {
				src
			}
		}
		image {
			fixed(width: 500, imgixParams: { auto: "compress,format", q: 65 }) {
				src
			}
		}
	}
`;

export default BlogCard;
