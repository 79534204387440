import React from 'react';
import CountryPicker from '../../CountryPicker';
import { MetaLinks } from '../Links/index';
import { LockIcon } from '../icons/GenericIcons';

function Mininav() {
	return (
		<nav className="bg-primary-100">
			<div className="flex flex-row justify-end container mx-auto">
				<div className="flex items-center justify-end font-bold text-primary-700">
					<CountryPicker />
					<div className="hidden mx-6 space-x-6 md:block">
						<MetaLinks />
					</div>

					<a
						data-header-link="login"
						href="https://app.brighthr.com"
						className="flex items-center text-sm gap-2 px-4 py-1.5 ml-6 bg-white md:ml-0"
					>
						<LockIcon />
						Login
					</a>
				</div>
			</div>
		</nav>
	);
}

export default Mininav;
