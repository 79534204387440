import React from 'react';

const AboutUsCard = ({ country, info, address, phone, fullPhone }) => {
	const addressLines = address.split('\n');
	return (
		<div className="w-full p-4 sm:w-1/2 lg:w-1/3">
			<div className="h-full p-6 rounded-lg shadow-md">
				<h3 className="mb-2 text-2xl font-bold">{country}</h3>

				{info && <p>{info}</p>}

				<address className="mb-4 not-italic">
					{addressLines &&
						addressLines.map((part) => (
							<span key={part}>
								{part}
								<br />
							</span>
						))}
				</address>

				{fullPhone ? <a href={`tel:${fullPhone}`}>Tel: {phone}</a> : <p>Tel: {phone}</p>}
			</div>
		</div>
	);
};

export default AboutUsCard;
