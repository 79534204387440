import React, { useState } from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import CopyBlock from '../../CopyBlock';
import VideoModal from '../../VideoModal';
import ReviewBadges from '../../ReviewBadges';
import LocaleContent from '../../LocaleContent';
import VideoPlayPink from '../../../icons/VideoPlayPink.svg';
import PlayScrubber from '../../../icons/PlayScrubber.svg';
import { useLocale } from '../../../context/Locale';

const VideoHero = ({ thumbnail, youtubeId, vimeoId, text, title, underneathText }) => {
	const [open, setOpen] = useState(false);
	const locale = useLocale();

	return (
		<section className="flex flex-col items-center max-w-screen-xl px-12 py-12 mx-auto mb-6 md:mb-12 xl:flex-row">
			<div className="mb-10 md:mr-10">
				<h1 className="mb-4 text-2xl font-bold leading-7 md:leading-11 md:text-5xl">
					{title}
				</h1>
				<CopyBlock>{text}</CopyBlock>
				<div className="flex flex-row justify-center md:mt-4 md:justify-start">
					<LocaleContent showInLocales={['en', 'ie']}>
						<ReviewBadges
							locale={locale}
							pageName="who we serve"
							width="224"
							height="60"
							classes="w-48 lg:w-56"
							g2AwardClasses="ml-3 w-24 lg:w-28"
						/>
					</LocaleContent>
					<LocaleContent showInLocales={['ca', 'au', 'nz']}>
						<ReviewBadges
							locale={locale}
							pageName="who we serve"
							width={locale === 'ca' ? '110' : '170'}
							height={locale === 'ca' ? '91' : '80'}
							classes="fill-current"
							g2AwardClasses="ml-4 w-24 lg:w-28"
						/>
					</LocaleContent>
				</div>
			</div>
			<div className="flex-none max-w-[556px] my-12 shadow-lg rounded-2xl">
				<button
					type="button"
					onClick={() => setOpen(true)}
					className="relative flex-none w-full mx-auto"
				>
					<GatsbyImage className="rounded-t-xl" image={thumbnail} alt="video thumbnail" />
					<VideoPlayPink className="absolute z-20 w-16 h-auto translate-x-1/2 translate-y-1/2 right-1/2 bottom-1/2" />
					<PlayScrubber className="absolute bottom-0 z-20 m-4" />
				</button>
				<p className="bottom-0 w-full py-2 font-bold text-center text-gray-700 bg-white rounded-b-xl">
					{underneathText}
				</p>
			</div>
			{open && (
				<VideoModal
					youtubeId={youtubeId}
					vimeoId={vimeoId}
					onClose={() => setOpen(false)}
				/>
			)}
		</section>
	);
};

export default VideoHero;
