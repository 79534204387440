/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useRef, useState, useEffect } from 'react';

import Button from '@brighthr/component-button';
import { Field, useFormikContext } from 'formik';

import MarketoForm from '../Form';

const datefns = require('date-fns');

const Form = ({ title, subtitle }) => {
	const [todaysDate, setTodaysDate] = useState();

	useEffect(() => {
		const today = datefns.format(new Date(), 'yyyy-MM-dd');
		setTodaysDate(today);
	}, []);

	const targetScrollElement = useRef(null);

	const scrollToElement = () => targetScrollElement.current.scrollIntoView();

	const { handleChange, values, isSubmitting } = useFormikContext();

	return (
		<>
			<div className="flex flex-col max-w-full" ref={targetScrollElement}>
				<div className="mb-8">
					<h1 className="mb-4 text-2xl font-bold tracking-tight md:text-4xl">{title}</h1>
					<h2 className="text-xl md:text-xl">{subtitle}</h2>
				</div>
				<div className="mb-2 ">
					<div className="flex flex-wrap w-full mb-2 md:flex-nowrap">
						<div className="w-full md:pr-3">
							<label
								htmlFor="FirstName"
								className="block w-full mb-2 font-bold text-left text-black"
							>
								First name
							</label>
							<input
								id="FirstName"
								name="FirstName"
								placeholder="First name"
								className="w-full px-4 py-2 text-gray-800 border-2 border-gray-500 rounded"
								required
							/>
						</div>
						<div className="w-full md:pl-3">
							<label
								htmlFor="LastName"
								className="block w-full mb-2 font-bold text-left text-black "
							>
								Last name
							</label>
							<input
								id="LastName"
								name="LastName"
								placeholder="Last name"
								className="w-full px-4 py-2 text-gray-800 border-2 border-gray-500 rounded"
								required
							/>
						</div>
					</div>

					<div className="flex flex-wrap w-full mb-2 md:flex-nowrap">
						<div className="w-full md:pr-3">
							<label
								htmlFor="PhoneNumber"
								className="block w-full mb-2 font-bold text-left text-black"
							>
								Phone number
							</label>
							<input
								id="PhoneNumber"
								name="Phone"
								type="tel"
								placeholder="01632 960410"
								className="w-full px-4 py-2 text-gray-800 border-2 border-gray-500 rounded"
								pattern="[0-9\s]{7,16}"
								title="Enter a valid telephone number between 7 and 16 digits."
								required
							/>
						</div>
						<div className="w-full md:pl-3">
							<label
								htmlFor="Company"
								className="block w-full mb-2 font-bold text-left text-black"
							>
								Company name
							</label>
							<input
								name="Company"
								id="Company"
								type="text"
								placeholder="ACME Inc."
								className="w-full px-4 py-2 text-gray-800 border-2 border-gray-500 rounded"
								required
							/>
						</div>
					</div>

					<div className="flex flex-wrap w-full mb-2 md:flex-nowrap">
						<div className="w-full">
							<label
								htmlFor="Email"
								className="block w-full mb-2 font-bold text-left text-black"
							>
								Email
							</label>
							<input
								id="Email"
								name="Email"
								placeholder="Email address"
								className="w-full px-4 py-2 text-gray-800 border-2 border-gray-500 rounded"
								type="email"
								required
							/>
						</div>
					</div>

					<div className="flex flex-col items-center mb-2 md:flex-row">
						<label
							htmlFor="WBNLeadPreferredDate"
							className="block w-full font-bold text-left text-black md:w-auto"
						>
							Preferred date for contact:
							<div className="flex flex-col items-center md:flex-row">
								<input
									type="text"
									onFocus={(e) => {
										e.currentTarget.type = 'date';
										e.currentTarget.focus();
									}}
									placeholder="Select date"
									name="WBNLeadPreferredDate"
									id="WBNLeadPreferredDate"
									min={todaysDate}
									required
									value={values.WBNLeadPreferredDate}
									onChange={handleChange}
									className="block w-full px-4 py-2 mt-2 border-2 border-gray-500 rounded focus-visible:drop-shadow-md focus-visible:border-brand-400"
								/>
							</div>
						</label>
						<div className="flex flex-col w-full md:ml-8 md:w-auto">
							<label
								htmlFor="WBNLeadPreferredTime"
								className="block w-full font-bold text-left text-black md:w-auto"
							>
								Time:
							</label>

							<input
								type="time"
								id="WBNLeadPreferredTime"
								name="WBNLeadPreferredTime"
								value={values.WBNLeadPreferredTime}
								onChange={handleChange}
								required
								className="block w-full px-4 py-2 mt-2 border-2 border-gray-500 rounded focus-visible:drop-shadow-md focus-visible:border-brand-400"
							/>
						</div>
					</div>

					<div>
						<label className="block w-full font-bold text-left text-black">
							Software/advice services/both
						</label>
						<div className="py-2">
							<div className="inline-flex items-center text-center">
								<Field
									name="WBNLeadSoftware"
									id="WBNLeadSoftware"
									type="checkbox"
									className="inline-block mr-2 hover:cursor-pointer"
								/>
								<span>Software</span>
							</div>
							<div className="inline-flex items-center mx-4 text-center">
								<Field
									name="WBNLeadAdvice"
									id="WBNLeadAdvice"
									type="checkbox"
									className="mr-2 hover:cursor-pointer"
								/>
								<span>Advice</span>
							</div>
						</div>
					</div>

					<div className="pt-4">
						<div className="mb-1 font-bold">
							Is there anything specific you&apos;d like us to take you through?
						</div>
						<textarea
							name="Contact_Us_Comments__c"
							onChange={handleChange}
							value={values.Contact_Us_Comments__c}
							placeholder="Your message.."
							className="block w-full h-32 px-4 py-2 text-gray-900 bg-white border-2 border-gray-500 rounded"
							required
						/>
					</div>

					<div className="flex flex-col mt-4 ">
						<Button
							color="accent"
							type="submit"
							disabled={isSubmitting}
							onClick={scrollToElement}
							text="Submit"
							size="lg"
						/>
					</div>
				</div>
			</div>
		</>
	);
};

const WebinarLeadForm = () => (
	<MarketoForm
		preload
		formId={2796}
		initialValues={{
			FirstName: '',
			LastName: '',
			Phone: '',
			Email: '',
			Company: '',
			WBNLeadPreferredDate: '',
			WBNLeadPreferredTime: '',
			WBNLeadAdvice: '',
			WBNLeadSoftware: '',
			Contact_Us_Comments__c: '',
			LeadSource: 'Prospect Webinar',
		}}
		successMessage={
			<>
				<div className="text-center lg:mt-32">
					<h2 className="mb-2 text-xl font-bold text-black lg:text-2xl">
						Thanks for your interest in BrightHR
					</h2>
					<p className="mb-2 text-base font-bold text-black lg:text-lg">
						A member of the team will be in touch shortly.
					</p>
				</div>
			</>
		}
	>
		<Form
			title="Book a custom demo with one of our experts"
			subtitle="Fill out the form below so we can tailor the demo to best suit you."
		/>
	</MarketoForm>
);
export default WebinarLeadForm;
/* eslint-enable jsx-a11y/label-has-associated-control */
