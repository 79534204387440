import React from 'react';
import LinkButton from '@brighthr/component-linkbutton';
import CountdownTimer from '../CountdownClock';
import WebinarPopupContent from './WebinarPopupContent';

const LiveWebinarCountdownPopup = ({ upcomingWebinar, href }) => (
	<>
		<div className="p-4">
			{upcomingWebinar.speakerOneShowLogo && (
				<div>
					<img
						src={upcomingWebinar.speakerOne.logo2.url}
						alt=""
						className="max-w-[200px] m-2 md:m-5 md:mb-8"
					/>
				</div>
			)}
			<h3 className="m-2 text-3xl font-bold md:m-5">Before you go... Secure your place</h3>
			<CountdownTimer
				targetDate={`${upcomingWebinar.liveWebinarDate}T${upcomingWebinar.liveWebinarTime}:00`}
			/>
		</div>
		<div
			className={`flex justify-center ${
				upcomingWebinar.speakerOne?.externalSpeaker
					? 'external-speaker-popover-transparent-bg text-white'
					: 'webinar-popover-transparent-bg'
			}`}
		>
			{upcomingWebinar.speakerOne.externalSpeaker && (
				<div className="absolute right-0 pb-4 pr-5">
					<img
						src={upcomingWebinar.speakerTwo.logo.url}
						alt=""
						className="pt-12 xs:pt-16 sm:pt-10 pr-3.5"
					/>
				</div>
			)}
			<div className="px-8 pt-12 pb-8">
				<div className="pt-10">
					<WebinarPopupContent
						subHeading="Join our Webinar on"
						heading={upcomingWebinar.title}
						description={
							upcomingWebinar.speakerOne.externalSpeaker ? (
								<p className="mb-4 leading-tight">{upcomingWebinar.description}</p>
							) : (
								<p className="mb-4 leading-tight">
									{upcomingWebinar.description} Hosted by{' '}
									<span className="font-bold">
										{upcomingWebinar.speakerOne.fullName}
									</span>
									.
								</p>
							)
						}
						date={`${upcomingWebinar.liveWebinarDate}T${upcomingWebinar.liveWebinarTime}:00`}
						CTA={
							<LinkButton
								href={`${href}?utm_source=website&utm_medium=popup&utm_content=countdown`}
								target="_blank"
								id="webinar-popup-countdown"
								text="Save my seat"
							/>
						}
					/>
				</div>
			</div>
		</div>
	</>
);

export default LiveWebinarCountdownPopup;
