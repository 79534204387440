import React, { useState, useEffect, useRef } from 'react';
import cn from 'classnames';

import throttle from 'lodash.throttle';
import ChevronSmall from '../../../../../../icons/ChevronSmall.svg';

import ArrowLeftLarge from '../../../../../../icons/ArrowLeftLarge.svg';
import BrightLogoCircle from '../../../../../../icons/BrightLogoCircle.svg';

export default ({ children }) => {
	const [detailsDrawerOpen, setDetailsDrawerOpen] = useState(false);
	const [isScrolling, setIsScrolling] = useState(false);
	const mobileHeaderRef = useRef(null);

	useEffect(() => {
		const onScroll = throttle(() => {
			if (mobileHeaderRef.current.getBoundingClientRect().top + window.scrollY <= 0) {
				setIsScrolling(false);
			} else {
				setIsScrolling(true);
			}
		}, 100);

		window.addEventListener('scroll', onScroll);

		return () => window.removeEventListener('scroll', onScroll);
	}, [setIsScrolling]);

	return (
		<div
			className={cn('fixed w-full top-0 z-10 block lg:hidden', {
				'border-b border-gray-300': isScrolling,
			})}
			ref={mobileHeaderRef}
		>
			<div
				className={cn(
					'flex flex-row justify-between transition-colors duration-300 px-5 py-3 flex-nowrap',
					{
						'bg-white': isScrolling || detailsDrawerOpen,
					},
					{
						'bg-transparent': !isScrolling && !detailsDrawerOpen,
					},
				)}
			>
				<a className="flex flex-row items-center flex-nowrap" href="/pricing/">
					<ArrowLeftLarge /> <BrightLogoCircle className="ml-2" />{' '}
					<span className="ml-2">BrightHR</span>
				</a>
				<button
					type="button"
					className="flex flex-row items-center flex-nowrap"
					onClick={() => setDetailsDrawerOpen(!detailsDrawerOpen)}
				>
					Details{' '}
					<ChevronSmall
						className={cn('ml-2 transition-transform duration-200', {
							'transform rotate-180': detailsDrawerOpen,
						})}
					/>
				</button>
			</div>
			<div
				className={cn(
					'overflow-hidden bg-white transition-all duration-200 ease-linear',
					{
						'h-full shadow-lg max-h-screen': detailsDrawerOpen,
					},
					{
						'h-0 max-h-0 ': !detailsDrawerOpen,
					},
				)}
			>
				<div className="p-5">{children}</div>
			</div>
		</div>
	);
};
