import React from 'react';
import cn from 'classnames';

import { useLocation } from '@reach/router';
import DefaultLayout from '../../layouts/Default';
import SEO from '../../components/SEO';

import content from '../../content/pricing_ie';
import PricingCards from '../../components/PricingCards';
import HrefLang from '../../components/HrefLang';

const PriceBadge = ({ price, className }) => (
	<span
		className={cn(
			'hidden lg:inline-flex text-center flex-col items-center justify-start text-white rounded-full bg-brand-400 rotate-12 absolute right-0 mt-5',
			className,
		)}
		style={{
			width: '10rem',
			height: '10rem',
		}}
	>
		<span className="block mt-8 text-sm font-normal">All for as little as</span>
		<span className="text-3xl font-bold">{price}</span>
		<span className="text-sm font-normal">per employee per month</span>
	</span>
);

const PricingPage = () => {
	const location = useLocation();

	return (
		<DefaultLayout>
			<SEO
				path={location.pathname}
				title="HR Management Software Pricing in Ireland"
				description="Our range of HR software features numerous price packages to ensure affordability - perfect for your business."
				titleSuffix="Our packages"
			/>
			<HrefLang locales={['en', 'en-IE']} slug="pricing" />
			<div className="relative pt-4 text-gray-800">
				<PriceBadge price="€7.95" />

				<div className="mb-10 text-center lg:px-32">
					<h1 className="mb-8 text-4xl font-bold leading-tight md:text-5xl">
						Your new HR management starts here
					</h1>

					<p className="max-w-2xl mx-auto">
						Whether you run a business with a cosy team of five people or you’re growing
						by the month, we&apos;ll craft the perfect BrightHR package for you.
					</p>
				</div>

				<section className="mb-16">
					<PricingCards demoLink="/ie/book-a-demo" content={content} buyOnline={false} />
				</section>
			</div>
		</DefaultLayout>
	);
};

export default PricingPage;
