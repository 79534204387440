import React, { useState, useEffect, useRef } from 'react';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import CopyBlock from '../components/CopyBlock';
import DefaultLayout from '../layouts/Default';
import BlobSmall from '../images/blobs/headshot-blob-small.svg';
import BlobSmallDots from '../images/blobs/headshot-blob-small-dots.svg';
import ProfileInfoBox from '../components/Leadership/ProfileInfoBox';
import LeadershipProfile from '../components/Leadership/Profile';
import LeadershipSocials from '../components/Leadership/Socials';
import StaticCTA from '../components/StaticCTA';

const Leadership = ({ data, pageContext }) => {
	const [organisedProfiles, setOrganisedProfiles] = useState([]);
	const [infoBox, setInfoBox] = useState({ slug: null });
	const { heroProfile, featured, copyNode, header } = data.datoCmsLeadership;

	useEffect(() => {
		// Aim of this is to group each profile with three in an array, and the remainder profiles at the end
		const threeProfiles = featured.map((profile, index) => {
			const remainderProfiles = featured.length % 3;
			if (index === featured.length - remainderProfiles) {
				return featured.slice(index, index + remainderProfiles);
			}
			if ((index + 1) % 3 === 0) {
				return featured.slice(index - 2, index + 1);
			}
			return null;
		});
		const removeNull = threeProfiles.filter((el) => el !== null);
		setOrganisedProfiles(removeNull);
	}, [featured]);

	const targetScrollElement = useRef(null);

	const scrollToElement = () => targetScrollElement.current.scrollIntoView();

	const { locale } = pageContext;

	return (
		<>
			<DefaultLayout>
				<div className="max-w-screen-xl mx-auto">
					<div className="mt-6 mb-12 text-center lg:text-left">
						<h1 className="mb-4 text-3xl font-bold lg:text-5xl">{header}</h1>
						<div className="text-lg">
							<CopyBlock>{copyNode.childMdx.body}</CopyBlock>
						</div>
					</div>

					<div className="mb-10 lg:mb-16">
						<LeadershipProfile profileData={heroProfile} heroProfile />
					</div>

					{organisedProfiles.map((profileRow, upperIndex) => (
						<div key={profileRow[0].slug} className="flex flex-col mb-6">
							<div className="flex flex-col gap-12 mx-auto lg:p-4 lg:flex-row">
								{profileRow.map((profile) => (
									<div key={profile.slug}>
										<div
											className={`relative w-full max-w-xs xl:max-w-sm mx-auto lg:mb-auto transition duration-150 ease-in-out ${
												profile.slug === infoBox.slug
													? 'lg:scale-125 lg:translate-y-16'
													: ''
											}`}
										>
											<button
												className="hidden lg:block group"
												onClick={
													infoBox.slug === profile.slug
														? () => setInfoBox({ slug: null })
														: () => {
																setInfoBox({
																	...profile,
																	upperIndex,
																});
																scrollToElement();
														  }
												}
												type="button"
											>
												<img
													src={BlobSmall}
													alt=""
													width="670"
													height="614"
												/>
												<div
													className="absolute top-0 right-0 h-full overflow-hidden blob-mask-small"
													ref={targetScrollElement}
												>
													<div className="transition duration-150 ease-in-out scale-110 translate-y-16 group-hover:scale-125">
														<GatsbyImage
															image={profile.image.gatsbyImageData}
															alt={profile.image.alt || 'headshot'}
														/>
													</div>
												</div>
												<div className="absolute top-0 right-0 w-full h-full hover:bg-white/10" />
											</button>
											<div className="block lg:hidden">
												<img
													className="w-full"
													src={BlobSmallDots}
													alt=""
													width="670"
													height="685"
												/>
												<div className="absolute h-90% top-0 right-0 blob-mask-small">
													<div className="scale-125 translate-y-20">
														<GatsbyImage
															image={profile.image.gatsbyImageData}
															alt={profile.image.alt || 'headshot'}
														/>
													</div>
												</div>
											</div>
										</div>
										<div className="min-h-[112px]">
											<div
												className={`flex flex-col mt-4 ${
													profile.slug === infoBox.slug ? 'lg:hidden' : ''
												}`}
											>
												<p className="text-2xl font-bold text-brand-400">
													{profile.name}
												</p>
												<p className="mb-4 font-bold">
													{profile.companyRole}
												</p>
												<div>
													<div className="block mb-4 lg:hidden">
														<CopyBlock>
															{profile.descriptionNode.childMdx.body}
														</CopyBlock>
													</div>
													<LeadershipSocials
														tiktok={profile.tiktok}
														twitter={profile.twitter}
														linkedin={profile.linkedin}
													/>
												</div>
											</div>
										</div>
									</div>
								))}
							</div>

							<div>
								{infoBox && infoBox.upperIndex === upperIndex && (
									<ProfileInfoBox
										infoBox={infoBox}
										locale={locale}
										setInfoBox={setInfoBox}
									/>
								)}
							</div>
						</div>
					))}
				</div>
				<div className="mt-12 md:mt-20 md:mb-16">
					<StaticCTA locale={locale} />
				</div>
			</DefaultLayout>
		</>
	);
};

export default Leadership;

export const query = graphql`
	query leadershipQuery($locale: String!) {
		datoCmsLeadership(locale: { eq: $locale }) {
			header
			copyNode {
				childMdx {
					body
				}
			}

			heroProfile {
				name
				companyRole
				slug
				tiktok
				twitter
				linkedin
				quoteNode {
					childMdx {
						body
					}
				}
				quoteTwoNode {
					childMdx {
						body
					}
				}
				descriptionNode {
					childMdx {
						body
					}
				}
				image {
					gatsbyImageData
					alt
				}
			}

			featured {
				slug
				name
				companyRole
				tiktok
				twitter
				linkedin
				descriptionNode {
					internal {
						content
					}
					childMdx {
						body
					}
				}
				image {
					gatsbyImageData
					alt
				}
			}
		}
	}
`;
