/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-nested-ternary */
import React, { useRef } from 'react';
import { graphql } from 'gatsby';
import { nanoid } from 'nanoid';
import { GatsbyImage } from 'gatsby-plugin-image';
import { useLocation } from '@reach/router';
import Icon from '@brighthr/component-icon';
import DefaultLayout from '../layouts/Default';
import NewsCard from '../components/NewsCard';
import Dropdown from '../components/Dropdown';
import TabButton from '../components/TabButton';
import useFuse from '../components/useFuse';
import Container from '../components/Container';
import Pagination from '../components/Pagination';
import usePagination from '../hooks/usePagination';
import usePaginatedSearch from '../hooks/usePaginatedSearch';
import CopyBlock from '../components/CopyBlock';
import ImageWithCopy from '../components/CMSBlocks/ImageWithCopy';
import HrefLang from '../components/HrefLang';
import DatoSEO from '../components/DatoSEO';
import { useLocale } from '../context/Locale';

const sortArticles = (articles) =>
	articles.sort((a, b) => new Date(b.releaseDate) - new Date(a.releaseDate));

const NewsPage = ({ data }) => {
	const { title, heroContentNode, heroImage, elements, _allTitleLocales, seoMetaTags, slug } =
		data.datoCmsNews;
	const { nodes: pressReleases } = data.allMultisitePressReleases;
	const { nodes: inTheNews } = data.allMultisiteInTheNews;
	const { nodes: awards } = data.allMultisiteAwards;
	const allLatestNews = sortArticles([...inTheNews, ...pressReleases, ...awards]);
	const sortedPressReleases = sortArticles(pressReleases);
	const sortedInTheNews = sortArticles(inTheNews);
	const sortedAwards = sortArticles(awards);
	const { page } = usePagination();
	const locale = useLocale();
	const location = useLocation();
	const searchInputRef = useRef();

	const tabMap = {
		all: { text: 'All', data: allLatestNews },
		press: { text: 'Press Releases', data: sortedPressReleases },
		news: { text: 'In the News', data: sortedInTheNews },
		awards: { text: 'Awards', data: sortedAwards },
	};

	const fuseOptions = {
		keys: [
			{
				name: 'title',
				weight: 0.7,
			},
			{
				name: 'articleCopy',
				weight: 0.3,
			},
		],
		minMatchCharLength: 3,
		isCaseSensitive: false,
		distance: 1000,
		threshold: 0.3,
	};

	const { filter } = usePaginatedSearch({
		search: () => {},
		searchInputRef: null,
	});

	const { result, search } = useFuse(
		filter === 'all'
			? allLatestNews
			: filter === 'press'
			? pressReleases
			: filter === 'news'
			? inTheNews
			: filter === 'awards'
			? awards
			: allLatestNews,
		fuseOptions,
	);

	const { searchInput, clearSearch, showSearchResults } = usePaginatedSearch({
		searchInputRef,
		search,
	});

	const PER_PAGE = 9;

	const pageUpdate = page;

	const indexOfLastDocuments = pageUpdate * PER_PAGE;
	const indexOfFirstDocuments = indexOfLastDocuments - PER_PAGE;

	const localeResults = result?.filter((article) => article.itemCountry.includes(locale));

	const pageNumbers = [];
	for (let i = 1; i <= Math.ceil(localeResults.length / PER_PAGE); i++) {
		pageNumbers.push(i);
	}

	const TabContent = ({ results }) => {
		if (results.length > 0) {
			return (
				<div className="block grid-cols-3 gap-8 mb-4 lg:mb-8 lg:grid">
					{results.map((newsItem) => (
						<NewsCard key={nanoid()} newsItem={newsItem} />
					))}
				</div>
			);
		}

		return (
			<div className="pt-3 font-bold text-center">
				No results found, please try clearing your filters
			</div>
		);
	};

	return (
		<>
			<HrefLang locales={_allTitleLocales} slug={slug} />
			<DatoSEO path={location.pathname} meta={seoMetaTags} />
			<section className="overflow-hidden text-center lg:text-left md:pt-8 bg-gradient-to-t from-white to-brand-200 main-banner-section-bg lg:relative">
				<div className="px-6 py-4 max-w-7xl lg:flex lg:py-4 lg:mx-auto">
					<div className="relative z-10 space-y-4 lg:w-7/12">
						<h1 className="my-4 text-4xl font-bold leading-none md:text-5xl md:mt-0">
							{title}
						</h1>
						<div className="lg:max-w-md">
							<CopyBlock>{heroContentNode.childMdx.body}</CopyBlock>
						</div>
					</div>

					<div className="relative z-0 w-full mt-8 lg:mt-0 lg:flex lg:justify-end lg:w-1/2 md:ml-auto">
						<GatsbyImage image={heroImage.gatsbyImageData} />
					</div>
				</div>
			</section>
			<section>
				<Container className="pt-2 pb-2 mt-0 mb-0 md:pb-6 lg:mb-8 lg:px-3 max-w-7xl ">
					<h3 className="m-auto mb-6 text-2xl font-bold text-center md:text-3xl lg:mb-8">
						Latest news
					</h3>
					<p className="font-bold text-center md:text-left">Search by keyword:</p>
					<div className="relative mt-2 mb-5 ">
						<input
							id="search"
							className="w-full px-12 py-3 mx-auto placeholder-gray-500 border border-gray-400 rounded-md outline-none"
							placeholder="Search by keyword..."
							onChange={(e) => {
								showSearchResults(e);
							}}
							ref={searchInputRef}
							value={searchInput}
						/>
						<Icon
							iconName="cross-thin"
							size="xs"
							type="button"
							onClick={() => clearSearch()}
							className="absolute top-4 right-4 hover:cursor-pointer"
						/>
						<Icon
							iconName="search"
							size={24}
							className="absolute top-[0.8rem] left-2"
						/>
					</div>

					<p className="font-bold text-center md:text-left">Filter by category:</p>
					<div className="flex-row hidden py-2 lg:flex lg:gap-8 md:mb-12">
						{Object.keys(tabMap).map((tabId) => (
							<TabButton
								isSelected={filter === tabId}
								href={`?page=1${`&filter=${tabId}`}${
									searchInput ? `&search=${searchInput}` : ''
								}`}
								key={tabId}
							>
								{tabMap[tabId].text}
							</TabButton>
						))}
					</div>

					<div className="w-full py-2 pointer-events-auto select-none md:py-5 dropdown lg:hidden">
						<Dropdown selectedOption={tabMap[filter]?.text || tabMap.all.text}>
							{Object.keys(tabMap).map((tabId) => (
								<TabButton
									isSelected={filter === tabId}
									href={`?page=1${`&filter=${tabId}`}${
										searchInput ? `&search=${searchInput}` : ''
									}`}
									key={tabId}
								>
									{tabMap[tabId].text}
								</TabButton>
							))}
						</Dropdown>
					</div>
					<TabContent
						results={localeResults.slice(indexOfFirstDocuments, indexOfLastDocuments)}
					/>

					{localeResults.length > PER_PAGE && (
						<Pagination page={page} pageNumbers={pageNumbers} filter={filter} />
					)}
				</Container>
			</section>

			<section>
				<div className="mx-auto overflow-hidden max-w-7xl">
					{elements.map((el) => (
						<div className="mb-6 md:mb-12" key={el.id}>
							{el.blocktype === 'ImageWithCopy' && (
								<ImageWithCopy
									imgSrc={el.imageWithCopyImage.gatsbyImageData}
									alt={el.imageWithCopyImage.alt}
									text={el.textNode.childMdx.body}
									imagePosition={el.imagePosition}
								/>
							)}
						</div>
					))}
				</div>
			</section>
		</>
	);
};

const WrappedNewsPage = (props) => (
	<DefaultLayout fullWidth>
		<NewsPage {...props} />
	</DefaultLayout>
);

export default WrappedNewsPage;

export const query = graphql`
	query newspage($locale: String!) {
		datoCmsNews(locale: { eq: $locale }) {
			_allTitleLocales {
				locale
			}
			seoMetaTags {
				...GatsbyDatoCmsSeoMetaTags
			}
			slug
			locale
			title
			heroContentNode {
				childMdx {
					body
				}
			}
			heroImage {
				gatsbyImageData(layout: CONSTRAINED, width: 590)
			}
			elements {
				... on DatoCmsImageWithCopy {
					id
					blocktype
					imagePosition
					textNode {
						childMdx {
							body
						}
					}
					imageWithCopyImage {
						alt
						gatsbyImageData(layout: CONSTRAINED, width: 590)
					}
				}
			}
		}
		allMultisitePressReleases {
			nodes {
				id
				title
				subtitle
				externalArticleLink
				releaseDate
				slug
				isArticleInternal
				newsType
				itemCountry
				articleCopy
			}
		}
		allMultisiteInTheNews {
			nodes {
				title
				subtitle
				externalArticleLink
				releaseDate
				id
				slug
				isArticleInternal
				newsType
				articleCopy
				itemCountry
			}
		}
		allMultisiteAwards(filter: { id: { ne: "7749ca3d-a65c-5235-a9e1-65dcf65285d0" } }) {
			nodes {
				title
				subtitle
				externalArticleLink
				releaseDate
				id
				slug
				isArticleInternal
				newsType
				articleCopy
				itemCountry
			}
		}
	}
`;
