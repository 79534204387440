import React from 'react';
import { useLocation } from '@reach/router';
import DefaultLayout from '../layouts/Default';

import Shield from '../images/security/gdpr-shield.svg';
import Globe from '../images/security/globe-connections.png';
import SystemSecurity from '../images/security/mobile-desktop-security.svg';
import Hero from '../images/security/security-hero.png';
import SecurityModel from '../images/security/security-model.svg';
import Azure from '../images/security/azure.svg';

import UkFlag from '../images/security/UkFlag.svg';
import IeFlag from '../images/security/IeFlag.svg';
import NzFlag from '../images/security/NzFlag.svg';
import AuFlag from '../images/security/AuFlag.svg';
import CaFlag from '../images/security/CaFlag.svg';

import Card from '../components/Card/index';
import CheckCard from '../components/Card/check';
import Var from '../components/Var';
import HrefLang from '../components/HrefLang';
import { buildLink } from '../utils/locale';
import { useLocale } from '../context/Locale';
import SEO from '../components/SEO';

const Security = () => {
	const locale = useLocale();
	const location = useLocation();

	return (
		<>
			<HrefLang locales={['en']} slug="security" />
			<SEO
				path={location.pathname}
				title="Security"
				description="Security and Reliability"
				locale={locale}
			/>

			<div
				className="bg-no-repeat bg-cover "
				style={{
					backgroundImage: `linear-gradient(to right, rgba(15, 51, 77, 0.6), rgba(15, 51, 77, 0.9)), url(${Hero})`,
				}}
			>
				<div className="max-w-6xl py-24 mx-auto md:pt-48 md:pb-32">
					<div className="px-6 md:w-6/12">
						<h1 className="pb-4 text-3xl font-bold text-white">
							Security and Reliability
						</h1>
						<h4 className="text-lg text-white">
							BrightHR is committed to keeping your employee and business information
							safe and secure. This page explains how we protect, safeguard and store
							your data.
						</h4>
					</div>
				</div>
			</div>

			<div className="flex flex-col max-w-6xl pt-20 pb-10 mx-auto md:flex-row md:pt-40 md:pb-24">
				<div className="flex flex-col justify-center px-6 pb-12 md:w-6/12">
					<h2 className="pb-4 text-2xl font-bold leading-7">Who is BrightHR?</h2>
					<p className="pb-4 text-base ">
						We&#39;re a trusted HR software provider for small business owners and we’ve
						designed software systems for over five years.
					</p>
					<p className="text-base ">
						We have a global audience with over <Var name="numCompanies" /> customers in
						the UK, Ireland, Canada, New Zealand and Australia—and we’re still growing.
					</p>
				</div>
				<div className="grid grid-cols-3 gap-1 mx-auto md:mx-0 md:pl-16 ">
					<div className="flex justify-center ">
						<img src={UkFlag} alt="United Kingdom flag" className="mx-auto" />
					</div>
					<div className="flex justify-center ">
						<img src={IeFlag} alt="Republic of Ireland flag" className="mx-auto" />
					</div>
					<div className="flex justify-center ">
						<img src={AuFlag} alt="Australia flag" className="mx-auto" />
					</div>
					<div className="flex justify-center ">
						<img src={NzFlag} alt="New Zealand flag" className="mx-auto" />
					</div>
					<div className="flex justify-center ">
						<img src={CaFlag} alt="Canada flag" className="mx-auto" />
					</div>
				</div>
			</div>

			<div
				className="bg-no-repeat bg-cover "
				style={{
					backgroundImage: `linear-gradient(360deg, #0057AD 0.04%, #0A2233 100%)`,
				}}
			>
				<div className="flex flex-col max-w-5xl px-6 pt-20 pb-24 mx-auto text-left align-center md:text-center md:pt-40 md:pb-32">
					<div className="pb-10 md:pb-20">
						<h2 className="pb-6 text-2xl font-bold leading-7 text-white">
							How does your software perform?
						</h2>
						<h4 className="pb-6 text-base text-white">
							We know how important it is for you to access your business data
							quickly. That’s why we host several instances of our software in
							multiple data centres and use traffic management technology to direct
							traffic to the fastest responding service.
						</h4>
						<p className="text-base text-white">
							As a result, our software always operates at a high level of speed and
							reliability.
						</p>
					</div>
					<img src={SecurityModel} alt="azure security model" className="mx-auto" />
				</div>
			</div>

			<div className="flex flex-col items-center max-w-6xl px-6 pt-20 pb-10 mx-auto md:flex-row-reverse md:py-16">
				<div className="pb-12 md:w-6/12 md:pl-8">
					<h2 className="pb-4 text-2xl font-bold leading-7">
						What security measures do you have in place?
					</h2>
					<p className="pb-4 text-base ">
						With our two-factor authentication feature, a BrightHR user has to enter a
						unique code sent to their mobile to access their BrightHR account.
					</p>
					<p className="text-base ">
						We also ask users to follow strong password complexity rules and change
						their passwords regularly. Plus, we monitor any changes to admin accounts to
						make sure they’re genuine..
					</p>
				</div>
				<div className="w-full md:pr-8 md:w-6/12">
					<img src={SystemSecurity} alt="security procedures " className="mx-auto" />
				</div>
			</div>

			<div
				className="bg-right bg-no-repeat bg-contain md-bg-img-none xs-bg-img-none bg-brand-900 "
				style={{
					backgroundImage: ` url(${Globe})`,
				}}
			>
				<div className="flex flex-col max-w-6xl px-6 pt-20 pb-24 mx-auto align-center md:pt-32 md:pb-32">
					<div className="w-full mx-auto lg:w-6/12 lg:mx-0">
						<h2 className="pb-8 text-2xl font-bold leading-7 text-white">
							Your data and where it&#39;s held
						</h2>
						<p className="pb-4 text-base text-white">
							We hold your data in highly secure local data centres. For customers
							subscribing in the Republic of Ireland we store data in the European
							Union. For Customers subscribing in the United Kingdom we store data in
							the United Kingdom and the European Union. For Customers subscribing in
							Canada, Australia or New Zealand we store data in the county of
							subscription.
						</p>
						<p className="text-base text-white">
							This complies with the Data Protection Act (DPA) and the General Data
							Protection Regulation (GDPR) and local data protection legislation. We
							assume that both the DPA and GDPR will not change following the UK&#39;s
							exit from the European Union. However, we will manage any law changes to
							make sure we remain compliant.
						</p>
					</div>
				</div>
			</div>

			<div className="bg-no-repeat bg-cover ">
				<div className="flex flex-col max-w-6xl px-6 pt-20 pb-24 mx-auto text-left align-center md:text-center md:pt-40 md:pb-32">
					<div className="pb-6 md:pb-10">
						<h2 className="pb-6 text-2xl font-bold leading-7">
							How we monitor access to your data
						</h2>
						<p className="pb-6 text-base ">
							The security of your data is our top priority, which is why we have a
							dedicated information and cyber security team.
						</p>
						<p className="text-base ">
							We use standard authentication mechanisms to identify users, so we know
							exactly who’s accessing your data. We also restrict or allow access to
							data based on a user’s role and their need to access the data, to make
							sure that information stays confidential.
						</p>
					</div>
					<div className="w-full pb-6 mx-auto md:w-8/12">
						<img src={Azure} alt="azure security management" />
					</div>
					<p className="pb-6 text-base ">
						To prevent external access to your data, we deploy our system in
						Microsoft&#39;s Azure platform.
					</p>
					<p className="w-full mx-auto text-base md:w-7/12">
						Azure has in-built protection and controls access to our systems and data.We
						also run regular internal vulnerability tests and address any issues found.
					</p>
				</div>
			</div>

			<div className="bg-brand-900">
				<div className="flex flex-col-reverse items-center justify-center w-full max-w-6xl px-6 pt-20 pb-24 mx-auto my-auto md:pt-32 md:pb-32 lg:flex-row ">
					<div className="w-full md:w-1/3 sm:w-8/12">
						<img src={Shield} alt="GDPR icon" className="mx-auto" />
					</div>
					<div className="flex flex-col w-full lg:pl-12 md:pt-12 lg:w-2/3">
						<h2 className="pb-2 text-2xl font-bold leading-7 text-white">
							How we comply with GDPR
						</h2>
						<h4 className="pb-2 text-lg text-white ">
							As a business, we’re committed to complying with the General Data
							Protection Regulation (GDPR) for our software products.
						</h4>
						<div className="grid gap-x-6 gap md:grid-cols-3 ">
							<CheckCard tickColor="text-gold">
								<p className="pr-3 text-white">
									When you subscribe to BrightHR, we become the data processor and
									you remain the data controller. We always store data local to
									the company that are using it and use encryption to protect it.
								</p>
							</CheckCard>
							<CheckCard tickColor="text-gold">
								<p className="pr-3 text-white">
									We also use secure protocols for transporting the data and when
									asked to delete the data, we remove it permanently from the
									system.
								</p>
							</CheckCard>
							<CheckCard tickColor="text-gold">
								<p className="pr-3 text-white">
									It&#39;s highly unlikely that there will ever be a data breach,
									but if there is, we will inform you immediately. Under the ICO
									guidelines for breaches, we must inform the ICO within 72 hours
									of becoming aware of a breach.
								</p>
							</CheckCard>
						</div>
					</div>
				</div>
			</div>

			<div className="">
				<div className="max-w-6xl px-6 pt-20 pb-24 mx-auto md:pt-32 md:pb-4">
					<h2 className="w-full pb-12 text-2xl font-bold leading-7 text-center">
						Useful Links
					</h2>

					<div className="flex flex-row ">
						<div className="grid mb-12 gap-x-6 gap md:grid-cols-2 lg:grid-cols-3 ">
							<Card title="Certification	">
								<p className="pb-8">
									BrightHR is ISO27001 and accredited by the Cyber Essentials
									Plus. It is also PCI compliant and registered with the ICO.
								</p>
								<a
									href="/pdfs/ce-certificate-2023.pdf"
									target="_blank"
									className="block mt-auto font-bold text-secondary-300 hover:text-secondary-400"
								>
									See certificates
								</a>
							</Card>
							<Card title="Terms">
								<p className="pb-8">
									To learn more about how our BrightHR accounts operate, you can
									access the full terms and conditions on our website.
								</p>
								<a
									href={buildLink(locale, 'terms')}
									className="block mt-auto font-bold text-secondary-300 hover:text-secondary-400"
								>
									Read full T&amp;Cs
								</a>
							</Card>
							<Card title="Security FAQs ">
								<p className="pb-8 ">
									Got more security questions? No problem. Head to our Support Hub
									to browse our most frequently asked questions now.
								</p>
								<a
									href={buildLink(locale, 'help/faqs')}
									className="block mt-auto font-bold text-secondary-300 hover:text-secondary-400"
								>
									Go to Support Hub
								</a>
							</Card>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

const WrappedSecurity = (props) => (
	<DefaultLayout fullWidth>
		<Security {...props} />
	</DefaultLayout>
);

export default WrappedSecurity;
