import { graphql, Link } from 'gatsby';
import React, { useEffect, useState } from 'react';
import { useLocation } from '@reach/router';
import { nanoid } from 'nanoid';
import DefaultLayout from '../layouts/Default';
import Xing from '../icons/Xing.svg';
import UKDots from '../images/country-dots/ukdots.png';
import CanadaDots from '../images/country-dots/canadadots.png';
import IrelandDots from '../images/country-dots/irelanddots.png';
import AustraliaDots from '../images/country-dots/australiadots.png';
import NewZealandDots from '../images/country-dots/newzealanddots.png';
import Pill from '../components/Pill';
import SEO from '../components/SEO';
import Var from '../components/Var';
import { buildLink } from '../utils/locale';
import HrefLang from '../components/HrefLang';
import CopyBlock from '../components/CopyBlock';

const CareersPage = ({ data, pageContext }) => {
	const [jobs, setJobs] = useState(false);
	const [departments, setDepartments] = useState([]);
	const [selectedDepartment, setDepartment] = useState(null);

	useEffect(() => {
		if (data.allDatoCmsCareer.nodes[0].jobs.length > 0) {
			setJobs(true);

			setDepartments(
				Array.from(new Set(data.allDatoCmsCareer.nodes[0].jobs.map((n) => n.department))),
			);
		}
	}, [data, jobs]);

	const location = useLocation();
	const { locale } = pageContext;
	const { _allSlugLocales, slug, textAndIcon } = data.allDatoCmsCareer.nodes[0];

	return (
		<DefaultLayout fullWidth>
			<HrefLang locales={_allSlugLocales} slug={slug} />
			<SEO
				path={location.pathname}
				title="Search BrightHR Careers and Discover Your Dream Job!"
				description="Discover Your Dream Job Here! We Have a Number Of Positions Available Including Technical, Developer And Marketing Roles"
			/>
			<div className="relative overflow-hidden bg-white">
				<div className="mx-auto max-w-7xl">
					<div className="relative z-10 pb-8 bg-white sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32">
						<svg
							className="absolute inset-y-0 right-0 hidden w-48 h-full text-white translate-x-1/2 lg:block"
							fill="currentColor"
							viewBox="0 0 100 100"
							preserveAspectRatio="none"
							aria-hidden="true"
						>
							<polygon points="50,0 100,0 50,100 0,100" />
						</svg>

						<main className="px-4 pt-10 mx-auto max-w-7xl sm:pt-12 sm:px-6 md:pt-16 lg:pt-20 lg:px-8 xl:pt-28">
							<div className="sm:text-center lg:text-left">
								<h1 className="text-4xl font-bold leading-none tracking-tight text-gray-800 sm:text-5xl sm:leading-none md:text-6xl md:leading-none">
									Now is the <span className=" text-brand-500">Bright</span> time,
									come join us.
								</h1>
								<p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
									Do you have the passion to succeed? Do you have the dedication
									to push yourself? If so, we want to hear from you.
								</p>
								<div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
									<div className="rounded-md shadow">
										<a
											href="#jobs"
											className="flex items-center justify-center w-full px-8 py-3 text-base font-bold text-white border border-transparent rounded-md bg-brand-500 hover:bg-brand-400 md:py-4 md:text-lg md:px-10"
										>
											View our vacancies
										</a>
									</div>
								</div>
							</div>
						</main>
					</div>
				</div>
				<div className="hidden lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2 lg:block">
					<img
						className="object-cover object-bottom w-full h-56 sm:h-72 md:h-96 lg:w-full lg:h-full"
						src="https://www.datocms-assets.com/23428/1617790508-img5572.png?q=60&w=1080&auto=compress,format"
						alt=""
					/>
				</div>
			</div>

			<div className="bg-brand-500">
				<div className="max-w-4xl px-4 py-16 mx-auto sm:px-6 sm:pt-20 sm:pb-24 lg:max-w-7xl lg:pt-24 lg:px-8">
					<h2 className="text-4xl font-bold text-center text-white ">Why BrightHR?</h2>

					<div className="grid grid-cols-1 mt-12 gap-x-6 gap-y-12 sm:grid-cols-2 lg:mt-16 lg:grid-cols-3 lg:gap-y-16">
						<div>
							<div className="flex items-center space-x-4">
								<span className="flex items-center justify-center p-2 text-2xl font-bold bg-white rounded-md text-brand-500">
									<Xing className="w-8 h-8" />
								</span>

								<h3 className="text-lg font-bold text-white">Great team culture</h3>
							</div>
						</div>
						<div>
							<div className="flex items-center space-x-4">
								<span className="flex items-center justify-center p-2 text-2xl font-bold bg-white rounded-md text-brand-500">
									<Xing className="w-8 h-8" />
								</span>

								<h3 className="text-lg font-bold text-white">
									Generous benefits package
								</h3>
							</div>
						</div>
						<div>
							<div className="flex items-center space-x-4">
								<span className="flex items-center justify-center p-2 text-2xl font-bold bg-white rounded-md text-brand-500">
									<Xing className="w-8 h-8" />
								</span>

								<h3 className="text-lg font-bold text-white">Competitive salary</h3>
							</div>
						</div>
						<div>
							<div className="flex items-center space-x-4">
								<span className="flex items-center justify-center p-2 text-2xl font-bold bg-white rounded-md text-brand-500">
									<Xing className="w-8 h-8" />
								</span>

								<h3 className="text-lg font-bold text-white">
									Collaborative environment
								</h3>
							</div>
						</div>
						<div>
							<div className="flex items-center space-x-4">
								<span className="flex items-center justify-center p-2 text-2xl font-bold bg-white rounded-md text-brand-500">
									<Xing className="w-8 h-8" />
								</span>

								<h3 className="text-lg font-bold text-white">
									Profit share scheme
								</h3>
							</div>
						</div>
						<div>
							<div className="flex items-center space-x-4">
								<span className="flex items-center justify-center p-2 text-2xl font-bold bg-white rounded-md text-brand-500">
									<Xing className="w-8 h-8" />
								</span>

								<h3 className="text-lg font-bold text-white">
									Progression opportunities
								</h3>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div
				id="jobs"
				className="relative px-4 pt-16 pb-20 bg-gray-100 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8"
			>
				<div className="absolute inset-0">
					<div className="bg-white h-1/3 sm:h-2/3" />
				</div>
				<div className="relative mx-auto max-w-7xl">
					<div className="text-center">
						<h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
							Open vacancies
						</h2>
						{!jobs ? (
							<p>We have no roles currently available</p>
						) : (
							<p className="max-w-2xl mx-auto mt-3 text-xl text-gray-500 sm:mt-4">
								We&apos;ve got the following roles available right now
							</p>
						)}
						<div className="mt-8 space-x-4 space-y-4">
							{jobs &&
								departments.length !== 1 &&
								departments.map((d) => (
									<button
										type="button"
										className="inline-block focus:outline-none group"
										onClick={() =>
											setDepartment((currentDepartment) =>
												currentDepartment === d ? null : d,
											)
										}
										key={nanoid()}
									>
										<Pill active={selectedDepartment === d}>{d}</Pill>
									</button>
								))}
						</div>
					</div>
					<div className="grid max-w-lg gap-5 mx-auto mt-12 lg:grid-cols-3 lg:max-w-none">
						{jobs &&
							data.allDatoCmsCareer.nodes[0].jobs
								.filter((n) =>
									!selectedDepartment
										? true
										: n.department === selectedDepartment,
								)
								.map(({ department, jobTitle, originalId, cardDescription }) => (
									<div
										key={originalId}
										className="flex flex-col overflow-hidden border border-gray-100 rounded-lg shadow-lg"
									>
										<div className="flex flex-col justify-between flex-1 p-6 bg-white">
											<div className="flex-1">
												<p className="text-sm font-bold text-brand-500">
													{department}
												</p>
												<Link
													to={buildLink(locale, `careers/${originalId}`)}
													className="block mt-2"
												>
													<p className="text-xl font-bold text-gray-900">
														{jobTitle}
													</p>
													<p className="mt-3 text-base text-gray-500">
														{cardDescription || (
															<>
																We are looking for a talented and
																ambitious {jobTitle} to join the
																BrightHR family. Sound like you? Get
																in touch now.
															</>
														)}
													</p>
												</Link>
											</div>
										</div>
									</div>
								))}
					</div>
				</div>
			</div>

			<div className="relative bg-gray-900">
				<div className="max-w-4xl px-4 mx-auto sm:px-6 lg:max-w-7xl lg:px-8">
					<div className="relative pt-12 pb-24 sm:pt-24">
						<h2 className="text-sm font-bold tracking-wide uppercase text-brand-200">
							Our core values
						</h2>
						<p className="mt-3 text-3xl font-bold text-white">
							The future of HR software
						</p>
						<p className="mt-5 text-lg text-gray-300">
							We are a people management software company, with a mission and product
							to be proud of. We practice what we preach, looking after our dedicated
							employees with competitive salaries, an environment that encourages
							growth and the opportunity to stand-out.
						</p>
						<div className="grid grid-cols-1 mt-12 gap-y-12 gap-x-12 lg:grid-cols-2">
							{textAndIcon.map((text) => (
								<div className="flex h-full m-auto text-lg text-gray-300 lg:w-full lg:flex-row">
									<div className="hidden my-auto mr-8 xs:block">
										<img
											src={text.vector.url}
											className="max-w-[4rem] lg:max-w-[7rem]"
											alt=""
										/>
									</div>
									<div>
										<h2 className="pb-3 text-xl font-bold">{text.heading}</h2>
										<CopyBlock>{text.copyNode.childMdx.body}</CopyBlock>
									</div>
								</div>
							))}
						</div>
					</div>
				</div>
			</div>

			<div className="relative py-16 bg-white sm:py-24 lg:py-32">
				<div className="max-w-md px-4 mx-auto sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
					<h2 className="mt-2 text-3xl font-bold tracking-tight text-center text-gray-900 sm:text-4xl">
						Our offices
					</h2>

					<div className="mt-12">
						<h3 className="mt-8">UK &amp; Ireland offices:</h3>
						<div className="grid grid-cols-1 gap-8 my-8 md:my-12 sm:grid-cols-2 lg:grid-cols-3">
							<div className="h-full pt-6 text-center">
								<div className="flow-root h-full px-6 pb-8 bg-white rounded-lg shadow-lg">
									<div className="-mt-6">
										<div>
											<span className="inline-flex items-center justify-center p-2 rounded-md shadow-lg bg-brand-500">
												<img
													src={UKDots}
													alt="UK map"
													className="w-16 h-16"
												/>
											</span>
										</div>
										<h3 className="mt-8 text-xl font-bold tracking-tight text-gray-900">
											UK: <Var name="supportNumber" localeOveride="en" />
										</h3>
										<p className="mt-5 text-base text-gray-700">
											<span className="block mb-2 font-bold">
												BrightHR England (our registered address)
											</span>
											The Peninsula
											<br />
											Victoria Place
											<br />
											Manchester
											<br />
											M4 4FB
										</p>
									</div>
								</div>
							</div>
							<div className="h-full pt-6 text-center">
								<div className="flow-root h-full px-6 pb-8 bg-white rounded-lg shadow-lg">
									<div className="-mt-6">
										<div>
											<span className="inline-flex items-center justify-center p-2 rounded-md shadow-lg bg-brand-500">
												<img
													src={UKDots}
													alt="UK map"
													className="w-16 h-16"
												/>
											</span>
										</div>
										<h3 className="mt-8 text-xl font-bold tracking-tight text-gray-900">
											Scotland:{' '}
											<Var name="supportNumber" localeOveride="en" />
										</h3>
										<p className="mt-5 text-base text-gray-700">
											<span className="block mb-2 font-bold">
												BrightHR Scotland
											</span>
											180 West George Street
											<br />
											Glasgow
											<br />
											G2 2NR
										</p>
									</div>
								</div>
							</div>
							<div className="h-full pt-6 text-center">
								<div className="flow-root h-full px-6 pb-8 bg-white rounded-lg shadow-lg">
									<div className="-mt-6">
										<div>
											<span className="inline-flex items-center justify-center p-2 rounded-md shadow-lg bg-brand-500">
												<img
													src={IrelandDots}
													alt="UK map"
													className="w-16 h-16"
												/>
											</span>
										</div>
										<h3 className="mt-8 text-xl font-bold tracking-tight text-gray-900">
											Ireland: <Var name="supportNumber" localeOveride="ie" />
										</h3>
										<p className="mt-5 text-base text-gray-700">
											<span className="block mb-2 font-bold">
												BrightHR Ireland <br />
											</span>
											Block W<br />
											East Point Business Park
											<br />
											Alfie Bryne Rd, East Wall
											<br />
											Dublin 3<br />
											Ireland
										</p>
									</div>
								</div>
							</div>
							<div className="h-full pt-6 text-center">
								<div className="flow-root h-full px-6 pb-8 bg-white rounded-lg shadow-lg">
									<div className="-mt-6">
										<div>
											<span className="inline-flex items-center justify-center p-2 rounded-md shadow-lg bg-brand-500">
												<img
													src={UKDots}
													alt="UK map"
													className="w-16 h-16"
												/>
											</span>
										</div>
										<h3 className="mt-8 text-xl font-bold tracking-tight text-gray-900">
											Northern Ireland: 0333 258 7461
										</h3>
										<p className="mt-5 text-base text-gray-700">
											<span className="block mb-2 font-bold">
												BrightHR Northern Ireland <br />
											</span>
											Unit 5, Citylink Business Park
											<br />
											Albert Street
											<br />
											Belfast
											<br />
											BT12 4HQ
											<br />
											Northern Ireland
										</p>
									</div>
								</div>
							</div>
						</div>
						<h3>International offices:</h3>
						<div className="grid grid-cols-1 gap-8 my-8 md:my-12 sm:grid-cols-2 lg:grid-cols-3">
							<div className="h-full pt-6 text-center">
								<div className="flow-root h-full px-6 pb-8 bg-white rounded-lg shadow-lg">
									<div className="-mt-6">
										<div>
											<span className="inline-flex items-center justify-center p-2 rounded-md shadow-lg bg-brand-500">
												<img
													src={CanadaDots}
													alt="UK map"
													className="w-16 h-16"
												/>
											</span>
										</div>
										<h3 className="mt-8 text-xl font-bold tracking-tight text-gray-900">
											Canada: <Var name="supportNumber" localeOveride="ca" />
										</h3>
										<p className="mt-5 text-base text-gray-700">
											<span className="block mb-2 font-bold">
												BrightHR Canada
											</span>
											Peninsula Employment Services Limited
											<br />
											123 Front Street West, Suite 200
											<br />
											Toronto
											<br />
											ON M5J 2M2
											<br />
											Canada
										</p>
									</div>
								</div>
							</div>
							<div className="h-full pt-6 text-center">
								<div className="flow-root h-full px-6 pb-8 bg-white rounded-lg shadow-lg">
									<div className="-mt-6">
										<div>
											<span className="inline-flex items-center justify-center p-2 rounded-md shadow-lg bg-brand-500">
												<img
													src={AustraliaDots}
													alt="UK map"
													className="w-16 h-16"
												/>
											</span>
										</div>
										<h3 className="mt-8 text-xl font-bold tracking-tight text-gray-900">
											Australia:{' '}
											<Var name="supportNumber" localeOveride="au" />
										</h3>
										<p className="mt-5 text-base text-gray-700">
											<span className="block mb-2 font-bold">
												BrightHR Australia
											</span>{' '}
											Level 12,
											<br />
											700 Harris Street
											<br />
											Ultimo
											<br />
											2007
											<br />
											Australia
										</p>
									</div>
								</div>
							</div>
							<div className="h-full pt-6 text-center">
								<div className="flow-root h-full px-6 pb-8 bg-white rounded-lg shadow-lg">
									<div className="-mt-6">
										<div>
											<span className="inline-flex items-center justify-center p-2 rounded-md shadow-lg bg-brand-500">
												<img
													src={NewZealandDots}
													alt="UK map"
													className="w-16 h-16"
												/>
											</span>
										</div>
										<h3 className="mt-8 text-xl font-bold tracking-tight text-gray-900">
											New Zealand:{' '}
											<Var name="supportNumber" localeOveride="nz" />
										</h3>
										<p className="mt-5 text-base text-gray-700">
											<span className="block mb-2 font-bold">
												BrightHR New Zealand
											</span>
											1 Nelson St
											<br />
											Auckland
											<br />
											1010
											<br />
											New Zealand
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</DefaultLayout>
	);
};

export default CareersPage;

export const query = graphql`
	query Careers($locale: String!) {
		allDatoCmsCareer(filter: { locale: { eq: $locale } }) {
			nodes {
				slug
				_allSlugLocales {
					locale
					value
				}
				jobs {
					originalId
					department
					jobTitle
					location
					salary
					cardDescription
				}
				textAndIcon {
					vector {
						url
						alt
					}
					heading
					copyNode {
						childMdx {
							body
						}
					}
				}
			}
		}
	}
`;
