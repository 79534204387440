import React from 'react';

const UL = ({ children }) => (
	<ul className="pt-2 mb-4 space-y-2 list-inside ticks-pink tick-list last:mb-0">
		{React.Children.map(children, (child) =>
			React.cloneElement(child, {
				children: (
					<>
						<svg className="shrink-0 w-5 h-5 tick" viewBox="0 0 24 24">
							<title>check</title>
							<path
								d="M19.293 5.293l-10.293 10.293-4.293-4.293c-0.391-0.391-1.024-0.391-1.414 0s-0.391 1.024 0 1.414l5 5c0.391 0.391 1.024 0.391 1.414 0l11-11c0.391-0.391 0.391-1.024 0-1.414s-1.024-0.391-1.414 0z"
								fill="currentColor"
							/>
						</svg>

						<span>{child.props.children}</span>
					</>
				),
			}),
		)}
	</ul>
);

export default UL;
