import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import CopyBlock from '../CopyBlock';
import Var from '../Var';
import { useLocale } from '../../context/Locale';

const MeetOurCustomers = ({ logos, cta }) => {
	const locale = useLocale();

	return (
		<>
			{logos.length > 0 && (
				<div className="container relative px-6 mx-auto mb-12 text-center md:mb-24 xl:mb-20 xl:pt-0">
					<div className="max-w-4xl mx-auto my-8 text-center xl:mt-4">
						<h2 className="mb-12 text-xl font-bold text-center md:text-2xl xl:my-0">
							Join the <Var name="numCompanies" />+
							{locale === 'ca' ? ' Canadian' : ''} businesses that trust BrightHR
						</h2>
					</div>

					<div className="flex flex-row flex-wrap justify-center max-w-5xl gap-8 mx-auto my-12 space-evenly xl:mt-0">
						{logos.map((logo) => (
							<GatsbyImage
								image={logo.gatsbyImageData}
								alt={logo.alt}
								key={logo.url}
								objectFit="contain"
								className="h-24 w-28"
							/>
						))}
					</div>
					{cta && <CopyBlock>{cta}</CopyBlock>}
				</div>
			)}
		</>
	);
};

export default MeetOurCustomers;
