const trailingSlash = (url) => (url.substr(-1) !== '/' ? `${url}/` : url);

const config = {
	en: {
		countryCode: 'en',
		langCode: 'en',
		nameShort: 'UK',
		name: 'United Kingdom',
	},
	ie: {
		countryCode: 'ie',
		langCode: 'en-IE',
		nameShort: 'IE',
		name: 'Ireland',
	},
	au: {
		countryCode: 'au',
		langCode: 'en-AU',
		nameShort: 'AU',
		name: 'Australia',
	},
	ca: {
		countryCode: 'ca',
		langCode: 'en-CA',
		nameShort: 'CA',
		name: 'Canada',
	},
	nz: {
		countryCode: 'nz',
		langCode: 'en-NZ',
		nameShort: 'NZ',
		name: 'New Zealand',
	},
};

const SUPPORTED_LOCALES = ['en', 'en-CA', 'en-AU', 'en-IE', 'en-NZ'];
const DEFAULT_LOCALE = config.en.countryCode;
const LOCALE_PATHS = {
	en: 'en',
	'en-CA': 'ca',
	'en-AU': 'au',
	'en-NZ': 'nz',
	'en-IE': 'ie',
};
const LOCALES = Object.values(LOCALE_PATHS);

const LOCALE_REGEX = /\/(en|au|nz|ie|ca)(?:\/|$)/i;

const getLocale = (pathname) => {
	const matches = pathname.match(LOCALE_REGEX);
	const map = matches && Object.entries(LOCALE_PATHS).find(([, v]) => v === matches[1]);

	return map ? map[1] : DEFAULT_LOCALE;
};

const seen = {};

// eslint-disable-next-line max-params
const buildLink = (locale, pagePath, check, excludeTrailingSlash) => {
	const pagePathNormalised = pagePath === '/' ? '' : pagePath;

	const link =
		locale === DEFAULT_LOCALE || !locale
			? `/${pagePathNormalised}`
			: `/${LOCALE_PATHS[locale] || locale}/${pagePathNormalised}`;

	if (process.env.GATSBY_ENV !== 'production' && check && seen[link]) {
		throw new Error('Duplicate path found: ', link);
	}

	if (process.env.GATSBY_ENV !== 'production' && check) {
		seen[link] = true;
	}

	if (process.env.GATSBY_ENV !== 'production' && check) {
		// eslint-disable-next-line no-console
		console.log('Created page: ', link);
	}

	if (excludeTrailingSlash) {
		return link;
	}
	return trailingSlash(link.replace(/^\/\//, '/'));
};

const localisedRead = (candidates, locale, fallbackLocale = 'en') => {
	const normalisedLocale = config[locale]?.langCode || locale;

	const valueChecker =
		candidates.find((c) => c.locale.replace('_', '-') === normalisedLocale) ||
		candidates.find((c) => c.locale.replace('_', '-') === fallbackLocale);

	if (valueChecker) {
		return valueChecker.value;
	}
};

exports.LOCALE_PATHS = LOCALE_PATHS;
exports.LOCALE_CONFIG = config;
exports.LOCALES = LOCALES;
exports.SUPPORTED_LOCALES = SUPPORTED_LOCALES;
exports.DEFAULT_LOCALE = DEFAULT_LOCALE;
exports.buildLink = buildLink;
exports.getLocale = getLocale;
exports.localisedRead = localisedRead;
