import React from 'react';
import FormikInput from 'bright-components/dist/components/Formik/FormikInput';
import Fieldset from '../../Components/Fieldset';
import Label from '../../Components/Label';

export default ({ handleBlur, debouncedMaybeSyncSession }) => (
	<Fieldset>
		<div>
			<Label className="flex flex-row" id="customerTaxId">
				Tax ID/Number <small className="ml-2">(Optional)</small>
			</Label>
			<FormikInput
				fullWidth
				className="flex-grow-0"
				type="text"
				id="customerTaxId"
				name="customerTaxId"
				onBlur={(e) => {
					handleBlur(e);
					debouncedMaybeSyncSession();
				}}
			/>
		</div>
	</Fieldset>
);
