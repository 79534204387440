import React from 'react';

const Card = ({ title, children }) => (
	<div className="mb-8 overflow-hidden bg-white rounded-lg shadow-md ">
		<h3 className="px-6 py-4 text-xl font-bold text-white bg-brand-500">{title}</h3>
		<div className="px-6 py-4 flex flex-col">{children}</div>
	</div>
);

export default Card;
