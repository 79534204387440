import React from 'react';
import { Helmet } from 'react-helmet';
import { buildLink, DEFAULT_LOCALE, LOCALE_PATHS } from '../../utils/locale';

const config = require('../../config')();

const formatSlug = (slug) => {
	let formatted = slug;

	if (formatted.startsWith('/')) {
		formatted = formatted.slice(1);
	}

	return formatted;
};

const HrefLang = ({ locales, slug, alternateSlugs }) => {
	const altLocales = locales.map((l) => l.locale || l);

	if (altLocales.length <= 1) {
		return null;
	}

	const prefix = alternateSlugs ? alternateSlugs.pop() : '';

	const alternateVersions = [];

	if (alternateSlugs) {
		if (Array.isArray(alternateSlugs[0]) === false) {
			alternateSlugs.forEach((element) => {
				alternateVersions.push(element);
			});
		} else {
			alternateSlugs.forEach((element) => {
				alternateVersions.push(element[element.length - 1]);
			});
		}
	}

	const slugFinder = (locale) => {
		if (alternateSlugs) {
			const foundElement = alternateVersions.find((element) => element.id.includes(locale));
			if (foundElement) {
				return `${prefix}${foundElement.slug}`;
			}
		}

		return typeof slug === 'function' ? slug(locale) : slug;
	};

	const xDefaultLocale = altLocales.includes('en') ? DEFAULT_LOCALE : altLocales[0];

	return (
		<Helmet>
			{altLocales.sort().map((l) => (
				<link
					key={l}
					rel="alternate"
					hrefLang={l === 'en' ? 'en-GB' : l}
					href={`${config.HOST}${buildLink(
						LOCALE_PATHS[l] || DEFAULT_LOCALE,
						formatSlug(slugFinder(l)),
					)}`}
				/>
			))}

			<link
				rel="alternate"
				hrefLang="x-default"
				href={`${config.HOST}${buildLink(
					xDefaultLocale,
					formatSlug(slugFinder(xDefaultLocale)),
				)}`}
			/>
		</Helmet>
	);
};

export default HrefLang;
