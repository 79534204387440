import React from 'react';
import ProductBenefit from '../ImageWithCopy';
import MultiParagraphBlock from '../../MultiParagraphBlock';

const ImageWithCopyBlocks = ({ block, header }) => (
	<div className="container p-6 mx-auto">
		<h2 className="pt-24 text-3xl font-semibold text-center md:text-4xl md:font-bold">
			<MultiParagraphBlock content={header} />
		</h2>
		<div className="md:flex md:flex-wrap md:justify-center">
			{block.map((el) => (
				<React.Fragment key={el.id}>
					{el.blocktype === 'ImageWithCopy2' && (
						<ProductBenefit
							image={el.image.url}
							alt={el.image.alt}
							title={el.title}
							content={el.copyNode.childMdx.body}
						/>
					)}
				</React.Fragment>
			))}
		</div>
	</div>
);

export default ImageWithCopyBlocks;
