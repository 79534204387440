import styled from 'styled-components';

const Blockquote = styled.blockquote.attrs(() => ({
	className: 'relative p-8 px-12 mt-8 bg-brand-100 rounded-lg mb-6 last:mb-0 italic',
}))`
	&:before {
		content: '';
		font-family: 'Albert Sans';
		position: absolute;
		top: 0;
		left: 0;
		line-height: 1;
		height: 1.75rem;
		width: 2.5rem;
		margin-left: 0.75rem;
		margin-top: -0.75rem;
		background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 137 94'%3E%3Cdefs/%3E%3Cpath fill='%23A0D7F9' d='M59.3 40v53.7H.3V68.1c0-27.5 7.4-48.6 20-68.1H49c-6.4 10-12.8 25.6-14.4 40h24.7zm76.7 0v53.7H77.2V68.1C77.2 40.6 84.5 19.5 97 0h28.8c-6.4 10-12.8 25.6-14.4 40H136z'/%3E%3C/svg%3E");
		background-repeat: no-repeat;
		transform: rotate(-10deg);
	}
`;

export default Blockquote;
