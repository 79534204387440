import LinkButton from '@brighthr/component-linkbutton';
import React from 'react';
import LocaleContent from '../../LocaleContent';
import { buildLink } from '../../../utils/locale';

const LogSupport = ({ locale }) => {
	const buttonText = 'Log a support case';

	return (
		<div className="h-full p-6 border">
			<p className="mb-4 font-bold">
				Still not answered your question? Get in touch with someone on our friendly Service
				Team.
			</p>

			<LocaleContent showInLocales={['en', 'ie']}>
				<>
					<p className="mb-4">
						You can quickly raise a support case, enter a few details and we will be
						straight back in touch.
					</p>
					<LinkButton href="/log-a-support-case" text={buttonText} />
				</>
			</LocaleContent>

			<LocaleContent showInLocales={['ca', 'au', 'nz']}>
				<>
					<p className="mb-4">
						You can quickly raise a support case, enter a few details and we will be
						straight back in touch.
					</p>
					<LinkButton
						href={buildLink(locale, `contact/?Reason=Support`)}
						text={buttonText}
					/>
				</>
			</LocaleContent>
		</div>
	);
};

export default LogSupport;
