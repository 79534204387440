import React from 'react';

export const LockIcon = () => (
	<svg width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M3.32808 2.4C3.32808 1.73726 3.86534 1.2 4.52808 1.2C5.19082 1.2 5.72808 1.73726 5.72808 2.4V3.6L3.32808 3.6V2.4ZM2.12808 3.6V2.4C2.12808 1.07452 3.2026 0 4.52808 0C5.85356 0 6.92808 1.07452 6.92808 2.4V3.6H7.52813C8.19087 3.6 8.72813 4.13725 8.72813 4.8V7.8C8.72813 8.46274 8.19087 9 7.52813 9H1.52813C0.865383 9 0.328125 8.46274 0.328125 7.8V4.8C0.328125 4.13725 0.865383 3.6 1.52813 3.6H2.12808Z"
			fill="currentColor"
		/>
	</svg>
);

export const MenuIcon = () => (
	<svg width="21" height="14" viewBox="0 0 33 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M32.7059 20.4412H0V23.1667H32.7059V20.4412Z" fill="#0057AD" />
		<path d="M32.7059 10.2206H0V12.9461H32.7059V10.2206Z" fill="#0057AD" />
		<path d="M32.7059 0H0V2.72549H32.7059V0Z" fill="#0057AD" />
	</svg>
);

export const ArrowIcon = ({ className }) => (
	<svg
		width="10"
		height="12"
		viewBox="0 0 10 12"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		className={className}
	>
		<path
			d="M6.99213 2.5L6.53189 3.06763L8.48391 5.47512H0V6.27762H8.48391L6.53189 8.68511L6.99213 9.25274L9.72973 5.87691L6.99213 2.50107V2.5Z"
			fill="currentColor"
		/>
	</svg>
);

export const Chevron = ({ expanded }) => (
	<svg
		width="11"
		height="8"
		viewBox="0 0 11 8"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		className="ml-2"
	>
		{expanded ? (
			<path
				d="M5.53244 0.791681C5.67623 0.790849 5.81878 0.818411 5.9519 0.872785C6.08502 0.927158 6.2061 1.00727 6.30819 1.10854L10.6786 5.47899C10.7805 5.58086 10.8613 5.7018 10.9165 5.8349C10.9716 5.96801 11 6.11067 11 6.25474C11 6.39881 10.9716 6.54147 10.9165 6.67458C10.8613 6.80768 10.7805 6.92862 10.6786 7.03049C10.5768 7.13237 10.4558 7.21318 10.3227 7.26831C10.1896 7.32345 10.047 7.35182 9.90289 7.35182C9.75882 7.35182 9.61616 7.32345 9.48305 7.26831C9.34995 7.21318 9.22901 7.13237 9.12713 7.03049L5.53244 3.41395L1.92682 6.88846C1.82631 7.00022 1.70382 7.09004 1.56701 7.1523C1.4302 7.21457 1.28202 7.24793 1.13172 7.25031C0.981432 7.2527 0.832263 7.22404 0.69355 7.16614C0.554837 7.10824 0.429564 7.02235 0.325563 6.91382C0.221562 6.8053 0.14107 6.67649 0.0891237 6.53544C0.037178 6.39439 0.0148944 6.24414 0.0236653 6.09408C0.0324372 5.94403 0.0720757 5.79739 0.140102 5.66336C0.208129 5.52932 0.303078 5.41076 0.419016 5.31509L4.78946 1.09761C4.98927 0.904968 5.25491 0.795588 5.53244 0.791681Z"
				fill="currentColor"
			/>
		) : (
			<path
				d="M5.48938 6.8518C5.34559 6.85263 5.20304 6.82507 5.06992 6.77069C4.9368 6.71632 4.81572 6.63621 4.71363 6.53494L0.343179 2.16449C0.241305 2.06262 0.160495 1.94168 0.105361 1.80857C0.0502278 1.67547 0.0218506 1.53281 0.0218506 1.38874C0.0218506 1.24467 0.0502278 1.10201 0.105361 0.968903C0.160495 0.835799 0.241305 0.714858 0.343179 0.612984C0.445052 0.511111 0.565994 0.4303 0.699098 0.375166C0.832202 0.320033 0.974862 0.291656 1.11893 0.291656C1.263 0.291656 1.40566 0.320033 1.53877 0.375166C1.67187 0.4303 1.79281 0.511111 1.89469 0.612984L5.48938 4.22953L9.095 0.755024C9.19551 0.643261 9.318 0.553438 9.45481 0.491175C9.59162 0.428911 9.7398 0.395546 9.8901 0.393165C10.0404 0.390784 10.1896 0.419437 10.3283 0.477336C10.467 0.535235 10.5923 0.621132 10.6963 0.729655C10.8003 0.838178 10.8807 0.966993 10.9327 1.10804C10.9846 1.24909 11.0069 1.39934 10.9982 1.5494C10.9894 1.69945 10.9497 1.84609 10.8817 1.98012C10.8137 2.11416 10.7187 2.23272 10.6028 2.32838L6.23236 6.54587C6.03255 6.73851 5.76691 6.84789 5.48938 6.8518Z"
				fill="currentColor"
			/>
		)}
	</svg>
);

export const BrightLogoShort = () => (
	<svg
		width="31"
		height="33"
		viewBox="0 0 31 33"
		fill="currentColor"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path d="M24.6589 15.3591C24.3771 14.3727 23.3203 13.95 21.4885 14.0909L20.8544 14.1614C19.7271 14.2318 18.2476 14.3727 16.6976 14.5841C21.8408 11.2727 30.5067 6.83409 30.5771 6.83409L30.2953 5.70682C30.1544 5.14318 29.4499 4.93182 28.8158 5.21364C27.1249 6.05909 18.8112 10.5682 14.7953 13.175C17.6839 6.55228 20.3612 1.12727 20.3612 0.986365L19.1635 0.704547C18.5999 0.563638 17.8249 0.915911 17.543 1.55C16.5567 3.59318 14.443 8.17273 12.118 13.3864C10.7794 11.2727 9.08849 9.51137 8.10213 8.525C7.6794 8.10228 6.9044 8.24318 6.41122 8.73637L5.42485 9.72273C5.42485 9.72273 8.24304 12.2591 10.0749 15.2182C7.39758 15.5705 4.86122 15.9227 2.95894 16.3455C2.2544 16.4864 1.76122 17.1205 1.76122 17.7545L1.90213 19.0227C3.38167 18.6 5.63622 18.2477 8.17258 17.8955C5.91804 19.5864 3.59303 21.5591 1.19758 23.7432C0.633944 24.2364 0.563489 24.9409 0.915762 25.3636L1.69076 26.2091C4.36803 23.7432 6.97485 21.5591 9.51122 19.6568C7.74985 23.9545 6.19985 28.1114 5.3544 31.2818L5.28394 31.4227C5.00213 32.4091 6.05894 32.9727 6.97485 32.3386L7.11576 32.2682C7.39758 32.0568 14.9362 26.9136 19.1635 23.1091C23.5317 19.1636 25.1521 16.9091 24.6589 15.3591ZM17.6839 21.4182C15.1476 23.6727 11.2726 26.5614 8.66576 28.4636C9.79304 25.1523 11.343 21.2068 12.9635 17.3318C15.8521 17.05 18.5999 16.7682 20.5021 16.6273L21.1362 16.5568C21.6999 16.4864 22.0521 16.5568 22.193 16.5568C22.0521 16.9091 21.3476 18.1773 17.6839 21.4182Z" />
	</svg>
);

export const BrightPrideLogoShort = () => (
	<svg width="31" height="33" viewBox="0 0 43 46" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g clipPath="url(#clip0_2416_4243)">
			<path
				d="M3.21001 22.24C2.21001 22.44 1.51001 23.34 1.51001 24.24L1.71001 26.04C2.43001 25.83 3.29001 25.64 4.24001 25.45V22.02C3.88001 22.09 3.54001 22.16 3.21001 22.24Z"
				fill="#F4ACC8"
			/>
			<path
				d="M0.310013 35.04L1.41001 36.24C2.36001 35.36 3.31001 34.51 4.24001 33.69V29.61C3.07001 30.62 1.90001 31.65 0.710013 32.74C-0.0899867 33.44 -0.189987 34.44 0.310013 35.04Z"
				fill="#F4ACC8"
			/>
			<path
				d="M8.10996 11.44L6.70996 12.84C6.70996 12.84 7.43996 13.5 8.48996 14.58V11.14C8.35996 11.23 8.22996 11.32 8.10996 11.44Z"
				fill="#745527"
			/>
			<path
				d="M4.23999 25.4501C5.49999 25.2001 6.92999 24.97 8.47999 24.74V21.3C6.94999 21.53 5.50999 21.7601 4.23999 22.0201V25.4501Z"
				fill="#7CCBE0"
			/>
			<path
				d="M4.23999 33.69C5.66999 32.43 7.07999 31.25 8.47999 30.11V26.09C7.08999 27.19 5.66999 28.38 4.23999 29.61V33.69Z"
				fill="#7CCBE0"
			/>
			<path
				d="M6.51005 43.6401C6.15005 44.8901 7.31005 45.6501 8.49005 45.1601V37.4501C7.74005 39.6201 7.09005 41.6401 6.61005 43.4401L6.51005 43.6401Z"
				fill="#745527"
			/>
			<path
				d="M10.51 11.14C10.01 10.64 9.15999 10.7 8.48999 11.14V14.58C9.70999 15.85 11.34 17.69 12.73 19.74V13.48C11.89 12.53 11.11 11.73 10.51 11.14Z"
				fill="#745527"
			/>
			<path
				d="M8.47998 24.75C9.16998 24.65 9.87998 24.55 10.6 24.45C9.89998 24.98 9.18998 25.53 8.47998 26.1V30.12C9.82998 29.02 11.18 27.95 12.5 26.95C11 30.62 9.60998 34.2 8.47998 37.46V45.17C8.61998 45.11 8.75998 45.04 8.89998 44.95L9.09998 44.85C9.23998 44.74 10.67 43.76 12.72 42.31V38.42C12.23 38.78 11.75 39.13 11.3 39.46C11.73 38.18 12.21 36.85 12.72 35.47V20.72C11.26 20.91 9.82998 21.11 8.47998 21.31V24.75Z"
				fill="#745527"
			/>
			<path
				d="M16.21 18.04C15.12 16.32 13.86 14.77 12.73 13.48V19.74C12.93 20.04 13.13 20.33 13.31 20.64C13.11 20.67 12.92 20.69 12.73 20.72V35.47C13.99 32.07 15.45 28.38 16.97 24.72V16.37C16.72 16.93 16.47 17.48 16.21 18.05V18.04Z"
				fill="#0A2233"
			/>
			<path
				d="M12.72 42.3C13.95 41.42 15.42 40.37 16.96 39.23V35.24C15.49 36.37 14.04 37.45 12.72 38.42V42.31V42.3Z"
				fill="#0A2233"
			/>
			<path
				d="M21.2 7.01001C20.28 9.00001 19.25 11.27 18.13 13.75C17.75 14.6 17.36 15.47 16.96 16.36V24.71C17.11 24.35 17.25 24 17.4 23.64C17.64 23.62 17.88 23.59 18.13 23.57C19.18 23.47 20.2 23.36 21.2 23.27V16.98C20.78 17.24 20.38 17.5 20 17.74C20.4 16.81 20.8 15.91 21.2 15.02V7.01001Z"
				fill="#E6212C"
			/>
			<path
				d="M18.14 34.33C17.75 34.63 17.36 34.93 16.97 35.23V39.22C17.36 38.93 17.75 38.64 18.14 38.35C19.16 37.58 20.19 36.78 21.21 35.99V31.89C20.22 32.69 19.19 33.51 18.14 34.32V34.33Z"
				fill="#E6212C"
			/>
			<path
				d="M23.91 1.24005C23.29 2.53005 22.34 4.54005 21.21 7.01005V15.03C22.8 11.46 24.28 8.25005 25.45 5.75005V0.0500488C24.82 0.180049 24.19 0.610049 23.91 1.25005V1.24005Z"
				fill="#F39219"
			/>
			<path
				d="M21.2 23.2701C22.71 23.1201 24.14 22.9801 25.44 22.8701V19.4201C24.56 19.5101 23.65 19.6201 22.7 19.7501C23.55 19.2001 24.48 18.6301 25.44 18.0501V14.4401C23.91 15.3301 22.46 16.2001 21.2 16.9901V23.2801V23.2701Z"
				fill="#F39219"
			/>
			<path
				d="M21.2 31.89V35.99C22.7 34.81 24.15 33.63 25.44 32.51V28.24C25.03 28.62 24.59 29.02 24.1 29.45C23.23 30.23 22.24 31.06 21.2 31.9V31.89Z"
				fill="#F39219"
			/>
			<path
				d="M29.51 19.04L28.61 19.14C27.7 19.2 26.61 19.29 25.45 19.41V22.86C26.42 22.77 27.32 22.7 28.11 22.64L29.01 22.54C29.27 22.51 29.49 22.5 29.69 22.5V19.02C29.63 19.02 29.57 19.02 29.51 19.02V19.04Z"
				fill="#F1E60E"
			/>
			<path
				d="M26.2099 0.0399921C25.9699 -0.0200079 25.71 -0.0100079 25.45 0.0399921V5.73999C26.94 2.55999 27.91 0.529992 27.91 0.429992L26.2099 0.0299921V0.0399921Z"
				fill="#F1E60E"
			/>
			<path
				d="M25.4399 28.23V32.5C25.6999 32.27 25.9599 32.05 26.1999 31.83C27.5399 30.62 28.6899 29.52 29.6799 28.52V23.83C28.9799 24.73 27.7199 26.13 25.4399 28.23Z"
				fill="#F1E60E"
			/>
			<path
				d="M25.4399 14.43V18.04C26.7999 17.22 28.2399 16.38 29.6799 15.56V12C28.2599 12.8 26.8199 13.63 25.4399 14.43Z"
				fill="#F1E60E"
			/>
			<path
				d="M29.6799 19.0201V22.5001C30.0899 22.5001 30.3699 22.5401 30.4999 22.5401C30.4199 22.7401 30.2099 23.1401 29.6799 23.8301V28.5201C32.0699 26.1001 33.4399 24.2401 33.9199 22.7701V20.6301C33.4499 19.4101 32.0399 18.8701 29.6799 19.0201Z"
				fill="#1C9547"
			/>
			<path
				d="M29.6799 12V15.56C31.1199 14.74 32.5499 13.95 33.9199 13.2V9.64001C32.5899 10.37 31.1499 11.17 29.6799 12Z"
				fill="#1C9547"
			/>
			<path
				d="M33.9199 20.63V22.77C34.1599 22.04 34.1799 21.4 33.9999 20.83C33.9799 20.76 33.9399 20.7 33.9199 20.63Z"
				fill="#1C9547"
			/>
			<path
				d="M33.9199 9.63997V13.2C35.4599 12.36 36.9099 11.58 38.1599 10.92V7.33997C37.0399 7.92997 35.5799 8.71997 33.9199 9.62997V9.63997Z"
				fill="#3B4C9B"
			/>
			<path
				d="M39.9099 6.43996C39.5399 6.61996 38.9299 6.93996 38.1699 7.34996V10.93C40.6699 9.60996 42.3699 8.74996 42.4099 8.74996L42.0099 7.14996C41.8099 6.34996 40.8099 6.04996 39.9099 6.44996V6.43996Z"
				fill="#8F1C81"
			/>
		</g>
		<defs>
			<clipPath id="clip0_2416_4243">
				<rect width="42.41" height="45.31" fill="white" />
			</clipPath>
		</defs>
	</svg>
);
