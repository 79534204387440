import React, { useState } from 'react';
import { graphql } from 'gatsby';
import groupBy from 'group-by';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import { useLocation } from '@reach/router';
import bgImg from '../images/timeline/bg-skyline.jpg';
import { LOCALE_PATHS } from '../utils/locale';

import AnnouncementIcon from '../icons/Announcement.svg';
import MilestoneIcon from '../icons/Feature.svg';
import AwardIcon from '../icons/Timeline-Award.svg';
import 'react-vertical-timeline-component/style.min.css';

import DefaultLayout from '../layouts/Default';
import TimelineEntry from '../components/TimelineEntry';

import SEO from '../components/SEO';

const position = ['left', 'right'];

const Timeline = ({ data, pageContext }) => {
	const { nodes: timeline } = data.allDatoCmsTimeline;
	let currentEntryIndex = 0;
	const [active, setActive] = useState(false);
	const location = useLocation();

	const locale = LOCALE_PATHS[pageContext.locale];
	const entriesForLocale = timeline.filter((timelineEntry) => {
		if (locale === 'en' && timelineEntry.showInUnitedKingdom) return true;
		if (locale === 'ca' && timelineEntry.showInCanada) return true;
		if (locale === 'ie' && timelineEntry.showInIreland) return true;
		if (locale === 'au' && timelineEntry.showInAustralia) return true;
		if (locale === 'nz' && timelineEntry.showInNewZealand) return true;

		return false;
	});
	const groupedResults = groupBy(entriesForLocale, ({ timelineDate }) => {
		const date = new Date(timelineDate);
		return date.getFullYear();
	});
	const years = Object.keys(groupedResults).reverse();

	return (
		<>
			<SEO
				title="Business Milestones"
				path={location.pathname}
				description="Its been an amazing journey with Bright HR, and its all thanks to our customers. Check out this timeline of milestones that've made us the UK's number 1 HR company. "
			/>
			<div
				className="bg-fixed bg-cover lg:bg-contain"
				style={{
					backgroundImage: `url(${bgImg})`,
				}}
			>
				<div className="relative z-10 max-w-6xl mx-auto md:pt-6 xl:px-0 md:py-10">
					<div className="sticky z-30 flex justify-start py-2 overflow-x-scroll bg-white pointer-events-auto top-24 md:overflow-hidden md:bg-transparent md:float-right sm:justify-center md:justify-items-auto md:right-0 md:flex-col">
						{years.map((year) => (
							<a
								href={`#year-${year}`}
								onClick={() => setActive(!active)}
								className="px-6 py-1 mt-1 mr-3 font-bold whitespace-no-wrap rounded-full text-brand-500 focus:text-white hover:text-brand-500 focus:bg-brand-500 md:mb-2 md:mr-0 bg-brand-100 md:bg-white text-grey-100 md:shadow-lg "
								key={year}
							>
								{year}
							</a>
						))}
					</div>
					<div className="relative">
						<div className="flex-row w-full px-4 pt-4 mx-auto text-center md:w-11/12">
							<h1 className="text-2xl font-bold text-white md:text-4xl">
								The evolution of BrightHR
							</h1>
							<p className="mx-auto font-medium text-white md:w-4/5">
								We started BrightHR with an idea: to create simple HR software that
								makes people management easier for SMEs. And ever since, we’ve
								worked hard to improve it. Here are our greatest moments:
							</p>
						</div>

						<div className="w-full px-2 pt-6 mx-auto md:w-11/12 md:px-6 xl:px-0 timeline">
							{years.map((year) => (
								<VerticalTimeline animate={false}>
									<div className="flex justify-center ">
										<div className="z-20 inline-block px-6 py-2 mx-auto bg-white rounded-full shadow-lg">
											<div
												id={`year-${year}`}
												className="absolute -mt-24 md:-mt-10"
											/>
											<h1 className="text-2xl font-bold text-center text-brand-500">
												{year}
											</h1>
										</div>
									</div>
									{groupedResults[year].map(
										({
											timelineHeadline,
											timelineDate,
											timelineType,
											timelineContentNode,
										}) => {
											const currentPosition = position[currentEntryIndex % 2];
											currentEntryIndex++;

											let Icon;
											if (timelineType === 'Milestone') {
												Icon = <MilestoneIcon className="timeline-icon" />;
											} else if (timelineType === 'Announcement') {
												Icon = (
													<AnnouncementIcon className="timeline-icon" />
												);
											} else if (timelineType === 'Award') {
												Icon = <AwardIcon className="timeline-icon" />;
											}

											return (
												<VerticalTimelineElement
													position={currentPosition}
													className="vertical-timeline-element--work "
													icon={Icon}
													contentStyle={{
														boxshadow:
															' 0 16px 32px -3px rgba(25, 85, 128, 0.1), 0 4px 6px -2px rgba(25, 85, 128, 0.2) ',
													}}
													key={timelineHeadline}
												>
													<TimelineEntry
														timelineHeadline={timelineHeadline}
														timelineDate={timelineDate}
														timelineType={timelineType}
														timelineContentNode={timelineContentNode}
													/>
												</VerticalTimelineElement>
											);
										},
									)}
								</VerticalTimeline>
							))}
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export const query = graphql`
	query MyQuery {
		allDatoCmsTimeline(
			sort: { fields: timelineDate, order: DESC }
			filter: { locale: { eq: "en" } }
		) {
			nodes {
				...TimelineEntry
			}
		}
	}
`;

const TimelineWithLayout = (props) => (
	<DefaultLayout fullWidth>
		<Timeline {...props} />
	</DefaultLayout>
);

export default TimelineWithLayout;
