/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';
import formatPathForCanonical from '../../utils/formatPathForCanonical';
import { DEFAULT_LOCALE } from '../../utils/locale';
import SeoSchema from '../SeoSchema';

function SEO({
	description,
	lang = DEFAULT_LOCALE,
	locale,
	meta,
	title,
	path,
	titleSuffix,
	canonicalhref,
	datoSchema,
	schemaType = 'WebSite',
	autoSchema,
}) {
	const { site } = useStaticQuery(
		graphql`
			query {
				site {
					siteMetadata {
						title
						description
						author
						siteUrl
					}
				}
			}
		`,
	);

	const metaDescription = description || site.siteMetadata.description;

	let backupSchema;

	if (autoSchema === undefined) {
		const appendedPageTitle = title.endsWith(` | ${site.siteMetadata.title}`)
			? title
			: `${title} | ${site.siteMetadata.title}`;
		backupSchema = {
			name: appendedPageTitle,
			url: `${site.siteMetadata.siteUrl}${path}`,
			sameAs: [
				'https://www.facebook.com/BrightHRcom?fref=ts',
				'https://twitter.com/brighthr_UK',
				'https://www.youtube.com/channel/UCKvE4P25zlQ5TbpaZweyIcg',
				'https://www.linkedin.com/company/brighthr',
			],
		};
	}

	return (
		<>
			<Helmet
				htmlAttributes={{
					lang,
				}}
				title={title}
				titleTemplate={`%s | ${titleSuffix || site.siteMetadata.title}`}
				meta={[
					{
						name: `description`,
						content: metaDescription,
					},
					{
						name: `og:site_name`,
						content: site.siteMetadata.siteName,
					},
					{
						name: `og:locale`,
						content: locale,
					},
					{
						property: `og:title`,
						content: title,
					},
					{
						property: `og:description`,
						content: metaDescription,
					},
					{
						property: `og:type`,
						content: `website`,
					},
					{
						name: `twitter:card`,
						content: `summary`,
					},
					{
						name: `twitter:creator`,
						content: site.siteMetadata.author,
					},
					{
						name: `twitter:title`,
						content: title,
					},
					{
						name: `twitter:description`,
						content: metaDescription,
					},
				].concat(meta)}
			>
				<link
					rel="canonical"
					href={
						canonicalhref ||
						`${site.siteMetadata.siteUrl}/${formatPathForCanonical(path)}`
					}
				/>
			</Helmet>
			<SeoSchema
				datoSchema={datoSchema}
				schemaType={schemaType}
				autoSchema={autoSchema || backupSchema}
			/>
		</>
	);
}

SEO.defaultProps = {
	lang: `en`,
	meta: [],
	description: ``,
	locale: 'en_GB',
	titleSuffix: undefined,
};

SEO.propTypes = {
	description: PropTypes.string,
	titleSuffix: PropTypes.string,
	lang: PropTypes.string,
	path: PropTypes.string.isRequired,
	locale: PropTypes.string,
	// eslint-disable-next-line react/forbid-prop-types
	meta: PropTypes.arrayOf(PropTypes.object),
	title: PropTypes.string.isRequired,
};

export default SEO;
