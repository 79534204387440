import { useEffect, useState } from 'react';

const useQueryParams = () => {
	const [params, setParams] = useState({});

	useEffect(() => {
		// Check if there are query parameters before updating the state
		if (!window.location.search) {
			return;
		}
		const getQueryParams = () => {
			const search = window.location.search.substring(1); // Get the query string without the leading '?'
			const queryParams = {};
			const keyValuePairs = search.split('&');

			keyValuePairs.forEach((pair) => {
				const [key, value] = pair.split('=');
				queryParams[key] = decodeURIComponent(value.replace(/\+/g, ' '));
			});

			return queryParams;
		};

		const queryParams = getQueryParams();
		setParams(queryParams);
	}, []);

	return params;
};

export default useQueryParams;
