import Icon from '@brighthr/component-icon';
import React from 'react';

function MenuDropdown({ isMobile, setDropDown, title, isActive }) {
	return (
		<div className="flex flex-row justify-between">
			<p
				className="flex items-center font-semibold transition-colors cursor-default group shrink-0 text-primary-700 hover:text-primary-400 text-lg"
				onMouseEnter={(e) => {
					if (!isMobile) {
						e.preventDefault();
						setDropDown(title);
					}
				}}
			>
				{title}
			</p>
			{isMobile && (
				<button
					type="button"
					onClick={() => (isActive ? setDropDown('reset') : setDropDown(title))}
				>
					<Icon
						className={`mr-3 inline-block h-4 w-4 fill-primary-700 duration-200 ${
							isActive && 'rotate-180'
						}`}
						iconName="chevron-thick-down"
						size={14}
					/>
				</button>
			)}
		</div>
	);
}

export default MenuDropdown;
