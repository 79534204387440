import React, { useEffect, useState } from 'react';
import LinkButton from '@brighthr/component-linkbutton';
import CopyBlock from '../../CopyBlock';

function WhatsNew({ data }) {
	const [isMobile, setIsMobile] = useState(false);
	useEffect(() => {
		const browserWidth = window.innerWidth;

		if (browserWidth < 992) {
			setIsMobile(true);
		}
	}, []);
	return (
		<>
			{data.map((product) => {
				const { copyNode, ctaLink, ctaText, id, desktopImage, mobileImage, title } =
					product;

				return data.length > 1 ? (
					<div
						key={id}
						className="flex flex-col justify-between h-full bg-white border border-gray-300 rounded-md xl:border-none"
					>
						<div className="flex flex-col justify-between h-full p-5 small-paragraph">
							<div className="py-3">
								<div className="flex flex-row items-center pb-2 grow">
									{isMobile && mobileImage ? (
										<img
											src={mobileImage.url}
											alt={mobileImage.alt || ''}
											width={30}
											height={30}
										/>
									) : null}
									{!isMobile && desktopImage ? (
										<img
											src={desktopImage.url}
											alt={desktopImage.alt || ''}
											width={30}
											height={30}
										/>
									) : null}
									<a
										href={ctaLink}
										className="ml-3 font-bold text-primary-700 hover:text-brand-400 hover:underline xl:text-xl"
									>
										{title}
									</a>
								</div>
								<CopyBlock className="text-sm">{copyNode.childMdx.body}</CopyBlock>
							</div>
							<div className="w-full text-right">
								<a
									href={ctaLink}
									className="pt-2 text-xs font-bold text-primary-700 hover:text-brand-400 hover:underline"
								>
									{ctaText}
								</a>
							</div>
						</div>
					</div>
				) : (
					<div
						key={id}
						className="flex flex-row justify-between h-full bg-white rounded-md shadow-lg xl:flex-col xl:shadow-none"
					>
						{isMobile && mobileImage ? (
							<img
								src={mobileImage.url}
								alt={mobileImage.alt || ''}
								className="w-2/5 rounded-l-md object-cover xl:w-full xl:rounded-l-none xl:rounded-t-md"
							/>
						) : null}

						{!isMobile && desktopImage ? (
							<img
								src={desktopImage.url}
								alt={desktopImage.alt || ''}
								className="w-2/5 rounded-l-md object-cover xl:w-full xl:rounded-l-none xl:rounded-t-md"
							/>
						) : null}
						<div className="rounded-r-md xl:rounded-r-none">
							<div className="p-5 small-paragraph grow">
								<p className="pb-2 font-bold xl:text-xl">{title}</p>
								<CopyBlock className="text-sm">{copyNode.childMdx.body}</CopyBlock>
							</div>
							<div className="p-5">
								<LinkButton href={ctaLink} text={ctaText} outlineButton />
							</div>
						</div>
					</div>
				);
			})}
		</>
	);
}

export default WhatsNew;
