import React from 'react';
import cn from 'classnames';
import LazyLoad from 'react-lazyload';
import { GatsbyImage } from 'gatsby-plugin-image';
import format from 'date-fns/format';
import Play from '../../../icons/Play.svg';
import { buildLink } from '../../../utils/locale';

const WebinarCards = ({
	resources,
	className,
	cardClassName,
	imgClassName,
	locale,
	slugBase,
	showcase,
	searchInput,
}) => {
	const triggerGate = (fullSlug, e) => {
		e.preventDefault();
		window.location.href = buildLink(locale, fullSlug);
	};

	return (
		<section className={cn(`${!showcase ? `webinars-cards-grid` : ``}`, className)}>
			{resources[0] &&
				resources.map(({ id, title, thumbnail, slug, category, date, meta, youtubeId }) => {
					const fullSlug = category
						? `${slugBase}/${category.slug}/${slug}`
						: `${slugBase}/${slug}`;

					return (
						<div key={id} className={cn(cardClassName)}>
							<div
								className={`flex ${
									showcase ? 'flex-col md:flex-row' : 'flex-col'
								} w-full h-full overflow-hidden transition duration-100 border border-b-0 border-gray-400 rounded shadow hover:shadow-lg hover:-translate-y-2 hover:scale-105`}
							>
								<div className="relative">
									{thumbnail && (
										<LazyLoad once>
											<a
												href={buildLink(locale, fullSlug)}
												className={cn(
													'relative mx-auto block md:flex md:items-stretch overflow-hidden h-full',
													imgClassName,
												)}
												onClick={(e) => {
													triggerGate(fullSlug, e);
												}}
											>
												<GatsbyImage
													image={thumbnail.gatsbyImageData}
													loading="eager"
													objectFit="cover"
													alt={thumbnail.alt || title}
												/>
												<div className="absolute w-24 h-16 mx-auto -translate-x-1/2 -translate-y-1/2 cursor-pointer opacity-80 bg-secondary-300 top-1/2 left-1/2 rounded-xl">
													<Play className="w-full h-8 mt-4 text-white" />
												</div>
											</a>
										</LazyLoad>
									)}

									{!thumbnail && (
										<LazyLoad once>
											<a
												href={buildLink(locale, fullSlug)}
												className={cn(
													'relative mx-auto block md:flex md:items-stretch overflow-hidden h-full',
													imgClassName,
												)}
												onClick={(e) => {
													triggerGate(fullSlug, title, e);
												}}
											>
												<img
													src={`https://img.youtube.com/vi/${youtubeId}/maxresdefault.jpg`}
													alt={title}
												/>
												<div className="absolute w-24 h-16 -translate-x-1/2 -translate-y-1/2 cursor-pointer rounded-xl top-1/2 left-1/2 opacity-80 bg-secondary-300">
													<Play className="w-full h-8 mt-4 text-white" />
												</div>
											</a>
										</LazyLoad>
									)}
								</div>
								<div className="flex flex-col w-full h-full p-5 bg-white border-t-0 border-gray-400">
									{category && (
										<a
											href={buildLink(
												locale,
												`webinars/${category?.slug}?page=1${
													searchInput ? `&search=${searchInput}` : ''
												}`,
											)}
											className="relative mb-5 text-xs text-brand-500 hover:text-brand-400"
										>
											<strong className="truncate">{category.title}</strong>
										</a>
									)}
									<p className="mb-3 leading-5 text-gray-700 text-md">
										{format(
											new Date(date || meta.createdAt),
											'eeee, MMM dd, yyyy',
										)}
									</p>
									<a
										href={buildLink(locale, fullSlug)}
										onClick={(e) => {
											triggerGate(fullSlug, title, e);
										}}
									>
										<h3 className="mb-5 text-lg font-bold leading-7 text-gray-900">
											{title}
										</h3>
									</a>
								</div>
							</div>
						</div>
					);
				})}
		</section>
	);
};

export default WebinarCards;
