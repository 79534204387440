import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import CopyBlock from '../../CopyBlock';
import CTAs from '../../CTAs';

const HeroWithWave = ({ deviceImage, logo, title, descriptionMdx, children, page }) => (
	<section className="relative py-8 mb-16 md:py-16 hero sm:pb-40 md:pb-32 bg-brand-100">
		<div className="absolute inset-0 w-full h-full">
			<div className="absolute inset-0 w-full h-full bg-brand-400" />
			<div className="absolute inset-0 w-full h-full bg-gradient-to-b md:bg-gradient-to-r from-brand-500" />
			<div className="absolute inset-0 w-full h-full bg-gradient-to-b md:bg-gradient-to-t from-brand-500" />
		</div>

		<svg
			className="absolute bottom-0 left-0 w-full h-auto"
			width="1440"
			height="476"
			viewBox="0 0 1440 476"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M1440 0.797726V476H0V435.842C58.4568 450.732 121.918 460.5 192.6 460.5C355.675 460.5 473.554 416.438 587.627 373.798C621.883 360.993 655.796 348.316 690.486 337C809.491 298.179 903.311 277.906 992.087 258.723C1043 247.721 1092.26 237.078 1143.65 223.5C1264.61 191.541 1370.08 87.2796 1440 0.797726Z"
				fill="white"
			/>
		</svg>

		<div className="relative flex flex-col items-end max-w-screen-xl px-6 mx-auto overflow-visible flex-nowrap md:flex-row">
			<div className="w-full mb-12 space-y-6 lg:mb-0 lg:pr-20 sm:mb-0 lg:w-1/2 xl:w-6/12">
				{logo && (
					<img
						src={logo.url}
						className="object-contain object-left w-full h-full"
						style={{
							maxHeight: '6rem',
							maxWidth: '10rem',
						}}
						alt="Logo"
					/>
				)}
				<h1
					className="max-w-xl mb-6 text-3xl font-bold leading-tight tracking-tight text-white md:text-5xl"
					style={{
						hyphens: 'auto',
					}}
				>
					{title}
				</h1>
				<div className="mb-4 text-white">
					<CopyBlock>{descriptionMdx}</CopyBlock>
					<CTAs darkBackground page={page} />
				</div>
				<div className="flex items-center justify-center gap-8 md:flex-row lg:justify-start">
					{children}
				</div>

				<a href="https://app.brighthr.com" className="inline-block mt-4 text-white">
					<strong>Already a customer?</strong>{' '}
					<span className="underline">Start here</span>.
				</a>
			</div>

			<div className="relative right-0 w-full pb-10 -mb-20 sm:hidden lg:block md:w-1/2 xl:w-7/12 xl:absolute">
				<GatsbyImage
					image={deviceImage.gatsbyImageData}
					className="bottom-0 block w-full max-w-2xl ml-auto"
					loading="eager"
					style={{ position: undefined, right: '-4rem' }}
					objectFit="contain"
					alt={deviceImage.alt || 'Device Screenshot'}
				/>
			</div>
		</div>
	</section>
);

export default HeroWithWave;
