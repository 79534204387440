import React, { useState, useRef, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from '@reach/router';
import { graphql } from 'gatsby';
import cn from 'classnames';
import Headset from './icons/headset.svg';
import Learnmore from './icons/learnmore.svg';
import Coupon from './icons/coupon.svg';
import LangNotify from '../../components/LangNotify';
import HrefLang from '../../components/HrefLang';
import SEO from '../../components/SEO';
import { buildLink } from '../../utils/locale';
import { useLocale, LocaleProvider } from '../../context/Locale';
import Logo from '../../components/Logo';
import { useVar } from '../../components/Var';
import CopyBlock from '../../components/CopyBlock';
import MinimalFooter from '../../components/MinimalFooter';
import BookADemoBox from './components/BookADemoBox';
import getLeadSource from '../../utils/leadSource';
import DoubleQuote from '../../images/vectors/DoubleQuote.svg';
import TestimonialSlide from './components/TestimonialSlide';
import Optimize from '../../components/Optimize';

const BookADemoPage = ({ data, showTitle }) => {
	const location = useLocation();
	const locale = useLocale();
	const model = data.datoCmsBookADemo || data.datoCmsDemoPage;
	const salesNumber = useVar('salesNumber');
	const [showAllFields, setShowAllFields] = useState(false);
	const testimonialsContainer = useRef();
	const [testimonialsContainerWidth, setTestimonialsContainerWidth] = useState();
	const [testimonialsPosition, setTestimonialsPosition] = useState(0);
	const [startProgressBar, setStartProgressBar] = useState(true);
	const [progressBarNumber, setProgressBarNumber] = useState(1);

	const noLocalePath = () => {
		if (model.locale !== 'en') {
			return location.pathname.slice(3);
		}
		return location.pathname;
	};

	const demoPageLeadSource = data.datoCmsDemoPage?.leadSource;
	const leadSource =
		demoPageLeadSource === 'Webinar - Web Demo Request'
			? 'Webinar - Web Demo Request'
			: getLeadSource(locale);

	const WhatYouGain = () => (
		<>
			<h2 className="mb-5 text-lg font-semibold text-center lg:text-white lg:text-xl lg:text-left">
				What you&apos;ll gain from the demo
			</h2>
			<ul className="px-12 lg:px-0">
				<li className="flex flex-col items-center mb-8 lg:flex-row lg:flex-nowrap lg:mb-11">
					<Headset className="mr-5 w-14" />
					<span className="mt-4 text-center lg:text-xl lg:text-white lg:mt-0 lg:text-left">
						An understanding of our features &amp; how they benefit you
					</span>
				</li>
				<li className="flex flex-col items-center mb-8 lg:flex-row lg:flex-nowrap lg:mb-11">
					<Learnmore className="mr-5 w-14" />
					<span className="mt-4 text-center lg:text-xl lg:text-white lg:mt-0 lg:text-left">
						Information about our package options &amp; pricing
					</span>
				</li>
				<li className="flex flex-col items-center mb-8 lg:flex-row lg:flex-nowrap lg:mb-11">
					<Coupon className="mr-5 w-14" />
					<span className="mt-4 text-center lg:text-xl lg:text-white lg:mt-0 lg:text-left">
						Options to try features for free or receive seasonal offers
					</span>
				</li>
			</ul>
		</>
	);

	useEffect(() => {
		setTestimonialsContainerWidth(testimonialsContainer.current.clientWidth);
		const slidesToScroll = model.customerTestimonials?.length;
		let scrollCount = 1;

		// the interval time needs to be the same as the progress bar timing in the tailwindcss.config file
		setInterval(() => {
			if (scrollCount < slidesToScroll) {
				setTestimonialsPosition(testimonialsContainerWidth * scrollCount);
				setStartProgressBar(true);
				setProgressBarNumber(scrollCount + 1);
				scrollCount++;
			} else {
				setTestimonialsPosition(0);
				setStartProgressBar(false);
				setProgressBarNumber(0);
				scrollCount = 0;
			}
		}, 4000);
	}, [model.customerTestimonials?.length, testimonialsContainerWidth]);

	return (
		<div className="bg-white">
			<Optimize />
			<LangNotify />
			<HrefLang
				// eslint-disable-next-line no-underscore-dangle
				locales={model._allSlugLocales || model._allHeadingLocales}
				slug={noLocalePath()}
			/>
			<Helmet>
				<link
					rel="prefetch"
					href="https://app-lon04.marketo.com/js/forms2/js/forms2.min.js"
				/>
			</Helmet>
			<SEO path={location.pathname} title="Book a demo" />
			<>
				<div
					className="px-3 pb-4 m-3 rounded-3xl pt-9 lg:px-9 lg:pb-36"
					style={{
						background:
							'radial-gradient(100% 158.48% at 100% 100%, #FFB8DD 0%, #0070E0 100%, #0070E0 100%)',
					}}
				>
					<div className="flex flex-col max-w-6xl mx-auto lg:flex-row lg:flex-wrap lg:relative">
						<div className="flex flex-row items-center justify-between w-full px-5 basis-full flex-nowrap mb-14 lg:px-0">
							<a href={buildLink(locale, '/')} className="text-white">
								<Logo className="text-white" />
							</a>
							<a
								className="text-lg font-semibold text-white md:text-2xl"
								href={`tel:${salesNumber}`}
							>
								{salesNumber}
							</a>
						</div>
						<div className="lg:w-1/2 lg:basis-1/2 lg:pr-16">
							<h1 className="mb-4 text-3xl font-bold text-white">
								{model.title || model.heading}
							</h1>
							<div className="mb-10 text-xl text-white">
								<CopyBlock>
									{model.bodyNode.childMdx.body || model.copyNode.childMdx.body}
								</CopyBlock>
								{model.termsAndConditions && (
									<small>
										<CopyBlock>
											{model.termsAndConditionsNode.childMdx.body}
										</CopyBlock>
									</small>
								)}
							</div>
							<div className="hidden lg:block">
								<WhatYouGain />
							</div>
						</div>
						<div className="lg:basis-1/2 lg:w-1/2 lg:pl-16 lg:absolute lg:top-[94px] lg:right-0">
							<div className="px-5 py-10 bg-white rounded-3xl lg:px-10 lg:shadow-xl">
								<BookADemoBox
									ctaText={model.ctaText}
									leadSource={leadSource}
									locale={locale}
									showAllFields={showAllFields}
									setShowAllFields={setShowAllFields}
									showTitle={showTitle}
									subtitle={model.formSubtitle}
									title={model.formTitle}
								/>
							</div>
						</div>
					</div>
				</div>
				<div className="mx-3 mt-6 mb-20 lg:hidden">
					<WhatYouGain />
				</div>
				{model.customerTestimonials && (
					<div className="xl:mx-auto lg:max-w-6xl lg:ml-12">
						<div className="lg:w-1/2 lg:pr-16 lg:-mt-24">
							<div
								ref={testimonialsContainer}
								className="px-10 mb-4 overflow-hidden bg-white lg:pt-8 lg:pb-10 lg:shadow-xl lg:rounded-3xl"
							>
								<img
									src={DoubleQuote}
									type="image/svg+xml"
									alt="Quote"
									width="44"
									className="mb-7"
								/>

								<div
									className="flex flex-row w-[9999px] flex-nowrap transition-all duration-500"
									style={{ marginLeft: `-${testimonialsPosition}px` }}
								>
									{model.customerTestimonials.map((testimonial) => (
										<TestimonialSlide
											testimonial={testimonial}
											width={testimonialsContainerWidth}
											key={testimonial.id}
										/>
									))}
								</div>
								<div className="grid w-2/3 grid-cols-3 gap-3 mt-5">
									{model.customerTestimonials.map((bar, index) => (
										<div className="w-full bg-gray-300" key={bar.id}>
											<div
												className={cn(`h-1 bg-brand-500`, {
													'w-0': !startProgressBar,
													'animate-carouselProgressBar': startProgressBar,
												})}
												style={{
													animationPlayState:
														startProgressBar &&
														index === progressBarNumber - 1
															? 'running'
															: 'paused',
												}}
											/>
										</div>
									))}
								</div>
							</div>
						</div>
					</div>
				)}

				<MinimalFooter
					locale={locale}
					additionalClasses="!text-left px-7 xl:px-0 !max-w-6xl"
				/>
			</>
		</div>
	);
};

const BookADemoPageContainer = (props) => (
	<LocaleProvider>
		<BookADemoPage {...props} />
	</LocaleProvider>
);

export default BookADemoPageContainer;

// Hardcoding to just query en for now
// Change to $locale variable when A/B test is won
// Dont forget second query below
// $locale: String!
export const query = graphql`
	query bookADemoPages($id: String) {
		datoCmsBookADemo(locale: { eq: "en" }) {
			id
			locale
			_allHeadingLocales {
				locale
			}
			seoMetaTags {
				...GatsbyDatoCmsSeoMetaTags
			}
			title
			bodyNode {
				childMdx {
					body
				}
			}
			formTitle
			formSubtitle
			ctaText
			trustBadges {
				url
				alt
			}
			badge {
				... on DatoCmsBadgefrom {
					id
					price
					description
					internal {
						type
					}
				}
				... on DatoCmsBadgeoffer {
					id
					internal {
						type
					}
					offer
					description
				}
			}
			customerTestimonials {
				title
				body
				bodyNode {
					childMdx {
						body
					}
				}
				customerName
				customerJobTitle
				id
			}
		}
		datoCmsDemoPage(locale: { eq: "en" }, id: { eq: $id }) {
			id
			locale
			_allSlugLocales {
				locale
			}
			seoMetaTags {
				...GatsbyDatoCmsSeoMetaTags
			}
			copyNode {
				childMdx {
					body
				}
			}
			termsAndConditionsNode {
				childMdx {
					body
				}
			}
			termsAndConditions
			heading
			ctaText
			formImageDesktop {
				gatsbyImageData(layout: CONSTRAINED, width: 370, imgixParams: { auto: "compress" })
				url
				alt
			}
			formImageMobile {
				gatsbyImageData(layout: CONSTRAINED, width: 716, imgixParams: { auto: "compress" })
				url
				alt
			}
			trustBadges {
				url
				alt
			}
			badge {
				... on DatoCmsBadgefrom {
					id
					price
					description
					internal {
						type
					}
				}
				... on DatoCmsBadgeoffer {
					id
					internal {
						type
					}
					offer
					description
				}
			}
			leadSource
		}
	}
`;
