const fetchPromotionCode = async (code) => {
	const retrievePromotionCode = await fetch('/api/stripe/promotion-codes', {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			code,
		}),
	}).then((res) => res.json());

	return retrievePromotionCode;
};

export default fetchPromotionCode;
