import { useState, useEffect } from 'react';
import hasAuthCookie from '../utils/hasAuthCookies';

const useExistingUserTimout = ({ locale }) => {
	const [isCookieReady, setIsCookieReady] = useState(false);
	const [existingUser, setExistingUser] = useState(false);

	useEffect(() => {
		if (hasAuthCookie()) {
			setExistingUser(true);
		}
	}, [locale]);

	useEffect(() => {
		const awaitCookie = setInterval(() => {
			setIsCookieReady(true);
		}, 1000);

		return () => clearInterval(awaitCookie);
	}, []);

	return { isCookieReady, existingUser };
};

export default useExistingUserTimout;
