// https://developers.marketo.com/blog/make-a-marketo-form-submission-in-the-background/

import Cookies from 'js-cookie';
import AES from 'crypto-js/aes';
import Base64 from 'crypto-js/enc-base64';
import Utf8 from 'crypto-js/enc-utf8';

const UTMMap = {
	msclkid: 'MSCLKID__c',
	gclid: 'GCLID__c',
	utm_campaign: 'utmcampaign',
	utm_campaign_last: 'UTM_Campaign_LC__c',
	utm_campaign_first: 'UTM_Campaign_FT__c',
	utm_content: 'utmcontent',
	utm_content_last: 'UTM_Content_LC__c',
	utm_content_first: 'UTM_Content_FT__c',
	utm_medium: 'utmmedium',
	utm_medium_last: 'UTM_Medium_LC__c',
	utm_medium_first: 'UTM_Medium_FT__c',
	utm_source: 'utmsource',
	utm_source_last: 'UTM_Source_LC__c',
	utm_source_first: 'UTM_Source_FT__c',
	utm_term: 'utmterm',
	utm_term_last: 'UTM_Term_LC__c',
	utm_term_first: 'UTM_Term_FT__c',
};

const getUTMValues = (cookies) =>
	Object.keys(UTMMap).reduce((utms, utmKey) => {
		const newUtms = utms;
		if (cookies[utmKey] === undefined) {
			const switchFirstAndOriginal = utmKey.replace('_first', '');
			newUtms[UTMMap[utmKey]] = cookies[switchFirstAndOriginal];
		} else {
			newUtms[UTMMap[utmKey]] = cookies[utmKey];
		}

		return newUtms;
	}, {});

const submitMarketoForm = (data) => {
	const utmValues = getUTMValues(Cookies.get());
	const sk = 'bad2dsGtq0ixmcT5qfjFOH';
	const regex = /ca(?![a-zA-Z])|au(?![a-zA-Z])|nz(?![a-zA-Z])|ireland(?![a-zA-Z])/g;
	const url = window.location.pathname;
	const locale = url.match(regex);

	const webFormIds = {
		au: 'Book_a_Demo_Australia',
		nz: 'Book_a_Demo_NewZealand',
		ca: 'Book_a_Demo_Canada',
		ireland: 'Book_a_Demo_ROI',
	};

	const LeadSourceIds = {
		au: 'AU - Web Demo Request',
		ca: 'CA - Web Demo Request',
		ireland: 'IE - Web Demo Request',
	};

	const localeURL = {
		au: 'au/',
		nz: 'nz/',
		ca: 'ca/',
		ireland: 'ie/',
	};

	const webFormId = webFormIds[locale];
	const LeadSourceId = LeadSourceIds[locale];
	const localePrefix = localeURL[locale];

	return new Promise((res, rej) => {
		if (!window.MktoForms2) {
			rej(new Error('Marketo forms is not loaded'));
		}

		const errorTimer = setTimeout(() => rej(), 5000);

		const form = window.MktoForms2.allForms()[0];
		form.addHiddenFields({
			LeadSource: LeadSourceId || 'Web Demo Request',
			web_form_id: webFormId || 'Book_a_Demo_UK',
			...data,
			...utmValues,
		});

		// Prevent Marketo redirect
		form.onSuccess(() => {
			clearTimeout(errorTimer);
			res();

			const encDemoEmail = AES.encrypt(data.Email, sk).toString();
			const baseDemoEmail = Base64.stringify(Utf8.parse(encDemoEmail));
			const baseDemoSource = Base64.stringify(Utf8.parse(LeadSourceId || 'Web Demo Request'));
			window.location.href = `https://www.brighthr.com/${
				localePrefix || ''
			}demo-survey?email=${baseDemoEmail}&leadsource=${baseDemoSource}`;

			return false;
		});

		form.submit();
	});
};

export default submitMarketoForm;
