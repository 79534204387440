import React from 'react';
import Spinner from '@brighthr/component-spinner';
import ButtonLink from '../ButtonLink';
import { useLocale } from '../../context/Locale';
import { buildLink } from '../../utils/locale';
import LocaleContent from '../LocaleContent';
import useExistingUserTimout from '../../hooks/useExistingUserTimout';

const CTAs = ({ darkBackground = false, page }) => {
	const locale = useLocale();
	const { isCookieReady, existingUser } = useExistingUserTimout({ locale });

	return (
		<div className="flex items-center h-[107px] lg:h-[73px]">
			{isCookieReady ? (
				<div className="flex flex-col w-full gap-4 mb-4 lg:w-fit md:flex-row">
					{existingUser ? (
						<ButtonLink
							href={
								page?.includes('brightsafe')
									? 'https://app.brightsafe.com'
									: buildLink(locale, '/contact?Reason=Support')
							}
						>
							{page?.includes('brightsafe')
								? 'Login to BrightSafe'
								: 'Contact support'}
						</ButtonLink>
					) : (
						<>
							<ButtonLink href={buildLink(locale, 'book-a-demo')}>
								Start my free demo
							</ButtonLink>
							<LocaleContent showInLocales={['en', 'ca', 'ie', 'au']}>
								{darkBackground ? (
									<ButtonLink
										color="white"
										outline
										href={buildLink(locale, 'request-quote')}
										id="get-a-price-online"
										includeUtmData
									>
										{locale === 'en' ? 'Get a price online' : 'Request pricing'}
									</ButtonLink>
								) : (
									<ButtonLink
										outline
										href={buildLink(locale, 'request-quote')}
										id="get-a-price-online"
										includeUtmData
									>
										{locale === 'en' ? 'Get a price online' : 'Request pricing'}
									</ButtonLink>
								)}
							</LocaleContent>
						</>
					)}
				</div>
			) : (
				<div className="flex items-center ml-auto mr-auto md:ml-20 md:mr-0">
					<Spinner
						ariaLabel="processing request"
						className="stroke-primary-700"
						size="xs"
					/>
				</div>
			)}
		</div>
	);
};

export default CTAs;
