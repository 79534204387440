import React from 'react';
import cn from 'classnames';

const DefaultIcon = ({ className }) => (
	<svg className={className} viewBox="0 0 24 24">
		<title>check</title>
		<path
			fill="currentColor"
			d="M19.293 5.293l-10.293 10.293-4.293-4.293c-0.391-0.391-1.024-0.391-1.414 0s-0.391 1.024 0 1.414l5 5c0.391 0.391 1.024 0.391 1.414 0l11-11c0.391-0.391 0.391-1.024 0-1.414s-1.024-0.391-1.414 0z"
		/>
	</svg>
);

const ThickIcon = ({ className }) => (
	<svg className={className} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M7.5587 18.3337C7.04262 18.3337 6.55383 18.092 6.24062 17.6753L2.00166 12.0545C1.8706 11.8809 1.77492 11.6831 1.72009 11.4724C1.66526 11.2617 1.65235 11.0422 1.68209 10.8265C1.71184 10.6107 1.78367 10.403 1.89347 10.2152C2.00326 10.0273 2.14888 9.86301 2.32198 9.73167C2.49503 9.59978 2.69231 9.50346 2.90252 9.44822C3.11272 9.39299 3.33173 9.37993 3.54698 9.40979C3.76222 9.43965 3.96948 9.51185 4.15687 9.62225C4.34426 9.73265 4.5081 9.87907 4.639 10.0531L7.4282 13.7487L14.4409 2.44765C14.6735 2.07452 15.0441 1.8092 15.4713 1.70987C15.8986 1.61055 16.3476 1.68534 16.72 1.91784C17.4947 2.40122 17.7332 3.42513 17.2491 4.20377L8.96339 17.5503C8.82194 17.7791 8.62698 17.9699 8.39543 18.106C8.16387 18.2421 7.90272 18.3194 7.63463 18.3313C7.60853 18.3337 7.5848 18.3337 7.5587 18.3337V18.3337Z"
			fill="currentColor"
		/>
	</svg>
);

const IconList = ({
	tick,
	className = 'space-y-4',
	iconClassName = 'text-secondary-300 w-8 h-4',
	items,
}) => {
	const Icon = tick === 'thick' ? ThickIcon : DefaultIcon;
	const iconClass = tick === 'thick' ? 'text-secondary-300 w-6 h-6' : iconClassName;

	return (
		<ul className={cn('tick-list', className)}>
			{items.map((item) => (
				<li key={item}>
					<Icon className={`tick ${iconClass}`} />
					{item}
				</li>
			))}
		</ul>
	);
};

export default IconList;
