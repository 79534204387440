import React from 'react';

import Tick from '../../icons/Tick.svg';

const CheckCard = ({ children, tickColor }) => (
	<div className="flex flex-col mb-8 mt-4">
		<Tick className={tickColor} style={{ width: '25px', marginRight: '4px' }} />
		<div className="mt-2">{children}</div>
	</div>
);

export default CheckCard;
