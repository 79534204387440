import React from 'react';

const Section = ({ name, links, className }) => (
	<div data-testid={name} className={`pr-1 mb-10 lg:mb-0 ${className}`}>
		<h3 className="mb-2 font-bold lg:text-xl">{name}</h3>
		<ul>
			{links.map((link) => (
				<li className="mb-3" key={link.id}>
					<a className="text-brand-500 hover:text-brand-200" href={link.href}>
						{link.text}
					</a>
				</li>
			))}
		</ul>
	</div>
);

export default Section;
