import React, { useState } from 'react';
import cn from 'classnames';

import { Modal, ModalBody } from '@brighthr/component-modal';

import Info from '../../icons/Info.svg';

const InfoModal = ({ title, img, children, className }) => {
	const [open, setOpen] = useState(false);

	return (
		<>
			<button
				type="button"
				onClick={() => setOpen(true)}
				className={cn('text-brand-300', className)}
				aria-label="More info"
			>
				<Info className="w-6 h-6" />
			</button>

			{open && (
				<>
					<Modal close={() => setOpen(false)} title={title} width="sm">
						<ModalBody>
							<div className="p-2">
								<div className="mb-4 overflow-hidden rounded shadow">
									<img alt={title} src={img.default} className="w-full h-auto" />
								</div>

								{children}
							</div>
						</ModalBody>
					</Modal>
				</>
			)}
		</>
	);
};

export default InfoModal;
