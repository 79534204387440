import React, { useEffect, useState } from 'react';
import loadScript from 'load-script2';
import Button from '@brighthr/component-button';
import AES from 'crypto-js/aes';
import { useFormik } from 'formik';
import cn from 'classnames';
import { Modal, ModalBody, ModalFooter } from '@brighthr/component-modal';
import Icon from '@brighthr/component-icon';
import { useCalendlyEventListener, InlineWidget } from 'react-calendly';
import { useVar } from '../../../../components/Var';
import { buildLink } from '../../../../utils/locale';
import submitMarketoForm from '../../../../components/Marketo/submitMarketoForm';
import getLeadSource from '../../../../utils/leadSource';
import FormFooterLinks from '../../../../components/Marketo/FormFooterLinks';
import LocaleContent from '../../../../components/LocaleContent';
import ReviewBadges from '../../../../components/ReviewBadges';
import CallUs from '../../../../icons/CallUs.svg';
import CalendarBlue from '../../../../icons/CalendarBlue.svg';
import CalendlyLogo from '../../../../icons/CalendlyLogo.svg';

const BookADemoBox = ({
	ctaText = 'Start my free demo',
	leadSource,
	locale,
	pageContext = false,
	secondCtaText,
	secondCtaHref,
	showMessageField = false,
	showAllFields,
	setShowAllFields,
	showTitle = false,
	subtitle,
	title,
}) => {
	const webFormIds = {
		au: 'Book_a_Demo_Australia',
		nz: 'Book_a_Demo_NewZealand',
		ca: 'Book_a_Demo_Canada',
		ie: 'Book_a_Demo_ROI',
		en: 'Book_a_Demo_UK',
	};
	const supportNumber = useVar('supportNumber');
	const salesNumber = useVar('salesNumber', locale || 'en');
	const sk = 'bad2dsGtq0ixmcT5qfjFOH';
	const webFormId = webFormIds[locale];
	const LeadSource = leadSource || getLeadSource(locale);
	// const [showAllFields, setShowAllFields] = useState(false);
	const [openCalendly, setOpenCalendly] = useState(false);
	const [showCalendly, setShowCalendly] = useState(false);
	const [calendlyEventScheduled, setCalendlyEventScheduled] = useState(false);

	useEffect(() => {
		// Preloads the marketo form for background submission!
		const loadForm = () => {
			loadScript('//app-lon04.marketo.com/js/forms2/js/forms2.min.js')
				.then(() => {
					window.MktoForms2.loadForm('//app-lon04.marketo.com', '217-MIC-854', 2796);
				})
				.catch(() => {});
		};

		if (window.requestIdleCallback) {
			window.requestIdleCallback(loadForm);
		} else {
			setTimeout(loadForm);
		}
		// setStatus('dave');
	}, []);

	useCalendlyEventListener({
		onEventScheduled: () => setCalendlyEventScheduled(true),
	});

	const { handleChange, handleSubmit, setStatus, values, isSubmitting, status } = useFormik({
		enableReinitialize: true,
		initialValues: {
			FirstName: '',
			LastName: '',
			Title: '',
			Company: '',
			NumberOfEmployees: '',
			Phone: '',
			Email: '',
			LeadSource,
			web_form_id: webFormId,
			Referrer_Name__c: '',
			Contact_Us_Comments__c: '',
		},
		onSubmit: async (data) => {
			submitMarketoForm(data)
				.then(() => {
					setStatus('submitted');
					setOpenCalendly(true);
					window.dataLayer.push({ event: 'Book_My_Demo_Success', pageVariant: 'b' });
					window.dataLayer.push({
						event: 'GA4 - Demo form page - Successful Submit',
						pageVariant: 'b',
					});

					if (!pageContext) {
						const encDemoEmail = AES.encrypt(values.Email, sk).toString();
						window.sessionStorage.setItem('bade', encDemoEmail);
						window.sessionStorage.setItem('bads', values.LeadSource);
						// window.location.href = buildLink(locale, `demo-survey/`);
						// window.location.href = `https://pages.brighthr.com/demo-survey.html?name=${values.FirstName}&email=${values.Email}`;
					}
				})
				.catch(() => {
					setStatus('error');
				});
		},
	});

	return (
		<>
			{status === 'error' && (
				<p className="text-red-400">
					Sorry we have been unable to book your demo, please call us on{' '}
					<a className="font-bold whitespace-nowrap" href={`tel:${supportNumber}`}>
						{supportNumber}
					</a>
				</p>
			)}

			{status === 'submitted' && openCalendly === true && (
				<Modal
					width={showCalendly ? 'lg' : 'sm'}
					className="justify-top"
					headerSize="lg"
					close={() => setOpenCalendly(false)}
				>
					<ModalBody>
						<button
							type="button"
							className="absolute cursor-pointer top-4 right-7"
							onClick={() => setOpenCalendly(false)}
						>
							<Icon iconName="cross-thin" size="sm" />
						</button>
						{!showCalendly && (
							<>
								<h2 className="text-xl font-semibold">Thanks {values.FirstName}</h2>
								<p className="mb-10">How would you like to connect with us</p>
								<div className="p-5 bg-gradient-to-b from-gray-200 to-white">
									<LocaleContent showInLocales={['en']}>
										<div className="flex flex-col pb-5 border-b border-gray-300 md:flex-row md:justify-between md:flex-nowrap">
											<div className="mb-3 md:mb-0">
												<p>
													<strong>Schedule a call...</strong>
													<br /> Book a time that suits you
												</p>
											</div>
											<button
												type="button"
												onClick={() => setShowCalendly(true)}
												className="flex w-64 bg-white flex-row justify-center px-3 py-3 font-bold border-2 rounded-md flex-nowrap border-brand-500 text-brand-500 md:h-30%"
											>
												<CalendarBlue className="w-6 h-6 mr-3" />
												<span className="text-sm leading-6 md:text-base">
													Book your slot now
												</span>
											</button>
										</div>
									</LocaleContent>
									<div className="flex flex-col pt-5 md:flex-row md:flex-nowrap md:justify-between">
										<div className="mb-3 md:mb-0">
											<p>
												<strong>Can&rsquo;t wait?</strong>
												<br /> Speak to us right away
											</p>
										</div>
										<a
											href={`tel:${salesNumber}`}
											className="flex w-64 bg-white flex-row justify-center px-3 py-3 font-bold border-2 rounded-md flex-nowrap border-brand-500 text-brand-500 md:h-30%"
										>
											<CallUs className="w-6 h-6 mr-3" />
											<span className="text-sm leading-6 md:text-base">
												Call us {salesNumber}
											</span>
										</a>
									</div>
								</div>
							</>
						)}
						{showCalendly && (
							<InlineWidget
								hideGdprBanner
								styles={{ minWidth: '240px', height: '66vh' }}
								url="https://calendly.com/brighthr-demo-request/free-brighthr-demo"
								prefill={{
									name: `${values.FirstName} ${values.LastName}`,
									firstName: values.FirstName,
									lastName: values.LastName,
									email: values.Email,
									phoneNumber: values.Phone,
								}}
								pageSettings={{
									backgroundColor: 'ffffff',
									hideEventTypeDetails: false,
									hideLandingPageDetails: false,
									primaryColor: '0057AD',
									textColor: '0a2233',
									hideGdprBanner: true,
								}}
							/>
						)}
					</ModalBody>
					<ModalFooter>
						<div className="flex flex-row items-center justify-between w-full flex-nowrap">
							<CalendlyLogo className="mr-4" />
							<Button
								text={calendlyEventScheduled ? 'Next' : 'Skip'}
								// eslint-disable-next-line no-return-assign
								onClick={() =>
									(window.location.href = buildLink(locale, `demo-survey-b`))
								}
							/>
						</div>
					</ModalFooter>
				</Modal>
			)}

			{(!status || status === 'submitted') && (
				<form className="flex flex-col max-w-full text-black" onSubmit={handleSubmit}>
					{/* I want to change the name of these props to formTitle and formSubTitle
					When we optimise the pop-up BookADemo forms on Article, Blogs, and Pricing pages,
					and inline BookADemo forms on Partner pages we can do this */}
					{title && (
						<h4 className="text-2xl font-bold text-center mb-7 lg:mb-3 lg:text-3xl">
							{title}
						</h4>
					)}
					{subtitle && (
						<p className="hidden mb-8 text-lg text-center lg:block">{subtitle}</p>
					)}

					<div
						className={cn('space-y-3', {
							'mb-8': showAllFields,
							'mb-4': !showAllFields,
						})}
					>
						<label htmlFor="Email" className="block">
							<div className="mb-2 text-base font-semibold">Business email</div>
							<input
								name="Email"
								id="Email"
								className="w-full px-4 py-2 text-gray-800 border-2 border-gray-400 rounded"
								type="email"
								placeholder="john.smith@acmeinc.com"
								required
								value={values.Email}
								onChange={(e) => {
									setShowAllFields(true);
									handleChange(e);
								}}
							/>
						</label>

						<div
							className={cn(
								'space-y-3 overflow-hidden max-h-0 h-0 transition-all ease-out duration-1000',
								{
									'h-full max-h-[1000px]': showAllFields,
								},
							)}
						>
							<label htmlFor="FirstName" className="block">
								<div className="mb-2 text-base font-semibold">First name</div>
								<input
									name="FirstName"
									id="FirstName"
									className="w-full px-4 py-2 text-gray-800 border-2 border-gray-400 rounded"
									type="text"
									placeholder="John"
									required
									value={values.FirstName}
									onChange={handleChange}
								/>
							</label>
							<label htmlFor="LastName" className="block">
								<div className="mb-2 text-base font-semibold">Last name</div>
								<input
									name="LastName"
									id="LastName"
									className="w-full px-4 py-2 text-gray-800 border-2 border-gray-400 rounded"
									type="text"
									placeholder="Smith"
									required
									value={values.LastName}
									onChange={handleChange}
								/>
							</label>
							{locale === 'ie' && showTitle && (
								<label htmlFor="Title" className="block">
									<div className="mb-2 text-base font-semibold">Job title</div>
									<input
										name="Title"
										id="Title"
										className="w-full px-4 py-2 text-gray-800 border-2 border-gray-400 rounded"
										type="text"
										placeholder="Director"
										value={values.Title}
										onChange={handleChange}
									/>
								</label>
							)}
							<label htmlFor="Company" className="block">
								<div className="mb-2 text-base font-semibold">Company name</div>
								<input
									name="Company"
									id="Company"
									className="w-full px-4 py-2 text-gray-800 border-2 border-gray-400 rounded"
									type="text"
									placeholder="ACME Inc."
									required
									value={values.Company}
									onChange={handleChange}
								/>
							</label>
							<label htmlFor="Phone" className="block">
								<div className="mb-2 text-base font-semibold">Phone number</div>
								<input
									name="Phone"
									id="Phone"
									className="w-full px-4 py-2 text-gray-800 border-2 border-gray-400 rounded"
									type="tel"
									placeholder="01632 960410"
									required
									value={values.Phone}
									pattern="[0-9\s]{7,16}"
									title="Enter a valid telephone number between 7 and 16 digits."
									onChange={handleChange}
								/>
							</label>

							{showMessageField && (
								<label htmlFor="Contact_Us_Comments__c" className="block">
									<div className="mb-2 text-base font-semibold">Your Message</div>
									<textarea
										name="Contact_Us_Comments__c"
										id="Contact_Us_Comments__c"
										onChange={handleChange}
										value={values.Contact_Us_Comments__c}
										placeholder="Your message..."
										className="w-full px-4 py-2 text-gray-800 border-2 border-gray-400 rounded"
									/>
								</label>
							)}
							{(locale === 'ie' || locale === 'en-IE') &&
								pageContext === 'partner' && (
									<label htmlFor="Referrer_Name__c" className="block">
										<div className="mb-2 text-base font-semibold">
											Chambers referrer
										</div>
										<input
											name="Referrer_Name__c"
											id="Referrer_Name__c"
											className="w-full px-4 py-2 text-gray-800 border-2 border-gray-400 rounded"
											type="text"
											placeholder="John Smith"
											required
											value={values.Referrer_Name__c}
											onChange={handleChange}
										/>
									</label>
								)}
						</div>
					</div>

					<div className="flex flex-wrap items-center ">
						<div className="flex flex-col w-full">
							<Button
								type="submit"
								disabled={isSubmitting}
								text={ctaText}
								size="lg"
							/>
						</div>

						{secondCtaText && (
							<a
								href={secondCtaHref}
								className="block w-full mt-6 font-bold text-center text-brand-500 hover:underline"
							>
								{secondCtaText}
							</a>
						)}
					</div>
					<FormFooterLinks
						locale={locale}
						className="mt-1 text-sm text-center text-gray-900"
						textLinkColor="text-brand-500"
					/>
					<div className="flex flex-row flex-wrap items-center justify-center mt-6 lg:space-between">
						<LocaleContent showInLocales={['en', 'ie']}>
							<ReviewBadges
								locale={locale}
								pageName="book a demo"
								classes="w-32 h-auto mb-2 max-h-32 shrink-0"
								g2AwardClasses="ml-4 shrink-0 w-24 lg:w-28"
							/>
						</LocaleContent>
						<LocaleContent showInLocales={['ca', 'au', 'nz']}>
							<ReviewBadges
								locale={locale}
								pageName="book a demo"
								width={locale === 'ca' ? '110' : '170'}
								classes="mx-3 mb-2 shrink-0"
								g2AwardClasses="ml-2 w-24 lg:w-28"
							/>
						</LocaleContent>
					</div>
				</form>
			)}
		</>
	);
};

export default BookADemoBox;
