import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import LazyLoad from 'react-lazyload';
import cn from 'classnames';
import CopyBlock from '../../CopyBlock';
import Container from '../../Container';

const ImageWithCopy = ({ imgSrc, imagePosition, alt, text, bgColor }) => (
	<section
		className={cn(`last:mb-0`, {
			'bg-white text-black': bgColor === 'White' || '',
			'text-white bg-gradient-to-r from-brand-500 to-brand-400': bgColor === 'Blue',
		})}
	>
		<Container className="flex flex-wrap items-start">
			<div
				className={cn('w-full pt-1 mb-12 lg:mb-0 lg:w-1/2', {
					'lg:order-last lg:pl-20': imagePosition === 'Right',
					'lg:pr-20': imagePosition === 'Left',
				})}
			>
				<LazyLoad once>
					<GatsbyImage
						image={imgSrc}
						className="block max-w-lg mx-auto"
						height={imgSrc.height}
						width={imgSrc.width}
						alt={alt || ''}
					/>
				</LazyLoad>
			</div>
			<div className="w-full lg:pt-8 lg:w-1/2">
				<CopyBlock>{text}</CopyBlock>
			</div>
		</Container>
	</section>
);

export default ImageWithCopy;
