import React from 'react';
import cn from 'classnames';
import LinkButton from '@brighthr/component-linkbutton';
import InfoModal from '../InfoModal';

const { nanoid } = require('nanoid');

const PricingCard = ({
	packageTitle,
	mostPopular,
	link,
	children,
	className,
	buyOnline,
	demoLink,
	packageName,
	setShowModal,
}) => (
	<div
		className={cn(
			'relative p-6 mr-4 last:mr-0 rounded mb-8 lg:mb-0 flex flex-col w-full shadow-lg bg-white',
			{ 'pt-12 md:pt-6': mostPopular },
			className,
		)}
	>
		<div className="mb-4 md:flex md:items-center">
			<h3 className="text-2xl font-bold leading-none text-secondary-300">{packageTitle}</h3>
			{mostPopular && (
				<div className="absolute top-0 left-0 w-full px-2 py-1 ml-auto text-sm font-bold text-center xl:px-4 md:static md:w-auto md:rounded-full bg-brand-200 text-brand-400">
					Most Popular
				</div>
			)}
		</div>

		<div className="mb-10 text-center">{children}</div>

		<div className="flex flex-col w-full mt-auto">
			<LinkButton
				data-testid={`modal-${packageName}`}
				color="accent"
				size="lg"
				href={buyOnline ? link : demoLink}
				onClick={() => {
					if (setShowModal) {
						setShowModal(true);
					}
				}}
				text={`Request My Free ${packageName} Demo`}
			/>
		</div>
	</div>
);

const BenefitItem = ({ children }) => (
	<li className="relative pb-4 pr-10 mb-4 border-b last:mb-0 last:pb-0 last:border-0 border-brand-300">
		{children}
	</li>
);

const PricingCards = ({ buyOnline, demoLink, content, filter = null, setShowModal }) => {
	const cards = content.options.filter((o) => {
		if (filter) {
			return filter.includes(o.id);
		}

		return true;
	});

	return (
		<div className="lg:flex">
			{cards.map((option) => (
				<PricingCard
					key={option.id}
					buyOnline={buyOnline}
					demoLink={demoLink}
					packageTitle={
						<>
							<option.icon className="inline-block w-6 mr-1 align-top" />{' '}
							{option.name}
						</>
					}
					rating={option.rating}
					link={option.link}
					mostPopular={option.mostPopular && cards.length > 1}
					className={cn(cards.length > 1 ? option.modifiers : undefined)}
					packageName={option.name}
					setShowModal={setShowModal}
				>
					<h3 className="mb-6 text-lg text-left text-gray-800">{option.subheading}</h3>

					<ul className="text-left">
						{option.benefits.map((benefit) => (
							<BenefitItem key={benefit.id}>
								<h4 className="font-bold leading-snug text-gray-700">
									{benefit.name}
								</h4>
								<p className="text-sm text-gray-700">{benefit.description}</p>
								<InfoModal
									className="absolute top-0 right-0"
									title={benefit.name}
									img={benefit.img}
								>
									{benefit.info.map((item) => (
										<p className="mb-4 last:mb-0" key={nanoid()}>
											{item}
										</p>
									))}
								</InfoModal>
							</BenefitItem>
						))}
					</ul>
				</PricingCard>
			))}
		</div>
	);
};

export default PricingCards;
