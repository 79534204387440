import React from 'react';

const TrustStatsBlock = ({ trustStatsBlockContent }) => {
	const {
		mainHeading,
		subHeading,
		firstStatFigure,
		firstStatText,
		secondStatFigure,
		secondStatText,
		thirdStatFigure,
		thirdStatText,
		trustBadge,
		legalText,
	} = trustStatsBlockContent;

	return (
		<div className="px-5 py-10">
			{mainHeading && (
				<h2 className="mb-4 text-5xl font-bold leading-none text-center">{mainHeading}</h2>
			)}
			{subHeading && (
				<h3 className="mb-10 text-2xl font-bold leading-8 text-center">{subHeading}</h3>
			)}
			<div className="flex flex-col justify-between mb-10 sm:flex-row sm:flex-nowrap">
				{firstStatFigure && firstStatText && (
					<div className="flex flex-col mb-8 text-center sm:w-1/3 sm:mb-0">
						<span className="text-4xl font-bold leading-10 text-brand-500">
							{firstStatFigure}
						</span>
						<span className="max-w-xs px-5 mx-auto">{firstStatText}</span>
					</div>
				)}
				{secondStatFigure && secondStatText && (
					<div className="flex flex-col mb-8 text-center sm:w-1/3 sm:mb-0">
						<span className="text-4xl font-bold leading-10 text-brand-500">
							{secondStatFigure}
						</span>
						<span className="max-w-xs px-5 mx-auto">{secondStatText}</span>
					</div>
				)}
				{thirdStatFigure && thirdStatText && (
					<div className="flex flex-col text-center sm:w-1/3">
						<span className="text-4xl font-bold leading-10 text-brand-500">
							{thirdStatFigure}
						</span>
						<span className="max-w-xs px-5 mx-auto">{thirdStatText}</span>
					</div>
				)}
			</div>
			{trustBadge.url && (
				<img src={trustBadge.url} alt="" className="w-full max-w-sm mx-auto mb-5" />
			)}
			{legalText && (
				<p className="mb-0 text-center">
					<small className="leading-5 text-s">{legalText}</small>
				</p>
			)}
		</div>
	);
};

export default TrustStatsBlock;
