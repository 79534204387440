import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

const standardisedLocales = {
	en: 'en',
	ca: 'en-CA',
	au: 'en-AU',
	nz: 'en-NZ',
	ie: 'en-IE',
	'en-CA': 'en-CA',
	'en-AU': 'en-AU',
	'en-NZ': 'en-NZ',
	'en-IE': 'en-IE',
};

const ReviewBadgesPPC = ({
	locale,
	width,
	height,
	classes,
	g2AwardWidth,
	g2AwardHeight,
	g2AwardClasses,
}) => {
	const data = useStaticQuery(graphql`
		query ReviewBadgesPPCQuery {
			allDatoCmsBadgesFreeDemoPpc {
				nodes {
					locale
					preferredReviewerBadge {
						url
						alt
					}
					g2AwardBadge {
						alt
						url
					}
				}
			}
		}
	`);

	const { nodes: badges } = data.allDatoCmsBadgesFreeDemoPpc;
	const badgeByLocale = badges.filter((badge) => badge.locale === standardisedLocales[locale])[0];

	const preferredReviewerBadgeImage = badgeByLocale?.preferredReviewerBadge;
	const g2AwardImage = badgeByLocale?.g2AwardBadge;

	return (
		<>
			<img
				src={preferredReviewerBadgeImage?.url}
				alt={preferredReviewerBadgeImage?.alt || 'Review badge'}
				width={width}
				height={height}
				className={classes}
			/>
			<img
				src={g2AwardImage?.url}
				alt={g2AwardImage?.alt || 'G2 Award badge'}
				width={g2AwardWidth}
				height={g2AwardHeight}
				className={g2AwardClasses}
			/>
		</>
	);
};
export default ReviewBadgesPPC;
