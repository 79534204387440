import { useEffect, useState, useCallback } from 'react';
import { sanitizeUtmString } from '../components/Pagination/utils';

const usePaginatedSearch = ({ search, searchInputRef }) => {
	const isSearchParam = typeof window !== 'undefined' && window.location.search;
	const utmString = isSearchParam ? window.location.search : 'page=1';

	const [showSearch, setShowSearch] = useState(false);
	const [searchInput, setSearchInput] = useState('');
	const [filter, setFilter] = useState('');
	const [searchValue, setSearchValue] = useState(null);
	const [debouncedSearchValue, setDebouncedSearchValue] = useState(null);

	const setSearchResult = useCallback(
		(value, display) => {
			setSearchInput(value);
			search(value);
			setShowSearch(display);
		},
		[search],
	);

	const clearSearch = () => {
		setSearchResult('', false);
		window.location.search = `?page=1${filter && `&filter=${filter}`}`;
	};

	const delayedURLUpdate = (e) => setSearchValue(e.target.value);

	const updateURL = (value) => {
		window.history.replaceState(
			{ search: value },
			'',
			`${`?page=1${filter && `&filter=${filter}`}&search=${value}`}`,
		);
	};

	useEffect(() => {
		const delaySearch = setTimeout(() => {
			setDebouncedSearchValue(searchValue);
		}, 2000);
		return () => clearTimeout(delaySearch);
	}, [searchValue]);

	useEffect(() => {
		if (!debouncedSearchValue) {
			return;
		}
		updateURL(debouncedSearchValue);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [debouncedSearchValue]);

	const showSearchResults = (e, instantURLUpdate = true) => {
		const searchTarget = e ? e.target.value : searchInputRef.current.value;

		setSearchResult(searchTarget, true);
		if (instantURLUpdate) {
			updateURL(searchTarget);
		}

		if (searchTarget?.length === 0) {
			clearSearch();
		}
	};
	const handleKeyDown = (e) => {
		if (e.key === 'Enter' || e.which === 13 || e.keyCode === 13) {
			showSearchResults();
		}

		if (e.key === 'Escape' || e.which === 27 || e.key === 27) {
			clearSearch();
		}
	};

	useEffect(() => {
		if (utmString.includes('search')) {
			setSearchResult(sanitizeUtmString(utmString, 'search'), true);
		}
		if (utmString.includes('filter')) {
			setFilter(sanitizeUtmString(utmString, 'filter'), false);
		}
	}, [utmString, setSearchResult]);

	return {
		showSearch,
		searchInput,
		showSearchResults,
		clearSearch,
		handleKeyDown,
		filter,
		delayedURLUpdate,
	};
};

export default usePaginatedSearch;
