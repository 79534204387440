import React from 'react';
import CopyBlock from '../../CopyBlock';
import ArrowRightLarge from '../../../icons/ArrowRightLarge.svg';

const TabContent = ({ el, currentSlide, slides }) => (
	<div className="flex flex-col justify-between h-full px-6 pb-6">
		<div>
			<p className="pb-6 text-xl font-bold text-brand-500">{el.title}</p>
			<CopyBlock>{el.descriptionNode.childMdx.body}</CopyBlock>
		</div>
		{currentSlide === slides.indexOf(el) && el.link && (
			<a
				href={el.link}
				className="flex items-center mt-4 mr-auto font-bold row text-brand-500 hover:text-brand-400"
			>
				<p>Find out more</p>
				<ArrowRightLarge className="m-2" />
			</a>
		)}
	</div>
);

export default TabContent;
