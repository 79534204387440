import React from 'react';

import Button from 'bright-components/dist/components/Button/Button';
import ValidationMsg from 'bright-components/dist/components/Validation';
import Loader from 'bright-components/dist/components/InlineLoader';
import PayLock from '../../../../../../icons/PayLock.svg';
import PaySheild from '../../../../../../icons/PayShield.svg';

export default ({
	isSubmitting,
	processing,
	values,
	setEditAddress,
	setAddressSelected,
	isValid,
	quoteReady,
	totalPrice,
	errorMessage,
}) => {
	const PadlockLockedIcon = (props) => (
		<svg {...props} enableBackground="new 0 0 448 448" viewBox="0 0 448 448">
			<path d="m352 149.33h-21.333v-42.667c0-58.879-47.787-106.67-106.67-106.67s-106.67 47.787-106.67 106.67v42.667h-21.333c-23.573 0-42.667 19.093-42.667 42.667v213.33c0 23.573 19.094 42.666 42.667 42.666h256c23.573 0 42.667-19.093 42.667-42.667v-213.33c0-23.573-19.094-42.667-42.667-42.667zm-128 192c-23.573 0-42.667-19.093-42.667-42.667 0-23.573 19.094-42.666 42.667-42.666s42.667 19.093 42.667 42.667c0 23.573-19.094 42.666-42.667 42.666zm66.133-192h-132.27v-42.667c0-36.48 29.653-66.133 66.133-66.133s66.133 29.653 66.133 66.133v42.667z" />
		</svg>
	);
	return (
		<>
			<Button
				disabled={isSubmitting || processing || !values.tandc}
				className="w-full"
				type="submit"
				onClick={() => {
					if (!isValid) {
						setEditAddress(true);
						setAddressSelected(false);
					}
				}}
				data-cy="paymentSubmit"
			>
				<div className="flex items-center justify-center mx-auto">
					{!isSubmitting && !processing ? (
						<PadlockLockedIcon className="inline-block w-5 h-5 mr-2 fill-current color-white" />
					) : (
						<Loader isLoading className="inline-block w-4 h-4 mr-2" />
					)}
					{quoteReady ? (
						<span>Pay £{totalPrice || ''}</span>
					) : (
						<span>Pay £ Calculating...</span>
					)}
				</div>
			</Button>
			<div data-cy="paymentError">
				<ValidationMsg visible={!!errorMessage}>{errorMessage || ''}</ValidationMsg>
			</div>
			<div className="flex items-center mt-10 mb-4">
				<PaySheild className="w-8 h-8 mr-6" />
				<p className="text-sm leading-5">
					Your information is safe. We will not rent or sell your personal contact
					information for any marketing purposes whatsoever.
				</p>
			</div>
			<div className="flex items-center">
				<PayLock className="w-8 h-8 mr-6" />
				<p className="text-sm leading-5">
					Secure checkout. Information is encrypted and transmitted without risk using a
					Secure Sockets Layer protocol.
				</p>
			</div>
		</>
	);
};
