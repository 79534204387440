import React from 'react';

import ArrowLeftLarge from '../../../../../../icons/ArrowLeftLarge.svg';
import BrightLogoCircle from '../../../../../../icons/BrightLogoCircle.svg';

export default () => (
	<div className="flex-row justify-between hidden flex-nowrap lg:flex lg:mb-5 lg:w-full lg:max-w-md lg:transform lg:-translate-x-6">
		<a className="flex flex-row items-center flex-nowrap" href="/pricing/">
			<ArrowLeftLarge /> <BrightLogoCircle className="ml-2" />{' '}
			<span className="ml-2">BrightHR</span>
		</a>
	</div>
);
