import React, { useState, useEffect, useMemo } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Cookies from 'js-cookie';
import LiveWebinarCountdownPopup from './LiveWebinarCountdownPopup';
import DefaultWebinarPopup from './DefaultWebinarPopup';
import LiveWebinarPopup from './LiveWebinarPopup';
import { buildLink, LOCALE_PATHS } from '../../utils/locale';
import Modal from '../Modal';
import { useLocale } from '../../context/Locale';
import ClientOnly from '../../utils/client-only';

const getWebinarId = (idString) => idString.split('DatoCmsLiveWebinar-')[1].split('-')[0];

const WebinarPopup = ({ variant }) => {
	const data = useStaticQuery(graphql`
		query LiveWebinarQuery {
			allDatoCmsLiveWebinar {
				nodes {
					_allLocalisationEnablerLocales {
						locale
					}
					id
					title
					registrationLink
					liveWebinarDate
					liveWebinarTime
					liveWebinarTimezone
					description
					locale
					speakerOneShowLogo
					speakerOne {
						fullName
						headshot {
							gatsbyImageData(imgixParams: { fm: "webp", auto: "compress" })
							alt
						}
						jobTitle
						externalSpeaker
						logo {
							url
						}
						logo2 {
							url
						}
					}
					speakerTwo {
						fullName
						logo {
							url
						}
					}
					jointHeadshot {
						headshot {
							gatsbyImageData(imgixParams: { fm: "webp", auto: "compress" })
							alt
						}
					}
				}
			}
		}
	`);

	const locale = useLocale();

	const [showModal, setShowModal] = useState(false);

	const { nodes: liveWebinars } = data.allDatoCmsLiveWebinar;

	const today = useMemo(() => new Date(), []);

	const upcomingWebinar = useMemo(() => {
		const nonExpiredWebinars = liveWebinars?.filter(
			(webinar) =>
				new Date(`${webinar.liveWebinarDate}T${webinar.liveWebinarTime}:00`) > today,
		);

		if (nonExpiredWebinars.length === 0) {
			return null;
		}

		// compares useLocale value to the one on the webinar record from Dato
		const localeSpecificWebinars = nonExpiredWebinars?.filter(
			(webinar) =>
				// eslint-disable-next-line no-underscore-dangle
				LOCALE_PATHS[webinar._allLocalisationEnablerLocales[0].locale] === locale &&
				webinar.liveWebinarDate !== null,
		);

		const sortedWebinars = localeSpecificWebinars?.sort(
			(a, b) =>
				new Date(`${a.liveWebinarDate}T${a.liveWebinarTime}:00`) -
				new Date(`${b.liveWebinarDate}T${b.liveWebinarTime}:00`),
		);

		return (
			sortedWebinars?.find(
				(webinar) => !Cookies.get('liveWebinars')?.includes(getWebinarId(webinar.id)),
			) || sortedWebinars[sortedWebinars.length - 1]
		);
	}, [liveWebinars, locale, today]);

	useEffect(() => {
		const onMouseLeave = (event) => {
			if (
				event.clientY <= 0 ||
				event.clientX <= 0 ||
				event.clientX >= window.innerWidth ||
				event.clientY >= window.innerHeight
			) {
				setShowModal(true);
				window.sessionStorage.setItem('webinarPopupShown', true);
			}
		};

		if (typeof window !== 'undefined' && !window.sessionStorage.getItem('webinarPopupShown')) {
			document.documentElement.addEventListener('mouseleave', onMouseLeave);
			return () => document.documentElement.removeEventListener('mouseleave', onMouseLeave);
		}
	});

	const getContent = () => {
		if (upcomingWebinar) {
			const webinarId = getWebinarId(upcomingWebinar.id);
			const href = `${upcomingWebinar.registrationLink}?webinarId=${webinarId}`;

			if (variant === 'countdown') {
				return <LiveWebinarCountdownPopup upcomingWebinar={upcomingWebinar} href={href} />;
			}
			return <LiveWebinarPopup upcomingWebinar={upcomingWebinar} href={href} />;
		}
		return <DefaultWebinarPopup href={buildLink(locale, 'webinars/')} />;
	};

	return (
		<ClientOnly>
			<Modal show={showModal} onClose={() => setShowModal(false)} theme="dark">
				<div className="m-4 md:mb-0">
					<div className="max-w-[700px] h-full overflow-hidden rounded-xl bg-white">
						{getContent()}
					</div>
				</div>
			</Modal>
		</ClientOnly>
	);
};

export default WebinarPopup;
