import React from 'react';
import CopyBlock from '../../CopyBlock';

const FooterCTA = ({ cta, header }) => (
	<section className="px-6 pb-24 text-center lg:mb-24 form-section-bg-alt">
		<h2 className="my-12 text-xl font-bold text-brand-500">{header}</h2>
		<div className="relative mb-4 text-white hero-harry-redknapp-content">
			<CopyBlock>{cta}</CopyBlock>
		</div>
	</section>
);

export default FooterCTA;
