import React, { useRef } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { graphql } from 'gatsby';
import cn from 'classnames';
import { useLocation } from '@reach/router';
import DefaultLayout from '../layouts/Default';
import videosTile from '../images/support/videos-tile.svg';
import guidesTile from '../images/support/guides-tile.svg';
import faqsTile from '../images/support/faqs-tile.svg';
import { buildLink, SUPPORTED_LOCALES } from '../utils/locale';
import scrollDirection from '../utils/scrollDirection';
import { useLocale } from '../context/Locale';
import HrefLang from '../components/HrefLang';
import DatoSEO from '../components/DatoSEO';

import ServiceWebinarBanner from '../components/ServiceWebinarBanner';
import LogSupport from '../components/Support/LogSupportCase';
import PhoneContact from '../components/Support/PhoneContact';
import Question from '../components/Support/Question';
import VideoCard from '../components/Support/VideoCard';
import GuideCard from '../components/Support/GuideCard';

import useFuse from '../components/useFuse';
import SearchBar from '../components/SearchBar';
import ArrowRightLarge from '../icons/ArrowRightLarge.svg';
import usePaginatedSearch from '../hooks/usePaginatedSearch';

const fuseOptions = {
	keys: [
		{
			name: 'title',
			weight: 0.7,
		},
		{
			name: 'topic.name',
			weight: 0.3,
		},
		{
			name: 'question',
			weight: 0.7,
		},
		{
			name: 'answer',
			weight: 0.3,
		},
	],
	minMatchCharLength: 2,
	limit: 9,
};

const SupportHub = ({ data }) => {
	const locale = useLocale();
	const location = useLocation();
	const {
		heading,
		description,
		videosDescription,
		guidesDescription,
		faqsDescription,
		seoMetaTags,
	} = data.allDatoCmsSupport.nodes[0];

	const videosData = data.videosData.nodes;
	const guidesData = data.guidesData.nodes;
	const faqsData = data.faqsData.nodes;
	const allResults = faqsData.concat(videosData, guidesData);

	const listRef = useRef();
	const searchInputRef = useRef();
	const { result, search } = useFuse(allResults, fuseOptions);
	const { clearSearch, showSearchResults, searchInput, handleKeyDown, delayedURLUpdate } =
		usePaginatedSearch({
			searchInputRef,
			search,
		});

	const topFaqs = result.filter((item) => 'question' in item).slice(0, 9);
	const topVideos = result.filter((item) => 'vimeoId' in item).slice(0, 9);
	const topGuides = result
		.filter((item) => 'vimeoId' in item === false && 'question' in item === false)
		.slice(0, 9);

	const storeSearchTerm = () => localStorage.setItem('storedSearchTerm', searchInput);

	const scroll = scrollDirection();

	return (
		<>
			<HrefLang locales={SUPPORTED_LOCALES} slug="help" />
			<DatoSEO path={location.pathname} meta={seoMetaTags} />
			<div className="px-6">
				<section className="flex flex-row items-start max-w-screen-xl mx-auto bg-right-top bg-no-repeat bg-contain border-b border-gray-300">
					<div className="max-w-2xl py-8">
						<h1 className="mb-3 text-2xl font-bold lg:text-4xl md:mt-12">{heading} </h1>
						<p className="lg:text-xl">{description}</p>
					</div>
					<StaticImage
						data-cy="support-faqs-hero-image"
						src="../images/support/hub-hero.png"
						alt="faqs banner"
						className="hidden w-4/12 mt-8 ml-auto md:block"
						placeholder="eager"
					/>
				</section>

				<div className="relative max-w-screen-xl mx-auto mt-16 mb-8">
					<SearchBar
						listRef={listRef}
						className="mb-8"
						value={searchInput}
						onChange={(e) => {
							showSearchResults(e, false);
							delayedURLUpdate(e);
						}}
						placeholder="What do you need help with?"
						ref={searchInputRef}
						clearSearch={clearSearch}
						scroll={scroll}
						onKeyDown={handleKeyDown}
					/>
					<div
						className={cn(
							'rounded bg-brand-100',
							searchInput.length >= 3 ? 'block p-8 pt-6 mb-8' : 'hidden',
						)}
						ref={listRef}
					>
						{searchInput.length >= 3 && (
							<>
								{topFaqs.length > 0 && (
									<div>
										<div className="flex flex-col justify-between mb-5 md:flex-row flex-nowrap">
											<h3 className="mb-3 text-xl leading-7 md:mb-0">
												Top results in:{' '}
												<span className="font-bold text-brand-500">
													FAQs
												</span>
											</h3>
											<p className="flex flex-row justify-end w-full leading-7 md:w-auto text-brand-500 flex-nowrap">
												<a
													href={buildLink(
														locale,
														`help/faqs?page=1&search=${searchInput}`,
													)}
													onClick={storeSearchTerm}
													className="font-bold leading-7"
												>
													See all results in &lsquo;FAQs&rsquo;
													<ArrowRightLarge className="inline-block ml-5 h-7" />
												</a>
											</p>
										</div>
										<ul className="space-y-4">
											{topFaqs.map((question) => (
												<li key={question.id}>
													<Question {...question} locale={locale} />
												</li>
											))}
										</ul>
									</div>
								)}
								{topVideos.length > 0 && (
									<div className="mt-7">
										<div className="flex flex-col justify-between mb-5 md:flex-row flex-nowrap">
											<h3 className="mb-3 text-xl leading-7 md:mb-0">
												Top results in:{' '}
												<span className="font-bold text-brand-500">
													Videos
												</span>
											</h3>
											<p className="flex flex-row justify-end w-full leading-7 md:w-auto text-brand-500 flex-nowrap">
												<a
													href={buildLink(
														locale,
														`help/videos?page=1&search=${searchInput}`,
													)}
													onClick={storeSearchTerm}
													className="font-bold leading-7"
												>
													See all results in &lsquo;Videos&rsquo;
													<ArrowRightLarge className="inline-block ml-5 h-7" />
												</a>
											</p>
										</div>
										<ul className="grid gap-6 xl:gap-8 lg:grid-cols-3 md:grid-cols-2">
											{topVideos.map((video) => (
												<VideoCard key={video.id} {...video} />
											))}
										</ul>
									</div>
								)}
								{topGuides.length > 0 && (
									<div className="mt-10">
										<div className="flex flex-col justify-between mb-5 md:flex-row flex-nowrap">
											<h3 className="mb-3 text-xl leading-7 md:mb-0">
												Top results in:{' '}
												<span className="font-bold text-brand-500">
													Guides
												</span>
											</h3>
											<p className="flex flex-row justify-end w-full leading-7 md:w-auto text-brand-500 flex-nowrap">
												<a
													href={buildLink(
														locale,
														`help/guides?page=1&search=${searchInput}`,
													)}
													onClick={storeSearchTerm}
													className="font-bold leading-7"
												>
													See all results in &lsquo;Guides&rsquo;
													<ArrowRightLarge className="inline-block ml-5 h-7" />
												</a>
											</p>
										</div>
										<ul className="grid gap-6 lg:grid-cols-3 md:grid-cols-2">
											{topGuides.map((guide) => (
												<GuideCard key={guide.id} {...guide} />
											))}
										</ul>
									</div>
								)}
							</>
						)}
					</div>
					<section>
						<h2 className="mb-4 text-xl font-bold text-center md:mb-0">
							Browse help by type
						</h2>
						<ul className="flex flex-wrap mt-0 md:-mx-4">
							<li className="w-full p-4 md:w-1/3">
								<a
									href={buildLink(locale, 'help/faqs')}
									className="flex flex-col h-full p-6 space-y-4 text-center transition-shadow duration-150 border border-gray-300 rounded hover:shadow-lg"
								>
									<img
										className="w-48 mx-auto"
										src={faqsTile}
										alt="FAQs Tile"
										aria-hidden
									/>
									<div className="mt-auto">
										<h3 className="mb-2 text-xl font-bold">FAQs</h3>
										<p>{faqsDescription}</p>
									</div>
								</a>
							</li>
							<li className="w-full p-4 md:w-1/3">
								<a
									href={buildLink(locale, 'help/videos')}
									className="flex flex-col h-full p-6 space-y-4 text-center transition-shadow duration-150 border border-gray-300 rounded hover:shadow-lg"
								>
									<img
										className="w-48 mx-auto"
										src={videosTile}
										alt="Videos Tile"
										aria-hidden
									/>
									<div className="mt-auto">
										<h3 className="mb-2 text-xl font-bold">Videos</h3>
										<p>{videosDescription}</p>
									</div>
								</a>
							</li>
							<li className="w-full p-4 md:w-1/3">
								<a
									href={buildLink(locale, 'help/guides')}
									className="flex flex-col h-full p-6 space-y-4 text-center transition-shadow duration-150 border border-gray-300 rounded hover:shadow-lg"
								>
									<img
										className="w-48 mx-auto"
										src={guidesTile}
										alt="Guides Tile"
										aria-hidden
									/>
									<div className="mt-auto">
										<h3 className="mb-2 text-xl font-bold">Guides</h3>
										<p>{guidesDescription}</p>
									</div>
								</a>
							</li>
						</ul>
					</section>
					<section>
						<ServiceWebinarBanner />
					</section>
					<section className="flex flex-col pt-12 mt-12 mb-10 border-t md:items-stretch md:flex-row">
						<div className="w-full md:mr-3 md:6/12">
							<LogSupport locale={locale} />
						</div>
						<div className="w-full md:ml-3 md:6/12">
							<PhoneContact />
						</div>
					</section>
				</div>
			</div>
		</>
	);
};

const WrappedSupportHub = (props) => (
	<DefaultLayout fullWidth>
		<SupportHub {...props} />
	</DefaultLayout>
);

export default WrappedSupportHub;

export const query = graphql`
	query supportHubPage($locale: String!) {
		allDatoCmsSupport(filter: { locale: { eq: $locale } }) {
			nodes {
				seoMetaTags {
					...GatsbyDatoCmsSeoMetaTags
				}
				videosDescription
				guidesDescription
				faqsDescription
				description
				heading
			}
		}
		guidesData: allDatoCmsSupportGuide(
			filter: { locale: { eq: $locale }, title: { ne: null } }
			sort: { fields: title, order: ASC }
		) {
			nodes {
				id
				originalId
				title
				topic {
					originalId
					name
				}
				link
			}
		}
		videosData: allDatoCmsSupportVideo(
			filter: { locale: { eq: $locale }, title: { ne: null } }
			sort: { fields: title, order: ASC }
		) {
			nodes {
				id
				originalId
				title
				vimeoId
				topic {
					originalId
					name
				}
			}
		}
		faqsData: allDatoCmsSupportQuestion(filter: { locale: { eq: $locale } }) {
			nodes {
				locale
				showInAu
				showInCa
				showInIe
				showInNz
				showInUk
				topic {
					name
					slug
				}
				subTopic {
					slug
					name
					image {
						fixed(width: 650, imgixParams: { auto: "compress", q: 60 }) {
							srcSet
							src
						}
					}
				}
				question
				answer
				id
				originalId
				videoId
			}
		}
	}
`;
