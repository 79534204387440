const createPaymentMethod = async ({
	stripe,
	cardElement,
	PaymentMethodCreationError,
	setFinalPaymentMethodId,
	setProcessing,
}) => {
	const { error, paymentMethod } = await stripe.createPaymentMethod({
		type: 'card',
		card: cardElement,
	});

	if (error) {
		setProcessing(false);
		throw new PaymentMethodCreationError(error.message);
	}

	setFinalPaymentMethodId(paymentMethod.id);

	return paymentMethod.id;
};

export default createPaymentMethod;
