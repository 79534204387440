import React from 'react';
import { FormikInput } from 'bright-components';

import Fieldset from '../../Components/Fieldset';
import HStack from '../../Components/HStack';
import Label from '../../Components/Label';
import Legend from '../../Components/Legend';
import HelpText from '../../Components/HelpText';

export default ({ handleBlur, debouncedMaybeSyncSession, emailInput, errorMessage }) => (
	<Fieldset>
		<Legend>Your details:</Legend>
		<div>
			<Label className="flex flex-col" id="companyName">
				Company name
			</Label>
			<FormikInput
				onBlur={(e) => {
					handleBlur(e);
					debouncedMaybeSyncSession();
				}}
				fullWidth
				className="flex-grow-0"
				type="text"
				required
				id="companyName"
				name="companyName"
				autocomplete="organization"
			/>
		</div>
		<HStack>
			<div>
				<Label className="flex flex-col" id="firstName">
					First name
				</Label>
				<FormikInput
					onBlur={(e) => {
						handleBlur(e);
						debouncedMaybeSyncSession();
					}}
					autocomplete="given-name"
					fullWidth
					className="flex-grow-0"
					type="text"
					required
					id="firstName"
					name="firstName"
				/>
			</div>
			<div>
				<Label className="flex flex-col" id="lastName">
					Last name
				</Label>
				<FormikInput
					onBlur={(e) => {
						handleBlur(e);
						debouncedMaybeSyncSession();
					}}
					autocomplete="family-name"
					fullWidth
					className="flex-grow-0"
					type="text"
					required
					id="lastName"
					name="lastName"
				/>
			</div>
		</HStack>

		<HStack>
			<div ref={emailInput}>
				<Label className="flex flex-col" id="email">
					Email
				</Label>
				<FormikInput
					onBlur={(e) => {
						handleBlur(e);
						debouncedMaybeSyncSession();
					}}
					autocomplete="email"
					fullWidth
					className="flex-grow-0"
					type="text"
					required
					id="email"
					name="email"
				/>
				{errorMessage ===
				'The email address you have used is already registered with BrightHR.' ? (
					<HelpText>
						<p className="mt-4">
							If you would like to continue you can{' '}
							<a
								href="https://app.brighthr.com/"
								className="font-bold underline text-brand-500"
							>
								login
							</a>{' '}
							or try signing up with a different email address.
						</p>
					</HelpText>
				) : (
					<HelpText>
						<span className="block pt-3">
							We need your email address for account creation purposes
						</span>
					</HelpText>
				)}
			</div>
			<div>
				<Label className="flex flex-col" id="telephone">
					Phone
				</Label>
				<FormikInput
					onBlur={(e) => {
						handleBlur(e);
						debouncedMaybeSyncSession();
					}}
					autocomplete="tel"
					fullWidth
					className="flex-grow-0"
					type="text"
					required
					id="telephone"
					name="telephone"
				/>
			</div>
		</HStack>
	</Fieldset>
);
