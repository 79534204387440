import React, { useEffect, useState, forwardRef } from 'react';
import throttle from 'lodash.throttle';
import cn from 'classnames';
import CrossIcon from '../../icons/Cross.svg';

const SearchBar = forwardRef(
	(
		{
			value,
			onChange,
			onFocus,
			className,
			placeholder = 'Search...',
			listRef,
			clearSearch,
			scroll,
		},
		searchInputRef,
	) => {
		const [stuck, setStuck] = useState(false);
		useEffect(() => {
			const onScroll = throttle(() => {
				if (searchInputRef.current?.getBoundingClientRect().top === 0) {
					setStuck(true);
				} else {
					setStuck(false);
				}
			}, 100);

			window.addEventListener('scroll', onScroll);
			return () => window.removeEventListener('scroll', onScroll);
		}, [setStuck, scroll, searchInputRef]);

		return (
			<label
				ref={searchInputRef}
				htmlFor="search"
				className={cn(
					'sticky md:mx-4 block z-20 shadow-lg rounded border-gray-200 transition-all duration-300 ease-in-out',
					{
						'shadow-lg transition-shadow duration-300 bg-white': stuck,
						'lg:top-20 top-16': scroll === 'up',
						'top-4': scroll === 'down',
					},
					className,
				)}
			>
				<div className="flex flex-row items-stretch mx-auto ">
					<div className="py-6 pl-6 pr-4 overflow-hidden bg-white border-2 border-r-0 border-gray-200 rounded-l">
						<svg
							className="mt-1"
							width="18"
							height="18"
							viewBox="0 0 18 18"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M1.79993 8.09969C1.79993 4.62043 4.62043 1.79993 8.09969 1.79993C11.579 1.79993 14.3995 4.62043 14.3995 8.09969C14.3995 9.79758 13.7278 11.3386 12.6356 12.4715C12.6055 12.4948 12.5765 12.5202 12.5488 12.5479C12.5211 12.5756 12.4956 12.6046 12.4723 12.6348C11.3393 13.7274 9.79798 14.3995 8.09969 14.3995C4.62043 14.3995 1.79993 11.579 1.79993 8.09969ZM13.1559 14.4278C11.7702 15.5364 10.0124 16.1994 8.09969 16.1994C3.62635 16.1994 0 12.573 0 8.09969C0 3.62635 3.62635 0 8.09969 0C12.573 0 16.1994 3.62635 16.1994 8.09969C16.1994 10.0119 15.5367 11.7693 14.4286 13.1549L17.7364 16.4628C18.0879 16.8142 18.0879 17.384 17.7364 17.7355C17.3849 18.087 16.8151 18.087 16.4637 17.7355L13.1559 14.4278Z"
								fill="#004991"
							/>
						</svg>
					</div>

					<input
						autoComplete="off"
						id="search"
						className="w-full py-6 pr-6 mx-auto overflow-hidden bg-white border-2 border-l-0 border-gray-200 rounded-r outline-none"
						placeholder={placeholder}
						onFocus={onFocus}
						onChange={(e) => {
							onChange(e);

							if (
								window.scrollY <
								listRef.current?.parentElement.offsetTop +
									listRef.current?.offsetHeight
							)
								return;

							window.scrollTo(
								0,
								listRef.current?.parentElement.offsetTop -
									searchInputRef.current?.clientHeight,
							);
						}}
						value={value}
					/>
					{clearSearch && (
						<CrossIcon
							className="absolute w-6 -translate-y-1/2 z-100 top-1/2 right-4 hover:cursor-pointer"
							onClick={clearSearch}
							tabIndex="0"
						/>
					)}
				</div>
			</label>
		);
	},
);

export default SearchBar;
