import React, { useLayoutEffect } from 'react';
import loadScript from 'load-script2';
import { useLocation } from '@reach/router';

import Logo from '../components/Logo';
import IconList from '../components/IconList';
import Var from '../components/Var';
import MasterLayout from '../layouts/Master';
import SEO from '../components/SEO';
import ReviewBadges from '../components/ReviewBadges';

const BookADemoLandbot = () => {
	const location = useLocation();

	useLayoutEffect(() => {
		loadScript('https://static.landbot.io/landbot-3/landbot-3.0.0.js').then(() => {
			// eslint-disable-next-line no-unused-vars
			const landbot = new window.Landbot.Container({
				container: '#landbot-container',
				configUrl: 'https://chats.landbot.io/v3/H-954613-YFGCGUJ7QD7CSN6U/index.json',
			});
		});
	}, []);

	return (
		<>
			<SEO path={location.pathname} title="Book a demo" />
			<MasterLayout>
				<div className="relative flex flex-row overflow-hidden content">
					<main className="flex-1 lg:pr-large lg:h-screen">
						<div className="h-screen">
							<div className="fixed inset-x-0 px-3 py-2 space-y-2 bg-white border-b">
								<div className="flex items-center justify-between space-x-6">
									<div className="block text-brand-500">
										<Logo className="h-12" />
									</div>
									<div className="flex justify-center w-48 lg:hidden">
										<ReviewBadges
											locale="en"
											pageName="book a demo"
											classes="w-[100px]"
											g2AwardClasses="hidden"
										/>
									</div>
								</div>
							</div>
							<div
								id="landbot-container"
								className="h-screen pt-24 overflow-hidden lg:h-full"
							/>
						</div>
					</main>
					<aside
						className="relative z-10 hidden h-screen max-w-lg text-white bg-center bg-cover bg-brand-500 lg:block "
						style={{
							backgroundImage: `url('https://www.datocms-assets.com/23428/1605690677-cafeman.jpg')`,
						}}
					>
						<div className="absolute top-0 bottom-0 left-0 right-0 h-screen bg-gray-900 opacity-90" />
						<div className="relative flex flex-col h-screen overflow-auto">
							<section className="px-12 py-12 ">
								<div className="space-y-6 lg:mb-0 2xl:pr-12">
									<h1 className="hidden text-lg leading-normal lg:block 2xl:text-xl 2xl:mb-12">
										Join the{' '}
										<span className="font-bold">
											<Var name="numCompanies" />+{' '}
										</span>{' '}
										businesses that trust BrightHR for all their HR needs
									</h1>
									<IconList
										items={[
											<p className="leading-normal">
												<span className="font-bold">Track and manage</span>{' '}
												remote workers.
											</p>,
											<p className="leading-normal">
												<span className="font-bold">
													Make your business COVID-secure
												</span>{' '}
												with expert health & safety software and advice.
											</p>,
											<p className="leading-normal">
												<span className="font-bold">
													24/7 employment law advice
												</span>{' '}
												to help with difficult HR decisions.
											</p>,
											<p className="leading-normal">
												Plus, loads more{' '}
												<span className="font-bold">
													expert tools to help you through the COVID-19
													pandemic and beyond.
												</span>
											</p>,
										]}
									/>

									<p className="text-sm leading-normal">And much, much more...</p>
								</div>
							</section>

							<section className="px-12 pb-12 lg:flex lg:flex-col lg:flex-auto lg:justify-end">
								<div className="relative hidden mb-16 2xl:block">
									<div className="text-white -translate-y-4">
										<svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
											<path d="M10.4 11v9.6H0V16c0-4.8 1.3-8.6 3.5-12h5.1a17.9 17.9 0 00-2.5 7h4.3zM24 11v9.6H13.6V16c0-4.8 1.3-8.6 3.5-12h5.1a17.9 17.9 0 00-2.6 7H24z" />
										</svg>
									</div>
									<div className="relative z-20 text-white">
										<p className="text-lg font-bold leading-tight">
											Having BrightHR has been a complete lifesaver for our
											business.
										</p>
										<p className="text-sm">
											- Steven Greenall, Cornerstone Children’s Home
										</p>
									</div>
								</div>
								<div className="flex flex-row items-center justify-center lg:space-between">
									<ReviewBadges
										locale="en"
										pageName="techradar book a demo"
										width="230"
										classes="my-auto"
										g2AwardClasses="ml-3 w-24 lg:w-28"
									/>
								</div>
								<p className="relative text-xs">
									<a className="underline" href="/terms" target="_blank">
										Terms & privacy
									</a>{' '}
									| Copyright © 2021 BrightHR
								</p>
							</section>
						</div>
					</aside>
				</div>
			</MasterLayout>
		</>
	);
};

export default BookADemoLandbot;
