import React from 'react';

function StickyCTA({ children }) {
	return (
		<div className="sticky bottom-0 left-0 px-8 z-nav-dropdown lg:hidden bg-white-transparent">
			<div className="mx-auto w-fit">{children}</div>
		</div>
	);
}

export default StickyCTA;
