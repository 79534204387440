import React, { useState, useRef, useEffect } from 'react';
import { graphql } from 'gatsby';
import format from 'date-fns/format';
import { useLocation } from '@reach/router';
import LinkButton from '@brighthr/component-linkbutton';
import Button from '@brighthr/component-button';
import throttle from 'lodash.throttle';
import cn from 'classnames';
import { GatsbyImage } from 'gatsby-plugin-image';
import DefaultLayout from '../layouts/Default';
import DatoSEO from '../components/DatoSEO';
import ArticleProvider from '../components/CopyBlock/ArticleProvider';
import CopyBlock from '../components/CopyBlock';
import Breadcrumbs, { Breadcrumb } from '../components/Breadcrumbs';
import Clock from '../icons/Clock.svg';
import BlobBG from '../images/blobs/blob-light-dotted.svg';
import BlogCard from '../components/Blog/Card';
import CarouselBg from '../images/blog/carousel-bg.svg';
import Var from '../components/Var';
import Banner from '../components/CMSBlocks/Banner';
import Facebook from '../icons/FacebookCircle.svg';
import LinkedIn from '../icons/LinkedInCircle.svg';
import Twitter from '../icons/TwitterCircle.svg';
import HrefLang from '../components/HrefLang';
import { buildLink, LOCALE_PATHS, localisedRead } from '../utils/locale';
import LightningTileWrapper from '../components/LightningTile';
import Modal from '../components/Modal';
import BookADemoForm from '../components/Marketo/BookADemo';
import Newsletter from '../components/Footer/Components/newsletter';
import scrollDirection from '../utils/scrollDirection';
import WebinarPopup from '../components/WebinarPopup';
import LocaleContent from '../components/LocaleContent';
import useExistingUserTimout from '../hooks/useExistingUserTimout';

const BlogPost = ({ data, pageContext }) => {
	const {
		originalId,
		title,
		subtitle,
		image,
		contentNode,
		meta,
		schema: datoSchema,
		category,
		seoMetaTags,
		_allSlugLocales,
		_allSeoLocales,
		lightningWidget,
		inlineBanner,
		sideBanner,
		date,
		author,
	} = data.datoCmsBlogPost;
	const { firstPublishedAt, updatedAt } = meta;

	const sideBannerRef = useRef();
	const scroll = scrollDirection();

	const [stuck, setStuck] = useState(false);
	useEffect(() => {
		const onScroll = throttle(() => {
			if (sideBannerRef.current?.getBoundingClientRect().top === 0) {
				setStuck(true);
			} else {
				setStuck(false);
			}
		}, 100);

		window.addEventListener('scroll', onScroll);
		return () => window.removeEventListener('scroll', onScroll);
	}, [setStuck, scroll, sideBannerRef]);

	const relatedPosts = data.relatedPosts.nodes.filter((n) => n.slug);
	const location = useLocation();

	const [showModal, setShowModal] = useState(false);
	const locale = LOCALE_PATHS[pageContext.locale];

	if (location.search.includes('edit')) {
		window.location.href = `https://brighthr.admin.datocms.com/editor/item_types/252958/items/${originalId}/edit`;
	}
	const { existingUser } = useExistingUserTimout({ locale });

	const autoSchema = {
		headline: title,
		image: [image.url],
		datePublished: meta.firstPublishedAt,
		dateModified: meta.updatedAt,
		author: [
			{
				'@type': 'Organization',
				name: data.site.siteMetadata.title,
				url: data.site.siteMetadata.siteUrl,
			},
		],
	};

	return (
		<DefaultLayout fullWidth>
			<HrefLang
				locales={_allSeoLocales}
				slug={(hreflang) =>
					`blog/${category.slug}/${localisedRead(_allSlugLocales, hreflang)}`
				}
			/>
			{showModal && (
				<Modal show={showModal} onClose={() => setShowModal(false)}>
					<div className="max-w-lg p-8 m-4 bg-white md:rounded md:mb-0">
						<BookADemoForm
							title="See BrightHR in action"
							subtitle="Fill out a few short details below to arrange your free BrightHR demo."
							locale={locale}
						/>
					</div>
				</Modal>
			)}
			{!existingUser && (
				<LocaleContent showInLocales={['en', 'ca', 'au', 'ie']}>
					<WebinarPopup variant="image" />
				</LocaleContent>
			)}
			<ArticleProvider>
				<DatoSEO
					path={location.pathname}
					meta={seoMetaTags}
					datoSchema={datoSchema}
					schemaType="BlogPosting"
					autoSchema={autoSchema}
				/>
				<div
					className="max-w-6xl px-6 mx-auto bg-no-repeat xs-bg-img-none md:bg-right-top xl:px-0"
					style={{
						backgroundImage: `url(${BlobBG})`,
						width: '718',
						height: '535',
					}}
				>
					<div>
						<div className="max-w-6xl px-4 py-12 mx-auto xl:px-0 ">
							<Breadcrumbs>
								<Breadcrumb
									color="text-brand-500"
									href={buildLink(locale, 'blog')}
									position={0}
								>
									Blog
								</Breadcrumb>
								<Breadcrumb
									color="text-brand-500"
									position={1}
									href={buildLink(locale, `blog/${category.slug}`)}
								>
									{data.datoCmsBlogPost.category.name}
								</Breadcrumb>
								<Breadcrumb position={2}>{title}</Breadcrumb>
							</Breadcrumbs>
							<div className="w-full max-w-2xl pt-6 mx-auto lg:ml-0 xl:max-w-3xl">
								<h1 className="mb-2 text-3xl font-bold leading-tight md:text-5xl">
									{title}
								</h1>
								<h2 className="text-lg font-bold leading-tight text-gray-700 md:text-2xl">
									{subtitle}
								</h2>
							</div>
						</div>
					</div>

					<div>
						<div className="flex flex-row flex-wrap items-stretch max-w-6xl px-4 mx-auto lg:flex-nowrap xl:px-0">
							<article className="w-full max-w-2xl mx-auto xl:ml-0 xl:max-w-3xl xl:mb-10">
								<div className="aspect-[2/1] relative mb-10 bg-gray-200">
									<img
										aria-hidden="true"
										className="object-center w-full h-full pointer-events-none object-fit"
										src={image.fixed.src}
										srcSet={image.fixed.srcSet}
										alt={image.alt || `${title} hero image`}
									/>
									<div className="absolute left-0 flex flex-wrap w-full p-2 bg-white -bottom-6 md:bottom-0 md:p-4 md:w-9/12">
										<div className="flex flex-col sm:flex-row sm:justify-between sm:space-x-5">
											{firstPublishedAt !== updatedAt ? (
												<div className="flex flex-col text-sm font-medium">
													<p>
														First published on{' '}
														{format(
															new Date(firstPublishedAt || date),
															'eeee, MMM dd, yyyy',
														)}
													</p>
													<p>
														Last updated on{' '}
														{format(
															new Date(date || updatedAt),
															'eeee, MMM dd, yyyy',
														)}
													</p>
												</div>
											) : (
												<div className="flex flex-col text-sm font-medium">
													<p>
														Published on{' '}
														{format(
															new Date(updatedAt || date),
															'eeee, MMM dd, yyyy',
														)}{' '}
														by{' '}
														<a
															className="text-brand-500 underline"
															href={buildLink(
																locale,
																`authors/${author?.slug}`,
															)}
														>
															{author?.name}
														</a>{' '}
													</p>
												</div>
											)}

											<div className="flex items-start">
												<div className="flex items-center">
													<Clock className="inline-block w-4 h-4 mr-2" />
													<CopyBlock>
														{contentNode.childMdx.timeToRead} min read
													</CopyBlock>
												</div>
											</div>
										</div>
									</div>
								</div>
								<CopyBlock injectionId="{!banner}">
									{contentNode.childMdx.body}
									{inlineBanner && (
										<>
											<hr className="my-4" />
											<Banner
												bannerStyle="inline"
												bannerData={inlineBanner}
												scroll={scroll}
											/>
											<hr className="my-4" />
										</>
									)}
								</CopyBlock>

								{lightningWidget?.[0]?.addLightningWidget && (
									<LightningTileWrapper
										title={lightningWidget[0].lightningWidgetTitle}
										content={
											lightningWidget[0].lightningWidgetContentNode.childMdx
												.body
										}
										defaultQuestion={lightningWidget[0].lightningWidgetQuestion}
									/>
								)}

								<hr className="my-8" />

								{author && (
									<div className="mx-auto mb-6 space-y-2 rounded-xl border border-gray-200 bg-white px-8 py-6 shadow sm:flex sm:items-center sm:space-x-6 sm:space-y-0 sm:py-4">
										<div className="flex w-full flex-col items-center justify-between space-y-2 md:flex-row">
											<GatsbyImage
												image={author?.avatar?.gatsbyImageData}
												alt={
													author?.avatar?.gatsbyImageData?.alt ||
													`${author?.name} - Bright HR Expert`
												}
												className="block object-cover object-center pointer-events-none h-16 w-16 rounded-full sm:mx-0 sm:shrink-0"
											/>
											<div className="space-y-2 text-center sm:text-left md:ml-5">
												<div className="space-y-0.5">
													<p className="text-lg font-semibold">
														{author?.name}
													</p>
													<p className="text-sm font-medium text-slate-500">
														{author?.jobTitle}
													</p>
												</div>
											</div>

											<div className="md:ml-auto">
												<LinkButton
													outlineButton
													type="button"
													color="primary"
													size="lg"
													href={buildLink(
														locale,
														`authors/${author?.slug}`,
													)}
													text="View profile"
												/>
											</div>
										</div>
									</div>
								)}

								<div className="text-gray-700">
									<h4 className="mb-2 text-lg font-bold">Share this article</h4>
									<div className="flex flex-row">
										<a
											className="flex items-center justify-center mb-4 mr-4 font-bold"
											href="https://facebook.com/sharer/sharer.php?u=&t="
										>
											<Facebook
												className="w-10 h-10"
												width="40"
												height="40"
											/>
										</a>
										<a
											className="flex items-center justify-center mb-4 mr-4 font-bold"
											href="http://twitter.com/share?text=&url="
										>
											<Twitter className="w-10 h-10" width="40" height="40" />
										</a>
										<a
											className="flex items-center justify-center mb-4 mr-4 font-bold"
											href="https://www.linkedin.com/shareArticle?mini=true&url=&title=&summary=&source="
										>
											<LinkedIn
												className="w-10 h-10"
												width="40"
												height="40"
											/>
										</a>
									</div>
								</div>
							</article>

							<aside className="w-full max-w-2xl mx-auto ml-auto lg:max-w-sm lg:pl-4 ">
								<a
									href={buildLink(locale, `blog/${category.slug}`)}
									className="flex items-center font-bold lg:mb-8 lg:mx-6 text-brand-grey text-brand-400 hover:text-brand-500"
								>
									<svg
										width="24"
										height="14"
										viewBox="0 0 24 14"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
										className="mr-4"
									>
										<path
											d="M6.7527 0L7.888 1.13527L3.073 5.95025H24V7.55524H3.073L7.888 12.3702L6.7527 13.5055L0 6.75381L6.7527 0.00213984V0Z"
											fill="currentColor"
										/>
									</svg>
									Back to {category.name}
								</a>

								<div className="max-w-sm px-6 pt-4 pb-6 mx-auto lg:ml-auto lg:mr-0">
									<div
										className="w-full px-6 py-4 bg-white bg-right-top bg-no-repeat bg-contain border border-gray-200 rounded shadow-lg"
										style={{
											backgroundImage: `url(${CarouselBg})`,
											backgroundPosition: '175px -150px',
										}}
									>
										<h3 className="mb-4 text-2xl font-bold leading-snug text-brand-500">
											Subscribe for updates
										</h3>
										<p className="mb-4">
											Looking for the latest in HR, advice and tips? Get our
											latest news, articles, webinars and podcasts right in
											your inbox.
										</p>
										<Newsletter stlying="inline" />
									</div>
								</div>

								{sideBanner ? (
									<div
										ref={sideBannerRef}
										className={cn(
											'sticky right-0 lg:mx-6 transition-all duration-300 ease-in-out',
											{
												'shadow-lg transition-shadow duration-300 bg-white':
													stuck,
												'top-20': scroll === 'up',
												'top-4': scroll === 'down',
											},
										)}
									>
										<Banner bannerData={sideBanner} bannerStyle="side" />
									</div>
								) : (
									<div className="sticky right-0 top-28 max-w-sm px-6 pt-4 pb-6 mx-auto bg-no-repeat bg-cover lg:pb-24 lg:ml-auto lg:mr-0">
										<div className="w-full px-6 py-4 bg-white border border-gray-100 rounded shadow-lg">
											<p className="mb-4 text-xl font-bold leading-tight text-brand-500">
												Ready to join over <Var name="numCompanies" />{' '}
												companies around the world loving BrightHR?
											</p>

											<div>
												<LocaleContent showInLocales={['en']}>
													<>
														<div className="w-full mb-4">
															<LinkButton
																outlineButton
																href="https://app.brighthr.com/lite?utm_campaign=2022-brighthr-lite&utm_source=web&utm_medium=referral&utm_content=blog&utm_term=tryforfree"
																text="Try BrightHR for free"
																size="lg"
															/>
														</div>
														<div className="flex flex-col">
															<Button
																onClick={() => setShowModal(true)}
																text="Book your demo"
																size="lg"
															/>
														</div>
													</>
												</LocaleContent>

												<LocaleContent showInLocales={['ie']}>
													<>
														<div className="w-full mb-4">
															<LinkButton
																outlineButton
																href={buildLink(
																	locale,
																	'product-demo',
																)}
																iconName="video-controls-play"
																text="See it in action"
																size="lg"
															/>
														</div>
														<div className="flex flex-col">
															<Button
																onClick={() => setShowModal(true)}
																text="Book your free demo"
																size="lg"
															/>
														</div>
													</>
												</LocaleContent>

												<LocaleContent showInLocales={['au', 'ca', 'nz']}>
													<div className="flex flex-col w-full mb-4 ">
														<Button
															onClick={() => setShowModal(true)}
															text="Book your free demo"
															size="lg"
														/>
													</div>
												</LocaleContent>
											</div>
										</div>
									</div>
								)}
							</aside>
						</div>
						{relatedPosts && relatedPosts.length > 0 && (
							<div className="max-w-6xl px-4 pb-24 mx-auto mt-6 xl:px-0">
								<h4 className="max-w-2xl mb-4 text-xl font-bold">
									Related posts in {category.name}
								</h4>
								<section className="flex flex-row flex-wrap items-stretch mb-12 -m-3">
									{relatedPosts.map((post) => (
										<div key={post.id} className="flex w-full p-3 lg:w-1/3">
											<BlogCard
												stack
												key={post.id}
												title={post.title}
												slug={post.slug}
												updatedAt={post.meta.updatedAt}
												date={post.date}
												image={post.thumbnail || post.image}
												category={post.category}
												locale={locale}
											>
												{post.contentNode.childMdx.excerpt}
											</BlogCard>
										</div>
									))}
								</section>
							</div>
						)}
					</div>
				</div>
			</ArticleProvider>
		</DefaultLayout>
	);
};

export default BlogPost;

export const query = graphql`
	query blogPost($slug: String, $locale: String!, $categoryId: String!) {
		site {
			siteMetadata {
				title
				siteUrl
				author
			}
		}

		datoCmsBlogPost(locale: { eq: $locale }, slug: { eq: $slug }) {
			seoMetaTags {
				...GatsbyDatoCmsSeoMetaTags
			}
			_allSlugLocales {
				locale
				value
			}
			_allSeoLocales {
				locale
			}
			meta {
				updatedAt
				firstPublishedAt
			}
			category {
				name
				slug
			}
			date
			schema
			id
			originalId
			title
			subtitle
			locale
			slug
			author {
				id
				name
				slug
				jobTitle
				bio
				jobTitle
				breadcrumbText
				linkedin
				twitterX
				facebook
				avatar {
					gatsbyImageData
					alt
				}
			}
			image {
				alt
				fixed(
					width: 1100
					imgixParams: {
						auto: "compress"
						q: 60
						ar: "2:1"
						fit: "crop"
						crop: "entropy"
					}
				) {
					srcSet
					src
				}
				url
			}
			contentNode {
				childMdx {
					body
					timeToRead
					excerpt
				}
			}
			lightningWidget {
				addLightningWidget
				lightningWidgetTitle
				lightningWidgetContentNode {
					childMdx {
						body
					}
				}
				lightningWidgetQuestion
			}
			inlineBanner {
				title
				description
				quote
				quoteText
				quoteCitationPerson
				quoteCitationRole
				deviceType
				deviceImage {
					alt
					gatsbyImageData
				}
				ctaOneText
				ctaOneHref
				ctaTwoText
				ctaTwoHref
			}
			sideBanner {
				title
				description
				quote
				quoteText
				quoteCitationPerson
				quoteCitationRole
				deviceType
				deviceImage {
					alt
					gatsbyImageData
				}
				ctaOneText
				ctaOneHref
				ctaTwoText
				ctaTwoHref
			}
		}

		relatedPosts: allDatoCmsBlogPost(
			filter: {
				category: { id: { eq: $categoryId }, locale: { eq: $locale } }
				slug: { ne: $slug }
			}
			sort: { fields: meta___firstPublishedAt, order: DESC }
			limit: 6
		) {
			nodes {
				...BlogCard
			}
		}
	}
`;
