import { useEffect, useState } from 'react';

const prettifyNumber = (num) => num.toString().padStart(2, '0');

const getDigits = (countDown) => {
	const days = prettifyNumber(Math.floor(countDown / (1000 * 60 * 60 * 24)));
	const hours = prettifyNumber(
		Math.floor((countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
	);
	const minutes = prettifyNumber(Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60)));
	const seconds = prettifyNumber(Math.floor((countDown % (1000 * 60)) / 1000));

	return [days, hours, minutes, seconds];
};

const useCountdown = (targetDate) => {
	const countDownDate = new Date(targetDate).getTime();

	const [countDown, setCountDown] = useState(countDownDate - new Date().getTime());

	useEffect(() => {
		const interval = setInterval(() => {
			setCountDown(countDownDate - new Date().getTime());
		}, 1000);

		return () => clearInterval(interval);
	}, [countDownDate]);

	return getDigits(countDown);
};

export default useCountdown;
