import React from 'react';

const Businesses = ({ header, badges }) => (
	<div>
		<div className="max-w-4xl mx-auto text-center xl:mt-4">
			<h2 className="my-12 text-2xl font-semibold text-center md:text-3xl">{header}</h2>
		</div>

		<div className="text-center">
			{badges.map((logo) => (
				<img
					key={logo.url}
					src={logo.url}
					alt={logo.alt}
					width="90"
					height="90"
					className="inline-block mx-4 my-2 md:my-6 lg:mx-5 xl:my-0"
				/>
			))}
		</div>
	</div>
);

export default Businesses;
