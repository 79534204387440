const attachPaymentToCustomer = async ({
	customerId,
	paymentMethodId,
	PaymentMethodCreationError,
	setProcessing,
}) => {
	const { error, attach } = await fetch('/api/stripe/payment/attach-payment-method', {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			customerId,
			paymentMethodId,
		}),
	}).then((res) => res.json());

	if (error) {
		setProcessing(false);
		throw new PaymentMethodCreationError(error);
	}

	return attach;
};

export default attachPaymentToCustomer;
