import React from 'react';
import { format } from 'date-fns';

const WebinarPopupContent = ({ subHeading, heading, description, CTA, date }) => (
	<>
		<h3 className="text-2xl">{subHeading}</h3>
		<h2 className="mb-2 text-3xl font-bold leading-none min-w-0 md:min-w-[350px] max-w-[550px]">
			{heading}
		</h2>
		{description}
		{date && (
			<p className="mb-4 font-bold leading-tight">
				{format(new Date(date), 'eeee, d MMM, yyyy')}
			</p>
		)}
		{CTA}
	</>
);

export default WebinarPopupContent;
