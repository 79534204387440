import React, { useState } from 'react';
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';

const Ticker = ({ className, ...rest }) => {
	const [viewPortEntered, setViewPortEntered] = useState(false);

	return (
		<CountUp {...rest} start={viewPortEntered ? null : 0} separator="," duration={3}>
			{({ countUpRef }) => (
				<VisibilitySensor
					active={!viewPortEntered}
					onChange={(isVisible) => {
						if (isVisible) {
							setViewPortEntered(true);
						}
					}}
					delayedCall
				>
					<span className={className} ref={countUpRef} />
				</VisibilitySensor>
			)}
		</CountUp>
	);
};

export default Ticker;
