import React from 'react';
import { graphql } from 'gatsby';
import { useLocation } from '@reach/router';
import DefaultLayout from '../layouts/Default';
import NewsArticlePage from '../components/NewsArticlePage';

const PressRelease = ({ data }) => {
	const {
		articleCopy,
		download,
		id,
		articleImage,
		title,
		slug,
		primarySite,
		subtitle,
		newsType,
		itemCountry,
	} = data.multisitePressReleases || data.multisiteAwards || data.multisiteInTheNews;

	const allPressItems = () => {
		if (newsType === 'Awards') {
			return data.allMultisiteAwards.nodes;
		}
		if (newsType === 'PressRelease') {
			return data.allMultisitePressReleases.nodes;
		}
		if (newsType === 'InTheNews') {
			return data.allMultisiteInTheNews.nodes;
		}
	};

	const otherPressItems = allPressItems().filter((article) => article.id !== id);

	const location = useLocation();
	const url = data.site.siteMetadata.siteUrl + location.pathname;

	const latestArticles = otherPressItems
		.sort((a, b) => new Date(b.releaseDate) - new Date(a.releaseDate))
		.slice(0, 3);
	const relatedArticles = otherPressItems;

	return (
		<>
			<NewsArticlePage
				articleImage={articleImage}
				title={title}
				slug={slug}
				primarySite={primarySite}
				subHeading={subtitle}
				latestArticles={latestArticles}
				downloadUrl={download?.url}
				articleCopy={articleCopy}
				relatedArticles={relatedArticles.slice(0, 6)}
				pageType={newsType}
				itemCountry={itemCountry}
				pageUrl={url}
			/>
		</>
	);
};

const WrappedPressReleasePage = (props) => (
	<DefaultLayout fullWidth>
		<PressRelease {...props} />
	</DefaultLayout>
);

export default WrappedPressReleasePage;

export const query = graphql`
	query pressQuery($id: String) {
		site {
			siteMetadata {
				title
				siteUrl
			}
		}
		multisitePressReleases(slug: { ne: null }, id: { eq: $id }) {
			title
			subtitle
			id
			articleImage {
				url
				responsiveImage {
					src
					srcSet
				}
			}
			download {
				url
			}
			articleCopy
			newsType
			slug
			primarySite
		}
		multisiteAwards(slug: { ne: null }, id: { eq: $id, ne: "148029051" }) {
			title
			subtitle
			id
			articleImage {
				url
				responsiveImage {
					src
					srcSet
				}
			}
			articleCopy
			newsType
			slug
			primarySite
		}
		multisiteInTheNews(slug: { ne: null }, id: { eq: $id }) {
			title
			subtitle
			id
			articleImage {
				url
				responsiveImage {
					src
					srcSet
				}
			}
			articleCopy
			newsType
			slug
			primarySite
		}
		allMultisitePressReleases {
			nodes {
				title
				subtitle
				isArticleInternal
				externalArticleLink
				releaseDate
				id
				slug
				newsType
				itemCountry
			}
		}
		allMultisiteAwards {
			nodes {
				title
				subtitle
				isArticleInternal
				externalArticleLink
				releaseDate
				id
				slug
				newsType
				itemCountry
			}
		}
		allMultisiteInTheNews {
			nodes {
				title
				subtitle
				isArticleInternal
				externalArticleLink
				releaseDate
				id
				slug
				newsType
				itemCountry
			}
		}
	}
`;
