import React, { useCallback, useEffect, useState, useRef, useMemo } from 'react';
import { Form, useFormik, FormikProvider } from 'formik';
import { Modal, ModalBody } from '@brighthr/component-modal';

import compare from 'react-fast-compare';
import qs from 'qs';

import { useStripe, useElements, CardNumberElement } from '@stripe/react-stripe-js';
import { navigate, useLocation } from '@reach/router';
import { useDebouncedCallback } from 'use-debounce';
import getEnv from '../../../../config/getEnv';
import { useSessionState, useSessionUpdate } from '../../context/session';
import { fullWithSplitCard as fullSchema } from '../../validation';

import {
	AccountAvailabilityError,
	CustomerSetupError,
	OrderError,
	PaymentMethodCreationError,
	PurchaseError,
} from '../../errors';

import SummaryDetails from '../../components/SummaryDetails';
import PackageDetails from '../../components/PackageDetails';
import BigTotal from '../../components/BigTotal';

import Review from './Section/Review';
import YourDetails from './Section/YourDetails';
import BillingDetails from './Section/BillingDetails';
import CustomerTaxId from './Section/CustomerTaxId';
import CardDetails from './Section/CardDetails';
import TAndC from './Section/TAndC';

import PayCTA from './Section/PayCTA';
import MobileHeader from './Section/MobileHeader';
import DesktopBackButton from './Components/DesktopBackButton';

import updateCustomer from './Services/updateCustomer';
import createPaymentMethod from './Services/createPaymentMethod';
import attachPaymentToCustomer from './Services/attachPaymentToCustomer';
import createSubscription from './Services/createSubscription';
import confirmPaymentIntent from './Services/confirmPaymentIntent';
import retrievePaymentIntent from './Services/retrievePaymentIntent';
import registerCustomer from './Services/registerCustomer';
import prices from './Services/prices';
import createQuote from './Services/createQuote';
import createCustomer from './Services/createCustomer';

import initialFormValues from './utils/initialFormValues';
import handleFormSubmit from './utils/handleFormSubmit';
import triggerFetchPromotionCode from './utils/triggerFetchPromotionCode';

//
// Utils
//

const priceFormatter = (price) => (price / 100).toFixed(2);
const getPriceValue = (prObj, amountOfEmployees) =>
	prObj?.tiers.filter((tier) => amountOfEmployees <= tier.up_to || tier.up_to === null)[0]
		.unit_amount;
// Anyone in the wrong locale should have been redirected before arriving on checkout
// This is best guess at their locale
const localePreference = () => {
	switch (localStorage.getItem('locale-preference')) {
		case 'en':
			return 'GB';
		case 'au':
			return 'AU';
		case 'ca':
			return 'CA';
		case 'ie':
			return 'IE';
		case 'nz':
			return 'NZ';
		default:
			return 'GB';
	}
};

const Payment = () => {
	//
	// Hooks and State
	//

	const session = useSessionState();
	const updateSession = useSessionUpdate();
	const stripe = useStripe();
	const elements = useElements();
	const { search } = useLocation();
	const qsObj = qs.parse(search, { ignoreQueryPrefix: true }); // leave here. Needed below
	const [errorMessage, setErrorMessage] = useState('');
	const [processing, setProcessing] = useState(false);
	const [priceInformation, setPriceInformation] = useState([]);
	// eslint-disable-next-line no-unused-vars
	const [taxInformation, setTaxInformation] = useState({ display_name: 'Tax', percentage: 0 });
	const priceId = window.sessionStorage.getItem('priceId') || qsObj.priceId || '';
	const packageId =
		window.sessionStorage.getItem('packageId') ||
		session.session.package ||
		qsObj.package ||
		'';
	const term = window.sessionStorage.getItem('term') || qsObj.term || 36;
	const emailInput = useRef();
	const promoInput = useRef();
	const [isMobile, setIsMobile] = useState(false);
	const [addressSelected, setAddressSelected] = useState(false);
	const [editAddress, setEditAddress] = useState(false);
	const [show3DS, setShow3DS] = useState(false);
	const [threeDeeUrl, setThreeDeeUrl] = useState('');
	const [authenticationCompleted, setAuthenticationCompleted] = useState(false);
	const [paymentIntentId, setPaymentIntentId] = useState('');
	const [paymentIntentStatus, setPaymentIntentStatus] = useState();
	const [subscriptionObject, setSubscriptionObject] = useState({});
	const [finalPaymentMethodId, setFinalPaymentMethodId] = useState();
	const [pricesLoaded, setPricesLoaded] = useState(false);
	const [promoCodeObj, setPromoCodeObj] = useState(null);
	const [promoCodeError, setPromoCodeError] = useState();
	const [couponId, setCouponId] = useState();
	const [summaryValues, setSummaryValues] = useState({});
	const [quoteId, setQuoteId] = useState({});
	const [customerId, setCustomerId] = useState();
	const [quoteReady, setQuoteReady] = useState(false);
	const [firstQuoteReady, setFirstQuoteReady] = useState(false);
	const [showPromoInput, setShowPromoInput] = useState(false);
	const [taxApplied, setTaxApplied] = useState(false);

	//
	// Formik set up
	//

	const formik = useFormik({
		initialValues: initialFormValues(session, qsObj, packageId, term),
		validationSchema: fullSchema,
		enableReinitialize: false,
		onSubmit: async () =>
			handleFormSubmit(
				// eslint-disable-next-line no-use-before-define
				values,
				// eslint-disable-next-line no-use-before-define
				actions,
				setErrorMessage,
				setProcessing,
				updateSession,
				AccountAvailabilityError,
				CustomerSetupError,
				PurchaseError,
				PaymentMethodCreationError,
				// eslint-disable-next-line no-use-before-define
				buy,
			),
	});
	const { values, errors, actions } = formik;

	//
	// Price Object Filtering and Memo'ing
	//

	const currentPriceObject = useMemo(
		() => priceInformation.find((priceObj) => priceObj.id === priceId),
		[priceId, priceInformation],
	);

	const unitPrice = useMemo(() => {
		const currentUnitPrice = getPriceValue(currentPriceObject, values.amountOfEmployees);
		return priceFormatter(currentUnitPrice);
	}, [currentPriceObject, values.amountOfEmployees]);

	//
	// Price Variables
	//

	const subtotal = priceFormatter(summaryValues.subtotalValue);

	const discountAmount = priceFormatter(summaryValues.discountAmountValue);

	const monthlyTax = priceFormatter(summaryValues.taxValue);
	const taxLabel = `Tax (${summaryValues.taxPercentage}%)`;

	const calcTotalMonthlyPrice = priceFormatter(summaryValues.totalValue);
	const price = calcTotalMonthlyPrice;

	//
	// Session Sync'ing
	//

	const getValues = () => values;
	const getSession = () => session.session;
	const maybeSyncSession = useDebouncedCallback(() => {
		const v = getValues();
		const s = getSession();
		if (compare(v, s)) return;
		updateSession({ ...v });
	}, 500);

	const debouncedMaybeSyncSession = useCallback(() => maybeSyncSession(), [maybeSyncSession]);
	if (!session.initialised) {
		return null;
	}

	if (session?.session?.paymentComplete) {
		navigate('/buy-online/confirmation?returning=true');
		return null;
	}

	//
	// useEffects
	//

	useEffect(() => {
		const browserWidth = window.innerWidth;

		if (browserWidth < 992) {
			setIsMobile(true);
		}
	}, []);

	// Fetch prices from Stripe
	useEffect(() => {
		Promise.all([prices()])
			.then(([pricesData]) => {
				const pricesForEnv = getEnv({
					production: pricesData.data.filter(
						(priceData) =>
							priceData.livemode === true && priceData.billing_scheme === 'tiered',
					),
					default: pricesData.data.filter(
						(priceData) =>
							priceData.livemode === false && priceData.billing_scheme === 'tiered',
					),
				});

				setPriceInformation(pricesForEnv);

				setPricesLoaded(true);
			})
			.catch(() => {
				setPricesLoaded(false);
			});
	}, []);

	// Create a blank customer on page load
	useEffect(() => {
		if (session.session.customerId) {
			setCustomerId(session.session.customerId);
		} else {
			const getCustomerId = async () => {
				await createCustomer({
					countryCode: values.countryCode || localePreference(),
					postState: values.postState,
				}).then((res) => {
					if (res.error) {
						setErrorMessage('Something has gone wrong. Please refresh your browser.');
						return;
					}
					if (
						res.tax?.automatic_tax === 'supported' ||
						res.tax?.automatic_tax === 'not_collecting'
					) {
						setTaxApplied(true);
					}
					setCustomerId(res.id);
					values.customerId = res.id;
					debouncedMaybeSyncSession();
				});
			};
			getCustomerId();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// Create a quote for monthly options once prices received from Stripe
	useEffect(() => {
		if (pricesLoaded && customerId) {
			const generateQuote = async () => {
				await createQuote(currentPriceObject?.id, values.amountOfEmployees, customerId)
					.then((res) => {
						setQuoteId(res.id);
						setSummaryValues({
							...summaryValues,
							subtotalValue: res.amount_subtotal,
							totalValue: res.amount_total,
							taxValue: res.total_details?.amount_tax,
							taxPercentage: Math.round(
								(res.total_details?.amount_tax /
									(res.amount_total - res.total_details?.amount_tax)) *
									100,
							),
						});
						setFirstQuoteReady(true);
					})
					.catch(() => {
						setFirstQuoteReady(false);
					});
			};
			generateQuote();
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pricesLoaded, customerId]); // don't want to include all dependencies as causes infinite loop

	// When we know the customer's address
	// Only run this on page load.
	// We do not want it to fire everytime values.postCode updates
	useEffect(() => {
		if (values.postCode !== '') {
			setEditAddress(true);
		}
		if (values.countryCode !== '') {
			setTaxApplied(true);
		}
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	// Wait for 3DS Authentication to notify us its completed
	useEffect(() => {
		const completeAuthentication = (e) => {
			if (e.data === 'authenticationCompleted') {
				setShow3DS(false);
				setAuthenticationCompleted(true);
			}
		};

		window.addEventListener('message', completeAuthentication);
	}, []);

	// When the customer hits the final "Pay" button...
	const buy = async () => {
		if (!priceId) {
			throw new PurchaseError('PriceId is missing');
		}

		const cardElement = elements.getElement(CardNumberElement);

		await updateCustomer({
			customerId,
			email: values.email,
			telephone: values.telephone,
			firstName: values.firstName,
			lastName: values.lastName,
			companyName: values.companyName,
			countryCode: values.countryCode, // need to pass these values again or they get passed as null
			postState: values.postState,
			CustomerSetupError,
			setProcessing,
		});
		const paymentMethodId = await createPaymentMethod({
			stripe,
			cardElement,
			PaymentMethodCreationError,
			setFinalPaymentMethodId,
			setProcessing,
		});

		await attachPaymentToCustomer({
			customerId,
			paymentMethodId,
			PaymentMethodCreationError,
			setProcessing,
		});

		const subscription = await createSubscription({
			customerId,
			priceId,
			amountOfEmployees: values.amountOfEmployees,
			couponId,
			setProcessing,
		});

		if (subscription.error) {
			// eslint-disable-next-line no-console
			console.log(subscription.error);
			setProcessing(false);
			if (subscription.error.includes(`location isn't recognized`)) {
				setErrorMessage(
					'We are unable to caluclate the tax. The address you have entered is either incorrect or incomplete. Please ensure you have entered your complete Billing address accurately.',
				);
			} else {
				setErrorMessage(subscription.error);
			}

			return;
		}

		const subscriptionInvoicePayment = subscription.latest_invoice.payment_intent;

		setSubscriptionObject(subscription);
		setPaymentIntentId(subscriptionInvoicePayment.id);

		updateSession({
			...session.session,
			paymentIntentId: subscriptionInvoicePayment.id,
		});

		if (subscriptionInvoicePayment.status === 'succeeded') {
			setAuthenticationCompleted(true);
			setPaymentIntentStatus('succeeded');
		}
		if (subscriptionInvoicePayment.status === 'requires_action') {
			// We need to get the PaymentIntent again here.
			// This time we are using the PaymentIntent API rather than the Subscription API,
			// which means we can pass the 'return_url' parameter. This stops the Stripe from
			// using the SDK to handle the 3DS handover.
			// Since moving to Cloudflare we can no longer use the Stripe SDK
			const getPaymentIntent = await confirmPaymentIntent({
				intentId: subscriptionInvoicePayment.id,
				PurchaseError,
				setProcessing,
			});

			if (getPaymentIntent.next_action?.type === 'redirect_to_url') {
				setThreeDeeUrl(getPaymentIntent.next_action.redirect_to_url.url);
				setShow3DS(true);
			} else {
				setProcessing(false);
				throw new PurchaseError(
					'We were unable to take your payment please contact our Sales team on 0800 470 2432',
				);
			}
		}
		if (subscriptionInvoicePayment.status === 'requires_payment_method') {
			setProcessing(false);
			throw new PurchaseError(
				'We were unable to take your payment please contact your bank or card issuer',
			);
		}
	};

	// Handle the 3DS Authentication if required
	useEffect(() => {
		if (authenticationCompleted === true) {
			// Get straight to registration if paymentIntent is already succeeded...
			if (paymentIntentStatus === 'succeeded') {
				registerCustomer({
					customerId,
					paymentMethodId: finalPaymentMethodId,
					priceId,
					packageId,
					term,
					unitPrice,
					subscription: subscriptionObject,
					OrderError,
					updateSession,
					session,
					values,
					navigate,
					setErrorMessage,
					taxPercentage: summaryValues.taxPercentage,
					customerTaxId: values.customerTaxId,
				});
				return;
			}

			// If 3DS Auth has taken place then we need to check the outcome...
			const checkPaymentIntentStatus = async () => {
				await retrievePaymentIntent({ paymentIntentId, PurchaseError, setProcessing }).then(
					(status) => {
						if (status === 'succeeded') {
							registerCustomer({
								customerId,
								paymentMethodId: finalPaymentMethodId,
								priceId,
								packageId,
								term,
								unitPrice,
								subscription: subscriptionObject,
								OrderError,
								updateSession,
								session,
								values,
								navigate,
								setErrorMessage,
								taxPercentage: summaryValues.taxPercentage,
								customerTaxId: values.customerTaxId,
							});
						} else {
							setErrorMessage(
								'Authentication failed. Please try again or contact your bank or card issuer',
							);
							setProcessing(false);
							setAuthenticationCompleted(false);
							setSubscriptionObject({});
							setPaymentIntentId('');
						}
					},
				);
			};
			checkPaymentIntentStatus();
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [authenticationCompleted, paymentIntentStatus]);

	// Promo Code stuff

	const handleFetchPromotionCode = () => {
		if (promoInput.current.value) {
			triggerFetchPromotionCode(promoInput.current.value, {
				setPromoCodeObj,
				setPromoCodeError,
				setQuoteReady,
				quoteId,
				customerId,
				setSummaryValues,
				summaryValues,
				setCouponId,
				values,
				debouncedMaybeSyncSession,
			});
		} else {
			setPromoCodeError('Please enter a promotion code');
			window.dataLayer.push({
				event: 'Buy Online Promo Code',
				codeValid: 'invalid',
				errorMessage: 'Please enter a promotion code',
			});
		}
	};

	const handlePromoCodeRemoval = () => {
		// Reset PromoCode states
		setPromoCodeObj(null);
		setPromoCodeError(null);
		setCouponId(null);

		// Create a brand new Quote
		// ... can't remove a discount from a Quote. Can only remove from Customer or Subscription
		setQuoteReady(false);
		const generateQuote = async () => {
			await createQuote(currentPriceObject?.id, values.amountOfEmployees, customerId)
				.then((res) => {
					setQuoteId(res.id);
					setSummaryValues({
						...summaryValues,
						subtotalValue: res.amount_subtotal,
						totalValue: res.amount_total,
						taxValue: res.total_details?.amount_tax,
						taxPercentage: Math.round(
							(res.total_details?.amount_tax /
								(res.amount_total - res.total_details?.amount_tax)) *
								100,
						),
					});
					setQuoteReady(true);
				})
				.catch(() => {
					setQuoteReady(false);
				});
		};
		generateQuote();

		values.promoCode = '';
		values.discountPercent = '';
		debouncedMaybeSyncSession();
	};

	useEffect(() => {
		if (firstQuoteReady) {
			if (session.session.promoCode) {
				triggerFetchPromotionCode(session.session.promoCode, {
					setPromoCodeObj,
					setPromoCodeError,
					setQuoteReady,
					quoteId,
					customerId,
					setSummaryValues,
					summaryValues,
					setCouponId,
					values,
					debouncedMaybeSyncSession,
				}).then((obj) => {
					setShowPromoInput(true);

					if (obj.error || obj.active === false) {
						promoInput.current.value = session.session.promoCode;
						setQuoteReady(true);

						values.promoCode = '';
						values.discountPercent = '';
						debouncedMaybeSyncSession();
					}
				});
			} else {
				setQuoteReady(true);
			}
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [firstQuoteReady]); // only do this on page load

	// Components used in multiple places - mobile vs desktop
	const SummaryDetailsSection = (
		<SummaryDetails
			unitPrice={unitPrice}
			subtotal={subtotal}
			quoteReady={quoteReady}
			taxLabel={taxLabel}
			tax={monthlyTax}
			price={price}
			promoCodeObj={promoCodeObj}
			promoInput={promoInput}
			promoCodeError={promoCodeError}
			handlePromoCodeRemoval={handlePromoCodeRemoval}
			setPromoCodeError={setPromoCodeError}
			discountAmount={discountAmount}
			handleFetchPromotionCode={handleFetchPromotionCode}
			showPromoInput={showPromoInput}
			setShowPromoInput={setShowPromoInput}
			taxApplied={taxApplied}
			term={term}
		/>
	);

	const PackageDetailsSection = (
		<PackageDetails
			packageName={window.sessionStorage.getItem('packageName') || 'Connect'}
			packageDescription={window.sessionStorage.getItem('packageDescription') || ''}
		/>
	);

	return (
		<FormikProvider value={formik}>
			<div className="flex flex-col lg:flex-row lg:flex-nowrap">
				{isMobile && (
					<MobileHeader>
						{PackageDetailsSection}
						{SummaryDetailsSection}
					</MobileHeader>
				)}

				<div className="p-5 pt-20 bg-gray-200 lg:pt-12 lg:px-12 lg:max-w-2xl lg:w-45% lg:flex lg:flex-col lg:items-end">
					<DesktopBackButton />
					<div className="lg:max-w-md lg:w-full lg:bg-white lg:rounded-lg lg:shadow-lg">
						<div className="lg:p-5">
							<div className="hidden lg:block">{PackageDetailsSection}</div>
							<BigTotal
								price={price}
								quoteReady={quoteReady}
								errorMessage={errorMessage}
							/>
							{!isMobile && (
								<div className="hidden lg:block">{SummaryDetailsSection}</div>
							)}
						</div>
					</div>

					<Review />
				</div>
				<div className="p-5 lg:w-55% lg:flex lg:flex-row lg:justify-center lg:pt-12 lg:px-12">
					<div className="lg:max-w-xl">
						<h1 className="max-w-md mt-3 text-lg font-bold leading-7 text-gray-900 mb-7 md:mt-0 md:text-4xl md:leading-10 md:mb-10 ">
							You&apos;re one step away from BrightHR
						</h1>

						<Form className="space-y-6" data-cy="paymentForm" disabled>
							<YourDetails
								handleBlur={formik.handleBlur}
								debouncedMaybeSyncSession={debouncedMaybeSyncSession}
								emailInput={emailInput}
								errorMessage={errors.email} // hook into error.email string for this
							/>
							<BillingDetails
								addressSelected={addressSelected}
								companyName={values.companyName}
								setAddressSelected={setAddressSelected}
								setEditAddress={setEditAddress}
								editAddress={editAddress}
								handleBlur={formik.handleBlur}
								handleChange={formik.handleChange}
								setFieldValue={formik.setFieldValue}
								debouncedMaybeSyncSession={debouncedMaybeSyncSession}
								quoteId={quoteId}
								customerId={customerId}
								values={values}
								setQuoteReady={setQuoteReady}
								setSummaryValues={setSummaryValues}
								summaryValues={summaryValues}
								setTaxApplied={setTaxApplied}
								localePreference={localePreference()}
							/>
							<CustomerTaxId
								handleBlur={formik.handleBlur}
								debouncedMaybeSyncSession={debouncedMaybeSyncSession}
							/>
							<CardDetails
								setFieldValue={formik.setFieldValue}
								setFieldError={formik.setFieldError}
								setErrorMessage={setErrorMessage}
								setFieldTouched={formik.setFieldTouched}
							/>
							<TAndC
								debouncedMaybeSyncSession={debouncedMaybeSyncSession}
								isSubmitting={formik.isSubmitting}
							/>
							<PayCTA
								isSubmitting={formik.isSubmitting}
								processing={processing}
								values={values}
								setEditAddress={setEditAddress}
								setAddressSelected={setAddressSelected}
								isValid={formik.isValid}
								quoteReady={quoteReady}
								totalPrice={price}
								errorMessage={errorMessage}
							/>
						</Form>
						<p className="hidden">{priceId}</p>
					</div>
				</div>
			</div>
			{show3DS && (
				<Modal width="base">
					<ModalBody>
						<div data-testid="authModal3DS" className="h-[50vh]">
							<iframe src={threeDeeUrl} title="3DS" className="w-full h-full" />
						</div>
					</ModalBody>
				</Modal>
			)}
		</FormikProvider>
	);
};

export default Payment;
