import React from 'react';
import { graphql } from 'gatsby';
import DefaultLayout from '../layouts/Default';
import LeadershipProfile from '../components/Leadership/Profile';
import { buildLink } from '../utils/locale';
import StaticCTA from '../components/StaticCTA';

const LeadershipProfilePage = ({ data, pageContext }) => {
	const { locale } = pageContext;

	return (
		<>
			<DefaultLayout>
				<div className="mx-auto">
					<div className="flex flex-wrap gap-3 mt-6 mb-12">
						<a href={buildLink(locale, 'our-leadership')}>
							<p className="text-brand-500">Our Leadership</p>
						</a>
						<p className="text-brand-500">{'>'}</p>
						<p>{data.datoCmsLeadershipProfile.name}</p>
					</div>
					<LeadershipProfile profileData={data.datoCmsLeadershipProfile} />
				</div>
				<div className="my-8 lg:my-20">
					<StaticCTA locale={locale} />
				</div>
			</DefaultLayout>
		</>
	);
};

export default LeadershipProfilePage;

export const query = graphql`
	query leadershipProfileQuery($slug: String, $locale: String!) {
		datoCmsLeadershipProfile(locale: { eq: $locale }, slug: { eq: $slug }) {
			name
			companyRole
			slug
			tiktok
			twitter
			linkedin
			quoteNode {
				childMdx {
					body
				}
			}
			quoteTwoNode {
				childMdx {
					body
				}
			}
			descriptionNode {
				childMdx {
					body
				}
			}
			image {
				gatsbyImageData(layout: CONSTRAINED, width: 440)
				alt
			}
		}
	}
`;
