import { graphql } from 'gatsby';
import BookADemo from '../components/BookADemo';

export default BookADemo;

export const query = graphql`
	query DemoPage($id: String, $locale: String!) {
		datoCmsDemoPage(locale: { eq: $locale }, id: { eq: $id }) {
			id
			locale
			_allSlugLocales {
				locale
			}
			seoMetaTags {
				...GatsbyDatoCmsSeoMetaTags
			}
			isStarlingDemoPage
			hideCustomerMessage
			copyNode {
				childMdx {
					body
				}
			}
			termsAndConditionsNode {
				childMdx {
					body
				}
			}
			termsAndConditions
			heading
			ctaText
			formImageDesktop {
				gatsbyImageData(layout: CONSTRAINED, width: 370, imgixParams: { auto: "compress" })
				url
				alt
			}
			formImageMobile {
				gatsbyImageData(layout: CONSTRAINED, width: 716, imgixParams: { auto: "compress" })
				url
				alt
			}
			textColor
			trustBadges {
				url
				alt
			}
			badge {
				... on DatoCmsBadgefrom {
					id
					price
					description
					internal {
						type
					}
				}
				... on DatoCmsBadgeoffer {
					id
					internal {
						type
					}
					offer
					description
				}
			}
			leadSource
		}
	}
`;
