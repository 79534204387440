import React from 'react';

import { Field, ErrorMessage } from 'formik';

export default ({ debouncedMaybeSyncSession, isSubmitting }) => (
	<>
		<label htmlFor="tandc" className="flex w-max hover:cursor-pointer">
			<Field
				name="tandc"
				id="tandc"
				type="checkbox"
				className="flex-shrink-0 w-5 h-5 mr-3 hover:cursor-pointer"
				disabled={isSubmitting}
				onFocus={() => debouncedMaybeSyncSession()}
			/>
			<span>
				I accept the{' '}
				<a href="/terms" className="text-sm text-brand-500" target="_blank">
					T&amp;Cs
				</a>
			</span>
		</label>
		<ErrorMessage component="p" name="tandc" className="mt-2 text-legacy-error" />
	</>
);
