import React from 'react';
import CopyBlock from '../../../../components/CopyBlock';

const PackageDetails = ({ packageName, packageDescription }) => (
	<div className="pb-5 mb-3 border-b border-gray-400">
		<h3 className="mb-0 text-lg font-bold leading-7">Package:</h3>
		<div className="mb-1 text-gray-700">
			<strong data-cy="currentPackage">{packageName}</strong> -{' '}
			{packageDescription && <CopyBlock>{packageDescription}</CopyBlock>}
		</div>
	</div>
);

export default PackageDetails;
