import React from 'react';
import cn from 'classnames';

export const FilterButton = ({ children, active, href }) => (
	<li className="w-full md:w-auto">
		<a
			className={cn(
				'md:px-6 md:py-3 py-1 px-3 mb-2 md:mb-0 rounded font-bold w-full  md:w-auto  focus:outline-none text-gray-700 flex flex-row items-center focus:ring-2',
				{
					'bg-brand-200': active,
				},
			)}
			href={href}
		>
			{children}
		</a>
	</li>
);

const FilterBar = ({ filter, children, searchInput }) => (
	<ul className="flex-wrap w-full mb-8 md:flex">
		<FilterButton
			active={!filter}
			href={`?page=1${searchInput ? `&search=${searchInput}` : ''}`}
		>
			<svg
				width="16"
				height="16"
				viewBox="0 0 16 16"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
				className="mr-2"
			>
				<path
					d="M15.8281 6.26562C15.7552 6.18229 15.6667 6.11979 15.5625 6.07812C15.4688 6.02604 15.3646 6 15.25 6H14.625V3.75C14.625 3.54167 14.5521 3.36458 14.4062 3.21875C14.2604 3.07292 14.0833 3 13.875 3H13.25V0.75C13.25 0.541667 13.1771 0.364583 13.0312 0.21875C12.8854 0.0729167 12.7083 0 12.5 0H3.5C3.29167 0 3.11458 0.0729167 2.96875 0.21875C2.82292 0.364583 2.75 0.541667 2.75 0.75V3H2.125C1.91667 3 1.73958 3.07292 1.59375 3.21875C1.44792 3.36458 1.375 3.54167 1.375 3.75V6H0.75C0.635417 6 0.526042 6.02604 0.421875 6.07812C0.328125 6.11979 0.244792 6.18229 0.171875 6.26562C0.0989583 6.34896 0.046875 6.44792 0.015625 6.5625C-0.00520833 6.66667 -0.00520833 6.77083 0.015625 6.875L1.51562 15.375C1.54688 15.5625 1.63021 15.7135 1.76562 15.8281C1.91146 15.9427 2.07292 16 2.25 16H13.75C13.9271 16 14.0833 15.9427 14.2188 15.8281C14.3646 15.7135 14.4531 15.5625 14.4844 15.375L15.9844 6.875C16.0052 6.77083 16 6.66667 15.9688 6.5625C15.9479 6.44792 15.901 6.34896 15.8281 6.26562ZM4.25 1.5H11.75V3H4.25V1.5ZM2.875 4.5H13.125V6H2.875V4.5ZM13.125 14.5H2.875L1.64062 7.5H14.3594L13.125 14.5ZM11.125 8.875C10.9167 8.875 10.7396 8.94792 10.5938 9.09375C10.4479 9.23958 10.375 9.41667 10.375 9.625V10.75H5.625V9.625C5.625 9.41667 5.55208 9.23958 5.40625 9.09375C5.26042 8.94792 5.08333 8.875 4.875 8.875C4.66667 8.875 4.48958 8.94792 4.34375 9.09375C4.19792 9.23958 4.125 9.41667 4.125 9.625V10.75C4.125 11.1667 4.27083 11.5208 4.5625 11.8125C4.85417 12.1042 5.20833 12.25 5.625 12.25H10.375C10.7917 12.25 11.1458 12.1042 11.4375 11.8125C11.7292 11.5208 11.875 11.1667 11.875 10.75V9.625C11.875 9.41667 11.8021 9.23958 11.6562 9.09375C11.5104 8.94792 11.3333 8.875 11.125 8.875Z"
					fill="#0057AD"
				/>
			</svg>
			All
		</FilterButton>

		{children}
	</ul>
);

export default FilterBar;
