import React from 'react';
import useCountdown from '../../hooks/useCountdown';

function DateTimeDisplay({ value, type }) {
	return (
		<span className="flex space-x-1">
			<span className="font-bold">{value}</span>
			<span>{type}</span>
		</span>
	);
}

function ClockDisplay({ days, hours, minutes, seconds }) {
	return (
		<span className="inline-flex flex-row flex-wrap space-x-1 text-xs md:text-sm">
			{days > 0 && <DateTimeDisplay value={days} type="days" />}
			<DateTimeDisplay value={hours} type="hours" />
			<DateTimeDisplay value={minutes} type="mins" />
			<DateTimeDisplay value={seconds} type="secs" />
		</span>
	);
}

function CountdownTimer({ targetDate }) {
	const [days, hours, minutes, seconds] = useCountdown(targetDate);

	if (days + hours + minutes + seconds <= 0) {
		return '';
	}
	return <ClockDisplay days={days} hours={hours} minutes={minutes} seconds={seconds} />;
}

export default CountdownTimer;
