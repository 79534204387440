import React, { useState } from 'react';
import Icon from '@brighthr/component-icon';

function HeaderLink({ link, collapsibleSubmenus = false, displayTextAppend }) {
	const [expanded, setExpanded] = useState(false);
	const { displayText, linkUrl, description, sublinks } = link;
	// We may want to update <a> tags to Next Link tags when all nav components are on the Next repo

	if (collapsibleSubmenus) {
		return (
			<div
				className={`flex w-full py-2 pl-5 ${
					expanded ? 'mb-3 rounded-md bg-brand-100 bg-opacity-50 shadow-lg' : 'bg-none'
				}`}
				key={displayText}
			>
				<div className="w-full">
					{sublinks && sublinks.length > 0 ? (
						<button
							type="button"
							onClick={() => setExpanded(!expanded)}
							className="flex justify-between w-full gap-3 pt-1"
						>
							<p className="font-semibold text-left text-16 text-primary-700">
								{displayText}
							</p>
							<Icon
								className={`mr-3 inline-block h-4 w-4 fill-primary-700 duration-200 ${
									expanded && 'rotate-180'
								}`}
								iconName="chevron-thick-down"
								size={14}
							/>
						</button>
					) : (
						<a
							className="font-semibold transition-colors text-16 text-primary-700 hover:text-primary-400"
							href={linkUrl}
						>
							{displayText}
						</a>
					)}
					<p className="hidden text-sm text-neutral-500 lg:block">{description}</p>

					{expanded ? (
						<ul className="flex flex-col gap-3 mt-5">
							{sublinks.map((sublink) => (
								<li key={sublink.text} className="flex items-center gap-4">
									<a
										className="text-sm transition-colors text-primary-700 hover:text-primary-400"
										key={sublink.text}
										href={sublink.href}
									>
										{sublink.text}
									</a>
								</li>
							))}
							{linkUrl && (
								<li>
									<a
										className="text-sm font-semibold transition-colors text-primary-700 hover:text-primary-400"
										href={linkUrl}
									>
										{displayText} {displayTextAppend}
									</a>
								</li>
							)}
						</ul>
					) : null}
				</div>
			</div>
		);
	}
	return (
		<div className="flex w-full" key={displayText}>
			<div className="w-full">
				<div className="flex justify-between w-full">
					<div className="flex items-center gap-1 pt-1.5 lg:pt-0">
						{linkUrl ? (
							<a
								className="font-semibold transition-colors text-16 text-primary-700 hover:text-primary-400"
								href={linkUrl}
							>
								{displayText}
							</a>
						) : (
							<p className="font-semibold text-primary-700">{displayText}</p>
						)}
					</div>
				</div>

				<p className="hidden text-neutral-500 lg:block">{description}</p>

				<ul className="flex flex-col gap-3 mt-2">
					{sublinks.map((subLink) => (
						<li key={subLink.text} className="flex items-center justify-between gap-4">
							<a
								className="text-16 text-primary-700"
								key={subLink.text}
								href={subLink.href}
							>
								{subLink.text}
							</a>
						</li>
					))}
				</ul>
			</div>
		</div>
	);
}

export default HeaderLink;
