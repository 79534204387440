import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import LazyLoad from 'react-lazyload';
import CopyBlock from '../../CopyBlock';
import Container from '../../Container';
import Blob from '../../../images/blobs/blob-light-blue.svg';
import TransparentQuote from '../../../images/home/transparent-quote.svg';

const SlimImageWithCopy = ({ imgSrc, alt, text, header }) => (
	<section className="relative my-12 lg:my-24 last:mb-0">
		<Container className="relative p-6 pt-8 overflow-visible lg:pb-16">
			<div className="absolute -translate-x-24 translate-y-8 lg:-translate-x-56 lg:-translate-y-24">
				<img src={Blob} type="image/svg+xml" alt="Blob" width="624" height="521" />
			</div>
			<h2 className="relative mb-10 text-xl font-bold leading-none last:mb-0 md:text-2xl">
				{header}
			</h2>
			<div className="relative items-center p-8 mb-4 bg-white rounded-lg shadow-2xl">
				<div className="absolute left-2 top-2">
					<img
						src={TransparentQuote}
						width="38"
						height="37"
						type="image/svg+xml"
						alt="Quote"
					/>
				</div>
				<div className="relative flex flex-col items-center lg:flex-row">
					<div className="w-full lg:mb-4 lg:w-3/4">
						<CopyBlock>{text}</CopyBlock>
					</div>
					<div className="w-full my-6 lg:my-0 lg:py-0 lg:w-1/4 lg:pl-20">
						<LazyLoad once>
							<GatsbyImage image={imgSrc} className="max-w-lg mx-auto" alt={alt} />
						</LazyLoad>
					</div>
				</div>
				<a
					className="pt-6 underline text-brand-500 hover:text-brand-200"
					href="https://www.brighthr.com/blog/all-things-bright/ukhospitality-chooses-brighthr-as-their-hr-experts/"
				>
					Find out more
				</a>
			</div>
		</Container>
	</section>
);

export default SlimImageWithCopy;
