const retrievePaymentIntent = async ({ paymentIntentId, PurchaseError, setProcessing }) => {
	const paymentIntent = await fetch('/api/stripe/payment/retrieve-payment-intent', {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			intentId: paymentIntentId,
		}),
	})
		.then((res) => res.json())
		.catch(() => {
			setProcessing(false);
			throw new PurchaseError(
				'We were unable to take your payment please contact our Sales team on 0800 470 2432',
			);
		});

	return paymentIntent.status;
};

export default retrievePaymentIntent;
