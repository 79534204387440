import React, { useState, useEffect, useRef } from 'react';
import cn from 'classnames';
import { graphql, useStaticQuery } from 'gatsby';
import AnnouncementBar from './AnnouncementBar';
import MiniNav from './MiniNav/index';
import MainNav from '../MainNav';
import scrollDirection from '../../utils/scrollDirection';
import { LOCALE_PATHS } from '../../utils/locale';
import { useLocale } from '../../context/Locale';

export const COLORS = {
	WHITE: 'white',
};

const Header = () => {
	const headerData = useStaticQuery(graphql`
		query HeaderLinks {
			allDatoCmsHeader {
				nodes {
					locale
					headerCopy
					pricingDisplayText
					pricingLinkUrl
					ourProducts {
						id
						description
						displayText
						hideOnMobile
						linkUrl
						icon
						description
						subheading
						new
						sublinks {
							text
							href
						}
					}
					resources {
						id
						displayText
						hideOnMobile
						linkUrl
						icon
						description
						subheading
						new
						sublinks {
							text
							href
						}
					}
					whoWeServeLinks {
						id
						displayText
						linkUrl
						description
						image {
							url
							alt
						}
					}
					whatsNewCopyNode {
						childMdx {
							body
						}
					}
					featured {
						copyNode {
							childMdx {
								body
							}
						}
						ctaLink
						ctaText
						id
						desktopImage {
							url
							alt
						}
						mobileImage {
							url
							alt
						}
						title
						placement
					}
				}
			}
			allDatoCmsAnnouncementBanner {
				...AnnouncementBannerFragment
			}
		}
	`);
	const locale = useLocale();
	const localeData = headerData.allDatoCmsHeader.nodes.filter(
		(node) => LOCALE_PATHS[node.locale] === locale,
	)[0];
	const announcementBannerlocaleData = headerData.allDatoCmsAnnouncementBanner.nodes.filter(
		(node) => LOCALE_PATHS[node.locale] === locale,
	)[0];
	const [dropDown, setDropDown] = useState(false);
	const [isMobile, setIsMobile] = useState(false);
	const headerRef = useRef();

	const scroll = scrollDirection();

	useEffect(() => {
		let windowWidth = window.innerWidth;

		const resetExpandos = () => {
			if (window.innerWidth !== windowWidth) {
				setDropDown(null);
				windowWidth = window.innerWidth;
			}
			setIsMobile(windowWidth <= 1279);
		};

		setIsMobile(windowWidth <= 1279);

		window.addEventListener('resize', resetExpandos);

		return () => window.removeEventListener('resize', resetExpandos);
	}, []);

	useEffect(() => {
		const handleClick = (e) => {
			if (!headerRef.current || headerRef.current.contains(e.target)) return;

			setDropDown(false);
		};

		document.addEventListener('mousedown', handleClick);

		return () => {
			document.removeEventListener('mousedown', handleClick);
		};
	}, []);

	return (
		<header
			ref={headerRef}
			className={cn('ease-in-out transition z-nav-dropdown shadow-lg w-full sticky top-0 ', {
				'-translate-y-full shadow-none': scroll === 'down' && isMobile,
				'translate-y-0 fixed': scroll === 'up' && dropDown,
				'translate-y-0 fixed w-full overflow-y-scroll max-h-[95vh] shadow-2xl':
					dropDown && isMobile,
				'-translate-y-8': scroll === 'up' && isMobile,
			})}
		>
			<AnnouncementBar bannerData={announcementBannerlocaleData} />
			<MiniNav />
			<MainNav
				headerData={localeData}
				dropDown={dropDown}
				isMobile={isMobile}
				setDropDown={setDropDown}
			/>
		</header>
	);
};

export default Header;
