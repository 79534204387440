import React from 'react';
import cn from 'classnames';
import LazyLoad from 'react-lazyload';
import { GatsbyImage } from 'gatsby-plugin-image';
import format from 'date-fns/format';
import { buildLink } from '../../utils/locale';
import { getSlug } from '../../utils/datoCMS';

const ResourceCards = ({
	resources,
	className,
	cardClassName,
	imgClassName,
	locale,
	slugBase,
	articleTree,
}) => (
	<section className={className}>
		{resources.map(({ id, contentNode, bodyNode, title, slug, category, image, meta }) => {
			let fullSlug = '';

			if (articleTree) {
				fullSlug = `${slugBase}/${getSlug(articleTree, id)}`;
			} else if (category) {
				fullSlug = `${slugBase}/${category?.slug}/${slug}`;
			} else {
				fullSlug = `${slugBase}/${slug}`;
			}

			const timeToRead = contentNode?.childMdx.timeToRead || bodyNode?.childMdx.timeToRead;

			return (
				<div key={id} className={cardClassName}>
					<div className="flex flex-col w-full h-full overflow-hidden transition duration-100 border border-b-0 border-gray-400 rounded shadow hover:shadow-lg hover:-translate-y-2 hover:scale-105">
						<div className="relative">
							<LazyLoad once>
								<a
									href={buildLink(locale, fullSlug)}
									className={cn(
										'relative mx-auto block md:flex md:items-stretch overflow-hidden h-full',
										imgClassName,
									)}
								>
									{image.gatsbyImageData ? (
										<GatsbyImage
											image={image.gatsbyImageData}
											loading="eager"
											objectFit="cover"
											alt={image.alt || title}
											class="w-full"
										/>
									) : (
										<img
											className="object-center w-full h-full pointer-events-none object-fit"
											src={image.fixed.src}
											srcSet={image.fixed.srcSet}
											alt={image.fixed.alt}
										/>
									)}
								</a>
							</LazyLoad>
						</div>
						<div className="flex flex-col h-full p-5 bg-white border-t-0 border-gray-400">
							{meta?.publishedAt && (
								<p className="mb-3 text-sm leading-5 text-gray-700">
									{format(new Date(meta.publishedAt), 'eeee, MMM dd, yyyy')}
								</p>
							)}
							<a href={buildLink(locale, fullSlug)}>
								<h3 className="mb-2 text-lg font-bold">{title}</h3>
							</a>

							<div className="flex flex-col mt-auto">
								{timeToRead && (
									<p className="mt-8 mb-4 text-sm text-gray-500">
										{timeToRead} MIN READ
									</p>
								)}

								{category && (
									<a
										href={buildLink(locale, `${slugBase}/${category?.slug}`)}
										className="relative self-start px-4 py-1 text-sm transition duration-300 ease-out bg-gray-200 rounded-full hover:bg-brand-400 hover:text-white"
									>
										<strong>{category?.name}</strong>
									</a>
								)}
							</div>
						</div>
					</div>
				</div>
			);
		})}
	</section>
);

export default ResourceCards;
