import React, { useState, useContext, useCallback, useEffect } from 'react';
import { useLocation } from '@reach/router';
import Cookies from 'js-cookie';
import { getLocale, DEFAULT_LOCALE, LOCALE_PATHS } from '../utils/locale';

const LocaleContext = React.createContext();
const LocaleUpdateContext = React.createContext();

export const COOKIE_NAME = 'nf_country';

export function LocaleProvider({ children }) {
	const location = useLocation();
	const [locale, setLocale] = useState(getLocale(location.pathname));

	function langToUpperCase(languageSelector) {
		const browserLangCode = languageSelector.split(/en-/gm);
		if (browserLangCode.length !== 2) {
			return null;
		}
		return `en-${browserLangCode[1].toUpperCase()}`;
	}

	const lang =
		// eslint-disable-next-line no-nested-ternary
		typeof window !== 'undefined'
			? window.navigator.languages && window.navigator.languages.length
				? LOCALE_PATHS[langToUpperCase(window.navigator.languages[0])] ?? DEFAULT_LOCALE
				: (LOCALE_PATHS[window.navigator.userLanguage] ?? DEFAULT_LOCALE) ||
				  (LOCALE_PATHS[langToUpperCase(window.navigator.language)] ?? DEFAULT_LOCALE) ||
				  (LOCALE_PATHS[window.navigator.browserLanguage] ?? DEFAULT_LOCALE) ||
				  DEFAULT_LOCALE
			: DEFAULT_LOCALE;

	const updateLocale = useCallback((newLocale = lang, urlPath = lang, redirect = true) => {
		Cookies.set(COOKIE_NAME, newLocale);
		localStorage.setItem('locale-preference', newLocale);

		async function urlExists(url, redirectLocale) {
			// this won't work with gatsby dev, 404 pages return 200 response instead of 404 in this env. Gatsby serve & prod env does return 404 response
			const response = await fetch(url);

			if (response.ok) {
				window.location.assign(url);
			} else {
				window.location.assign(`${redirectLocale}/`);
			}
		}

		if (redirect) {
			const LOCALE_REGEX = /\/(au|nz|ie|ca)(?:\/|$)/i;
			const { origin, pathname } = window.location;

			const newPath = pathname.replace(LOCALE_REGEX, '/');
			const newLocaleMutated = newLocale === DEFAULT_LOCALE ? '' : `/${newLocale}`;
			const newUrl = `${origin}${newLocaleMutated}${newPath}`;

			urlExists(newUrl, newLocaleMutated);
		} else {
			setLocale(urlPath);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		const pathLocale = getLocale(location.pathname);

		if (Cookies.get(COOKIE_NAME) !== locale) {
			updateLocale(Cookies.get(COOKIE_NAME), pathLocale, false);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [locale, updateLocale]);

	return (
		<LocaleUpdateContext.Provider value={updateLocale}>
			<LocaleContext.Provider value={locale}>{children}</LocaleContext.Provider>
		</LocaleUpdateContext.Provider>
	);
}

export const useLocale = () => useContext(LocaleContext);
export const useLocaleUpdate = () => useContext(LocaleUpdateContext);

export default LocaleContext;
