import { useState, useEffect } from 'react';
import throttle from 'lodash.throttle';

const scrollDirection = () => {
	const [scroll, setScroll] = useState('');

	useEffect(() => {
		let lastScrollTop = 0;
		const scrolldirection = throttle(() => {
			const scrollPosition = window.pageYOffset || document.documentElement.scrollTop;
			if (scrollPosition < 150) {
				setScroll('top');
			} else if (scrollPosition > lastScrollTop && scrollPosition > 150) {
				setScroll('down');
			} else {
				setScroll('up');
			}
			lastScrollTop = scrollPosition <= 0 ? 0 : scrollPosition;
		}, 100);

		document.addEventListener('scroll', scrolldirection);

		return () => document.removeEventListener('scroll', scrolldirection);
	}, []);

	return scroll;
};
export default scrollDirection;
