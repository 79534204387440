const isValidNode = (node) => {
	if (!node.slug) {
		return false;
	}
	if (node.root === false) {
		if (node.parent.root === true) {
			if (node.parent.slug === null) {
				return false;
			}
			return true;
		}
		if (node.parent.parent.root === true) {
			if (node.parent.parent.slug === null) {
				return false;
			}
			return true;
		}
		if (node.parent.parent.parent.root === true) {
			if (node.parent.parent.parent.slug === null) {
				return false;
			}
			return true;
		}
		if (node.parent.parent.parent.parent.root === true) {
			if (node.parent.parent.parent.parent.slug === null) {
				return false;
			}
			return true;
		}
		if (node.parent.parent.parent.parent.parent.root === true) {
			if (node.parent.parent.parent.parent.parent.slug === null) {
				return false;
			}
			return true;
		}
	}
	return true;
};

function listToTree(list) {
	const validatedList = [];
	for (let i = 0; i < list.length; i++) {
		if (isValidNode(list[i])) {
			validatedList.push(list[i]);
		}
	}
	const copy = validatedList.slice(0);
	const map = {};
	let node;
	const roots = [];
	let i;
	for (i = 0; i < copy.length; i += 1) {
		map[copy[i].id] = i; // initialize the map
		copy[i].children = []; // initialize the children
	}
	for (i = 0; i < copy.length; i += 1) {
		node = copy[i];
		if (node.parent !== null) {
			const parentId = node.parent.id;
			const parentNode = map[parentId];
			if (parentNode !== undefined) {
				copy[parentNode].children.push(node);
			}
		} else {
			roots.push(node);
		}
	}
	return [roots, copy];
}

function searchTree(element, nodeId) {
	if (element.id === nodeId) {
		return element;
	}
	if (Array.isArray(element)) {
		let i;
		let result = null;
		for (i = 0; result == null && i < element.length; i++) {
			result = searchTree(element[i], nodeId);
		}
		return result;
	}
	if (element.children != null) {
		let i;
		let result = null;
		for (i = 0; result == null && i < element.children.length; i++) {
			result = searchTree(element.children[i], nodeId);
		}
		return result;
	}
	return null;
}

const getAlternateLocales = (allPagesArray, pageId) => {
	const alternateLocales = allPagesArray.filter((page) => page.id.includes(pageId));

	return alternateLocales;
};

const getSiblings = (elementId, allPagesTree) => {
	const element = searchTree(allPagesTree, elementId);
	if (element === null) {
		return null;
	}
	if (element.parent === null) {
		return element.children;
	}
	const parent = searchTree(allPagesTree, element.parent.id);
	const allSiblings = parent.children;
	const filteredSiblings = allSiblings.filter((sibling) => sibling.id !== elementId);
	return filteredSiblings;
};

function getBreadcrumbs(tree, nodeId) {
	const crumbs = [];
	let n = searchTree(tree, nodeId);
	while (n) {
		crumbs.push({
			id: n.id,
			title: n.title,
			breadcrumbText: n.breadcrumbText,
			slug: n.slug,
		});

		n = searchTree(tree, n.parent && n.parent.id);
	}

	return crumbs.reverse();
}

function getSlug(tree, nodeId) {
	const crumbs = getBreadcrumbs(tree, nodeId);

	return crumbs.map((c) => c.slug).join('/');
}

module.exports = {
	listToTree,
	searchTree,
	getAlternateLocales,
	getSiblings,
	isValidNode,
	getBreadcrumbs,
	getSlug,
};
