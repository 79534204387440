import cn from 'classnames';
import React from 'react';
import MenuDropdown from '../../MenuDropdown';
import DesktopMenuComponent from '../DesktopMenuComponent';
import MenuComponent from '../MenuComponent';

function Menu({
	featured,
	linksData,
	dropDown,
	isMobile,
	setDropDown,
	menuTitle,
	displayTextAppend,
	largerColumn = false,
}) {
	const isActive = dropDown === menuTitle;
	return (
		<div className="relative">
			<MenuDropdown
				isMobile={isMobile}
				setDropDown={setDropDown}
				title={menuTitle}
				isActive={isActive}
			/>
			{isActive && (
				<>
					<div className="absolute left-[30px] top-[46px] z-10 hidden xl:block">
						<svg
							width="27"
							height="20"
							viewBox="0 0 27 20"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<g clipPath="url(#clip0_109_13)">
								<path
									d="M12.3722 0.172346C12.6532 0.0585174 12.9544 -4.76933e-05 13.2586 2.97457e-08C13.8722 -9.71663e-05 14.4608 0.238005 14.8949 0.661978L26.4694 11.9778L13.3819 19.8933L0.0463867 11.9792L11.6208 0.663395C11.8358 0.453039 12.0912 0.286175 12.3722 0.172346Z"
									fill="#F0F6FA"
								/>
							</g>
							<defs>
								<clipPath id="clip0_109_13">
									<rect width="27" height="20" fill="white" />
								</clipPath>
							</defs>
						</svg>
					</div>

					<div
						className={cn(
							'xl:mx-auto bg-white xl:shadow-lg xl:top-14 xl:-left-20 xl:absolute rounded-md',
							{
								'overflow-y-hidden max-h-[80vh]': !isMobile,
							},
						)}
					>
						<div className="relative flex flex-col flex-wrap w-full mx-auto mt-4 xl:mt-0 xl:flex-row">
							{isMobile ? (
								<MenuComponent
									links={linksData}
									collapsibleSubmenus
									displayTextAppend={displayTextAppend}
								/>
							) : (
								<DesktopMenuComponent
									featured={featured}
									navLinks={linksData}
									menuTitle={menuTitle}
									largerColumn={largerColumn}
								/>
							)}
						</div>
					</div>
				</>
			)}
		</div>
	);
}

export default Menu;
