import React from 'react';
import { format } from 'date-fns';
import LinkButton from '@brighthr/component-linkbutton';
import Icon from '@brighthr/component-icon';
import { GatsbyImage } from 'gatsby-plugin-image';
import Cookies from 'js-cookie';
import Clock from '../../../icons/Clock.svg';

const LiveWebinarCard = ({
	title,
	description,
	speakerOne,
	speakerTwo,
	speakerOneShowLogo,
	speakerTwoShowLogo,
	webinarDate,
	webinarTime,
	webinarTimezone,
	registrationLink,
	heroCard,
	bottomBorder,
	id,
	jointHeadshot,
}) => {
	const time = new Date(`${webinarDate}T${webinarTime}:00`);
	const webinarId = id.split('DatoCmsLiveWebinar-')[1].split('-')[0];
	const href = `${registrationLink}?webinarId=${webinarId}`;
	return (
		<div
			className={`block ${
				heroCard && 'xl:flex-row flex-col flex border-gray-300 rounded-lg shadow-xl mb-8'
			}`}
		>
			{heroCard && (
				<div
					className={`flex justify-center xl:w-40% ${
						speakerOneShowLogo || speakerTwoShowLogo ? 'flex-col' : 'flex-row'
					} 
					 ${speakerOne?.externalSpeaker ? 'external-speaker-bg' : 'speaker-bg'}`}
				>
					{(speakerOneShowLogo || speakerTwoShowLogo) && (
						<div className="flex flex-row justify-between px-3 pt-3 md:px-5 md:pt-6">
							<div className="flex items-center ">
								<img
									src={speakerOne.logo?.url}
									alt=""
									className="md:w-fit w-3/5 max-w-[200px]"
								/>
							</div>
							<div className="flex items-center justify-end ">
								<img
									src={speakerTwo.logo?.url}
									alt=""
									className="md:w-fit w-3/5 max-w-[200px]"
								/>
							</div>
						</div>
					)}
					{jointHeadshot?.headshot ? (
						<GatsbyImage
							image={jointHeadshot.headshot.gatsbyImageData}
							className="relative xl:min-h-[315px] xl:left-0 h-auto"
							alt={speakerOne.headshot.alt || ''}
						/>
					) : (
						<div
							className={`relative flex items-end justify-center overflow-hidden h-full 
						${speakerTwo ? 'justify-center' : 'justify-center'}
						`}
						>
							<div className="flex items-end h-full">
								<GatsbyImage
									image={speakerOne.headshot.gatsbyImageData}
									className={`relative xl:min-h-[315px] left-[5px] md:left-[10px] xl:left-0 h-auto ${
										speakerOne.externalSpeaker ? 'z-10' : 'z-0'
									}`}
									alt={speakerOne.headshot.alt || ''}
								/>
								{speakerTwo && (
									<GatsbyImage
										image={speakerTwo.headshot.gatsbyImageData}
										className="h-auto xl:min-h-[315px] right-[5px] md:right-[10px] xl:right-0"
										alt={speakerTwo.headshot.alt || ''}
									/>
								)}
							</div>
						</div>
					)}
				</div>
			)}
			<div
				className={`${
					heroCard
						? 'lg:p-8 p-6 block xl:w-60%'
						: 'mx-6 py-3 block md:flex md:justify-between md:items-center'
				} ${bottomBorder ? 'border-b-2 border-gray-300' : 'border-none'}`}
			>
				<section>
					<h2
						className={`${
							heroCard ? 'mb-5 text-2xl' : 'mb-0 text-lg'
						} font-bold leading-8 text-gray-900 line-clamp-2`}
					>
						{title}
					</h2>
					{heroCard && (
						<section>
							<p className="md:min-h-[30px] lg:min-h-[60px] mb-3 line-clamp-3">
								{description}
							</p>
							<a
								href={href}
								target="_blank"
								rel="noreferrer"
								className="mb-5 text-brand-500"
							>
								Show details
							</a>
							<div className="grid-cols-12 lg:grid lg:min-h-[80px] mt-3 mb-4 lg:mb-0">
								<h3 className="font-bold text-gray-900 lg:col-span-3 xl:col-span-2">
									Speakers:{' '}
								</h3>
								<div className="pl-5 lg:pl-0 lg:col-span-9 xl:col-span-10">
									<span className="font-bold">{speakerOne.fullName},</span>{' '}
									<span>{speakerOne.jobTitle}</span>
									{speakerTwo && (
										<div className="lg:pl-0">
											<span className="font-bold">
												{speakerTwo.fullName},
											</span>{' '}
											<span>{speakerTwo.jobTitle}</span>
										</div>
									)}
								</div>
							</div>
						</section>
					)}

					<section
						className={`${
							heroCard
								? 'block lg:flex font-bold mb-5'
								: 'block md:flex mb-0 md:items-center'
						}  `}
					>
						<div className="flex items-center">
							<Icon
								iconName="calendar-dates"
								size={32}
								className="inline-block w-4 h-4 mr-2"
							/>
							{format(time, 'eeee, d MMM')}
						</div>

						<div
							className={`${
								heroCard ? 'lg:pl-5' : 'md:pl-5'
							} flex items-center mb-5 md:mb-0`}
						>
							<Clock className="inline-block w-4 h-4 mr-2" />
							{format(time, "h:mm aaaaa'm'")} {webinarTimezone}
						</div>
					</section>
				</section>

				<section>
					{Cookies.get('liveWebinars')?.includes(webinarId) ? (
						<div
							className={`${
								!heroCard ? 'md:w-[inherit] w-full' : 'w-full'
							} px-4 py-2 text-center font-bold rounded bg-success-100 text-success-900 pointer-events-none`}
						>
							Already registered
						</div>
					) : (
						<>
							<LinkButton
								className="mt-4"
								color="accent"
								size="xl"
								text="Save my seat"
								href={href}
								target="_blank"
							/>
						</>
					)}
				</section>
			</div>
		</div>
	);
};

export default LiveWebinarCard;
