import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import CopyBlock from '../../CopyBlock';
import DoubleQuote from '../../../images/vectors/DoubleQuote.svg';
import Blob from '../../../images/blobs/light-blob.svg';
import BlobSmallDots from '../../../images/blobs/headshot-blob-small-dots.svg';
import LeadershipSocials from '../Socials';

const LeadershipProfile = ({ profileData, heroProfile = false }) => {
	const {
		name,
		companyRole,
		quoteNode,
		quoteTwoNode,
		descriptionNode,
		image,
		linkedin,
		twitter,
		tiktok,
	} = profileData;

	return (
		<div className="flex flex-col justify-between gap-6 lg:flex-row">
			<div className="relative w-full max-w-md mx-auto lg:mb-auto lg:shrink-0 blob-mask lg:-ml-12">
				<img className="hidden lg:block" src={Blob} alt="" width="624" height="521" />
				<img
					className="block lg:hidden"
					src={BlobSmallDots}
					alt=""
					width="670"
					height="685"
				/>
				<div className="absolute h-90% lg:w-auto lg:h-auto top-0 right-0 blob-mask-mobile">
					<div className="scale-110 translate-y-16 lg:translate-y-10">
						<GatsbyImage image={image.gatsbyImageData} alt={image.alt || 'headshot'} />
					</div>
				</div>
			</div>
			<div className="relative lg:w-2/3">
				<img
					className={`mb-4 lg:absolute lg:top-0 lg:-left-24 lg:mb-0 ${
						heroProfile ? 'hidden lg:block' : ''
					}`}
					src={DoubleQuote}
					alt=""
					width="86"
					height="71"
				/>

				<div>
					<div className={`${heroProfile ? 'hidden lg:block' : ''}`}>
						<div className="mb-4 text-xl italic font-bold lg:text-3xl">
							<CopyBlock>{quoteNode.childMdx.body}</CopyBlock>
						</div>
						{quoteTwoNode && (
							<div className="mb-4 text-lg italic lg:text-2xl">
								<CopyBlock>{quoteTwoNode.childMdx.body}</CopyBlock>
							</div>
						)}
					</div>

					<div className="mb-4 font-bold">
						<p className="text-lg lg:text-2xl text-brand-400">{name}</p>
						<p>{companyRole}</p>
					</div>
					<div className="mb-4 text-lg text-gray-700">
						<CopyBlock>{descriptionNode.childMdx.body}</CopyBlock>
					</div>
					<LeadershipSocials tiktok={tiktok} twitter={twitter} linkedin={linkedin} />
				</div>
			</div>
		</div>
	);
};

export default LeadershipProfile;
