import React from 'react';
import LinkButton from '@brighthr/component-linkbutton';
import Spinner from '@brighthr/component-spinner';
import { buildLink } from '../../../utils/locale';
import { useLocale } from '../../../context/Locale';
import useExistingUserTimout from '../../../hooks/useExistingUserTimout';

const CTAButtons = () => {
	const locale = useLocale();
	const { isCookieReady, existingUser } = useExistingUserTimout({ locale });

	return (
		<div className="w-full lg:w-[196px] flex items-center lg:h-[43px]">
			{isCookieReady ? (
				<div
					className={`${
						existingUser ? 'justify-end' : 'justify-center'
					} block w-full lg:flex`}
				>
					{existingUser ? (
						<LinkButton
							href={buildLink(locale, 'help')}
							outlineButton
							text="Support"
							size="lg"
						/>
					) : (
						<LinkButton
							href={buildLink(locale, 'book-a-demo')}
							color="accent"
							text="Book your free demo"
							size="lg"
						/>
					)}
				</div>
			) : (
				<div className="flex items-center m-auto">
					<Spinner
						ariaLabel="processing request"
						className="stroke-primary-700"
						size="xs"
					/>
				</div>
			)}
		</div>
	);
};

export default CTAButtons;
