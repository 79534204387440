import React from 'react';
import FeatureImageWithCopy from '../FeatureImageWithCopy';
import MultiParagraphBlock from '../../MultiParagraphBlock';

const Features = ({ featureElements, featuresHeader }) => (
	<section className="features-bg">
		<div className="container p-6 mx-auto">
			<div>
				<h2 className="my-6 text-3xl font-semibold text-center md:text-4xl md:font-bold lg:mb-12">
					<MultiParagraphBlock content={featuresHeader} />
				</h2>
			</div>
			{featureElements.map((el) => (
				<React.Fragment key={el.id}>
					<div className="mb-16 md:mb-28 last:mb-0">
						{el.blocktype === 'ImageWithCopyV2' && (
							<FeatureImageWithCopy
								imgSrc={el.imageWithCopyImageV2.gatsbyImageData}
								alt={el.imageWithCopyImageV2.alt}
								header={el.header}
								copy={el.copyNode}
								imagePosition={el.imagePosition}
							/>
						)}
					</div>
				</React.Fragment>
			))}
		</div>
	</section>
);

export default Features;
