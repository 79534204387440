/* eslint-disable global-require */
import React from 'react';
import {
	BRIGHTHR,
	BLIP,
	POP,
	VACCTRAK,
	BRIGHTWELLBEING,
	CONNECT as CONNECT_BASE,
	PROTECT as PROTECT_BASE,
	PRIME as PRIME_BASE,
	BRIGHTSAFEADVICE as BRIGHTSAFEADVICE_BASE,
	BRIGHTBASE as BRIGHTBASE_BASE,
	BRIGHTEXCHANGE as BRIGHTEXCHANGE_BASE,
	BACKTOWORK,
} from './pricing';

const BRIGHTADVICE = {
	id: 'BRIGHTADVICE',
	name: 'BrightAdvice: Dedicated HR Advisors',
	shortName: 'BrightAdvice',
	description:
		'Professional HR and employment law advice from our Irish expert advisors available 24/7.',
	img: require('../images/pricing/brightadvice.jpg'),
	info: [
		'BrightAdvice is a 24/7 employment law helpline that gives you fast access to legal support whenever you need it.',
		'Our qualified Ireland-based experts are always available to give you clear, practical and jargon-free advice. And with the free BrightAdvice mobile app, you can get the answers to your most recent advice questions on the go.',
		'Plus, get an extra level of protection with BrightDefence. It’s exclusive cover against the costs of defending employment disputes in your business.',
		<>
			<a
				className="text-brand-500"
				target="_blank"
				rel="noreferrer"
				href="https://www.datocms-assets.com/23428/1645619215-brightdefence_ireland.pdf"
			>
				(Ts &amp; Cs apply).
			</a>
		</>,
	],
};

const BRIGHTSAFE = {
	id: 'BRIGHTSAFE',
	name: 'BrightSafe Software',
	shortName: 'BrightSafe',
	description:
		'Simple to use software helping you to manage risk assessments, accident and near miss reporting to staff compliance.',
	img: require('../images/pricing/brightsafe.jpg'),
	info: [
		'BrightSafe’s online software helps you keep on top of all your health & safety management, so you can protect your people and your business.',
		'Make risk assessments in minutes, record workplace accidents with ease, and access exclusive online health & safety training courses. Plus, get access to a trusted health & safety advice helpline with qualified, Ireland-based advisors.',
		'And in the unlikely event that you ever face a dispute, get exclusive cover against the costs of defending health & safety prosecutions in your business with BrightDefence.',
		<>
			<a
				className="text-brand-500"
				target="_blank"
				rel="noreferrer"
				href="https://www.datocms-assets.com/23428/1645619215-brightdefence_ireland.pdf"
			>
				(Ts &amp; Cs apply).
			</a>
		</>,
	],
};

const BRIGHTSAFEADVICE = {
	...BRIGHTSAFEADVICE_BASE,
	shortName: 'Access to professional H&S advisors you can speak to',
	description:
		'Qualified health & safety advice from our Irish health & safety advisors available 24/7.',
};

const BRIGHTBASE = {
	...BRIGHTBASE_BASE,
	shortName: 'Library of downloadable HR policies, templates, and guides',
};

const BRIGHTEXCHANGE = {
	...BRIGHTEXCHANGE_BASE,
	shortName: 'Bright Exchange online marketplace',
};

export const BENEFITS = [
	BRIGHTHR,
	BACKTOWORK,
	VACCTRAK,
	BLIP,
	POP,
	BRIGHTADVICE,
	BRIGHTSAFE,
	BRIGHTWELLBEING,
];

const CONNECT = {
	...CONNECT_BASE,
	link: '/ie/book-a-demo/',
	benefits: [BRIGHTHR, BRIGHTSAFE, BLIP, POP, BRIGHTEXCHANGE],
	fullBenefits: [BRIGHTHR, BACKTOWORK, VACCTRAK, BLIP, POP],
};

const PROTECT = {
	...PROTECT_BASE,
	link: '/ie/book-a-demo/',
	benefits: [BRIGHTADVICE, BRIGHTBASE, BRIGHTSAFEADVICE],
	fullBenefits: [BRIGHTHR, BACKTOWORK, VACCTRAK, BLIP, POP, BRIGHTADVICE],
	subheading: (
		<>
			The <strong className="text-secondary-300">Connect</strong> package{' '}
			<strong className="uppercase">plus</strong> a team of Irish HR advisors and templates at
			your finger tips. The fastest way to get expert advice for any business need or issue.
		</>
	),
};

const PRIME = {
	...PRIME_BASE,
	link: '/ie/book-a-demo/',
	benefits: [BRIGHTWELLBEING],
	fullBenefits: [
		BRIGHTHR,
		BACKTOWORK,
		VACCTRAK,
		BLIP,
		POP,
		BRIGHTADVICE,
		BRIGHTSAFE,
		BRIGHTWELLBEING,
	],
	subheading: <>Employee Assistance Programme plus all-in-one software and advice service</>,
};

export default {
	options: [CONNECT, PROTECT, PRIME],
};
/* eslint-enable global-require */
