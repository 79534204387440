import React, { useEffect, useState } from 'react';
import loadScript from 'load-script2';
import Button from '@brighthr/component-button';
import cn from 'classnames';
import { useFormik } from 'formik';

import Spinner from '@brighthr/component-spinner';
import AES from 'crypto-js/aes';
import queryString from 'query-string';
import checkEmailAvailability from '../../../utils/checkEmailAvailability';

import FormFooterLinks from '../FormFooterLinks';
import submitMarketoForm from '../submitMarketoForm/index2';
import ReviewBadges from '../../ReviewBadges';
import LocaleContent from '../../LocaleContent';
import {
	SessionProvider,
	useSessionState,
	useSessionUpdate,
} from '../../../apps/BuyOnline/context/session';
import { useVar } from '../../Var';
import getLeadSource from '../../../utils/leadSource';
import { buildLink } from '../../../utils/locale';
import HelpText from '../HelpText';

const RequestADemoFormAndLoader = (props) => {
	const { initialised, session } = useSessionState();
	const updateSession = useSessionUpdate();

	if (!initialised)
		return (
			<div className="flex justify-center">
				<Spinner ariaLabel="processing request" className="stroke-primary-700" size="xl" />
			</div>
		);

	return <RequestADemoForm session={session} updateSession={updateSession} {...props} />;
};

const webFormIds = {
	au: 'Book_a_Demo_Australia',
	nz: 'Book_a_Demo_NewZealand',
	ca: 'Book_a_Demo_Canada',
	ie: 'Book_a_Demo_ROI',
	en: 'Book_a_Demo_UK',
};

const RequestADemoForm = ({
	title,
	subtitle,
	formColor,
	ctaText = 'Book my free demo',
	pageContext = false,
	isStarlingDemoPage = false,
	showFeefo = false,
	hidePrivacyLinks = false,
	showMessageField = false,
	hideCustomerMessage,
	transactionId,
	session = {},
	locale = 'en',
	secondCtaText,
	secondCtaHref,
	leadSource,
	showTitle,
	referrerField,
}) => {
	const [emailExists, setEmailExists] = useState(false);

	useEffect(() => {
		// Preloads the marketo form for background submission!
		const loadForm = () => {
			loadScript('//app-lon04.marketo.com/js/forms2/js/forms2.min.js')
				.then(() => {
					window.MktoForms2.loadForm('//app-lon04.marketo.com', '217-MIC-854', 2796);
				})
				.catch(() => {});
		};

		if (window.requestIdleCallback) {
			window.requestIdleCallback(loadForm);
		} else {
			setTimeout(loadForm);
		}
	}, []);

	const sk = 'bad2dsGtq0ixmcT5qfjFOH';
	const supportNumber = useVar('supportNumber');
	const LeadSource = leadSource || getLeadSource(locale);

	const webFormId = webFormIds[locale];

	const validate = async (values) => {
		try {
			const result = await checkEmailAvailability(values.Email);

			setEmailExists(false);

			if ('available' in result && !result.available) {
				setEmailExists(true);
				return false; // Indicate that the email is not available on the db
			}

			return true; // Indicate that the email is available on the db
		} catch (error) {
			throw new Error('An error occurred while checking email availability');
		}
	};

	const { handleChange, handleSubmit, setStatus, handleBlur, values, isSubmitting, status } =
		useFormik({
			enableReinitialize: true,
			initialValues: {
				FirstName: session?.firstName || '',
				LastName: session?.lastName || '',
				Title: session?.title || '',
				Company: session?.companyName || '',
				Phone: session?.telephone || '',
				Email: session?.email || '',
				LeadSource,
				web_form_id: webFormId,
				Transaction_Id__c: transactionId,
				Referrer_Name__c: session?.referrerName,
			},
			validate,
			onSubmit: async (data) => {
				const splitName = data.LastName.split(' ');
				const updatedData = {
					...data,
					FirstName: splitName[0],
					LastName: splitName.slice(1).join(' '),
				};
				submitMarketoForm({ data: updatedData })
					.then(() => {
						setStatus('submitted');
						window.dataLayer.push({ event: 'Book_My_Demo_Success', pageVariant: 'a' });
						window.dataLayer.push({
							event: 'GA4 - Demo form page - Successful Submit',
							pageVariant: 'a',
						});

						if (!pageContext) {
							const encDemoEmail = AES.encrypt(values.Email, sk).toString();
							const encDemoFirstName = AES.encrypt(values.FirstName, sk).toString();
							const encDemoLastName = AES.encrypt(values.LastName, sk).toString();
							const encDemoPhone = AES.encrypt(values.Phone, sk).toString();
							window.sessionStorage.setItem('badfn', encDemoFirstName);
							window.sessionStorage.setItem('badln', encDemoLastName);
							window.sessionStorage.setItem('badp', encDemoPhone);
							window.sessionStorage.setItem('bade', encDemoEmail);
							window.sessionStorage.setItem('bads', values.LeadSource);
							window.location.href = buildLink(locale, `demo-survey/`);
							// window.location.href = `https://pages.brighthr.com/demo-survey.html?name=${values.FirstName}&email=${values.Email}`;
						}
					})
					.catch(() => {
						setStatus('error');
					});
			},
		});

	const handleFormDataUpdate = async () => {
		const { FirstName, LastName, Phone, Email, ...otherFormValues } = values;

		const encryptValue = (plainValue) => AES.encrypt(plainValue, sk).toString();

		const encFirstName = encryptValue(FirstName);
		const encLastName = encryptValue(LastName);
		const encPhone = encryptValue(Phone);
		const encEmail = encryptValue(Email);

		// Update formValues with the encrypted Email
		const formValuesWithEncryptedEmail = {
			FirstName: encFirstName,
			LastName: encLastName,
			Phone: encPhone,
			Email: encEmail,
			...otherFormValues,
		};

		const queryParams = queryString.stringify(formValuesWithEncryptedEmail);

		if (locale === 'en') {
			window.location.href = buildLink(locale, `log-a-support-case/?${queryParams}`);
		} else {
			window.location.href = buildLink(locale, `contact/?${queryParams}`);
		}
	};

	return (
		<>
			{status === 'error' && (
				<p
					className={cn('text-red-400', {
						'text-black': formColor === 'white',
						'text-white': formColor === 'blue',
					})}
				>
					Sorry we have been unable to book your demo, please call us on{' '}
					<a className="font-bold whitespace-nowrap" href={`tel:${supportNumber}`}>
						{supportNumber}
					</a>
					.
				</p>
			)}

			{status === 'submitted' && (
				<>
					<div
						className={cn('', {
							'text-black': formColor === 'white',
							'text-white': formColor === 'blue',
						})}
					>
						<h2 className="mb-2 text-3xl font-bold text-center">
							Thanks for your interest in BrightHR
						</h2>

						{pageContext === 'partner' && (
							<p className="text-center">
								A member of the team will be in touch shortly.
							</p>
						)}
					</div>
				</>
			)}

			{!status && (
				<form
					className={cn('flex flex-col max-w-full pt-4', {
						'text-black': formColor === 'white',
						'text-white': formColor === 'blue',
					})}
					onSubmit={handleSubmit}
				>
					{title && <h4 className="mb-4 text-2xl font-bold text-center">{title}</h4>}
					{subtitle && <p className="mb-10 text-lg text-center">{subtitle}</p>}

					<div className="mb-8 space-y-3">
						{/* <label htmlFor="FirstName" className="block w-full mb-2 text-left md:mb-0">
							<div className="mb-1 text-sm font-bold">First name*</div>
							<input
								name="FirstName"
								id="FirstName"
								className="w-full px-4 py-2 text-gray-800 border border-gray-500 rounded"
								type="text"
								placeholder="John"
								required
								value={values.FirstName}
								onChange={handleChange}
							/>
						</label> */}
						<label htmlFor="LastName" className="block w-full text-left">
							<div className="mb-1 text-sm font-bold">Full name*</div>
							<input
								name="LastName"
								id="LastName"
								className="w-full px-4 py-2 text-gray-800 border border-gray-500 rounded"
								type="text"
								placeholder="John Smith"
								required
								value={values.LastName}
								onChange={handleChange}
							/>
						</label>
						{locale === 'ie' && showTitle && (
							<label htmlFor="Title" className="block w-full text-left">
								<div className="mb-1 text-sm font-bold">Job title</div>
								<input
									name="Title"
									id="Title"
									className="w-full px-4 py-2 text-gray-800 border border-gray-500 rounded"
									type="text"
									placeholder="Director"
									value={values.Title}
									onChange={handleChange}
								/>
							</label>
						)}
						<label htmlFor="Company" className="block w-full mb-2 text-left md:mb-0">
							<div className="mb-1 text-sm font-bold">Company name*</div>
							<input
								name="Company"
								id="Company"
								className="w-full px-4 py-2 text-gray-800 border border-gray-500 rounded"
								type="text"
								placeholder="ACME Inc."
								required
								value={values.Company}
								onChange={handleChange}
							/>
						</label>
						<label htmlFor="Phone" className="block w-full text-left">
							<div className="mb-1 text-sm font-bold">Phone number*</div>
							<input
								name="Phone"
								id="Phone"
								className="w-full px-4 py-2 text-gray-800 border border-gray-500 rounded"
								type="tel"
								placeholder="01632 960410"
								required
								value={values.Phone}
								pattern="[0-9\s]{7,16}"
								title="Enter a valid telephone number between 7 and 16 digits."
								onChange={handleChange}
							/>
						</label>

						<label htmlFor="Email" className="block text-left">
							<div className="mb-1 text-sm font-bold">Business email*</div>
							<input
								name="Email"
								id="Email"
								className="w-full px-4 py-2 text-gray-800 border border-gray-500 rounded"
								type="email"
								placeholder="john.smith@acmeinc.com"
								required
								value={values.Email}
								onChange={handleChange}
								onBlur={handleBlur}
							/>
						</label>
						{emailExists && (
							<div className="space-y-3">
								<p className="text-legacy-error">
									The email address you have used is already registered with
									BrightHR.
								</p>
								<HelpText>
									It looks like you’re a BrightHR user. Looking for support? Fill
									in the simple form{' '}
									<button
										onClick={() => handleFormDataUpdate(values)}
										type="button"
										className="font-bold underline text-brand-500"
									>
										here
									</button>{' '}
									and a member of our friendly team will be in touch.
								</HelpText>
							</div>
						)}
						{showMessageField && (
							<div>
								<div className="mb-1 text-sm font-bold">Your message</div>
								<textarea
									name="Contact_Us_Comments__c"
									onChange={handleChange}
									value={values.Contact_Us_Comments__c}
									placeholder="Your message.."
									className="block w-full px-4 py-2 text-gray-900 bg-white border border-gray-500 rounded"
									required
								/>
							</div>
						)}
						{referrerField && pageContext === 'partner' && (
							<label
								htmlFor="Referrer_Name__c"
								className="block w-full mb-2 text-left md:mb-0"
							>
								{locale === 'ie' ? (
									<div className="mb-1 text-sm font-bold">Chambers referrer</div>
								) : (
									<div className="mb-1 text-sm font-bold">Referrer name</div>
								)}
								<input
									name="Referrer_Name__c"
									id="Referrer_Name__c"
									className="w-full px-4 py-2 text-gray-800 border border-gray-500 rounded"
									type="text"
									placeholder="John Smith"
									required
									value={values.Referrer_Name__c}
									onChange={handleChange}
								/>
							</label>
						)}
					</div>

					<div className="flex flex-wrap items-center ">
						{showFeefo ? (
							<div className="mb-3 md:mr-6 md:w-auto md:mb-0">
								<Button
									type="submit"
									disabled={emailExists ? !isSubmitting : isSubmitting}
									text={ctaText}
								/>
							</div>
						) : (
							<div className="flex flex-col w-full">
								<Button
									type="submit"
									disabled={emailExists ? !isSubmitting : isSubmitting}
									text={ctaText}
									size="lg"
								/>
							</div>
						)}

						{secondCtaText && (
							<a
								href={secondCtaHref}
								className="block w-full mt-6 font-bold text-center text-brand-500 hover:underline"
							>
								{secondCtaText}
							</a>
						)}
						{showFeefo && (
							<div>
								{formColor === 'white' && (
									<div className="flex items-center justify-center mt-8 lg:my-0 lg:flex-row lg:space-between lg:mt-4 ">
										<LocaleContent showInLocales={['en', 'ie']}>
											<ReviewBadges
												locale={locale}
												pageName="book a demo"
												classes="w-32 h-auto max-h-32"
												g2AwardClasses="ml-4 w-24 lg:w-28"
											/>
										</LocaleContent>
										<LocaleContent showInLocales={['ca', 'au', 'nz']}>
											<ReviewBadges
												locale={locale}
												pageName="book a demo"
												width={locale === 'ca' ? '110' : '170'}
												classes="mx-3"
												g2AwardClasses="ml-2 w-24 lg:w-28"
											/>
										</LocaleContent>
									</div>
								)}
								{formColor === 'blue' && (
									<div className="flex flex-col items-center justify-center mt-8 lg:my-0 lg:flex-row lg:space-between lg:mt-4 ">
										<LocaleContent showInLocales={['en', 'ie']}>
											<ReviewBadges
												locale={locale}
												pageName="book a demo blue bg"
												classes="w-32 h-auto max-h-32"
												g2AwardClasses="ml-4 w-24 lg:w-28"
											/>
										</LocaleContent>
										<LocaleContent showInLocales={['ca', 'au', 'nz']}>
											<ReviewBadges
												locale={locale}
												pageName="book a demo blue bg"
												width={locale === 'ca' ? '110' : '170'}
												classes="mx-auto"
												g2AwardClasses="ml-2 w-24 lg:w-28"
											/>
										</LocaleContent>
									</div>
								)}
							</div>
						)}
					</div>
					{!isStarlingDemoPage && !hidePrivacyLinks && (
						<FormFooterLinks
							locale={locale}
							className="mt-4 text-center text-gray-900"
							textLinkColor="text-gray-900"
							hideCustomerMessage={hideCustomerMessage}
						/>
					)}
				</form>
			)}
		</>
	);
};

const WrappedRequestADemoForm = (props) =>
	props.isStarlingDemoPage ? (
		<SessionProvider>
			<RequestADemoFormAndLoader {...props} />
		</SessionProvider>
	) : (
		<RequestADemoForm {...props} />
	);

export default WrappedRequestADemoForm;
