import React from 'react';
import LazyLoad from 'react-lazyload';

const appendWidth = (src, size) => `${src}${src.includes('?') ? '&' : '?'}w=${size}`;

const Image = (props) => (
	<LazyLoad once>
		<img
			{...props}
			className={props.fullWidth ? 'mb-4' : undefined}
			alt={props.alt}
			src={appendWidth(props.src, props.fullWidth ? 1000 : 400)}
			width={props.fullWidth ? undefined : 400}
		/>
	</LazyLoad>
);

export default Image;
