const updateQuote = async (quoteData, setProcessing) => {
	const { quoteId, customerId, couponId } = quoteData;
	const quote = await fetch('/api/stripe/quote/update-quote', {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			quoteId,
			customerId,
			couponId,
		}),
	})
		.then((res) => res.json())
		.catch(() => {
			setProcessing(false);
			throw new Error(
				'Could not update your quote at this time. Please contact our Sales team on 0800 470 2432',
			);
		});

	return quote;
};

export default updateQuote;
