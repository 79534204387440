/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import { LocaleProvider } from '../../context/Locale';

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import MasterLayout from '../Master';
import Container from '../../components/Container';
import LangNotify from '../../components/LangNotify';
import PromoPopup from '../../components/PromoPopup';
import StickyCTA from '../../components/StickyCTA';
import CTAButtons from '../../components/Header/CTAButtons';

const Layout = ({ children, fullWidth = false }) => {
	const data = useStaticQuery(graphql`
		query SiteTitleQuery {
			site {
				siteMetadata {
					title
				}
			}
		}
	`);

	return (
		<MasterLayout>
			<Header siteTitle={data.site.siteMetadata.title} />
			<LangNotify />
			{!fullWidth && (
				<Container>
					<main>{children}</main>
				</Container>
			)}
			{fullWidth && <main>{children}</main>}
			<Footer />
			<StickyCTA>
				<div className="p-6">
					<CTAButtons />
				</div>
			</StickyCTA>
			<PromoPopup template="all" />
		</MasterLayout>
	);
};

Layout.propTypes = {
	children: PropTypes.node.isRequired,
};

const WrappedLayout = (props) => (
	<LocaleProvider>
		<Layout {...props} />
	</LocaleProvider>
);

export default WrappedLayout;
