import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from '@reach/router';
import SEO from '../components/SEO';
import Logo from '../components/Logo';
import BrightSafe from '../components/ProductLogos';

import RequestAppointmentForm from '../components/Marketo/RequestAppointment';
import MasterLayout from '../layouts/Master';
import Container from '../components/Container';
import MinimalFooter from '../components/MinimalFooter';
import LangNotify from '../components/LangNotify';

const RequestAppointment = ({ locale }) => {
	const location = useLocation();
	const [fromBrightSafe, setFromBrightSafe] = useState(null);

	useEffect(() => {
		const urlParams = (name) => {
			const results =
				typeof window !== 'undefined'
					? new RegExp(`[?&]${name}=([^&#]*)`).exec(window.location.search)
					: '';

			if (results == null) {
				return null;
			}

			return decodeURI(results[1]) || 0;
		};

		const requestappointmentBrightSafe = urlParams('p') ? urlParams('p') : '';

		// eslint-disable-next-line no-unused-expressions
		requestappointmentBrightSafe === 'safe'
			? setFromBrightSafe(true)
			: setFromBrightSafe(false);
	}, []);

	return (
		<>
			<LangNotify />
			<Helmet>
				<link
					rel="prefetch"
					href="https://app-lon04.marketo.com/js/forms2/js/forms2.min.js"
				/>
			</Helmet>
			<SEO path={location.pathname} title="Request an appointment" />
			<Container className="relative z-10">
				<div className="book-appointment-form-wrapper">
					<header className="relative flex flex-row items-center justify-center mb-8 md:mb-20 text-brand-500">
						{fromBrightSafe ? <BrightSafe /> : <Logo />}
					</header>
					<section className="mb-8">
						<div className="relative w-full px-8 py-10 mx-auto mb-8 bg-white border border-gray-300 rounded-md shadow-md sm:px-16 md:max-w-3xl md:mb-0">
							<RequestAppointmentForm ctaText="Submit" />
						</div>
					</section>
				</div>
				<MinimalFooter locale={locale} />
			</Container>
		</>
	);
};

const WrappedRequestAppointment = (props) => (
	<MasterLayout>
		<RequestAppointment {...props} />
	</MasterLayout>
);

export default WrappedRequestAppointment;
