import { useEffect } from 'react';

const Optimize = () => {
	useEffect(() => {
		if (process.env.GATSBY_ENV === 'production') {
			setTimeout(() => {
				if (window.google_optimize) {
					window.dataLayer = window.dataLayer || [];
					window.dataLayer.push({ event: 'optimize.activate' });
				}

				if (window.hasOptimizeMask) {
					document.body.classList.remove('async-hide');
				}
			});
		}
	}, []);

	return null;
};

export default Optimize;
