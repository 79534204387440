import React from 'react';

function MenuColumnSimple({ links }) {
	return (
		<ul className="flex flex-col w-full gap-2 mt-2">
			{links.map((link) => (
				<li key={link?.text || link?.displayText}>
					<a
						href={link?.href || link.linkUrl}
						className={`${
							link.description ? 'font-semibold' : 'font-normal'
						} text-16 text-primary-700 hover:text-brand-400 hover:underline`}
					>
						{link?.text || link?.displayText}
					</a>
					{link.description && (
						<p className="hidden pb-2 text-sm cursor-default text-neutral-500 lg:block">
							{link.description}
						</p>
					)}
				</li>
			))}
		</ul>
	);
}

export default MenuColumnSimple;
