import LinkButton from '@brighthr/component-linkbutton';
import React, { useEffect, useState } from 'react';

import Alert from '@brighthr/component-alert';
import Container from '../Container';

// https://caniuse.com/es6-module
function supportsStaticImport() {
	const script = document.createElement('script');
	return 'noModule' in script;
}

const BrowserWarning = () => {
	const [showWarning, setShowWarning] = useState(false);

	useEffect(() => {
		setShowWarning(
			window.sessionStorage.getItem('browser-warning-dismissed')
				? false
				: !supportsStaticImport(),
		);
	}, []);

	if (!showWarning) return null;

	return (
		<>
			<Container>
				<Alert
					type="warning"
					icon="error"
					dismiss
					onDismiss={() => {
						setShowWarning(false);
						window.sessionStorage.setItem('browser-warning-dismissed', true);
					}}
					className="w-full !max-w-none"
				>
					<div className="flex flex-col justify-center md:flex-row">
						<p>
							<span className="block font-bold ">Your browser is out of date.</span>{' '}
							Please update your browser for more security, speed and the best
							experience on this site.
						</p>
						<div className="w-fit md:ml-2">
							<LinkButton
								color="primary"
								outlineButton
								href="https://bestvpn.org/outdatedbrowser/en"
								size="lg"
								rel="noopener"
								text="Update browser"
								target="_blank"
							/>
						</div>
					</div>
				</Alert>
			</Container>
		</>
	);
};

export default BrowserWarning;
