import React from 'react';
import { graphql } from 'gatsby';
import LazyLoad from 'react-lazyload';
import { GatsbyImage, getSrc } from 'gatsby-plugin-image';
import cn from 'classnames';
import LinkButton from '@brighthr/component-linkbutton';
import Icon from '@brighthr/component-icon';
import CMSWellCTA from '../components/CMSBlocks/WellCTA';
import CopyBlock from '../components/CopyBlock';
import DatoSEO from '../components/DatoSEO';
import TestimonialQuote from '../components/TestimonialQuote';
import VideoCarousel from '../components/VideoCarousel';
import ReviewBadges from '../components/ReviewBadges';
import LocaleContent from '../components/LocaleContent';

// Feature Icons
import { useLocale } from '../context/Locale';
import { buildLink, SUPPORTED_LOCALES } from '../utils/locale';
import DefaultLayout from '../layouts/Default';
import HrefLang from '../components/HrefLang';

const iconMap = {
	'DatoCmsFeature-4518661-en': 'alarmclock',
	'DatoCmsFeature-4676157-en': 'accommodation',
	'DatoCmsFeature-4519118-en': 'shifts',
	'DatoCmsFeature-4388512-en': 'calendar-dates',
	'DatoCmsFeature-4519831-en': 'records',
	'DatoCmsFeature-4519034-en': 'refresh',
	'DatoCmsFeature-4520741-en': 'furlough-navigator',
	'DatoCmsProduct-4518477-en': 'plaster',
	'DatoCmsProduct-4512558-en': 'radar',
	'DatoCmsProduct-4518665-en': 'receipt',
	'DatoCmsProduct-4120713-en': 'chair',
	'DatoCmsProduct-4512042-en': 'lightbulb',
	'DatoCmsProduct-4518512-en': 'information',
	'DatoCmsProduct-4512197-en': 'help',
};

const DefaultIcon = () => 'xing';

const splitContent = (content) => {
	let before = content.slice(0, 3);
	let after = content.slice(3);

	// eslint-disable-next-line no-underscore-dangle
	if (before.some((c) => c.__typename === 'DatoCmsTestimonial' && c.featured)) {
		before = content.slice(0, 2);
		after = content.slice(2);
	}

	return [before, after];
};

const Testimonial = ({
	companyQuoteNode,
	heroImage,
	featured,
	industry,
	companyName,
	numberOfEmployees,
	features,
	slug,
}) => {
	const locale = useLocale();

	return (
		<div
			className={cn('sm:pr-0 sm:pl-0 md:pr-4 md:pl-4 mt-4 mb-8 rounded  w-full', {
				'w-full lg:w-1/3': !featured,
				'w-full lg:w-2/3': featured,
			})}
		>
			<div className="flex flex-col shadow-lg lg:h-full">
				<div
					className="h-48 bg-no-repeat bg-cover rounded-t"
					style={{
						backgroundImage: `url(${getSrc(heroImage.gatsbyImageData)})`,
					}}
				/>
				<div className="flex flex-col p-6 grow">
					<div className="relative pb-4 border-gray-400 border-solid border-1">
						<div
							className={cn('', {
								hidden: !featured,
								block: featured,
							})}
						/>
						<div className="mb-3 text-base lg:text-lg">
							<CopyBlock>{companyQuoteNode.childMdx.body}</CopyBlock>
						</div>
						<p className="text-base italic lg:text-lg">{companyName}</p>
					</div>
					<div
						className={cn('w-full border-t', {
							hidden: !featured,
							block: featured,
						})}
					>
						<div className="w-full pt-2 pb-2">
							<div className="flex flex-col pt-2 pb-4 md:flex-row">
								<div className="w-7/12 md:w-3/12 ">
									<div className="pb-4 sm:w-full">
										<h4 className="text-xs font-bold md:text-sm">
											No. of Employees
										</h4>
										<h4 className="text-xs md:text-sm">{numberOfEmployees}</h4>
									</div>
									<div className="pb-4">
										<h4 className="text-xs font-bold md:text-sm">Industry</h4>
										<h4 className="text-xs md:text-sm">{industry}</h4>
									</div>
								</div>
								<div className="w-8/12 ">
									<h4 className="pb-2 text-xs font-bold md:text-sm">
										Features Used
									</h4>
									<div className="flex">
										{features.map(({ id }) => {
											const IconName = iconMap[id] || DefaultIcon;

											return (
												<div
													key={id}
													className="flex items-center justify-center p-2 mr-2 text-gray-900 bg-gray-200 rounded-full"
												>
													<Icon
														iconName={IconName}
														size={32}
														className="inline-block w-5 h-5 text-left"
													/>
												</div>
											);
										})}
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="mt-auto w-fit">
						<LinkButton
							outlineButton
							href={buildLink(locale, `customers/${slug}`)}
							text="Read more"
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

const Tile = ({
	id,
	__typename,
	companyQuoteNode,
	heroImage,
	quoteSource,
	quoteTextNode,
	colour,
	companyName,
	numberOfEmployees,
	industry,
	featured,
	features,
	slug,
	rating,
}) => {
	if (__typename === 'DatoCmsQuote') {
		return (
			<div className="mt-4 mb-8 sm:w-full lg:w-1/3 md:pl-4 md:pr-4 sm:pl-0 sm:pr-0" key={id}>
				<TestimonialQuote
					quoteSource={quoteSource}
					quoteTextNode={quoteTextNode}
					colour={colour}
				/>
			</div>
		);
	}

	if (__typename === 'DatoCmsTestimonial') {
		return (
			<Testimonial
				key={id}
				slug={slug}
				heroImage={heroImage}
				companyQuoteNode={companyQuoteNode}
				featured={featured}
				companyName={companyName}
				numberOfEmployees={numberOfEmployees}
				industry={industry}
				features={features}
				rating={rating}
			/>
		);
	}

	return null;
};

const CustomerPage = ({ data, location, pageContext }) => {
	const { title, descriptionNode, heroImage, content, ctaBanner, seoMetaTags, videoCarousel } =
		data.datoCmsTestimonialLandingPage;

	const [before, after] = splitContent(content);

	return (
		<DefaultLayout fullWidth>
			<HrefLang locales={SUPPORTED_LOCALES} slug="customers" />
			<DatoSEO path={location.pathname} meta={seoMetaTags} />
			<div className="w-full mx-auto bg-primary-50">
				<div className="relative flex-col max-w-6xl pt-8 pb-8 mx-auto md:pb-12 md:flex md:flex-row ">
					<div className="flex flex-col items-start px-4 pb-2 mx-auto mb-0 align-bottom md:px-6 md:pt-6 sm:px-4 md:flex md:w-1/2 sm:w-full">
						<div className="w-full md:mb-0 md:pr-8">
							<h1 className="mb-4 text-5xl font-bold tracking-tight">{title}</h1>
							<div className="">
								<CopyBlock>{descriptionNode.childMdx.body}</CopyBlock>
							</div>
						</div>
						<LazyLoad once>
							<div>
								<div className="flex flex-row flex-wrap items-center justify-center gap-6 pt-6 mx-auto">
									<LocaleContent showInLocales={['en', 'ie']}>
										<ReviewBadges
											locale={pageContext.locale}
											pageName="customers"
											classes="w-24 lg:w-32"
											g2AwardClasses="w-24 lg:w-28"
										/>
									</LocaleContent>
									<LocaleContent showInLocales="ca">
										<ReviewBadges
											locale={pageContext.locale}
											pageName="customers"
											width="110"
											g2AwardClasses="w-24 lg:w-28"
										/>
									</LocaleContent>
									<LocaleContent showInLocales={['au', 'nz']}>
										<ReviewBadges
											locale={pageContext.locale}
											pageName="customers"
											width="170"
											g2AwardClasses="w-24 lg:w-28"
										/>
									</LocaleContent>
								</div>
							</div>
						</LazyLoad>
					</div>

					<div className="hidden w-1/2 max-w-md md:block">
						<GatsbyImage
							image={heroImage.gatsbyImageData}
							alt={heroImage.alt || 'hero image'}
						/>
					</div>
				</div>
			</div>
			<div className="customVideoBackgroundReverse">
				<div className="max-w-6xl px-2 mx-auto md:px-12 md:mb-12">
					<VideoCarousel videoCarousel={videoCarousel} />
				</div>
			</div>
			<div className="max-w-6xl mx-auto mt-6 md:mt-16 lg:mt-24">
				<div className="px-2 mx-auto mb-16 md:px-12 md:mb-12">
					<div className="mx-auto align-bottom">
						<div className="flex flex-wrap m:0 md:-m-4">
							{before.map((c) => (
								<Tile {...c} key={c.id} />
							))}
						</div>
						<div className="flex flex-wrap m:0 md:-m-4">
							{after.map((c) => (
								<Tile {...c} key={c.id} />
							))}
						</div>
					</div>
				</div>
			</div>
			<div className="px-0 mt-24 mb-24 lg:mb-6">
				{ctaBanner.length > 0 && (
					<CMSWellCTA
						className="mt-24"
						title={ctaBanner[0].title}
						text={ctaBanner[0].textNode.childMdx.body}
					/>
				)}
			</div>
		</DefaultLayout>
	);
};

export const query = graphql`
	query seoAndTestimonialLanding {
		datoCmsTestimonialLandingPage {
			seoMetaTags {
				...GatsbyDatoCmsSeoMetaTags
			}
			description
			contentDescription
			descriptionNode {
				childMdx {
					body
				}
			}
			title
			heroImage {
				alt
				url
				gatsbyImageData(width: 750)
			}
			ctaBanner {
				title
				textNode {
					childMdx {
						body
					}
				}
			}
			videoCarousel {
				id
				videoId
				title
				thumbnail {
					alt
					gatsbyImageData
				}
				descriptionNode {
					childMdx {
						body
					}
				}
			}
			content {
				... on DatoCmsTestimonial {
					__typename
					numberOfEmployees
					industry
					id
					slug
					companyQuoteNode {
						childMdx {
							body
						}
					}
					features {
						... on DatoCmsProduct {
							__typename
							id
						}
						... on DatoCmsFeature {
							__typename
							id
						}
					}
					heroImage: heroImage {
						gatsbyImageData(
							layout: CONSTRAINED
							width: 500
							imgixParams: {
								crop: "faces,center"
								fit: "crop"
								auto: "compress,format"
								q: 60
							}
						)
					}
					companyName
					featured
					rating
				}
				... on DatoCmsQuote {
					__typename
					id
					quoteSource
					quoteTextNode {
						childMdx {
							body
						}
					}
					colour
				}
			}
		}
	}
`;

export default CustomerPage;
