import React from 'react';
import QuoteRound from '../../../../../../icons/QuoteRound.svg';

export default () => (
	<div className="hidden mt-16 lg:block lg:max-w-md">
		<QuoteRound className="mb-3" />
		<div className="px-5">
			<h3 className="mb-4 text-lg italic font-bold leading-7">
				Great Customer Service and Support
			</h3>
			<p className="mb-3 text-sm italic leading-5">
				Very easy to get in touch if you have any questions. BrightHR software itself
				extremely user friendly and simple to use. Uncomplicated yet covers all of our
				business needs for a small company.
			</p>
			<p className="mb-5 font-bold">- Tony Carroll</p>
		</div>
	</div>
);
