import React from 'react';
import { useLocation } from '@reach/router';
import workplaceImage from '../images/feefo/workplace.png';
import Logo from '../components/Logo';
import MasterLayout from '../layouts/Master';
import Container from '../components/Container';
import MinimalFooter from '../components/MinimalFooter';
import FeefoForm from '../components/FeefoForm';
import Tick from '../icons/Tick.svg';
import SEO from '../components/SEO';

const Feefo = ({ locale }) => {
	const location = useLocation();

	return (
		<>
			<SEO
				path={location.pathname}
				title="Leave a review"
				meta={[
					{
						name: 'review',
						value: 'noindex',
					},
				]}
				locale={locale}
			/>
			<Container className="relative z-10">
				<div>
					<header className="flex flex-row items-center justify-center mb-8 reative md:mb-12 text-brand-500">
						<Logo />
					</header>
					<section className="mx-auto mb-8">
						<h1 className="my-4 text-3xl font-bold">Leave us a review...</h1>
						<div className="flex">
							<div className="relative w-full px-8 py-10 mx-auto mb-8 bg-white border border-gray-300 rounded-md shadow-md sm:px-16 md:max-w-3xl md:mb-0">
								<FeefoForm />
							</div>
							<div className="hidden ml-6 lg:block">
								<h2 className="text-2xl">
									Be honest about your experience with BrightHR and we will:
								</h2>

								<div className="flex items-center p-2 px-4">
									<Tick className="w-5 h-5 mr-3" />
									Use your feedback to bring you even better HR software.
								</div>
								<div className="flex items-center p-2 px-4">
									<Tick className="w-5 h-5 mr-3" />
									Add new features to help you manage your staff more easily.
								</div>
								<div className="flex items-center p-2 px-4">
									<Tick className="w-5 h-5 mr-3" />
									Tailor BrightHR for you and your team’s specific needs.
								</div>
								<img src={workplaceImage} alt="workplace" />
							</div>
						</div>
					</section>
				</div>
				<MinimalFooter locale={locale} />
			</Container>
		</>
	);
};

const WrappedFeefo = (props) => (
	<MasterLayout>
		<Feefo {...props} />
	</MasterLayout>
);

export default WrappedFeefo;
