import React from 'react';

const Highlight = ({ children }) => (
	<span className="font-bold text-secondary-300">{children}</span>
);

const CONNECT = 'connect';
const PROTECT = 'protect';
const PRIME = 'prime';

export const PACKAGES = {
	CONNECT,
	PROTECT,
	PRIME,
};

export default {
	packages: {
		[CONNECT]: {
			packagesIncluded: [CONNECT],
			name: 'Connect',
			overview: 'Expert HR software PLUS a suite of business support tools',
		},
		[PROTECT]: {
			packagesIncluded: [CONNECT, PROTECT],
			name: 'Protect',
			overview: 'Expert HR software PLUS employment law advice and HR document library',
			intro: (
				<p>
					Everything in <Highlight>Connect</Highlight>{' '}
					<span className="font-bold">PLUS</span> access to a team of HR advisors
				</p>
			),
		},
		[PRIME]: {
			packagesIncluded: [CONNECT, PROTECT, PRIME],
			name: 'Prime',
			overview:
				'Smart HR software PLUS BrightSafe Health and Safety Software and expert advice',
			intro: (
				<p>
					Everything in the <Highlight>Connect</Highlight> and{' '}
					<Highlight>Protect</Highlight> package <span className="font-bold">PLUS</span>{' '}
					health & safety software and employee assistance programme
				</p>
			),
		},
	},
};
