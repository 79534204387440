import React from 'react';
import cn from 'classnames';
import { useLocale } from '../../../context/Locale';
import { buildLink } from '../../../utils/locale';
import { ArrowIcon } from '../icons/GenericIcons';

export const A = ({ heading, href, className, children, dim, ariaLabel, ...props }) => (
	<a
		href={href}
		aria-label={ariaLabel}
		{...props}
		className={cn(
			'no-underline hover:text-brand-400 focus:outline-none focus:underline',
			{
				'font-bold': heading,
				'text-brand-500 lg:text-brand-300': dim,
				'text-brand-500': !dim,
			},
			className,
		)}
	>
		{children}
	</a>
);

export const MetaLinks = () => {
	const locale = useLocale();

	return (
		<>
			<A
				className="order-last hidden md:inline-block md:text-sm"
				heading
				data-header-link
				target="_blank"
				rel="noopener noreferrer"
				href="https://www.peninsulagroupglobal.com/"
			>
				Visit Peninsula Group Global
			</A>

			<A
				className="hidden md:inline-block md:text-sm"
				heading
				data-header-link
				href={buildLink(locale, 'careers')}
			>
				Careers
			</A>
			<A
				className="font-semibold md:font-bold md:text-sm"
				heading
				data-header-link
				href={buildLink(locale, 'help')}
			>
				Support
			</A>

			<A
				className="hidden md:inline-block md:text-sm"
				heading
				data-header-link
				href={buildLink(locale, 'blog')}
			>
				Blog
			</A>
		</>
	);
};

export const LinkDescription = ({ children, className }) => (
	<p className={cn('text-gray-600 hidden lg:block', className)}>{children}</p>
);

export const ArrowLink = ({ href, children }) => (
	<A className="inline-flex items-center" data-header-link href={href}>
		<ArrowIcon className="mr-6 text-brand-400" /> {children}
	</A>
);

export const renderArrowLink = (link) => <ArrowLink href={link.href}>{link.text}</ArrowLink>;
