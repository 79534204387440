import React from 'react';
import CopyBlock from '../../../../components/CopyBlock';

const TestimonialSlide = ({ testimonial, width }) => (
	<div style={{ width: `${width}px` }} className="pr-20">
		{testimonial.title && <h2 className="mb-4 text-3xl font-semibold">{testimonial.title}</h2>}
		{testimonial.body && (
			<div className="mb-4 text-xl">
				<CopyBlock>{testimonial.bodyNode.childMdx.body}</CopyBlock>
			</div>
		)}
		{testimonial.customerName && (
			<div className="flex flex-col text-base text-brand-500">
				<span className="font-bold">{testimonial.customerName}</span>
				{testimonial.customerJobTitle && <span>{testimonial.customerJobTitle}</span>}
			</div>
		)}
	</div>
);

export default TestimonialSlide;
