import React from 'react';
import { graphql } from 'gatsby';
import LinkButton from '@brighthr/component-linkbutton';
import { nanoid } from 'nanoid';
import { useLocation } from '@reach/router';
import usePagination from '../hooks/usePagination';
import Pagination from '../components/Pagination';
import DefaultLayout from '../layouts/Default';
import dateToUTC from '../utils/dateToUTC';
import Breadcrumbs, { Breadcrumb } from '../components/Breadcrumbs';
import CarouselBg from '../images/blog/carousel-bg.svg';
import BlogCard from '../components/Blog/Card';
import DatoSEO from '../components/DatoSEO';
import HrefLang from '../components/HrefLang';
import { buildLink, LOCALE_PATHS, SUPPORTED_LOCALES } from '../utils/locale';

const POST_PER_PAGE = 6;

const BlogCategory = ({ data, pageContext }) => {
	const result = data.allDatoCmsBlogPost.nodes;
	const dateSortedResults = result.sort((a, b) => {
		const updatedAtA = dateToUTC(new Date(a.date || a.meta.updatedAt));
		const updatedAtB = dateToUTC(new Date(b.date || b.meta.updatedAt));

		return +updatedAtB - +updatedAtA;
	});
	const { page, pageNumbers, currentPageResults } = usePagination(result.length, POST_PER_PAGE);
	const location = useLocation();
	const locale = LOCALE_PATHS[pageContext.locale];

	return (
		<DefaultLayout>
			<HrefLang locales={SUPPORTED_LOCALES} slug={`blog/${data.datoCmsBlogCategory.slug}`} />
			<DatoSEO path={location.pathname} meta={data.datoCmsBlogCategory.seoMetaTags} />
			<div
				className="max-w-6xl mx-auto bg-no-repeat md:px-6 xs-bg-img-none md:bg-right-top xl:px-0"
				style={{
					backgroundImage: `url(${CarouselBg})`,
				}}
			>
				<Breadcrumbs>
					<Breadcrumb href={buildLink(locale, 'blog')} position={0}>
						Blog
					</Breadcrumb>
					<Breadcrumb position={1}>{data.datoCmsBlogCategory.name}</Breadcrumb>
				</Breadcrumbs>

				<h1 className="mt-2 text-2xl font-bold md:text-3xl">
					{data.datoCmsBlogCategory.name}
				</h1>

				<div className="flex flex-col items-start mt-10 mb-6 md:flex-row">
					<div className="md:w-7/12 lg:w-8/12">
						<div>
							{result.length === 0 && (
								<p className="mb-12 text-xl font-bold">No posts</p>
							)}

							{currentPageResults(dateSortedResults).map(
								({
									title,
									subtitle,
									thumbnail,
									image,
									category,
									slug,
									meta: { updatedAt },
									date,
								}) => (
									<BlogCard
										key={nanoid()}
										title={title}
										subtitle={subtitle}
										updatedAt={updatedAt}
										date={date}
										image={thumbnail || image}
										category={category}
										slug={slug}
										locale={locale}
									/>
								),
							)}

							<div className="mt-4">
								<Pagination page={page} pageNumbers={pageNumbers} />
							</div>
						</div>
					</div>
					<div className="mt-6 md:pl-6 md:w-5/12 lg:w-4/12 md:mt-0">
						<div
							className="px-6 py-4 bg-white bg-right-top bg-no-repeat bg-contain border border-gray-200 rounded shadow-lg"
							style={{
								backgroundImage: `url(${CarouselBg})`,
								backgroundPosition: '175px -150px',
							}}
						>
							<h3 className="mb-4 text-2xl font-bold leading-snug text-brand-500">
								Subscribe for updates
							</h3>
							<p className="mb-8">
								Looking for the latest in HR, advice and tips? Get our latest news,
								articles, webinars and podcasts right in your inbox.
							</p>
							<div className="w-fit">
								<LinkButton
									target="_blank"
									href="https://pages.brighthr.com/newsletter-signup.html"
									outlineButton
									text="Subscribe now"
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</DefaultLayout>
	);
};

export default BlogCategory;

export const query = graphql`
	query blogCat($id: String, $locale: String!) {
		datoCmsBlogCategory(id: { eq: $id }) {
			id
			slug
			name
			seoMetaTags {
				...GatsbyDatoCmsSeoMetaTags
			}
		}
		allDatoCmsBlogPost(
			filter: { category: { id: { eq: $id } }, locale: { eq: $locale }, slug: { ne: null } }
		) {
			nodes {
				...BlogCard
			}
		}
	}
`;
