import React from 'react';
import HeaderLink from '../../HeaderLink';

function MenuComponent({ links, collapsibleSubmenus, displayTextAppend }) {
	return (
		<div className="w-full xl:w-1/3">
			<ul className="flex flex-col w-full bg-white">
				{links.map(
					(link) =>
						link.hideOnMobile !== true && (
							<li key={link.displayText} data-testid={`nav-link-${link.displayText}`}>
								<HeaderLink
									link={link}
									collapsibleSubmenus={collapsibleSubmenus}
									displayTextAppend={displayTextAppend}
								/>
							</li>
						),
				)}
			</ul>
		</div>
	);
}

export default MenuComponent;
