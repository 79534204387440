import React, { useState } from 'react';
import cn from 'classnames';

import Chevon from '../../icons/ChevronDown.svg';

const CollapsibleTree = ({ items, depth = 0 }) => {
	const [open, setOpen] = useState(false);

	if (!items) return null;

	return items.map(({ text, href, children }) => {
		const isOpen = open === href;
		return (
			<div className={cn('mb-2', depth > 0 && 'ml-4')} key={href}>
				<div className="flex flex-row items-center mb-2">
					<a href={href} className="text-brand-500 hover:text-brand-400">
						{text}
					</a>

					{children?.length > 0 && (
						<button
							type="button"
							className="ml-auto align-middle focus:outline-none text-brand-500"
							onClick={() => setOpen((o) => (o === href ? null : href))}
						>
							<Chevon
								width="34"
								height="30"
								className={cn('ml-2', {
									'transition-transform duration-100 rotate-180': isOpen,
								})}
							/>
						</button>
					)}
				</div>
				<div
					className={cn(
						{
							hidden: !isOpen,
						},
						depth === 0 ? 'border-l-2 border-gray-300' : 'hidden',
					)}
				>
					<CollapsibleTree items={children} depth={depth + 1} />
				</div>
			</div>
		);
	});
};

export default CollapsibleTree;
