import React, { useState } from 'react';
import DemoCTA from '../DemoCTA';

const getSource = (vimeoId, youtubeId) => {
	if (youtubeId) {
		return `https://www.youtube.com/embed/${youtubeId}?autoplay=1&modestbranding=true&rel=0`;
	}

	if (vimeoId) {
		return vimeoId.includes('?')
			? `https://player.vimeo.com/video/${vimeoId}&autoplay=1`
			: `https://player.vimeo.com/video/${vimeoId}?autoplay=1`;
	}

	return '';
};

const BlockVideo = ({
	youtubeId,
	vimeoId,
	header,
	title,
	subText,
	imgSrc,
	alt,
	primaryCtaText,
	primaryCtaHref,
	showModal,
}) => {
	const [open, setOpen] = useState(false);

	return (
		<div className="relative">
			<div className="absolute w-full h-full">
				<div className="w-full h-full customVideoBackground " />
			</div>
			<div className="relative px-8 text-center">
				<p className="w-auto mx-auto text-xl font-semibold text-brand-400">{header}</p>
				<h2 className="pt-2 mb-6 text-3xl font-bold md:text-4xl md:mb-16">{title}</h2>
				<div className="min-h-[200px] relative items-center mx-auto xl:w-7xl xl:h-4xl">
					<div className="relative w-full h-full">
						<button
							type="button"
							className="relative w-full mx-auto shadow aspect-video"
							onClick={() => setOpen(true)}
						>
							{!open && (
								<div>
									<img
										className="z-10 w-full rounded-md cursor-pointer md:rounded-xl"
										src={imgSrc}
										alt={alt}
									/>
									<img
										className="absolute top-0 left-0 w-full rounded-md cursor-pointer md:rounded-xl"
										src="https://www.datocms-assets.com/23428/1644488878-play_overlay.png"
										alt="Play icon and video scrubber"
									/>
								</div>
							)}
							{open && (
								<iframe
									className="absolute top-0 left-0 w-full rounded-md aspect-video md:rounded-xl"
									src={getSource(vimeoId, youtubeId)}
									frameBorder="0"
									allow="autoplay"
									title="iframe video"
								/>
							)}
						</button>
					</div>
				</div>
				<p className="mt-16 font-normal text-md">{subText}</p>
				<div className="mt-4">
					<DemoCTA
						primaryCtaText={primaryCtaText}
						primaryCtaHref={primaryCtaHref}
						showModal={showModal}
					/>
				</div>
			</div>
		</div>
	);
};

export default BlockVideo;
