const createSubscription = async ({
	customerId,
	priceId,
	amountOfEmployees,
	couponId,
	setProcessing,
}) => {
	const subscription = await fetch('/api/stripe/subscription', {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			customerId,
			priceId,
			amountOfEmployees,
			couponId,
		}),
	})
		.then((res) => res.json())
		.catch(() => {
			setProcessing(false);
			throw new Error(
				'Could not create a subscription at this time. Please contact our Sales team on 0800 470 2432',
			);
		});

	return subscription;
};

export default createSubscription;
