import React, { useEffect } from 'react';

const AuthenticationCompleted = () => {
	useEffect(() => {
		window.top.postMessage('authenticationCompleted');
	}, []);

	return (
		<div>
			<h1>Authentication Completed</h1>
			<p>Please wait for screen to update</p>
		</div>
	);
};

export default AuthenticationCompleted;
