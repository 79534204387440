import React, { useLayoutEffect } from 'react';
import loadScript from 'load-script2';
import { graphql } from 'gatsby';
import { useLocation } from '@reach/router';
import { GatsbyImage } from 'gatsby-plugin-image';
import { useLocale } from '../context/Locale';
import Logo from '../components/Logo';
import MasterLayout from '../layouts/Master';
import CopyBlock from '../components/CopyBlock';
import TermsCopyrightBlock from '../components/TermsCopyrightBlock';
import CopyBlockProvider from '../components/CopyBlock/provider';
import ReviewBadges from '../components/ReviewBadges';
import LocaleContent from '../components/LocaleContent';
import DatoSEO from '../components/DatoSEO';

const BookADemoLandbot = ({ data }) => {
	const {
		seoMetaTags,
		configUrl,
		backgroundImage,
		quoteNode,
		contentNode,
		mobileHeaderAward,
		heading,
	} = data.datoCmsLandbotPage;

	const locale = useLocale();

	useLayoutEffect(() => {
		loadScript('https://cdn.landbot.io/landbot-3/landbot-3.0.0.js')
			.then(() => {
				// eslint-disable-next-line no-unused-vars
				const landbot = new window.Landbot.Container({
					container: '#myLandbot',
					configUrl: `${configUrl}`,
				});
			})
			.catch(() => {
				window.location.href = `https://www.brighthr.com/pricing/`;
			});
	}, [configUrl, locale]);

	const hasQuoteText = quoteNode.childMdx.rawBody;
	const location = useLocation();

	return (
		<>
			<DatoSEO path={location.pathname} locale={locale} meta={seoMetaTags} />
			<div className="relative flex flex-row overflow-hidden content">
				<main className="flex-1 lg:h-screen">
					<div className="h-screen">
						<div className="fixed inset-x-0 px-3 py-2 space-y-2 bg-white border-b">
							<div className="flex items-center justify-between space-x-6">
								<div className="block text-brand-500">
									<a href="/" aria-label="Home">
										<Logo className="h-12" />
									</a>
								</div>

								<div className="lg:hidden">
									{mobileHeaderAward &&
										(mobileHeaderAward.format !== 'svg' ? (
											<GatsbyImage
												image={mobileHeaderAward.gatsbyImageData}
												alt={
													mobileHeaderAward.alt ||
													'mobile header award image'
												}
												className="max-w-10"
											/>
										) : (
											<img
												src={mobileHeaderAward.url}
												alt={
													mobileHeaderAward.alt ||
													'mobile header award image'
												}
												className="max-h-12"
											/>
										))}
								</div>
							</div>
						</div>
						<div id="myLandbot" className="h-screen pt-24 overflow-hidden lg:h-full" />
					</div>
				</main>
				<aside
					style={{
						backgroundImage: `url(${backgroundImage.url})`,
					}}
					className="relative z-10 hidden h-screen max-w-lg text-white bg-center bg-cover bg-brand-500 lg:block"
				>
					<div className="absolute inset-0 bg-gray-900 opacity-90" />
					<div className="relative flex flex-col h-screen overflow-auto">
						<section className="px-12 py-12 ">
							<div className="space-y-6 lg:mb-0">
								<h1 className="mb-4 text-lg font-bold leading-none last:mb-0 md:text-xl">
									{heading}
								</h1>
								<CopyBlock>{contentNode.childMdx.body}</CopyBlock>
							</div>
						</section>

						<section className="px-12 pb-12 lg:flex lg:flex-col lg:flex-auto lg:justify-end">
							<div className="relative hidden mb-12 xl:block">
								{hasQuoteText.length > 0 && (
									<div className="text-white -translate-y-4">
										<div className="w-8 opacity-30">
											<svg
												className="w-full"
												viewBox="0 0 37 26"
												fill="none"
												xmlns="http://www.w3.org/2000/svg"
											>
												<path
													d="M8.086 9.62a8.05 8.05 0 1 1-8.05 8.05L0 16.52C0 7.627 7.208.42 16.099.42v4.6a11.43 11.43 0 0 0-8.132 3.367c-.419.419-.8.863-1.143 1.33a8.15 8.15 0 0 1 1.26-.1l.002.003Zm20.698 0a8.05 8.05 0 1 1-8.049 8.05l-.037-1.15c0-8.892 7.208-16.1 16.099-16.1v4.6a11.43 11.43 0 0 0-8.132 3.367c-.419.419-.8.863-1.143 1.33a8.157 8.157 0 0 1 1.262-.1v.003Z"
													fill="#ffffff"
												/>
											</svg>
										</div>
									</div>
								)}
								<div className="relative z-20 text-white">
									<CopyBlock>{quoteNode?.childMdx.body}</CopyBlock>
								</div>
							</div>
							<div className="flex flex-col items-center mb-12 justify-evenly lg:flex-row lg:space-between">
								<LocaleContent showInLocales={['en', 'ie']}>
									<ReviewBadges
										locale={locale}
										pageName="request quote"
										width="160"
										g2AwardClasses="max-w-[150px]"
									/>
								</LocaleContent>
								<LocaleContent showInLocales={['ca', 'au', 'nz']}>
									<ReviewBadges
										locale={locale}
										pageName="request quote"
										width={locale === 'ca' ? '110' : '170'}
										g2AwardClasses="max-w-[150px]"
									/>
								</LocaleContent>
							</div>
							<TermsCopyrightBlock />
						</section>
					</div>
				</aside>
			</div>
		</>
	);
};

const WrappedBookADemoLandbot = (props) => (
	<MasterLayout>
		<CopyBlockProvider>
			<BookADemoLandbot {...props} />
		</CopyBlockProvider>
	</MasterLayout>
);

export default WrappedBookADemoLandbot;

export const query = graphql`
	query landbotPage($locale: String!, $id: String!) {
		datoCmsLandbotPage(locale: { eq: $locale }, id: { eq: $id }) {
			seoMetaTags {
				...GatsbyDatoCmsSeoMetaTags
			}
			contentNode {
				childMdx {
					body
					rawBody
				}
			}
			heading
			backgroundImage {
				url
			}
			quoteNode {
				childMdx {
					body
					rawBody
				}
			}
			mobileHeaderAward {
				url
				alt
				format
				gatsbyImageData
			}
			awards {
				url
				alt
			}
			configUrl
		}
	}
`;
