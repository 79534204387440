import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import loadScript from 'load-script2';
import submitMarketoForm from '../../../../utils/PPC/submitMarketo';
import FormFooterLinks from '../../FormFooterLinks';
import Input from '../../Input';

const InlineForm = ({ formTitle, formSubTitle, telephoneNumber }) => {
	useEffect(() => {
		// Preloads the Marketo form for background submission
		const loadForm = () =>
			loadScript('//app-lon04.marketo.com/js/forms2/js/forms2.min.js')
				.then(() => {
					window.MktoForms2.loadForm(
						'//app-lon04.marketo.com',
						'217-MIC-854',
						2796,
						(form) => {
							form.onSuccess(() => false);
						},
					);
				})
				.catch(() => {});

		if (window.requestIdleCallback) {
			window.requestIdleCallback(loadForm);
		} else {
			setTimeout(loadForm);
		}
	}, []);

	const { handleChange, handleSubmit, setStatus, values, isSubmitting, submitted, error } =
		useFormik({
			initialValues: {
				FirstName: '',
				LastName: '',
				Email: '',
				Company: '',
				Phone: '',
			},
			onSubmit: async (data) => {
				submitMarketoForm(data)
					.then(() => {
						setStatus('submitted');
					})
					.catch(() => {
						setStatus('error');
					});
			},
		});

	return (
		<div>
			<div id="form" className="pt-32 anchor" />

			<form
				className="max-w-2xl p-8 mx-auto bg-white border border-gray-300 rounded-lg shadow-2xl"
				onSubmit={handleSubmit}
			>
				{error && (
					<p className="text-lg font-bold">
						Sorry we have been unable to book your demo, please call us on{' '}
						{telephoneNumber || '0800 470 2432'}.
					</p>
				)}
				{!error && !submitted && (
					<div>
						{formTitle && (
							<h3 className="mb-4 text-3xl font-bold text-center">{formTitle}</h3>
						)}
						{formSubTitle && <p className="mb-4 text-xl text-center">{formSubTitle}</p>}

						<div className="text-left">
							<Input
								onChange={handleChange}
								value={values.FirstName}
								type="text"
								label="First name"
								name="FirstName"
								placeholder="John"
								required
							/>
							<Input
								onChange={handleChange}
								value={values.LastName}
								type="text"
								label="Last name"
								name="LastName"
								placeholder="Smith"
								required
							/>
							<Input
								onChange={handleChange}
								value={values.Email}
								type="email"
								name="Email"
								label="Email address"
								placeholder="john.smith@acmeinc.com"
								required
							/>
							<Input
								onChange={handleChange}
								value={values.Company}
								type="text"
								label="Company name"
								name="Company"
								placeholder="Acme Inc."
								required
							/>
							<Input
								onChange={handleChange}
								value={values.Phone}
								type="tel"
								label="Phone number"
								name="Phone"
								placeholder="1300 029 198"
								pattern="[0-9\s]{7,16}"
								title="Enter a valid telephone number between 7 and 16 digits."
								required
							/>

							<div className="mt-3 text-center">
								<button
									type="submit"
									disabled={isSubmitting}
									className="block w-full px-6 py-3 font-bold text-center text-white rounded-md md:inline-block bg-secondary-300 hover:bg-secondary-400"
								>
									Start my free demo
								</button>
							</div>
						</div>
						<FormFooterLinks className="text-sm text-center" />
					</div>
				)}
				{submitted && <p className="text-lg font-bold">Form successfully submitted.</p>}
			</form>
		</div>
	);
};

export default InlineForm;
