/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import Markdown from 'markdown-to-jsx';
import { graphql } from 'gatsby';

import cn from 'classnames';
import Icon from '@brighthr/component-icon';
import LinkButton from '@brighthr/component-linkbutton';
import { useLocation } from '@reach/router';
import { buildLink, localisedRead } from '../../../utils/locale';

const getSource = (videoId) => {
	const isNum = /^\d+$/.test(videoId);

	if (!isNum) {
		return `https://www.youtube.com/embed/${videoId}?&modestbranding=true&rel=0`;
	}

	if (isNum) {
		return `https://player.vimeo.com/video/${videoId}`;
	}

	return '';
};

const Question = ({
	id,
	originalId,
	standalone,
	question,
	answer,
	videoId,
	assets = [],
	subTopic,
	topic,
	locale,
}) => {
	const [open, setOpen] = useState(false);
	const [isCopied, setIsCopied] = useState(false);
	const hasVideo = videoId;
	const hasAssets = assets && assets.length > 0;
	const hashId = `faq-${originalId || id}`;
	const location = useLocation();

	useEffect(() => {
		if (window.location.hash === `#${hashId}`) {
			setOpen(true);
		}
	}, [hashId]);

	const handleCopyClick = () => {
		const searchParam = typeof window !== 'undefined' && window.location.search;

		navigator.clipboard.writeText(
			`${location.origin}${buildLink(locale, `help/faqs/${topic.slug}/${subTopic.slug}`)}${
				searchParam ? `${searchParam}` : ''
			}#${hashId}`,
		);

		setIsCopied(true);

		setTimeout(() => {
			setIsCopied(false);
		}, 1500);
	};

	return (
		<div
			id={hashId}
			data-id={id}
			className={cn('px-5 py-4 bg-white rounded', {
				'shadow-md border border-gray-100': standalone,
			})}
			style={{
				scrollMarginTop: '6.75rem',
			}}
		>
			<div
				role="button"
				className="flex flex-row flex-wrap items-center justify-between rounded outline-none sm:flex-nowrap sm:space-x-4 focus:bg-gray-200"
				onClick={() => {
					setOpen((o) => !o);
					if (window.history.pushState) {
						window.history.pushState(null, null, `#${hashId}`);
					}
				}}
				tabIndex="0"
				onKeyDown={(e) => {
					if (e.key === 'Enter') {
						setOpen((o) => !o);
					}
				}}
			>
				<div className="items-center justify-center hidden p-3 text-lg font-bold leading-none bg-gray-200 rounded sm:flex text-brand-500">
					Q.
				</div>

				<h3 className="order-3 w-full text-lg font-bold leading-snug sm:order-none">
					<span className="sm:hidden text-brand-500">Q. </span>
					{question}
				</h3>

				<p className="hidden px-3 py-1 text-xs font-bold text-gray-600 bg-gray-200 rounded-full whitespace-nowrap sm:block">
					<a
						className="transition-colors duration-150 hover:text-gray-800"
						href={buildLink(locale, `help/faqs/${topic.slug}/${subTopic.slug}`)}
						onClick={(e) => e.stopPropagation()}
					>
						{subTopic?.name}
					</a>
				</p>
			</div>
			{open && (
				<div className="flex items-stretch w-full h-full mt-3 rounded outline-none sm:mt-8 sm:space-x-4 focus:bg-gray-200">
					<div className="items-start self-stretch justify-center hidden p-3 text-lg font-bold leading-none rounded sm:flex bg-positive text-green">
						A.
					</div>

					<div className="flex flex-wrap items-start justify-start w-full md:flex-nowrap">
						<div
							className={cn('w-full max-w-full mb-6  lg:mb-0', {
								'pr-6': hasAssets,
							})}
						>
							<Markdown className="prose">{answer}</Markdown>
							<button
								className="flex items-center mt-2 font-bold text-brand-500"
								tabIndex="0"
								type="button"
								onClick={handleCopyClick}
							>
								<Icon iconName="link" size={26} className="mr-2 fill-brand-500" />{' '}
								{isCopied ? 'Copied!' : 'Copy link to Clipboard'}
							</button>
						</div>

						{(hasVideo || hasAssets) && (
							<div className="flex flex-col w-full space-y-4 shrink-0 md:w-1/2 lg:w-1/3 xl:w-1/4">
								{hasVideo && (
									<div className="embed-responsive embed-responsive-16by9">
										<iframe
											title={question}
											className=""
											src={getSource(videoId)}
											frameBorder="0"
											allow="autoplay; fullscreen"
											allowFullScreen
										/>
									</div>
								)}
								{hasAssets &&
									assets.map(({ title, url }) => (
										<div className="p-4 border border-gray-600 rounded">
											<h4 className="mb-2 text-sm font-bold leading-tight">
												{title}
											</h4>
											<div className="w-fit">
												<LinkButton
													color="accent"
													outlineButton
													href={url}
													size="lg"
													text="Download"
												/>
											</div>
										</div>
									))}
							</div>
						)}
					</div>
				</div>
			)}
		</div>
	);
};

export default Question;

export const isValidForLocale = (q, locale) =>
	(locale === 'en-AU' && q.showInAu) ||
	(locale === 'en-CA' && q.showInCa) ||
	(locale === 'en-IE' && q.showInIe) ||
	(locale === 'en-NZ' && q.showInNz) ||
	(locale === 'en' && q.showInUk);

export const format = (question, locale) => ({
	id: question.id,
	originalId: question.originalId,
	question: localisedRead(question._allQuestionLocales, locale),
	answer: localisedRead(question._allAnswerLocales, locale),
	videoId: localisedRead(question._allVideoIdLocales, locale),
	assets: localisedRead(question._allAssetsLocales, locale),
	subTopic: {
		slug: localisedRead(question.subTopic._allSlugLocales, locale),
		name: localisedRead(question.subTopic._allNameLocales, locale),
	},
	topic: question.topic,
	showInAu: question.showInAu,
	showInCa: question.showInCa,
	showInIe: question.showInIe,
	showInNz: question.showInNz,
	showInUk: question.showInUk,
});

// For raw queries
export const QuestionFragment = `
	fragment QuestionCard on SupportQuestionRecord {
		id
		subTopic {
			_allSlugLocales {
				locale
				value
			}
			_allNameLocales {
				locale
				value
			}
		}
		topic {
			slug
			name
		}
		showInAu
		showInCa
		showInIe
		showInNz
		showInUk
		_allAnswerLocales(markdown: false) {
			locale
			value
		}
		_allAssetsLocales {
			locale
			value {
				title
				url
				format
			}
		}
		_allQuestionLocales {
			locale
			value
		}
		_allSlugLocales {
			locale
			value
		}
		_allVideoIdLocales {
			locale
			value
		}
	}
`;

export const query = graphql`
	fragment QuestionCard on DatoCmsSupportQuestion {
		originalId
		id
		subTopic {
			_allSlugLocales {
				locale
				value
			}
			_allNameLocales {
				locale
				value
			}
		}
		topic {
			slug
			name
		}
		showInAu
		showInCa
		showInIe
		showInNz
		showInUk
		_allAnswerLocales {
			locale
			value
		}
		_allAssetsLocales {
			locale
			value {
				title
				url
				format
			}
		}
		_allQuestionLocales {
			locale
			value
		}
		_allSlugLocales {
			locale
			value
		}
		_allVideoIdLocales {
			locale
			value
		}
	}
`;
/* eslint-enable no-underscore-dangle */
