import React, { useEffect, useMemo, useState, useRef } from 'react';
import { graphql } from 'gatsby';
import qs from 'qs';

import { createGlobalStyle } from 'styled-components';
import PPCLayout from '../layouts/PPC';
import PPCHeader from '../components/PPCHeader';
import ImageWithCopy from '../components/CMSBlocks/ImageWithCopy';
import VerticalImageScrollWithCopy from '../components/CMSBlocks/VerticalImageScrollWithCopy';
import Container from '../components/Container';
import CarouselWithCopy from '../components/CMSBlocks/CarouselWithCopy';
import HighlightedCopy from '../components/CMSBlocks/HighlightedCopy';
import TestimonialCards from '../components/CMSBlocks/TestimonialCards';
import BlobTestimonial from '../components/CMSBlocks/BlobTestimonial';
import FeatureCards from '../components/CMSBlocks/FeatureCards';
import Copy from '../components/CMSBlocks/Copy';
import VideoModal from '../components/VideoModal';
import CopyBlockProvider from '../components/CopyBlock/provider';
import PricingCards from '../components/CMSBlocks/PricingCards';
import DemoForm from '../components/CMSBlocks/DemoForm';
import { trackAffiliate } from '../utils/trackAffiliate';
import DatoSEO from '../components/DatoSEO';
import GoogleReviewsTestimonials from '../components/GoogleReviewsTestimonials';
import ReviewBadges from '../components/ReviewBadges';
import getLeadSource from '../utils/leadSource';

import HeroWithBgImage from '../components/Heros/WithBgImage';
import BlobTestimonialTwo from '../components/CMSBlocks/BlobTestimonialTwo';
import LocaleContent from '../components/LocaleContent';

const HashAnchor = createGlobalStyle`
.anchor {
	display: block;
	position: relative;
	top: -160px;
	visibility: hidden;
}
`;

const PartnerPage = ({ data, location }) => {
	const {
		elements,
		elements2,
		showHideVc,
		navBackgroundColour,
		verticalCarouselParent,
		verticalCarouselSubtitle,
		verticalCarouselTitle,
		increasePadding,
		affiliateId,
		heroThumbnail,
		phoneNumber,
		phoneText,
		trustPilotPosition,
		title,
		videoId,
		descriptionNode,
		logo,
		isVideoHero,
		youtubeId,
		vimeoId,
		underneathText,
		heroImage,
		badge,
		hasBuyOnline,
		seoMetaTags,
		locale,
		referrerField,
	} = data.datoCmsPartner;

	const { googleReviews } = data;
	const [isVideoVisible, setVideoVisible] = useState(false);
	const qsObj = qs.parse(location.search, { ignoreQueryPrefix: true });
	const transactionId = qsObj.txid || qsObj.txId;

	useEffect(() => {
		trackAffiliate(affiliateId);
	}, [affiliateId]);

	if (!title) {
		return null;
	}

	const containerRef = useRef(null);
	const leadSource = useMemo(() => {
		if (affiliateId) {
			return getLeadSource(locale, `${affiliateId} - Book a Demo`);
		}
		return getLeadSource(locale, 'Book a Demo');
	}, [locale, affiliateId]);

	return (
		<>
			<DatoSEO path={location.pathname} meta={seoMetaTags}>
				<meta name="robots" content="noindex" />
			</DatoSEO>

			<CopyBlockProvider>
				<HashAnchor />
				{isVideoVisible && (
					<VideoModal youtubeId={videoId} onClose={() => setVideoVisible(false)} />
				)}

				<PPCHeader
					logoSrc={logo?.url}
					logoAlt={logo?.alt}
					phoneNumber={phoneNumber}
					phoneText={phoneText}
					navBackgroundColour={navBackgroundColour}
				/>

				<HeroWithBgImage
					heroImage={heroImage.gatsbyImageData}
					badge={badge || null}
					heroThumbnail={heroThumbnail?.gatsbyImageData}
					isVideoHero={isVideoHero}
					underneathText={underneathText}
					youtubeId={youtubeId}
					vimeoId={vimeoId}
					logo={logo}
					title={title}
					descriptionMdx={descriptionNode.childMdx.body}
				>
					<div className="flex flex-row items-center gap-8">
						<LocaleContent showInLocales={['en', 'ie']}>
							<ReviewBadges
								locale={locale}
								pageName="partner"
								width="224"
								height="60"
								classes="w-48 lg:w-56"
								g2AwardClasses="w-24 lg:w-28"
							/>
						</LocaleContent>
						<LocaleContent showInLocales={['ca', 'au', 'nz']}>
							<ReviewBadges
								locale={locale}
								pageName="partner"
								width={locale === 'ca' ? '110' : '170'}
								height={locale === 'ca' ? '91' : '80'}
								classes="fill-current"
								g2AwardClasses="ml-2 w-24 lg:w-28"
							/>
						</LocaleContent>
					</div>
				</HeroWithBgImage>

				{trustPilotPosition === 'Top' && (
					<Container>
						<LocaleContent showInLocales="ca">
							<GoogleReviewsTestimonials
								googleReviews={googleReviews}
								starClass="text-gold"
								textClass="text-black"
							/>
						</LocaleContent>
					</Container>
				)}

				<div className="max-w-full overflow-hidden">
					{elements.map((el) => (
						<div className="mb-6 md:mb-12" key={el.id}>
							{el.blocktype === 'ImageWithCopy' && (
								<ImageWithCopy
									imgSrc={el.imageWithCopyImage.gatsbyImageData}
									alt={el.imageWithCopyImage.alt}
									text={el.textNode.childMdx.body}
									imagePosition={el.imagePosition}
									bgColor={el.backgroundColour}
								/>
							)}

							{el.blocktype === 'BlobTestimonial' && (
								<BlobTestimonial
									large={el.makeQuoteBlockLarge}
									imgSrc={el.blobTestimonialImage.gatsbyImageData}
									alt={el.blobTestimonialImage.alt}
									text={el.textNode.childMdx.body}
									testimonial={el.testimonialNode.childMdx.body}
									companyLogoSrc={el.companyLogo?.gatsbyImageData}
									companyLogoAlt={el.companyLogo?.alt}
									testimonialPosition={
										el.testimonialPosition === null
											? 'Left'
											: el.testimonialPosition
									}
								/>
							)}
							{el.blocktype === 'BlobTestimonialTwo' && (
								<BlobTestimonialTwo
									large={el.makeQuoteBlockLarge}
									imgSrc={el.blobTestimonialImage.gatsbyImageData}
									alt={el.blobTestimonialImage.alt}
									text={el.textNode.childMdx.body}
									testimonial={el.testimonialNode.childMdx.body}
									companyLogoSrc={el.companyLogo?.gatsbyImageData}
									companyLogoAlt={el.companyLogo?.alt}
								/>
							)}
							{el.blocktype === 'CarouselWithCopy' && (
								<CarouselWithCopy
									slides={el.images}
									text={el.textNode.childMdx.body}
									imagePosition={el.imagePosition}
								/>
							)}
							{el.blocktype === 'HighlightedCopy' && (
								<HighlightedCopy version={el.version}>
									{el.textNode.childMdx.body}
								</HighlightedCopy>
							)}
							{el.blocktype === 'FeatureCards' && (
								<FeatureCards
									title={el.title}
									descriptionNode={el.descriptionNode}
									features={el.features}
								/>
							)}
							{el.blocktype === 'PricingCards' && (
								<PricingCards
									buyOnline={hasBuyOnline}
									protect={el.protect}
									prime={el.prime}
									connect={el.connect}
								/>
							)}
							{el.blocktype === 'TestimonialCards' && (
								<TestimonialCards
									title={el.title}
									descriptionNode={el.descriptionNode}
									testimonials={el.testimonials}
								/>
							)}
							{el.blocktype === 'Copy' && (
								<Copy
									centered={el.centered}
									background={el.background}
									width={el.width}
									textSize={el.textSize}
								>
									{el.textNode.childMdx.body}
								</Copy>
							)}

							{el.blocktype === 'DemoForm' && (
								<DemoForm
									showMessageField
									showFeefo
									pageContext="partner"
									hidePrivacyLinks
									title={el.title}
									ctaText={el.ctaText}
									formColor={el.formColor}
									transactionId={transactionId}
									leadSource={leadSource}
									referrerField={referrerField}
								/>
							)}
						</div>
					))}
				</div>

				{showHideVc && (
					<Container className="border-b-2 border-brand-400">
						<div ref={containerRef} className="relative ">
							<VerticalImageScrollWithCopy
								verticalCarouselParent={verticalCarouselParent}
								title={verticalCarouselTitle}
								subtitle={verticalCarouselSubtitle}
								increasePadding={increasePadding}
							/>
						</div>
					</Container>
				)}

				<div className="max-w-full overflow-hidden md:pt-12">
					{elements2.map((el2) => (
						<div className="mb-6 md:mb-12 last:mb-0 " key={el2.id}>
							{el2.blocktype === 'ImageWithCopy' && (
								<ImageWithCopy
									imgSrc={el2.imageWithCopyImage.gatsbyImageData}
									alt={el2.imageWithCopyImage.alt}
									text={el2.textNode.childMdx.body}
									imagePosition={el2.imagePosition}
									bgColor={el2.backgroundColour}
								/>
							)}

							{el2.blocktype === 'BlobTestimonial' && (
								<BlobTestimonial
									large={el2.makeQuoteBlockLarge}
									imgSrc={el2.blobTestimonialImage.gatsbyImageData}
									alt={el2.blobTestimonialImage.alt}
									text={el2.textNode.childMdx.body}
									testimonial={el2.testimonialNode.childMdx.body}
									companyLogoSrc={el2.companyLogo?.gatsbyImageData}
									companyLogoAlt={el2.companyLogo?.alt}
									testimonialPosition={
										el2.testimonialPosition === null
											? 'Left'
											: el2.testimonialPosition
									}
								/>
							)}
							{el2.blocktype === 'BlobTestimonialTwo' && (
								<BlobTestimonialTwo
									large={el2.makeQuoteBlockLarge}
									imgSrc={el2.blobTestimonialImage.gatsbyImageData}
									alt={el2.blobTestimonialImage.alt}
									text={el2.textNode.childMdx.body}
									testimonial={el2.testimonialNode.childMdx.body}
									companyLogoSrc={el2.companyLogo?.gatsbyImageData}
									companyLogoAlt={el2.companyLogo?.alt}
								/>
							)}
							{el2.blocktype === 'Carousel2WithCopy' && (
								<CarouselWithCopy
									slides={el2.images}
									text={el2.textNode.childMdx.body}
									imagePosition={el2.imagePosition}
								/>
							)}
							{el2.blocktype === 'HighlightedCopy' && (
								<HighlightedCopy version={el2.version}>
									{el2.textNode.childMdx.body}
								</HighlightedCopy>
							)}
							{el2.blocktype === 'FeatureCards' && (
								<FeatureCards
									title={el2.title}
									descriptionNode={el2.descriptionNode}
									features={el2.features}
								/>
							)}
							{el2.blocktype === 'PricingCards' && (
								<PricingCards
									buyOnline={hasBuyOnline}
									protect={el2.protect}
									prime={el2.prime}
									connect={el2.connect}
								/>
							)}
							{el2.blocktype === 'TestimonialCards' && (
								<TestimonialCards
									title={el2.title}
									descriptionNode={el2.descriptionNode}
									testimonials={el2.testimonials}
								/>
							)}
							{el2.blocktype === 'Copy' && (
								<Copy
									centered={el2.centered}
									background={el2.background}
									width={el2.width}
									textSize={el2.textSize}
								>
									{el2.textNode.childMdx.body}
								</Copy>
							)}

							{el2.blocktype === 'DemoForm' && (
								<DemoForm
									showMessageField
									showFeefo
									pageContext="partner"
									hidePrivacyLinks
									title={el2.title}
									ctaText={el2.ctaText}
									formColor={el2.formColor}
									transactionId={transactionId}
									leadSource={leadSource}
									referrerField={referrerField}
								/>
							)}
						</div>
					))}
				</div>

				{trustPilotPosition === 'Bottom' && (
					<Container>
						<LocaleContent showInLocales="ca">
							<GoogleReviewsTestimonials
								googleReviews={googleReviews}
								starClass="text-gold"
								textClass="text-black"
							/>
						</LocaleContent>
					</Container>
				)}
			</CopyBlockProvider>
		</>
	);
};

const WrappedPartner = (props) => (
	<PPCLayout fullWidth buyOnline={props.data.datoCmsPartner?.hasBuyOnline}>
		<PartnerPage {...props} />
	</PPCLayout>
);

export default WrappedPartner;

export const query = graphql`
	query seoAndPartner($locale: String!, $id: String!) {
		site {
			siteMetadata {
				title
			}
		}

		datoCmsPartner(locale: { eq: $locale }, id: { eq: $id }) {
			id
			locale
			seoMetaTags {
				...GatsbyDatoCmsSeoMetaTags
			}
			referrerField
			hasBuyOnline
			hasFreeAdvice
			phoneNumber
			phoneText
			trustPilotPosition
			verticalCarouselSubtitle
			verticalCarouselTitle
			showHideVc
			increasePadding
			navBackgroundColour
			heroThumbnail {
				gatsbyImageData(
					layout: CONSTRAINED
					width: 600
					imgixParams: { crop: "center", fit: "crop" }
				)
			}
			heroImage {
				gatsbyImageData(
					layout: CONSTRAINED
					width: 2000
					imgixParams: { crop: "faces,entropy", fit: "crop" }
				)
				url
				alt
			}
			badge {
				gatsbyImageData(layout: CONSTRAINED, width: 1000, imgixParams: { fit: "crop" })
				url
				alt
			}
			logo {
				alt
				url
				width
				height
				gatsbyImageData(
					layout: CONSTRAINED
					width: 200
					imgixParams: { crop: "faces,entropy", fit: "crop" }
				)
			}
			affiliateId
			title
			description
			descriptionNode {
				childMdx {
					body
				}
			}
			isVideoHero
			underneathText
			youtubeId
			vimeoId
			verticalCarouselParent {
				... on DatoCmsImageWithCopy {
					id
					blocktype
					imagePosition
					textNode {
						childMdx {
							body
						}
					}
					backgroundColour
					imageWithCopyImage {
						gatsbyImageData(
							layout: CONSTRAINED
							width: 590
							imgixParams: { fm: "webp" }
						)
						url
						alt
					}
				}
			}
			elements {
				... on DatoCmsImageWithCopy {
					id
					blocktype
					imagePosition
					backgroundColour
					textNode {
						childMdx {
							body
						}
					}
					imageWithCopyImage {
						gatsbyImageData(layout: CONSTRAINED, width: 590)
					}
				}

				... on DatoCmsBlobTestimonial {
					id
					blocktype
					makeQuoteBlockLarge
					testimonialPosition
					textNode {
						childMdx {
							body
						}
					}
					testimonialNode {
						childMdx {
							body
						}
					}
					companyLogo {
						alt
						gatsbyImageData(layout: CONSTRAINED, width: 195)
					}
					blobTestimonialImage {
						alt
						gatsbyImageData(layout: CONSTRAINED, width: 590)
					}
				}
				... on DatoCmsBlobTestimonialTwo {
					textNode {
						childMdx {
							body
						}
					}
					blobTestimonialImage {
						gatsbyImageData
					}
					id
					blocktype
					quoteSourceNode {
						childMdx {
							body
						}
					}
				}
				... on DatoCmsHighlightedCopy {
					id
					blocktype
					version
					textNode {
						childMdx {
							body
						}
					}
				}
				... on DatoCmsCopy {
					id
					blocktype
					textNode {
						childMdx {
							body
						}
					}
					background
					width
					centered
					textSize
				}
				... on DatoCmsCarouselWithCopy {
					id
					blocktype
					imagePosition
					textNode {
						childMdx {
							body
						}
					}
					images {
						alt
						gatsbyImageData(layout: CONSTRAINED, width: 590)
					}
				}
				... on DatoCmsTestimonialCard {
					id
					blocktype
					title
					descriptionNode {
						childMdx {
							body
						}
					}
					testimonials {
						quoteSource
						quoteTextNode {
							childMdx {
								body
							}
						}
					}
				}
				... on DatoCmsFeatureCard {
					id
					blocktype
					title
					descriptionNode {
						childMdx {
							body
						}
					}
					features {
						featureName
						cardDescriptionNode {
							childMdx {
								body
							}
						}
					}
				}
				... on DatoCmsPricingCard {
					id
					blocktype
					protect
					prime
					connect
				}
				... on DatoCmsDemoForm {
					id
					blocktype
					title
					ctaText
					formColor
				}
			}
			elements2 {
				... on DatoCmsImageWithCopy {
					id
					blocktype
					imagePosition
					backgroundColour
					textNode {
						childMdx {
							body
						}
					}
					imageWithCopyImage {
						gatsbyImageData(layout: CONSTRAINED, width: 590)
					}
				}

				... on DatoCmsBlobTestimonial {
					id
					blocktype
					makeQuoteBlockLarge
					testimonialPosition
					textNode {
						childMdx {
							body
						}
					}
					testimonialNode {
						childMdx {
							body
						}
					}
					companyLogo {
						alt
						gatsbyImageData(layout: CONSTRAINED, width: 195)
					}
					blobTestimonialImage {
						alt
						gatsbyImageData(layout: CONSTRAINED, width: 590)
					}
				}
				... on DatoCmsHighlightedCopy {
					id
					blocktype
					version
					textNode {
						childMdx {
							body
						}
					}
				}
				... on DatoCmsCopy {
					id
					blocktype
					textNode {
						childMdx {
							body
						}
					}
					background
					width
					centered
					textSize
				}
				... on DatoCmsCarouselWithCopy {
					id
					blocktype
					imagePosition
					textNode {
						childMdx {
							body
						}
					}
					images {
						alt
						gatsbyImageData(layout: CONSTRAINED, width: 590)
					}
				}
				... on DatoCmsTestimonialCard {
					id
					blocktype
					title
					descriptionNode {
						childMdx {
							body
						}
					}
					testimonials {
						quoteSource
						quoteTextNode {
							childMdx {
								body
							}
						}
					}
				}
				... on DatoCmsFeatureCard {
					id
					blocktype
					title
					descriptionNode {
						childMdx {
							body
						}
					}
					features {
						featureName
						cardDescriptionNode {
							childMdx {
								body
							}
						}
					}
				}
				... on DatoCmsPricingCard {
					id
					blocktype
					protect
					prime
					connect
				}
				... on DatoCmsDemoForm {
					id
					blocktype
					title
					ctaText
					formColor
				}
			}
		}

		googleReviews: allGoogleReview(
			limit: 6
			filter: { rating: { eq: 5 }, body: { ne: null } }
		) {
			nodes {
				id
				body
				rating
				date_utc
			}
		}
	}
`;
