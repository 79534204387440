import React from 'react';
import {
	BRIGHTHR,
	FURLOUGH,
	BLIP as BLIP_BASE,
	POP as POP_BASE,
	BRIGHTADVICE as BRIGHTADVICE_BASE,
	BRIGHTBASE as BRIGHTBASE_BASE,
	BRIGHTSAFE as BRIGHTSAFE_BASE,
	BRIGHTSAFEAPP,
	BRIGHTWELLBEING,
	BRIGHTEXCHANGE as BRIGHTEXCHANGE_BASE,
	BRIGHTSAFEADVICE as BRIGHTSAFEADVICE_BASE,
	VACCTRAK as VACCTRAK_BASE,
	BACKTOWORK as BACKTOWORK_BASE,
	PAYROLL as PAYROLL_BASE,
	SHIFTSANDSCHEDULES,
	STAFFVACATIONPLANNER,
	SICKLEAVE,
	DOCUMENTSTORAGE,
	OVERTIMETRACKER,
	EDUCATIONNAVIGATOR as EDUCATIONNAVIGATOR_BASE,
	CONNECT as CONNECT_BASE,
	PROTECT as PROTECT_BASE,
	PRIME as PRIME_BASE,
} from './pricing';

const BRIGHTADVICE = {
	...BRIGHTADVICE_BASE,
	shortName: 'Professional HR advice from our dedicated expert advisors',
	description:
		'Professional HR and employment law advice from our Canadian expert advisors available 24/7.',
};

const BRIGHTBASE = {
	...BRIGHTBASE_BASE,
	shortName: 'Library of downloadable HR policies, templates, and guides',
};

const POP = {
	...POP_BASE,
	shortName: 'PoP – Business expenses and mileage tracker app',
};

const BLIP = {
	...BLIP_BASE,
	shortName: 'Blip – Click in and out tracking app / software',
};

const BRIGHTSAFE = {
	...BRIGHTSAFE_BASE,
	shortName: 'BrightSafe – Health & Safety management software',
};

const BRIGHTEXCHANGE = {
	...BRIGHTEXCHANGE_BASE,
	shortName: 'Bright Exchange online marketplace',
};

const PAYROLL = {
	...PAYROLL_BASE,
	shortName: 'Payroll navigator',
};

const BRIGHTSAFEADVICE = {
	...BRIGHTSAFEADVICE_BASE,
	shortName: 'Access to professional H&S advisors you can speak to',
};

const VACCTRAK = {
	...VACCTRAK_BASE,
	shortName: 'VaccTrack',
};

const BACKTOWORK = {
	...BACKTOWORK_BASE,
	shortName: 'Back to work navigator tool',
};

const EDUCATIONNAVIGATOR = {
	...EDUCATIONNAVIGATOR_BASE,
	shortName: 'E-learning Health & Safety courses ',
};

export const BENEFITS = [
	SHIFTSANDSCHEDULES,
	STAFFVACATIONPLANNER,
	SICKLEAVE,
	DOCUMENTSTORAGE,
	OVERTIMETRACKER,
	VACCTRAK,
	BACKTOWORK,
	PAYROLL,
	POP,
	BLIP,
	BRIGHTEXCHANGE,
	BRIGHTADVICE,
	BRIGHTBASE,
	BRIGHTSAFE,
	EDUCATIONNAVIGATOR,
	BRIGHTSAFEAPP,
	BRIGHTSAFEADVICE,
];

const CONNECT = {
	...CONNECT_BASE,
	benefits: [BRIGHTHR, BRIGHTSAFE, BLIP, POP, BRIGHTEXCHANGE],
	fullBenefits: [
		BRIGHTEXCHANGE,
		FURLOUGH,
		VACCTRAK,
		PAYROLL,
		BLIP,
		POP,
		BACKTOWORK,
		SHIFTSANDSCHEDULES,
		STAFFVACATIONPLANNER,
		SICKLEAVE,
		DOCUMENTSTORAGE,
		OVERTIMETRACKER,
	],
};

const PROTECT = {
	...PROTECT_BASE,
	benefits: [BRIGHTADVICE, BRIGHTBASE, BRIGHTSAFEADVICE],
	fullBenefits: [
		BRIGHTEXCHANGE,
		FURLOUGH,
		VACCTRAK,
		PAYROLL,
		BLIP,
		POP,
		BACKTOWORK,
		BRIGHTADVICE,
		BRIGHTBASE,
		SHIFTSANDSCHEDULES,
		STAFFVACATIONPLANNER,
		SICKLEAVE,
		DOCUMENTSTORAGE,
		OVERTIMETRACKER,
	],
	subheading: (
		<>
			The <strong className="text-secondary-300">Connect</strong> package{' '}
			<strong className="uppercase">plus</strong> a team of Canadian HR advisors and templates
			at your finger tips. The fastest way to get expert advice for any business need or
			issue.
		</>
	),
};

const PRIME = {
	...PRIME_BASE,

	benefits: [],
	fullBenefits: [
		BRIGHTEXCHANGE,
		FURLOUGH,
		VACCTRAK,
		PAYROLL,
		BLIP,
		POP,
		BACKTOWORK,
		BRIGHTADVICE,
		BRIGHTBASE,
		BRIGHTSAFE,
		BRIGHTSAFEAPP,
		BRIGHTSAFEADVICE,
		BRIGHTWELLBEING,
		SHIFTSANDSCHEDULES,
		STAFFVACATIONPLANNER,
		SICKLEAVE,
		DOCUMENTSTORAGE,
		OVERTIMETRACKER,
		EDUCATIONNAVIGATOR,
	],
};

export default {
	options: [CONNECT, PROTECT, PRIME],
};
