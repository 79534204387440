import React, { useState, useEffect } from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import Cookies from 'js-cookie';
import LazyLoad from 'react-lazyload';
import PlayOverlay from '../../../images/feature/play_overlay.png';
import CopyBlock from '../../CopyBlock';

const getSource = (vimeoId, youtubeVideoId) => {
	if (youtubeVideoId) {
		return `https://www.youtube.com/embed/${youtubeVideoId}?autoplay=1&modestbranding=true&rel=0`;
	}

	if (vimeoId) {
		return vimeoId.includes('?')
			? `https://player.vimeo.com/video/${vimeoId}`
			: `https://player.vimeo.com/video/${vimeoId}?autoplay=1`;
	}

	return '';
};

const InlineVideo = ({
	youtubeId,
	vimeoId,
	header,
	title,
	subText,
	imgSrc,
	alt,
	isGated,
	triggerGate,
	setAllowPlay,
	allowPlay,
	submitPlay,
}) => {
	const [play, setPlay] = useState(false);

	useEffect(() => {
		if (isGated && Cookies.get('bhr_ff') === '1') {
			setAllowPlay(true);
		}
	}, [isGated, setAllowPlay]);

	return (
		<div className="z-10">
			<div className="relative">
				<div className="absolute w-full h-full">
					<div className="w-full h-full customVideoBackground " />
				</div>

				<div className="relative mx-6 text-center">
					<p className="w-auto mx-auto text-base font-bold md:text-lg text-brand-400">
						{header}
					</p>
					<h2 className="mt-1 mb-8 text-2xl font-bold md:mb-12 md:text-3xl">{title}</h2>

					<div className="relative mx-auto text-center md:w-9/12 lg:w-2/3">
						<button
							type="button"
							className="relative w-full max-w-5xl mx-auto shadow cursor-pointer aspect-video rounded-2xl"
							onClick={() => (isGated && !allowPlay ? triggerGate() : setPlay(true))}
						>
							{!play && !submitPlay && (
								<LazyLoad once>
									<div className="z-10 w-full">
										<GatsbyImage
											image={imgSrc}
											alt={alt || title || ''}
											className="rounded-2xl"
										/>
										<img
											src={PlayOverlay}
											alt=""
											className="absolute top-0 left-0 w-full cursor-pointer rounded-2xl "
										/>
									</div>
								</LazyLoad>
							)}
							{(play || submitPlay) && (
								<iframe
									id="videoblockplayer"
									title="videoTitle - DYNAMIC"
									type="text/html"
									className="absolute top-0 w-full h-full rounded-md md:rounded-2xl"
									src={getSource(vimeoId, youtubeId)}
									frameBorder="0"
									allow="autoplay"
								/>
							)}
						</button>
					</div>
					{subText && (
						<div className="py-10">
							<CopyBlock>{subText}</CopyBlock>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default InlineVideo;
