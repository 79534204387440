import React from 'react';

import { useLocation } from '@reach/router';
import Cookies from 'js-cookie';
import Button from '@brighthr/component-button';

import { getLocale, LOCALES, LOCALE_CONFIG } from '../../utils/locale';
import { useLocaleUpdate } from '../../context/Locale';
import Flag from '../CountryFlag';
import Cross from '../../icons/Cross.svg';

const CountryModal = ({ setShow, prefferedUrl }) => {
	const location = useLocation();
	const pathLocale = getLocale(location.pathname);
	const cookieLocale = LOCALE_CONFIG[Cookies.get('nf_country')];
	const updateLocale = useLocaleUpdate();

	const handleCookieChange = (newLocale) => {
		Cookies.set('nf_country', newLocale);
		localStorage.setItem('locale-preference', newLocale);
	};

	const handleTakeMeThere = (newUrl) => {
		window.location.href = newUrl;
	};

	return (
		<div
			className="fixed top-0 left-0 flex items-center justify-center w-full h-full px-4 pt-12 pb-4 z-country-modal bg-gray-overlay lg:items-start lg:pt-20"
			data-testid="country-modal"
		>
			<div className="relative flex flex-col w-full h-full max-w-xl mx-auto text-center bg-white rounded-md shadow-lg lg:h-auto">
				<Flag
					countryCode={cookieLocale.countryCode}
					className="w-16 h-16 mx-auto -mt-8 rounded-full shadow-md "
				/>

				<button
					type="button"
					onClick={() => {
						handleCookieChange(pathLocale);
						setShow(false);
					}}
					className="absolute w-8 top-5 right-5"
				>
					<Cross />
				</button>

				<div className="flex flex-row flex-wrap items-center px-5 py-6 overflow-y-scroll lg:justify-around lg:px-20">
					<h2 className="w-full mb-1 text-lg font-bold leading-7">Hello!</h2>
					<p className="w-full mb-8 leading-6 text-l">
						This page is also available on BrightHR {cookieLocale.name}.<br />
						Would you like to visit the {cookieLocale.nameShort} site?
					</p>

					<div className="mx-auto mb-5 lg:mb-0 lg:w-auto lg:mx-0">
						<Button
							color="accent"
							outlineButton
							size="lg"
							onClick={() => {
								handleCookieChange(pathLocale);
								setShow(false);
							}}
							text={`No stay on ${LOCALE_CONFIG[pathLocale].nameShort.toUpperCase()}`}
						/>
					</div>

					<div className="mx-auto lg:w-auto lg:mx-0">
						<Button
							color="accent"
							size="lg"
							onClick={() => {
								handleCookieChange(cookieLocale.countryCode);
								handleTakeMeThere(prefferedUrl);
							}}
							text={`Yes take me to the ${cookieLocale.nameShort} site`}
						/>
					</div>
				</div>

				<div className="px-8 py-6 mt-auto bg-gray-200 rounded-b-md">
					<p className="text-sm leading-5">
						Or please select your country below so we can display the features of
						BrightHR that are most appropriate to you{' '}
					</p>

					<ul className="flex flex-wrap justify-around pt-6">
						{LOCALES.filter(
							(l) => l !== pathLocale && l !== cookieLocale.countryCode,
						).map((l) => (
							<li className="flex items-center" key={l}>
								<button
									type="button"
									className="block w-full leading-5"
									onClick={() => updateLocale(l)}
								>
									<Flag countryCode={l} className="inline-block w-5 h-5 mr-1" />
									<span className="inline-block leading-5 align-middle text-m">
										{LOCALE_CONFIG[l].nameShort} Site
									</span>
								</button>
							</li>
						))}
					</ul>
				</div>
			</div>
		</div>
	);
};

export default CountryModal;
