import React from 'react';
import cn from 'classnames';

const Heading = ({ id, children, level = 'h2' }) => {
	const Tag = level;

	const textSize = {
		h2: 'text-xl md:text-2xl',
		h3: 'text-lg md:text-xl',
		h4: 'text-base md:text-lg',
		h5: 'text-sm md:text-base',
	};

	return (
		<Tag id={id} className={cn('mb-4 font-bold leading-none last:mb-0', textSize[level])}>
			{children}
		</Tag>
	);
};

export default Heading;
