import React from 'react';
import LinkedIn from '../../../icons/LinkedIn.svg';
import TikTok from '../../../icons/TikTok.svg';
import Twitter from '../../../icons/TwitterCircleFilled.svg';

const LeadershipSocials = ({ tiktok, twitter, linkedin }) => (
	<>
		<div className="flex flex-row gap-2">
			{tiktok && (
				<a
					className="flex items-center float-left gap-4 group"
					href={tiktok}
					target="_blank"
					rel="noopener noreferrer"
				>
					<TikTok
						className="text-brand-500 group-hover:text-brand-400"
						width={32}
						aria-hidden
						fill="#0057AD"
					/>
				</a>
			)}
			{twitter && (
				<a
					className="flex items-center float-left gap-4 group"
					href={twitter}
					target="_blank"
					rel="noopener noreferrer"
				>
					<Twitter
						className="text-brand-500 group-hover:text-brand-400"
						width={32}
						aria-hidden
						fill="#0057AD"
					/>
				</a>
			)}
			{linkedin && (
				<a
					className="flex items-center float-left gap-4 group"
					href={linkedin}
					target="_blank"
					rel="noopener noreferrer"
				>
					<LinkedIn
						className="text-brand-500 group-hover:text-brand-400"
						width={32}
						aria-hidden
						fill="#0057AD"
					/>
				</a>
			)}
		</div>
	</>
);

export default LeadershipSocials;
