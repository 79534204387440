const mdxSplitter = (body, injectionId) => {
	if (!body) {
		return [];
	}

	if (body.search(injectionId) === -1 || injectionId === undefined) {
		return [body];
	}

	const startMdx =
		'const _excluded = ["components"];\n\nfunction _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }\n\nfunction _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }\n\nfunction _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }\n\n/* @jsxRuntime classic */\n\n/* @jsx mdx */\nconst _frontmatter = {};\nconst layoutProps = {\n  _frontmatter\n};\nconst MDXLayout = "wrapper";\nreturn function MDXContent(_ref) {\n  let {\n    components\n  } = _ref,\n      props = _objectWithoutProperties(_ref, _excluded);\n\n  return mdx(MDXLayout, _extends({}, layoutProps, props, {\n    components: components,\n    mdxType: "MDXLayout"\n  })';

	const endMdx = ');\n}\n;\nMDXContent.isMDXComponent = true;';

	const injectionIdMdx = `, mdx("p", null, \`${injectionId}\`)`;

	const splitBodyArray = body.replace(startMdx, '').replace(endMdx, '').split(injectionIdMdx);

	const firstSection = startMdx + splitBodyArray[0] + endMdx;
	const secondSection = startMdx + splitBodyArray[1] + endMdx;

	return [firstSection, secondSection];
};

export default mdxSplitter;
