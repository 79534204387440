import React from 'react';
import { Link } from 'gatsby';
import { format } from 'date-fns';
import { useLocale } from '../../context/Locale';
import { buildLink } from '../../utils/locale';

const NewsCard = ({ newsItem }) => {
	const { title, releaseDate, isArticleInternal, externalArticleLink, newsType, itemCountry } =
		newsItem;

	const locale = useLocale();

	const newsTypeMap = {
		PressRelease: 'Press Releases',
		InTheNews: 'In the News',
		Awards: 'Awards',
	};

	const newsTypeColor = {
		PressRelease: 'bg-brand-200',
		InTheNews: 'bg-secondary-100',
		Awards: 'bg-positive',
	};

	const newsTypeId = Object.keys(newsTypeMap && newsTypeColor).filter((key) =>
		newsType?.includes(key),
	);

	return (
		<>
			{itemCountry?.includes(locale) && (
				<div className="w-full my-4 border-2 border-gray-300 rounded-md shadow-sm lg:my-0">
					<div className="relative p-4 ">
						<div
							className={`absolute top-0 left-0 px-6 py-1 rounded-tl-sm uppercase font-semibold text-sm ${newsTypeColor[newsTypeId]}`}
						>
							<p>{newsTypeMap[newsTypeId]}</p>
						</div>
						<p className="pt-6 pb-2 ">
							<span className="pb-2 ">
								{format(new Date(releaseDate), 'eeee, MMM dd, yyyy')}
							</span>
						</p>
						<h3 className="text-xl mb-4 font-semibold h-[52px] overflow-hidden">
							{title}
						</h3>
						{isArticleInternal ? (
							<Link
								to={buildLink(locale, `news/${newsItem.slug}`)}
								target="_blank"
								rel="noreferrer"
								className="flex items-center justify-end font-bold text-brand-500"
							>
								Read more
							</Link>
						) : (
							<a
								href={externalArticleLink}
								target="_blank"
								rel="noreferrer nofollow"
								className="flex items-center justify-end font-bold text-brand-500"
							>
								Read more
							</a>
						)}
					</div>
				</div>
			)}
		</>
	);
};

export default NewsCard;
