import React from 'react';

import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import LinkButton from '@brighthr/component-linkbutton';
import cn from 'classnames';
import LazyLoad from 'react-lazyload';
import { useLocation } from '@reach/router';
import DefaultLayout from '../layouts/Default';
import DatoSEO from '../components/DatoSEO';
import personLeft from '../images/about-us/person-left.svg';
import personRight from '../images/about-us/person-right.svg';
import { useVar } from '../components/Var';
import HrefLang from '../components/HrefLang';
import { buildLink } from '../utils/locale';
import CopyBlock from '../components/CopyBlock';
import AboutUsCard from '../components/CMSBlocks/AboutUsCard';

const HR = ({ className = '' }) => (
	<hr className={`h-1 w-20 bg-secondary-300 rounded-full mb-4 mr-auto ${className}`} />
);

const H1 = ({ children }) => (
	<h1 className="mt-0 mb-1 text-4xl font-bold md:text-5xl md:mb-8">{children}</h1>
);

const H2 = ({ children, className }) => (
	<h2 className={cn('text-3xl font-bold my-0 mb-4', className)}>{children}</h2>
);

const AboutPage = ({ data }) => {
	const {
		locale,
		header1,
		header2,
		image1,
		image2,
		title,
		content1Node,
		content2Node,
		mapImage,
		valuesNode,
		locationCard,
		ctaTextNode,
		_allSeoLocales,
		slug,
		seoMetaTags,
	} = data.datoCmsAbout;
	const numCompanies = useVar('numCompanies');
	const location = useLocation();

	return (
		<>
			<HrefLang locales={_allSeoLocales} slug={slug} />
			<DatoSEO path={location.pathname} meta={seoMetaTags} />
			<div className="text-gray-800">
				<H1>{title}</H1>

				<section className="content-center mb-6 md:flex md:flex-row lg:mb-20">
					<div className="md:w-1/2 lg:w-1/3 md:pr-6">
						<H2>{header1}</H2>

						<HR />
						<CopyBlock>{content1Node.childMdx.body}</CopyBlock>
					</div>

					<div className="md:w-1/2 lg:w-2/3">
						<div className="blob blob--bottom-right">
							<div className="blob__content">
								<GatsbyImage
									image={image1.gatsbyImageData}
									loading="eager"
									critical
									fadeIn={false}
									alt="View from the Manchester office"
								/>
							</div>
						</div>
					</div>
				</section>

				<section className="mb-6 md:flex md:flex-row lg:mb-12">
					<div className="md:w-1/2 lg:w-2/3">
						<H2>{header2}</H2>

						<HR />

						<CopyBlock>{content2Node.childMdx.body}</CopyBlock>

						<p className="mb-4">
							No wonder {(numCompanies || 0).toLocaleString()} businesses – across the
							UK, Ireland, Canada, New Zealand and Australia—trust us to free them of
							their paperwork and keep them on the right side of the law.
						</p>
					</div>

					<div className="order-first md:w-1/2 md:pr-8 lg:w-1/3">
						<div className="blob blob--bottom-left ">
							<div className="blob__content">
								<GatsbyImage
									image={image2.gatsbyImageData}
									alt="UK office town hall meeting"
								/>
							</div>
						</div>
					</div>
				</section>

				<section className="text-center">
					<H2 className="text-center">Our values</H2>

					<HR className="m-auto mb-8" />

					<div className="flex">
						<LazyLoad once>
							<div className="grow shrink">
								<img
									className="hidden w-full h-auto lg:block"
									src={personLeft}
									alt="Person left"
								/>
							</div>
						</LazyLoad>
						<div className="w-full max-w-md mx-auto mb-10 shrink-0">
							<CopyBlock>{valuesNode.childMdx.body}</CopyBlock>
						</div>
						<LazyLoad once>
							<div className="grow shrink">
								<img
									className="hidden w-full h-auto mt-20 lg:block"
									src={personRight}
									alt="Person right"
								/>
							</div>
						</LazyLoad>
					</div>
				</section>

				<section>
					<H2 className="text-center">Our offices</H2>

					<HR className="m-auto mb-8" />
					<div className="flex flex-row flex-wrap justify-center mb-2 -m-4 lg:mb-6">
						{locationCard.map((card) => (
							<AboutUsCard
								country={card.country}
								info={card.info}
								address={card.address}
								phone={card.phone}
								fullPhone={card.fullPhone}
								key={card.country}
							/>
						))}
					</div>

					<img
						className="w-full h-auto mt-10 mb-6 lg:px-6"
						src={mapImage.url}
						alt="Office locations"
						height={mapImage.height}
						width={mapImage.width}
					/>
				</section>

				<section>
					<H2 className="text-center">Interested in BrightHR?</H2>
					<HR className="m-auto mb-8" />

					<div className="max-w-3xl mx-auto mb-8 text-center">
						<CopyBlock>{ctaTextNode.childMdx.body}</CopyBlock>
					</div>

					<div className="w-full m-auto md:w-fit">
						<LinkButton
							outlineButton
							href={buildLink(locale, 'book-a-demo')}
							text="Get your free demo"
						/>
					</div>
				</section>
			</div>
		</>
	);
};

const WrappedAbout = (props) => (
	<DefaultLayout>
		<AboutPage {...props} />
	</DefaultLayout>
);

export default WrappedAbout;

export const query = graphql`
	query aboutQuery($id: String, $locale: String!) {
		datoCmsAbout(locale: { eq: $locale }, slug: { ne: null }, id: { eq: $id }) {
			locale
			title
			slug
			_allSeoLocales {
				locale
			}
			seoMetaTags {
				...GatsbyDatoCmsSeoMetaTags
			}
			content1Node {
				childMdx {
					body
				}
			}
			content2Node {
				childMdx {
					body
				}
			}
			header1
			header2
			image1 {
				gatsbyImageData
				alt
			}
			image2 {
				alt
				gatsbyImageData
			}
			mapImage {
				url
				width
				height
			}
			valuesNode {
				childMdx {
					body
				}
			}
			locationCard {
				address
				country
				info
				phone
				fullPhone
			}
			ctaTextNode {
				childMdx {
					body
				}
			}
		}
	}
`;
