import React from 'react';
import cn from 'classnames';

const Pill = ({ as = 'span', children, color = 'brand', active = true, className, ...props }) =>
	React.createElement(
		as,
		{
			...props,
			className: cn(
				'rounded-full px-3 py-2 text-center font-bold text-sm transition duration-200 border-none group-focus:bg-brand-500 group-focus:text-white',
				{
					'bg-brand-100 text-brand-500': color === 'brand' && !active,
					'bg-brand-500 text-white': color === 'brand' && active,
					'bg-secondary-50 text-secondary-300': color === 'secondary' && !active,
					'bg-secondary-300 text-white': color === 'secondary' && active,
				},
				className,
			),
		},
		children,
	);

export default Pill;
