import { graphql, useStaticQuery } from 'gatsby';
import { useEffect } from 'react';
import { useLocale } from '../context/Locale';
import { DEFAULT_LOCALE, LOCALE_CONFIG } from '../utils/locale';

const map = {
	salesNumber: {
		key: '_allSalesNumberLocales',
		localised: true,
	},
	supportNumber: {
		key: '_allSupportNumberLocales',
		localised: true,
	},
	supportEmail: {
		key: '_allSupportEmailLocales',
		localised: true,
	},
	clientReferralFee: {
		key: '_allClientReferralFeeLocales',
		localised: true,
	},
	numCompanies: {
		key: 'numCompanies',
		localised: false,
	},
};

const localisedValues = {
	numCompaniesca: 6000,
};

let cache = {};

export const useVar = (name, localeOveride) => {
	const value = map[name];
	const { datoCmsSiteInfo: data } = useStaticQuery(graphql`
		query Metadata {
			datoCmsSiteInfo {
				_allSalesNumberLocales {
					value
					locale
				}
				_allSupportNumberLocales {
					locale
					value
				}
				numCompanies
				_allClientReferralFeeLocales {
					locale
					value
				}
				_allSupportEmailLocales {
					locale
					value
				}
			}
		}
	`);

	if (!data) {
		return null;
	}

	const pageLocale = useLocale();
	const selectedLocale = localeOveride || pageLocale;

	const cacheKey = value.key + selectedLocale;

	useEffect(() => {
		cache = {};
	}, [data]);

	if (cache[cacheKey]) {
		return cache[cacheKey];
	}

	if (!data[value.key]) {
		return null;
	}

	if (!selectedLocale) return null;

	if (value.localised) {
		const requestedLocale = data[value.key].find(
			({ locale }) => locale === LOCALE_CONFIG[selectedLocale].langCode,
		);
		const fallbackLocale = data[value.key].find(
			({ locale }) => locale === DEFAULT_LOCALE.countryCode,
		);

		cache[cacheKey] = (requestedLocale || fallbackLocale).value;
	} else if (localisedValues[cacheKey]) {
		cache[cacheKey] = localisedValues[cacheKey];
	} else {
		cache[cacheKey] = data[value.key];
	}

	return cache[cacheKey];
};

const Var = ({ name, localeOveride }) => {
	const value = useVar(name, localeOveride);

	return typeof value === 'number' ? value.toLocaleString() : value;
};

export default Var;
