import React from 'react';

const DemoCTASecondary = ({ showModal, secondaryCtaText, secondaryCtaHref, children, href }) => (
	<a
		href={secondaryCtaHref || href}
		onClick={showModal}
		className="block px-6 py-3 mt-3 font-bold text-center text-white transition duration-300 ease-in-out border border-white rounded-full md:mt-0 hover:text-gray-300 hover:border-gray-300 md:ml-4 md:inline-block bg-brand-500"
	>
		{secondaryCtaText || children}
	</a>
);

export default DemoCTASecondary;
