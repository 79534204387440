export const defaultLeadSource = 'Web Demo Request';
const countries = ['AU', 'CA', 'NZ', 'IE'];

const getLeadSource = (locale = '', suffix = defaultLeadSource) => {
	const country = locale.toUpperCase().slice(-2);

	if (countries.includes(country)) {
		return `${country} - ${suffix}`;
	}
	return suffix;
};
export default getLeadSource;
