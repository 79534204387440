import React from 'react';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import { useLocation } from '@reach/router';

import SEO from '../components/SEO';
import Logo from '../components/Logo';
import Ticker from '../components/Ticker';

import WebinbarLeadForm from '../components/Marketo/WebinarLeadForm';
import Container from '../components/Container';
import CopyBlock from '../components/CopyBlock';
import CopyBlockProvider from '../components/CopyBlock/provider';
import HrefLang from '../components/HrefLang';
import MinimalFooter from '../components/MinimalFooter';
import { buildLink, SUPPORTED_LOCALES } from '../utils/locale';

import BlobBg from '../images/webinar-lead/BigBlob.png';
import Calendar from '../images/webinar-lead/calendar.png';
import Chair from '../images/webinar-lead/chair.png';
import Handshake from '../images/webinar-lead/handshake.png';

const WebinarLead = ({ data, locale }) => {
	const location = useLocation();

	const { image, phoneNumber, imageCopyNode } = data.datoCmsWebinarLeadGen;

	return (
		<>
			<HrefLang locales={SUPPORTED_LOCALES} slug="/webinar-demo" />
			<Helmet>
				<link
					rel="prefetch"
					href="https://app-lon04.marketo.com/js/forms2/js/forms2.min.js"
				/>
			</Helmet>
			<SEO path={location.pathname} title="Webinar Lead Form" />
			<div
				className="bg-no-repeat"
				style={{
					backgroundImage: `url(${BlobBg})`,
					backgroundPosition: '80% 100px',
					backgroundSize: '55%',
				}}
			>
				<Container className="relative pb-0 md:p-6 ">
					<header className="relative flex flex-row items-center justify-between mb-8 md:mb-20 text-brand-500">
						<a href={buildLink(locale, '/')}>
							<Logo />
						</a>
						<a className="text-xl font-bold " href={`tel:${phoneNumber}`}>
							{phoneNumber}
						</a>
					</header>
					<div className="m-auto lg:w-11/12">
						<section className="mb-8 ">
							<div className="flex flex-col lg:flex-row-reverse">
								<div className="relative w-full mx-auto mt-12 mb-8 bg-white border border-gray-300 rounded-md shadow-md lg:mx-12 lg:w-2/3 lg:mb-0">
									<div className="md:flex">
										<div className="p-8 md:flex-1 lg:px-12">
											<WebinbarLeadForm />
										</div>
									</div>
								</div>
								<div className="w-full md:px-6 lg:w-1/3">
									<div className="pb-3">
										<div className="flex flex-col items-center ">
											<img
												src={image.url}
												className="w-full h-auto sm:w-2/5 sm:h-auto lg:w-full"
												alt={image.alt}
											/>
										</div>
										<p className="py-2 sm:text-center lg:text-left">
											Sam Tunney - Bright HR Expert
										</p>
									</div>
									<div className="py-8 bg-white ticks-pink ">
										<CopyBlock>{imageCopyNode.childMdx.body}</CopyBlock>
									</div>
								</div>
							</div>
						</section>
					</div>
				</Container>
			</div>

			<div className="hidden md:block bg-gradient-to-b from-brand-100 to-transparent">
				<Container>
					<section className="px-6 m-auto mt-16 mb-4 lg:w-11/12">
						<div className="grid justify-center grid-cols-1 md:grid-cols-3">
							<div className="flex flex-col items-center">
								<img src={Handshake} alt="Handshake" />
								<h2 className="font-bold lg:text-5xl md:text-4xl">
									<Ticker className="inline-block count" end={56187} />+
								</h2>
								<h6 className="text-base text-center">Companies using BrightHR</h6>
							</div>
							<div className="flex flex-col items-center">
								<img src={Calendar} alt="Calendar" />
								<h2 className="font-bold lg:text-5xl md:text-4xl">
									<Ticker className="inline-block count" end={5034385} />+
								</h2>
								<h6 className="text-base text-center">
									Holidays booked through BrightHR
								</h6>
							</div>
							<div className="flex flex-col items-center">
								<img src={Chair} alt="Chair" />
								<h2 className="font-bold lg:text-5xl md:text-4xl">
									<Ticker className="inline-block count" end={5883186} />+
								</h2>
								<h6 className="text-base text-center">Staff using BrightHR</h6>
							</div>
						</div>
					</section>
				</Container>
			</div>

			<Container class="pt-0 mt:pt-6">
				<MinimalFooter locale={locale} includePricingPage />
			</Container>
		</>
	);
};

export const query = graphql`
	query webinarLeadGen {
		datoCmsWebinarLeadGen {
			phoneNumber
			image {
				alt
				url
			}
			imageCopyNode {
				childMdx {
					body
				}
			}
		}
	}
`;

const WrappedWebinarLead = (props) => (
	<CopyBlockProvider>
		<WebinarLead {...props} />
	</CopyBlockProvider>
);

export default WrappedWebinarLead;
