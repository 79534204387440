// eslint-disable-next-line max-classes-per-file
export class SessionError extends Error {
	constructor(message) {
		super(message);

		if (Error.captureStackTrace) {
			Error.captureStackTrace(this, SessionError);
		}

		this.name = 'SessionError';
	}
}

export class SessionUpdateError extends SessionError {
	constructor(message) {
		super(message);

		if (Error.captureStackTrace) {
			Error.captureStackTrace(this, SessionUpdateError);
		}

		this.name = 'SessionUpdateError';
		this.message = message || 'Session Update Failed';
	}
}
