import React, { useState } from 'react';
import cn from 'classnames';
import { GatsbyImage } from 'gatsby-plugin-image';
import LazyLoad from 'react-lazyload';
import CopyBlock from '../CopyBlock';
import VideoModal from '../VideoModal';
import Quote from '../../icons/Quote.svg';
import BlobSix from '../../images/blobs/BlobSix.svg';
import VideoPlayPink from '../../icons/VideoPlayPink.svg';

const TrustBlock = ({ trustBlockContent, fullWidthPage }) => {
	const {
		trustBadge,
		trustBlockLayout,
		trustImage,
		trustReviewBodyNode,
		trustReviewTitle,
		vimeoId,
		youtubeId,
	} = trustBlockContent;
	const [open, setOpen] = useState(false);

	return (
		<div
			className={cn(`flex flex-col-reverse my-6 bg-gray-100 lg:flex-row`, {
				'lg:flex-row-reverse':
					trustBlockLayout === 'Split - review right' ||
					trustBlockLayout === 'Blob - review right',
			})}
		>
			<div
				className={cn(
					`relative flex flex-col px-5 w-full lg:justify-center`,
					{
						'pt-28 pb-10 z-20 lg:w-1/2':
							trustBlockLayout === 'Split - review left' ||
							trustBlockLayout === 'Split - review right',
					},
					{
						'pt-28 pb-10 z-20 lg:w-1/2 lg:pt-12 lg:pb-12':
							trustBlockLayout === 'Blob - review left' ||
							trustBlockLayout === 'Blob - review right',
					},
					{
						'pt-24 pb-10': trustBlockLayout === 'Full',
					},
				)}
			>
				{trustReviewTitle && (
					<h2 className="mb-4 text-2xl font-bold leading-8 lg:text-center">
						{trustReviewTitle}
					</h2>
				)}
				{trustReviewBodyNode && (
					<div className="mb-5 italic lg:text-center">
						<CopyBlock>{trustReviewBodyNode.childMdx.body}</CopyBlock>
					</div>
				)}
				{trustBadge && (
					<div className="lg:mx-auto max-w-[230px]">
						<img src={trustBadge.url} alt={trustBadge.alt} width={trustBadge.width} />
					</div>
				)}
				<Quote
					className={cn(
						`absolute text-gray-200 top-12 left-5 lg:left-1/2  lg:-translate-x-3`,
						{
							'top-16': trustBlockLayout !== 'Full',
						},
						{
							'lg:top-3':
								trustBlockLayout === 'Blob - review left' ||
								trustBlockLayout === 'Blob - review right',
						},
						{
							'xl:top-[14%]':
								fullWidthPage &&
								(trustBlockLayout === 'Blob - review left' ||
									trustBlockLayout === 'Blob - review right'),
						},
					)}
				/>
			</div>
			{trustBlockLayout !== 'Full' && (
				<div
					className={cn(
						`relative flex justify-end w-full align-middle lg:w-1/2 lg:justify-center`,
						{
							'pt-20':
								trustBlockLayout === 'Blob - review left' ||
								trustBlockLayout === 'Blob - review right',
						},
						{
							'lg:pt-0':
								!fullWidthPage &&
								(trustBlockLayout === 'Blob - review left' ||
									trustBlockLayout === 'Blob - review right'),
						},
						{
							'lg:pt-24 lg:pb-28':
								fullWidthPage &&
								(trustBlockLayout === 'Blob - review left' ||
									trustBlockLayout === 'Blob - review right'),
						},
					)}
				>
					<LazyLoad once>
						{youtubeId || vimeoId ? (
							<div
								role="button"
								className="relative flex justify-end lg:justify-center"
								onClick={() => setOpen(true)}
								onKeyDown={() => setOpen(true)}
								tabIndex="0"
							>
								<GatsbyImage
									image={trustImage.gatsbyImageData}
									objectFit={
										trustBlockLayout === 'Split - review left' ||
										trustBlockLayout === 'Split - review right'
											? 'cover'
											: 'contain'
									}
									className={cn(`cursor-pointer z-10 w-full`, {
										'w-10/12 sm:w-8/12 lg:w-3/5':
											trustBlockLayout === 'Blob - review left' ||
											trustBlockLayout === 'Blob - review right',
									})}
								/>
								<span className="sr-only">Play video</span>
								<VideoPlayPink
									className={cn(
										`absolute z-10 w-16 h-auto translate-x-1/2 translate-y-1/2 right-1/2 bottom-1/2`,
										{
											'translate-x-[83.33%] sm:translate-x-1/2 sm:right-1/3 lg:right-1/2':
												trustBlockLayout === 'Blob - review left' ||
												trustBlockLayout === 'Blob - review right',
										},
									)}
									width="108"
									height="70"
								/>
							</div>
						) : (
							<GatsbyImage
								image={trustImage.gatsbyImageData}
								objectFit={
									trustBlockLayout === 'Split - review left' ||
									trustBlockLayout === 'Split - review right'
										? 'cover'
										: 'contain'
								}
								className={cn(`w-full z-10`, {
									'w-10/12 sm:w-8/12 lg:w-3/5':
										trustBlockLayout === 'Blob - review left' ||
										trustBlockLayout === 'Blob - review right',
								})}
							/>
						)}
					</LazyLoad>
					{(youtubeId || vimeoId) && open && (
						<VideoModal
							youtubeId={youtubeId}
							vimeoId={vimeoId}
							videoTitle={trustReviewTitle}
							onClose={() => setOpen(false)}
						/>
					)}
					<img
						src={BlobSix}
						alt=""
						width="970"
						height="970"
						className={cn(
							`w-full h-auto absolute top-10 right-0 sm:w-4/5 sm:top-4 lg:w-4/5 lg:left-[10%] lg:top-[20%] xl:top-[14%]`,
							{
								'lg:top-[5%] xl:top-0': fullWidthPage,
							},
							{
								block:
									trustBlockLayout === 'Blob - review left' ||
									trustBlockLayout === 'Blob - review right',
							},
							{
								hidden:
									trustBlockLayout === 'Split - review left' ||
									trustBlockLayout === 'Split - review right',
							},
						)}
					/>
				</div>
			)}
		</div>
	);
};

export default TrustBlock;
