import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';

import LazyLoad from 'react-lazyload';
import CopyBlock from '../../CopyBlock';
import Container from '../../Container';

const FeatureBlock = ({ title, text, imgSrc1, imgSrc2, imgAlt1, imgAlt2 }) => (
	<div className="bg-gradient-to-r from-brand-500 to-[#2e7cc2]">
		<Container>
			<div className="flex flex-col items-center py-8 mb-4 md:py-12 md:mb-0 md:flex-row">
				<div className="w-full mb-8 md:w-2/3 md:mb-0">
					<h1 className="mb-4 text-xl font-bold text-white">{title}</h1>
					<p className="text-white">
						<CopyBlock>{text}</CopyBlock>
					</p>
				</div>
				<div className="w-full md:text-center md:w-1/3">
					<LazyLoad once>
						<GatsbyImage
							image={imgSrc1}
							className="inline-block mx-2 rounded-md md:mx-4 max-w-[8rem]"
							alt={imgAlt1}
						/>
					</LazyLoad>
					<LazyLoad once>
						<GatsbyImage
							image={imgSrc2}
							className="inline-block mx-2 rounded-md md:mx-4 max-w-[8rem]"
							alt={imgAlt2}
						/>
					</LazyLoad>
				</div>
			</div>
		</Container>
	</div>
);

export default FeatureBlock;
