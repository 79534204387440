// eslint-disable-next-line max-params
const createQuote = async (priceId, amountOfEmployees, customerId, couponId, setProcessing) => {
	const quote = await fetch('/api/stripe/quote/create-quote', {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			priceId,
			amountOfEmployees,
			customerId,
			couponId,
		}),
	})
		.then((res) => res.json())
		.catch(() => {
			setProcessing(false);
			throw new Error(
				'Could not create a quote at this time. Please contact our Sales team on 0800 470 2432',
			);
		});

	return quote;
};

export default createQuote;
