import React from 'react';
import cn from 'classnames';
import { useLocale } from '../../../context/Locale';

import BookADemoForm from '../../Marketo/BookADemo';

const DemoForm = ({
	title,
	ctaText,
	pageContext,
	formColor,
	showFeefo,
	hidePrivacyLinks,
	showMessageField,
	transactionId,
	leadSource,
	referrerField,
}) => {
	const locale = useLocale();

	return (
		<section className="px-6 py-20 pb-40">
			<div id="demo-form" className="anchor" />
			<div
				className={cn('max-w-2xl p-8 mx-auto rounded shadow-lg', {
					'bg-white': formColor === 'white',
					'bg-brand-500 text-white': formColor === 'blue',
				})}
			>
				<BookADemoForm
					showMessageField={showMessageField}
					hidePrivacyLinks={hidePrivacyLinks}
					showFeefo={showFeefo}
					pageContext={pageContext}
					ctaText={ctaText}
					title={title}
					locale={locale}
					formColor={formColor}
					transactionId={transactionId}
					leadSource={leadSource}
					referrerField={referrerField}
				/>
			</div>
		</section>
	);
};

export default DemoForm;
