import React from 'react';

import Star from '../../icons/Star.svg';

const Stars = ({ count }) =>
	Array(count)
		.fill(0)
		.map((_, i) => (
			// eslint-disable-next-line react/no-array-index-key
			<Star key={`star-${i}`} width={20} height={20} />
		));

export default Stars;
