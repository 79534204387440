import React from 'react';
import cn from 'classnames';

import QuoteMarks from '../../icons/Quotation-mark.svg';
import Stars from '../Stars';
import CopyBlock from '../CopyBlock';

const TestimonialQuote = ({ quoteSource, quoteTextNode, colour }) => (
	<div className="w-full h-full">
		<div
			className={cn('relative lg:h-full p-6 rounded border shadow-md flex flex-col', {
				'bg-gray-200 text-gray-800 border-gray-200': colour === 'Dark',
				'bg-white border-gray-200': colour === 'Light',
			})}
		>
			<QuoteMarks
				className={cn('mb-3 md:mb-6 w-8', {
					'text-brand-400 opacity-50': colour === 'Dark',
					'text-gray-300 opacity-100': colour === 'Light',
				})}
			/>
			<div className="mb-3 text-base lg:text-lg">
				<CopyBlock>{quoteTextNode.childMdx.body}</CopyBlock>
			</div>
			<div className="mt-auto">
				<p className="mb-2 italic">{quoteSource}</p>

				<div className="flex flex-row space-x-1 text-gold">
					<Stars count={5} />
				</div>
			</div>
		</div>
	</div>
);

export default TestimonialQuote;
