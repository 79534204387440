import React, { useEffect, useState } from 'react';
import { graphql } from 'gatsby';

import DefaultLayout from '../layouts/Default';
import ImageWithCopy from '../components/CMSBlocks/ImageWithCopy';
import BlobTestimonial from '../components/CMSBlocks/BlobTestimonial';
import ProductsHero from '../components/CMSBlocks/ProductsHero';
import Container from '../components/Container';
import CopyBlockProvider from '../components/CopyBlock/provider';
import CMSWellCTA from '../components/CMSBlocks/WellCTA';
import CarouselWithCopy from '../components/CMSBlocks/CarouselWithCopy';
import HighlightedCopy from '../components/CMSBlocks/HighlightedCopy';
import VideoWithCopy from '../components/CMSBlocks/VideoWithCopy';
import FeatureBlock from '../components/CMSBlocks/FeatureBlock';
import StatBlob from '../components/CMSBlocks/StatBlob';
import Copy from '../components/CMSBlocks/Copy';
import DatoSEO from '../components/DatoSEO';
import HrefLang from '../components/HrefLang';
import HeroHarryRedknapp from '../components/Heros/HarryRedknapp';
import ReviewBadges from '../components/ReviewBadges';
import { localisedRead, LOCALE_PATHS } from '../utils/locale';
import VideoBlock from '../components/CMSBlocks/VideoBlock';
import MeetOurCustomers from '../components/MeetOurCustomers';
import GoogleReviewsTestimonials from '../components/GoogleReviewsTestimonials';
import PromoPopup from '../components/PromoPopup';
import LocaleContent from '../components/LocaleContent';

const ProductPage = ({ data, location, pageContext }) => {
	const {
		productElements,
		heroImage,
		productName,
		productTitle,
		productDescriptionNode,
		mainCta,
		seoMetaTags,
		_allSlugLocales,
		_allSeoLocales,
	} = data.datoCmsProduct;
	const { googleReviews } = data;

	const pages = [
		'/brightadvice-employment-law-advice/',
		'/blip-clocking-in-app/',
		'/pop-expense-tracker-app/',
		'/hr-software/',
		'/brightsafe/',
	];

	const alternativeHeroPages = [
		'/brightadvice-employment-law-advice/',
		'/pop-expense-tracker-app/',
	];

	const locale = LOCALE_PATHS[pageContext.locale];
	const [autoSchema, setAutoSchema] = useState({});

	useEffect(() => {
		const aggregateRating = {
			aggregateRating: {
				'@type': 'AggregateRating',
				ratingValue: locale === 'ca' ? data.googleReviewTotals.rating : null,
				ratingCount: locale === 'ca' ? data.googleReviewTotals.reviews : null,
			},
		};

		setAutoSchema({
			name: productName,
			operatingSystem: 'Android, iOS, Web Browser',
			applicationCategory: 'BusinessApplication',
			...aggregateRating,
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<DefaultLayout fullWidth>
			<HrefLang
				locales={_allSeoLocales}
				slug={(hreflang) => localisedRead(_allSlugLocales, hreflang)}
				lang={pageContext.locale}
			/>
			<CopyBlockProvider>
				<DatoSEO
					path={location.pathname}
					meta={seoMetaTags}
					schemaType="SoftwareApplication"
					autoSchema={autoSchema}
				/>

				<div className="mb-4">
					{pages.includes(location.pathname) && locale === 'en' ? (
						<HeroHarryRedknapp
							alternativeHero={alternativeHeroPages.includes(location.pathname)}
							heroImage={heroImage ? heroImage.gatsbyImageData : null}
							title={productTitle}
							descriptionMdx={productDescriptionNode.childMdx.body}
							page={location.pathname}
							crumbs={[
								{
									text: 'Home',
									link: '/',
								},
								{
									text: productName,
								},
							]}
						>
							<div className="flex">
								<LocaleContent showInLocales="en">
									<ReviewBadges
										locale={locale}
										pageName="product blue bg"
										classes="w-48 lg:w-56"
										width="224"
										height="60"
										g2AwardClasses="ml-3 w-24 lg:w-28"
									/>
								</LocaleContent>
								<LocaleContent showInLocales="ie">
									<ReviewBadges
										locale={locale}
										pageName="product"
										width="224"
										height="60"
										classes="w-48 lg:w-56"
										g2AwardClasses="ml-3 w-24 lg:w-28"
									/>
								</LocaleContent>
								<LocaleContent showInLocales={['ca', 'au', 'nz']}>
									<ReviewBadges
										locale={locale}
										pageName="product"
										width={locale === 'ca' ? '110' : '170'}
										height={locale === 'ca' ? '91' : '80'}
										g2AwardClasses="ml-4 w-24 lg:w-28"
									/>
								</LocaleContent>
							</div>
						</HeroHarryRedknapp>
					) : (
						<ProductsHero
							heroImage={heroImage ? heroImage.gatsbyImageData : null}
							title={productTitle}
							subTitle={productName}
							descriptionMdx={productDescriptionNode.childMdx.body}
							page={location.pathname}
							locale={locale}
							crumbs={[
								{
									text: 'Home',
									link: '/',
								},
								{
									text: productName,
								},
							]}
						/>
					)}
				</div>

				{productElements.map((el) => (
					<div className="mb-6 md:mb-12 last:mb-0" key={el.id}>
						{el.blocktype === 'VideoBlock' && (
							<VideoBlock
								imgSrc={el.thumbnail.gatsbyImageData}
								alt={el.thumbnail.alt}
								header={el.header}
								title={el.title}
								youtubeId={el.youtubeVideoId}
								vimeoId={el.vimeoId}
								subText={el.videoSubTextNode.childMdx.body}
							/>
						)}
						{el.blocktype === 'MeetOurCustomer' && (
							<MeetOurCustomers logos={el.logos} cta={el.ctaNode.childMdx.body} />
						)}
						{el.blocktype === 'ImageWithCopy' && (
							<ImageWithCopy
								imgSrc={el.imageWithCopyImage.gatsbyImageData}
								alt={el.imageWithCopyImage.alt}
								text={el.textNode.childMdx.body}
								imagePosition={el.imagePosition}
							/>
						)}
						{el.blocktype === 'BlobTestimonial' && (
							<BlobTestimonial
								imgSrc={el.blobTestimonialImage.gatsbyImageData}
								alt={el.blobTestimonialImage.alt}
								text={el.textNode.childMdx.body}
								testimonial={el.testimonialNode.childMdx.body}
								companyLogoSrc={el.companyLogo.gatsbyImageData}
								companyLogoAlt={el.companyLogo.alt}
								testimonialPosition={
									el.testimonialPosition === null
										? 'Left'
										: el.testimonialPosition
								}
								large={el.makeQuoteBlockLarge}
							/>
						)}
						{el.blocktype === 'VideoWithCopy' && (
							<VideoWithCopy
								laptopThumbnail={el.laptopThumbnail.gatsbyImageData}
								noLaptopThumbnail={el.noLaptopThumbnail.gatsbyImageData}
								alt={el.noLaptopThumbnail.alt}
								text={el.textNode.childMdx.body}
								imagePosition={el.imagePosition}
								youtubeId={el.youtubeId}
								vimeoId={el.vimeoId}
								videoClickPrompt={el.videoClickPrompt}
								thumbnailInLaptop={el.thumbnailInLaptop}
								showScrubber={el.showScrubber}
							/>
						)}
						{el.blocktype === 'FeatureBlock' && (
							<FeatureBlock
								title={el.title}
								text={el.textNode.childMdx.body}
								imgSrc1={el.image1?.gatsbyImageData}
								imgSrc2={el.image2?.gatsbyImageData}
								imgAlt1={el.image1?.alt}
								imgAlt2={el.image2?.alt}
							/>
						)}
						{el.blocktype === 'CarouselWithCopy' && (
							<CarouselWithCopy
								slides={el.images}
								text={el.textNode.childMdx.body}
								imagePosition={el.imagePosition}
							/>
						)}
						{el.blocktype === 'HighlightedCopy' && (
							<HighlightedCopy version={el.version || 'v1'}>
								{el.textNode.childMdx.body}
							</HighlightedCopy>
						)}
						{el.blocktype === 'StatBlob' && (
							<StatBlob
								header={el.header}
								figure={el.figure}
								text={el.textNode.childMdx.body}
								imgSrc={el.image.gatsbyImageData}
								alt={el.image.alt}
							/>
						)}
						{el.blocktype === 'Copy' && (
							<Copy
								centered={el.centered}
								background={el.background}
								width={el.width}
								textSize={el.textSize}
							>
								{el.textNode.childMdx.body}
							</Copy>
						)}
					</div>
				))}
				{mainCta.length > 0 && (
					<CMSWellCTA title={mainCta[0].title} text={mainCta[0].textNode.childMdx.body} />
				)}

				<Container>
					<LocaleContent showInLocales="ca">
						<GoogleReviewsTestimonials
							googleReviews={googleReviews}
							starClass="text-gold"
							textClass="text-black"
						/>
					</LocaleContent>
				</Container>
			</CopyBlockProvider>
			<PromoPopup template="product" />
		</DefaultLayout>
	);
};

export default ProductPage;

export const query = graphql`
	query seoAndProduct($slug: String, $locale: String!) {
		site {
			siteMetadata {
				title
			}
		}

		datoCmsProduct(locale: { eq: $locale }, slug: { eq: $slug }) {
			_allSeoLocales {
				locale
			}
			_allSlugLocales {
				locale
				value
			}
			id
			locale
			slug
			seoMetaTags {
				...GatsbyDatoCmsSeoMetaTags
			}
			productLogo {
				alt
				url
				width
				height
			}
			heroImage {
				gatsbyImageData(layout: CONSTRAINED, width: 590)
			}
			productLogo {
				alt
				url
				width
				height
			}
			productName
			productTitle
			productDescription
			productDescriptionNode {
				childMdx {
					body
				}
			}
			productElements {
				... on DatoCmsVideoBlock {
					blocktype
					id
					header
					title
					videoSubTextNode {
						childMdx {
							body
						}
					}
					thumbnail {
						alt
						gatsbyImageData(imgixParams: { auto: "" }, layout: CONSTRAINED)
					}
					vimeoId
					youtubeVideoId
				}
				... on DatoCmsImageWithCopy {
					id
					blocktype
					imagePosition
					textNode {
						childMdx {
							body
						}
					}
					imageWithCopyImage {
						alt
						gatsbyImageData(layout: CONSTRAINED, width: 590)
					}
				}
				... on DatoCmsBlobTestimonial {
					id
					blocktype
					textNode {
						childMdx {
							body
						}
					}
					testimonialNode {
						childMdx {
							body
						}
					}
					companyLogo {
						alt
						gatsbyImageData(layout: CONSTRAINED, width: 195)
					}
					blobTestimonialImage {
						alt
						gatsbyImageData(layout: CONSTRAINED, width: 590)
					}
					testimonialPosition
					makeQuoteBlockLarge
				}
				... on DatoCmsVideoWithCopy {
					id
					blocktype
					imagePosition
					textNode {
						childMdx {
							body
						}
					}
					youtubeId
					vimeoId
					laptopThumbnail: thumbnail {
						alt
						gatsbyImageData(
							layout: CONSTRAINED
							width: 540
							imgixParams: {
								auto: "compress"
								crop: "top,center"
								ar: "1.5"
								fit: "crop"
							}
						)
					}
					noLaptopThumbnail: thumbnail {
						alt
						gatsbyImageData(
							layout: CONSTRAINED
							width: 720
							imgixParams: {
								auto: "compress"
								crop: "faces,center"
								ar: "16:9"
								fit: "crop"
							}
						)
					}
					videoClickPrompt
					thumbnailInLaptop
					showScrubber
				}
				... on DatoCmsFeatureBlock {
					blocktype
					title
					image1 {
						url
						alt
						gatsbyImageData(layout: CONSTRAINED, width: 590)
					}
					image2 {
						alt
						url
						gatsbyImageData(layout: CONSTRAINED, width: 590)
					}
					textNode {
						childMdx {
							body
						}
					}
				}
				... on DatoCmsHighlightedCopy {
					id
					blocktype
					textNode {
						childMdx {
							body
						}
					}
				}

				... on DatoCmsStatBlob {
					id
					blocktype
					header
					textNode {
						childMdx {
							body
						}
					}
					figure
					image {
						alt
						gatsbyImageData(layout: CONSTRAINED, width: 80)
					}
				}
				... on DatoCmsCopy {
					id
					blocktype
					textNode {
						childMdx {
							body
						}
					}
					centered
					background
					width
					textSize
				}
				... on DatoCmsCarouselWithCopy {
					id
					blocktype
					imagePosition
					textNode {
						childMdx {
							body
						}
					}
					images {
						alt
						gatsbyImageData(layout: CONSTRAINED, width: 590)
					}
				}
				... on DatoCmsMeetOurCustomer {
					id
					blocktype
					logos {
						alt
						gatsbyImageData
						url
					}
					ctaNode {
						childMdx {
							body
						}
					}
				}
			}
			mainCta {
				title
				textNode {
					childMdx {
						body
					}
				}
			}
		}

		googleReviews: allGoogleReview(
			limit: 6
			filter: { rating: { eq: 5 }, body: { ne: null } }
		) {
			nodes {
				id
				body
				rating
				date_utc
			}
		}

		googleReviewTotals {
			rating
			reviews
		}
	}
`;
