import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import LazyLoad from 'react-lazyload';

import CopyBlock from '../../CopyBlock';
import Container from '../../Container';
import Ticker from '../../Ticker';
import Quote from '../../../icons/Quote.svg';

import TestimonialBlob from '../../../images/partners/testimonialBlob.svg';
import TestimonialBlobTablet from '../../../images/blobs/tablet-blob.svg';

const StatBlob = ({ header, figure, text, imgSrc, alt }) => (
	<Container className="md:py-0 xl:py-6 ">
		<div className="relative flex flex-col items-start w-full pt-6 pb-32 bg-no-repeat xs:pb-48 sm:pb-64 md:justify-start md:items-center md:py-16 md:flex-row md:px-0 lg:px-12">
			<picture className="absolute top-0 z-0 flex items-center translate-y-0 md:translate-y-6 lg:translate-y-0 xl:-translate-y-4">
				<source
					srcSet={TestimonialBlob}
					type="image/svg+xml"
					media="(min-width: 768px)"
					alt="wide blob"
					width="1059"
					height="231"
				/>
				<img
					src={TestimonialBlobTablet}
					type="image/svg+xml"
					alt="Blob"
					width="582"
					height="464"
				/>
			</picture>
			<div className="z-10 max-w-xl pl-8 sm:pl-16 md:flex md:w-1/2 md:pl-12">
				<div className="absolute bottom-0 right-0 flex items-center mb-2 mr-8 sm:mr-8 md:mb-0 md:mr-4 md:static md:order-first">
					<LazyLoad once>
						<GatsbyImage image={imgSrc} className="max-w-lg mx-auto " alt={alt} />
					</LazyLoad>
				</div>
				<div className="relative flex flex-col order-first pt-6 md:pt-0 md:pl-2">
					<Quote
						alt="quotation mark"
						height="15"
						className="absolute -left-4 top-3 md:-top-2 opacity-60 text-brand-200 "
					/>
					<h4 className="text-xs leading-[0.4rem] md:leading-5 lg:text-sm md:-mb-2">
						{header}
					</h4>
					<h2 className="text-4xl font-bold text-brand-500 lg:text-5xl">
						<Ticker end={figure} />
						<span className="font-normal">+</span>
					</h2>
				</div>
			</div>

			<div className="z-10 max-w-xl pl-8 text-xl sm:pl-16 md:pl-4 lg:text-2xl xl:pr-32 lg:w-1/2 lg:pl-0">
				<CopyBlock>{text}</CopyBlock>
			</div>
		</div>
	</Container>
);

export default StatBlob;
