import React from 'react';
import { graphql } from 'gatsby';

import DefaultLayout from '../../layouts/Default';

import HomePage from './home';

const WrappedHome = (props) => (
	<DefaultLayout fullWidth>
		<HomePage {...props} />
	</DefaultLayout>
);

export default WrappedHome;

export const query = graphql`
	query seoAndHome($locale: String!) {
		site {
			siteMetadata {
				title
				siteUrl
				logo
			}
		}

		datoCmsHomepage(locale: { eq: $locale }) {
			_allSeoLocales {
				locale
			}
			id
			videoId
			locale
			seoMetaTags {
				...GatsbyDatoCmsSeoMetaTags
			}
			heroThumbnail {
				gatsbyImageData(
					layout: CONSTRAINED
					width: 600
					placeholder: NONE
					imgixParams: { crop: "center", fit: "crop", fm: "webp" }
				)
			}
			heroImage {
				gatsbyImageData(
					layout: FULL_WIDTH
					imgixParams: { crop: "faces,entropy", fit: "crop", fm: "webp" }
				)
				url
			}
			logo {
				alt
				url
				width
				height
			}
			title
			subTitle
			description
			descriptionNode {
				childMdx {
					body
				}
			}
			homepageElements {
				... on DatoCmsImageWithCopy {
					id
					blocktype
					imagePosition
					textNode {
						childMdx {
							body
						}
					}
					imageWithCopyImage {
						alt
						gatsbyImageData(
							layout: CONSTRAINED
							width: 590
							imgixParams: { fm: "webp" }
						)
					}
				}
				... on DatoCmsSlimImageWithCopy {
					id
					blocktype
					header
					textNode {
						childMdx {
							body
						}
					}
					slimImageWithCopyImage {
						alt
						gatsbyImageData(
							layout: CONSTRAINED
							width: 138
							imgixParams: { fm: "webp" }
						)
					}
				}
				... on DatoCmsVideoWithCopy {
					id
					blocktype
					imagePosition
					textNode {
						childMdx {
							body
						}
					}
					youtubeId
					vimeoId
					laptopThumbnail: thumbnail {
						alt
						gatsbyImageData(
							layout: CONSTRAINED
							width: 540
							imgixParams: { crop: "top,center", ar: "1.5", fit: "crop", fm: "webp" }
						)
					}
					noLaptopThumbnail: thumbnail {
						alt
						gatsbyImageData(
							layout: CONSTRAINED
							width: 720
							imgixParams: {
								crop: "faces,center"
								ar: "16:9"
								fit: "crop"
								fm: "webp"
							}
						)
					}
					videoClickPrompt
					thumbnailInLaptop
					showScrubber
				}
				... on DatoCmsCopy {
					id
					blocktype
					textNode {
						childMdx {
							body
						}
					}
					background
					width
					centered
					textSize
				}
				... on DatoCmsArticleFeature {
					id
					blocktype
					featured {
						... on DatoCmsBlogPost {
							id
							locale
							category {
								slug
							}
							contentNode {
								childMdx {
									excerpt(pruneLength: 120)
								}
							}
							title
							slug
							image {
								gatsbyImageData(
									layout: CONSTRAINED
									width: 800
									imgixParams: { fm: "webp" }
								)
							}
						}
					}
				}
				... on DatoCmsCarouselWithCopy {
					id
					blocktype
					imagePosition
					textNode {
						childMdx {
							body
						}
					}
					images {
						alt
						gatsbyImageData(
							layout: CONSTRAINED
							width: 590
							imgixParams: { fm: "webp" }
						)
					}
				}
			}
			mainCta {
				title
				text
			}
		}

		googleReviews: allGoogleReview(
			limit: 6
			filter: { rating: { eq: 5 }, body: { ne: null } }
		) {
			nodes {
				id
				body
				rating
				date_utc
			}
		}
		allDatoCmsBlogPost(
			limit: 2
			sort: { fields: meta___publishedAt, order: DESC }
			filter: { locale: { eq: $locale }, slug: { ne: null } }
		) {
			nodes {
				id
				locale
				slug
				title
				category {
					slug
				}
				image {
					gatsbyImageData(imgixParams: { fm: "webp" })
				}
				contentNode {
					childMdx {
						excerpt
					}
				}
			}
		}
	}
`;
