import React from 'react';
import LinkButton from '@brighthr/component-linkbutton';
import LazyLoad from 'react-lazyload';
import { getSrc } from 'gatsby-plugin-image';
import { buildLink } from '../../../utils/locale';

const H3 = ({ children }) => <h3 className="mb-4 text-xl font-bold md:text-3xl">{children}</h3>;
const P = ({ children }) => <p className="mb-8">{children}</p>;

const ArticleFeature = ({ featured, recentBlogs }) => {
	let postData;

	if (featured?.length > 1) {
		postData = featured;
	} else {
		postData = recentBlogs;
	}
	return (
		<LazyLoad once>
			<div className="container px-4 mx-auto mb-12">
				<hr className="mb-6 overflow-hidden border-brand-400" />
				<div className="justify-between overflow-hidden text-white md:-mx-4 md:flex">
					{postData.map((f) => (
						<div className="mb-6 md:p-4 md:w-1/2" key={f.id}>
							<div
								className="relative h-full p-6 bg-cover rounded bg-brand-500 "
								style={{
									backgroundImage: `url(${getSrc(f.image.gatsbyImageData)})`,
								}}
							>
								<div className="absolute top-0 left-0 w-full h-full opacity-50 bg-gradient-to-t grand from-gray-900 to-gray-900" />
								<div className="relative flex-col h-full md:flex">
									<H3>{f.title}</H3>
									<P>{f.contentNode.childMdx.excerpt}</P>

									<div className="mt-auto">
										<div className="w-fit">
											<LinkButton
												color="white"
												href={buildLink(
													f.locale,
													`blog/${f.category.slug}/${f.slug}/`,
												)}
												size="lg"
												text="Read more"
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
					))}
				</div>
				<hr className="border-brand-400" />
			</div>
		</LazyLoad>
	);
};

export default ArticleFeature;
