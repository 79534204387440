import * as yup from 'yup';
import checkEmailAvailability from './utils/checkEmailAvailability';

function mergeSchema(...schemas) {
	const [first, ...rest] = schemas;

	const merged = rest.reduce((mergedSchemas, schema) => mergedSchemas.concat(schema), first);

	return merged;
}

const plan = yup.object().shape({
	packageId: yup.string().label('Plan').required(),
	amountOfEmployees: yup
		.number()
		.label('Amount of employees')
		.min(1)
		.max(10000)
		.integer('Amount of employees must be a whole number'),
});

const emailExistsMessage = 'The email address you have used is already registered with BrightHR.';

const details = yup.object().shape({
	firstName: yup.string().max(40).label('First name').required(),
	lastName: yup.string().max(80).label('Last name').required(),
	companyName: yup.string().max(255).label('Company name').required(),
	email: yup
		.string()
		.email()
		.test('Email exists', emailExistsMessage, async (value) =>
			checkEmailAvailability(value).then((data) => data.available),
		)
		.required(),
	telephone: yup
		.string()
		.label('Tel no')
		.matches(/^[0-9+ ]+$/, 'Tel no is not valid')
		.required(),
});

const companyDetails = yup.object().shape({
	address1: yup.string().max(255).label('Building/Street Address').required(),
	address2: yup.string().max(255).label('Address line 2'),
	address3: yup.string().max(255).label('Address line 3'),
	postTown: yup.string().max(40).label('Town/City').required(),
	postCode: yup.string().max(20).label('Postcode').required(),
});

const payment = yup.object().shape({
	tandc: yup
		.boolean()
		.required('Terms and conditions must be accepted')
		.oneOf([true], 'Terms and conditions must be accepted'),
	card: yup
		.boolean()
		.required('Please check your card details')
		.oneOf([true], 'Please check your card details'),
});

const paymentWithSplitCard = yup.object().shape({
	tandc: yup
		.boolean()
		.required('Terms and conditions must be accepted')
		.oneOf([true], 'Terms and conditions must be accepted'),
	cardNumber: yup
		.boolean()
		.required('Please check your card number')
		.oneOf([true], 'Please check your card number'),
	cardExpiry: yup
		.boolean()
		.required('Please check your card expiry')
		.oneOf([true], 'Please check your card expiry'),
	cardCvc: yup
		.boolean()
		.required('Please check your card security code')
		.oneOf([true], 'Please check your card security code'),
});

const full = mergeSchema(plan, details, companyDetails, payment);
const fullWithSplitCard = mergeSchema(plan, details, companyDetails, paymentWithSplitCard);

export { plan, details, companyDetails, full, payment, fullWithSplitCard };
