import React from 'react';
import { graphql } from 'gatsby';
import cn from 'classnames';
import { useLocation } from '@reach/router';
import CryptoJS from 'crypto-js';
import useQueryParams from '../hooks/useQueryParams';
import DefaultLayout from '../layouts/Default';
import Var, { useVar } from '../components/Var';
import SEO from '../components/SEO';
import ContactForm from '../components/Marketo/Contact';
import HrefLang from '../components/HrefLang';
import UKDots from '../images/country-dots/ukdots.png';
import CanadaDots from '../images/country-dots/canadadots.png';
import IrelandDots from '../images/country-dots/irelanddots.png';
import AustraliaDots from '../images/country-dots/australiadots.png';
import NewZealandDots from '../images/country-dots/newzealanddots.png';
import ArrowRight from '../icons/ArrowRight.svg';
import ContactIcon from '../images/vectors/ContactEnvelope.svg';
import { useLocale } from '../context/Locale';
import { buildLink, SUPPORTED_LOCALES } from '../utils/locale';

const Contact = ({ data }) => {
	const supportEmail = useVar('supportEmail');
	const locale = useLocale();
	const location = useLocation();
	const { form } = data.datoCmsContact;

	const queryParams = useQueryParams();
	const { FirstName, LastName, Phone, Company, Email } = queryParams;

	const sk = 'bad2dsGtq0ixmcT5qfjFOH';

	const decryptValue = (encryptedValue) =>
		encryptedValue ? CryptoJS.AES.decrypt(encryptedValue, sk).toString(CryptoJS.enc.Utf8) : '';

	const decryptFirstName = decryptValue(FirstName);
	const decryptLastName = decryptValue(LastName);
	const decryptPhone = decryptValue(Phone);
	const decryptEmail = decryptValue(Email);

	const addressSchema = {
		en: {
			'@type': 'PostalAddress',
			streetAddress: 'The Peninsula, Victoria Place',
			addressLocality: 'Manchester',
			addressRegion: 'England',
			postalCode: 'M4 4FB',
			addressCountry: 'GB',
		},
		ca: {
			'@type': 'PostalAddress',
			streetAddress: '123 Front Street West, Suite 200',
			addressLocality: 'Toronto',
			addressRegion: 'Ontario',
			postalCode: 'M5J 2M2',
			addressCountry: 'CA',
		},
		au: {
			'@type': 'PostalAddress',
			streetAddress: 'Level 12, 700 Harris Street',
			addressLocality: 'Ultimo',
			addressRegion: 'New South Wales',
			postalCode: '2007',
			addressCountry: 'AU',
		},
		ie: {
			'@type': 'PostalAddress',
			streetAddress: 'Block W, East Point Business Park, Alfie Bryne Rd',
			addressLocality: 'Dublin',
			postalCode: 'Dublin 3',
			addressCountry: 'IE',
		},
		nz: {
			'@type': 'PostalAddress',
			streetAddress: '1 Nelson St',
			addressLocality: 'Auckland',
			postalCode: '1010',
			addressCountry: 'NZ',
		},
	};

	const geoSchema = {
		en: {
			'@type': 'GeoCoordinates',
			latitude: 53.4893007,
			longitude: -2.2436707,
		},
		ca: {
			'@type': 'GeoCoordinates',
			latitude: 43.6448963,
			longitude: -79.3855476,
		},
		au: {
			'@type': 'GeoCoordinates',
			latitude: -33.8814548,
			longitude: 151.1996268,
		},
		ie: {
			'@type': 'GeoCoordinates',
			latitude: 53.358431,
			longitude: -6.2282718,
		},
		nz: {
			'@type': 'GeoCoordinates',
			latitude: -36.8460156,
			longitude: 174.758115,
		},
	};

	const autoSchema = {
		name: data.site.siteMetadata.title,
		address: addressSchema[locale],
		geo: geoSchema[locale],
		telephone: useVar('salesNumber'),
		openingHoursSpecification: [
			{
				'@type': 'OpeningHoursSpecification',
				dayOfWeek: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'],
				opens: '09:00',
				closes: '17:30',
			},
		],
	};
	return (
		<>
			<HrefLang locales={SUPPORTED_LOCALES} slug="contact" />
			<SEO
				title="Contact"
				titleSuffix="BrightHR"
				description="Meet the most popular HR software and support service for SMEs. With HR advice, staff wellbeing and even a clocking in app, discover how BrightHR saves you time, money &amp; effort now"
				path={location.pathname}
				schemaType="ProfessionalService"
				autoSchema={autoSchema}
			/>
			<div>
				<section
					className={cn('bg-brand-500', {
						hidden: !form,
						block: form,
					})}
				>
					<div className="container p-6 py-8 mx-auto">
						<div className="flex flex-col lg:flex-row md:items-center lg:items-start lg:justify-between">
							<div className="w-full mb-12 lg:w-1/2 lg:pr-16 xl:pr-0 xl:w-1/3 lg:mb-0">
								<h1 className="pb-4 text-3xl font-bold text-white md:pb-2">
									How can we help?
								</h1>
								<h4 className="mb-8 text-lg text-white">
									Please select a topic below related to your enquiry. If you
									don’t find what you need, just send us a message and we’ll get
									back to you within one working day.
								</h4>
								<ul className="text-white">
									<li className="flex py-3">
										<ArrowRight className="relative mr-3 text-white top-2" />
										<div>
											<a
												href={buildLink(locale, 'book-a-demo')}
												className="flex items-center text-lg font-bold duration-100 hover:text-gray-300"
											>
												Book a Demo
											</a>
											<p>
												Request a demo from one of our friendly specialists.
											</p>
										</div>
									</li>
									<li className="flex py-3">
										<ArrowRight className="relative mr-3 text-white top-2" />
										<div>
											<a
												href={buildLink(locale, 'customers')}
												className="flex items-center text-lg font-bold duration-100 hover:text-gray-300 "
											>
												Get Inspired
											</a>
											<p>
												Discover the many ways in which our customers use
												BrightHR.
											</p>
										</div>
									</li>
								</ul>
							</div>
							<div className="w-full mt-2 md:w-full lg:w-1/2 xl:w-1/2">
								<ContactForm
									decryptFirstName={decryptFirstName}
									decryptLastName={decryptLastName}
									decryptPhone={decryptPhone}
									decryptEmail={decryptEmail}
									Company={Company}
								/>
							</div>
						</div>
					</div>
				</section>
				<section className="bg-gray-100">
					<div className="container p-6 py-8 mx-auto md:pb-32 md:pt-16">
						<h1 className="text-3xl font-bold md:text-4xl">Call us</h1>
						<h2 className="text-xl font-bold">Speak to one of our team today on:</h2>
						<h3 className="mt-8">UK &amp; Ireland offices:</h3>
						<div className="grid grid-cols-1 gap-8 my-8 md:my-12 sm:grid-cols-2 lg:grid-cols-3">
							<div className="h-full pt-6 text-center">
								<div className="flow-root h-full px-6 pb-8 bg-white rounded-lg shadow-lg">
									<div className="-mt-6">
										<div>
											<span className="inline-flex items-center justify-center p-2 rounded-md shadow-lg bg-brand-500">
												<img
													src={UKDots}
													alt="UK map"
													className="w-16 h-16"
												/>
											</span>
										</div>
										<h3 className="mt-8 text-xl font-bold tracking-tight text-gray-900">
											UK: <Var name="supportNumber" localeOveride="en" />
										</h3>
										<p className="mt-5 text-base text-gray-700">
											<span className="block mb-2 font-bold">
												BrightHR England (our registered address)
											</span>
											The Peninsula
											<br />
											Victoria Place
											<br />
											Manchester
											<br />
											M4 4FB
										</p>
									</div>
								</div>
							</div>
							<div className="h-full pt-6 text-center">
								<div className="flow-root h-full px-6 pb-8 bg-white rounded-lg shadow-lg">
									<div className="-mt-6">
										<div>
											<span className="inline-flex items-center justify-center p-2 rounded-md shadow-lg bg-brand-500">
												<img
													src={UKDots}
													alt="UK map"
													className="w-16 h-16"
												/>
											</span>
										</div>
										<h3 className="mt-8 text-xl font-bold tracking-tight text-gray-900">
											Scotland:{' '}
											<Var name="supportNumber" localeOveride="en" />
										</h3>
										<p className="mt-5 text-base text-gray-700">
											<span className="block mb-2 font-bold">
												BrightHR Scotland
											</span>
											180 West George Street
											<br />
											Glasgow
											<br />
											G2 2NR
										</p>
									</div>
								</div>
							</div>
							<div className="h-full pt-6 text-center">
								<div className="flow-root h-full px-6 pb-8 bg-white rounded-lg shadow-lg">
									<div className="-mt-6">
										<div>
											<span className="inline-flex items-center justify-center p-2 rounded-md shadow-lg bg-brand-500">
												<img
													src={IrelandDots}
													alt="UK map"
													className="w-16 h-16"
												/>
											</span>
										</div>
										<h3 className="mt-8 text-xl font-bold tracking-tight text-gray-900">
											Ireland: <Var name="supportNumber" localeOveride="ie" />
										</h3>
										<p className="mt-5 text-base text-gray-700">
											<span className="block mb-2 font-bold">
												BrightHR Ireland <br />
											</span>
											Block W<br />
											East Point Business Park
											<br />
											Alfie Bryne Rd, East Wall
											<br />
											Dublin 3<br />
											Ireland
										</p>
									</div>
								</div>
							</div>
							<div className="h-full pt-6 text-center">
								<div className="flow-root h-full px-6 pb-8 bg-white rounded-lg shadow-lg">
									<div className="-mt-6">
										<div>
											<span className="inline-flex items-center justify-center p-2 rounded-md shadow-lg bg-brand-500">
												<img
													src={UKDots}
													alt="UK map"
													className="w-16 h-16"
												/>
											</span>
										</div>
										<h3 className="mt-8 text-xl font-bold tracking-tight text-gray-900">
											Northern Ireland: 0333 258 7461
										</h3>
										<p className="mt-5 text-base text-gray-700">
											<span className="block mb-2 font-bold">
												BrightHR Northern Ireland <br />
											</span>
											Unit 5, Citylink Business Park
											<br />
											Albert Street
											<br />
											Belfast
											<br />
											BT12 4HQ
											<br />
											Northern Ireland
										</p>
									</div>
								</div>
							</div>
						</div>
						<h3>International offices:</h3>
						<div className="grid grid-cols-1 gap-8 my-8 md:my-12 sm:grid-cols-2 lg:grid-cols-3">
							<div className="h-full pt-6 text-center">
								<div className="flow-root h-full px-6 pb-8 bg-white rounded-lg shadow-lg">
									<div className="-mt-6">
										<div>
											<span className="inline-flex items-center justify-center p-2 rounded-md shadow-lg bg-brand-500">
												<img
													src={CanadaDots}
													alt="UK map"
													className="w-16 h-16"
												/>
											</span>
										</div>
										<h3 className="mt-8 text-xl font-bold tracking-tight text-gray-900">
											Canada: <Var name="supportNumber" localeOveride="ca" />
										</h3>
										<p className="mt-5 text-base text-gray-700">
											<span className="block mb-2 font-bold">
												BrightHR Canada
											</span>
											Peninsula Employment Services Limited
											<br />
											123 Front Street West, Suite 200
											<br />
											Toronto
											<br />
											ON M5J 2M2
											<br />
											Canada
										</p>
									</div>
								</div>
							</div>
							<div className="h-full pt-6 text-center">
								<div className="flow-root h-full px-6 pb-8 bg-white rounded-lg shadow-lg">
									<div className="-mt-6">
										<div>
											<span className="inline-flex items-center justify-center p-2 rounded-md shadow-lg bg-brand-500">
												<img
													src={AustraliaDots}
													alt="UK map"
													className="w-16 h-16"
												/>
											</span>
										</div>
										<h3 className="mt-8 text-xl font-bold tracking-tight text-gray-900">
											Australia:{' '}
											<Var name="supportNumber" localeOveride="au" />
										</h3>
										<p className="mt-5 text-base text-gray-700">
											<span className="block mb-2 font-bold">
												BrightHR Australia
											</span>{' '}
											Level 12,
											<br />
											700 Harris Street
											<br />
											Ultimo
											<br />
											2007
											<br />
											Australia
										</p>
									</div>
								</div>
							</div>
							<div className="h-full pt-6 text-center">
								<div className="flow-root h-full px-6 pb-8 bg-white rounded-lg shadow-lg">
									<div className="-mt-6">
										<div>
											<span className="inline-flex items-center justify-center p-2 rounded-md shadow-lg bg-brand-500">
												<img
													src={NewZealandDots}
													alt="UK map"
													className="w-16 h-16"
												/>
											</span>
										</div>
										<h3 className="mt-8 text-xl font-bold tracking-tight text-gray-900">
											New Zealand:{' '}
											<Var name="supportNumber" localeOveride="nz" />
										</h3>
										<p className="mt-5 text-base text-gray-700">
											<span className="block mb-2 font-bold">
												BrightHR New Zealand
											</span>
											1 Nelson St
											<br />
											Auckland
											<br />
											1010
											<br />
											New Zealand
										</p>
									</div>
								</div>
							</div>
						</div>
						<div className="flex flex-col justify-between mt-16 space-y-6 lg:flex-row md:mt-0 lg:space-y-0">
							<div className="w-full lg:w-1/2 ">
								<p className="mb-2">
									If there’s something you’d like to discuss, whether it’s a
									simple enquiry or even a quick question - feel free to give us a
									call, drop us an email, or write to us using the contact
									information provided.
								</p>
								<p>We look forward to hearing from you!</p>
							</div>
							<div className="flex items-center w-full px-5 py-5 rounded-lg lg:w-1/3 bg-brand-100">
								<img src={ContactIcon} className="h-16" alt="Contact" />
								<p>
									<span className="text-sm font-bold">
										Email us at:
										<br />
									</span>

									<a href={`mailto:${supportEmail}`}>
										<Var name="supportEmail" className="text-white" />
									</a>
								</p>
							</div>
						</div>
					</div>
				</section>
			</div>
		</>
	);
};

export const query = graphql`
	query contactQuery($id: String, $locale: String!) {
		site {
			siteMetadata {
				title
				siteUrl
				logo
			}
		}
		datoCmsContact(locale: { eq: $locale }, id: { eq: $id }) {
			form
		}
	}
`;

const WrappedContact = (props) => (
	<DefaultLayout fullWidth>
		<Contact {...props} />
	</DefaultLayout>
);

export default WrappedContact;
