import React, { useState } from 'react';
import { useSwipeable } from 'react-swipeable';
import styled from 'styled-components';
import cn from 'classnames';
import { mediaMin } from '../../utils/media';

const Carousel = styled.div`
	.image {
		max-height: 15rem;
	}

	.text,
	.image {
		transform: translate3d(0, 0, 0);
	}

	.text {
		opacity: 0;
		min-height: 320px;
	}

	.image {
		opacity: 0;
	}

	.active {
		position: relative;
		z-index: 10;
	}

	.active .text,
	.active .image {
		opacity: 1;
		transform: translateX(0);
	}

	${mediaMin.smallDesktop`
		.image {
			max-height: 20rem;
		}

		.image, .text {
			transition: all 0.5s ease-in-out;
		}

		.image {
			transform: translateX(50%);
		}

		.text {
			transform: translateX(-50%);
		}
	`}

	.shadow-lg {
		box-shadow: 0 0 20px 0 rgba(37, 44, 97, 0.15);
	}

	.stack {
		display: -ms-grid;
		display: grid;
	}

	.stack > * {
		grid-row: 1;
		grid-column: 1;
	}
`;

export const Slide = ({ image, children, active }) => (
	<div
		className={cn('flex flex-col items-stretch justify-center mx-auto slide  lg:flex-row', {
			active,
		})}
	>
		<div className="flex flex-col w-full px-10 py-10 bg-white shadow-lg lg:max-w-2xl xl:max-w-3xl text lg:mt-24 lg:pr-56">
			{children}
		</div>
		<div className="order-first w-full h-full overflow-hidden bg-white rounded shadow-lg image lg:max-w-xl lg:order-last lg:-ml-48">
			<img
				className="object-center w-full h-full pointer-events-none object-fit"
				src={image.fixed.src}
				srcSet={image.fixed.srcSet}
				alt={image.fixed.alt}
			/>
		</div>
	</div>
);

const Btn = (props) => (
	<button
		type="button"
		{...props}
		className={cn(
			'absolute top-0  items-center justify-center w-12 h-12 mt-56 text-white bg-gray-800 hidden lg:flex',
			props.className,
		)}
	>
		{props.children}
	</button>
);

const PostCarousel = ({ children }) => {
	const [currentSlide, setSlide] = useState(0);

	const next = () => {
		const nextSlide = currentSlide === children.length - 1 ? 0 : currentSlide + 1;
		setSlide(nextSlide);
	};

	const prev = () => {
		const prevSlide = currentSlide === 0 ? children.length - 1 : currentSlide - 1;
		setSlide(prevSlide);
	};

	const handlers = useSwipeable({
		onSwipedLeft: next,
		onSwipedRight: prev,
		preventDefaultTouchmoveEvent: true,
	});

	return (
		<Carousel className="relative px-4 py-4 overflow-hidden lg:px-12 carousel-wrapper xl:px-0 ">
			<div
				className="relative items-start w-full max-w-5xl mx-auto xl:max-w-6xl carousel stack"
				{...handlers}
			>
				{React.Children.map(children, (child, i) =>
					React.cloneElement(child, {
						active: i === currentSlide,
					}),
				)}

				<Btn className="left-0 -ml-12" onClick={prev}>
					<svg
						width="6"
						height="10"
						viewBox="0 0 6 10"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M0.51928 4.93097L5.82202 9.4103L5.82202 0.371773L0.51928 4.93097Z"
							fill="white"
						/>
					</svg>
				</Btn>

				<Btn className="right-0 -mr-12" onClick={next}>
					<svg
						width="6"
						height="10"
						viewBox="0 0 6 10"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M5.82203 4.93097L0.519287 9.4103L0.519288 0.371773L5.82203 4.93097Z"
							fill="white"
						/>
					</svg>
				</Btn>
			</div>
			<ul className="mt-6 mb-8 text-center md:mb-0 lg:hidden">
				{Array(React.Children.count(children))
					.fill()
					.map((_, i) => (
						// eslint-disable-next-line react/no-array-index-key
						<li className="inline-block" key={i}>
							<button
								type="button"
								onClick={() => setSlide(i)}
								className={cn(
									'w-4 h-4 bg-gray-500 rounded-full inline-block mx-2',
									{
										'active bg-brand-500': i === currentSlide,
									},
								)}
							>
								<span className="sr-only">Go to {i + 1}</span>
							</button>
						</li>
					))}
			</ul>
		</Carousel>
	);
};

export default PostCarousel;
