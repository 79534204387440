import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import CopyBlock from '../../../CopyBlock';

const Step = ({ step, index }) => (
	<>
		{index > 0 && (
			<svg
				width="604"
				height="169"
				className={`mx-auto lg:block hidden w-2/5 h-[169px] top-0 left-0 text-zapier-orange ${
					index % 2 === 0 ? '-scale-y-100' : ''
				}`}
				viewBox="0 0 604 169"
				preserveAspectRatio="none"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M602.5 1.9231V49.3585C602.5 69.5526 586.129 85.9231 565.935 85.9231L38.0646 85.9231C17.8705 85.9231 1.5 102.294 1.5 122.488L1.5 167.423"
					stroke="currentColor"
					strokeWidth="3"
					strokeLinecap="round"
					strokeDasharray="0.55 10.31"
				/>
			</svg>
		)}
		<div className="flex flex-col justify-center w-full gap-3 mx-auto mb-6 lg:gap-10 lg:flex-row lg:mb-0">
			<div className={`p-4 lg:p-2 lg:w-2/5 ${index % 2 === 0 ? 'lg:order-0' : 'lg:order-1'}`}>
				<div className="flex items-center justify-center w-8 h-8 mb-4 text-white rounded-full text-md lg:text-xl lg:w-12 lg:h-12 bg-zapier-orange">
					<p>{index + 1}</p>
				</div>
				<CopyBlock>{step.textNode.childMdx.body}</CopyBlock>
			</div>
			<div className="overflow-hidden outline-3 outline outline-offset-[-1px] rounded-lg lg:w-2/5 outline-zapier-orange shadow-lg max-w-[560px] mx-auto lg:mx-0">
				<GatsbyImage image={step.image.gatsbyImageData} alt={step.image.alt} />
			</div>
		</div>
	</>
);

export default Step;
