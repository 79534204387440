import React, { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';

const transparent =
	'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';

const VimeoThumbnail = ({ id, alt, ...props }) => {
	const [src, setSrc] = useState(null);
	const { ref, inView } = useInView({
		/* Optional options */
		threshold: 0,
		triggerOnce: true,
	});

	useEffect(() => {
		if (!inView) return;

		fetch(`https://vimeo.com/api/v2/video/${id}.json`)
			.then((res) => res.ok && res.json())
			.then((data) => {
				setSrc(data[0]?.thumbnail_large);
			});
	}, [id, inView]);

	return <img ref={ref} {...props} src={src || transparent} alt={alt} />;
};

export default VimeoThumbnail;
