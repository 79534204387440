import React, { useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import Icon from '@brighthr/component-icon';
import cn from 'classnames';
import ReactMarkdown from 'react-markdown';
import { useLocale } from '../../../context/Locale';
import useExistingUserTimout from '../../../hooks/useExistingUserTimout';
import CountdownTimer from '../../CountdownClockMini';

export default function AnnouncementBar({ bannerData }) {
	const locale = useLocale();
	const [showBanner, setShowBanner] = useState(false);
	const { isCookieReady, existingUser } = useExistingUserTimout({ locale });

	const closeBanner = () => {
		const closeTime = Date.now();
		localStorage.setItem('bannerCloseTime', closeTime.toString());
		setShowBanner(false);
	};

	const { defaultContent, contentLoggedin, countdownTimer, countdownTimerLoggedin } =
		bannerData || {};

	useEffect(() => {
		if (bannerData.togglebanner) {
			setShowBanner(true);
		}

		// Hide the banner for 72hrs if user closes it
		const lastCloseTime = localStorage.getItem('bannerCloseTime');

		if (lastCloseTime) {
			const timeSinceClose = Date.now() - parseInt(lastCloseTime, 10);
			const hoursSinceClose = timeSinceClose / (1000 * 60 * 60);

			if (hoursSinceClose < 72) {
				setShowBanner(false);
			} else {
				localStorage.removeItem('bannerCloseTime');
			}
		}
	}, [existingUser, contentLoggedin, defaultContent, bannerData]);

	const hasUserContent = !!(contentLoggedin?.length && isCookieReady && existingUser);
	const hasDefaultContent = defaultContent?.length > 0;

	const customComponents = {
		p: ({ node, ...props }) => <span {...props} />,
	};

	const customNodeRules = {
		a: ({ node, key, ...props }) => (
			<a
				className="font-bold text-white underline hover:underline-offset-2"
				{...props}
				key={key}
				target="_blank"
				rel="noopener noreferrer"
			>
				{props.children}
			</a>
		),
	};

	return (
		showBanner &&
		(hasDefaultContent || (hasUserContent && isCookieReady && existingUser)) && (
			<div
				className={cn(
					'bright-gradient relative items-center justify-center mx-auto  text-white',
					[hasUserContent || hasDefaultContent ? 'flex flex-col' : 'hidden'],
				)}
				role="alert"
			>
				<div className="container relative mx-auto flex flex-col items-center justify-center py-0.5">
					<div className="relative mx-auto flex whitespace-pre-wrap py-2 text-center text-xs font-medium tracking-normal sm:whitespace-normal">
						{isCookieReady && existingUser && (
							// offer banner for loggedIn users
							<div className="relative space-x-1">
								{countdownTimer && (
									<CountdownTimer targetDate={countdownTimerLoggedin} />
								)}
								<ReactMarkdown
									components={{
										...customComponents,
										...customNodeRules,
									}}
								>
									{contentLoggedin}
								</ReactMarkdown>
							</div>
						)}

						{!existingUser && defaultContent && (
							// offer banner for prospects/loggedOut users
							<div className="relative space-x-1">
								{countdownTimer && <CountdownTimer targetDate={countdownTimer} />}
								<ReactMarkdown
									components={{
										...customComponents,
										...customNodeRules,
									}}
								>
									{defaultContent}
								</ReactMarkdown>
							</div>
						)}
					</div>
				</div>

				{Object.values(bannerData || {}).some(Boolean) && (
					<Icon
						iconName="cross-thin"
						className="absolute right-0 mr-2 cursor-pointer fill-white"
						size="xs"
						onClick={() => closeBanner()}
						role="button"
						aria-label="Close Banner"
						tabIndex={0}
					/>
				)}
			</div>
		)
	);
}

export const query = graphql`
	fragment AnnouncementBannerFragment on DatoCmsAnnouncementBannerConnection {
		nodes {
			locale
			togglebanner
			defaultContent
			contentLoggedin
			countdownTimer
			countdownTimerLoggedin
		}
	}
`;
