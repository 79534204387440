import React from 'react';

const WebinarList = ({ children }) => {
	const slicedList = children.slice(0, 4);

	return (
		<ul
			className={`block pt-2 mx-auto mb-4 text-center list-inside xs:max-w-sm lg:max-w-6xl lg:grid lg:grid-cols-${slicedList.length} `}
		>
			{React.Children.map(slicedList, (child) =>
				React.cloneElement(child, {
					children: (
						<div className="p-4">
							<svg
								width="46"
								height="45"
								viewBox="0 0 46 45"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
								className="mx-auto mb-3"
							>
								<rect y="0.923096" width="46" height="44" rx="22" fill="#CCEBFF" />
								<path
									d="M31 16.9231L20 27.9231L15 22.9231"
									stroke="#0057AD"
									strokeWidth="2"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</svg>

							<span>{child.props.children}</span>
						</div>
					),
				}),
			)}
		</ul>
	);
};

export default WebinarList;
