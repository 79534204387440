import { graphql } from 'gatsby';

import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import cn from 'classnames';

import loadScript from 'load-script2';
import { useLocation } from '@reach/router';
import LinkButton from '@brighthr/component-linkbutton';
import Button from '@brighthr/component-button';
import { useFormik } from 'formik';
import { GatsbyImage } from 'gatsby-plugin-image';
import submitMarketoForm from '../components/Marketo/submitMarketoForm';
import DefaultLayout from '../layouts/Default';
import CopyBlock from '../components/CopyBlock';
import Breadcrumbs, { Breadcrumb } from '../components/Breadcrumbs';
import DatoSEO from '../components/DatoSEO';
import HrefLang from '../components/HrefLang';
import Logo from '../components/Logo';
import { buildLink, LOCALE_PATHS } from '../utils/locale';
import truncate from '../utils/truncate';

import AlertIcon from '../icons/AlertIcon.svg';

const DownloadPage = ({ data, pageContext }) => {
	const [showDownload, setShowDownload] = useState(false);
	const [showFormType, setShowFormType] = useState('');
	const {
		title,
		heroImage,
		document,
		bodyNode,
		seoMetaTags,
		_allSeoLocales,
		freeDownload,
		simpleForm,
		fullForm,
		brightbaseDocument,
		brightbaseDocumentMessageNode,
		formContent,
		additionalInformationTextNode,
		additionalInformationLinks,
		category,
		imageOverlayText,
		hideImageOverlay,
	} = data.download;
	const alternateSlugs = data.allDatoCmsDownload.nodes;

	useEffect(() => {
		// Preloads the Marketo form for background submission
		const loadForm = () =>
			loadScript('//app-lon04.marketo.com/js/forms2/js/forms2.min.js')
				.then(() => {
					window.MktoForms2.loadForm('//app-lon04.marketo.com', '217-MIC-854', 2796);
				})
				.catch(() => {});

		if (window.requestIdleCallback) {
			window.requestIdleCallback(loadForm);
		} else {
			setTimeout(loadForm);
		}
	}, []);

	const location = useLocation();
	const locale = LOCALE_PATHS[pageContext.locale];

	useEffect(() => {
		const completedSimpleForm = Cookies.get('bhr_sf');
		const completedFullForm = Cookies.get('bhr_ff');
		const existingUser = Cookies.get('bhr_has-logged-in');
		if (brightbaseDocument) {
			setShowFormType('');
		} else if (
			(completedFullForm === '1' && (fullForm || simpleForm)) ||
			(completedSimpleForm === '1' && simpleForm) ||
			existingUser === 'true'
		) {
			setShowFormType('');
			setShowDownload(true);
		} else if ((completedSimpleForm || completedFullForm) !== '1' && simpleForm) {
			setShowFormType('simple');
		} else if (completedFullForm !== '1' && fullForm) {
			setShowFormType('full');
		}
	}, [brightbaseDocument, fullForm, simpleForm]);

	const showDownloadButton = showDownload || freeDownload;

	const openDocument = () => {
		window.open(document.url, '_blank', 'noopener');
	};

	const initialValues = {};
	initialValues.LeadSource = `Download Centre`;
	initialValues.downloadCentreAsset = document?.filename || location.pathname;
	initialValues.web_form_id = 'Free Document';

	if (simpleForm) {
		initialValues.Email = '';
		initialValues.web_form_id = 'Minimal form';
	} else if (fullForm) {
		initialValues.FirstName = '';
		initialValues.LastName = '';
		initialValues.Email = '';
		initialValues.Company = '';
		initialValues.web_form_id = 'Full form';
	}

	const { handleChange, handleSubmit, setStatus, values, isSubmitting, status } = useFormik({
		initialValues,
		onSubmit: async (formData) => {
			submitMarketoForm(formData)
				.then(() => {
					if (simpleForm) {
						Cookies.set('bhr_sf', '1', { expires: 3650 });
					} else if (fullForm) {
						Cookies.set('bhr_ff', '1', { expires: 3650 });
					}
					openDocument();
					setShowDownload(true);
					setStatus('submitted');
				})
				.catch(() => {
					setStatus('error');
				});
		},
	});

	alternateSlugs.push(`download-centre/${category.slug}/`);

	return (
		<DefaultLayout fullWidth>
			<HrefLang
				locales={_allSeoLocales}
				slug={location.pathname}
				alternateSlugs={alternateSlugs}
			/>
			<DatoSEO path={location.pathname} meta={seoMetaTags} />
			<div className="relative overflow-x-hidden bg-gradient-to-bl from-brand-300 bg-wave-blue">
				<div className="relative max-w-6xl px-8 py-12 mx-auto xl:px-0">
					<Breadcrumbs>
						<Breadcrumb href={buildLink(locale, '/')} position={0}>
							Home
						</Breadcrumb>
						<Breadcrumb href={buildLink(locale, 'download-centre')} position={1}>
							Download Centre
						</Breadcrumb>
						{category && (
							<Breadcrumb
								href={buildLink(locale, `download-centre/${category.slug}`)}
								position={2}
							>
								{category.title}
							</Breadcrumb>
						)}
						<Breadcrumb position={category ? 3 : 2}>{title}</Breadcrumb>
					</Breadcrumbs>
					<div className="pt-8 lg:flex lg:items-start">
						<div className="z-10 w-full mx-auto lg:w-1/2 lg:ml-0">
							<h1 className="mb-4 text-3xl font-bold leading-none md:text-5xl">
								{title}
							</h1>
							<h2 className="whitespace-pre-line">
								<CopyBlock>{bodyNode.childMdx.body}</CopyBlock>
							</h2>

							{brightbaseDocument && (
								<div className="p-8 my-4 bg-white rounded shadow-sm">
									{brightbaseDocumentMessageNode.childMdx.wordCount.words > 0 ? (
										<CopyBlock>
											{brightbaseDocumentMessageNode.childMdx.body}
										</CopyBlock>
									) : (
										<>
											<p className="mb-4">
												This content is exclusively available to our Protect
												and Prime clients. If you&apos;re an existing
												customer please log in and you&apos;ll find
												BrightBase in the Documents area.
											</p>
											<p>
												If you&apos;d like to find out more about BrightHR
												including access to our guides and templates{' '}
												<LinkButton
													href={buildLink(locale, 'book-a-demo')}
													text="book a demo here"
													size="lg"
													textButton
													color="primary"
												/>
												.
											</p>
										</>
									)}
								</div>
							)}
							{status === 'submitted' && (
								<p className="mt-4 text-secondary-300">
									<strong>Thanks, form submitted</strong>
								</p>
							)}
							{(status === 'submitted' || showDownloadButton) && document && (
								<>
									<div className="w-full mt-8 mb-16 md:w-fit">
										<LinkButton
											href={document.url}
											target="_blank"
											rel="noopener noreferrer"
											text="Download now"
										/>
									</div>
								</>
							)}
							{status === 'error' && (
								<p className="p-8 my-4 bg-white rounded shadow-sm">
									Sorry we have been unable handle your request, please try again
									later.
								</p>
							)}
							{showFormType && !status && !freeDownload && (
								<form
									onSubmit={handleSubmit}
									className={cn(
										'max-w-xl p-8 mx-auto mt-8 mb-12 bg-white rounded shadow-sm lg:my-4',
										{
											'xl:flex xl:flex-row xl:flex-wrap':
												showFormType === 'simple',
										},
									)}
								>
									<div className="md:flex">
										<AlertIcon />
										<p
											className={cn(
												'mt-4 text-sm md:mt-0 xl:text-lg md:flex-1 md:ml-4 text-brand-500',
												{ 'mb-4': showFormType === 'simple' },
											)}
										>
											{formContent}
										</p>
									</div>
									{showFormType === 'simple' && (
										<input
											type="email"
											id="Email"
											name="Email"
											placeholder="Email Address"
											value={values.Email}
											onChange={handleChange}
											required
											className="block w-full px-4 py-3 my-4 placeholder-gray-400 border border-gray-400 rounded xl:my-0 xl:flex-1 xl:mr-4"
										/>
									)}
									{showFormType === 'full' && (
										<>
											<div className="md:flex md:flex-row md:justify-between">
												<label
													htmlFor="first"
													className="block w-full my-4 md:mr-2"
												>
													<strong>First Name</strong>
													<input
														type="text"
														id="FirstName"
														name="FirstName"
														placeholder="First Name"
														value={values.FirstName}
														onChange={handleChange}
														required
														className="block w-full px-4 py-3 mt-2 placeholder-gray-400 border border-gray-400 rounded"
													/>
												</label>
												<label
													htmlFor="last"
													className="block w-full my-4 md:ml-2"
												>
													<strong>Last Name</strong>
													<input
														type="text"
														id="LastName"
														name="LastName"
														placeholder="Last Name"
														value={values.LastName}
														onChange={handleChange}
														required
														className="block w-full px-4 py-3 mt-2 placeholder-gray-400 border border-gray-400 rounded"
													/>
												</label>
											</div>
											<label htmlFor="email" className="block mb-4">
												<strong>Email Address</strong>
												<input
													type="email"
													id="Email"
													name="Email"
													placeholder="Email Address"
													value={values.Email}
													onChange={handleChange}
													required
													className="block w-full px-4 py-3 mt-2 placeholder-gray-400 border border-gray-400 rounded"
												/>
											</label>
											<label htmlFor="company" className="block my-4">
												<strong>Company Name</strong>{' '}
												<small className="text-gray-600">(Optional)</small>
												<input
													type="text"
													id="Company"
													name="Company"
													placeholder="Company Name"
													value={values.Company}
													onChange={handleChange}
													className="block w-full px-4 py-3 mt-2 placeholder-gray-400 border border-gray-400 rounded"
												/>
											</label>
										</>
									)}

									<div
										className={cn('w-full mb-3 lg:mb-0 lg:w-auto', {
											'xl:ml-auto': showFormType === 'simple',
										})}
									>
										<Button
											id="Submit"
											type="submit"
											disabled={isSubmitting}
											text="Download Now"
										/>
									</div>
								</form>
							)}
						</div>
						{heroImage && (
							<div className="relative flex items-center justify-center p-8 pt-0">
								<div className="relative download-hero-image">
									{!hideImageOverlay && (
										<div className="absolute bottom-0 left-0 z-20 w-full px-4 pt-8 pb-12 text-white sm:px-8 download-overlay">
											<div className="relative z-10">
												{category && (
													<p className="mb-2">
														<small>{category.title}</small>
													</p>
												)}
												<h3 className="mb-2 text-sm font-bold sm:text-xl">
													{truncate(imageOverlayText, 80, '...') ||
														truncate(title, 80, '...')}
												</h3>
												<Logo className="w-20 ml-auto" />
											</div>
										</div>
									)}
									<GatsbyImage
										image={heroImage.gatsbyImageData}
										className="relative z-10 rounded-md shadow-md"
										alt={heroImage.alt || title}
									/>
								</div>
							</div>
						)}
					</div>
				</div>
			</div>
			<div className="max-w-6xl px-8 py-12 mx-auto xl:px-0 lg:flex">
				<div
					className={cn('md:mb-8', {
						'lg:pr-8 lg:flex-1': additionalInformationLinks?.length > 0,
					})}
				>
					<CopyBlock>{additionalInformationTextNode.childMdx.body}</CopyBlock>
				</div>
				{additionalInformationLinks.length > 0 && (
					<div className="flex flex-col items-center my-4 sm:justify-center sm:flex-row lg:flex-1">
						{additionalInformationLinks.map((e, index) => (
							<a
								href={buildLink(
									locale,
									`download-centre/${e.category.slug}/${e.slug}`,
								)}
								key={e.heroImage?.id}
								className={cn('m-2 lg:my-0 inline-block relative', {
									'lg:translate-y-6':
										index === 1 && additionalInformationLinks.length === 3,
								})}
							>
								{!e.hideImageOverlay && (
									<div className="absolute bottom-0 left-0 z-20 w-full p-8 text-white lg:px-4 lg:py-0 download-overlay">
										<div className="relative z-10">
											{e.category && (
												<p className="text-[9px]">
													<small>{e.category.title}</small>
												</p>
											)}
											<h3 className="text-xs font-bold leading-3">
												{truncate(e.imageOverlayText, 30, '...') ||
													truncate(e.title, 30, '...')}
											</h3>
											<Logo className="w-10 h-6 ml-auto" />
										</div>
									</div>
								)}
								<div className="lg:hidden">
									<GatsbyImage
										image={e.mobileImage?.gatsbyImageData}
										className="rounded-md shadow-lg"
										alt={e.mobileImage?.alt || e.title}
									/>
								</div>
								<div className="hidden lg:block">
									<GatsbyImage
										image={e.desktopImage?.gatsbyImageData}
										className="rounded-md shadow-lg"
										alt={e.desktopImage?.alt || e.title}
									/>
								</div>
							</a>
						))}
					</div>
				)}
			</div>
		</DefaultLayout>
	);
};

export default DownloadPage;

export const query = graphql`
	query seoAndDownload($id: String, $locale: String!, $originalId: String) {
		site {
			siteMetadata {
				title
				siteUrl
			}
		}

		download: datoCmsDownload(locale: { eq: $locale }, slug: { ne: null }, id: { eq: $id }) {
			seoMetaTags {
				...GatsbyDatoCmsSeoMetaTags
			}
			_allSeoLocales {
				locale
			}
			id
			originalId
			slug
			locale
			breadcrumbText
			title
			bodyNode {
				childMdx {
					body
				}
			}
			freeDownload
			simpleForm
			fullForm
			brightbaseDocument
			brightbaseDocumentMessageNode {
				childMdx {
					body
					wordCount {
						words
					}
				}
			}
			formContent
			heroImage {
				gatsbyImageData(width: 400, height: 568)
			}
			hideImageOverlay
			imageOverlayText
			document {
				url
				filename
			}
			category {
				slug
				title
			}
			additionalInformationTextNode {
				childMdx {
					body
				}
			}
			additionalInformationLinks {
				id
				title
				slug
				mobileImage: heroImage {
					gatsbyImageData(width: 218, height: 310)
				}
				desktopImage: heroImage {
					gatsbyImageData(width: 140, height: 200)
				}
				category {
					slug
					title
				}
				hideImageOverlay
			}
		}

		allDatoCmsDownload(filter: { originalId: { eq: $originalId } }) {
			nodes {
				id
				slug
				locale
			}
		}
	}
`;
