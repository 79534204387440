import React from 'react';
import { graphql } from 'gatsby';
import { useLocation } from '@reach/router';
import { useLocale } from '../context/Locale';

import DefaultLayout from '../layouts/Default';
import CopyBlock from '../components/CopyBlock';
import TermsProvider from '../components/CopyBlock/TermsProvider';
import CollapsibleTree from '../components/CollapsibleTree';
import HrefLang from '../components/HrefLang';
import heroImg from '../images/slavery-statement/broken-chains.svg';
import SEO from '../components/SEO';

const formatToc = (nodes) =>
	nodes?.map((node) => ({
		text: node.title,
		href: node.url,
		children: formatToc(node.items),
	}));

const SlaveryContent = ({ tableOfContentsItems, bodyMdx }) => (
	<div className="flex flex-col md:flex-row ">
		<div className="w-full h-full pr-4 mb-6 md:w-1/4 md:sticky top-24">
			<h4 className="mb-6 font-bold text-gray-500">Table of contents</h4>
			<CollapsibleTree items={formatToc(tableOfContentsItems)} />
		</div>
		<div className="w-full pt-16 pl-0 md:w-3/4 md:pl-10">
			<CopyBlock>{bodyMdx}</CopyBlock>
		</div>
	</div>
);

const SlaveryTrafficking = ({ data }) => {
	const { statementNode, title, versionDate, statementSignOffNode, _allSeoLocales, slug } =
		data.datoCmsSlaveryAndHumanTraffickingStatement;

	const location = useLocation();
	const locale = useLocale();

	return (
		<>
			<HrefLang locales={_allSeoLocales} slug={slug} />
			<SEO
				path={location.pathname}
				title="Slavery and Human Trafficking Statement"
				locale={locale}
			/>
			<div className="relative">
				<section className="flex flex-row items-start h-auto max-w-screen-xl mx-auto overflow-hidden bg-right-top bg-no-repeat bg-contain border-b border-gray-300 lg:h-64">
					<div className="max-w-2xl py-8">
						<h1 className="mb-3 text-2xl font-bold leading-tight lg:text-4xl md:mt-12">
							{title}
						</h1>
					</div>
					<img
						className="hidden w-4/12 h-full ml-auto md:block"
						src={heroImg}
						alt="broken chains"
						aria-hidden
					/>
				</section>

				<div className="md:pt-8 ">
					<h1 className="pt-8 pb-8 text-xl font-bold lg:text-3xl md:pt-0">
						Version Date: {versionDate}
					</h1>

					<SlaveryContent
						tableOfContentsItems={statementNode.childMdx.tableOfContents.items}
						bodyMdx={statementNode.childMdx.body}
					/>

					<div className="flex justify-end">
						<div className="w-full p-6 mt-8 border-l-4 border-brand-400 md:w-3/4 bg-brand-100">
							<CopyBlock>{statementSignOffNode.childMdx.body}</CopyBlock>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export const query = graphql`
	query SlaveryQuery($id: String, $locale: String!) {
		datoCmsSlaveryAndHumanTraffickingStatement(
			locale: { eq: $locale }
			slug: { ne: null }
			id: { eq: $id }
		) {
			locale
			slug
			_allSeoLocales {
				locale
			}
			statementNode {
				childMdx {
					body
					tableOfContents
				}
			}
			statementSignOffNode {
				childMdx {
					body
				}
			}
			title
			versionDate
		}
	}
`;

const SlaveryTraffickingWithLayout = (props) => (
	<DefaultLayout>
		<TermsProvider>
			<SlaveryTrafficking {...props} />
		</TermsProvider>
	</DefaultLayout>
);

export default SlaveryTraffickingWithLayout;
