/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import cn from 'classnames';

import LazyLoad from 'react-lazyload';
import CopyBlock from '../../CopyBlock';
import Container from '../../Container';
import VideoModal from '../../VideoModal';
import Laptop from '../../../icons/Laptop.svg';
import VideoArrow from '../../../icons/VideoArrow.svg';
import VideoPlayPink from '../../../icons/VideoPlayPink.svg';
import BlobSix from '../../../images/blobs/BlobSix.svg';
import PlayScrubber from '../../../icons/PlayScrubber.svg';

const VideoWithCopy = ({
	laptopThumbnail,
	noLaptopThumbnail,
	imagePosition,
	alt,
	youtubeId,
	vimeoId,
	text,
	videoClickPrompt,
	thumbnailInLaptop,
	showScrubber,
}) => {
	const [open, setOpen] = useState(false);

	return (
		<section className="last:mb-0">
			<Container className="flex flex-wrap items-start">
				<div className="w-full lg:w-1/2 lg:self-center">
					<CopyBlock>{text}</CopyBlock>
				</div>
				<div
					className={cn(
						`relative flex flex-col justify-center order-first w-full mb-10 lg:mb-0 lg:w-1/2`,
						{
							'lg:order-none lg:pl-10%': imagePosition === 'Right',
							'lg:pr-10%': imagePosition === 'Left',
						},
					)}
				>
					<LazyLoad once>
						<div
							role="button"
							onClick={() => setOpen(true)}
							className={cn(
								`absolute z-10 mx-auto -translate-x-1/2 -translate-y-1/2 cursor-pointer top-1/2 left-1/2`,
								{
									'lg:-translate-x-1/3':
										thumbnailInLaptop && imagePosition === 'Right',
									'lg:-translate-x-2/3':
										thumbnailInLaptop && imagePosition === 'Left',
								},
								{
									'lg:translate-x-[-40%]':
										!thumbnailInLaptop && imagePosition === 'Right',
									'lg:translate-x-[-60%]':
										!thumbnailInLaptop && imagePosition === 'Left',
								},
								{
									'w-full rounded-md lg:w-4/5': !thumbnailInLaptop,
									'w-3/4 lg:w-2/3': thumbnailInLaptop,
									'lg:translate-x-[-63.3333%]':
										thumbnailInLaptop && imagePosition === 'Left',
								},
							)}
							tabIndex="0"
						>
							<GatsbyImage
								image={thumbnailInLaptop ? laptopThumbnail : noLaptopThumbnail}
								className={cn(`z-10 w-full`, { 'rounded-md': !thumbnailInLaptop })}
								alt={alt}
							/>
							<span className="sr-only">Open video</span>
							<VideoPlayPink className="absolute z-20 w-16 h-auto translate-x-1/2 translate-y-1/2 right-1/2 bottom-1/2" />
							{showScrubber && <PlayScrubber className="absolute z-20 bottom-2" />}
							{videoClickPrompt && (
								<div
									className={cn(
										`absolute left-0 w-full text-sm font-bold leading-5 text-right text-secondary-300`,
										{
											'-top-12 w-[105%] sm:-top-14 md:-top-16 lg:-top-14 xl:-top-16':
												thumbnailInLaptop,
											'-top-8': !thumbnailInLaptop,
										},
									)}
								>
									<span>{videoClickPrompt}</span>
									<VideoArrow className="absolute top-2 -right-14" />
								</div>
							)}
						</div>
					</LazyLoad>
					{thumbnailInLaptop && (
						<Laptop
							className={cn(
								`absolute w-full h-auto -translate-x-1/2 -translate-y-40% top-1/2 left-1/2 lg:w-90%`,
								{
									'lg:-translate-x-60%': imagePosition === 'Left',
									'lg:translate-x-[-38%]': imagePosition === 'Right',
								},
							)}
						/>
					)}
					<img src={BlobSix} alt="" className="w-full h-auto" width="970" height="970" />
				</div>
			</Container>
			{open && (
				<VideoModal
					youtubeId={youtubeId}
					vimeoId={vimeoId}
					onClose={() => setOpen(false)}
				/>
			)}
		</section>
	);
};

export default VideoWithCopy;
/* eslint-enable jsx-a11y/click-events-have-key-events */
