import React, { useState } from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import cn from 'classnames';
import BrightLogo from '../../../images/partners/brightdevice@2x.svg';
import CopyBlock from '../../CopyBlock';

import VideoModal from '../../VideoModal';
import VideoPlayPink from '../../../icons/VideoPlayPink.svg';
import PlayScrubber from '../../../icons/PlayScrubber.svg';

const HeroWithBgImage = ({
	heroImage,
	title,
	descriptionMdx,
	children,
	heroThumbnail,
	youtubeId,
	vimeoId,
	underneathText,
	isVideoHero,
	badge,
}) => {
	const [open, setOpen] = useState(false);

	return (
		<div>
			<section
				className={cn(
					'z-0 overflow-hidden text-center bg-right bg-no-repeat bg-contain from-white to-brand-200 bg-gradient-to-t lg:text-left md:pt-8 md:pb-16 main-banner-section-bg',
					{ relative: !open, static: open },
				)}
			>
				<div className="px-6 py-4 lg:gap-16 lg:flex lg:py-16 lg:mx-auto lg:container">
					<div
						className={cn(`relative z-10 space-y-4`, {
							'lg:w-6/12': isVideoHero,
							'lg:w-7/12 ': !isVideoHero,
						})}
					>
						<h1 className="my-8 text-3xl font-bold leading-none text-left text-black md:text-4xl md:mt-0">
							{title}
						</h1>
						<div className="mb-4 text-left">
							<CopyBlock>{descriptionMdx}</CopyBlock>
						</div>
						<div className="flex items-center justify-center gap-8 md:justify-start md:flex-row ">
							{children}
						</div>
					</div>

					{!isVideoHero && (
						<div className="relative hidden mt-12 -mr-32 lg:-mr-6 md:block lg:mt-0 xxl:mt-4 lg:top-1/2 lg:absolute lg:right-0 lg:transform lg:-translate-y-1/2 lg:w-5/12 xxxl:mt-28 xl:-mr-16 product-ui-wrapper">
							<div className="relative">
								<GatsbyImage
									className="ml-auto"
									image={heroImage}
									alt="Device Screenshot"
								/>
								{badge && (
									<div className="absolute w-40% -z-10 -top-24 md:left-24 lg:left-12 lg:-top-12 xl:-top-24 xl:left-16 xxl:left-20 xxxl:-top-20 xxxl:w-[35%] xxxl:left-32">
										<GatsbyImage
											className="ml-auto"
											image={badge.gatsbyImageData}
											alt={badge.alt}
										/>
									</div>
								)}
							</div>
						</div>
					)}

					{isVideoHero && (
						<div className="flex-none max-w-[556px] m-auto shadow-lg rounded-xl">
							<button
								type="button"
								onClick={() => setOpen(true)}
								className="relative flex-none w-full mx-auto"
							>
								<GatsbyImage
									className="rounded-t-xl"
									image={heroThumbnail}
									alt="video thumbnail"
								/>
								<VideoPlayPink className="absolute z-20 w-16 h-auto transform translate-x-1/2 translate-y-1/2 right-1/2 bottom-1/2" />
								<PlayScrubber className="absolute bottom-0 z-20 m-4" />
							</button>
							<p className="bottom-0 w-full py-2 font-bold text-center text-gray-700 bg-white rounded-b-xl">
								{underneathText}
							</p>
						</div>
					)}
					{open && (
						<VideoModal
							youtubeId={youtubeId}
							vimeoId={vimeoId}
							onClose={() => setOpen(false)}
						/>
					)}
					<img
						src={BrightLogo}
						alt="BrightHR Logo"
						className="absolute z-0 h-full overflow-hidden text-black opacity-50 -right-36 -top-24 md:opacity-100 md:-z-20 lg:-top-16 xl:right-72 xxl:right-96"
					/>
				</div>
			</section>
		</div>
	);
};

export default HeroWithBgImage;
