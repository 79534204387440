import React from 'react';
import Icon from '@brighthr/component-icon';
import paymentCompleteIcon from '../../assets/paymentComplete.svg';

const EmailExists = () => (
	<div>
		<div
			className="flex flex-col items-center justify-end h-64 text-center"
			style={{
				background: 'linear-gradient(rgb(61, 176, 247) 0%, rgb(0, 87, 173) 100%)',
				margin: '-2rem -1.5rem 0px',
			}}
		>
			<img src={paymentCompleteIcon} alt="payment complete icon" />
			<h1 className="mx-4 my-4 text-2xl font-bold text-white">
				Thanks for purchasing BrightHR!
			</h1>
		</div>
		<p className="mx-4 my-4 bg-white">
			<p>You will receive a BrightHR registration email shortly.</p>
			<br />
			<p>
				If you haven’t received this email in the next 48 hours please contact our customer
				support team on:
			</p>
			<br />
			<a href="tel: 0800 783 2806" className="flex items-center">
				<Icon size={32} iconName="phone" />
				<span className="text-lg font-bold">0800 783 2806</span>
			</a>
		</p>
		<p>
			<a href="/" data-cy="confirmationBackLink">
				Back to BrightHR
			</a>
		</p>
	</div>
);

export default EmailExists;
