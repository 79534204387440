import React from 'react';
import format from 'date-fns/format';

import LazyLoad from 'react-lazyload';
import Stars from '../Stars';

const GoogleReviewTestimonials = ({ googleReviews, starClass = '', textClass = '' }) => {
	if (!googleReviews) {
		return null;
	}

	const fitlerReviewData = googleReviews.nodes.filter((review) => {
		const date = new Date(review.date_utc);

		const timestamp = date.getTime();

		const twelveHoursAgo = Date.now() - 12 * 60 * 60 * 1000;

		const isOlderThanTwelveHours = timestamp - twelveHoursAgo < 0;

		return review.body != null && review.rating === 5 && isOlderThanTwelveHours;
	});

	const reviewData = fitlerReviewData
		.map((review) => ({
			date: review.date_utc,
			text: review.body,
			rating: review.rating,
			id: review.id,
		}))
		.slice(0, 3);

	return (
		<div className="flex flex-wrap items-start pt-6 overflow-hidden border-t lg:flex-nowrap">
			<ul className="flex flex-wrap w-full p-0 m-0">
				{reviewData.map((r) => (
					<li
						key={r.id}
						className={`flex flex-col flex-nowrap w-full px-2 mb-6  md:flex-1 ${
							textClass || 'text-brand-500 '
						}`}
					>
						<p className="mb-4 font-bold">{format(new Date(r.date), 'dd MMMM yyyy')}</p>
						<div className={`flex flex-row mb-4 ${starClass || 'text-secondary-300'}`}>
							<Stars count={r.rating} />
							<p className="sr-only">{r.rating} stars</p>
						</div>

						<p className="mb-6">{r.text}</p>
					</li>
				))}
			</ul>

			<LazyLoad once>
				<a
					className="block px-4 mx-auto lg:ml-auto lg:mr-0 lg:min-w-[180px] lg:pr-0"
					href="https://www.google.com/maps/place/BrightHR/@43.6448909,-79.383023,15z/data=!4m7!3m6!1s0x0:0xdd0c2c01e92416ce!8m2!3d43.6448909!4d-79.383023!9m1!1b1"
				>
					<img
						width="150"
						height="150"
						alt="Google Review Rating"
						src="https://www.datocms-assets.com/23428/1644947235-google_48.svg"
					/>
				</a>
			</LazyLoad>
		</div>
	);
};

export default GoogleReviewTestimonials;
