import React from 'react';

import styled from 'styled-components';
import CopyBlock from '../../CopyBlock';
import { mediaMin } from '../../../utils/media';

import BeforeUrl from './before.png';
import AfterUrl from './after.png';

const Container = styled.section`
	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1421.48 508.55' preserveAspectRatio='none'%3E%3Cpath d='M1316.24,462.88C1003.84,582,557.84,425.27,252.84,470-32.08,511.71-55.36,178.54,79.72,79.09,329.84-105,668.14,94.43,1015.93,62.27S1552.77,416.68,1316.24,462.88Z' style='fill: %23eaf6fd'/%3E%3C/svg%3E%0A");
	background-size: cover;
	background-repeat: no-repeat;
	min-height: 15rem;
	background-position: center;
	position: relative;

	p:only-of-type {
		font-size: 1.25rem;
	}

	img {
		margin: 0 auto;
	}

	${mediaMin.tablet`
		&:before {
			position: absolute;
			content: '';
			width: 250px;
			height: 140px;
			background-repeat: no-repeat;
			background-size: contain;
			display: block;
			background-image: url(${BeforeUrl});
			top: 0;
			left: 0;
		}
	`};

	&:after {
		position: absolute;
		content: '';
		width: 270px;
		height: 135px;
		background-repeat: no-repeat;
		background-size: contain;
		display: block;
		background-image: url(${AfterUrl});
		bottom: 0;
		right: 0;
	}
`;

const HighlightedCopy = ({ children, version = 'v1' }) => (
	<div>
		{version === 'v1' && (
			<Container className="flex flex-col items-center justify-center max-w-4xl p-12 py-20 mx-auto mb-8 text-center md:text-lg md:px-12 lg:px-32 md:py-20 text-brand-500">
				<div className="relative z-10">
					<CopyBlock>{children}</CopyBlock>
				</div>
			</Container>
		)}
		{version === 'v2' && (
			<div className="form-section-bg-alt xl:-mt-32">
				<div className="container px-4 pt-24 mx-auto mb-16 text-center md:pt-40 lg:pt-48 xl:pt-24">
					<div className="relative z-10">
						<CopyBlock>{children}</CopyBlock>
					</div>
				</div>
			</div>
		)}
	</div>
);

export default HighlightedCopy;
