import React from 'react';
import DemoCTA from '../DemoCTA';
import DemoCTASecondary from '../DemoCTA/secondary';

const FooterCTA = ({
	showModal,
	footerTitle,
	primaryCtaText,
	primaryCtaHref,
	secondaryCtaHref,
	secondaryCtaText,
	secondaryShow,
}) => (
	<div className="px-4 mx-auto my-12 text-center sm:px-18 md:mb-16">
		<div className="z-10">
			<h2 className="mb-12 text-3xl font-bold text-brand-500 md:text-4xl">{footerTitle}</h2>
			<DemoCTA
				primaryCtaText={primaryCtaText}
				showModal={showModal}
				primaryCtaHref={primaryCtaHref}
			/>
			{secondaryShow && (
				<DemoCTASecondary
					secondaryCtaHref={secondaryCtaHref}
					secondaryCtaText={secondaryCtaText}
				/>
			)}
		</div>
	</div>
);

export default FooterCTA;
