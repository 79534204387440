import React, { useState } from 'react';

import ImageWithCopy from '../../components/CMSBlocks/ImageWithCopy';
import SlimImageWithCopy from '../../components/CMSBlocks/SlimImageWithCopy';
import StaticCTA from '../../components/StaticCTA';
import Container from '../../components/Container';
import DatoSEO from '../../components/DatoSEO';
import CMSWellCTA from '../../components/CMSBlocks/WellCTA';
import CarouselWithCopy from '../../components/CMSBlocks/CarouselWithCopy';
import VideoWithCopy from '../../components/CMSBlocks/VideoWithCopy';
import ArticleFeature from '../../components/CMSBlocks/ArticleFeature';
import Copy from '../../components/CMSBlocks/Copy';
import VideoModal from '../../components/VideoModal';
import CopyBlockProvider from '../../components/CopyBlock/provider';
import HrefLang from '../../components/HrefLang';
import { SUPPORTED_LOCALES } from '../../utils/locale';
import HeroHarryRedknapp from '../../components/Heros/HarryRedknapp';
import HeroWithWave from '../../components/Heros/WithWave';
import ReviewBadges from '../../components/ReviewBadges';
import GoogleReviewsTestimonials from '../../components/GoogleReviewsTestimonials';
import PromoPopup from '../../components/PromoPopup';
import LocaleContent from '../../components/LocaleContent';
import { useLocale } from '../../context/Locale';
import { useVar } from '../../components/Var';

const HomePage = ({ data, location, pageContext }) => {
	const {
		homepageElements,
		heroThumbnail,
		title,
		subTitle,
		videoId,
		descriptionNode,
		mainCta,
		heroImage,
		seoMetaTags,
	} = data.datoCmsHomepage;
	const blogPosts = data.allDatoCmsBlogPost.nodes;
	const { googleReviews } = data;
	const [isVideoVisible, setVideoVisible] = useState(false);

	if (!title) {
		return null;
	}

	const locale = useLocale();

	const autoSchema = {
		name: data.site.siteMetadata.title,
		image: [heroImage.url],
		url: data.site.siteMetadata.siteUrl,
		logo: data.site.siteMetadata.logo,
		telephone: useVar('salesNumber'),
	};

	return (
		<div>
			<HrefLang locales={SUPPORTED_LOCALES} slug="" />
			<CopyBlockProvider>
				<DatoSEO
					path={location.pathname}
					meta={seoMetaTags}
					lang={pageContext.locale}
					schemaType="Organization"
					autoSchema={autoSchema}
				/>
				{isVideoVisible && (
					<VideoModal youtubeId={videoId} onClose={() => setVideoVisible(false)} />
				)}

				<div className="max-w-full overflow-hidden">
					<LocaleContent showInLocales={['ie', 'en']}>
						<HeroHarryRedknapp
							heroImage={heroThumbnail.gatsbyImageData}
							title={title}
							descriptionMdx={descriptionNode.childMdx.body}
							page={location.pathname}
						>
							<div className="flex flex-row flex-wrap items-center justify-center">
								<ReviewBadges
									locale={locale}
									pageName="homepage"
									width="224"
									height="60"
									classes="w-48 lg:w-56"
									g2AwardClasses="ml-3 w-24 lg:w-28"
								/>
							</div>
						</HeroHarryRedknapp>
					</LocaleContent>

					<LocaleContent showInLocales={['ca', 'au', 'nz']}>
						<HeroWithWave
							heroImage={heroImage.gatsbyImageData}
							deviceImage={heroThumbnail}
							title={title}
							subTitle={subTitle}
							descriptionMdx={descriptionNode.childMdx.body}
							page={location.pathname}
						>
							<div className="flex flex-row flex-wrap items-center justify-center">
								<ReviewBadges
									locale={locale}
									pageName="homepage"
									width={locale === 'ca' ? '110' : '170'}
									height={locale === 'ca' ? '91' : '80'}
									classes="fill-current"
									g2AwardClasses="ml-4 w-24 lg:w-28"
								/>
							</div>
						</HeroWithWave>
					</LocaleContent>

					{homepageElements.map((el) => (
						<div className="mb-6 md:mb-12 last:mb-0" key={el.id}>
							{el.blocktype === 'ImageWithCopy' && (
								<ImageWithCopy
									imgSrc={el.imageWithCopyImage.gatsbyImageData}
									alt={el.imageWithCopyImage.alt}
									text={el.textNode.childMdx.body}
									imagePosition={el.imagePosition}
								/>
							)}
							{el.blocktype === 'SlimImageWithCopy' && (
								<SlimImageWithCopy
									imgSrc={el.slimImageWithCopyImage.gatsbyImageData}
									alt={el.slimImageWithCopyImage.alt}
									text={el.textNode.childMdx.body}
									header={el.header}
								/>
							)}
							{el.blocktype === 'VideoWithCopy' && (
								<VideoWithCopy
									laptopThumbnail={el.laptopThumbnail.gatsbyImageData}
									noLaptopThumbnail={el.noLaptopThumbnail.gatsbyImageData}
									alt={el.noLaptopThumbnail.alt}
									text={el.textNode.childMdx.body}
									imagePosition={el.imagePosition}
									youtubeId={el.youtubeId}
									vimeoId={el.vimeoId}
									videoClickPrompt={el.videoClickPrompt}
									thumbnailInLaptop={el.thumbnailInLaptop}
									showScrubber={el.showScrubber}
								/>
							)}
							{el.blocktype === 'CarouselWithCopy' && (
								<CarouselWithCopy
									slides={el.images}
									text={el.textNode.childMdx.body}
									imagePosition={el.imagePosition}
								/>
							)}
							{el.blocktype === 'Copy' && (
								<Copy
									centered={el.centered}
									background={el.background}
									width={el.width}
									textSize={el.textSize}
								>
									{el.textNode.childMdx.body}
								</Copy>
							)}
							{el.blocktype === 'WellCTA' && (
								<CMSWellCTA
									title={el.title}
									text={el.textNode.childMdx.bodyNode.childMdx.body}
								>
									{el.textNode.childMdx.body}
								</CMSWellCTA>
							)}
							{el.blocktype === 'ArticleFeature' && (
								<ArticleFeature
									key={el.id}
									featured={el.featured}
									recentBlogs={blogPosts}
								/>
							)}
						</div>
					))}

					{mainCta.length > 0 && (
						<CMSWellCTA
							title={mainCta[0].title}
							text={mainCta[0].textNode.childMdx.bodyNode.childMdx.body}
						/>
					)}

					<div className="p-6 mt-12 md:mt-20 md:mb-16 md:p-0">
						<StaticCTA locale={locale} />
					</div>

					<div data-testid="review-block">
						<Container>
							<LocaleContent showInLocales="ca">
								<GoogleReviewsTestimonials
									googleReviews={googleReviews}
									starClass="text-gold"
									textClass="text-black"
								/>
							</LocaleContent>
						</Container>
					</div>
				</div>
			</CopyBlockProvider>
			<PromoPopup template="home" />
		</div>
	);
};

export default HomePage;
