import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import LazyLoad from 'react-lazyload';
import CopyBlock from '../../CopyBlock';
import Container from '../../Container';
import CurlyArrow from '../../../images/vectors/curly-arrow.svg';
import BlueDots from '../../../images/blobs/blue-dots-two.svg';
import BlueQuote from '../../../images/vectors/blue-quote.svg';
import Blob from '../../../images/blobs/wide-blob/wide-light-blue.svg';

const BlobTestimonialTwo = ({ imgSrc, alt, text, quoteSource }) => (
	<section className="mb-16 lg:mb-24">
		<Container className="flex flex-col items-center lg:flex-row">
			<img
				className="absolute z-0 -translate-x-1/2 -translate-y-1/5"
				src={Blob}
				alt="Blob"
				width="738"
				height="616"
			/>
			<div className="relative w-full lg:w-1/2">
				<img
					className="absolute left-0 -top-5 sm:left-12 lg:left-0"
					src={BlueDots}
					alt="Blue Dots"
					width="286"
					height="246"
				/>
				<div className="relative">
					<div className="max-w-lg mx-auto">
						<LazyLoad once>
							<GatsbyImage image={imgSrc} alt={alt || ''} />
						</LazyLoad>
					</div>
				</div>
				<img
					className="absolute right-0 hidden w-1/4 bottom-20 lg:flex"
					src={CurlyArrow}
					alt="Curly Arrow"
				/>
			</div>

			<div className="relative lg:pl-6 lg:w-1/2 lg:py-0">
				<img
					className="pb-2 mr-auto"
					src={BlueQuote}
					alt="Blue Quote"
					width="38"
					height="37"
				/>
				<CopyBlock>{text}</CopyBlock>
				<div className="text-brand-400">
					<CopyBlock>{quoteSource}</CopyBlock>
				</div>
			</div>
		</Container>
	</section>
);

export default BlobTestimonialTwo;
