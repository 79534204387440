import React from 'react';
import cn from 'classnames';
import Var, { useVar } from '../../Var';

export const NumCompanies = ({ className }) => (
	<span className={className}>
		<Var name="numCompanies" />
	</span>
);

export const SalesNumber = ({ className }) => {
	const salesNumber = useVar('salesNumber');

	return (
		<a href={`tel:${salesNumber}`} className={cn('font-bold', className)}>
			<Var name="salesNumber" />
		</a>
	);
};

export const SupportNumber = ({ className }) => {
	const supportNumber = useVar('supportNumber');

	return (
		<a href={`tel:${supportNumber}`} className={cn('font-bold', className)}>
			<Var name="supportNumber" />
		</a>
	);
};

export const SupportEmail = ({ className }) => {
	const supportEmail = useVar('supportEmail');

	return (
		<a href={`mailTo:${supportEmail}`} className={cn('font-bold', className)}>
			<Var name="supportEmail" />
		</a>
	);
};
