import React from 'react';

import Layout from '../layouts/Default';
import SEO from '../components/SEO';
import Stars from '../icons/Stars.svg';
import StarsLg from '../icons/StarsLg.svg';
import Clouds from '../icons/Clouds.svg';
import CloudsLg from '../icons/CloudsLg.svg';

const ServerError = () => (
	<>
		<SEO title="Uh-oh! Something's gone wrong" path="/500" />

		<div className="relative block overflow-hidden bg-gradient-to-b from-brand-900 to-brand-500">
			<Stars className="absolute top-0 -inset-x-12 md:left-1/2 md:right-0 md:transform md:-translate-x-1/2 md:w-full lg:hidden" />
			<StarsLg className="absolute top-0 hidden -inset-x-12 lg:block xl:left-1/2  xl:-translate-x-1/2 xl:w-full xl:right-0" />
			<Clouds className="absolute top-16 -inset-x-20 sm:left-1/2 sm:right-0 sm:-translate-x-1/2 sm:w-full lg:hidden" />
			<CloudsLg className="absolute hidden bottom-16 -inset-x-20 lg:block xl:left-0 xl:right-0 xl:w-full" />
			<span className="absolute z-10 block w-full tracking-tight font-bold text-center top-16 text-white text-[48vw] opacity-10 leading-none lg:text-[33vw] xl:text-[30rem]">
				500
			</span>
			<div className="relative z-20 flex flex-col w-full px-5 pb-64 text-center pt-36 flex-nowrap lg:pt-56 lg:pb-48 lg:max-w-3xl lg:mx-auto xl:pb-64">
				<div className="relative w-full mx-auto text-white lg:w-auto">
					<h1 className="pb-4 text-3xl font-bold text-white md:pb-2">
						Uh-oh! Something&rsquo;s gone wrong
					</h1>
					<p className="mb-4 text-xl leading-7">
						Sorry, we&rsquo;re having problems with the page you&rsquo;re looking for.
					</p>
					<p className="text-xl leading-7">Please refresh or try again later.</p>
				</div>
			</div>
		</div>
	</>
);

const WrappedServerError = (props) => (
	<Layout fullWidth>
		<ServerError {...props} />
	</Layout>
);

export default WrappedServerError;
