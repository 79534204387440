import React from 'react';
import cn from 'classnames';

import styled from 'styled-components';
import CopyBlock from '../../CopyBlock';

const Container = styled.div`
	img {
		margin: 0 auto;
	}
`;

const Copy = ({ children, centered, width, background, textSize }) => (
	<Container
		className={cn('w-full mx-auto mb-8 rounded', {
			'text-center': centered,
			'bg-gray-200 py-10': background === 'grey',
			'bg-brand-500 py-10 text-white': background === 'blue',
			'text-sm': textSize === 'sm',
		})}
	>
		<div
			className={cn('mx-auto w-full px-6', {
				'max-w-sm': width === 'sm',
				'max-w-md': width === 'md',
				'max-w-lg': width === 'lg',
				container: width === 'full',
			})}
		>
			<CopyBlock>{children}</CopyBlock>
		</div>
	</Container>
);

export default Copy;
