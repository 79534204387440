import React from 'react';

import Austrialia from '../../images/vectors/Flags/Austrialia.svg';
import Canada from '../../images/vectors/Flags/Canada.svg';
import Ireland from '../../images/vectors/Flags/Ireland.svg';
import NewZealand from '../../images/vectors/Flags/NewZealand.svg';
import UnitedKingdom from '../../images/vectors/Flags/UnitedKingdom.svg';
import { DEFAULT_LOCALE } from '../../utils/locale';

const icons = {
	au: Austrialia,
	ca: Canada,
	ie: Ireland,
	nz: NewZealand,
	en: UnitedKingdom,
};

const CountryFlag = ({ countryCode, className = '' }) => {
	const FlagSrc = icons[countryCode] || icons[DEFAULT_LOCALE];

	return <img loading="lazy" aria-hidden alt="" src={FlagSrc} className={className} />;
};

export default CountryFlag;
