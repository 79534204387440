import React from 'react';
import cn from 'classnames';
import { buildLink } from '../../utils/locale';

const DownloadCategoryList = ({ downloadCategory, search, downloadFilter, locale }) => (
	<div className="flex overflow-x-scroll md:overflow-x-visible">
		<a
			className={cn(
				'mb-6 mr-6 font-bold focus:text-brand-500 focus:outline-none hover:text-brand-500',
				{
					'text-brand-500': !downloadFilter,
				},
			)}
			href={buildLink(locale, `download-centre?page=1${search ? `&search=${search}` : ''}`)}
		>
			All
		</a>
		{downloadCategory.map((downloadCat) => (
			<a
				key={downloadCat.id}
				id={downloadCat.title}
				className={cn(
					'mb-6 mr-6 font-bold focus:text-brand-500 focus:outline-none hover:text-brand-500 last:mr-0',
					{
						'text-brand-500': downloadFilter === downloadCat.title,
					},
				)}
				href={buildLink(
					locale,
					`download-centre/${downloadCat.slug}?page=1${
						search ? `&search=${search}` : ''
					}`,
				)}
			>
				{downloadCat.title}
			</a>
		))}
	</div>
);

export default DownloadCategoryList;
