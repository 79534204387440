const updateCustomer = async ({
	customerId,
	email,
	telephone,
	firstName,
	lastName,
	companyName,
	countryCode,
	postState,
	CustomerSetupError,
	setProcessing,
}) => {
	const { customer, error } = await fetch('/api/stripe/customer/update', {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			id: customerId,
			email,
			phone: telephone,
			name: `${firstName} ${lastName}`,
			metadata: {
				companyName,
			},
			address: {
				country: countryCode,
				state: postState,
			},
		}),
	})
		.then((res) => res.json())
		.catch((e) => {
			setProcessing(false);
			throw new CustomerSetupError(e.message);
		});

	if (error) {
		setProcessing(false);
		throw new CustomerSetupError(error);
	}

	return customer;
};

export default updateCustomer;
