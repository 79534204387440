import React from 'react';
import Plant from './assets/Plant.svg';

const WellCTA = ({ children }) => (
	<div className="pt-0">
		<div className="flex items-center max-w-5xl mx-auto text-center aspect-video wellcta">
			<div className="relative w-full px-6 py-6 mx-8 text-center bg-white rounded shadow-md md:w-3/4 lg:w-2/3 md:mx-auto lg:px-10 lg:py-6">
				{children}

				<img
					alt="Plant"
					aria-hidden
					src={Plant}
					className="absolute bottom-0 left-0 hidden w-32 -mb-32 -ml-20 md:block"
				/>
			</div>
		</div>
	</div>
);

export default WellCTA;
