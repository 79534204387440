import React, { useState, useRef, useEffect, useMemo } from 'react';
import cn from 'classnames';
import { graphql } from 'gatsby';
import LinkButton from '@brighthr/component-linkbutton';
import throttle from 'lodash.throttle';
import { useLocation } from '@reach/router';
import { format } from 'date-fns';
import DefaultLayout from '../layouts/Default';
import SEO from '../components/SEO';
import useFuse from '../components/useFuse';
import PostCarousel, { Slide } from '../components/PostCarousel';
import BlogCard from '../components/Blog/Card';
import dateToUTC from '../utils/dateToUTC';
import WebinarPopup from '../components/WebinarPopup';
import CarouselBg from '../images/blog/carousel-bg.svg';
import HrefLang from '../components/HrefLang';
import { buildLink, LOCALE_PATHS, SUPPORTED_LOCALES } from '../utils/locale';
import scrollDirection from '../utils/scrollDirection';
import Newsletter from '../components/Footer/Components/newsletter';
import LocaleContent from '../components/LocaleContent';
import Pagination from '../components/Pagination';
import usePagination from '../hooks/usePagination';
import usePaginatedSearch from '../hooks/usePaginatedSearch';
import useExistingUserTimout from '../hooks/useExistingUserTimout';

const POST_PER_PAGE = 6;

const fuseOptions = {
	keys: ['title', 'subtitle', 'breadcrumbText', 'bodyNode.childMdx.excerpt'],
};

const BlogPage = ({ data, pageContext }) => {
	const listRef = useRef();
	const searchRef = useRef();
	const searchInputRef = useRef();
	const { nodes: posts } = data.allDatoCmsBlogPost;
	const { nodes: slides } = data.featured;
	const { result, search } = useFuse(posts, fuseOptions);
	const location = useLocation();
	const locale = LOCALE_PATHS[pageContext.locale];
	const scroll = scrollDirection();

	const { existingUser } = useExistingUserTimout({ locale });
	const { searchInput, showSearchResults } = usePaginatedSearch({
		search,
		searchInputRef,
	});

	const categories = useMemo(
		() =>
			data.allDatoCmsBlogCategory.nodes
				.map((cat) => ({
					...cat,
					count: posts.filter(({ category }) => category.id === cat.id).length,
				}))
				.filter((c) => c.count > 0),
		[data.allDatoCmsBlogCategory.nodes, posts],
	);

	const postsNotFeatured = result
		.filter((article) => !slides.some((slide) => slide.id === article.id))
		.sort((a, b) => {
			if (searchInput) return 0;

			const updatedAtA = dateToUTC(new Date(a.date || a.meta.updatedAt));
			const updatedAtB = dateToUTC(new Date(b.date || b.meta.updatedAt));

			return +updatedAtB - +updatedAtA;
		});

	const { page, pageNumbers, currentPageResults } = usePagination(
		postsNotFeatured.length,
		POST_PER_PAGE,
	);

	const [stuck, setStuck] = useState(false);

	useEffect(() => {
		const onScroll = throttle(() => {
			if (searchRef.current?.getBoundingClientRect().top === 0) {
				setStuck(true);
			} else {
				setStuck(false);
			}
		}, 100);

		window.addEventListener('scroll', onScroll);

		return () => window.removeEventListener('scroll', onScroll);
	}, [setStuck]);

	return (
		<DefaultLayout fullWidth>
			<HrefLang locales={SUPPORTED_LOCALES} slug="blog" />
			<SEO
				path={location.pathname}
				title="Blog"
				description="Visit the BrightHR Blog for expert writing. We offer news, insights, guides, and discussions on hot topics such as HR, HR software, employment law, and more."
			/>
			{!existingUser && (
				<LocaleContent showInLocales={['en', 'ca', 'au', 'ie']}>
					<WebinarPopup variant="image" />
				</LocaleContent>
			)}
			<div className="px-4 pt-6 sm:px-6 lg:px-12 xl:px-6">
				<h1 className="max-w-6xl mx-auto mb-16 text-4xl font-bold lg:text-5xl">
					BrightHR blog
				</h1>

				<h2 className="max-w-6xl pb-6 mx-auto text-xl font-bold md:-mb-24 lg:pb-0">
					Featured posts
				</h2>
			</div>

			<div
				className="mx-auto bg-right bg-no-repeat bg-contain md:mb-12"
				style={{
					backgroundImage: `url(${CarouselBg})`,
					backgroundPosition: '80% 0',
				}}
			>
				<PostCarousel>
					{slides.map(
						({
							id,
							title,
							subtitle,
							image,
							date,
							meta: { firstPublishedAt, updatedAt },
							category,
							slug,
						}) => (
							<Slide key={id} image={image}>
								<div className="flex flex-col h-full">
									<p className="mb-4 text-xs font-bold text-gray-500 uppercase">
										{category.name}
									</p>
									<h3 className="mb-4 text-2xl font-bold leading-tight text-gray-800">
										{title}
									</h3>

									<p className="mb-4 text-gray-800">{subtitle}</p>

									<div className="flex flex-col justify-between w-full mt-auto lg:flex-row">
										<p className="font-bold">
											{format(
												new Date(date || updatedAt || firstPublishedAt),
												'eeee, MMM dd, yyyy',
											)}
										</p>
										<div className="mt-4 lg:-mr-40 lg:mt-0">
											<LinkButton
												color="accent"
												size="lg"
												href={buildLink(
													locale,
													`blog/${category.slug}/${slug}`,
												)}
												outlineButton
												text="Read more"
											/>
										</div>
									</div>
								</div>
							</Slide>
						),
					)}
				</PostCarousel>
			</div>

			<label
				ref={searchRef}
				htmlFor="search"
				className={cn(
					'sticky block z-40 mb-6 transition-all duration-300 ease-in-out mx-auto max-w-6xl',
					{
						'shadow-md transition-shadow duration-300': stuck,
						'lg:top-20 top-14': scroll === 'up',
						'top-4': scroll === 'down',
					},
				)}
			>
				<div className="flex flex-row items-stretch max-w-6xl mx-4 bg-white shadow-lg">
					<div className="py-4 pl-6 pr-4 bg-gray-200 border-2 border-r-0 border-gray-300 rounded-l">
						<svg
							className="mt-1"
							width="18"
							height="18"
							viewBox="0 0 18 18"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M1.79993 8.09969C1.79993 4.62043 4.62043 1.79993 8.09969 1.79993C11.579 1.79993 14.3995 4.62043 14.3995 8.09969C14.3995 9.79758 13.7278 11.3386 12.6356 12.4715C12.6055 12.4948 12.5765 12.5202 12.5488 12.5479C12.5211 12.5756 12.4956 12.6046 12.4723 12.6348C11.3393 13.7274 9.79798 14.3995 8.09969 14.3995C4.62043 14.3995 1.79993 11.579 1.79993 8.09969ZM13.1559 14.4278C11.7702 15.5364 10.0124 16.1994 8.09969 16.1994C3.62635 16.1994 0 12.573 0 8.09969C0 3.62635 3.62635 0 8.09969 0C12.573 0 16.1994 3.62635 16.1994 8.09969C16.1994 10.0119 15.5367 11.7693 14.4286 13.1549L17.7364 16.4628C18.0879 16.8142 18.0879 17.384 17.7364 17.7355C17.3849 18.087 16.8151 18.087 16.4637 17.7355L13.1559 14.4278Z"
								fill="#004991"
							/>
						</svg>
					</div>

					<input
						ref={searchInputRef}
						id="search"
						className="w-full py-4 pr-6 mx-auto bg-gray-200 border-2 border-l-0 border-gray-300 rounded-r outline-none"
						placeholder="Search posts"
						onChange={() => {
							showSearchResults();

							window.scrollTo(
								0,
								listRef.current?.offsetTop - searchRef.current?.clientHeight - 16,
							);
						}}
						value={searchInput}
					/>
				</div>
			</label>

			<div
				className="flex flex-col items-start max-w-6xl px-6 mx-auto mb-6 sm:mb-24 xl:px-0 lg:flex-row"
				ref={listRef}
			>
				<div className="lg:w-8/12">
					{postsNotFeatured.length === 0 && (
						<p className="mb-12 text-xl font-bold">No posts</p>
					)}
					{currentPageResults(postsNotFeatured).map(
						({
							id,
							title,
							subtitle,
							thumbnail,
							image,
							category,
							meta: { updatedAt },
							date,
							slug,
						}) => (
							<BlogCard
								key={id}
								title={title}
								subtitle={subtitle}
								image={thumbnail || image}
								category={category}
								slug={slug}
								updatedAt={updatedAt}
								date={date}
								locale={locale}
							/>
						),
					)}

					{postsNotFeatured.length > POST_PER_PAGE && (
						<div className="mt-4">
							<Pagination
								page={page}
								searchInput={searchInput}
								pageNumbers={pageNumbers}
							/>
						</div>
					)}
				</div>
				<div className="w-full pt-6 lg:pl-6 lg:pt-0 lg:w-4/12">
					<div
						className="px-6 py-4 bg-right-top bg-no-repeat bg-contain border border-gray-200 rounded shadow-lg"
						style={{
							backgroundImage: `url(${CarouselBg})`,
							backgroundPosition: '175px -150px',
						}}
					>
						{locale === 'ca' ? (
							<>
								<h3 className="mb-4 text-2xl font-bold leading-snug text-brand-500">
									Join the mailing list
								</h3>
								<p className="mb-4">
									Sign up to get the latest HR news and BrightHR product updates
									straight to your inbox. Just enter your email and hit the sign
									up button to get started...
								</p>
							</>
						) : (
							<>
								<h3 className="mb-4 text-2xl font-bold leading-snug text-brand-500">
									Join our BrightHR newsletter
								</h3>
								<p className="mb-4">
									Every month we’ll send you the latest HR news and BrightHR
									product updates straight to your inbox. Just enter your email
									and hit the sign up button to get started...
								</p>
							</>
						)}
						<Newsletter stlying="inline" />
					</div>
				</div>
			</div>

			{categories.length > 0 && (
				<div className="max-w-6xl px-6 mx-auto mb-12 overflow-hidden xl:px-0">
					<h2 className="max-w-6xl pb-6 mx-auto text-xl font-bold md:-mb-24 lg:pb-0">
						Categories
					</h2>
					<ul className="flex flex-wrap mt-0 md:-mx-4 sm:mt-24">
						{categories.map((cat) => (
							<li
								className="w-full mb-4 md:w-1/2 lg:w-1/3 md:p-4 md:mb-0"
								key={cat.id}
							>
								<div
									className="flex h-40 bg-center bg-cover"
									style={{ backgroundImage: `url(${cat.image.fixed?.src})` }}
								>
									<a
										href={buildLink(locale, `blog/${cat.slug}`)}
										className="block px-6 py-2 mt-auto bg-white"
									>
										<strong>{cat.name}</strong> ({cat.count}){' '}
										<svg
											width="12"
											height="7"
											viewBox="0 0 24 14"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
											className="inline-block ml-2"
										>
											<path
												d="M17.2473 0L16.112 1.13527L20.927 5.95025H0V7.55524H20.927L16.112 12.3702L17.2473 13.5055L24 6.75381L17.2473 0.00213984V0Z"
												fill="#3DB0F7"
											/>
										</svg>
									</a>
								</div>
							</li>
						))}
					</ul>
				</div>
			)}
		</DefaultLayout>
	);
};

export default BlogPage;

export const query = graphql`
	query BlogPosts($locale: String!) {
		featured: allDatoCmsBlogPost(
			limit: 3
			filter: { locale: { eq: $locale }, featured: { eq: true }, slug: { ne: null } }
			sort: { fields: meta___firstPublishedAt, order: DESC }
		) {
			nodes {
				id
				title
				subtitle
				slug
				date
				meta {
					updatedAt
					firstPublishedAt
				}
				category {
					slug
					name
					id
				}
				thumbnail {
					fixed(width: 650, imgixParams: { auto: "compress", q: 60 }) {
						srcSet
						src
					}
				}
				image {
					fixed(width: 650, imgixParams: { auto: "compress", q: 60 }) {
						srcSet
						src
					}
				}
			}
		}
		allDatoCmsBlogPost(filter: { locale: { eq: $locale }, slug: { ne: null } }) {
			nodes {
				...BlogCard
			}
		}
		allDatoCmsBlogCategory(
			filter: {
				locale: { eq: $locale }
				originalId: { nin: ["5165422", "5837588", "5165423"] }
			}
		) {
			nodes {
				originalId
				id
				name
				slug
				image {
					fixed(
						imgixParams: { auto: "compress,format", q: 65, ar: "2.283950617" }
						width: 540
					) {
						src
					}
				}
			}
		}
	}
`;
