import { useState, useEffect, useRef } from 'react';

require('intersection-observer');

function useOnScreen(rootMargin = '0px') {
	// State and setter for storing whether element is visible
	const [isIntersecting, setIntersecting] = useState(false);
	const ref = useRef();

	useEffect(() => {
		const el = ref.current;
		// eslint-disable-next-line compat/compat
		const observer = new IntersectionObserver(
			([entry]) => {
				// Update our state when observer callback fires
				setIntersecting(entry.isIntersecting);
			},
			{
				rootMargin,
			},
		);

		if (el) {
			observer.observe(el);
		}

		return () => {
			observer.unobserve(el);
		};
	}, [rootMargin]); // Empty array ensures that effect is only run on mount and unmount

	return [ref, isIntersecting];
}

export default useOnScreen;
