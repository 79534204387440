const handleFormSubmit = async (
	values,
	actions,
	setErrorMessage,
	setProcessing,
	updateSession,
	AccountAvailabilityError,
	CustomerSetupError,
	PurchaseError,
	PaymentMethodCreationError,
	buy,
	// eslint-disable-next-line max-params
) => {
	setErrorMessage(null);
	setProcessing(true);
	try {
		await updateSession({ ...values });
		await buy();
	} catch (e) {
		if (e instanceof AccountAvailabilityError || e instanceof CustomerSetupError) {
			setErrorMessage('Something went wrong, please try again.');
		} else if (e instanceof PurchaseError) {
			setErrorMessage(e.message);
		} else if (e instanceof PaymentMethodCreationError) {
			setErrorMessage(e.message);
		} else {
			setErrorMessage(e.message || e);
		}

		actions.setSubmitting(false);
		setProcessing(false);
	}
};

export default handleFormSubmit;
