import React from 'react';
import Buttons from './Buttons';
import Logo from '../../Logo';
import DotsHorizontal from '../../../images/blobs/dots-oval-horizontal.svg';
import DotsVertical from '../../../images/blobs/dots-oval-vertical.svg';
import Device from './Device';
import Quote from '../Quote';

const Banner = ({ bannerData, bannerStyle }) => {
	const { description, deviceImage, deviceType, quote, title } = bannerData;

	const buttonData = {
		ctaOneText: bannerData.ctaOneText,
		ctaOneHref: bannerData.ctaOneHref,
		ctaTwoText: bannerData.ctaTwoText,
		ctaTwoHref: bannerData.ctaTwoHref,
	};

	const quoteData = {
		quoteText: bannerData.quoteText,
		quoteCitationPerson: bannerData.quoteCitationPerson,
		quoteCitationRole: bannerData.quoteCitationRole,
	};

	if (bannerStyle === 'inline') {
		return (
			<div className="relative flex flex-col justify-center w-full p-4 overflow-hidden rounded lg:flex-row bg-brand-100">
				<img
					alt=""
					src={DotsHorizontal}
					className="absolute z-20 -right-6 -bottom-10"
					width="189"
					height="91"
				/>
				<div
					className={`${
						deviceType === 'none' ? 'w-full' : 'lg:w-[60%]'
					} flex flex-col items-center gap-2 text-center justify-center`}
				>
					<div className="mb-1 text-brand-500">
						<Logo width={137} height={35} />
					</div>
					<p className="text-lg font-bold text-brand-500">{title}</p>

					{quote ? (
						<Quote quoteData={quoteData} />
					) : (
						<p className="text-sm">{description}</p>
					)}

					<div className="relative z-30 hidden mt-2 mb-4 lg:block">
						<Buttons buttonData={buttonData} />
					</div>
				</div>

				{deviceType && (
					<div
						className={`${
							deviceType === 'laptop' ? 'lg:-mr-20' : ''
						} lg:flex lg:justify-center`}
					>
						<Device
							deviceType={deviceType}
							buttonData={buttonData}
							deviceImage={deviceImage}
						/>
					</div>
				)}

				<img
					alt=""
					src={DotsVertical}
					className="absolute z-0 -top-20 -left-10 -rotate-12"
					width="85"
					height="137"
				/>
			</div>
		);
	}

	if (bannerStyle === 'side') {
		return (
			<div className="relative flex flex-col items-center w-full p-4 my-10 overflow-hidden rounded bg-brand-100">
				<img
					alt=""
					src={DotsHorizontal}
					className="absolute z-20 -right-6 -bottom-10"
					width="189"
					height="91"
				/>
				<div className="flex flex-col items-center w-full gap-2 text-center">
					<div className="mb-1 text-brand-500">
						<Logo width={137} height={35} />
					</div>
					<p className="text-lg font-bold text-brand-500">{title}</p>

					{quote ? (
						<Quote quoteData={quoteData} />
					) : (
						<p className="text-sm">{description}</p>
					)}
					<div className="relative z-30 hidden mt-2 mb-4 lg:block">
						<Buttons buttonData={buttonData} />
					</div>
				</div>

				{deviceType && (
					<Device
						deviceType={deviceType}
						buttonData={buttonData}
						deviceImage={deviceImage}
					/>
				)}

				<img
					alt=""
					src={DotsVertical}
					width="85"
					height="137"
					className="absolute z-0 -top-20 -left-10 -rotate-12"
				/>
			</div>
		);
	}
};

export default Banner;
