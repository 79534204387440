import React, { useCallback, useEffect } from 'react';
import axios from 'axios';
import { SessionError, SessionUpdateError } from './errors';

const SessionStateContext = React.createContext();
const SessionUpdateContext = React.createContext();

function sessionReducer(state, action) {
	switch (action.type) {
		case 'init': {
			return {
				...state,
				initialised: true,
				session: action.session,
			};
		}
		case 'update_start': {
			return {
				...state,
				updating: true,
			};
		}
		case 'update_success': {
			return {
				...state,
				updating: false,
				session: {
					...state.session,
					...action.session,
				},
			};
		}

		default: {
			throw new SessionError(`Unhandled action type: ${action.type}`);
		}
	}
}

let cancelTokenSource;

function SessionProvider({ children, initialSession = {} }) {
	const [state, dispatch] = React.useReducer(sessionReducer, { session: initialSession });
	const updateSession = useCallback(async (updatedSession) => {
		if (cancelTokenSource) {
			cancelTokenSource.cancel();
		}

		dispatch({
			type: 'update_start',
		});

		cancelTokenSource = axios.CancelToken.source();

		await axios
			.post('/api/session', updatedSession, {
				cancelToken: cancelTokenSource.token,
			})
			.catch((e) => {
				throw new SessionUpdateError(e.message);
			});

		dispatch({
			type: 'update_success',
			session: updatedSession,
		});
	}, []);

	useEffect(() => {
		const retrieveSession = async () => {
			dispatch({
				type: 'init',
				session: (
					await axios.get('/api/session', {
						responseType: 'json',
					})
				).data,
			});
		};
		retrieveSession();
	}, []);

	return (
		<SessionStateContext.Provider value={state}>
			<SessionUpdateContext.Provider value={updateSession}>
				{children}
			</SessionUpdateContext.Provider>
		</SessionStateContext.Provider>
	);
}

function useSessionState() {
	const context = React.useContext(SessionStateContext);

	if (context === undefined) {
		throw new SessionError('useSessionState must be used within a SessionProvider');
	}
	return context;
}

function useSessionUpdate() {
	const context = React.useContext(SessionUpdateContext);

	if (context === undefined) {
		throw new SessionError('useSessionUpdate must be used within a SessionProvider');
	}
	return context;
}

export { SessionProvider, useSessionState, useSessionUpdate, SessionStateContext };
