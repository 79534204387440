import React from 'react';
import { CardNumberElement, CardExpiryElement, CardCvcElement } from '@stripe/react-stripe-js';
import colors from 'bright-components/dist/constants/colors';
import { ErrorMessage } from 'formik';
import HStack from '../../Components/HStack';
import Fieldset from '../../Components/Fieldset';
import Label from '../../Components/Label';

const stripeStyles = {
	base: {
		iconColor: colors.secondary,
		color: colors.greyDarker,
		fontWeight: 500,
		fontFamily: 'Albert Sans, Open Sans, Segoe UI, sans-serif',
		fontSize: '16px',
		fontSmoothing: 'antialiased',
		border: `2px solid ${colors.greyDarker}`,
		backgroundColor: 'white',
		'::placeholder': {
			color: colors.grey,
		},
	},
	invalid: {
		iconColor: colors.error,
		color: colors.error,
	},
};

export default ({ setFieldValue, setFieldError, setErrorMessage, setFieldTouched }) => {
	const handleStripeChange = (_e) => {
		const e = _e.target || _e; // Needed for testing

		setFieldValue(e.elementType, e.complete, false);
		setFieldError(e.elementType, e.error ? e.error.message : null);
		setErrorMessage(undefined);
	};

	const handleStripeBlur = (e) => {
		setFieldTouched(e.elementType, true, false);
	};

	return (
		<Fieldset highlight>
			<div>
				<Label className="mb-2" id="stripe-card">
					Card number
				</Label>
				<CardNumberElement
					options={{
						style: stripeStyles,
					}}
					id="stripe-card"
					onBlur={handleStripeBlur}
					onChange={handleStripeChange}
				/>
				<ErrorMessage component="p" className="mt-2 text-legacy-error" name="cardNumber" />
			</div>
			<HStack>
				<div>
					<Label htmlFor="stripe-expiry" className="mb-2">
						Expiry date
					</Label>
					<div>
						<CardExpiryElement
							options={{
								style: stripeStyles,
							}}
							id="stripe-expiry"
							onBlur={handleStripeBlur}
							onChange={handleStripeChange}
						/>
						<ErrorMessage
							component="p"
							className="mt-2 text-legacy-error"
							name="cardExpiry"
						/>
					</div>
				</div>
				<div>
					<Label htmlFor="stripe-cvc" className="mb-2">
						Security code
					</Label>
					<div>
						<CardCvcElement
							options={{
								style: stripeStyles,
							}}
							id="stripe-cvc"
							onBlur={handleStripeBlur}
							onChange={handleStripeChange}
						/>
						<ErrorMessage
							component="p"
							className="mt-2 text-legacy-error"
							name="cardCvc"
						/>
					</div>
				</div>
			</HStack>
		</Fieldset>
	);
};
