import React, { useEffect, useState } from 'react';

import AutoComplete from 'react-google-autocomplete';
import ValidationAlert from '@brighthr/component-alert';
import Label from '../../Components/Label';
import Legend from '../../Components/Legend';
import HelpText from '../../Components/HelpText';
import HStack from '../../Components/HStack';
import Fieldset from '../../Components/Fieldset';
import updateQuote from '../../Services/updateQuote';
import updateCustomer from '../../Services/updateCustomer';

export default ({
	addressSelected,
	companyName,
	setAddressSelected,
	setEditAddress,
	editAddress,
	handleChange,
	debouncedMaybeSyncSession,
	setFieldValue,
	quoteId,
	customerId,
	values,
	setQuoteReady,
	setSummaryValues,
	summaryValues,
	setTaxApplied,
	localePreference,
}) => {
	const [selectedAddressDetails, setSelectedAddressDetails] = useState({});
	const [selectedStreetAddress, setSelectedStreetAddress] = useState('');
	const [redoQuote, setRedoQuote] = useState(false);
	const [taxErrorMessage, setTaxErrorMessage] = useState();

	const handleAddressSelected = (place) => {
		if (place.address_components === undefined) {
			setAddressSelected(false);
			setEditAddress(false);
			return;
		}

		const selectedPlaceArray = place.address_components.map((component) => {
			const compKey = component.types[0];
			const compValue = component.long_name;
			return [compKey, compValue];
		});
		const selectedPlaceObject = Object.fromEntries(selectedPlaceArray);
		const splitFormattedAddress = place.formatted_address.split(',');
		const formattedAddressFirstPart = splitFormattedAddress.slice(0, -2).join(',');
		const selectedAddressCountryCode = place.address_components.find(
			(component) => component.types[0] === 'country',
		);

		setFieldValue('address1', formattedAddressFirstPart);
		setFieldValue(
			'postTown',
			selectedPlaceObject.postal_town || selectedPlaceObject.locality || '',
		);
		setFieldValue(
			'postCode',
			selectedPlaceObject.postal_code || selectedPlaceObject.postal_code_prefix || ' ',
		);
		// this is the Province in Canada, State in Australia... but for UK it returns England, Scotland, Wales, etc
		setFieldValue('postState', selectedPlaceObject.administrative_area_level_1);
		setFieldValue('countryCode', selectedAddressCountryCode.short_name);

		setSelectedStreetAddress(formattedAddressFirstPart);
		setSelectedAddressDetails(selectedPlaceObject);
		setAddressSelected(true);
		debouncedMaybeSyncSession();
	};

	useEffect(() => {
		if (addressSelected || redoQuote) {
			updateCustomer({
				customerId,
				countryCode: values.countryCode,
				postState: values.postState,
			}).then((customer) => {
				if (
					customer.tax.automatic_tax === 'supported' ||
					customer.tax.automatic_tax === 'not_collecting'
				) {
					// update quote silently if country entered is same as assumed on page load
					// may need a tweak for Canada
					if (values.countryCode !== localePreference) {
						setQuoteReady(false);
					}
					const generateQuote = async () => {
						await updateQuote({
							quoteId,
							customerId,
						}).then((res) => {
							setSummaryValues({
								...summaryValues,
								totalValue: res.amount_total,
								taxValue: res.total_details?.amount_tax,
								taxPercentage: Math.round(
									(res.total_details?.amount_tax /
										(res.amount_total - res.total_details?.amount_tax)) *
										100,
								),
							});
							setTaxApplied(true);
							setQuoteReady(true);
							setTaxErrorMessage(null);
						});
					};
					generateQuote();
				} else if (customer.tax.automatic_tax === 'unrecognized_location') {
					setTaxApplied(false);
					setTaxErrorMessage(
						'Please check your address is correct and complete. We need this so we can accurately calculate your total price.',
					);
				} else {
					// eslint-disable-next-line no-console
					console.log('Customer Tax Status - ', customer.tax.automatic_tax);
					setTaxErrorMessage('An error occured. Please refresh the page and try again.');
				}
				setRedoQuote(false);
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [addressSelected, redoQuote]);

	return (
		<Fieldset>
			<Legend>Billing details:</Legend>
			{addressSelected === true ? (
				<div>
					<h3 className="block mb-3 text-base font-bold leading-6 text-gray-900">
						Address
					</h3>
					<address className="mb-3 text-base not-italic leading-6 text-gray-900">
						{companyName && (
							<>
								{companyName}
								<br />
							</>
						)}
						{selectedStreetAddress}
						<br />
						{selectedAddressDetails.postal_town || selectedAddressDetails.locality}
						<br />
						{values.countryCode !== 'GB' && (
							<>
								{selectedAddressDetails.administrative_area_level_1 || ''}
								<br />
							</>
						)}
						{selectedAddressDetails.postal_code ||
							selectedAddressDetails.postal_code_prefix}
						<br />
						{selectedAddressDetails.country}
					</address>
					<button
						type="button"
						onClick={() => setAddressSelected(false)}
						className="underline text-brand-500"
					>
						Change address
					</button>{' '}
					|{' '}
					<button
						type="button"
						onClick={() => {
							setEditAddress(true);
							setAddressSelected(false);
						}}
						className="underline text-brand-500"
					>
						Edit address
					</button>
				</div>
			) : (
				<div className={editAddress ? 'hidden' : 'block'}>
					<Label className="flex flex-col" id="addresslookup">
						Address
					</Label>
					<AutoComplete
						apiKey={process.env.GATSBY_GOOGLE_LOOKUP}
						options={{
							types: ['geocode', 'establishment'],
							fields: ['address_components', 'formatted_address', 'place_id'],
							strictBounds: false,
							componentRestrictions: { country: ['gb'] }, // restrict by locale for better results -  allowed up to 5
							// componentRestrictions: { country: ['gb', 'ie', 'ca', 'au', 'nz'] }, // restrict by locale for better results -  allowed up to 5
						}}
						onPlaceSelected={(place) => {
							handleAddressSelected(place);
						}}
						className="w-full h-10 px-4 py-0 my-2 border-2 border-gray-400 rounded-sm"
						id="addresslookup"
						placeholder="Search first line of address"
					/>
					<HelpText>We need your business address for tax purposes</HelpText>
				</div>
			)}

			<div className={editAddress ? 'block' : 'hidden'}>
				<h3 className="block mb-3 text-base font-bold leading-6 text-gray-900">Address</h3>
				<div>
					<Label className="flex flex-col mb-2" id="address1">
						Building/Street Address
					</Label>
					<input
						autoComplete="address-line1"
						className="flex-grow-0 w-full px-4 py-2 mb-2 text-gray-800 border-2 border-gray-500 rounded"
						type="text"
						required
						id="address1"
						name="address1"
						onBlur={() => {
							debouncedMaybeSyncSession();
						}}
						value={values.address1}
						onChange={(e) => setFieldValue('address1', e.target.value)}
					/>
				</div>
				<div>
					<Label className="flex flex-col mb-2" id="postTown">
						Town/City
					</Label>
					<input
						autoComplete="postal-code"
						className="flex-grow-0 w-full px-4 py-2 mb-2 text-gray-800 border-2 border-gray-500 rounded"
						type="text"
						required
						id="postTown"
						name="postTown"
						onBlur={() => {
							debouncedMaybeSyncSession();
							setRedoQuote(true);
						}}
						value={values.postTown}
						onChange={(e) => setFieldValue('postTown', e.target.value)}
					/>
				</div>
				{values.countryCode !== 'GB' && (
					<div>
						<Label className="flex flex-col mb-2" id="postState">
							County, State or Province
						</Label>
						<input
							className="flex-grow-0 w-full px-4 py-2 mb-2 text-gray-800 border-2 border-gray-500 rounded"
							type="text"
							id="postState"
							name="postState"
							onBlur={() => {
								debouncedMaybeSyncSession();
								setRedoQuote(true);
							}}
							value={values.postState}
							onChange={(e) => setFieldValue('postState', e.target.value)}
						/>
					</div>
				)}
				<HStack>
					<div>
						<Label className="flex flex-col mb-2" id="postCode">
							Postcode
						</Label>
						<input
							autoComplete="postal-code"
							className="flex-grow-0 w-full px-4 py-2 mb-2 text-gray-800 border-2 border-gray-500 rounded"
							type="text"
							required
							id="postCode"
							name="postCode"
							onBlur={() => {
								debouncedMaybeSyncSession();
							}}
							value={values.postCode}
							onChange={(e) => setFieldValue('postCode', e.target.value)}
						/>
					</div>
					<div>
						<Label className="flex flex-col mb-2" id="countryCode">
							Country
						</Label>
						<select
							autoComplete="country"
							className="flex-grow-0 font-bold w-full px-4 pt-2 pb-[0.65rem] mb-2 text-gray-800 border-2 border-gray-500 rounded"
							required
							id="countryCode"
							name="countryCode"
							onChange={(e) => {
								handleChange(e);
								setFieldValue('countryCode', e.target.value);
								debouncedMaybeSyncSession();
								setRedoQuote(true);
							}}
							value={values.countryCode}
						>
							<option value="">Select your Country</option>
							<option value="GB">United Kingdom</option>
							<option value="AU">Australia</option>
							<option value="CA">Canada</option>
							<option value="IE">Ireland</option>
							<option value="NZ">New Zealand</option>
							<option value="AF">Afghanistan</option>
							<option value="AX">Åland Islands</option>
							<option value="AL">Albania</option>
							<option value="DZ">Algeria</option>
							<option value="AS">American Samoa</option>
							<option value="AD">Andorra</option>
							<option value="AO">Angola</option>
							<option value="AI">Anguilla</option>
							<option value="AQ">Antarctica</option>
							<option value="AG">Antigua and Barbuda</option>
							<option value="AR">Argentina</option>
							<option value="AM">Armenia</option>
							<option value="AW">Aruba</option>
							<option value="AT">Austria</option>
							<option value="AZ">Azerbaijan</option>
							<option value="BS">Bahamas</option>
							<option value="BH">Bahrain</option>
							<option value="BD">Bangladesh</option>
							<option value="BB">Barbados</option>
							<option value="BY">Belarus</option>
							<option value="BE">Belgium</option>
							<option value="BZ">Belize</option>
							<option value="BJ">Benin</option>
							<option value="BM">Bermuda</option>
							<option value="BT">Bhutan</option>
							<option value="BO">Bolivia</option>
							<option value="BA">Bosnia and Herzegovina</option>
							<option value="BW">Botswana</option>
							<option value="BV">Bouvet Island</option>
							<option value="BR">Brazil</option>
							<option value="IO">British Indian Ocean Territory</option>
							<option value="VG">British Virgin Islands</option>
							<option value="BN">Brunei</option>
							<option value="BG">Bulgaria</option>
							<option value="BF">Burkina Faso</option>
							<option value="BI">Burundi</option>
							<option value="KH">Cambodia</option>
							<option value="CM">Cameroon</option>
							<option value="CV">Cape Verde</option>
							<option value="BQ">Caribbean Netherlands</option>
							<option value="KY">Cayman Islands</option>
							<option value="CF">Central African Republic</option>
							<option value="TD">Chad</option>
							<option value="CL">Chile</option>
							<option value="CN">China</option>
							<option value="CX">Christmas Island</option>
							<option value="CC">Cocos Islands</option>
							<option value="CO">Colombia</option>
							<option value="KM">Comoros</option>
							<option value="CG">Congo - Brazzaville</option>
							<option value="CK">Cook Islands</option>
							<option value="CR">Costa Rica</option>
							<option value="CI">Cote D&apos;ivoire</option>
							<option value="HR">Croatia</option>
							<option value="CW">Curaçao</option>
							<option value="CY">Cyprus</option>
							<option value="CZ">Czechia</option>
							<option value="DK">Denmark</option>
							<option value="DJ">Djibouti</option>
							<option value="DM">Dominica</option>
							<option value="DO">Dominican Republic</option>
							<option value="EC">Ecuador</option>
							<option value="EG">Egypt</option>
							<option value="SV">El Salvador</option>
							<option value="GQ">Equatorial Guinea</option>
							<option value="ER">Eritrea</option>
							<option value="EE">Estonia</option>
							<option value="SZ">Eswatini</option>
							<option value="ET">Ethiopia</option>
							<option value="FO">Faroe Islands</option>
							<option value="FJ">Fiji</option>
							<option value="FI">Finland</option>
							<option value="FR">France</option>
							<option value="GF">French Guiana</option>
							<option value="PF">French Polynesia</option>
							<option value="TF">French Southern Territories</option>
							<option value="GA">Gabon</option>
							<option value="GM">Gambia</option>
							<option value="GE">Georgia</option>
							<option value="DE">Germany</option>
							<option value="GH">Ghana</option>
							<option value="GI">Gibraltar</option>
							<option value="GR">Greece</option>
							<option value="GL">Greenland</option>
							<option value="GD">Grenada</option>
							<option value="GP">Guadeloupe</option>
							<option value="GU">Guam</option>
							<option value="GT">Guatemala</option>
							<option value="GG">Guernsey</option>
							<option value="GN">Guinea</option>
							<option value="GY">Guyana</option>
							<option value="HT">Haiti</option>
							<option value="HM">Heard Island and McDonald Islands</option>
							<option value="VA">Holy See</option>
							<option value="HN">Honduras</option>
							<option value="HK">Hong Kong</option>
							<option value="HU">Hungary</option>
							<option value="IS">Iceland</option>
							<option value="ID">Indonesia</option>
							<option value="IQ">Iraq</option>
							<option value="IM">Isle of Man</option>
							<option value="IL">Israel</option>
							<option value="IT">Italy</option>
							<option value="JM">Jamaica</option>
							<option value="JP">Japan</option>
							<option value="JE">Jersey</option>
							<option value="JO">Jordan</option>
							<option value="KZ">Kazakhstan</option>
							<option value="KE">Kenya</option>
							<option value="KI">Kiribati</option>
							<option value="XK">Kosovo</option>
							<option value="KW">Kuwait</option>
							<option value="KG">Kyrgyzstan</option>
							<option value="LA">Laos</option>
							<option value="LV">Latvia</option>
							<option value="LB">Lebanon</option>
							<option value="LS">Lesotho</option>
							<option value="LR">Liberia</option>
							<option value="LY">Libya</option>
							<option value="LI">Liechtenstein</option>
							<option value="LT">Lithuania</option>
							<option value="LU">Luxembourg</option>
							<option value="MO">Macau</option>
							<option value="MG">Madagascar</option>
							<option value="MW">Malawi</option>
							<option value="MY">Malaysia</option>
							<option value="MV">Maldives</option>
							<option value="ML">Mali</option>
							<option value="MT">Malta</option>
							<option value="MH">Marshall Islands</option>
							<option value="MQ">Martinique</option>
							<option value="MR">Mauritania</option>
							<option value="MU">Mauritius</option>
							<option value="YT">Mayotte</option>
							<option value="MX">Mexico</option>
							<option value="FM">Micronesia</option>
							<option value="MD">Moldova</option>
							<option value="MC">Monaco</option>
							<option value="MN">Mongolia</option>
							<option value="ME">Montenegro</option>
							<option value="MS">Montserrat</option>
							<option value="MA">Morocco</option>
							<option value="MZ">Mozambique</option>
							<option value="MM">Myanmar</option>
							<option value="NA">Namibia</option>
							<option value="NR">Nauru</option>
							<option value="NP">Nepal</option>
							<option value="NL">Netherlands</option>
							<option value="NC">New Caledonia</option>
							<option value="NI">Nicaragua</option>
							<option value="NE">Niger</option>
							<option value="NG">Nigeria</option>
							<option value="NU">Niue</option>
							<option value="NF">Norfolk Island</option>
							<option value="MK">North Macedonia</option>
							<option value="MP">Northern Mariana Islands</option>
							<option value="NO">Norway</option>
							<option value="OM">Oman</option>
							<option value="PK">Pakistan</option>
							<option value="PW">Palau</option>
							<option value="PS">Palestine</option>
							<option value="PA">Panama</option>
							<option value="PG">Papua New Guinea</option>
							<option value="PY">Paraguay</option>
							<option value="PE">Peru</option>
							<option value="PH">Philippines</option>
							<option value="PN">Pitcairn</option>
							<option value="PL">Poland</option>
							<option value="PT">Portugal</option>
							<option value="PR">Puerto Rico</option>
							<option value="QA">Qatar</option>
							<option value="RE">Réunion</option>
							<option value="RO">Romania</option>
							<option value="RU">Russia</option>
							<option value="RW">Rwanda</option>
							<option value="BL">Saint Barthélemy</option>
							<option value="SH">Saint Helena, Ascension and Tristan da Cunha</option>
							<option value="KN">Saint Kitts And Nevis</option>
							<option value="LC">Saint Lucia</option>
							<option value="PM">Saint Pierre and Miquelon</option>
							<option value="VC">Saint Vincent and The Grenadines</option>
							<option value="WS">Samoa</option>
							<option value="SM">San Marino</option>
							<option value="ST">Sao Tome and Principe</option>
							<option value="SA">Saudi Arabia</option>
							<option value="SN">Senegal</option>
							<option value="RS">Serbia</option>
							<option value="SC">Seychelles</option>
							<option value="SL">Sierra Leone</option>
							<option value="SG">Singapore</option>
							<option value="SX">Sint Maarten</option>
							<option value="SK">Slovakia</option>
							<option value="SI">Slovenia</option>
							<option value="SB">Solomon Islands</option>
							<option value="SO">Somalia</option>
							<option value="ZA">South Africa</option>
							<option value="GS">South Georgia and the South Sandwich Islands</option>
							<option value="KR">South Korea</option>
							<option value="SS">South Sudan</option>
							<option value="ES">Spain</option>
							<option value="LK">Sri Lanka</option>
							<option value="MF">St. Martin</option>
							<option value="SD">Sudan</option>
							<option value="SR">Suriname</option>
							<option value="SJ">Svalbard and Jan Mayen</option>
							<option value="SE">Sweden</option>
							<option value="CH">Switzerland</option>
							<option value="TW">Taiwan</option>
							<option value="TJ">Tajikistan</option>
							<option value="TZ">Tanzania</option>
							<option value="TH">Thailand</option>
							<option value="FK">The Falkland Islands</option>
							<option value="GW">The Republic of Guinea-Bissau</option>
							<option value="TL">Timor-Leste</option>
							<option value="TG">Togo</option>
							<option value="TK">Tokelau</option>
							<option value="TO">Tonga</option>
							<option value="TT">Trinidad and Tobago</option>
							<option value="TN">Tunisia</option>
							<option value="TR">Turkey</option>
							<option value="TM">Turkmenistan</option>
							<option value="TC">Turks And Caicos Islands</option>
							<option value="TV">Tuvalu</option>
							<option value="VI">U.S. Virgin Islands</option>
							<option value="UG">Uganda</option>
							<option value="UA">Ukraine</option>
							<option value="AE">United Arab Emirates</option>
							<option value="US">United States</option>
							<option value="UM">United States Minor Outlying Islands</option>
							<option value="UY">Uruguay</option>
							<option value="UZ">Uzbekistan</option>
							<option value="VU">Vanuatu</option>
							<option value="VE">Venezuela</option>
							<option value="VN">Vietnam</option>
							<option value="WF">Wallis and Futuna</option>
							<option value="EH">Western Sahara</option>
							<option value="YE">Yemen</option>
							<option value="ZM">Zambia</option>
							<option value="ZW">Zimbabwe</option>
						</select>
					</div>
				</HStack>
			</div>
			{!!taxErrorMessage && (
				<ValidationAlert border icon="error" size="xs" type="warning">
					{taxErrorMessage || ''}
				</ValidationAlert>
			)}
		</Fieldset>
	);
};
