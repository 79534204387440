import React from 'react';
import TestimonialBlob from '../../../images/partners/testimonialBlob.svg';
import TestimonialBlobTablet from '../../../images/partners/testimonialBlobTablet.svg';
import TestimonialBlobMobile from '../../../images/partners/testimonialBlobMobile.svg';
import QuoteIcon from '../../../images/partners/Quote_Icon.png';
import CopyBlock from '../../CopyBlock';

const CustomerReview = ({ heading, subHeading, customer }) => (
	<div className="container p-6 mx-auto">
		<div className="relative flex flex-col items-center">
			<picture className="absolute top-0 z-0 -translate-y-8 sm:-translate-y-12 md:-translate-y-24 lg:translate-y-0 xl:-translate-y-4">
				<source
					srcSet={TestimonialBlob}
					type="image/svg+xml"
					media="(min-width: 992px)"
					width="1059"
					height="231"
				/>
				<source
					srcSet={TestimonialBlobTablet}
					type="image/svg+xml"
					media="(min-width: 550px)"
					width="583"
					height="464"
				/>
				<img
					src={TestimonialBlobMobile}
					type="image/svg+xml"
					alt="Blob"
					width="350"
					height="464"
				/>
			</picture>
			<div className="relative z-10 flex flex-col items-center px-4 space-y-3 text-center sm:px-32 md:px-40 lg:px-32 xl:px-48 xl:max-w-screen-xl">
				<img src={QuoteIcon} alt="Quotation Marks" width="37" height="37" />
				<h3 className="text-3xl font-bold">{heading}</h3>
				<CopyBlock>{subHeading}</CopyBlock>
				<p className="text-brand-400">Customer Review - {customer}</p>
			</div>
		</div>
	</div>
);

export default CustomerReview;
