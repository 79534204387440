import React, { useEffect, useState } from 'react';

import LinkButton from '@brighthr/component-linkbutton';
import Layout from '../layouts/Default';
import SEO from '../components/SEO';
import Stars from '../icons/Stars.svg';
import StarsLg from '../icons/StarsLg.svg';
import Clouds from '../icons/Clouds.svg';
import CloudsLg from '../icons/CloudsLg.svg';

const PageNotFound = () => {
	const [isMount, setMount] = useState(false);

	useEffect(() => {
		setMount(true);
	}, []);

	if (!isMount) {
		return null;
	}
	return (
		<Layout fullWidth>
			<SEO title="Uh-oh! Something's gone wrong" path="/404" />

			<div className="relative block overflow-hidden bg-gradient-to-b from-brand-900 to-brand-500">
				<Stars className="absolute top-0 -inset-x-12 md:left-1/2 md:right-0 md:-translate-x-1/2 md:w-full lg:hidden" />
				<StarsLg className="absolute top-0 hidden -inset-x-12 lg:block xl:left-1/2 xl:-translate-x-1/2 xl:w-full xl:right-0" />
				<Clouds className="absolute top-16 -inset-x-20 sm:left-1/2 sm:right-0 sm:-translate-x-1/2 sm:w-full lg:hidden" />
				<CloudsLg className="absolute hidden bottom-16 -inset-x-20 lg:block xl:left-0 xl:right-0 xl:w-full" />
				<span className="absolute z-10 block w-full tracking-tight font-bold text-center top-16 text-white text-[48vw] opacity-10 leading-none lg:text-[33vw] xl:text-[30rem]">
					404
				</span>
				<div className="relative z-20 flex flex-col w-full px-5 pb-64 text-center pt-36 flex-nowrap lg:pt-56 lg:pb-40 lg:max-w-3xl lg:mx-auto">
					<div className="relative w-full mx-auto mb-10 text-white lg:w-auto lg:mb-12">
						<h1 className="pb-4 text-3xl font-bold text-white md:pb-2">
							Uh-oh! Something&rsquo;s gone wrong
						</h1>
						<p className="mb-4 text-xl leading-7">
							The page you&rsquo;re looking for no longer exists (our bad!).
						</p>
						<p className="text-xl leading-7">
							But on the bright side, our easy-to-navigate homepage can take you to
							wherever you need to go…
						</p>
					</div>

					<div className="relative z-20">
						<div className="m-auto w-fit">
							<LinkButton href="/" text="Take me home" size="xl" />
						</div>
					</div>
				</div>
			</div>
		</Layout>
	);
};

export default PageNotFound;
