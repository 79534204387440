import React from 'react';
import { buildLink, LOCALE_PATHS } from '../../../utils/locale';
import { useVar } from '../../Var';
import Logo from '../../Logo';

const MiniHeader = ({ locale }) => {
	const salesNumber = useVar('salesNumber', LOCALE_PATHS[locale]);

	return (
		<>
			<header className="relative flex flex-row items-center justify-between p-4 mx-auto max-w-7xl md:p-6 text-brand-500">
				<a href={buildLink(locale, '/')}>
					<Logo />
				</a>
				<a
					className="flex items-center justify-center font-bold whitespace-nowrap md:text-xl"
					href={`tel:${salesNumber}`}
				>
					<svg
						width="16"
						height="15"
						viewBox="0 0 16 15"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
						className="hidden mr-2 align-text-top md:inline-block"
					>
						<path
							d="M9.096 3.358A2.917 2.917 0 0111.4 5.662M9.096 1.025a5.25 5.25 0 014.637 4.631m-.583 4.654v1.75a1.167 1.167 0 01-1.272 1.166 11.542 11.542 0 01-5.033-1.79 11.372 11.372 0 01-3.5-3.5 11.542 11.542 0 01-1.79-5.056 1.166 1.166 0 011.16-1.272h1.75a1.166 1.166 0 011.167 1.003c.073.56.21 1.11.408 1.64a1.166 1.166 0 01-.263 1.23l-.74.74a9.332 9.332 0 003.5 3.5l.74-.74a1.166 1.166 0 011.23-.263c.53.197 1.08.334 1.64.408a1.166 1.166 0 011.003 1.184z"
							stroke="currentColor"
							strokeWidth="1.5"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
					</svg>
					{salesNumber}
				</a>
			</header>
		</>
	);
};

export default MiniHeader;
