import React from 'react';

function MultiParagraphBlock({ content, className }) {
	return (
		<div
			// eslint-disable-next-line react/no-danger
			dangerouslySetInnerHTML={{ __html: content }}
			className={`MultiParagraphBlock ${className}`}
		/>
	);
}

export default MultiParagraphBlock;
