const createCustomer = async ({ countryCode, postState }) => {
	const customer = await fetch('/api/stripe/customer', {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			countryCode,
			postState,
		}),
	}).then((res) => res.json());

	return customer;
};

export default createCustomer;
