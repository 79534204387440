import React, { useRef, useEffect } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { graphql } from 'gatsby';
import cn from 'classnames';
import { useLocation } from '@reach/router';
import DefaultLayout from '../layouts/Default';

import { buildLink, SUPPORTED_LOCALES } from '../utils/locale';

import FilterBar, { FilterButton } from '../components/Support/FilterBar';
import HrefLang from '../components/HrefLang';
import Breadcrumbs, { Breadcrumb } from '../components/Breadcrumbs';

import GuideCard from '../components/Support/GuideCard';
import Pagination from '../components/Pagination';

import useFuse from '../components/useFuse';
import SearchBar from '../components/SearchBar';
import scrollDirection from '../utils/scrollDirection';
import SEO from '../components/SEO';
import { useLocale } from '../context/Locale';
import usePaginatedSearch from '../hooks/usePaginatedSearch';
import usePagination from '../hooks/usePagination';

const fuseOptions = {
	keys: [
		{
			name: 'title',
			weight: 0.7,
		},
		{
			name: 'topic.name',
			weight: 0.3,
		},
	],
};

const SupportGuides = ({ data, pageContext }) => {
	// NB We want to remove this filter and ensure each category of video has its own static page
	const { filter } = usePaginatedSearch({
		search: () => {},
		searchInputRef: null,
	});
	const guides = data.allDatoCmsSupportGuide.nodes.filter(
		(video) => !filter || filter === video.topic.name,
	);
	const listRef = useRef();
	const searchInputRef = useRef();
	const scroll = scrollDirection();
	const { result, search } = useFuse(guides, fuseOptions);
	const location = useLocation();

	useEffect(() => {
		if (localStorage.getItem('storedSearchTerm')) {
			search(localStorage.getItem('storedSearchTerm'));
			localStorage.removeItem('storedSearchTerm');
		}
	}, [search]);

	const PER_PAGE = 9;

	const { page, pageNumbers, currentPageResults } = usePagination(result.length, PER_PAGE);
	const { searchInput, showSearchResults, clearSearch, handleKeyDown, delayedURLUpdate } =
		usePaginatedSearch({
			search,
			searchInputRef,
		});

	const locale = useLocale();

	const autoSchema = {
		itemListElement: currentPageResults(result).map((guide, index) => ({
			'@type': 'ListItem',
			position: index + 1,
			item: {
				'@type': 'Course',
				url: guide.link,
				name: guide.title,
				description: guide.topic.name,
				provider: {
					'@type': 'Organization',
					name: data.site.siteMetadata.title,
					url: data.site.siteMetadata.siteUrl,
				},
				hasCourseInstance: {
					'@type': 'CourseInstance',
					courseMode: 'online',
					courseWorkload: 'PT30M',
				},
				offers: {
					'@type': 'Offer',
					category: 'Information guide',
					areaServed: 'Guides',
				},
			},
		})),
	};

	return (
		<DefaultLayout fullWidth>
			<HrefLang locales={SUPPORTED_LOCALES} slug="help/guides" />
			<SEO
				path={location.pathname}
				locale={locale}
				title="Success Centre"
				description="Support guides"
				schemaType="ItemList"
				autoSchema={autoSchema}
			/>
			<section className="flex flex-row max-w-screen-xl mx-auto bg-right-top bg-no-repeat bg-contain">
				<div className="w-full px-6 py-8 grow md:pr-12">
					<Breadcrumbs className="hidden mb-4 md:flex">
						<Breadcrumb
							href={buildLink(
								pageContext.locale,
								`help?page=1${searchInput ? `&search=${searchInput}` : ''}`,
							)}
							position={0}
						>
							Support
						</Breadcrumb>
						<Breadcrumb position={1}>Guides</Breadcrumb>
					</Breadcrumbs>
					<h1 className="mb-3 text-2xl font-bold lg:text-4xl md:mt-12">Guides</h1>
					<p className="lg:text-xl">{data.datoCmsSupport.guidesDescription}</p>
				</div>
				<StaticImage
					data-cy="guide-faqs-hero-image"
					src="../images/support/guides-hero.png"
					alt="faqs banner"
					className="hidden w-4/12 mt-8 ml-auto shrink-0 md:block"
					placeholder="eager"
				/>
			</section>
			<div className="px-6 mx-auto mt-8 max-w-7xl md:mt-14">
				<SearchBar
					listRef={listRef}
					className="mb-8"
					value={searchInput}
					onChange={(e) => {
						showSearchResults(e, false);
						delayedURLUpdate(e);
					}}
					placeholder="What do you need help with?"
					ref={searchInputRef}
					clearSearch={clearSearch}
					scroll={scroll}
					onKeyDown={handleKeyDown}
				/>
				<div
					className={cn(
						'rounded bg-brand-100',
						searchInput.length >= 3 ? 'block p-8 pt-6 mb-8' : 'hidden',
					)}
					ref={listRef}
				>
					{searchInput.length >= 3 && (
						<>
							<h3 className="mb-6">
								Search results <span className="font-bold">({result.length})</span>
							</h3>
							<ul className="grid gap-6 lg:grid-cols-3 md:grid-cols-2">
								{currentPageResults(result).map((guide) => (
									<GuideCard key={guide.id} {...guide} />
								))}
							</ul>
							{result.length > PER_PAGE && (
								<Pagination
									page={page}
									searchInput={searchInput}
									pageNumbers={pageNumbers}
									className="mt-6 mb-0"
									filter={filter}
								/>
							)}
						</>
					)}
				</div>
				{searchInput.length < 3 && (
					<>
						<h2 className="mb-8 text-xl font-bold leading-7 text-center mt-14">
							Guides by product
						</h2>

						<FilterBar filter={filter} searchInput={searchInput}>
							{data.allDatoCmsSupportTopic.nodes.map(({ name, icon }) => (
								<FilterButton
									active={filter === name}
									href={`?page=1${`&filter=${name}`}${
										searchInput ? `&search=${searchInput}` : ''
									}`}
								>
									<img
										src={icon.fixed.base64}
										width="16"
										height="16"
										className="mr-2"
										aria-hidden
										alt=""
									/>
									{name}
								</FilterButton>
							))}
						</FilterBar>

						<div className="mb-8 lg:mb-20">
							{guides.length > 0 ? (
								<ul className="grid gap-6 xl:gap-8 lg:grid-cols-3 md:grid-cols-2">
									{currentPageResults(guides).map((props) => (
										<GuideCard key={props.originalId} {...props} />
									))}
								</ul>
							) : (
								<p>
									Oops! We’re working on adding new content here, check back soon
									for updates.
								</p>
							)}
							{guides.length > PER_PAGE && (
								<Pagination
									page={page}
									searchInput={searchInput}
									pageNumbers={pageNumbers}
									className="mt-8 mb-0"
									filter={filter}
								/>
							)}
						</div>
					</>
				)}
			</div>
		</DefaultLayout>
	);
};

export default SupportGuides;

export const query = graphql`
	query guideSupportPage($locale: String!) {
		site {
			siteMetadata {
				title
				siteUrl
			}
		}
		allDatoCmsSupportTopic(
			filter: { locale: { eq: $locale } }
			sort: { fields: name, order: ASC }
		) {
			nodes {
				originalId
				name
				icon {
					fixed {
						base64
					}
				}
			}
		}
		allDatoCmsSupportGuide(
			filter: { locale: { eq: $locale }, title: { ne: null } }
			sort: { fields: title, order: ASC }
		) {
			nodes {
				id
				originalId
				title
				topic {
					originalId
					name
				}
				link
			}
		}
		datoCmsSupport(locale: { eq: $locale }) {
			guidesDescription
			seoMetaTags {
				...GatsbyDatoCmsSeoMetaTags
			}
		}
	}
`;
