import React from 'react';
import { graphql } from 'gatsby';
import { format } from 'date-fns';
import CopyBlock from '../CopyBlock';

const TimelineEntry = ({ timelineHeadline, timelineDate, timelineContentNode }) => (
	<div className="flex timeline-entry">
		<div className="bg-white w-full  ">
			<h1 className="text-brand-500 font-bold text-base">
				{format(new Date(timelineDate), 'MMM yyyy')}
			</h1>
			<h1 className="text-lg font-bold py-1">{timelineHeadline}</h1>
			<CopyBlock>{timelineContentNode.childMdx.body}</CopyBlock>
		</div>
	</div>
);

export const query = graphql`
	fragment TimelineEntry on DatoCmsTimeline {
		timelineHeadline
		timelineDate
		timelineType
		showInUnitedKingdom
		showInCanada
		showInIreland
		showInAustralia
		showInNewZealand
		timelineContentNode {
			childMdx {
				body
			}
		}
	}
`;

export default TimelineEntry;
