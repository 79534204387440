/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import cn from 'classnames';
import Cross from '../../icons/Cross.svg';

const Modal = ({ show, onClose, children, theme = 'pink' }) => (
	<div
		className={cn(
			'fixed top-0 bottom-0 left-0 right-0 flex flex-col items-center overflow-auto z-100 bg-gray-overlay',
			{
				hidden: !show,
				block: show,
			},
		)}
		onClick={onClose}
	>
		<div className="relative m-auto" onClick={(e) => e.stopPropagation()}>
			{children}
			<button type="button" className="absolute top-5 right-5" onClick={onClose}>
				<Cross
					className={cn('w-8 h-8', {
						'text-secondary-300': theme === 'pink',
						'text-gray-800': theme === 'dark',
					})}
				/>
			</button>
		</div>
	</div>
);

export default Modal;
/* eslint-enable jsx-a11y/click-events-have-key-events */
/* eslint-enable jsx-a11y/no-static-element-interactions */
