import React from 'react';
import CopyBlock from '../../CopyBlock';

const VectorWithText = ({ image, alt, heading, text }) => (
	<>
		<div className="flex items-center justify-items-center">
			<img src={image} alt={alt} className="w-full" />
		</div>
		<h3 className="my-8 text-lg font-bold md:text-xl text-brand-500">{heading}</h3>
		<div className="pb-4">
			<CopyBlock>{text}</CopyBlock>
		</div>
	</>
);

export default VectorWithText;
