import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

const AppDownload = () => (
	<div className="lg:col-start-1 lg:row-start-2 lg:self-end">
		<h3 className="mb-2 text-xl font-bold">Download our mobile app</h3>
		<div className="flex flex-wrap sm:flex-nowrap">
			<a
				href="https://apps.apple.com/gb/developer/brighthr/id1031619348"
				className="block mb-6 mr-2"
				data-footer-link="appstore"
			>
				<StaticImage
					src="../../../images/vectors/AppStoreFull.svg"
					alt="App Store Icon"
					className="w-32 xs:w-40 lg:w-auto"
				/>
			</a>

			<a
				href="https://play.google.com/store/apps/developer?id=BrightHR"
				className="block mb-6"
				data-footer-link="playstore"
			>
				<StaticImage
					src="../../../images/vectors/PlayStoreFull.svg"
					alt="Google Play Store Icon"
					className="w-32 xs:w-40 lg:w-auto"
				/>
			</a>
		</div>
	</div>
);

export default AppDownload;
