import Cookies from 'js-cookie';
import { defaultLeadSource } from '../../../utils/leadSource';

const UTMMap = {
	msclkid: 'MSCLKID__c',
	gclid: 'GCLID__c',
	fbclid: 'FBCLID__c',
	utm_campaign: 'utmcampaign',
	utm_campaign_last: 'UTM_Campaign_LC__c',
	utm_campaign_first: 'UTM_Campaign_FT__c',
	utm_content: 'utmcontent',
	utm_content_last: 'UTM_Content_LC__c',
	utm_content_first: 'UTM_Content_FT__c',
	utm_medium: 'utmmedium',
	utm_medium_last: 'UTM_Medium_LC__c',
	utm_medium_first: 'UTM_Medium_FT__c',
	utm_source: 'utmsource',
	utm_source_last: 'UTM_Source_LC__c',
	utm_source_first: 'UTM_Source_FT__c',
	utm_term: 'utmterm',
	utm_term_last: 'UTM_Term_LC__c',
	utm_term_first: 'UTM_Term_FT__c',
};

const getUTMValues = (cookies) =>
	Object.keys(UTMMap).reduce((utms, utmKey) => {
		if (cookies[utmKey] === undefined) {
			const switchFirstAndOriginal = utmKey.replace('_first', '');
			// eslint-disable-next-line no-param-reassign
			utms[UTMMap[utmKey]] = cookies[switchFirstAndOriginal];
		} else {
			// eslint-disable-next-line no-param-reassign
			utms[UTMMap[utmKey]] = cookies[utmKey];
		}

		return utms;
	}, {});

export default (data, formId = 2796, location) => {
	let utmValues = {};

	if (!location || location.pathname !== '/demo-survey/') {
		utmValues = getUTMValues(Cookies.get());
	}

	return new Promise((res, rej) => {
		if (!window.MktoForms2) return rej(new Error('Marketo forms is not loaded'));

		const errorTimer = setTimeout(() => rej(), 5000);

		const form = window.MktoForms2.getForm(formId);
		form.addHiddenFields({
			...data.data,
			...utmValues,
			LeadSource:
				data.data.LeadSource === 'None' ? '' : data.data.LeadSource || defaultLeadSource,
		});

		// Prevent Marketo redirect
		form.onSuccess(() => {
			clearTimeout(errorTimer);
			res();

			return false;
		});
		form.submit();
	});
};
