import { graphql } from 'gatsby';
import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { useLocation } from '@reach/router';

import LinkButton from '@brighthr/component-linkbutton';
import DefaultLayout from '../layouts/Default';
import DatoSEO from '../components/DatoSEO';
import Breadcrumbs, { Breadcrumb } from '../components/Breadcrumbs';
import DownloadCards from '../components/DownloadCards';
import HrefLang from '../components/HrefLang';
import Container from '../components/Container';
import { buildLink, LOCALE_PATHS } from '../utils/locale';
import CopyBlock from '../components/CopyBlock';
import ResourceCards from '../components/ResourceCards';
import { listToTree } from '../utils/datoCMS';

const ResourceCentre = ({ data, pageContext }) => {
	const { title, bodyNode, _allSeoLocales, topCards, seoMetaTags } = data.datoCmsResourceCentre;
	const { nodes: downloads } = data.downloads;
	const { nodes: blogs } = data.blogs;
	const { nodes: articles } = data.articles;
	const location = useLocation();
	const locale = LOCALE_PATHS[pageContext.locale];
	const [tree] = listToTree(articles);

	return (
		<DefaultLayout fullWidth>
			<HrefLang locales={_allSeoLocales} slug="resource-centre" />
			<DatoSEO path={location.pathname} meta={seoMetaTags} lang={pageContext.locale} />

			<section className="relative pb-20 overflow-y-hidden xl:pb-40 bg-gradient-to-tl lg:bg-gradient-to-l from-brand-300 bg-wave-blue to-white">
				<Container className="relative z-10">
					<Breadcrumbs>
						<Breadcrumb href={buildLink(locale, '/')} position={0}>
							Home
						</Breadcrumb>
						<Breadcrumb position={1}>Resource Centre</Breadcrumb>
					</Breadcrumbs>

					<h1 className="my-4 text-2xl font-bold tracking-tight">{title}</h1>

					{bodyNode && <CopyBlock>{bodyNode.childMdx.body}</CopyBlock>}

					<div className="flex gap-4 mt-12 overflow-x-scroll xl:gap-8 md:overflow-x-visible">
						{topCards.map((card) => (
							<div className="relative flex flex-col items-start flex-none w-9/12 p-4 overflow-hidden text-white rounded-lg shadow-lg md:flex-1 xl:p-8 top-card group">
								<div className="absolute top-0 left-0 object-cover w-full h-full transition duration-300 ease-out group-hover:filter group-hover:blur-sm">
									<GatsbyImage
										image={card.image.gatsbyImageData}
										loading="eager"
										objectFit="cover"
										alt={card.image.alt || card.title}
										className="w-full h-full"
									/>
								</div>
								<h2 className="z-20 mb-4 text-2xl font-bold leading-none">
									{card.title}
								</h2>
								<div className="z-20 mb-8">
									<CopyBlock>{card.bodyNode.childMdx.body}</CopyBlock>
								</div>
								<div className="z-20 inline-block mt-auto w-fit">
									<LinkButton
										color="white"
										href={buildLink(locale, card.link)}
										text={card.linkText}
										size="lg"
									/>
								</div>
							</div>
						))}
					</div>
				</Container>
			</section>

			<Container>
				{blogs.length > 0 && (
					<section className="my-8 xl:mt-0">
						<div className="flex items-center">
							<h2 className="mb-4 text-2xl font-bold">Latest Blogs</h2>
							<a
								href={buildLink(locale, 'blog')}
								className="ml-auto transition duration-300 ease-in-out text-brand-500 hover:text-brand-400"
							>
								<span className="text-2xl">&rsaquo;</span> See all
							</a>
						</div>
						<ResourceCards
							className="flex flex-row gap-4 overflow-x-scroll md:gap-8 md:overflow-x-visible md:ml-auto"
							cardClassName="py-4 flex-none w-9/12 md:flex-1 md:w-1/3"
							resources={blogs}
							locale={locale}
							slugBase="blog"
						/>
					</section>
				)}
				{articles.length > 0 && (
					<section className="my-8">
						<div className="flex items-center">
							<h2 className="mb-4 text-2xl font-bold">Latest Articles</h2>
							<a
								href={buildLink(locale, 'articles')}
								className="ml-auto transition duration-300 ease-in-out text-brand-500 hover:text-brand-400"
							>
								<span className="text-2xl">&rsaquo;</span> See all
							</a>
						</div>

						<ResourceCards
							className="flex flex-row gap-4 overflow-x-scroll md:gap-8 md:overflow-x-visible md:ml-auto"
							cardClassName="py-4 flex-none w-9/12 md:flex-1 md:w-1/3"
							resources={articles.slice(0, 3)}
							locale={locale}
							slugBase="articles"
							articleTree={tree}
						/>
					</section>
				)}
				{downloads.length > 0 && (
					<section className="my-8">
						<div className="flex items-center">
							<h2 className="mb-4 text-2xl font-bold">Latest Downloads</h2>
							<a
								href={buildLink(locale, 'download-centre')}
								className="ml-auto transition duration-300 ease-in-out text-brand-500 hover:text-brand-400"
							>
								<span className="text-2xl">&rsaquo;</span> See all
							</a>
						</div>
						<DownloadCards
							className="flex flex-row gap-4 overflow-x-scroll md:gap-0 md:overflow-x-visible md:flex-wrap md:ml-auto"
							cardClassName="min-w-[300px] md:w-1/2 md:flex-initial px-0 md:px-4 md:odd:pl-0 md:even:pr-0"
							downloads={downloads}
							locale={locale}
						/>
					</section>
				)}
			</Container>
		</DefaultLayout>
	);
};

export default ResourceCentre;

export const query = graphql`
	query ResourceCentre($locale: String!) {
		datoCmsResourceCentre(locale: { eq: $locale }) {
			title
			bodyNode {
				childMdx {
					body
				}
			}
			_allSeoLocales {
				locale
			}
			seoMetaTags {
				...GatsbyDatoCmsSeoMetaTags
			}
			topCards {
				title
				bodyNode {
					childMdx {
						body
					}
				}
				link
				linkText
				image {
					gatsbyImageData(width: 284, imgixParams: { auto: "compress" })
				}
			}
		}
		downloads: allDatoCmsDownload(
			filter: { locale: { eq: $locale }, slug: { ne: null } }
			sort: { order: DESC, fields: meta___createdAt }
			limit: 4
		) {
			nodes {
				bodyNode {
					childMdx {
						excerpt(pruneLength: 140)
					}
				}
				title
				breadcrumbText
				id
				slug
				imageOverlayText
				heroImage {
					gatsbyImageData(
						width: 180
						imgixParams: { auto: "compress", ar: "12:17", fit: "crop" }
					)
				}
				document {
					size
				}
				category {
					id
					slug
					title
					locale
				}
			}
		}
		blogs: allDatoCmsBlogPost(
			filter: { locale: { eq: $locale }, slug: { ne: null } }
			sort: { order: DESC, fields: meta___firstPublishedAt }
			limit: 3
		) {
			nodes {
				title
				id
				slug
				locale
				image {
					gatsbyImageData(
						width: 400
						imgixParams: { auto: "compress", ar: "37:25", fit: "crop" }
					)
				}
				meta {
					publishedAt
				}
				category {
					name
					slug
				}
				contentNode {
					childMdx {
						timeToRead
					}
				}
			}
		}
		articles: allDatoCmsArticle(
			filter: { locale: { eq: $locale }, slug: { ne: null } }
			sort: { order: DESC, fields: meta___firstPublishedAt }
		) {
			nodes {
				title
				id
				slug
				locale
				image {
					fixed(
						width: 400
						imgixParams: { auto: "compress", q: 60, ar: "37:25", fit: "crop" }
					) {
						srcSet
						src
					}
				}
				meta {
					publishedAt
				}
				bodyNode {
					childMdx {
						timeToRead
					}
				}
				parent: treeParent {
					id
					title
					slug
				}
			}
		}
	}
`;
