import React, { useEffect, useState, useCallback, useRef, useMemo } from 'react';
import { useSwipeable } from 'react-swipeable';
import cn from 'classnames';
import Container from '../../Container';
import Underline from '../../../icons/Underline.svg';
import ProgressBar from './progressBar';
import Blob from '../../../images/blobs/wide-blob/wide-light-blue.svg';
import useTimer from '../../../utils/Timer';
import TabContent from './tabContent';

const TabbedCarousel = ({ slides, carouselHeader }) => {
	const [currentSlide, setCurrentSlide] = useState(0);
	const [slideDescriptionWidth, setSlideDescriptionWidth] = useState(0);
	const [slideImageHeight, setSlideImageHeight] = useState(0);
	const [isCarouselVisible, setIsCarouselVisible] = useState(false);
	const [count, setCount] = useState(true);
	const [isBarOne, setIsBarOne] = useState(true);
	const [key, setKey] = useState(1);
	const slideDescriptionRef = useRef();
	const slideImageRef = useRef();
	const tabsRef = useRef();
	const { pause, running, seconds, start, stop, reset, setSeconds } = useTimer();
	const slideCount = slides.length - 1;

	const useWindowSize = () => {
		const [windowSize, setWindowSize] = useState({
			width: undefined,
			height: undefined,
		});
		useEffect(() => {
			const handleResize = () => {
				setWindowSize({
					width: window.innerWidth,
					height: window.innerHeight,
				});
			};
			window.addEventListener('resize', handleResize);
			handleResize();
			return () => window.removeEventListener('resize', handleResize);
		}, []);
		return windowSize;
	};

	const size = useWindowSize();

	const resetAnimation = () => {
		setKey(key + 1);
		if (isBarOne) {
			setIsBarOne(false);
		} else {
			setIsBarOne(true);
		}
	};

	// ref.current.clientHeight and clientWidth do not measure borderWidth or margin
	// To change slide margin or border use the objects below

	const slideDescription = useMemo(
		() => ({
			border: 1,
			marginRight: 40,
		}),
		[],
	);

	const slideImage = useMemo(
		() => ({
			marginTop: 40,
		}),
		[],
	);

	const next = useCallback(
		() =>
			setCurrentSlide((_currentSlide) =>
				_currentSlide < slideCount ? _currentSlide + 1 : _currentSlide,
			),
		[slideCount],
	);

	const prev = useCallback(
		() =>
			setCurrentSlide((_currentSlide) =>
				_currentSlide > 0 ? _currentSlide - 1 : _currentSlide,
			),
		[],
	);

	const handlers = useSwipeable({
		onSwipedLeft: next,
		onSwipedRight: prev,
		preventDefaultTouchmoveEvent: true,
	});

	useEffect(() => {
		// eslint-disable-next-line compat/compat
		const observer = new IntersectionObserver((entries) => {
			const entry = entries[0];
			setIsCarouselVisible(entry.isIntersecting);
		});

		observer.observe(tabsRef.current);
	}, []);

	useEffect(() => {
		if (isCarouselVisible && count) {
			start();
			setCount(false);
		}
	}, [isCarouselVisible, count, start]);

	useEffect(() => {
		const calculateDimensions = () => {
			const widthValue =
				slideDescriptionRef.current.clientWidth +
					slideDescription.marginRight +
					slideDescription.border * 2 || 606;
			setSlideDescriptionWidth(widthValue);

			const heightValue = slideImageRef.current.clientHeight + slideImage.marginTop || 500;
			setSlideImageHeight(heightValue);
		};

		setTimeout(calculateDimensions, 100);
	}, [slideDescription, slideImage, size, currentSlide]);

	useEffect(() => {
		if (currentSlide > slideCount) {
			stop();
		}
		if (seconds === 0) {
			setCurrentSlide(0);
			resetAnimation();
		} else if (seconds === 10) {
			setCurrentSlide(1);
			resetAnimation();
		} else if (seconds === 20) {
			setCurrentSlide(2);
			resetAnimation();
		} else if (seconds === 30) {
			setCurrentSlide(3);
			resetAnimation();
		} else if (seconds === 40) {
			stop();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [seconds]);

	const manualTabbing = (slide) => {
		if (slide === 0) {
			setSeconds(0);
		} else if (slide === 1) {
			setSeconds(10);
		} else if (slide === 2) {
			setSeconds(20);
		} else if (slide === 3) {
			setSeconds(30);
		}
	};

	const swapSlide = (newSlide) => {
		setCurrentSlide(
			slides.indexOf(newSlide),
			stop(),
			reset(),
			resetAnimation(),
			manualTabbing(slides.indexOf(newSlide)),
		);
	};

	return (
		<>
			<div className="overflow-hidden">
				<section className="relative z-20 mb-16 lg:mb-24" {...handlers}>
					<Container>
						<img
							className="absolute translate-x-1/2 lg:translate-x-full"
							src={Blob}
							type="image/svg+xml"
							alt="Blob"
							width="738"
							height="616"
						/>
						<h2 className="relative mb-12 text-xl font-bold text-center md:text-2xl">
							{carouselHeader}
						</h2>
						<div className="relative flex flex-col items-center justify-between mb-12 text-center text-gray-700 md:flex-row xl:px-36">
							{slides.map((el) => (
								<button
									ref={tabsRef}
									onClick={() => swapSlide(el)}
									type="button"
									className="flex flex-col items-center mx-2 group"
									key={el.title}
								>
									<p
										className={`group-hover:text-brand-400 ${
											currentSlide === slides.indexOf(el)
												? 'text-brand-500'
												: 'text-gray-700 '
										}
										`}
									>
										{el.title}
									</p>
									<Underline
										className={`my-4 group-hover:text-brand-400 ${
											currentSlide === slides.indexOf(el)
												? 'text-brand-500'
												: 'text-gray-400'
										}`}
									/>
								</button>
							))}
						</div>

						<div className="flex flex-row">
							<div
								className="hidden overflow-hidden duration-150 ease-in-out lg:flex"
								style={{
									height: `${slideImageHeight}px`,
								}}
							>
								<div
									className="hidden w-full duration-100 ease-in-out lg:inline-block"
									style={{
										transform: `translateY(-${
											currentSlide * slideImageHeight
										}px)`,
									}}
								>
									{slides.map((el) => (
										<div
											ref={slideImageRef}
											className="w-full mb-1"
											style={{
												marginTop: `${slideImage.marginTop}px`,
											}}
											key={el.image.url}
										>
											<div
												className="w-full m-3 bg-center bg-no-repeat bg-contain w-80 h-80 xl:w-96 xl:h-96"
												style={{
													backgroundImage: `url(${el.image.url})`,
												}}
											/>
										</div>
									))}
								</div>
							</div>

							<div className="w-full lg:ml-20 lg:w-7/12">
								<div
									className="flex flex-row duration-100 ease-in-out w-[2000px] lg:w-[1800px] xl:w-[2500px]"
									style={{
										transform: `translateX(-${
											currentSlide * slideDescriptionWidth
										}px)`,
									}}
								>
									{slides.map((el) => (
										<div
											ref={slideDescriptionRef}
											className={cn(
												`rounded-md bg-white border-gray-300 duration-100 ease-in-out w-72 xs:w-[420px] md:w-[566px] md:h-[516px] h-full flex flex-col`,
												{
													'shadow-lg':
														currentSlide === slides.indexOf(el),
													'opacity-50':
														currentSlide + 1 === slides.indexOf(el),
													'opacity-0':
														currentSlide + 1 < slides.indexOf(el) ||
														currentSlide > slides.indexOf(el),
												},
											)}
											style={{
												marginRight: `${slideDescription.marginRight}px`,
												borderWidth: `${slideDescription.border}px`,
											}}
											key={el.descriptionNode.childMdx.body}
										>
											<ProgressBar
												progressBarkey={key}
												currentlyShowing={
													currentSlide === slides.indexOf(el)
												}
												running={running}
												start={start}
												pause={pause}
											/>

											<TabContent
												el={el}
												currentSlide={currentSlide}
												slides={slides}
											/>
										</div>
									))}
								</div>
							</div>
						</div>
					</Container>
				</section>
			</div>
		</>
	);
};

export default TabbedCarousel;
