import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';

import LazyLoad from 'react-lazyload';
import CopyBlock from '../../CopyBlock';

const FooterImageWithCopy = ({ imgSrc, alt, text }) => (
	<section>
		<div className="flex flex-col items-start mx-auto mb-6 lg:mb-0 lg:mr-12 lg:w-36">
			<div className="w-full mb-4">
				<CopyBlock>{text}</CopyBlock>
			</div>
			<div className="w-full">
				<LazyLoad once>
					<GatsbyImage image={imgSrc} className="max-w-lg" alt={alt} />
				</LazyLoad>
			</div>
		</div>
	</section>
);

export default FooterImageWithCopy;
