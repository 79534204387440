import React from 'react';
import { graphql } from 'gatsby';
import { GatsbyImage, getSrc } from 'gatsby-plugin-image';
import LinkButton from '@brighthr/component-linkbutton';
import { Helmet } from 'react-helmet';
import Icon from '@brighthr/component-icon';
import WellCTA from '../components/WellCTA';
import CopyBlock from '../components/CopyBlock';
import Breadcrumbs, { Breadcrumb } from '../components/Breadcrumbs';
import BookADemoForm from '../components/Marketo/BookADemo';
import DatoSEO from '../components/DatoSEO';
import DefaultLayout from '../layouts/Default';
import HrefLang from '../components/HrefLang';
import { buildLink, LOCALE_PATHS, SUPPORTED_LOCALES } from '../utils/locale';

const iconMap = {
	4518661: 'alarmclock',
	4676157: 'accommodation',
	4519118: 'shifts',
	4388512: 'calendar-dates',
	4519831: 'records',
	4519034: 'refresh',
	4520741: 'furlough-navigator',
	4518477: 'plaster',
	4512558: 'radar',
	4518665: 'receipt',
	4120713: 'chair',
	4512042: 'lightbulb',
	4518512: 'information',
	4512197: 'help',
};

const DefaultIcon = () => 'xing';

const InfoRow = ({ label, value }) => (
	<li className="w-full">
		<h4 className="inline-block w-20 pr-2 text-sm font-bold">{label}</h4>
		<p className="inline-block text-sm ">{value}</p>
	</li>
);

const IndividualTestimonialPage = ({ data, location, pageContext }) => {
	const {
		heroQuote,
		heroImage,
		companyName,
		employeeName,
		employeeImage,
		industry,
		jobTitle,
		challengeQuote,
		solutionQuote,
		features,
		companyImage,
		website,
		solutionNode,
		challengeNode,
		seoMetaTags,
		slug,
	} = data.datoCmsTestimonial;
	const locale = LOCALE_PATHS[pageContext.locale];

	return (
		<DefaultLayout fullWidth>
			<HrefLang locales={SUPPORTED_LOCALES} slug={`customers/${slug}`} />
			<DatoSEO path={location.pathname} meta={seoMetaTags} />
			<Helmet>
				<link
					rel="prefetch"
					href="https://app-lon04.marketo.com/js/forms2/js/forms2.min.js"
				/>
			</Helmet>
			<div className="bg-brand-500">
				<div className="max-w-6xl px-8 pb-56 mx-auto xl:px-0">
					<Breadcrumbs className="hidden pt-10 text-white md:flex">
						<Breadcrumb
							href={buildLink(locale, 'customers')}
							position={0}
							color="text-brand-300"
						>
							Customer Testimonials
						</Breadcrumb>
						<Breadcrumb position={1} color="text-brand-300">
							{companyName}
						</Breadcrumb>
					</Breadcrumbs>
				</div>
			</div>

			<div className="max-w-6xl px-4 pb-12 mx-auto md:px-8 xl:px-0 ">
				<div className="-mt-48 heroBanner-text">
					<div
						className="flex flex-col items-center justify-center w-full px-10 py-10 text-center bg-no-repeat bg-cover rounded-t md:py-20"
						style={{
							backgroundImage: `linear-gradient(to bottom, rgba(15, 51, 77, 0.6), rgba(15, 51, 77, 0.6)), url(${heroImage.fixed.src})`,
						}}
					>
						<h1 className="pb-1 text-4xl font-bold text-white sm:text-5xl">
							{companyName}
						</h1>
						<h2 className="w-full pb-4 text-sm text-white md:pb-10 sm:text-base">
							{heroQuote}
						</h2>

						<LinkButton
							color="white"
							href={buildLink(locale, 'customers')}
							text="Back to customer stories"
						/>
					</div>
					<div className="flex flex-row items-stretch w-full">
						<div
							className="hidden w-full bg-center bg-no-repeat bg-contain grow-0 shrink-0 md:block md:w-40 md:h-40"
							style={{
								backgroundImage: `url(${getSrc(companyImage.gatsbyImageData)})`,
							}}
						/>
						<div className="w-full h-full">
							<div className="flex flex-row flex-wrap items-start px-4 py-5 md:px-10 bg-brand-100 md:flex-nowrap">
								<ul className="mr-8 shrink-0">
									<InfoRow label="Name" value={employeeName} />
									<InfoRow label="Job title" value={jobTitle} />
									<InfoRow label="Company" value={companyName} />
									<InfoRow label="Industry" value={industry} />
									<li className="w-full">
										<h4 className="inline-block w-20 pr-2 text-sm font-bold">
											Website
										</h4>
										<p className="inline-block text-sm text-brand-400 ">
											<a href={website} rel="noopener">
												Go to website
											</a>
										</p>
									</li>
								</ul>
								<div className="mr-4">
									<h4 className="pb-2 text-sm font-bold leading-6">
										Features used:
									</h4>
									<ul className="flex flex-wrap">
										{features.map(({ originalId }) => {
											const IconName = iconMap[originalId] || DefaultIcon;

											return (
												<li
													key={originalId}
													className="flex items-center justify-center p-2 mb-2 mr-2 text-gray-900 bg-gray-300 rounded-full"
												>
													<Icon
														iconName={IconName}
														size={32}
														className="inline-block w-5 h-5 text-left"
													/>
												</li>
											);
										})}
									</ul>
								</div>
								<div className="hidden w-16 h-16 ml-auto overflow-hidden rounded-full shrink-0 md:block">
									<GatsbyImage
										alt={employeeImage.alt}
										image={employeeImage.gatsbyImageData}
									/>
								</div>
							</div>
						</div>
					</div>
					<div className="max-w-3xl mx-auto">
						<section>
							<h1 className="pt-4 text-3xl font-bold md:text-5xl md:pt-8">
								The challenge
							</h1>
							<h2 className="pb-4 text-2xl font-bold md:pb-8">{challengeQuote}</h2>
							<CopyBlock>{challengeNode.childMdx.body}</CopyBlock>
						</section>
						<section>
							<h1 className="pt-4 text-3xl font-bold md:text-5xl md:pt-8">
								The solution
							</h1>
							<h2 className="pb-4 text-2xl font-bold md:pb-8">{solutionQuote}</h2>
							<CopyBlock>{solutionNode.childMdx.body}</CopyBlock>
						</section>
					</div>
				</div>
			</div>

			<div className="">
				<div className="max-w-6xl pb-12 mx-auto mb-0 sm:mb-12 lg:mb-2 xl:px-0 ">
					<WellCTA className="mt-24">
						<h3 className="mb-6 text-xl font-bold leading-snug md:text-3xl">
							Introduce your team to BrightHR
						</h3>
						<BookADemoForm light locale={locale} />
					</WellCTA>
				</div>
			</div>
		</DefaultLayout>
	);
};

export const query = graphql`
	query seoAndCustomerTestimonial($id: String, $locale: String!) {
		datoCmsTestimonial(locale: { eq: $locale }, id: { eq: $id }) {
			seoMetaTags {
				...GatsbyDatoCmsSeoMetaTags
			}
			id
			companyName
			employeeName
			heroQuote
			website
			industry
			jobTitle
			challenge
			challengeQuote
			solution
			solutionQuote
			slug
			heroImage: heroImage {
				fluid(maxWidth: 600, imgixParams: { crop: "center", fit: "crop" }) {
					src
				}
				fixed(width: 500, imgixParams: { crop: "faces,center", fit: "crop" }) {
					src
				}
			}
			solutionNode {
				childMdx {
					body
				}
			}
			challengeNode {
				childMdx {
					body
				}
			}
			features {
				... on DatoCmsProduct {
					originalId
				}
				... on DatoCmsFeature {
					originalId
				}
			}
			employeeImage {
				alt
				gatsbyImageData(
					layout: CONSTRAINED
					width: 600
					imgixParams: { crop: "center", fit: "crop", ar: "1:1" }
				)
			}
			companyImage {
				alt
				gatsbyImageData(
					layout: FIXED
					width: 320
					imgixParams: { crop: "faces,center", fit: "crop" }
				)
			}
		}
	}
`;

export default IndividualTestimonialPage;
