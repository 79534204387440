import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import cn from 'classnames';
import CTAs from '../../CTAs';
import Container from '../../Container';
import CopyBlock from '../../CopyBlock';
import Breadcrumbs, { Breadcrumb } from '../../Breadcrumbs';
import LocaleContent from '../../LocaleContent';
import ReviewBadges from '../../ReviewBadges';
import { useLocale } from '../../../context/Locale';

const FeatureHero = ({
	subTitle,
	title,
	descriptionMdx,
	heroImage,
	crumbs = [],
	pathname,
	page,
}) => {
	const strCompare = (str1, str2) => str1.toLowerCase().trim() === str2.toLowerCase().trim();
	const locale = useLocale();

	return (
		<section className="relative pb-40 overflow-y-hidden md:pb-32 lg:pb-6 bg-gradient-to-b  lg:bg-gradient-to-l from-brand-200 to-white products-hero min-h-[600px]">
			<Container className="relative z-10">
				{crumbs.length > 0 && (
					<Breadcrumbs className="mb-6 lg:mb-12">
						{crumbs.map((crumb, i) => (
							<Breadcrumb href={crumb.link} position={i} key={crumb.text}>
								{crumb.text}
							</Breadcrumb>
						))}
					</Breadcrumbs>
				)}

				<div className="lg:flex">
					<div className="max-w-lg product-content">
						<h1 className="mb-4 text-2xl font-bold leading-none md:text-3xl">
							{title}
						</h1>

						{!strCompare(title, subTitle) && (
							<h2 className="mb-4 text-xl font-bold leading-none md:text-2xl">
								{subTitle}
							</h2>
						)}

						{descriptionMdx && <CopyBlock>{descriptionMdx}</CopyBlock>}
						<CTAs page={page} />
						<div className="flex flex-row items-center justify-center gap-4 mt-6 md:justify-start md:gap-8">
							<LocaleContent showInLocales={['en', 'ie']}>
								<ReviewBadges
									locale={locale}
									pageName="product"
									width="224"
									height="60"
									classes="w-48 lg:w-56"
									g2AwardClasses="w-24 lg:w-28"
								/>
							</LocaleContent>
							<LocaleContent showInLocales={['ca', 'au', 'nz']}>
								<ReviewBadges
									locale={locale}
									pageName="product"
									width={locale === 'ca' ? '110' : '170'}
									height={locale === 'ca' ? '91' : '80'}
									classes="fill-current"
									g2AwardClasses="w-24 lg:w-28"
								/>
							</LocaleContent>
						</div>
					</div>
					<div
						className={cn('flex-auto hidden ml-auto text-right lg:block', {
							'lg:max-w-lg': pathname === 'hr-software',
							'lg:max-w-md': pathname !== 'hr-software',
						})}
					>
						<GatsbyImage image={heroImage} alt="" loading="eager" />
					</div>
				</div>
			</Container>
		</section>
	);
};
export default FeatureHero;
