import React from 'react';
import Facebook from '../../../icons/FacebookCircle.svg';
import LinkedIn from '../../../icons/LinkedInCircle.svg';
import Youtube from '../../../icons/YoutubeCircle.svg';
import Twitter from '../../../icons/TwitterCircle.svg';

const Social = () => (
	<div className="mb-6">
		<div className="flex mb-6 text-white">
			<a
				href="https://www.facebook.com/BrightHRcom?fref=ts"
				title="Facebook"
				data-footer-link="social"
				data-testid="social-facebook"
			>
				<Facebook width={32} height={32} fill="#0057AD" className="mr-1" aria-hidden />
			</a>
			<a
				href="https://twitter.com/BrightHR_"
				title="Twitter"
				data-footer-link="social"
				data-testid="social-twitter"
			>
				<Twitter width={32} height={32} fill="#0057AD" className="mr-1" aria-hidden />
			</a>
			<a
				href="https://www.youtube.com/c/BrighthrTV"
				title="Youtube"
				data-footer-link="social"
				data-testid="social-youtube"
			>
				<Youtube width={32} height={32} className="mr-1" aria-hidden />
			</a>
			<a
				href="https://www.linkedin.com/company/brighthr"
				title="LinkedIn"
				data-footer-link="social"
				data-testid="social-linkedin"
			>
				<LinkedIn width={32} height={32} aria-hidden fill="#0057AD" />
			</a>
		</div>
	</div>
);

export default Social;
