import { graphql } from 'gatsby';
import React, { useEffect, useState } from 'react';
import loadScript from 'load-script2';

import { useLocation } from '@reach/router';
import Button from '@brighthr/component-button';
import LinkButton from '@brighthr/component-linkbutton';
import { Modal, ModalBody } from '@brighthr/component-modal';
import { useFormik } from 'formik';
import Cookies from 'js-cookie';
import LazyLoad from 'react-lazyload';
import { GatsbyImage } from 'gatsby-plugin-image';
import FormFooterLinks from '../components/Marketo/FormFooterLinks';
import Layout from '../layouts/Default';
import CopyBlock from '../components/CopyBlock';
import DatoSEO from '../components/DatoSEO';
import HrefLang from '../components/HrefLang';
import { LOCALE_PATHS, buildLink } from '../utils/locale';
import Container from '../components/Container';
import WebinarCards from '../components/ResourceCards/Webinar';
import ServiceWebinarBanner from '../components/ServiceWebinarBanner';
import WebinarProvider from '../components/CopyBlock/WebinarProvider';
import submitMarketoForm from '../components/Marketo/submitMarketoForm';
import Play from '../icons/Play.svg';
import getLeadSource from '../utils/leadSource';
import LocaleContent from '../components/LocaleContent';

const WebinarPage = ({ data, pageContext }) => {
	const {
		slug,
		title,
		description,
		bodyNode,
		category,
		thumbnail,
		youtubeId,
		_allSeoLocales,
		seoMetaTags,
		termsAndConditions,
		schema: datoSchema,
	} = data.webinar;
	const { nodes: relatedWebinars } = data.relatedWebinars;
	const location = useLocation();
	const locale = LOCALE_PATHS[pageContext.locale];

	const demoLink = () => {
		if (locale === 'ca') {
			return '/ca/book-a-demo/webinar-demo/';
		}
		if (locale === 'en') {
			return '/book-a-demo/webinar-demo/';
		}
		return buildLink(locale, 'book-a-demo/');
	};

	const autoSchema = {
		name: title,
		description,
		eventAttendanceMode: 'https://schema.org/OnlineEventAttendanceMode',
		location: [
			{
				'@type': 'VirtualLocation',
				url: data.site.siteMetadata.siteUrl + location.pathname,
			},
		],
		organizer: [
			{
				'@type': 'Organization',
				name: title,
				url: data.site.siteMetadata.siteUrl,
			},
		],
	};

	const [includedModalVisible, setIncludeModalVisible] = useState(false);

	const initialValues = {
		FirstName: '',
		LastName: '',
		Phone: '',
		Email: '',
		Company: '',
		customer: '',
		LeadSource: '',
	};

	const triggerGate = (e) => {
		e.preventDefault();
		initialValues.LeadSource = getLeadSource(locale, 'Webinar Watch Request');
		setIncludeModalVisible(true);
	};

	const { handleChange, handleSubmit, setStatus, values, isSubmitting, status } = useFormik({
		initialValues,
		onSubmit: async (formData) => {
			submitMarketoForm(formData)
				.then(() => {
					Cookies.set('bhr_ff', '1', { expires: 7 });
					setStatus('submitted');
				})
				.catch(() => {
					setStatus('error');
				});
		},
	});

	useEffect(() => {
		// Preloads the Marketo form for background submission
		const loadForm = () =>
			loadScript('//app-lon04.marketo.com/js/forms2/js/forms2.min.js')
				.then(() => {
					window.MktoForms2.loadForm(
						'//app-lon04.marketo.com',
						'217-MIC-854',
						2796,
						(form) => {
							form.onSuccess(() => false);
						},
					);
				})
				.catch(() => {});

		if (window.requestIdleCallback) {
			window.requestIdleCallback(loadForm);
		} else {
			setTimeout(loadForm);
		}
	}, []);

	return (
		<>
			<Layout fullWidth>
				<HrefLang locales={_allSeoLocales} slug={`webinars/${category.slug}/${slug}`} />
				<DatoSEO
					path={location.pathname}
					meta={seoMetaTags}
					datoSchema={datoSchema}
					schemaType="Event"
					autoSchema={autoSchema}
				/>

				<Container className="max-w-screen-xl px-5 lg:px-12">
					<WebinarProvider>
						<div className="flex justify-center">
							<div className="px-4 py-1 border rounded-full bg-primary-100 text-primary-700">
								<p className="text-xs tracking-widest uppercase">
									{locale ? 'BrightHR Webinar' : 'BrightHR Seminar'}
								</p>
							</div>
						</div>

						<h1 className="my-5 text-4xl font-bold leading-9 tracking-tight text-center">
							{title}
						</h1>

						{description && (
							<p className="mb-10 text-lg leading-7 text-center md:mb-8">
								{description}
							</p>
						)}

						<div className="flex flex-col">
							{bodyNode.childMdx.body && (
								<div className="order-last mt-8 text-lg leading-7 text-center lg:order-first">
									<CopyBlock>{bodyNode.childMdx.body}</CopyBlock>
								</div>
							)}

							{Cookies.get('bhr_ff') === '1' ? (
								<div>
									<div className="mx-auto lg:mt-8 lg:max-w-4xl">
										<button
											type="button"
											className="relative items-stretch w-full shadow aspect-video"
											onClick={(e) => triggerGate(e)}
										>
											<iframe
												src={`https://www.youtube.com/embed/${youtubeId}?autoplay=1&modestbranding=true&rel=0`}
												title={title}
												className="absolute top-0 bottom-0 left-0 right-0 w-full h-full rounded-xl"
												frameBorder="0"
												allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
												allowFullScreen
											/>
										</button>
									</div>
								</div>
							) : (
								<LazyLoad once>
									<div className="mx-auto">
										<button
											type="button"
											className="relative lg:mt-8 lg:max-w-4xl"
											onClick={(e) => {
												triggerGate(e);
											}}
										>
											{thumbnail ? (
												<GatsbyImage
													image={thumbnail.gatsbyImageData}
													loading="eager"
													objectFit="cover"
													alt={thumbnail.alt || title}
													className="rounded-xl"
												/>
											) : (
												<img
													src={`https://img.youtube.com/vi/${youtubeId}/maxresdefault.jpg`}
													alt={thumbnail.alt || title}
													className="rounded-xl"
												/>
											)}
											<div className="absolute w-24 h-16 -translate-x-1/2 -translate-y-1/2 cursor-pointer rounded-xl top-1/2 left-1/2 opacity-80 bg-secondary-300">
												<Play className="w-full h-8 mt-4 text-white" />
											</div>
										</button>
									</div>
								</LazyLoad>
							)}
							{termsAndConditions && (
								<p className="py-8 mx-auto text-sm italic text-center max-w-[870px]">
									{termsAndConditions}
								</p>
							)}
						</div>
					</WebinarProvider>

					<Container className="max-w-screen-xl px-5 text-center lg:px-12">
						<h2 className="my-8 text-2xl font-bold leading-8">
							And once you’ve watched it…
						</h2>
						<p className="text-lg">
							Book a free demo to see BrightHR’s exclusive range of smart HR tools in
							action. Our friendly experts will show you around the software and
							explain how it helps you transform your people management.
						</p>
						<p className="my-8 text-lg">
							Just click the button below to schedule your personal tour:
						</p>
						<div className="m-auto w-fit">
							<LinkButton
								href={demoLink()}
								target="_blank"
								text="Book my free demo"
								size="lg"
							/>
						</div>
					</Container>
				</Container>
				{relatedWebinars.length > 0 && (
					<div className="mt-2 mb-4 lg:mt-10">
						<Container className="max-w-screen-xl px-5 lg:px-12">
							<h2 className="mb-8 text-2xl font-bold leading-8 text-center">
								Related Webinars
							</h2>

							<WebinarCards
								className="flex flex-col mb-8 md:flex-row md:flex-wrap md:-translate-x-4 md:mb-0"
								cardClassName="w-full pb-8 md:w-1/2 md:px-4 lg:w-1/3 last:pb-0 md:last:pb-8"
								resources={relatedWebinars}
								locale={locale}
								slugBase="webinars"
							/>
						</Container>
					</div>
				)}
				<LocaleContent showInLocales="en">
					<div className="max-w-screen-xl px-5 mx-auto lg:px-12">
						<ServiceWebinarBanner />
					</div>
				</LocaleContent>
			</Layout>

			{includedModalVisible && (
				<Modal width="base" close={() => setIncludeModalVisible(false)} version="2">
					<ModalBody>
						{status !== 'submitted' && (
							<div className="p-8">
								<h2 className="mb-6 text-2xl leading-8">
									Watch <strong>{`'${title}'`}</strong> now by filling out the
									form below
								</h2>
								<form onSubmit={handleSubmit} className="mx-auto bg-white">
									{status === 'error' && (
										<p className="mb-5 font-bold text-legacy-error">
											Something went wrong. Please try submitting your details
											again.
										</p>
									)}
									<div className="md:flex md:flex-row md:justify-between">
										<label
											htmlFor="first"
											className="block w-full mb-4 md:mr-2"
										>
											<strong>First name</strong>
											<input
												type="text"
												id="FirstName"
												name="FirstName"
												placeholder="First name"
												value={values.FirstName}
												onChange={handleChange}
												required
												className="block w-full px-4 py-3 mt-2 placeholder-gray-400 border border-gray-400 rounded"
											/>
										</label>
										<label htmlFor="last" className="block w-full mb-4 md:ml-2">
											<strong>Last name</strong>
											<input
												type="text"
												id="LastName"
												name="LastName"
												placeholder="Last name"
												value={values.LastName}
												onChange={handleChange}
												required
												className="block w-full px-4 py-3 mt-2 placeholder-gray-400 border border-gray-400 rounded"
											/>
										</label>
									</div>
									<label htmlFor="company" className="block mb-4">
										<strong>Company</strong>
										<input
											type="text"
											id="Company"
											name="Company"
											placeholder="Company name"
											value={values.Company}
											onChange={handleChange}
											required
											className="block w-full px-4 py-3 mt-2 placeholder-gray-400 border border-gray-400 rounded"
										/>
									</label>
									<label htmlFor="email" className="block mb-4">
										<strong>Email address</strong>
										<input
											type="email"
											id="Email"
											name="Email"
											placeholder="Email address"
											value={values.Email}
											onChange={handleChange}
											required
											className="block w-full px-4 py-3 mt-2 placeholder-gray-400 border border-gray-400 rounded"
										/>
									</label>
									<label htmlFor="Phone" className="block mb-4">
										<strong>Phone number</strong>
										<input
											type="tel"
											id="Phone"
											name="Phone"
											placeholder="Phone number"
											value={values.Phone}
											onChange={handleChange}
											pattern="[0-9\s]{7,16}"
											title="Enter a valid telephone number between 7 and 16 digits."
											required
											className="block w-full px-4 py-3 mt-2 placeholder-gray-400 border border-gray-400 rounded"
										/>
									</label>

									<label htmlFor="customer" className="flex my-6">
										<input
											type="checkbox"
											id="customer"
											name="customer"
											value={values.customer}
											onChange={handleChange}
											className="w-6 h-6"
										/>
										<span className="inline-block ml-4 leading-6 text-gray-700">
											Already a bright user? Check here
										</span>
									</label>
									<div className="flex flex-col w-full mb-6">
										<Button
											id="Submit"
											type="submit"
											disabled={isSubmitting}
											text="Watch Now"
											size="lg"
										/>
									</div>
									<FormFooterLinks
										className="text-sm text-left"
										textLinkColor="text-brand-500"
										locale={locale}
									/>
								</form>
							</div>
						)}

						{status === 'submitted' && (
							<>
								<p className="mb-6 text-2xl leading-8 text-center">
									Thanks for submitting your information
								</p>
								<div className="w-full">
									<Button
										onClick={() => setIncludeModalVisible(false)}
										text="Watch Now"
										size="lg"
									/>
								</div>
							</>
						)}
					</ModalBody>
				</Modal>
			)}
		</>
	);
};

export default WebinarPage;

export const query = graphql`
	query singleWebinar($id: String, $locale: String!, $slug: String, $categoryId: String!) {
		site {
			siteMetadata {
				title
				siteUrl
			}
		}
		webinar: datoCmsWebinar(locale: { eq: $locale }, slug: { eq: $slug }, id: { eq: $id }) {
			seoMetaTags {
				...GatsbyDatoCmsSeoMetaTags
			}
			_allSeoLocales {
				locale
			}
			id
			slug
			locale
			title
			description
			breadcrumbText
			schema
			bodyNode {
				childMdx {
					body
				}
			}
			thumbnail {
				gatsbyImageData(
					width: 870
					imgixParams: { auto: "compress", ar: "16:9", fit: "crop" }
				)
				alt
			}
			youtubeId
			category {
				slug
				title
			}
			termsAndConditions
		}
		relatedWebinars: allDatoCmsWebinar(
			filter: {
				category: { id: { eq: $categoryId }, locale: { eq: $locale }, slug: { ne: null } }
				id: { ne: $id }
			}
			sort: { order: DESC, fields: meta___createdAt }
			limit: 3
		) {
			nodes {
				id
				title
				slug
				date
				meta {
					createdAt
				}
				bodyNode {
					childMdx {
						excerpt(truncate: true)
					}
				}
				thumbnail {
					gatsbyImageData
					alt
				}
				youtubeId
				category {
					id
					slug
					title
				}
			}
		}
	}
`;
