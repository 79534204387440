import React, { useState } from 'react';
import { graphql } from 'gatsby';
import { useLocation } from '@reach/router';
import { Helmet } from 'react-helmet';
import { format } from 'date-fns';
import cn from 'classnames';
import HrefLang from '../../components/HrefLang';
import SEO from '../../components/SEO';
import { LocaleProvider } from '../../context/Locale';
import { useVar } from '../../components/Var';
import { buildLink, LOCALE_PATHS } from '../../utils/locale';
import Logo from '../../components/Logo';
import CopyBlock from '../../components/CopyBlock';
import DemoSurveyBox from './components/DemoSurveyBox';
import ListTick from './icons/list-tick.svg';
import CardDividingLine from './icons/card-dividing-line.svg';
import MinimalFooter from '../../components/MinimalFooter';
import NewsletterFooter from '../../components/Footer/Components/newsletterFooter';
import Optimize from '../../components/Optimize';

const DemoSurveyPage = ({ data, pageContext }) => {
	const {
		seo,
		_allSeoLocales,
		slug,
		formTitle,
		formSubtitle,
		formBodyNode,
		benefitsTitle,
		benefitsList,
	} = data.datoCmsDemoSurvey;
	const { nodes: blogs } = data.allDatoCmsBlogPost;
	const location = useLocation();
	const salesNumber = useVar('salesNumber');
	const locale = LOCALE_PATHS[pageContext.locale];
	const [surveyFormSubmitted, setSurveyFormSubmitted] = useState(false);

	return (
		<div className="bg-white">
			<Optimize />
			<HrefLang locales={_allSeoLocales} slug={slug} />
			<Helmet>
				<link
					rel="prefetch"
					href="https://app-lon04.marketo.com/js/forms2/js/forms2.min.js"
				/>
			</Helmet>
			<SEO path={location.pathname} title={seo.title} />
			<>
				<div
					className="px-3 pb-4 m-3 rounded-3xl pt-9 lg:px-9 lg:pb-56"
					style={{
						background:
							'radial-gradient(100% 158.48% at 100% 100%, #FFB8DD 0%, #0070E0 100%, #0070E0 100%)',
					}}
				>
					<div className="flex flex-col max-w-6xl mx-auto lg:flex-row lg:flex-wrap lg:relative lg:w-full lg:justify-end">
						<div className="flex flex-row items-center justify-between w-full px-5 basis-full flex-nowrap mb-14 lg:px-0">
							<a href={buildLink(locale, '/')} className="text-white">
								<Logo className="text-white" />
							</a>
							<a
								className="text-lg font-semibold text-white md:text-2xl"
								href={`tel:${salesNumber}`}
							>
								{salesNumber}
							</a>
						</div>
						<div className="lg:basis-1/2 lg:w-1/2 lg:pr-16 lg:absolute lg:top-[94px] lg:left-0">
							<div className="px-5 py-10 bg-white rounded-3xl lg:px-10 lg:shadow-xl">
								<DemoSurveyBox
									formTitle={formTitle}
									formSubtitle={formSubtitle}
									formBodyNode={formBodyNode}
									setSurveyFormSubmitted={setSurveyFormSubmitted}
								/>
							</div>
						</div>
						<div className="hidden lg:block lg:w-1/2 lg:basis-1/2 lg:pl-16">
							<h2 className="mb-6 text-xl font-semibold text-white">
								{benefitsTitle}
							</h2>
							<ul className="text-xl text-white">
								{benefitsList.map((item, index) => (
									// eslint-disable-next-line react/no-array-index-key
									<li className="flex flex-row mb-4 flex-nowrap" key={index}>
										<ListTick className="w-10 mr-5 shrink-0 basis-10" />
										<div>
											<CopyBlock>{item.textNode.childMdx.body}</CopyBlock>
										</div>
									</li>
								))}
							</ul>
						</div>
					</div>
				</div>
				<div
					className={cn('p-3 mt-11 lg:max-w-6xl lg:mx-auto', {
						'lg:mt-48': !surveyFormSubmitted,
						'lg:mt-11': surveyFormSubmitted,
					})}
				>
					<h2 className="mb-10 text-3xl font-semibold text-center">
						Latest from the blog
					</h2>
					<div className="flex flex-col lg:grid lg:gap-7 lg:grid-rows-1 lg:grid-cols-3">
						{blogs.map((blog) => (
							<div className="mb-8 border border-gray-400 rounded-md" key={blog.id}>
								<a
									href={buildLink(
										locale,
										`blog/${blog.category.slug}/${blog.slug}`,
									)}
								>
									<img
										src={blog.thumbnail.fixed.src}
										alt={blog.thumbnail.alt || blog.title}
									/>
								</a>
								<CardDividingLine className="w-full" />
								<div className="px-5 py-8">
									<a
										href={buildLink(locale, `blog/${blog.category.slug}`)}
										className="block mb-4 text-xs font-semibold text-brand-500"
									>
										{blog.category.name}
									</a>
									<span className="block mb-4 text-sm">
										{format(
											new Date(blog.date || blog.updatedAt),
											'eeee, MMM dd, yyyy',
										)}
									</span>
									<h3 className="mb-3 text-lg font-semibold">
										<a
											href={buildLink(
												locale,
												`blog/${blog.category.slug}/${blog.slug}`,
											)}
										>
											{blog.title}
										</a>
									</h3>
									<p>{blog.subtitle}</p>
								</div>
							</div>
						))}
					</div>
				</div>
				<div className="bg-brand-100">
					<div className="max-w-6xl mx-auto">
						<NewsletterFooter />
					</div>
				</div>

				<MinimalFooter
					locale={locale}
					additionalClasses="!text-left px-7 xl:px-0 !max-w-6xl"
				/>
			</>
		</div>
	);
};

const DemoSurveyPageContainer = (props) => (
	<LocaleProvider>
		<DemoSurveyPage {...props} />
	</LocaleProvider>
);

export default DemoSurveyPageContainer;

// Hardcoding to just query en for now
// Change to $locale variable when A/B test is won
// Dont forget second query below
// $locale: String!
export const query = graphql`
	query demoSurveyB {
		datoCmsDemoSurvey(locale: { eq: "en" }) {
			_allSeoLocales {
				locale
			}
			seo {
				title
				description
			}
			id
			formTitle
			formSubtitle
			slug
			formBodyNode {
				childMdx {
					body
				}
			}
			benefitsTitle
			benefitsList {
				textNode {
					childMdx {
						body
					}
				}
			}
		}
		allDatoCmsBlogPost(
			limit: 3
			filter: { locale: { eq: "en" }, slug: { ne: null }, date: { ne: null } }
			sort: { fields: date, order: DESC }
		) {
			nodes {
				id
				title
				subtitle
				slug
				date
				category {
					slug
					name
					id
				}
				meta {
					updatedAt
				}
				thumbnail {
					fixed(width: 650, imgixParams: { auto: "compress", q: 60 }) {
						srcSet
						src
					}
					alt
				}
				image {
					fixed(width: 650, imgixParams: { auto: "compress", q: 60 }) {
						srcSet
						src
					}
					alt
				}
			}
		}
	}
`;
