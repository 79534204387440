import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import CrossIcon from '../../icons/Cross.svg';

const Toast = ({ status, setStatus, successMessage, errorMessage }) => {
	const [statusObj, setStatusObj] = useState({
		header: 'Success',
		textColor: 'text-legacy-success',
		borderColor: 'border-legacy-success',
	});

	useEffect(() => {
		const close = (e) => {
			if (e.key === 'Escape' && status !== undefined) {
				setStatus(undefined);
			}
		};
		window.addEventListener('keydown', close);
		return () => window.removeEventListener('keydown', close);
	}, [status, setStatus]);

	useEffect(() => {
		if (status !== undefined) {
			const timer = setTimeout(() => {
				setStatus(undefined);
			}, 5000);
			return () => clearTimeout(timer);
		}
	}, [status, setStatus]);

	useEffect(() => {
		if (status === 'submitted') {
			setStatusObj({
				header: 'Success',
				textColor: 'text-legacy-success',
				borderColor: 'border-legacy-success',
				message: successMessage,
			});
		} else if (status === 'error') {
			setStatusObj({
				header: 'Error',
				textColor: 'text-legacy-error',
				borderColor: 'border-legacy-error',
				message: errorMessage,
			});
		}
	}, [status, successMessage, errorMessage]);

	return (
		<>
			<div
				className={cn(
					`fixed flex flex-row items-center w-11/12 p-3 text-left -translate-x-1/2 bg-white border-2 rounded-md bottom-4 left-1/2 lg:w-1/3 ${statusObj.borderColor} z-100 transition duration-700 ease-in-out`,
					status !== undefined ? 'translate-y-0' : 'translate-y-96',
				)}
			>
				<CrossIcon
					tabIndex="0"
					onClick={() => setStatus(undefined)}
					className="absolute z-10 w-6 h-6 text-gray-700 cursor-pointer top-2 right-2"
				/>
				{status === 'error' ? (
					<svg
						className="w-12 mr-4 text-legacy-error"
						width="32"
						height="32"
						viewBox="0 0 32 32"
						fill="currentColor"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M23.2354 25.8478C22.9927 25.7474 22.7721 25.6001 22.5863 25.4145L16.0001 18.8283L9.41398 25.4145C9.22816 25.6001 9.00759 25.7474 8.76487 25.8478C8.52214 25.9482 8.26202 25.9998 7.99934 25.9997C7.73667 25.9996 7.47659 25.9477 7.23396 25.8471C6.99132 25.7465 6.77088 25.5991 6.58523 25.4132C6.39957 25.2274 6.25233 25.0068 6.15192 24.7641C6.05151 24.5214 5.99988 24.2613 6 23.9986C6.00012 23.7359 6.05197 23.4758 6.1526 23.2332C6.25322 22.9906 6.40066 22.7701 6.58648 22.5845L13.1715 15.9995L6.58648 9.41448C6.21136 9.03937 6.00062 8.5306 6.00062 8.00011C6.00062 7.46961 6.21136 6.96085 6.58648 6.58573C6.96159 6.21062 7.47036 5.99988 8.00085 5.99988C8.53134 5.99988 9.04011 6.21062 9.41523 6.58573L16.0001 13.1706L22.585 6.58573C22.9601 6.21062 23.4689 5.99988 23.9994 5.99988C24.5299 5.99988 25.0387 6.21062 25.4138 6.58573C25.7889 6.96085 25.9996 7.46961 25.9996 8.00011C25.9996 8.5306 25.7889 9.03937 25.4138 9.41448L18.8288 15.9995L25.4138 22.5845C25.5996 22.7701 25.747 22.9906 25.8476 23.2332C25.9483 23.4758 26.0001 23.7359 26.0002 23.9986C26.0004 24.2613 25.9487 24.5214 25.8483 24.7641C25.7479 25.0068 25.6007 25.2274 25.415 25.4132C25.2294 25.5991 25.0089 25.7465 24.7663 25.8471C24.5237 25.9477 24.2636 25.9996 24.0009 25.9997C23.7382 25.9998 23.4781 25.9482 23.2354 25.8478Z"
							fill="currentColor"
						/>
					</svg>
				) : (
					<svg
						className="w-12 mr-4 text-legacy-success"
						width="24"
						height="24"
						viewBox="0 0 24 24"
						fill="currentColor"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M22 0C21.6785 0.000230857 21.3618 0.0779473 21.0768 0.226564C20.7917 0.375181 20.5467 0.59032 20.3625 0.85375L7.72872 18.9L3.41497 14.5863C3.03985 14.2111 2.53109 14.0004 2.00059 14.0004C1.4701 14.0004 0.961335 14.2111 0.586219 14.5863C0.211104 14.9614 0.000366211 15.4701 0.000366211 16.0006C0.000366211 16.5311 0.211104 17.0399 0.586219 17.415L6.58622 23.415C6.79148 23.6202 7.03893 23.7782 7.31141 23.8782C7.58388 23.9781 7.87485 24.0176 8.1641 23.9938C8.45335 23.97 8.73396 23.8836 8.98644 23.7404C9.23893 23.5973 9.45724 23.401 9.62622 23.165L23.6375 3.14625C23.847 2.8466 23.9705 2.49523 23.9943 2.13034C24.0181 1.76545 23.9415 1.40101 23.7726 1.07665C23.6038 0.752288 23.3493 0.480414 23.0368 0.290592C22.7242 0.100769 22.3656 0.000262466 22 0V0Z"
							fill="currentColor"
						/>
					</svg>
				)}

				<div className="flex flex-col">
					<span className="text-xl font-bold">{statusObj.header}</span>
					<p data-cy="newsletter-thanks">{statusObj.message}</p>
				</div>
			</div>
		</>
	);
};

export default Toast;
