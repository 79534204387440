import React from 'react';
import DoubleQuote from '../../../images/vectors/DoubleQuote.svg';

const Quote = ({ quoteData }) => {
	const { quoteText, quoteCitationPerson, quoteCitationRole } = quoteData;

	return (
		<div className="flex flex-col gap-2">
			<img className="w-8 mx-auto" src={DoubleQuote} alt="" />
			<p className="text-sm font-bold">{quoteText}</p>
			<p className="text-primary-500">
				<span className="font-bold">- {quoteCitationRole} </span>
				{quoteCitationPerson}
			</p>
		</div>
	);
};

export default Quote;
