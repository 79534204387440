import React from 'react';

import { useLocation } from '@reach/router';
import { Formik, Field } from 'formik';
import Button from '@brighthr/component-button';

import CryptoJS from 'crypto-js';
import useQueryParams from '../hooks/useQueryParams';

import SEO from '../components/SEO';
import Logo from '../components/Logo';

import Container from '../components/Container';
import HrefLang from '../components/HrefLang';
import MinimalFooter from '../components/MinimalFooter';
import { buildLink } from '../utils/locale';

import BlobBg from '../images/webinar-lead/BigBlob.png';

const WebinarLead = ({ locale }) => {
	const location = useLocation();
	const queryParams = useQueryParams();
	const { FirstName, LastName, Phone, Company, Email } = queryParams;

	const sk = 'bad2dsGtq0ixmcT5qfjFOH';

	const decryptValue = (encryptedValue) =>
		encryptedValue ? CryptoJS.AES.decrypt(encryptedValue, sk).toString(CryptoJS.enc.Utf8) : '';

	const decryptFirstName = decryptValue(FirstName);
	const decryptLastName = decryptValue(LastName);
	const decryptPhone = decryptValue(Phone);
	const decryptEmail = decryptValue(Email);

	return (
		<>
			<HrefLang locales={['en']} slug="log-a-support-case" />

			<SEO
				path={location.pathname}
				title="Log a support case"
				description="Log your support case today with Bright HR and we will endeavour to help you in a timely fashion."
			/>
			<div
				className="h-screen bg-no-repeat"
				style={{
					backgroundImage: `url(${BlobBg})`,
					backgroundPosition: '80% 100px',
					backgroundSize: '55%',
				}}
			>
				<Container className="relative pb-0 md:p-6 ">
					<header className="relative flex flex-row items-center justify-between mb-8 md:mb-8 text-brand-500">
						<a href={buildLink(locale, '/')}>
							<Logo />
						</a>
					</header>
					<div className="m-auto">
						<section>
							<div className="flex justify-center">
								<div className="relative w-full mx-auto mt-2 mb-8 bg-white border border-gray-300 rounded-md shadow-md lg:w-2/3 xl:w-1/2 lg:mx-12 lg:mb-0">
									<div className="md:flex">
										<div className="px-8 py-12 sm:flex-1 lg:px-16">
											<div className="form-content formcontainer-special">
												<h1 className="mb-2 text-2xl font-bold md:text-3xl">
													BrightHR | Log a Support Case
												</h1>
												<p className="mb-6 text-base">
													Please fill in the form with the details of your
													request.
												</p>
												<Formik
													enableReinitialize
													initialValues={{
														name:
															decryptFirstName && decryptLastName
																? `${decryptFirstName} ${decryptLastName}`.trim()
																: '',
														email: decryptEmail || '',
														telephone: decryptPhone || '',
														company: Company || '',
														type: '',
														description: '',
													}}
												>
													{(formik) => (
														<form
															action="https://webto.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8"
															method="POST"
															name="logSupportCaseForm"
															id="logSupportCaseForm"
														>
															<input
																type="hidden"
																name="orgid"
																value="00D24000000Y4sp"
															/>
															<input
																type="hidden"
																name="retURL"
																value="http://pages.brighthr.com/new-case-thankyou.html"
															/>
															<input
																type="hidden"
																name="subject"
																id="subject"
																value="BrightHR Support Feedback"
															/>
															<input
																type="hidden"
																name="status"
																id="status"
																value="New"
															/>
															<input
																type="hidden"
																name="priority"
																id="priority"
																value="Medium"
															/>
															<input
																type="hidden"
																name="reason"
																id="reason"
																value="Support Feedback"
															/>
															<label
																htmlFor="name"
																className="space-y-2"
															>
																<span className="block mt-2 text-sm font-bold leading-tight">
																	Full Name*
																</span>
																<input
																	value={formik.values.name}
																	onChange={formik.handleChange}
																	name="name"
																	id="name"
																	type="text"
																	className="w-full px-3 py-2 text-base transition duration-100 border-2 border-gray-400 rounded outline-none focus:border-brand-500"
																	required
																/>
															</label>
															<label
																htmlFor="email"
																className="space-y-2"
															>
																<span className="block mt-2 text-sm font-bold leading-tight">
																	Email*
																</span>
																<input
																	onChange={formik.handleChange}
																	value={formik.values.email}
																	name="email"
																	id="email"
																	type="email"
																	required
																	className="w-full px-3 py-2 text-base transition duration-100 border-2 border-gray-400 rounded outline-none focus:border-brand-500"
																/>
															</label>
															<label
																htmlFor="phone"
																className="space-y-2"
															>
																<span className="block mt-2 text-sm font-bold leading-tight">
																	Telephone number*
																</span>
																<input
																	onChange={formik.handleChange}
																	value={formik.values.telephone}
																	name="telephone"
																	id="phone"
																	type="tel"
																	className="w-full px-3 py-2 text-base transition duration-100 border-2 border-gray-400 rounded outline-none focus:border-brand-500"
																	pattern="[0-9\s]{7,16}"
																	title="Enter a valid telephone number between 7 and 16 digits."
																	required
																/>
															</label>
															<label
																htmlFor="company"
																className="space-y-2"
															>
																<span className="block mt-2 text-sm font-bold leading-tight">
																	Company name*
																</span>
																<input
																	value={formik.values.company}
																	onChange={formik.handleChange}
																	name="company"
																	id="company"
																	type="text"
																	className="w-full px-3 py-2 text-base transition duration-100 border-2 border-gray-400 rounded outline-none focus:border-brand-500"
																	required
																/>
															</label>

															<label
																htmlFor="type"
																className="space-y-2"
															>
																<span className="block mt-2 text-sm font-bold leading-tight">
																	Type of issue*
																</span>
																<Field
																	name="type"
																	as="select"
																	className="w-full px-3 py-2 text-base transition duration-100 border-2 border-gray-400 rounded outline-none focus:border-brand-500"
																	required
																>
																	<option value="">
																		--None--
																	</option>
																	<option value="Problem">
																		Problem
																	</option>
																	<option value="Feature Request">
																		Feature Request
																	</option>
																	<option value="Question">
																		Question
																	</option>
																</Field>
															</label>

															<label
																htmlFor="description"
																className="space-y-2"
															>
																<span className="block mt-2 text-sm font-bold leading-tight">
																	Your Comments*
																</span>

																<textarea
																	value={
																		formik.values.description
																	}
																	onChange={formik.handleChange}
																	name="description"
																	rows="5"
																	required
																	placeholder="Please enter the details of your request..."
																	id="description"
																	className="w-full px-3 py-2 text-base transition duration-100 border-2 border-gray-400 rounded outline-none focus:border-brand-500"
																/>
															</label>

															<div className="flex flex-col mt-4 mb-3 d:mb-0">
																<Button
																	type="submit"
																	name="submit"
																	id="submit"
																	text="Submit"
																/>
															</div>
														</form>
													)}
												</Formik>
											</div>
										</div>
									</div>
								</div>
							</div>
						</section>
					</div>
				</Container>

				<Container class="pt-0 mt:pt-6">
					<MinimalFooter locale={locale} />
				</Container>
			</div>
		</>
	);
};

const WrappedWebinarLead = (props) => <WebinarLead {...props} />;

export default WrappedWebinarLead;
