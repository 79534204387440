import React, { useRef, useState, useEffect } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import cn from 'classnames';
import { graphql } from 'gatsby';
import { useLocation } from '@reach/router';
import { remark } from 'remark';
import remarkRehype from 'remark-rehype/lib';
import remarkParse from 'remark-parse/lib';
import rehypeStringify from 'rehype-stringify';
import DefaultLayout from '../layouts/Default';
import useFuse from '../components/useFuse';
import SearchBar from '../components/SearchBar';
import Breadcrumbs, { Breadcrumb } from '../components/Breadcrumbs';
import Question, { format, isValidForLocale } from '../components/Support/Question';
import DatoSEO from '../components/DatoSEO';
import HrefLang from '../components/HrefLang';
import { buildLink } from '../utils/locale';
import LogSupport from '../components/Support/LogSupportCase';
import PhoneContact from '../components/Support/PhoneContact';
import LightningTileWrapper from '../components/LightningTile';
import Pagination from '../components/Pagination';
import scrollDirection from '../utils/scrollDirection';
import usePagination from '../hooks/usePagination';
import usePaginatedSearch from '../hooks/usePaginatedSearch';

const fuseOptions = {
	keys: [
		{
			name: 'id',
			weight: 1,
		},
		{
			name: 'question',
			weight: 0.7,
		},
		{
			name: 'answer',
			weight: 0.3,
		},
	],
};

const SupportHubSubTopic = ({ pageContext, data }) => {
	const listRef = useRef();
	const searchInputRef = useRef();
	const location = useLocation();
	const scroll = scrollDirection();

	const questions = data.allDatoCmsSupportQuestion.nodes
		.filter((q) => isValidForLocale(q, pageContext.locale))
		.map((q) => format(q, pageContext.locale));

	const { result, search } = useFuse(questions, fuseOptions);

	const subTopicName = questions[0]?.subTopic.name;
	const subTopicSlug = questions[0]?.subTopic.slug;
	const subTopicLightningWidget = data.lightningWidget.nodes[0].subTopic.lightningWidget[0];

	const PER_PAGE = 9;
	const { page, pageNumbers, currentPageResults } = usePagination(result.length, PER_PAGE);
	const { searchInput, clearSearch, showSearchResults, handleKeyDown, delayedURLUpdate } =
		usePaginatedSearch({
			searchInputRef,
			search,
		});

	const { showInAu, showInCa, showInIe, showInNz, showInUk } =
		data.allDatoCmsSupportQuestion.nodes[0];

	const showIns = {
		showInAu,
		showInCa,
		showInIe,
		showInNz,
		showInUk,
	};

	const localeToShowInSwitch = {
		'en-AU': 'showInAu',
		'en-CA': 'showInCa',
		'en-IE': 'showInIe',
		'en-NZ': 'showInNz',
		en: 'showInUk',
	};

	const allLocaleSlugs = data.allDatoCmsSupportSubTopic.nodes;

	const filteredAllLocaleSlugs = allLocaleSlugs.filter((slug) => {
		const showInForLocale = localeToShowInSwitch[slug.locale];
		// WARNING: sometimes returns true if there are questions set to show in IE
		// that are in sub-topics that don't have that locale
		const isSlugValid = showIns[showInForLocale] && slug.slug !== null;
		return isSlugValid;
	});

	const filteredAllLocales = filteredAllLocaleSlugs.map((item) => {
		if (item.slug === null) {
			return;
		}
		return item.locale;
	});

	filteredAllLocaleSlugs.push(`help/faqs/${pageContext.topic.slug}/`);
	const [autoSchema, setAutoSchema] = useState([]);

	useEffect(() => {
		async function createAutoSchema() {
			const autoSchemaObj = Promise.all(
				questions.map(async (faq) => {
					const htmlAnswer = async (answerMarkdown) => {
						const thing = await remark()
							.use(remarkParse)
							.use(remarkRehype)
							.use(rehypeStringify)
							.process(answerMarkdown);
						return String(thing);
					};

					return {
						'@type': 'Question',
						name: faq.question,
						acceptedAnswer: {
							'@type': 'Answer',
							text: `${await htmlAnswer(faq.answer)}`,
						},
					};
				}),
			);

			setAutoSchema({ mainEntity: await autoSchemaObj });
		}
		createAutoSchema();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<DefaultLayout fullWidth>
			<HrefLang
				locales={filteredAllLocales}
				slug={`help/faqs/${pageContext.topic.slug}/${subTopicSlug}`}
				alternateSlugs={filteredAllLocaleSlugs}
			/>
			<div className="px-6">
				<DatoSEO
					path={location.pathname}
					meta={data.datoCmsSupportSubTopic.seoMetaTags}
					schemaType="FAQPage"
					autoSchema={autoSchema}
				/>
				<section className="flex flex-row max-w-screen-xl mx-auto bg-right-top bg-no-repeat bg-contain">
					<div className="w-full px-6 py-8 grow md:pr-12">
						<Breadcrumbs className="hidden mb-4 md:flex">
							<Breadcrumb href={buildLink(pageContext.locale, 'help')} position={0}>
								Support
							</Breadcrumb>
							<Breadcrumb
								href={buildLink(pageContext.locale, 'help/faqs')}
								position={1}
							>
								FAQs
							</Breadcrumb>
							<Breadcrumb
								href={buildLink(
									pageContext.locale,
									`help/faqs/${pageContext.topic.slug}`,
								)}
								position={2}
							>
								{pageContext.topic.name}
							</Breadcrumb>
							<Breadcrumb position={3}>{subTopicName}</Breadcrumb>
						</Breadcrumbs>
						<h1 className="mb-3 text-2xl font-bold lg:text-4xl md:mt-12">
							{pageContext.topic.name} / {subTopicName}
						</h1>
					</div>
					<StaticImage
						data-cy="support-faqs-hero-image"
						src="../images/support/faqs-hero.png"
						alt="faqs banner"
						className="hidden w-4/12 ml-auto shrink-0 md:block"
						placeholder="eager"
						aria-hidden
					/>
				</section>

				<div className="max-w-screen-xl mx-auto mt-8 md:mt-14">
					<SearchBar
						listRef={listRef}
						className="mb-8"
						value={searchInput}
						onChange={(e) => {
							showSearchResults(e, false);
							delayedURLUpdate(e);
						}}
						placeholder="What do you need help with?"
						ref={searchInputRef}
						clearSearch={clearSearch}
						scroll={scroll}
						onKeyDown={handleKeyDown}
					/>
					<div
						className={cn(
							'rounded bg-brand-100',
							searchInput.length >= 3 ? 'block p-8 pt-6 mb-8' : 'hidden',
						)}
						ref={listRef}
					>
						{searchInput.length >= 3 && (
							<>
								<h3 className="mb-6">
									Search results{' '}
									<span className="font-bold">({result.length})</span>
								</h3>
								<ul className="space-y-4">
									{currentPageResults(result).map((question) => (
										<li key={question.id}>
											<Question {...question} locale={pageContext.locale} />
										</li>
									))}
								</ul>
								{result.length > PER_PAGE && (
									<Pagination
										page={page}
										pageNumbers={pageNumbers}
										searchInput={searchInput}
										className="mt-6 mb-0"
									/>
								)}
							</>
						)}
					</div>
					{searchInput.length < 3 && (
						<section className="mt-16 space-y-6">
							{currentPageResults(questions).map((question) => (
								<Question
									key={question.id}
									standalone
									{...question}
									locale={pageContext.locale}
								/>
							))}
							{questions.length > PER_PAGE && (
								<Pagination
									page={page}
									pageNumbers={pageNumbers}
									searchInput={searchInput}
									className="mt-8 mb-0"
								/>
							)}
						</section>
					)}
					<section className="mt-16 space-y-6">
						{subTopicLightningWidget?.addLightningWidget && (
							<LightningTileWrapper
								title={subTopicLightningWidget.lightningWidgetTitle}
								content={
									subTopicLightningWidget.lightningWidgetContentNode.childMdx.body
								}
								defaultQuestion={subTopicLightningWidget.lightningWidgetQuestion}
							/>
						)}
					</section>
					<section className="flex flex-col pt-12 mt-12 mb-8 border-t md:items-stretch md:flex-row">
						<div className="w-auto">
							<LogSupport />
						</div>
						<div className="w-auto">
							<PhoneContact />
						</div>
					</section>
				</div>
			</div>
		</DefaultLayout>
	);
};

export default SupportHubSubTopic;

export const query = graphql`
	query supportProductPage($locale: String!, $subTopic: String!, $topicId: String!) {
		datoCmsSupportTopic(originalId: { eq: $topicId }) {
			webinar {
				monday
				tuesday
				wednesday
				thursday
				friday
				time
				name
				marketoFormId
				descriptionNode {
					childMdx {
						body
					}
				}
			}
		}
		datoCmsSupportSubTopic(originalId: { eq: $subTopic }, locale: { eq: $locale }) {
			seoMetaTags {
				...GatsbyDatoCmsSeoMetaTags
			}
			_allSeoLocales {
				locale
			}
		}
		allDatoCmsSupportSubTopic(filter: { originalId: { eq: $subTopic } }) {
			nodes {
				slug
				id
				locale
			}
		}
		allDatoCmsSupportQuestion(
			filter: {
				locale: { eq: $locale }
				topic: { originalId: { eq: $topicId } }
				subTopic: { originalId: { eq: $subTopic } }
			}
		) {
			nodes {
				showInAu
				showInCa
				showInIe
				showInNz
				showInUk
				...QuestionCard
			}
		}
		lightningWidget: allDatoCmsSupportQuestion(
			filter: { subTopic: { originalId: { eq: $subTopic } } }
		) {
			nodes {
				subTopic {
					lightningWidget {
						addLightningWidget
						lightningWidgetTitle
						lightningWidgetContentNode {
							childMdx {
								body
							}
						}
						lightningWidgetQuestion
					}
				}
			}
		}
	}
`;
