import React, { useEffect, useRef, useState } from 'react';
import { graphql, navigate } from 'gatsby';
import Tabs from 'bright-components/dist/components/Tabs';
import TabList from 'bright-components/dist/components/Tabs/TabList';
import Tab from 'bright-components/dist/components/Tabs/Tab';
import TabPanel from 'bright-components/dist/components/Tabs/TabPanel';

import qs from 'qs';
import { useLocation } from '@reach/router';
import { useLocale } from '../context/Locale';
import DefaultLayout from '../layouts/Default';
import CopyBlock from '../components/CopyBlock';
import TermsProvider from '../components/CopyBlock/TermsProvider';
import CollapsibleTree from '../components/CollapsibleTree';
import HrefLang from '../components/HrefLang';
import SEO from '../components/SEO';

import supportHeroBg from '../images/support/hub-hero.svg';
import ScrollButton from '../icons/scrollTopButton.svg';

const idToTabIndex = {
	terms: 0,
	privacy: 1,
	'data-security': 2,
	'data-processing': 3,
	dpia: 4,
};

const formatToc = (nodes) =>
	nodes?.map((node) => ({
		text: node.title,
		href: node.url,
		children: formatToc(node.items),
	}));

const TabSection = ({ tableOfContentsItems, bodyMdx }) => (
	<div className="flex flex-col md:flex-row ">
		<div className="w-full pr-4 mb-6 md:overflow-y-auto md:h-[75vh] md:w-1/4 md:sticky top-24">
			<h4 className="mb-4 font-bold text-gray-500">Table of contents</h4>
			<CollapsibleTree items={formatToc(tableOfContentsItems)} />
		</div>
		<div className="w-full pl-0 md:w-3/4 md:pl-10">
			<CopyBlock>{bodyMdx}</CopyBlock>
		</div>
	</div>
);

const Terms = ({ data }) => {
	const location = useLocation();
	const { tab } = qs.parse(location.search, { ignoreQueryPrefix: true });
	const getTabIndex = idToTabIndex[tab] || 0;
	const [currentTab, setCurrentTab] = useState(0);

	const {
		termsContentNode,
		privacyContentNode,
		dataSecurityContentNode,
		dataProcessingContentNode,
		dpiaContentNode,
		termsHeading,
		privacyHeading,
		dataSecurityHeading,
		dataProcessingHeading,
		dpiaHeading,
		termsLastUpdated,
		changesLogNode,
		_allTermsLastUpdatedLocales,
	} = data.datoCmsTerm;

	const hasPrivacyContent = privacyContentNode.childMdx.rawBody !== '';
	const hasDataSecurityContent = dataSecurityContentNode.childMdx.rawBody !== '';
	const hasDataProcessingContent = dataProcessingContentNode.childMdx.rawBody !== '';
	const hasDpiaContent = dpiaContentNode.childMdx.rawBody !== '';
	const hasChangeLog = changesLogNode.childMdx.rawBody !== '';

	const checkTabHasEmptyContent = (tabIndex) =>
		(tabIndex === 1 && !hasPrivacyContent) ||
		(tabIndex === 2 && !hasDataSecurityContent) ||
		(tabIndex === 3 && !hasDataProcessingContent) ||
		(tabIndex === 4 && !hasDpiaContent);

	const navigateToFirstTab = () => {
		const getFirstTabId = Object.keys(idToTabIndex).find((key) => idToTabIndex[key] === 0);
		navigate(`?tab=${getFirstTabId}`);
	};

	useEffect(() => {
		if (checkTabHasEmptyContent(getTabIndex)) {
			navigateToFirstTab();
		} else {
			setCurrentTab(getTabIndex);
		}
	}, [getTabIndex]); // eslint-disable-line react-hooks/exhaustive-deps

	const targetScrollElement = useRef(null);

	const scrollToElement = () => targetScrollElement.current.scrollIntoView();

	const getHeading = () => {
		if (tab === 'terms') return termsHeading;
		if (tab === 'privacy') return privacyHeading;
		if (tab === 'data-security') return dataSecurityHeading;
		if (tab === 'data-processing') return dataProcessingHeading;
		if (tab === 'dpia') return dpiaHeading;

		return termsHeading;
	};
	const locale = useLocale();

	return (
		<>
			<HrefLang locales={_allTermsLastUpdatedLocales} slug="terms" />
			<SEO
				path={location.pathname}
				locale={locale}
				title="Data Processing Policy"
				description="User Terms of Service and Privacy Policy"
			/>
			<div className="relative" ref={targetScrollElement}>
				<section className="flex flex-row items-start h-auto max-w-screen-xl mx-auto overflow-hidden bg-right-top bg-no-repeat bg-contain border-b border-gray-300 lg:h-64">
					<div className="max-w-2xl py-8">
						<h1 className="mb-3 text-2xl font-bold leading-tight lg:text-4xl md:mt-12">
							{getHeading()}
						</h1>

						<p className="text-sm text-gray-500">
							*The T&amp;C&apos;s may from time to time change, see clause 1.iv), in
							which case the terms and conditions on the website prevail.
						</p>
					</div>
					<img
						className="hidden w-4/12 mt-8 ml-auto md:block"
						src={supportHeroBg}
						alt=""
						aria-hidden
					/>
				</section>
				<Tabs
					selectedIndex={currentTab}
					onSelect={(index) => {
						setCurrentTab(index);
						const getTabId = Object.keys(idToTabIndex).find(
							(key) => idToTabIndex[key] === index,
						);

						return locale === 'en'
							? navigate(`/terms/?tab=${getTabId}`)
							: navigate(`${location.pathname}?tab=${getTabId}`);
					}}
				>
					<TabList className="flex justify-center " style={{ paddingTop: '10px' }}>
						<Tab
							className="w-full pt-2 text-center sm:w-auto sm:text-left "
							style={{ paddingBottom: '1rem' }}
							data-target="?tab=terms"
						>
							Terms
						</Tab>
						{hasPrivacyContent && (
							<Tab
								className="w-full pt-2 text-center sm:w-auto sm:text-left "
								style={{ paddingBottom: '1rem' }}
								data-target="?tab=privacy"
							>
								Privacy
							</Tab>
						)}
						{hasDataSecurityContent && (
							<Tab
								className="w-full pt-2 text-center sm:w-auto sm:text-left "
								style={{ paddingBottom: '1rem' }}
								data-target="?tab=data-security"
							>
								Data Security
							</Tab>
						)}
						{hasDataProcessingContent && (
							<Tab
								className="w-full pt-2 text-center sm:w-auto sm:text-left "
								style={{ paddingBottom: '1rem' }}
								data-target="?tab=data-processing"
							>
								Data Processing
							</Tab>
						)}
						{hasDpiaContent && (
							<Tab
								className="w-full pt-2 text-center sm:w-auto sm:text-left "
								style={{ paddingBottom: '1rem' }}
								data-target="?tab=dpia"
							>
								DPIA
							</Tab>
						)}
					</TabList>

					<div className="md:pt-8 ">
						<div className="md:mb-8">
							<h1 className="pt-8 pb-8 text-xl font-bold lg:text-3xl md:pt-0">
								Version Date: {termsLastUpdated}
							</h1>
						</div>

						<TabPanel>
							<TabSection
								tableOfContentsItems={
									termsContentNode.childMdx.tableOfContents.items
								}
								bodyMdx={termsContentNode.childMdx.body}
							/>
						</TabPanel>
						<TabPanel>
							<TabSection
								tableOfContentsItems={
									privacyContentNode.childMdx.tableOfContents.items
								}
								bodyMdx={privacyContentNode.childMdx.body}
							/>
						</TabPanel>
						<TabPanel>
							<TabSection
								tableOfContentsItems={
									dataSecurityContentNode.childMdx.tableOfContents.items
								}
								bodyMdx={dataSecurityContentNode.childMdx.body}
							/>
						</TabPanel>
						<TabPanel>
							<TabSection
								tableOfContentsItems={
									dataProcessingContentNode.childMdx.tableOfContents.items
								}
								bodyMdx={dataProcessingContentNode.childMdx.body}
							/>
						</TabPanel>
						<TabPanel>
							<TabSection
								tableOfContentsItems={
									dpiaContentNode.childMdx.tableOfContents.items
								}
								bodyMdx={dpiaContentNode.childMdx.body}
							/>
						</TabPanel>
						{hasChangeLog && (
							<div className="flex justify-end">
								<div className="w-full p-6 mt-8 border-l-4 border-brand-400 md:w-3/4 bg-brand-100">
									<h2 className="pb-3 text-xl font-bold">Changelog</h2>
									<CopyBlock>{changesLogNode.childMdx.body}</CopyBlock>
								</div>
							</div>
						)}
					</div>
				</Tabs>
				<div className="fixed bottom-[6.5rem] right-[2.35rem]">
					<ScrollButton
						width="56"
						height="56"
						onClick={scrollToElement}
						className="duration-500 cursor-pointer pointer-events-auto text-secondary-300 hover:text-secondary-400"
					/>
				</div>
			</div>
		</>
	);
};

export const query = graphql`
	query termsPage($id: String, $locale: String!) {
		datoCmsTerm(locale: { eq: $locale }, id: { eq: $id }) {
			_allTermsLastUpdatedLocales {
				locale
			}
			termsLastUpdated
			termsHeading
			privacyHeading
			dataSecurityHeading
			dataProcessingHeading
			dpiaHeading
			termsContentNode {
				childMdx {
					body
					tableOfContents(maxDepth: 3)
					rawBody
				}
			}
			privacyContentNode {
				childMdx {
					body
					tableOfContents(maxDepth: 3)
					rawBody
				}
			}
			dataSecurityContentNode {
				childMdx {
					body
					tableOfContents(maxDepth: 3)
					rawBody
				}
			}
			dataProcessingContentNode {
				childMdx {
					body
					tableOfContents(maxDepth: 3)
					rawBody
				}
			}
			dpiaContentNode {
				childMdx {
					body
					tableOfContents(maxDepth: 3)
					rawBody
				}
			}
			changesLogNode {
				childMdx {
					body
					rawBody
				}
			}
		}
	}
`;

const TermsWithLayout = (props) => (
	<DefaultLayout>
		<TermsProvider>
			<Terms {...props} />
		</TermsProvider>
	</DefaultLayout>
);

export default TermsWithLayout;
