import React, { useState, useEffect, useRef } from 'react';
import loadScript from 'load-script2';
import { Formik, Form } from 'formik';

import Spinner from '@brighthr/component-spinner';
import useOnScreen from '../../../utils/useOnScreen';
import submitMarketoForm from '../submitMarketoForm';

export const STATUS = {
	INITIALISING: 'INITIALISING',
	INITIALISED: 'INITIALISED',
	SUBMITTING: 'SUBMITTING',
	FAILED: 'SUBMITTED',
	SUBMITTED: 'SUBMITTED',
};

const isScriptLoaded = () => !!window.MktoForms2;

const loadMarketoForms = () => {
	const loaded = isScriptLoaded();

	if (loaded) {
		return Promise.resolve();
	}

	return (
		loadScript('https://app-lon04.marketo.com/js/forms2/js/forms2.min.js')
			// eslint-disable-next-line no-console
			.catch((err) => console.error(err))
	);
};

const MarketoForm = ({ successMessage, children, formId, initialValues, preload }) => {
	const [formStatus, setStatus] = useState(STATUS.INITIALISING);
	const [ref, onScreen] = useOnScreen();
	const initialised = useRef(false);

	useEffect(() => {
		const initialiseForm = () => {
			if (initialised.current === formId || !window.MktoForms2) return;

			initialised.current = formId;

			window.MktoForms2.loadForm('//app-lon04.marketo.com', '217-MIC-854', formId, () => {
				setStatus(STATUS.INITIALISED);
			});
		};

		const loadForm = async () => {
			await loadMarketoForms();

			initialiseForm();
		};

		if (onScreen || preload) {
			loadForm();
		}
	}, [formId, onScreen, preload]);

	return formStatus === STATUS.SUBMITTED ? (
		successMessage
	) : (
		<div ref={ref}>
			{/* <form id={`mktoForm_${formId}`} className="hidden" /> */}

			{formStatus === STATUS.INITIALISING && (
				<div className="flex justify-center">
					<Spinner
						ariaLabel="processing request"
						className="stroke-primary-700"
						size="xl"
					/>
				</div>
			)}
			{formStatus === STATUS.INITIALISED && (
				<Formik
					initialValues={initialValues}
					onSubmit={async (values) => {
						await submitMarketoForm(values, formId);
						setStatus(STATUS.SUBMITTED);
					}}
				>
					<Form>{children}</Form>
				</Formik>
			)}
		</div>
	);
};

export default MarketoForm;
