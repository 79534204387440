import React from 'react';

const DemoCTA = ({ showModal, primaryCtaText }) => (
	<button
		onClick={showModal}
		type="button"
		className="block px-6 py-3 mx-auto font-bold text-center text-white transition duration-300 ease-in-out border rounded-full cursor-pointer border-secondary-300 hover:border-secondary-400 md:inline-block bg-secondary-300 hover:bg-secondary-400"
	>
		{primaryCtaText || 'Get a free tailored demo'}
	</button>
);

export default DemoCTA;
