import React from 'react';
import cn from 'classnames';
import Container from '../../Container';
import CopyBlock from '../../CopyBlock';

const Card = ({ title, children }) => (
	<div className="mb-8 overflow-hidden bg-white rounded-lg shadow-md">
		<h4 className="px-6 py-4 text-xl font-bold text-white bg-brand-500">{title}</h4>
		<div className="px-6 py-4">{children}</div>
	</div>
);

const FeatureCards = ({ title, descriptionNode, features }) => {
	const cols =
		(features.length % 3 === 0 && 'md:grid-cols-3') ||
		(features.length % 2 === 0 && 'md:grid-cols-2') ||
		'grid-cols-1';

	return (
		<Container className="">
			{title && <h3 className="mb-2 text-2xl font-bold md:text-3xl">{title}</h3>}
			{descriptionNode && (
				<div className="mb-6">
					<CopyBlock>{descriptionNode.childMdx.body}</CopyBlock>
				</div>
			)}

			<ul className={cn('grid grid-flow-row gap-8', cols)}>
				{features.map((f) => (
					<li>
						<Card title={f.featureName}>
							<CopyBlock>{f.cardDescriptionNode.childMdx?.body}</CopyBlock>
						</Card>
					</li>
				))}
			</ul>
		</Container>
	);
};

export default FeatureCards;
