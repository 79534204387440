import React, { useState } from 'react';
import cn from 'classnames';
import Button from 'bright-components/dist/components/Button/Button';
import styled from 'styled-components';

import VideoModal from '../../VideoModal';
import AppStoreFull from '../../../images/vectors/AppStoreFull.svg';
import PlayStoreFull from '../../../images/vectors/PlayStoreFull.svg';
import ButtonLinkBase from '../../ButtonLink';

export const ButtonGrid = ({ children }) => (
	<div className="mb-4 overflow-hidden">
		<div className="flex flex-row flex-wrap -m-2 ">{children}</div>
	</div>
);
export const ButtonGridLink = styled(ButtonLinkBase).attrs(() => ({
	className: 'm-2 w-full',
}))`
	max-width: calc(50% - 1rem);
`;
export const ButtonRow = ({ children }) => (
	<div>
		{React.Children.map(children, (child) =>
			React.cloneElement(child, {
				className: 'w-full md:w-auto mr-4 last:mr-0',
			}),
		)}
	</div>
);
export const VideoButton = ({ children, videoId, className, color = 'white' }) => {
	const [showVideo, setVideoShow] = useState(false);

	return (
		<>
			<Button
				color={color}
				outline
				onClick={() => setVideoShow(true)}
				className={cn('mb-4', className)}
			>
				<svg
					width="11"
					height="13"
					viewBox="0 0 11 13"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
					className="inline-block mr-2 align-middle"
				>
					<path d="M11 6.50016L0 0.427246V12.5731L11 6.50016Z" fill="white" />
				</svg>
				<span className="inline-block align-middle">{children}</span>
			</Button>
			{showVideo && <VideoModal youtubeId={videoId} onClose={() => setVideoShow(false)} />}
		</>
	);
};
export const AppStoreButton = ({ href, className }) => (
	<a className={cn('mb-4 h-10 inline-block align-middle', className)} href={href}>
		<img loading="lazy" src={AppStoreFull} alt="App Store Logo" className="w-32 h-10" />
	</a>
);
export const PlayStoreButton = ({ href, className }) => (
	<a className={cn('mb-4 h-10 inline-block align-middle', className)} href={href}>
		<img loading="lazy" src={PlayStoreFull} alt="Play Store Logo" className="w-32 h-10" />
	</a>
);
export const ButtonLink = (props) => (
	<ButtonLinkBase {...props} className={cn('mb-4', props.className)} />
);
