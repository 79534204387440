import React from 'react';
import LinkButton from '@brighthr/component-linkbutton';
import Container from '../Container';
import IlloWebinar from '../../icons/IlloWebinar.svg';

const ServiceWebinarBanner = () => (
	<Container>
		<div className="flex flex-col mt-4 flex-nowrap lg:flex-row lg:justify-between">
			<div className="mb-8 text-center lg:max-w-2xl lg:text-left lg:mr-10 lg:mb-0">
				<h3 className="mb-3 text-xl font-bold leading-7 text-gray-900">
					Register for our product tour webinar
				</h3>
				<p className="mb-6 text-gray-900">
					Find out how BrightHR can help your business save time, save money and take the
					hassle out of HR
				</p>
				<div className="w-full md:w-fit">
					<LinkButton
						href="https://pages.brighthr.com/getting-started-webinars.html?_ga=2.18160121.1978125115.1636917241-1803464036.1624351293"
						text="Sign up"
						size="lg"
					/>
				</div>
			</div>
			<div className="lg:shrink-0 lg:ml-auto">
				<IlloWebinar className="m-auto" />
			</div>
		</div>
	</Container>
);

export default ServiceWebinarBanner;
