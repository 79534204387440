import Cookies from 'js-cookie';

const trackAffiliate = (affId) => {
	Cookies.set('affiliateId', affId, {
		expires: 30,
	});
};

const getAffiliate = () => Cookies.get('affiliateId');

export { trackAffiliate, getAffiliate };
