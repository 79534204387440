import React, { useRef, useEffect, useState } from 'react';
import cn from 'classnames';
import { GatsbyImage } from 'gatsby-plugin-image';
import CopyBlock from '../../CopyBlock';
import Container from '../../Container';

const VerticalImageScrollWithCopy = ({
	verticalCarouselParent,
	title,
	subtitle,
	increasePadding,
}) => {
	const [active, setActive] = useState(0);
	const itemsRef = useRef([]);
	const imagesRef = useRef([]);

	useEffect(() => {
		const observerCallback = (entries) => {
			entries.forEach((entry) => {
				if (entry.isIntersecting) {
					setActive(parseInt(entry.target.getAttribute('data-id'), 10));
				}
			});
		};

		itemsRef.current.forEach((i) => {
			if (i) {
				const observer = new IntersectionObserver(
					(entries) => {
						observerCallback(entries, observer, i);
					},
					{ rootMargin: '0px 0px -50%', threshold: 0.8 },
				);
				observer.observe(i);
			}
		});
	}, [active]);

	return (
		<Container>
			<section className="lg:p-12">
				<div className="w-full m-auto mb-16 md:w-3/4">
					{title && (
						<h2 className="my-8 text-2xl font-bold leading-none text-center text-black md:text-3xl">
							{title}
						</h2>
					)}
					{subtitle && (
						<h3 className="mb-4 leading-7 text-center text-black">{subtitle}</h3>
					)}
				</div>
				<div className="relative">
					{verticalCarouselParent.map((el, index) => (
						<div
							key={el.id}
							className="flex flex-col items-center md:items-start md:flex-row"
						>
							{el.blocktype === 'ImageWithCopy' && (
								<>
									<div
										className={cn('w-full mb-0  md:w-1/2 lg:pt-8', {
											'md:mb-72': increasePadding,
											'md:mb-24': !increasePadding,
										})}
										// eslint-disable-next-line no-return-assign
										ref={(itemRef) => (itemsRef.current[index] = itemRef)}
										data-id={`${index}`}
									>
										<CopyBlock>{el.textNode.childMdx.body}</CopyBlock>
									</div>

									<div className="py-8 sm:py-4 md:py-0 md:absolute md:top-0 md:right-0 xl:right-8 md:h-full">
										{el.imagePosition === 'Right' && (
											<div
												className={cn(
													'sticky md:top-28 xxl:top-48 transition-all duration-300 mt-8 md:my-0',
													{
														'opacity-100': index === active,
														'opacity-100 md:opacity-0':
															index !== active,
													},
												)}
												// eslint-disable-next-line no-return-assign
												ref={(imageRef) =>
													(imagesRef.current[index] = imageRef)
												}
											>
												<GatsbyImage
													image={el.imageWithCopyImage.gatsbyImageData}
													alt="test"
													className="text-image md:w-72 xl:w-[28rem]"
												/>
											</div>
										)}
									</div>
								</>
							)}
						</div>
					))}
				</div>
			</section>
		</Container>
	);
};

export default VerticalImageScrollWithCopy;
