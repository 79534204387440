import React from 'react';

import { Helmet } from 'react-helmet';
import CopyBlockProvider from '../../components/CopyBlock/provider';
import Optimize from '../../components/Optimize';
import { LocaleProvider } from '../../context/Locale';
import BrowserWarning from '../../components/BrowserWarning';
import setUtmSessionData from '../../utils/setUtmSessionData';

const MasterLayout = ({ children }) => {
	setUtmSessionData();

	return (
		<div className="bg-white">
			<Optimize />
			<Helmet>
				<link rel="preconnect" href="https://www.facebook.com" crossOrigin="true" />
				<link rel="preconnect" href="https://217-mic-854.mktoresp.com" crossOrigin="true" />
				<link rel="preconnect" href="https://ruler.nyltx.com" crossOrigin="true" />
				<link rel="preconnect" href="https://connect.facebook.net" crossOrigin="true" />

				<meta
					name="facebook-domain-verification"
					content="4qzf07w0zgnaetofcka91kswhagv5e"
				/>
			</Helmet>
			<LocaleProvider>
				<CopyBlockProvider>{children}</CopyBlockProvider>
				<div className="fixed bottom-0 z-50 w-full">
					<BrowserWarning />
				</div>
				<form id="mktoForm_2796" style={{ display: 'none' }} />
			</LocaleProvider>
		</div>
	);
};

export default MasterLayout;
