import React from 'react';
import colors from 'bright-components/dist/constants/colors';
import styled from 'styled-components';

const Ribbon = styled(({ className, children }) => (
	<h4 className={className}>
		<span>{children}</span>
	</h4>
))`
	font-weight: normal;
	display: inline-block;
	height: 36px;
	line-height: 30px;
	vertical-align: middle;
	position: relative;
	color: #fff;
	text-align: center;
	background: ${colors.primary};
	font-size: 22px;
	padding: 0 16px;
	width: ${(props) => props.width || 'auto'};

	&:before,
	&:after {
		content: '';
		position: absolute;
		width: 0;
		bottom: 0;
		display: inline-block;
		vertical-align: middle;
	}

	&:before {
		left: 0px;
		border-style: solid;
		border-width: 18px 0 18px 10px;
		border-color: transparent transparent transparent #ffffff;
	}

	&:after {
		right: 0px;
		border-style: solid;
		border-width: 18px 10px 18px 0;
		border-color: transparent #ffffff transparent transparent;
	}

	span {
		display: inline-block;
		vertical-align: middle;
	}
`;

export default Ribbon;
