import React from 'react';

const FormFooterLinks = ({ className = '', textLinkColor = 'text-brand-500' }) => (
	<div className={`${className}`}>
		<p className="mt-4 mb-1 ">
			Already a BrightHR customer? Get{' '}
			<a
				className={`font-bold underline ${textLinkColor}`}
				href="https://www.brighthr.com/help/"
			>
				support
			</a>{' '}
			or{' '}
			<a className={`font-bold underline ${textLinkColor}`} href="https://app.brighthr.com">
				login
			</a>{' '}
			today.
		</p>

		<p className="mb-1 leading-5">
			View our privacy notice&nbsp;
			<a
				href="https://www.brighthr.com/terms?tab=privacy"
				target="_blank"
				rel="noopener noreferrer"
				className={`font-bold underline ${textLinkColor}`}
			>
				here.
			</a>
		</p>
		<p className="leading-5 ">
			You can manage your preferences any time&nbsp;
			<a
				href="https://pages.brighthr.com/email-preferences-2.html"
				target="_blank"
				rel="noopener noreferrer"
				className={`font-bold underline ${textLinkColor}`}
			>
				here.
			</a>
		</p>
	</div>
);

export default FormFooterLinks;
