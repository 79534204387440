import React from 'react';
import MultiParagraphBlock from '../../MultiParagraphBlock';
import FeatureImageWithCopy from '../FeatureImageWithCopy';

const ProductInfo = ({
	productInfoTitle,
	productInfoSubtitle,
	productInfoCopyHeader,
	productInfoCopyNode,
	productInfoImagePosition,
	productInfoImage,
	productInfoLowerCopyHeader,
	productInfoLowerCopy,
}) => (
	<div className="container justify-center p-6 mx-auto text-center">
		<div className="mx-auto max-w-7xl">
			<div className="my-12 text-3xl font-semibold md:text-4xl md:font-bold md:my-18">
				<h2 className="max-w-2xl px-6 mx-auto mb-12 text-3xl font-semibold text-center md:text-4xl md:font-bold md:mb-20">
					<MultiParagraphBlock content={productInfoTitle} />
				</h2>
			</div>

			{productInfoSubtitle && (
				<h3 className="mb-12 text-2xl font-semibold md:text-3xl md:mb-18">
					{productInfoSubtitle}
				</h3>
			)}
		</div>

		{productInfoImage && (
			<div className="mb-12 md:mb-18">
				<FeatureImageWithCopy
					imgSrc={productInfoImage?.gatsbyImageData}
					alt={productInfoImage?.alt}
					header={productInfoCopyHeader}
					copy={productInfoCopyNode}
					imagePosition={productInfoImagePosition}
				/>
			</div>
		)}

		<div className="mx-auto max-w-7xl">
			{productInfoLowerCopyHeader && (
				<h3 className="mb-8 text-2xl font-bold">{productInfoLowerCopyHeader}</h3>
			)}

			<div className="text-gray-700">
				<MultiParagraphBlock content={productInfoLowerCopy} />
			</div>
		</div>
	</div>
);

export default ProductInfo;
