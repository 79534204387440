import React, { useEffect, useMemo, useState } from 'react';
import Button from '@brighthr/component-button';
import { useFormik } from 'formik';
import loadScript from 'load-script2';
import qs from 'qs';
import { useLocation } from '@reach/router';
import FormFooterLinks from '../FormFooterLinks';
import submitMarketoForm from '../submitMarketoForm';
import Toast from '../../Toast';
import ReviewBadges from '../../ReviewBadges';
import LocaleContent from '../../LocaleContent';
import { useLocale } from '../../../context/Locale';
import getLeadSource from '../../../utils/leadSource';

const ContactForm = ({
	decryptFirstName,
	decryptLastName,
	decryptPhone,
	decryptEmail,
	Company,
}) => {
	const pagelocale = useLocale();
	const [firstSubmit, setFirstSubmit] = useState();
	const [reason, setReason] = useState();
	const { search } = useLocation();

	const getLegacySource = (locale) => {
		if (locale === 'en') {
			return 'Web - Contact Us';
		}

		return getLeadSource(locale, 'Contact Us');
	};

	const leadSource = useMemo(() => getLegacySource(pagelocale), [pagelocale]);
	const locale = useLocale();

	useEffect(() => {
		// Preloads the Marketo form for background submission
		const loadForm = () =>
			loadScript('//app-lon04.marketo.com/js/forms2/js/forms2.min.js')
				.then(() => {
					window.MktoForms2.loadForm(
						'//app-lon04.marketo.com',
						'217-MIC-854',
						2796,
						(form) => {
							form.onSuccess(() => false);
						},
					);
				})
				.catch(() => {});

		if (window.requestIdleCallback) {
			window.requestIdleCallback(loadForm);
		} else {
			setTimeout(loadForm);
		}
	}, []);

	useEffect(() => {
		const { Reason } = qs.parse(search, { ignoreQueryPrefix: true });

		if (!Reason) {
			return;
		}

		const removeSlash = (string) => {
			const slashFinder = string.slice(-1);

			if (slashFinder === '/') {
				return string.slice(0, -1);
			}
			return string;
		};

		const ReasonNoSlash = removeSlash(Reason);

		const allowedOptions = ['Support', 'Sales', 'Press'];

		if (!allowedOptions.includes(ReasonNoSlash)) {
			return;
		}

		setReason(ReasonNoSlash);
	}, [search]);

	const { status, isSubmitting, values, setStatus, handleSubmit, handleChange } = useFormik({
		enableReinitialize: true,
		initialValues: {
			FirstName: decryptFirstName || '',
			LastName: decryptLastName || '',
			Company: Company || '',
			Phone: decryptPhone || '',
			Email: decryptEmail || '',
			Contact_Us_Reason__c: decryptEmail ? 'Support' : reason || '',
			Contact_Us_Comments__c: '',
			LeadSource: leadSource,
		},
		onSubmit: async (formData) => {
			submitMarketoForm(formData)
				.then(() => {
					setStatus('submitted');
				})
				.catch(() => {
					setStatus('error');
				});
		},
	});

	useEffect(() => {
		if (status) {
			setFirstSubmit(true);
		}
	}, [status]);

	return (
		<>
			<form onSubmit={handleSubmit} className={`${firstSubmit ? 'hidden' : ''}`}>
				<div className="flex flex-col max-w-full mb-6">
					<div className="mb-2 space-y-3">
						<div className="flex flex-wrap w-full md:flex-nowrap">
							<div className="w-full md:pr-3">
								<label
									htmlFor="ContactFirstName"
									className="block w-full font-bold text-left text-white"
								>
									First name
								</label>
								<input
									id="ContactFirstName"
									name="FirstName"
									placeholder="Jane"
									className="w-full px-3 py-2 border-2 border-gray-700 rounded-md"
									required
									value={values.FirstName}
									onChange={handleChange}
								/>
							</div>

							<div className="w-full md:pl-3">
								<label
									htmlFor="ContactLastName"
									className="block w-full font-bold text-left text-white"
								>
									Last name
								</label>
								<input
									id="ContactLastName"
									name="LastName"
									placeholder="Smith"
									className="w-full px-3 py-2 border-2 border-gray-700 rounded-md"
									required
									value={values.LastName}
									onChange={handleChange}
								/>
							</div>
						</div>

						<div className="flex flex-wrap w-full md:flex-nowrap">
							<div className="w-full md:pr-3">
								<label
									htmlFor="ContactCompany"
									className="block w-full font-bold text-left text-white"
								>
									Company
								</label>
								<input
									id="ContactCompany"
									name="Company"
									placeholder="ACME inc."
									className="w-full px-3 py-2 border-2 border-gray-700 rounded-md"
									required
									value={values.Company}
									onChange={handleChange}
								/>
							</div>
							<div className="w-full md:pl-3">
								<label
									htmlFor="ContactPhoneNumber"
									className="block w-full font-bold text-left text-white"
								>
									Phone number
								</label>
								<input
									id="ContactPhoneNumber"
									name="Phone"
									type="tel"
									placeholder="01632 960 410"
									className="w-full px-3 py-2 border-2 border-gray-700 rounded-md"
									pattern="[0-9\s]{7,16}"
									title="Enter a valid telephone number between 7 and 16 digits."
									required
									value={values.Phone}
									onChange={handleChange}
								/>
							</div>
						</div>
						<div className="flex flex-wrap w-full md:flex-nowrap">
							<div className="w-full">
								<label
									htmlFor="ContactEmail"
									className="block w-full font-bold text-left text-white"
								>
									Email
								</label>
								<input
									id="ContactEmail"
									name="Email"
									placeholder="jane.smith@acmeinc.com"
									className="w-full px-3 py-2 border-2 border-gray-700 rounded-md"
									type="email"
									required
									value={values.Email}
									onChange={handleChange}
								/>
							</div>
						</div>
						<div className="flex flex-wrap w-full md:flex-nowrap">
							<div className="w-full">
								<label
									htmlFor="Contact_Us_Reason__c"
									className="block w-full font-bold text-left text-white"
								>
									Reason
								</label>
								<select
									id="Contact_Us_Reason__c"
									name="Contact_Us_Reason__c"
									className="w-full p-2 border-2 border-gray-700 rounded-md"
									required
									value={values.Contact_Us_Reason__c}
									onChange={handleChange}
								>
									<option value="" disabled hidden>
										Choose a subject
									</option>
									<option value="Sales">Sales</option>
									<option value="Support">Support</option>
									<option value="Press">Press</option>
								</select>
							</div>
						</div>
						<div className="flex flex-wrap w-full md:flex-nowrap">
							<div className="w-full">
								<label
									htmlFor="Contact_Us_Comments__c"
									className="block w-full font-bold text-left text-white"
								>
									Message
								</label>
								<textarea
									placeholder="Insert message here.."
									id="Contact_Us_Comments__c"
									name="Contact_Us_Comments__c"
									required
									className="w-full h-32 px-3 py-2 border-2 border-gray-700 rounded-md"
									value={values.Contact_Us_Comments__c}
									onChange={handleChange}
								/>
							</div>
						</div>
						<div className="flex flex-col w-full">
							<Button type="submit" disabled={isSubmitting} size="lg" text="Submit" />
						</div>

						<FormFooterLinks
							className="mt-2 text-xs text-left text-white"
							textLinkColor="text-white"
							locale={pagelocale}
						/>

						<div className="flex flex-row flex-wrap items-center mt-2">
							<div className="flex flex-row flex-wrap items-center justify-center mt-2 lg:justify-start">
								<LocaleContent showInLocales={['en', 'ie']}>
									<ReviewBadges
										locale={locale}
										pageName="contact"
										width="178"
										classes="ml-6"
										g2AwardClasses="max-w-[100px] ml-4 mt-4 lg:mt-0"
									/>
								</LocaleContent>
								<LocaleContent showInLocales={['ca', 'au', 'nz']}>
									<ReviewBadges
										locale={locale}
										pageName="contact"
										width={locale === 'ca' ? '110' : '170'}
										classes="ml-6"
										g2AwardClasses="max-w-[100px] ml-4 mt-4 lg:mt-0"
									/>
								</LocaleContent>
							</div>
						</div>
					</div>
				</div>
			</form>
			<Toast
				status={status}
				setStatus={setStatus}
				successMessage="Thanks for getting in Touch!"
				errorMessage="There was an error, please try again."
			/>
		</>
	);
};

export default ContactForm;
