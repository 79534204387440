document.addEventListener(
	'click',
	function hashFix(e) {
		// loop parent nodes from the target to the delegation node
		for (let { target } = e; target && target !== this; target = target.parentNode) {
			if (target.matches('a')) {
				const href = target.getAttribute('href');
				if (href.startsWith('#')) {
					e.preventDefault();
					// Uses attribute selctor for leading number support
					document.querySelector(`[id='${href.replace('#', '')}']`).scrollIntoView({
						behavior: 'smooth',
						block: 'start',
						inline: 'nearest',
					});
					window.history.pushState(null, null, href);
				}

				break;
			}
		}
	},
	false,
);
