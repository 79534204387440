import { css } from 'styled-components';
import Breakpoints from 'bright-components/dist/constants/breakpoints';
import getValueAndUnit from 'polished/lib/helpers/getValueAndUnit';

const mediaMin = Object.keys(Breakpoints).reduce((acc, label) => {
	acc[label] = (...args) => css`
		@media screen and (min-width: ${Breakpoints[label]}) {
			${css(...args)}
		}
	`;

	return acc;
}, {});

const mediaMax = Object.keys(Breakpoints).reduce((acc, label) => {
	const [value, unit] = getValueAndUnit(Breakpoints[label]);

	acc[label] = (...args) => css`
		@media screen and (max-width: ${value - 1}${unit}) {
			${css(...args)}
		}
	`;

	return acc;
}, {});

export default mediaMin;
export { mediaMin, mediaMax };
