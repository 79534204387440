export default function formatPathForCanonical(initialPath = '') {
	let path = initialPath;

	if (path.startsWith('/')) {
		path = path.slice(1);
	}

	if (path.length && !path.endsWith('/')) {
		path += '/';
	}

	return path;
}
