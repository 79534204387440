import React, { useState, useRef, useEffect } from 'react';
import { useLocation } from '@reach/router';
import { nanoid } from 'nanoid';
import throttle from 'lodash.throttle';
import cn from 'classnames';
import Icon from '@brighthr/component-icon';
import ArticleProvider from '../CopyBlock/ArticleProvider';
import Breadcrumbs, { Breadcrumb } from '../Breadcrumbs';
import MultiParagraphBlock from '../MultiParagraphBlock';
import CarouselBg from '../../images/blog/carousel-bg.svg';
import Facebook from '../../icons/FacebookCircle.svg';
import LinkedIn from '../../icons/LinkedInCircle.svg';
import Twitter from '../../icons/TwitterCircle.svg';
import { buildLink } from '../../utils/locale';
import Newsletter from '../Footer/Components/newsletter';
import scrollDirection from '../../utils/scrollDirection';

import SEO from '../SEO';
import { useLocale } from '../../context/Locale';
import NewsCard from '../NewsCard';

const NewsArticlePage = ({
	articleImage,
	title,
	subHeading,
	latestArticles,
	relatedArticles,
	downloadUrl,
	articleCopy,
	pageType,
	primarySite,
	slug,
	pageUrl,
}) => {
	const sideBannerRef = useRef();
	const scroll = scrollDirection();
	const locale = useLocale();
	const location = useLocation();

	const pageTypeReformat = () => {
		if (pageType === 'Awards') {
			return 'Awards';
		}
		if (pageType === 'PressRelease') {
			return 'Press Releases';
		}
		if (pageType === 'InTheNews') {
			return 'In The News';
		}
	};

	// eslint-disable-next-line no-unused-vars
	const [stuck, setStuck] = useState(false);

	useEffect(() => {
		const onScroll = throttle(() => {
			if (sideBannerRef.current?.getBoundingClientRect().top === 0) {
				setStuck(true);
			} else {
				setStuck(false);
			}
		}, 100);

		window.addEventListener('scroll', onScroll);
		return () => window.removeEventListener('scroll', onScroll);
	}, [setStuck, scroll, sideBannerRef]);

	const filteredRelatedArticles = relatedArticles.filter((article) =>
		article.itemCountry.includes(locale),
	);

	return (
		<>
			<ArticleProvider>
				<SEO
					path={location.pathname}
					title={title}
					description={subHeading}
					meta={[
						{
							name: `og:image`,
							content: articleImage?.url,
						},
					]}
					canonicalhref={`${primarySite}${slug}`}
					isMultisite
				/>
				<div className="px-2 mx-auto bg-no-repeat xs-bg-img-none md:bg-right-top xl:px-0 bg-brand-500">
					<div>
						<div className="max-w-6xl px-4 py-12 mx-auto text-white xl:px-0">
							<Breadcrumbs>
								<Breadcrumb
									color="text-white"
									position={0}
									href={buildLink(locale, `news`)}
								>
									BrightHR in the news
								</Breadcrumb>
								<Breadcrumb position={1} color="text-white">
									{title}
								</Breadcrumb>
							</Breadcrumbs>
							<div className="w-full max-w-2xl pt-6 mx-auto lg:ml-0 xl:max-w-3xl">
								<h1 className="mb-2 text-3xl font-bold leading-tight text-white md:text-5xl">
									{title}
								</h1>
							</div>
						</div>
					</div>
				</div>

				<div
					style={{
						background: `linear-gradient(180deg, #0057AD 0%, #0057AD 5rem, rgba(255,255,255,1) 5rem, rgba(255,255,255,1) 100%)`,
					}}
				>
					<div className="max-w-6xl px-2 mx-auto bg-no-repeat xs-bg-img-none md:bg-right-top xl:px-0">
						<div>
							<div className="flex flex-row flex-wrap items-stretch max-w-6xl px-4 mx-auto lg:flex-nowrap xl:px-0">
								<article
									className={cn(
										'w-full max-w-2xl pb-8 mx-auto border-b-2 border-gray-200 xl:ml-0 xl:max-w-3xl xl:mb-10',
										{
											'pt-28': !articleImage,
											'': articleImage,
										},
									)}
								>
									{articleImage && (
										<div className="aspect-[2/1] relative mb-10 bg-gray-200">
											<img
												aria-hidden="true"
												className="object-center w-full h-full pointer-events-none object-fit"
												src={articleImage.responsiveImage?.src}
												srcSet={articleImage.responsiveImage?.srcSet}
												alt={articleImage.alt || `${title} hero image`}
											/>
										</div>
									)}

									<MultiParagraphBlock
										content={articleCopy}
										className="pb-6 md:pb-12"
									/>

									<div className="text-black">
										<h4 className="mb-2 text-lg font-semibold">
											Social Sharing
										</h4>
										<div className="flex">
											<a
												className="flex items-center justify-center mb-4 mr-4 font-bold"
												href={`https://facebook.com/sharer/sharer.php?u=${pageUrl}&t=${title}`}
											>
												<Facebook
													className="w-10 h-10"
													width="40"
													height="40"
												/>
											</a>
											<a
												className="flex items-center justify-center mb-4 mr-4 font-bold"
												href={`http://twitter.com/share?text=${title}&url=${pageUrl}`}
											>
												<Twitter
													className="w-10 h-10"
													width="40"
													height="40"
												/>
											</a>
											<a
												className="flex items-center justify-center mb-4 mr-4 font-bold"
												href={`https://www.linkedin.com/shareArticle?mini=true&url=${pageUrl}&title=${title}&summary=&source=`}
											>
												<LinkedIn
													className="w-10 h-10"
													width="40"
													height="40"
												/>
											</a>
										</div>
									</div>
								</article>

								<aside className="w-full max-w-2xl mx-auto mt-6 ml-auto grow-0 lg:max-w-sm lg:shrink-0 lg:pl-4 lg:mt-32">
									{downloadUrl && (
										<div className="inline-block px-3 py-1 mb-5 border-2 rounded md:block lg:mx-6 border-secondary-300 text-secondary-300 ">
											<a
												href={downloadUrl}
												className="flex items-center justify-between ml-auto text-base font-bold md:text-lg "
												download
											>
												Download press release
												<Icon
													iconName="download-2"
													className="inline-block ml-2 fill-secondary-300 md:ml-0"
												/>
											</a>
										</div>
									)}
									<a
										href={buildLink(locale, `news`)}
										className="flex items-center my-3 font-bold lg:mb-8 lg:mx-6 text-brand-grey text-brand-500 hover:text-brand-400"
									>
										<svg
											width="24"
											height="14"
											viewBox="0 0 24 14"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
											className="mr-4"
										>
											<path
												d="M6.7527 0L7.888 1.13527L3.073 5.95025H24V7.55524H3.073L7.888 12.3702L6.7527 13.5055L0 6.75381L6.7527 0.00213984V0Z"
												fill="currentColor"
											/>
										</svg>
										See all Press releases, News and Awards
									</a>

									<div className="py-4 bg-white border-gray-100 rounded md:mb-8 lg:px-6 lg:border lg:mx-6 lg:shadow ">
										<h4 className="mb-4 text-lg font-bold text-black md:text-xl ">
											Latest {pageTypeReformat()}
										</h4>
										<ul className="list-none">
											{latestArticles.map((newsItem) => (
												<li
													className="w-full max-w-sm mb-2 font-bold "
													key={newsItem.title}
												>
													<a
														href={buildLink(
															locale,
															`news/${newsItem.slug}`,
														)}
														className="relative text-brand-500 hover:text-brand-400"
													>
														<svg
															width="23"
															height="14"
															viewBox="0 0 23 14"
															fill="none"
															xmlns="http://www.w3.org/2000/svg"
															className="absolute top-0 left-0 inline-block mt-1 align-middle"
														>
															<path
																d="M16.119 0.688965L15.058 1.74996L19.558 6.24997H0V7.74997H19.558L15.058 12.25L16.119 13.311L22.43 7.00096L16.119 0.690965V0.688965Z"
																fill="#0057AD"
															/>
														</svg>

														<span className="block pl-8">
															{newsItem.title}
														</span>
													</a>
												</li>
											))}
										</ul>
									</div>

									<div className="sticky right-0 max-w-sm pt-4 pb-6 mx-auto md:px-6 top-36 lg:ml-auto lg:mr-0">
										<div
											className="w-full px-6 py-4 bg-white bg-right-top bg-no-repeat bg-contain border border-gray-200 rounded shadow-lg"
											style={{
												backgroundImage: `url(${CarouselBg})`,
												backgroundPosition: '175px -150px',
											}}
										>
											<h3 className="mb-4 text-2xl font-bold leading-snug text-brand-500">
												Join our BrightHR newsletter
											</h3>
											<p className="mb-4">
												Every month we&#39;ll send you the latest HR news
												and BrightHR product updates straight to your inbox.
												Just enter your email and hit the sign up button to
												get started...
											</p>
											<Newsletter styling="inline" />
										</div>
									</div>
								</aside>
							</div>

							{filteredRelatedArticles.length > 0 && (
								<div className="max-w-6xl px-4 pb-24 mx-auto mt-6 xl:px-0">
									<h4 className="max-w-2xl mx-auto mb-6 text-xl font-semibold text-center ">
										Related to {pageTypeReformat()}
									</h4>
									<section className="block grid-cols-3 gap-8 lg:grid">
										{filteredRelatedArticles.map((newsItem) => (
											<NewsCard key={nanoid()} newsItem={newsItem} />
										))}
									</section>
								</div>
							)}
						</div>
					</div>
				</div>
			</ArticleProvider>
		</>
	);
};

export default NewsArticlePage;
