import React from 'react';
import cn from 'classnames';
import DemoCTA from '../DemoCTA';
import DemoCTASecondary from '../DemoCTA/secondary';
import ReviewBadgesPPC from '../ReviewBadgesPPC';
import CopyBlock from '../../CopyBlock';

const Hero = ({
	h1,
	h2,
	primaryCtaHref,
	primaryCtaText,
	showModal,
	secondaryCtaHref,
	secondaryCtaText,
	showSecondaryCtaButtons,
	heroImage,
}) => (
	<section className="overflow-hidden md:pt-8 md:pb-16 bg-brand-500 main-banner-section-bg lg:relative">
		<div className="px-8 py-4 lg:flex lg:py-16 lg:max-w-7xl lg:mx-auto">
			<div className="text-white lg:w-7/12">
				<h1
					data-cy="slug-hero-h1"
					className="max-w-2xl my-8 text-4xl font-bold leading-none text-center md:text-5xl md:mt-0 xl:text-6xl md:text-left"
				>
					{h1}
				</h1>
				<div
					data-cy="slug-hero-copy"
					className="w-full pb-2 text-center md:text-left md:pb-6 lg:w-7/12"
				>
					<CopyBlock>{h2.childMdx.body}</CopyBlock>
				</div>

				<div data-cy="slug-hero-cta" className="mt-2 md:mt-0 md:inline-block">
					<DemoCTA
						primaryCtaHref={primaryCtaHref}
						primaryCtaText={primaryCtaText}
						showModal={showModal}
					/>
				</div>
				<div
					className={cn('md:inline-block', {
						block: showSecondaryCtaButtons,
						hidden: !showSecondaryCtaButtons,
					})}
				>
					{showSecondaryCtaButtons && (
						<DemoCTASecondary
							secondaryCtaHref={secondaryCtaHref}
							secondaryCtaText={secondaryCtaText}
							href="#form"
						/>
					)}
				</div>
				<div className="flex flex-row justify-center mt-3 mb-20 md:mb-0 md:justify-start">
					<ReviewBadgesPPC
						locale="en"
						classes="w-48 lg:w-56"
						width="224"
						height="60"
						g2AwardClasses="ml-3 w-24 lg:w-28"
					/>
				</div>
			</div>
			<div className="relative hidden mt-12 -mr-6 md:block lg:mt-0 lg:top-1/2 lg:absolute lg:right-0 lg:transform lg:-translate-y-1/2 lg:w-5/12 xl:-mr-16 product-ui-wrapper">
				<img
					data-cy="slug-hero-image"
					loading="eager"
					width="1000"
					height="604"
					className="ml-auto"
					src={heroImage}
					alt=""
				/>
			</div>
		</div>
	</section>
);

export default Hero;
