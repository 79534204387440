import React, { useEffect, useState, useMemo } from 'react';
import Button from '@brighthr/component-button';
import { useFormik } from 'formik';
import loadScript from 'load-script2';
import Alert from '@brighthr/component-alert';
import FormFooterLinks from '../FormFooterLinks';
import submitMarketoForm from '../submitMarketoForm';
import { buildLink } from '../../../utils/locale';

const ReferABusinessForm = ({ locale }) => {
	const [firstSubmit, setFirstSubmit] = useState();
	const [employeeStatus, setEmployeeStatus] = useState();
	const defaultEmployeeStatus = 'Business owner';
	const isBusinessOwner = employeeStatus === defaultEmployeeStatus;

	const LeadSource = useMemo(() => {
		const prefix = 'Client Exchange Referral';
		if (locale === 'ie') {
			return `${prefix} - ROI`;
		}
		if (locale === 'en') {
			return `${prefix} - UK`;
		}
		return `${prefix} - ${locale.toUpperCase()}`;
	}, [locale]);

	useEffect(() => {
		// Preloads the Marketo form for background submission
		const loadForm = () =>
			loadScript('//app-lon04.marketo.com/js/forms2/js/forms2.min.js')
				.then(() => {
					window.MktoForms2.loadForm(
						'//app-lon04.marketo.com',
						'217-MIC-854',
						2796,
						(form) => {
							form.onSuccess(() => false);
						},
					);
				})
				.catch(() => {});

		if (window.requestIdleCallback) {
			window.requestIdleCallback(loadForm);
		} else {
			setTimeout(loadForm);
		}
	}, []);

	const { status, isSubmitting, values, setStatus, handleSubmit, handleChange } = useFormik({
		enableReinitialize: true,
		initialValues: {
			Company: '',
			Email: '',
			FirstName: '',
			LastName: '',
			Phone: '',
			Title: '',
			Contact_Us_Comments__c: '',
			RefType: defaultEmployeeStatus,
			Referrer_Name__c: '',
			Referrer_Company__c: '',
			Referrer_Phone__c: '',
			Referrer_Email__c: '',
			LeadSource,
		},
		onSubmit: async (formData) => {
			submitMarketoForm(formData)
				.then(() => {
					setStatus('submitted');
				})
				.catch(() => {
					setStatus('error');
				});
		},
	});

	const [isFormDisabled, setFormDisabled] = useState(false);

	useEffect(() => {
		// Update the form disabled state based on the condition
		setFormDisabled(
			(values.Phone?.length > 0 && values.Phone === values.Referrer_Phone__c) ||
				(values.Email?.length > 0 && values.Email === values.Referrer_Email__c) ||
				(values.Company?.length > 0 && values.Company === values.Referrer_Company__c),
		);
	}, [values, status]);

	useEffect(() => {
		if (status) {
			setFirstSubmit(true);
		}
	}, [status]);

	useEffect(() => {
		if (values.RefType) {
			setEmployeeStatus(values.RefType);
		}
	}, [values?.RefType]);

	return (
		<>
			{status === 'error' && (
				<p className="px-4 py-8 font-bold rounded bg-brand-100">
					There was an error in the referral process, please refresh the page and try
					again.
				</p>
			)}

			{isFormDisabled && (
				<Alert type="warning" icon="error" className="w-full mb-4 !max-w-full items-center">
					<p>Your details cannot match the person you are referring</p>
				</Alert>
			)}

			{status === 'submitted' && (
				<p className="px-4 py-8 font-bold rounded bg-brand-100">
					Thank you for your referral and welcome to The BrightHR Promoter Programme! You
					can now earn unlimited cash rewards for every successful referral you make.
					Don’t forget to let the person or company you’re referring know that one of our
					friendly software experts will be contacting them today to arrange a demo of
					BrightHR. Know anyone else you’d like to refer? Click{' '}
					<a className="text-brand-500" href={buildLink(locale, '/refer-a-business')}>
						here
					</a>{' '}
					to complete the form.
				</p>
			)}

			<form
				onSubmit={handleSubmit}
				className={`flex flex-col max-w-full mb-6 ${firstSubmit ? 'hidden' : ''}`}
			>
				<div className="flex flex-col justify-between gap-8 mb-2 space-y-3 md:flex-row">
					<div className="flex flex-col flex-wrap w-full gap-3 md:w-1/2 md:flex-nowrap">
						<p className="mb-4 text-2xl font-bold">Who are you referring?</p>
						<label className="block w-full font-bold text-left ">
							Company name&#42;
							<input
								id="Company"
								name="Company"
								placeholder="Company name"
								className="w-full px-3 py-2 mt-2 font-normal border-2 border-gray-400 rounded"
								required
								value={values.Company}
								onChange={handleChange}
							/>
						</label>
						<label className="block w-full font-bold text-left ">
							Email address&#42;
							<input
								id="Email"
								name="Email"
								placeholder="Email address"
								className="w-full px-3 py-2 mt-2 font-normal border-2 border-gray-400 rounded"
								type="email"
								required
								value={values.Email}
								onChange={handleChange}
							/>
						</label>
						<label className="block w-full font-bold text-left ">
							First name
							<input
								id="FirstName"
								name="FirstName"
								placeholder="First name"
								className="w-full px-3 py-2 mt-2 font-normal border-2 border-gray-400 rounded"
								required
								value={values.FirstName}
								onChange={handleChange}
							/>
						</label>
						<label className="block w-full font-bold text-left ">
							Last name
							<input
								id="LastName"
								name="LastName"
								placeholder="Last name"
								className="w-full px-3 py-2 mt-2 font-normal border-2 border-gray-400 rounded"
								required
								value={values.LastName}
								onChange={handleChange}
							/>
						</label>
						<label className="block w-full font-bold text-left ">
							Phone number&#42;
							<input
								id="PhoneNumber"
								name="Phone"
								type="tel"
								placeholder="01632 960410"
								className="w-full px-3 py-2 mt-2 font-normal border-2 border-gray-400 rounded"
								pattern="[0-9\s]{7,16}"
								title="Enter a valid telephone number between 7 and 16 digits."
								required
								value={values.PhoneNumber}
								onChange={handleChange}
							/>
						</label>
						<label className="block w-full font-bold text-left ">
							Job title
							<input
								id="Title"
								name="Title"
								placeholder="Job title"
								className="w-full px-3 py-2 mt-2 font-normal border-2 border-gray-400 rounded"
								value={values.Title}
								onChange={handleChange}
							/>
						</label>
						<label className="block w-full font-bold text-left ">
							Any additional comments
							<textarea
								placeholder="Any additional comments"
								id="Contact_Us_Comments__c"
								name="Contact_Us_Comments__c"
								className="w-full px-3 py-2 mt-2 font-normal border-2 border-gray-400 rounded"
								value={values.Contact_Us_Comments__c}
								onChange={handleChange}
							/>
						</label>
					</div>

					<div className="flex flex-col w-full md:w-1/2">
						<div className="flex flex-col gap-3 p-8 bg-gray-200 rounded-md">
							<p className="mb-2 text-lg font-bold">Your details</p>

							<select
								id="RefType"
								name="RefType"
								className="w-full p-2 pr-10 border-2 border-gray-700 rounded-md select-arrow"
								value={values.RefType}
								onChange={handleChange}
							>
								<option value="Business owner">Business owner</option>
								<option value="Employee">Employee</option>
							</select>

							<label className="block w-full font-bold text-left ">
								Your full name
								<input
									id="Referrer_Name__c"
									name="Referrer_Name__c"
									placeholder="Your full name"
									className="w-full px-3 py-2 mt-2 font-normal border-2 border-gray-400 rounded"
									required
									value={values.Referrer_Name__c}
									onChange={handleChange}
								/>
							</label>

							{isBusinessOwner && (
								<label className="block w-full font-bold text-left ">
									Your company name&#42;
									<input
										id="Referrer_Company__c"
										name="Referrer_Company__c"
										placeholder="Your company name"
										className="w-full px-3 py-2 mt-2 font-normal border-2 border-gray-400 rounded"
										required
										value={values.Referrer_Company__c}
										onChange={handleChange}
									/>
								</label>
							)}

							<label className="block w-full font-bold text-left ">
								Your phone number&#42;
								<input
									id="Referrer_Phone__c"
									name="Referrer_Phone__c"
									type="tel"
									placeholder="Your phone number"
									className="w-full px-3 py-2 mt-2 font-normal border-2 border-gray-400 rounded"
									pattern="[0-9\s]{7,16}"
									title="Enter a valid telephone number between 7 and 16 digits."
									required
									value={values.Referrer_Phone__c}
									onChange={handleChange}
								/>
							</label>

							<label className="block w-full font-bold text-left ">
								Your email address&#42;
								<input
									id="Referrer_Email__c"
									name="Referrer_Email__c"
									placeholder="Your email address"
									className="w-full px-3 py-2 mt-2 font-normal border-2 border-gray-400 rounded"
									type="email"
									required
									value={values.Referrer_Email__c}
									onChange={handleChange}
								/>
							</label>
						</div>
						<div className="flex flex-col w-full mt-3">
							<Button
								type="submit"
								disabled={isSubmitting || isFormDisabled}
								text="Submit"
								size="lg"
							/>
						</div>

						<FormFooterLinks
							className="mt-2 text-xs text-left "
							textLinkColor=""
							locale={locale}
						/>
					</div>
				</div>
			</form>
		</>
	);
};

export default ReferABusinessForm;
