import Icon from '@brighthr/component-icon';
import cn from 'classnames';
import React, { useMemo } from 'react';
import { MenuIcon } from '../Header/icons/GenericIcons';
import Logo from '../Logo';
import { useLocale } from '../../context/Locale';
import { buildLink } from '../../utils/locale';
import CTAButtons from '../Header/CTAButtons';
import { MetaLinks } from '../Header/Links';
import { useVar } from '../Var';
import Menu from '../Header/Menu';
import WhatsNew from '../Header/WhatsNew';

function MainNav({ headerData, dropDown, isMobile, setDropDown }) {
	const salesNumber = useVar('salesNumber');
	const locale = useLocale();

	const {
		ourProducts,
		resources,
		pricingLinkUrl,
		pricingDisplayText,
		featured,
		whoWeServeLinks: whoWeServe,
	} = headerData;
	const brightSoftwareLinks = useMemo(
		() => ourProducts.filter((link) => link.subheading === 'BRIGHT SOFTWARE'),
		[ourProducts],
	);

	const resourcesLinks = useMemo(
		() => resources.filter((link) => link.subheading === 'RESOURCES'),
		[resources],
	);

	const companyLinks = useMemo(
		() => resources.filter((link) => link.subheading === 'COMPANY'),
		[resources],
	);

	return (
		<nav className="bg-white xl:sticky" data-cy="mainNav">
			<div className="flex items-center px-4 py-2 xl:hidden">
				<a href={buildLink(locale, '/')}>
					<Icon
						className="ml-2 fill-primary-700 group-hover:fill-primary-400"
						iconName="xing"
						size={40}
					/>
				</a>

				<a
					className="mx-auto text-lg font-bold xl:m-0 text-primary-700 hover:text-primary-400"
					href={`tel:${salesNumber}`}
					data-cy="landingPage-phoneNumber"
				>
					{salesNumber}
				</a>

				<button type="button" onClick={() => setDropDown(dropDown ? false : 'Mobile')}>
					<MenuIcon />
				</button>
			</div>

			<div
				className={cn(
					'xl:items-center flex-col xl:flex-row justify-start container gap-6 py-4 xl:mx-auto xl:flex',
					dropDown ? 'flex' : 'hidden',
				)}
			>
				<div className="hidden mr-2 text-brand-500 xl:block">
					<a href={buildLink(locale, '/')}>
						<Logo />
					</a>
				</div>

				<Menu
					featured={featured}
					linksData={brightSoftwareLinks}
					dropDown={dropDown}
					isMobile={isMobile}
					setDropDown={setDropDown}
					menuTitle="Products"
					displayTextAppend="overview"
				/>
				<Menu
					featured={featured}
					linksData={resourcesLinks}
					dropDown={dropDown}
					isMobile={isMobile}
					setDropDown={setDropDown}
					menuTitle="Resources"
					displayTextAppend="hub"
				/>
				{isMobile && (
					<Menu
						featured={featured}
						linksData={companyLinks}
						dropDown={dropDown}
						isMobile={isMobile}
						setDropDown={setDropDown}
						menuTitle="Company"
					/>
				)}
				{whoWeServe.length > 0 && (
					<Menu
						featured={featured}
						linksData={whoWeServe}
						dropDown={dropDown}
						isMobile={isMobile}
						setDropDown={setDropDown}
						menuTitle="Who we serve"
						largerColumn
					/>
				)}

				{pricingDisplayText && pricingLinkUrl && (
					<a
						className="font-semibold text-primary-700 hover:text-primary-400 text-lg"
						href={pricingLinkUrl}
					>
						{pricingDisplayText}
					</a>
				)}

				<div className="flex flex-col gap-4 font-semibold text-primary-700 md:hidden">
					<MetaLinks />
				</div>

				<div
					data-cy="landingPage-phoneNumber"
					className="items-center hidden ml-auto space-x-6 xl:flex"
				>
					<a
						href={`tel:${salesNumber}`}
						className="font-bold text-lg no-underline text-primary-700 hover:text-brand-400 focus:underline focus:outline-none"
					>
						{salesNumber}
					</a>
					<CTAButtons />
				</div>
				<div className="xl:hidden">
					<CTAButtons mobileView />
				</div>
				{!isMobile && (
					<div className="flex flex-col w-full gap-2 xl:hidden">
						<WhatsNew data={featured} />
					</div>
				)}
			</div>
		</nav>
	);
}

export default MainNav;
